import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import IconButton from '../../../../../gen/button/IconButton.gen';
// import ProjectMobileNav from './Project.mobile.nav';
import Project3DSectionMobile from '../../../three_d/mobile/Project3DSection.mobile';
export default function ProjectMobile() {
  const [search] = useSearchParams();
  let navigate = useNavigate();
  const app = search.get('app');
  // const previousFolderId
  const handleBack = () => {
    // const queryParams = new URLSearchParams(window.location.search);
    // queryParams.set('folderId', id);
    // setSearchParams(queryParams);
    navigate(-1);
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 5,
          alignItems: 'center',
          paddingLeft: 5,
        }}
      >
        <IconButton icon={'back'} border={false} onClick={handleBack} />
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
          {app?.toUpperCase()}
        </span>
      </div>
      <div style={{ height: 'calc(100% - 40px)', overflow: 'auto' }}>
        {true && <Project3DSectionMobile />}
      </div>
    </>
  );
}
