/**
 * @param {string} message
 * @returns
 */
const AlertLogin = ({ message }) => {
  return (
    <div
      className='mb-4 rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400'
      role='alert'
    >
      <span className='font-medium'>Failed!</span> {message}
    </div>
  );
};

export default AlertLogin;
