import { message } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppAPI } from 'src/components/api';
import LayOutProjectList from 'src/components/layout/project_list/ProjectList.layout';
import { adminStore } from 'src/store/Admin.store';
import { appStore } from 'src/store/App.store';

const ProjectListPage = () => {
  const navigate = useNavigate();
  const { setProjects } = adminStore();
  const { setIsLoading } = appStore();
  const { organizationId, projectId } = useParams();
  const handleProject = (e) => {
    navigate(`./project/${e}/apps`);
  };
  useEffect(() => {
    run();
    return () => {
      setProjects([]);
    };
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const projectsData =
        await AppAPI.ProjectAPI.GetAllByUserIdAndOrganizationId({
          organizationId,
          projectId,
        });
      if (projectsData) {
        setProjects(projectsData);
      }
    } catch (ex) {
      message.warning(ex.message);
    }
    setIsLoading(false);
  };
  return <LayOutProjectList gotoProject={handleProject} />;
};

export default ProjectListPage;
