import React, { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import { hourRangeFormat, numberWithCommas } from '../../../../util/Gantt.util';
import { timelineStore } from '../../Timeline.store';

const Gantt = window.Gantt;

var menu = new window.dhtmlXMenuObject();
export default function TimelineTasksGantt() {
  const { setTimelineGantt, timelineGantt } = timelineStore();
  const currentTimelineGantt = useRef(timelineGantt);
  const setCurrentTimelineGantt = (data) => {
    currentTimelineGantt.current = data;
  };
  useEffect(() => {
    setCurrentTimelineGantt(timelineGantt);
  }, [timelineGantt]);
  const ref = useCallback((node) => {
    if (!node) {
      currentTimelineGantt.current?.destructor();
      setTimelineGantt(null);
      return;
    }
    const ganttInstance = Gantt.getGanttInstance();
    menu.renderAsContextMenu();
    menu.setSkin("dhx_terrace");
    ganttInstance.attachEvent("onContextMenu", function (taskId, linkId, event) {
			var x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft,
				y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;

			var target = (event.target || event.srcElement);
			var column_id = target.getAttribute("column_id");
			menu.clearAll();

			addColumnsConfig();
			if (column_id) {
				addColumnToggle(column_id);
			}

			menu.showContextMenu(x, y);
			return false;
		});

		menu.attachEvent("onClick", function (id, zoneId, cas) {
			var parts = (id + "").split("#");
			var is_toggle = parts[0] == "toggle",
				column_id = parts[1] || id;

			var column = ganttInstance.getGridColumn(column_id);
      console.log(column)
			if (column) {
				var visible = !is_toggle ? menu.getCheckboxState(id) : false;
				column.hide = !visible;
				ganttInstance.render();
			}
			return true;
		});

    ganttInstance.config.grid_resize = true;
    ganttInstance.config.wide_form = false;
    // ganttInstance.config.auto_types = true;
    ganttInstance.config.date_format = "%Y-%m-%d %H:%i";
    ganttInstance.plugins({
      fullscreen: true,
      drag_timeline: true,
      auto_scheduling: true,
      undo: true,
      tooltip: true,
      marker: true,
      critical_path: true,
      grouping: true,
      multiselect: true,
      export_api: true
    });

    var zoomConfig = {
      levels: [
        [
          { unit: 'month', format: '%M %Y', step: 1 },
          {
            unit: 'week',
            step: 1,
            format: function (date) {
              var dateToStr = ganttInstance.date.date_to_str('%d %M');
              var endDate = ganttInstance.date.add(date, -6, 'day');
              var weekNum = ganttInstance.date.date_to_str('%W')(date);
              return (
                'Week #' +
                weekNum +
                ', ' +
                dateToStr(date) +
                ' - ' +
                dateToStr(endDate)
              );
            },
          },
        ],
        [
          { unit: 'month', format: '%M %Y', step: 1 },
          { unit: 'day', format: '%d %M', step: 1 },
        ],
        [
          { unit: 'day', format: '%d %M', step: 1 },
          { unit: 'hour', format: hourRangeFormat(12), step: 12 },
        ],
        [
          { unit: 'day', format: '%d %M', step: 1 },
          { unit: 'hour', format: hourRangeFormat(6), step: 6 },
        ],
        [
          { unit: 'day', format: '%d %M', step: 1 },
          { unit: 'hour', format: '%H:%i', step: 1 },
        ],
      ],
      // startDate: new Date(2018, 02, 27),
      // endDate: new Date(2018, 03, 20),
      useKey: 'ctrlKey',
      trigger: 'wheel',
      element: function () {
        return ganttInstance.$root.querySelector('.gantt_task');
      },
    };

    ganttInstance.config.columns = [
      // {
      //   name: 'id',
      //   label: 'Display Id',
      //   width: 100,
      //   resize: true,
      // },
      {
        name: 'text',
        label: 'Task name',
        tree: true,
        width: 200,
        min_width: 200,
        resize: true,
      },
      {
        name: 'start_date',
        label: 'Planned Start',
        width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'end_date',
        label: 'Planned End',
        width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'actual_start',
        label: 'Actual Start',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          return item?.actual_start;
        },
      },
      {
        name: 'actual_end',
        label: 'Actual End',
        width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'attached', 
        label: 'Attached',
        // width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'task_type', 
        label: 'Task Type',
        width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'material_cost',
        label: 'Material Cost',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          return numberWithCommas(item?.material_cost?.toFixed(0));
        },
      },
      {
        name: 'labor_cost',
        label: 'Labor Cost',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          return numberWithCommas(item?.labor_cost?.toFixed(0));
        },
      },
      {
        name: 'equipment_cost',
        label: 'Equipment Cost',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          return numberWithCommas(item?.equipment_cost?.toFixed(0));
        },
      },
      {
        name: 'total_cost',
        label: 'Total Cost',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          item.total_cost =
            item.labor_cost + item.equipment_cost + item.material_cost;
          return numberWithCommas(item.total_cost.toFixed(0));
        },
      },
      { name: 'add', width: 40 },
    ];

    ganttInstance.ext.zoom.init(zoomConfig);
    ganttInstance.config.layout = {
      css: "gantt_container",
      cols: [
        {
          width:400,
          min_width: 300,
          rows:[
            {view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer"},
            {view: "scrollbar", id: "gridScroll", group:"horizontal"}
          ]
        },
        {resizer: true, width: 1},
        {
          rows:[
            {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
            {view: "scrollbar", id: "scrollHor", group:"horizontal"}
          ]
        },
        {view: "scrollbar", id: "scrollVer"}
      ]
    };
    ganttInstance.init(node);

  
    
    setTimelineGantt(ganttInstance);
  }, []);
  useEffect(() => {
    if (timelineGantt) {
      let node = document.getElementById('idd-timeline-gantt');
      new ResizeObserver(handleResize).observe(node);
    }
  }, [timelineGantt]);
  const handleResize = () => {
    currentTimelineGantt.current?.render();
  };
  function addColumnToggle(column_name) {
    var column = timelineGantt.getGridColumn(column_name);
    var label = getColumnLabel(column);

    //add prefix to distinguish from the same item in 'show columns' menu
    var item_id = "toggle#" + column_name
    menu.addNewChild(null, -1, item_id, "Hide '" + label + "'", false);
    menu.addNewSeparator(item_id);
  }

  function addColumnsConfig() {
    menu.addNewChild(null, -1, "show_columns", "Show columns:", false);
    var columns = timelineGantt.config.columns;

    for (var i = 0; i < columns.length; i++) {
      var checked = (!columns[i].hide),
        itemLabel = getColumnLabel(columns[i]);
      menu.addCheckbox("child", "show_columns", i, columns[i].name, itemLabel, checked);
    }
  }

  function getColumnLabel(column) {
    if (column == null)
      return '';

    var locale = timelineGantt.locale.labels;
    var text = column.label !== undefined ? column.label : locale["column_" + column.name];

    text = text || column.name;
    return text;
  }
  return (
    <>
      <div style={{ height: '100%' }} id='idd-timeline-gantt'>
        <div style={{ height: '100%', width: '100%' }} ref={ref}></div>
      </div>
    </>
  );
}
