import _ from 'lodash';
import { pointerToRaycaster } from 'src/functions/Viewer.function';
import { v4 } from 'uuid';
import { EventDispatcher } from '../extensions/utils/EventDispatcher';

export default class DrawingSafetyArea extends EventDispatcher {
  name;
  raycaster = new window.THREE.Raycaster();
  enabled = false;
  viewer;
  camera;

  style;

  mouse = new window.THREE.Vector2();
  onDownPosition = new window.THREE.Vector2();
  onUpPosition = new window.THREE.Vector2();
  onMovePosition = new window.THREE.Vector2();

  event = {
    add: 'add',
    update: 'update',
    remove: 'remove',
    esc: 'esc',
  };
  customSceneIdentifier = 'DrawingSafetyArea';
  points = [];
  holes = [];
  hole = [];

  shapeMesh;
  area = [];
  result = [];
  plane;
  mode = 1;

  material1 = new window.THREE.MeshBasicMaterial({
    color: 'red',
    transparent: true,
    opacity: 0.5,
  });
  material = new window.THREE.MeshBasicMaterial({
    color: 0x00ff00,
    transparent: true,
    opacity: 0.5,
  });
  //
  constructor(viewer, name = 'DrawingSafetyArea') {
    super();
    this.viewer = viewer;

    this.camera = viewer.impl.camera;
    this.name = name;
  }
  init = (data) => {
    console.log('init');
    if (!this.viewer.overlays.hasScene(this.customSceneIdentifier)) {
      this.viewer.overlays.addScene(this.customSceneIdentifier);
    }

    this.plane = new window.THREE.Mesh(
      new window.THREE.PlaneBufferGeometry(1000000, 1000000),
      this.material
    );
    this.plane.visible = false;

    this.viewer.overlays.addMesh(this.plane, this.customSceneIdentifier);

    _.forEach(data, (item) => {
      const shape = new window.THREE.Shape();
      _.forEach(item.points, (p, k) => {
        if (k === 0) {
          shape.moveTo(p.x, p.y);
        } else {
          shape.lineTo(p.x, p.y);
        }
      });
      if (item.holes.length !== 0) {
        _.forEach(this.holes, (hole, k) => {
          const holePath = new window.THREE.Path();
          _.forEach(hole, (p, k) => {
            if (k === 0) {
              holePath.moveTo(p.x, p.y);
            } else {
              holePath.lineTo(p.x, p.y);
            }
          });
          shape.holes.push(holePath);
        });
      }
      const mesh = new window.THREE.Mesh(
        new window.THREE.ShapeGeometry(shape),
        this.material1
      );
      mesh.userData.id = item.id;
      this.result.push(mesh);
      this.viewer.overlays.addMesh(mesh, this.customSceneIdentifier);
    });
  };
  load = () => {};
  unload = () => {
    console.log('remove layout');
    if (this.viewer.overlays.hasScene(this.customSceneIdentifier)) {
      this.viewer.overlays.removeScene(this.customSceneIdentifier);
    }
    this.dispose();
  };

  setEnabled = (value, mode = 1, id, points = [], holes = []) => {
    this.mode = mode;
    this.enabled = value;
    this.points = points;
    this.holes = holes;
    this.currentItemId = id;

    if (value) {
      this.viewer.canvas.addEventListener(
        'pointerdown',
        this.onPointerDownController,
        false
      );
      this.viewer.canvas.addEventListener(
        'pointermove',
        this.onPointerMoveController,
        false
      );
      window.addEventListener('keyup', this.onKeyUp, false);
      if (this.currentItemId) {
        const index = _.findIndex(
          this.result,
          (v) => v.userData.id === this.currentItemId
        );
        if (index >= 0) {
          this.shapeMesh = this.result[index];
        }
      } else {
        this.shapeMesh = new window.THREE.Mesh(
          new window.THREE.Geometry(),
          this.material
        );
        this.viewer.overlays.addMesh(
          this.shapeMesh,
          this.customSceneIdentifier
        );
      }
    } else {
      this.dispose();
    }
  };
  getPointerPosition = (dom, x, y) => {
    const rect = dom.getBoundingClientRect();
    return [x - rect.x, y - rect.y];
  };
  onPointerDownController = (event) => {
    if (!this.enabled) {
      return true;
    }
    const array = this.getPointerPosition(
      this.viewer.canvas.parentElement,
      event.clientX,
      event.clientY
    );
    this.onDownPosition.fromArray(array);
    document.addEventListener('pointerup', this.onPointerUpController);
  };
  onPointerUpController = (event) => {
    if (!this.enabled) {
      return true;
    }
    const array = this.getPointerPosition(
      this.viewer.canvas.parentElement,
      event.clientX,
      event.clientY
    );
    this.onUpPosition.fromArray(array);
    this.handleClick(event);
    document.removeEventListener('pointerup', this.onPointerUpController);
  };

  hitTest = () => {
    return this.viewer.hitTest(this.onUpPosition.x, this.onUpPosition.y, true);
  };
  onPointerMoveController = (event) => {
    if (!this.enabled) {
      return true;
    }
    const array = this.getPointerPosition(
      this.viewer.canvas.parentElement,
      event.clientX,
      event.clientY
    );
    this.onMovePosition.fromArray(array);
  };
  handleClick = (event) => {
    event.stopPropagation();
    if (event.which !== 1) {
      return;
    }
    if (this.onDownPosition.distanceTo(this.onUpPosition) === 0) {
      this.container = this.viewer.canvas;
      this.rect = this.container.getBoundingClientRect();
      let raycaster = pointerToRaycaster(
        this.viewer.impl.camera,
        this.raycaster,
        event,
        this.rect
      );

      // this.raycaster.setFromCamera(this.onUpPosition, this.viewer.impl.camera);
      const intersections = raycaster.intersectObject(this.plane);

      if (intersections.length !== 0) {
        if (this.mode === 1) {
          this.points.push(intersections[0].point);
        } else if (this.mode === 2) {
          this.hole.push(intersections[0].point);
        }

        if (this.points.length > 2) {
          const shape = new window.THREE.Shape();

          _.forEach(this.points, (p, k) => {
            if (k === 0) {
              shape.moveTo(p.x, p.y);
            } else {
              shape.lineTo(p.x, p.y);
            }
          });
          if (this.holes.length !== 0) {
            _.forEach(this.holes, (hole, k) => {
              const holePath = new window.THREE.Path();
              _.forEach(hole, (p, k) => {
                if (k === 0) {
                  holePath.moveTo(p.x, p.y);
                } else {
                  holePath.lineTo(p.x, p.y);
                }
              });
              shape.holes.push(holePath);
            });
          }

          if (this.hole.length !== 0) {
            const holePath = new window.THREE.Path();
            _.forEach(this.hole, (p, k) => {
              if (k === 0) {
                holePath.moveTo(p.x, p.y);
              } else {
                holePath.lineTo(p.x, p.y);
              }
            });
            shape.holes.push(holePath);
          }
          this.shapeMesh.geometry.dispose();
          this.shapeMesh.geometry = new window.THREE.ShapeGeometry(shape);
          console.log(shape.holes);
          // var mesh = new window.THREE.Mesh(geometry, material);

          // // Add the mesh to your scene
          // this.viewer.overlays.addMesh(mesh, this.customSceneIdentifier);
        }
      }
    }
  };
  onKeyUp = ({ key }) => {
    if (key === 'Enter') {
      if (this.hole.length > 2) {
        this.holes.push([...this.hole]);
      }

      const id = this.currentItemId ?? v4();
      this.dispatchEvent({
        type: this.currentItemId ? this.event.update : this.event.add,
        points: [...this.points],
        holes: [...this.holes],
        id: id,
      });
      if (!this.currentItemId) {
        const mesh = new window.THREE.Mesh(
          this.shapeMesh.geometry.clone(),
          this.material1
        );
        mesh.userData.id = id;
        this.result.push(mesh);
        this.viewer.overlays.addMesh(mesh, this.customSceneIdentifier);
        this.viewer.overlays.removeMesh(
          this.shapeMesh,
          this.customSceneIdentifier
        );
        this.shapeMesh.geometry.dispose();
      }

      this.points.length = 0;
      this.holes.length = 0;
      this.hole.length = 0;
      this.currentItemId = null;
    } else if (key === 'Escape') {
      if (!this.currentItemId) {
        this.viewer.overlays.removeMesh(
          this.shapeMesh,
          this.customSceneIdentifier
        );
        this.shapeMesh.geometry.dispose();
        this.dispatchEvent({
          type: this.event.esc,
        });
      }
    }
  };
  removeMesh = (id) => {
    const index = _.findIndex(this.result, (v) => v.userData.id === id);
    if (index >= 0) {
      this.viewer.overlays.removeMesh(
        this.result[index],
        this.customSceneIdentifier
      );
      this.result.splice(index, 1);
    }
  };
  dispose = () => {
    if (this.viewer) {
      this.viewer?.canvas.removeEventListener(
        'pointerdown',
        this.onPointerDownController,
        false
      );
      this.viewer?.canvas.removeEventListener(
        'pointermove',
        this.onPointerMoveController,
        false
      );

      window.removeEventListener('keyup', this.onKeyUp, false);
    }
  };
}
