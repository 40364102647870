import _ from 'lodash';
import { useEffect, useState } from 'react';

export default function PaymentCellGroup(props) {
  const [value, setValue] = useState();
  useEffect(() => {
    let temp = 0;

    _.forEach(props.node.allLeafChildren, (v) => {
      let data = v.data[props.column.colId];
      if (data) {
        temp = temp + data * 1;
      }
    });
    if (!props.node.data) {
      props.node.data = {};
    }
    props.node.data[props.column.colId] = temp.toFixed(2);
    setValue(temp.toFixed(2));
  }, []);

  return <div style={{ fontWeight: 'bold' }}>{`$ ${value}`}</div>;
}
