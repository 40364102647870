import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { useSearchParams } from "react-router-dom";
import { viewerStore } from '../../../../../../store/Viewer.store';
import COBieTable from '../table/COBie.table';
import { COBieColumnTable } from '../constants/COBieColumnTable';
import { extractQuantity } from '../../../util/ExtractQuantity.util';
import { getAllModelsElementdbIdsWithCondition } from '../../../../../../functions/Viewer.function';



export default function COBieFloor() {
    const [search] = useSearchParams();
    const { viewer } = viewerStore()
    const [data, setData]= useState()
    useEffect(() => {
        run()
    }, [])
    const run= async ()=>{
        const models = viewer.impl.modelQueue().getModels();
        let data = []
        _.forEach(models, (model) => {
            let instanceTree = model.getData().instanceTree;
            let temp = getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all');
            data.push({ dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride })
        })


        let parameters =[]
        _.forEach(COBieColumnTable.Floor, v=>{
            parameters.push(v.headerName)
        })
        let result = await extractQuantity(data, ['Floors'], parameters, null)
        setData(result)
    }
    return (
        <>
            <COBieTable
                columnData={COBieColumnTable.Floor}
                dataTable={data}
            />

        </>

    );
}


