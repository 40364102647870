import React from 'react';
import WorkerTrackingLayout from '../../../layout/apps/worker/traking/WorkerTracking.layout';

export default function WorkerTrackPage() {
  return (
    <>
      {' '}
      <WorkerTrackingLayout />
    </>
  );
}
