import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AppAPI } from "../../../../api";
import { ViewerForgePDF } from "../../../viewer/Viewer.forge.pdf";
import { appStore } from "../../../../../store/App.store";
import { message, Button } from 'antd'
import { S3_BASED_PATH } from "../../../../../config";
import { generateS3Path } from "../../../../../functions/General.function";
import IconTextButton from "../../../../gen/button/IconTextButton.gen";
export default function DMSViewer() {
    const [search] = useSearchParams();
    const [, setViewer] = useState();
    const [link, setLink] = useState();
    const { setIsLoading } = appStore()
    const { organizationId, projectId } = useParams()
    const key = search.get("key");
    const type = search.get("type");
    const [file, setFile] = useState()
    // const [type, setType] = useState()
    useEffect(() => {
        if (organizationId && projectId) {
            run();
        } else {
            message.warning('Cannot find project id or organization id')
        }
    }, []);

    const run = async () => {
        setIsLoading(true)
        try {
            // const file = await AppAPI.DMSDocAPI.GetDMSDocOne({ id: fileId })
            // const key = generateS3Path(organizationId, projectId,
            //     S3_BASED_PATH.DMS_DOC, `${file.path}/${file.id}/${file.current_version}/${file.name}`)
            if (!key) {
                throw new Error('Cannot find key')
            }
            const link = await AppAPI.S3API.GetPublicLink({ key: atob(key) })
            // const split = file.name?.toLowerCase().split('.')
            // setType(split[split.length - 1])
            setLink(link)
            setFile(file)
        } catch (e) {
            message.warning(e.message)
        }
        setIsLoading(false)
    }

    const handleDownload = async () => {
        // const key = generateS3Path(organizationId, projectId,
        //     S3_BASED_PATH.DMS_DOC, `${file.path}/${file.id}/${file.current_version}/${file.name}`)
        const link = await AppAPI.S3API.GetPublicLink({ key })
        window.open(link)
    };
    return (
        <>
            <div style={{ height: "calc(100% - 0px)", width: "100%" }}>
                {link && (
                    <>
                        <div style={{ position: "absolute", bottom: 10, right: 10, zIndex: 1000 }}>
                            <IconTextButton type={'save'} icon={'download'} size="small" onClick={handleDownload}>Download</IconTextButton>
                        </div>
                        <ViewerForgePDF
                            setViewer={setViewer}
                            filePath={link}
                            fileExt={type ?? "pdf"}
                        />
                    </>
                )}
            </div>
        </>
    );
}
