import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Collapse } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Loader({ fontSize = 50 }) {
  const ref = useRef();
  const [position, setPosition] = useState('0% center');
  const [origin, setOrigin] = useState(null);
  useEffect(() => {}, []);
  const handleMove = (e) => {
    if (origin) setOrigin(e.nativeEvent.offsetX);
    let v = Math.round((e.nativeEvent.offsetX / 200) * 10);
    setPosition(`${7.14286 * v}% center`);
  };
  const handleOver = (e) => {
    setOrigin(null);
    setPosition(`0% center`);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 1000000000,
      }}
    >
      {/* <div ref={ref} 
      onPointerMove={handleMove} onPointerLeave={handleOver} 
      style={{
        height: '100%',     widows: '100%', backgroundPosition: position, backgroundSize: 'cover', 
        backgroundImage: `url("${dd}")`
      }}
      >

      </div> */}

      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: '#99999985',
        }}
      >
        <div>
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: fontSize }}
                spin
                color='gray'
              />
            }
          />
          <p />
          <div>
            <span> Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}
