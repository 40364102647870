import React from 'react';
/**
 * @param {React.Element} children - The text content of the label.
 * @returns {React.Element} The rendered label element.
 */
const PageHeader = ({ children }) => {
  return (
    <h3 class='text-lg font-bold text-black dark:text-white'>{children}</h3>
  );
};

export default PageHeader;
