import { Button, Popover, Upload } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { timelineStore } from '../../Timeline.store';
import ButtonGen from '../../../../../../gen/button/common/Button.gen';
import { v4 } from 'uuid';
import { DefaultColorTimeline } from '../../../../util/Timeline.util';

export default function TimelineConfigurationToolbar() {
  const {
    isOpenAppearanceDefinitions,
    setIsOpenAppearanceDefinitions,
    timelineAnimationControl,
    configurationData,
    setConfigurationData
  } = timelineStore();
  const handleAppearance = () => {
    setIsOpenAppearanceDefinitions(!isOpenAppearanceDefinitions);
  };
  useEffect(() => {
    if (timelineAnimationControl && configurationData) {
      console.log(configurationData);
      timelineAnimationControl.setSetting(configurationData);
    }
  }, [timelineAnimationControl, configurationData]);
  const handleAdd = () => {
    let name = window.prompt('Please, fill the name', 'New Appearance');
    if(name!==''){
      let clone =[...configurationData]
      clone.push(      {
        id: v4(),
        name: name,
        startAppearance: DefaultColorTimeline.None,
        endAppearance: DefaultColorTimeline.None,
        earlyAppearance: DefaultColorTimeline.None,
        lateAppearance: DefaultColorTimeline.None,
      })
      setConfigurationData(clone);
    }
  };
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: 5,
          }}
        >
          <div>
            <IconTextButton
              url={
                'https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/48/external-Import-files-tanah-basah-glyph-tanah-basah.png'
              }
              size='ssmall'
              onClick={handleAdd}
            >
              Add
            </IconTextButton>
          </div>
          <div>
            <ButtonGen onClick={handleAppearance} size='ssmall'>
              Appearance Definitions
            </ButtonGen>
          </div>
        </div>
      </div>
    </>
  );
}

// events.push(
//   gantt.attachEvent('onTaskClick', (id, e) => {
//     if (window.viewer) {
//       let task = gantt.getTask(id);
//       console.log(task);
//       window.viewer.search(task.id, onSuccessCallback, onErrorCallback, [
//         'Task ID',
//         'Activity ID',
//       ]);
//     }
//   })
// );
