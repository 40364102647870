// import { Tabs } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabItem from 'src/components/atoms/TabItem';
import LayoutCompany from 'src/components/layout/project_management/company/Company.layout';
import LayoutRole from 'src/components/layout/project_management/role/Role.layout';
import LayoutUser from 'src/components/layout/project_management/user/User.layout';
import Tabs from 'src/components/molecules/Tabs';
// import LayoutPermission from './Permission/Permission.layout';
export default function ProjectManagementNav() {
  const [search, setSearchParams] = useSearchParams();
  const projectId = search.get('projectId');
  const [type, setType] = useState(
    search.get('type') ? search.get('type') : 'user'
  );

  const newQueryParameters = new URLSearchParams();

  const handleChangeTab = (e) => {
    newQueryParameters.set('projectId', projectId);
    newQueryParameters.set('type', e);
    setSearchParams(newQueryParameters);
    setType(e);
  };

  return (
    <div
      style={{ width: '100%', padding: '0px 2px', height: 'calc(100% - 0px)' }}
    >
      <Tabs onTabChange={handleChangeTab} activeKey={type}>
        <TabItem title='User' key='user' />
        <TabItem title='Role' key='role' />
        <TabItem title='Company' key='company' />
      </Tabs>

      <div className='h-[calc(100%-95px)]'>
        {' '}
        {type === 'user' && <LayoutUser />}
        {type === 'role' && <LayoutRole />}
        {type === 'company' && <LayoutCompany />}
      </div>
    </div>
  );
}
