import React from 'react';
import WorkerListTable from '../table/WorkerList.table';
import WorkerListToolbar from './WorkerList.toolbar';

export default function WorkerListLayout() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 2,
        height: '100%',
        widthL: '100%',
        flexDirection: 'column',
      }}
    >
      <WorkerListToolbar />
      <WorkerListTable />
    </div>
  );
}
