import create from 'zustand'
import { COBieComponentType } from './constants/COBieComponentType'


const initialState = {
    type: COBieComponentType.List,
    cobieLoading:false,

 


}
export const cobieStore = create((set) => ({
    ...initialState,
    setType: (value) => set((state) => ({ type: value })),
    setCOBieLoading: (value) => set((state) => ({ cobieLoading: value })),
  

    resetCOBieStore: () => set(initialState)
}))