import { S3_BASED_PATH } from '../../../config';
import {
  generateS3Path,
  requestBimGrid,
} from '../../../functions/General.function';

export const rfaPath = (
  organizationId,
  projectId,
  refNumber,
  rev,
  type,
  name
) => {
  const path = `${refNumber}/${rev}/${type}/${name}`;
  return generateS3Path(organizationId, projectId, S3_BASED_PATH.DMS_RFA, path);
};

export const GetDMSRFACategoryWDiscipline = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/get-category-discipline',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetDMSRFARefByCategoryDiscipline = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/get-ref-by-category-discipline',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetDMSRFAByRev = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/get-rfa-by-rev',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CreateDMSRFA = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/dms-rfa/add-rfa', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateDMSRFA = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/dms-rfa/update-one',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateDMSRFARev = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/dms-rfa/update-rev-one',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetDMSRFAData = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/get-data',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetDMSRFAOne = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/get-one',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CheckDMSRFAByRefNumber = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/check-one-by-ref',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const DeleteDMSRFAOne = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/dms-rfa/delete-one',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetChidById = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/get-children-by-id',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetDMSMainDrawingChildrenById = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/dms-rfa/get-children-drawing',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CreateDMSRFARev = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/dms-rfa/add-rev-one',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
