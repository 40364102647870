import _ from 'lodash';
import { Raycaster } from 'three';
import { workerStore } from '../../../../store/Worker.store';
import icons from '../../../gen/constant/Icon.constant';
import { EventDispatcher } from '../extensions/utils/EventDispatcher';

const temp = [];
export default class HubDevicePicker extends EventDispatcher {
  name;
  raycaster = new window.THREE.Raycaster();
  enabled = false;
  viewer;
  camera;

  // material;
  devices;
  style;
  currentItem;

  mouse = new window.THREE.Vector2();
  onDownPosition = new window.THREE.Vector2();
  onUpPosition = new window.THREE.Vector2();
  onMovePosition = new window.THREE.Vector2();

  event = {
    add: 'add',
    update: 'update',
    remove: 'remove',
  };
  //
  constructor(viewer, name = 'HubDevicesPicker') {
    super();
    this.viewer = viewer;
    this.raycaster = new Raycaster();
    // this.raycaster.params.PointCloud.threshold = 0.1;
    this.camera = viewer.impl.camera;
    this.name = name;

    // const loader = new window.THREE.TextureLoader();
    // loader.setCrossOrigin('anonymous');
    // debugger;
    // loader.load(
    //   icons.hub_device,
    //   (t) => {
    //     console.log(t);
    //     // this.material = new window.THREE.MeshBasicMaterial({
    //     //   color: 'red',
    //     //   map: t,
    //     // });
    //     this.material = new window.THREE.SpriteMaterial({
    //       map: t,
    //       color: 'red',
    //     });
    //   },
    //   () => {},
    //   (e) => {
    //     console.log(e);
    //   }
    // );
  }
  init = async (data) => {
    this.devices = data;
    const dataVizExtn = await this.viewer.loadExtension(
      'Autodesk.DataVisualization'
    );
    const DataVizCore = window.Autodesk.DataVisualization.Core;
    dataVizExtn.removeAllViewables();
    this.style = new DataVizCore.ViewableStyle(
      DataVizCore.ViewableType.SPRITE,
      new window.THREE.Color(0xff0000),
      icons.hub_device,
      new window.THREE.Color(0xe0e0ff),
      'https://img.icons8.com/ios/50/joystick.png'
    );
    this.reGenerateDevice();
    this.viewer.addEventListener(
      DataVizCore.MOUSE_HOVERING,
      this.onHubDeviceHovering
    );
    this.viewer.addEventListener(
      DataVizCore.MOUSE_CLICK,
      this.onHubDeviceClick
    );
  };
  load = () => {
    // this.viewer.impl.createOverlayScene(this.name);
    // // this.viewer.impl.createOverlayScene(`add-${this.name}`);
    this.viewer.addEventListener(
      window.Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.handleChangeCamera
    );
  };
  unload = () => {
    // this.viewer.impl.removeOverlayScene(this.name);
    // // this.viewer.impl.removeOverlayScene(`add-${this.name}`);
    this.viewer.removeEventListener(
      window.Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.handleChangeCamera
    );
    const DataVizCore = window.Autodesk.DataVisualization.Core;
    this.viewer.addEventListener(
      DataVizCore.MOUSE_HOVERING,
      this.onHubDeviceHovering
    );
    this.viewer.addEventListener(
      DataVizCore.MOUSE_CLICK,
      this.onHubDeviceClick
    );
    this.dispose();
  };
  handleChangeCamera = () => {};

  setEnabled = (value, item) => {
    this.enabled = value;
    if (value) {
      this.viewer.canvas.addEventListener(
        'pointerdown',
        this.onPointerDownController,
        false
      );
      this.viewer.canvas.addEventListener(
        'pointermove',
        this.onPointerMoveController,
        false
      );
      this.currentItem = item;
    } else {
      debugger;
      this.dispose();
    }
  };
  getPointerPosition = (dom, x, y) => {
    const rect = dom.getBoundingClientRect();
    return [x - rect.x, y - rect.y];
  };
  onPointerDownController = (event) => {
    if (!this.enabled) {
      return true;
    }
    const array = this.getPointerPosition(
      this.viewer.canvas.parentElement,
      event.clientX,
      event.clientY
    );
    this.onDownPosition.fromArray(array);
    document.addEventListener('pointerup', this.onPointerUpController);
  };
  onPointerUpController = (event) => {
    if (!this.enabled) {
      return true;
    }
    const array = this.getPointerPosition(
      this.viewer.canvas.parentElement,
      event.clientX,
      event.clientY
    );
    this.onUpPosition.fromArray(array);
    this.handleClick(event);
    document.removeEventListener('pointerup', this.onPointerUpController);
  };

  hitTest = () => {
    return this.viewer.hitTest(this.onUpPosition.x, this.onUpPosition.y, true);
  };
  onPointerMoveController = (event) => {
    if (!this.enabled) {
      return true;
    }
    const array = this.getPointerPosition(
      this.viewer.canvas.parentElement,
      event.clientX,
      event.clientY
    );
    this.onMovePosition.fromArray(array);
  };
  handleClick = (event) => {
    event.preventDefault();
    if (!this.currentItem) {
      return true;
    }
    if (event.which !== 1) {
      return true;
    }
    if (this.onDownPosition.distanceTo(this.onUpPosition) === 0) {
      const intersect = this.hitTest();
      if (intersect) {
        // temp.push(intersect.point);
        // console.log(temp);
        this.addHubDevice(intersect.point);
      }
    }
  };
  generateHubDevice = (dbId, point) => {
    const DataVizCore = window.Autodesk.DataVisualization.Core;

    const viewable = new DataVizCore.SpriteViewable(point, this.style, dbId);
    return viewable;
  };
  removeHubDevice = () => {
    this.reGenerateDevice();
    this.dispatchEvent({
      type: this.event.remove,
      item: this.currentItem,
    });
  };
  addHubDevice = async (point) => {
    if (this.viewer) {
      if (this.currentItem.hubDeviceInBlockId) {
        this.dispatchEvent({
          type: this.event.update,
          point: point.toArray(),
        });
      } else {
        // this.currentItem.location = point.toArray();
        // this.reGenerateDevice();
        this.dispatchEvent({
          type: this.event.add,
          point: point.toArray(),
        });
      }

      return;
      // const geom = new window.THREE.SphereBufferGeometry(1, 32, 16);

      // const sphereMesh =
      //   // new window.THREE.Mesh(geom, this.material);
      //   new window.THREE.Sprite(this.material);
      // sphereMesh.position.copy(point);
      // sphereMesh.scale.set(100000, 100000, 100000);
      // if (!this.viewer.overlays.hasScene(this.name)) {
      //   this.viewer.overlays.addScene(this.name);
      // }
      // this.viewer.overlays.addMesh(sphereMesh, this.name);
    }
  };
  reGenerateDevice = async () => {
    const DataVizCore = window.Autodesk.DataVisualization.Core;
    const viewableData = new DataVizCore.ViewableData();
    viewableData.spriteSize = 24;
    const dataVizExtn = this.viewer.getExtension('Autodesk.DataVisualization');
    dataVizExtn.removeAllViewables();
    const dbIds = [];
    _.forEach(this.devices, async (v, k) => {
      if (v.location) {
        const dbId = k + 1;
        dbIds.push(dbId);
        const viewable = this.generateHubDevice(
          dbId,
          new window.THREE.Vector3().fromArray(v.location)
        );
        v.deviceId = dbId;
        viewableData.addViewable(viewable);
      }
    });

    await viewableData.finish();
    dataVizExtn.addViewables(viewableData);
  };

  onHubDeviceHovering = (event) => {
    console.log(event);
    const dataVizExtn = this.viewer.getExtension('Autodesk.DataVisualization');
    const item = this.devices.find((v) => {
      return v.deviceId === event.dbId;
    });
    if (event.hovering) {
      debugger;
      // const vector = new window.THREE.Vector3();
      // vector.project(this.viewer.getCamera());

      // // Convert the normalized device coordinates (NDC) to pixel coordinates
      // const widthHalf = 0.5 * this.viewer.canvas.width;
      // const heightHalf = 0.5 * this.viewer.canvas.height;

      // const x = vector.x * widthHalf + widthHalf;
      // const y = -(vector.y * heightHalf) + heightHalf;

      // return { x, y };
      // const spritesToUpdate = [];
      // dataVizExtn.viewableData.viewables.forEach((v) => {
      //   if (v.dbId === event.dbId) {
      //     spritesToUpdate.push(v.dbId);
      //   }
      // });
      dataVizExtn.invalidateViewables([event.dbId], (viewable) => {
        return {
          scale: 1.5, // Restore the viewable size
          // url: 'https://img.icons8.com/ios/50/joystick.png',
        };
      });
      workerStore
        .getState()
        .setHubDeviceSprite({ item, event: event.originalEvent });
    } else {
      dataVizExtn.invalidateViewables([event.dbId], (viewable) => {
        return {
          scale: 1.0, // Restore the viewable size
          url: icons.hub_device,
        };
      });
      workerStore.getState().setHubDeviceSprite();
    }
  };
  onHubDeviceClick = () => {};
  dispose = () => {
    if (this.viewer) {
      this.viewer?.canvas.removeEventListener(
        'pointerdown',
        this.onPointerDownController,
        false
      );
      this.viewer?.canvas.removeEventListener(
        'pointermove',
        this.onPointerMoveController,
        false
      );
    }
  };
}
