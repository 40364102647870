/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { qrElementStore } from '../QRElement.store';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import PropertySetting from './Property.item';
import { v4 } from 'uuid';
import HeaderProperty from './Property.header';
import { ViewerAPI } from '../../../../../../api';
import { message } from 'antd';

export default function ListProperty() {
  const [items, setItems] = useState([]);
  const { currentTemplate, setQRElementLoading } = qrElementStore();
  useEffect(() => {
    if (currentTemplate) {
      setItems(currentTemplate.properties);
    } else {
      setItems([]);
    }
  }, [currentTemplate]);

  const handleNewRow = async () => {
    let clone = [...items];
    clone.push({ name: '', property: '', id: v4() });
    setItems(clone);
  };
  const handleSave = async () => {
    setQRElementLoading(true);
    try {
      let temp = [];
      _.forEach(items, (v) => {
        if (v.name && v.property) {
          temp.push(v);
        }
      });
      let data = { id: currentTemplate.id, properties: temp };
      await ViewerAPI.QRElementAPI.Update({
        shareQRElementSettingData: data,
      });
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRElementLoading(false);
  };

  return (
    <>
      <div
        style={{
          width: '70%',
          display: 'flex',
          gap: 5,
          flexDirection: 'column',
          //   border: '1px solid black',
          padding: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 5,
            justifyContent: 'space-between',
          }}
        >
          {currentTemplate && (
            <>
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                }}
              >
                <span style={{ fontWeight: 'bolder', fontSize: 18 }}>
                  Name:
                </span>
                <span
                  style={{
                    fontWeight: 'bolder',
                    color: '#2b435f',
                    fontSize: 18,
                  }}
                >
                  {currentTemplate.name}
                </span>
              </div>
              <div>
                <IconTextButton
                  onClick={handleSave}
                  block={true}
                  size='ssmall'
                  type='save'
                  icon={'save'}
                >
                  Save
                </IconTextButton>
              </div>
            </>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            gap: 5,
            flexDirection: 'column',
            border: '1px solid black',
            overflow: 'auto',
            height: '100%',
            padding: 5,
          }}
        >
          <HeaderProperty />
          {items.map((i, k) => (
            <PropertySetting
              key={k}
              item={i}
              setItems={setItems}
              items={items}
            />
          ))}

          <IconTextButton
            onClick={handleNewRow}
            block={true}
            size='small'
            type='save'
            icon={'add'}
            disabled={!currentTemplate}
          >
            Add Row
          </IconTextButton>
        </div>
      </div>
    </>
  );
}
