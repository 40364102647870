import React from 'react';

const TAB_ITEM_STYLE = {
  active:
    'text-primary-600 border-b-2 border-primary-600 rounded-t-lg active dark:text-primary-500 dark:border-primary-500 group',
  disabled: 'text-gray-400 cursor-not-allowed dark:text-gray-500',
  normal:
    'border-transparent hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300',
};

/**
 * @param {string} title
 * @param {React.Element} icon
 * @param {string} active
 * @param {string} disabled
 * @param {Function} onClick
 * @returns {React.Element} The rendered input element.
 */
const TabItem = ({ icon, title, active, disabled, onClick }) => {
  const style = disabled
    ? TAB_ITEM_STYLE.disabled
    : active
      ? TAB_ITEM_STYLE.active
      : TAB_ITEM_STYLE.normal;
  return (
    <li className='me-2'>
      <div
        onClick={disabled ? undefined : onClick}
        className={`${style} group inline-flex cursor-pointer items-center justify-center rounded-t-lg border-b-2 p-4`}
      >
        {icon}
        {title}
      </div>
    </li>
  );
};

export default TabItem;
