import React from 'react';
import HorizontalDivider from 'src/components/atoms/HorizontalDivider';
import UserAccountDelete from 'src/components/molecules/UserAccountDelete';
import UserAccountEmail from 'src/components/molecules/UserAccountEmail';
import UserAccountInformation from 'src/components/molecules/UserAccountInformation';
import UserAccountPassword from 'src/components/molecules/UserAccountPassword';

/**
 * @param {React.Element} children
 * @returns {React.Element} The rendered input element.
 */
const UserAccountSetting = () => {
  return (
    <>
      <div className='pt-4'>
        <h1 className='py-2 text-2xl font-semibold'>Account settings</h1>
      </div>
      <HorizontalDivider />
      <UserAccountEmail />
      <HorizontalDivider />
      <UserAccountInformation />
      <HorizontalDivider />
      <UserAccountPassword />
      <HorizontalDivider />
      <UserAccountDelete />
    </>
  );
};

export default UserAccountSetting;
