import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import QRElementToolbar from './QRElement.toolbar';
import { qrElementStore } from './QRElement.store';
import QRElementSettingModal from './modal/QRElementSetting.modal';
import QRElementTree from './QRElement.tree';
import QRElementInformationModal from './modal/QRElementInformation.modal';

export default function QRElementLayout() {
  const ref = useRef();
  const { isShowSetting, resetQRElementStore ,currentElement} = qrElementStore();
  useEffect(()=>{
    return()=>{
      resetQRElementStore()
    }
  },[])
  return (
    <>
      <QRElementToolbar />
      <QRElementTree/>
      {isShowSetting && <QRElementSettingModal />}
      {currentElement && <QRElementInformationModal />}
    </>
  );
}
