import React from 'react';
import { Tag } from 'antd';

export default function VRModelStatusCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  return (
    <Tag
      style={{
        backgroundColor: cellValue === 'pending' ? 'orange' : 'green',
        color: 'white',
      }}
    >
      {cellValue.toUpperCase()}
    </Tag>
  );
}
