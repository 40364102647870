
export const buildSql = (request, projectId) => {
    console.log("buildSql", request);
    return (
        selectSql(request) +
        " FROM dms_rfa" +
        whereSql(request, projectId) +
        groupBySql(request) +
        orderBySql(request) +
        limitSql(request)
    );
}
const groupBySql = (request) => {
    var rowGroupCols = request.rowGroupCols;
    var groupKeys = request.groupKeys;

    if (isDoingGrouping(rowGroupCols, groupKeys)) {
        var rowGroupCol = rowGroupCols[groupKeys.length];

        return " GROUP BY " + rowGroupCol.id + " HAVING count(*) > 0";
    }

    return "";
}
const whereSql = (request, projectId) => {
    var rowGroups = request.rowGroupCols;
    var groupKeys = request.groupKeys;
    var whereParts = [];

    if (groupKeys) {
        groupKeys.forEach(function (key, i) {
            var value = typeof key === "string" ? "'" + key + "'" : key;

            whereParts.push(rowGroups[i].id + " = " + value);
        });
    }

    let tmp = ` project_id = '${projectId}' AND is_deleted = ${false}`
    if (whereParts.length > 0) {
        return " WHERE " + whereParts.join(" AND ") +
            ` AND ${tmp}`
    }

    return ` WHERE ${tmp}`;
}
const orderBySql = (request) => {
    var sortModel = request.sortModel;

    if (sortModel.length === 0) return "";

    var sorts = sortModel.map(function (s) {
        return s.colId + " " + s.sort.toUpperCase();
    });

    return " ORDER BY " + sorts.join(", ");
}

const selectSql = (request) => {
    var rowGroupCols = request.rowGroupCols;
    var valueCols = request.valueCols;
    var groupKeys = request.groupKeys;

    if (isDoingGrouping(rowGroupCols, groupKeys)) {
        var rowGroupCol = rowGroupCols[groupKeys.length];
        var colsToSelect = [rowGroupCol.id];

        valueCols.forEach(function (valueCol) {
            colsToSelect.push(
                valueCol.aggFunc + "(" + valueCol.id + ") AS " + valueCol.id
            );
        });

        return "SELECT " + colsToSelect.join(", ");
    }

    return "SELECT *";
}
const isDoingGrouping = (rowGroupCols, groupKeys) => {
    // we are not doing grouping if at the lowest level
    return rowGroupCols.length > groupKeys.length;
}
export const limitSql = (request) => {
    if (request.endRow === undefined || request.startRow === undefined) {
        return "";
    }

    var blockSize = request.endRow - request.startRow;
    return " LIMIT " + blockSize + " OFFSET " + request.startRow;
}