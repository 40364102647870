import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import InvitationCell from 'src/components/layout/admin/management/user/cell/Invitation.cell';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';
import IconButton from '../../../../../gen/button/IconButton.gen';

export default function UserTable({ data, setGridApi, setRowNode }) {
  const { setIsLoading } = appStore();
  const gridRef = useRef();
  const { organizationId, projectId } = useParams();

  const handleRemove = async (e) => {
    if (!window.confirm('Are you sure to remove?')) return;
    setIsLoading(true);
    let repData = await requestBimGrid(
      'delete',
      '/api/admin/organization/removeUserFromOrganization',
      { ...e.node.data },
      { organizationId, projectId }
    );
    if (repData) {
      await updateTableAsync(gridRef.current.api, { remove: [e.node.data] });
      gridRef.current.api.redrawRows();
    }
    setIsLoading(false);
  };

  const [columns] = useState([
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agSetColumnFilter',
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: () => {
              return (
                <span style={{ fontWeight: 'bold' }}> {params.value}</span>
              );
            },
          };
        }
        return undefined;
      },
    },

    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      minWidth: 100,
      maxWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: InvitationCell,
        };
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 70,
      maxWidth: 70,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ]);
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleRemove.bind(this, params)}
        />
      </div>
    );
  };

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params);
    },
    [setGridApi]
  );
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          onGridReady={onGridReady}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          suppressPaginationPanel={true}
          groupDefaultExpanded={2}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
