import axios from 'axios';
import { getForgeToken } from 'src/functions/Auth.function';
import { requestBimGrid } from 'src/functions/General.function';

export const GetForgeItemStatus = async ({ objectId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getForgeToken();
      let res = await axios.get(
        'https://developer.api.autodesk.com/modelderivative/v2/designdata/' +
          btoa(objectId) +
          '/manifest',
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        }
      );
      resolve(res);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const TranslateForgeItem = async ({ versionId, objectId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/forge/modelderivative/jobs',
        { id: versionId, objectId: objectId }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const TranslateForgeItemDirectly = async ({ versionId, objectId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/forge/modelderivative/jobs-directly',
        { objectId }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
