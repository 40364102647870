import React, { useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { colorCategory, colorStatus } from '../constants/Issue.constant';
import { issueStore } from '../Issue.store';
import { IssueComponentType } from '../constants/IssueComponentType';


export default function IssueCard({ data  }) {
    const { setIssueDetail ,setType} = issueStore()

    useEffect(() => {

    }, [])


    const handleViewIssue = () => {
        setIssueDetail(data)
        setType(IssueComponentType.Detail)
    }
    return (
        <>
            <div className='issue-card' 
                onClick={handleViewIssue}
            >
                <div style={{ display: 'flex', gap: 5, justifyContent: 'space-between', flexWrap: 'wrap' }} >
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }} >
                        <div style={{ padding: '0px 10px', backgroundColor: colorCategory[data.category], borderRadius: 10, display: 'flex', justifyContent: 'center' }}>
                            <span style={{ fontWeight: 'bold' }} >    {`Issue #${data.issue_no}`}</span>
                        </div>
                        <div style={{ padding: '0px 10px', backgroundColor: 'lightgray', borderRadius: 10, display: 'flex', justifyContent: 'center' }}>
                            <span style={{ fontWeight: 'bold' }} >    {`${data.type}`}</span>
                        </div>
                    </div>
                    <div>
                        <div style={{ padding: '0px 10px', backgroundColor: colorStatus[data.status], borderRadius: 10, display: 'flex', justifyContent: 'center' }}>
                            <span style={{ fontWeight: 'bold', color:'white' }} >    {`${data.status}`}</span>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: 5, overflow: 'hidden', textOverflow: 'ellipsis' }} >
                    <span style={{ fontWeight: 'bold' }} >Title:</span>
                    <span>{data?.title}</span>
                </div>

                <div style={{ display: 'flex', gap: 5, overflow: 'hidden', textOverflow: 'ellipsis' }} >
                    <span style={{ fontWeight: 'bold' }} >Description:</span>
                    <span>{data.description}</span>
                </div>

                <div style={{ display: 'flex', gap: 5 }} >
                    <span style={{ fontWeight: 'bold' }} >Due date:</span>
                    <span>{moment(data.due_date).format('DD/MM/YYYY')}</span>
                </div>
                <div style={{ display: 'flex', gap: 5 }} >
                    <span style={{ fontWeight: 'bold' }} >Created by:</span>
                    <span>{data.created_by}</span>
                </div>


            </div>

        </>

    );
}


