import React, { useCallback, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { appStore } from '../../../../../../store/App.store';
import { message } from 'antd';
import IconButton from '../../../../../gen/button/IconButton.gen';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { workerStore } from '../../../../../../store/Worker.store';
import DateCell from '../../../../../ag_grid_components/cell/Date.cell';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { AppAPI } from '../../../../../api';
import { useParams } from 'react-router-dom';

export default function SuperviorListTable() {
  const { setIsLoading } = appStore();
  const {
    setSuperviorListGridApi,
    superviorListGridApi,
    setIsOpenEditSuperviorList,
    setNodeEditSuperviorList,
    dataSuperviorList,
    setDataSuperviorList,
  } = workerStore();
  const gridRef = useRef();
  const { projectId } = useParams();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const superviorListRes =
        await AppAPI.WorkerSettingAPI.GetAllSuperviorListByProject({
          project_id: projectId,
        });
      setDataSuperviorList(superviorListRes);
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      await AppAPI.WorkerSettingAPI.DeleteSuperviorList({
        id: e.node.data.id,
      });
      await updateTableAsync(superviorListGridApi.api, {
        remove: [e.node.data],
      });
      debugger;
    } catch (ex) {}
    setIsLoading(false);
  };
  const handleEditItem = async (e) => {
    setNodeEditSuperviorList(e.node);
    setIsOpenEditSuperviorList(true);
  };
  const columns = [
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Report to',
      field: 'report_to_email',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Designation',
      field: 'designation',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Nationality',
      field: 'nationality',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleEditItem.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setSuperviorListGridApi(params);
  }, []);

  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={dataSuperviorList}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
