import React, { useEffect, useState } from 'react';
import { ReactComponent as SpinnerIcon } from 'src/assets/icons/loader/spinner.svg';
import { validatePermission } from 'src/functions/Auth.function';
import { appStore } from 'src/store/App.store';

const BUTTON_STYLE = {
  primary: `bg-primary-600 hover:bg-primary-700 
       focus:ring-primary-300 dark:bg-primary-600
       dark:hover:bg-primary-700 dark:focus:ring-primary-800
       text-white`,
  delete: `bg-delete-600 hover:bg-delete-700 
       focus:ring-delete-300 dark:bg-delete-600
       dark:hover:bg-delete-700 dark:focus:ring-delete-800
       text-white`,
  normal: `bg-white hover:bg-gray-100 
       focus:ring-white dark:bg-white
       dark:hover:bg-gray-100 dark:focus:ring-gray-100
       border border-gray-400 text-black
       `,
  disabled: 'text-white bg-gray-400 dark:bg-gray-400 cursor-not-allowed',
};
/**
 * @param {React.Element | null} children
 * @param {boolean} isFull
 * @param {boolean} isLoading
 * @param {Function} onClick
 * @param {string} type
 * @param {string} className
 * @param {boolean} disabled
 * @returns {React.Element}
 */
const PrimaryButton = ({
  children,
  isFull = false,
  isLoading,
  onClick,
  type = 'primary',
  className = '',
  disabled,

  requireActions = [],
  containActions = [],
}) => {
  const { permission, organization, user } = appStore();
  const full = isFull ? 'w-full' : 'w-auto';
  const style = disabled ? BUTTON_STYLE.disabled : BUTTON_STYLE[type];
  const [allowed, setIsAllowed] = useState();

  useEffect(() => {
    const { allowed } = validatePermission(
      permission,
      requireActions,
      containActions
    );
    setIsAllowed(allowed);
  }, [permission]);
  if (!allowed) {
    return null;
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${className} ${full} flex items-center justify-center gap-1 rounded-lg px-5 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4 ${style} `}
    >
      {isLoading ? (
        <>
          <SpinnerIcon className='me-3 inline h-4 w-4 animate-spin text-white' />
          Loading ...
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default PrimaryButton;
