import React from 'react';
import VRCanvas from '../vr_scene/VRCanvas';

export default function VRDesktop() {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <VRCanvas />
    </div>
  );
}
