/* eslint-disable no-unused-vars */
import { DatePicker as DatePickerDayjs, message, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import { AppAPI } from '../../../../../api';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);

export default function ModalEditWorkerDaily({ isTracking }) {
  const {
    setIsOpenEditWorkerDaily,
    workerDailyGridApi,
    nodeEditWorkerDaily,
    trackingGridApi,
  } = workerStore();
  const { setIsLoading } = appStore();
  const { projectId } = useParams();
  const [selectItems, setSelectItems] = useState({});
  const [data, setData] = useState({
    supervior_id: null,
    trade: null,
    start_at: null,
    end_at: null,
    work_time_id: null,
    block_id: null,
  });
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const superviorRes =
        await AppAPI.WorkerSettingAPI.GetAllSuperviorListByProject({
          projectId,
          select: ['name', 'id'],
        });
      selectItems['supervior'] = superviorRes;
      const tradeRes = await AppAPI.WorkerSettingAPI.GetAllTrade({
        projectId,
        select: ['trade', 'id'],
      });
      selectItems['trade'] = tradeRes;
      const workTimeRes = await AppAPI.WorkerSettingAPI.GetAllWorkTimeByProject(
        {
          projectId,
          select: ['time_type', 'id'],
        }
      );
      selectItems['work_time'] = workTimeRes;

      const blockZoneRes =
        await AppAPI.WorkerSettingAPI.GetAllBlockZoneByProject({
          projectId,
          select: ['block', 'level', 'zone', 'id'],
        });
      blockZoneRes.forEach((v) => {
        v.name = `${v.block} - ${v.level} - ${v.zone} `;
      });
      selectItems['block'] = blockZoneRes;

      setSelectItems({ ...selectItems });

      _.forEach(data, (v, k) => {
        if (nodeEditWorkerDaily.data[k]) {
          data[k] = nodeEditWorkerDaily.data[k];
        }
      });
      data['start_at'] = data['start_at']
        ? moment(data['start_at'])
        : moment(nodeEditWorkerDaily.data.date).startOf('day').add(8, 'hours');
      data['end_at'] = data['end_at']
        ? moment(data['end_at'])
        : moment(nodeEditWorkerDaily.data.date).startOf('day').add(17, 'hours');
      setData({ ...data });
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenEditWorkerDaily(false);
  };

  const handleOk = async (e) => {
    let check = true;
    const tmp = ['supervior_id', 'trade', 'start_at', 'end_at', 'work_time_id'];
    _.forEach(tmp, (v) => {
      if (!data[v]) {
        message.warning(`Please fill all input`);
        check = false;
        return false;
      }
    });
    if (!check) {
      return;
    }
    debugger;
    setIsLoading(true);
    try {
      data.worker_id = nodeEditWorkerDaily.data.worker_id;
      const res = await AppAPI.WorkerDailyAPI.UpdateOneWorkerDaily({
        id: nodeEditWorkerDaily.data.id,
        data: data,
      });

      _.forEach(nodeEditWorkerDaily.data, (v, k) => {
        if (res[k]) {
          nodeEditWorkerDaily.data[k] = res[k];
        }
      });
      debugger;
      if (isTracking) {
        await updateTableAsync(trackingGridApi.api, {
          update: [nodeEditWorkerDaily.data],
        });
      } else {
        await updateTableAsync(workerDailyGridApi.api, {
          update: [nodeEditWorkerDaily.data],
        });
      }

      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };

  const handleChangeSelect = (type, e) => {
    data[type] = e;
    setData({ ...data });
  };
  const handleDate = (type, e) => {
    data[type] = e;
    setData({ ...data });
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Edit worker daily'}
        style={{ width: 500 }}
      >
        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Start at'}: `}</span>
          <DatePicker.TimePicker
            value={data.start_at}
            picker='time'
            allowClear={false}
            onChange={handleDate.bind(this, 'start_at')}
            format='HH:mm'
          />
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'End at'}: `}</span>
          <DatePicker.TimePicker
            value={data.end_at}
            allowClear={false}
            onChange={handleDate.bind(this, 'end_at')}
            format='HH:mm'
          />
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Supervior'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'supervior_id')}
            value={data?.supervior_id}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['supervior']?.map((v) => (
              <Select.Option name={v.email} value={v.id}>
                {' '}
                {v.email}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Trade'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'trade')}
            value={data?.trade}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['trade']?.map((v) => (
              <Select.Option name={v.trade} value={v.trade}>
                {' '}
                {v.trade}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Work type'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'work_time_id')}
            value={data?.work_time_id}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['work_time']?.map((v) => (
              <Select.Option name={v.time_type} value={v.id}>
                {' '}
                {v.time_type}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Location'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'block_id')}
            value={data?.block_id}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['block']?.map((v) => (
              <Select.Option name={v.name} value={v.id}>
                {' '}
                {v.name}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>
      </ModalTemplate>
    </>
  );
}
