import React from 'react';
import { ReactComponent as InfoCircleIcon } from 'src/assets/icons/general/info-circle.svg';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import NotificationModal from 'src/components/molecules/NotificationModal';

/**
 * @param {function} onSendEmail
 *  @param {function} onClose
 * @param {string} name
 * @returns {React.Element} The rendered input element.
 */
const UserInvationModal = ({ name, onSendEmail, onClose }) => {
  return (
    <NotificationModal>
      <div className='pad flex items-center justify-center text-primary-400 dark:text-primary-800'>
        {' '}
        <InfoCircleIcon className='size-10 text-gray-600 dark:text-gray-800' />
      </div>

      <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
        {`This email: `}
        <span className='font-bold text-primary-600 dark:text-white'>
          {name}
        </span>
        {` is not register. Do you want send a invitation email?`}
      </h3>
      <div className='flex items-center justify-center gap-4'>
        <PrimaryButton className='px-9' type='normal' onClick={onClose}>
          {' '}
          Cancel
        </PrimaryButton>
        <PrimaryButton className='px-9' onClick={onSendEmail}>
          {' '}
          Send Invatation
        </PrimaryButton>
      </div>
    </NotificationModal>
  );
};

export default UserInvationModal;
