import { requestBimGrid } from '../../../functions/General.function';

export const GetAllWorkerTrackingByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/tracking/get-all-worker-tracking-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllWorkerTrackingByProjectDate = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/tracking/get-all-worker-tracking-by-project-date',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetTrackingTimeByWorkerId = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/tracking/get-tracking-time-by-worker-id',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getWorkerTrackingRealtime = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/tracking/get-worker-tracking-realtime',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetHubDeviceStatus = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/tracking/get-hub-devices-status',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      console.log(ex);
      reject(ex);
    }
  });
};

export const GetWorkerTrackingPosition = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/tracking/get-worker-tracking-position',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      console.log(ex);
      reject(ex);
    }
  });
};
