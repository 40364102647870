import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as PlusCircleIcon } from 'src/assets/icons/general/plus-circle.svg';
import PageHeader from 'src/components/atoms/PageHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { requestBimGrid } from '../../../../functions/General.function';
import { appStore } from '../../../../store/App.store';
import PermissionTable from './AppPermission.table';
import ModalAddPermission from './modal/ModalAddPermission';
import ModalEditPermission from './modal/ModalEditPermission';

export default function LayoutPermission() {
  const { organizationId, projectId } = useParams();
  const { setIsLoading } = appStore();
  const [data, setData] = useState([]);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [rowNode, setRowNode] = useState();
  const [gridApi, setGridApi] = useState();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let reqPermission = await requestBimGrid(
      'get',
      '/api/permission/getAll',
      null,
      { organizationId, projectId }
    );
    if (reqPermission) {
      setData(reqPermission);
    }
    setIsLoading(false);
  };
  const handleOpenAdd = () => {
    setIsModalAdd(true);
  };

  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader>App Permission</PageHeader>
          </>
        }
        right={
          <>
            <PrimaryButton onClick={handleOpenAdd}>
              <PlusCircleIcon className='size-4' />
              Add
            </PrimaryButton>
          </>
        }
      />

      <PermissionTable
        data={data}
        setGridApi={setGridApi}
        setRowNode={setRowNode}
      />

      {isModalAdd && (
        <ModalAddPermission gridApi={gridApi} setClose={setIsModalAdd} />
      )}
      {rowNode && (
        <ModalEditPermission
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
