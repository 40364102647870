import _ from "lodash";
import { getHitPoint, pointerToRaycaster } from "../../../../functions/Viewer.function";
import { EventDispatcher } from "../extensions/utils/EventDispatcher";
import { message } from "antd";
import { viewerOptions } from "../Viewer.constant";



const Autodesk = window.Autodesk
const avp = Autodesk.Viewing.Private;
const THREE = window.THREE
export default class LoadModel extends EventDispatcher {
    viewer = null
    files = []
    event = {
        finish: 'finish'
    };
    count = 0
    constructor(viewer) {
        super()
        this.viewer = viewer
    }

    load = async (files) => {
        this.files = files
        this.count = files.length
        if (this.count === 0) {
            this.dispatchEvent({
                type: this.event.finish,

            });
        }
        else {
            _.forEach(files,v => {
                Autodesk.Viewing.Document.load('urn:' + btoa(v.objectId),
                    this.onDocumentLoadSuccess.bind(this, v),
                    this.onDocumentLoadFailure.bind(this, v))
            })
        }

    }
    unload = () => {
        this.viewer = null
        this.files = []
    }
    onDocumentLoadSuccess = async (v, doc) => {
        let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
        if (view3d.length !== 0) {
            const svfUrl = doc.getViewablePath(view3d[0]);
            const aecModelData = await doc.downloadAecModelData()
            const globalOffset = this.viewer.model.getData().globalOffset
            const loadOptions = {
                ...viewerOptions, ...{
                    fileId: v.fileId, versionId: v.versionId, guid: view3d[0].data.guid,
                    modelNameOverride: v.fileName, aecModelData: aecModelData,globalOffset
                }
            }
            this.viewer.loadModel(svfUrl, loadOptions, this.onLoadModelSuccess.bind(this), this.onLoadModelError.bind(this))
        }

    }
    onDocumentLoadFailure = (doc) => {
        message.warning('Cannot load this file')
        this.onFinish()
    }
    onLoadModelSuccess = () => {
        this.onFinish()
    }
    onLoadModelError = () => {
        this.onFinish()
    }
    onFinish = () => {
        this.count--
        if (this.count === 0) {
            this.viewer.waitForLoadDone()
                .then(async () => {
                    this.dispatchEvent({
                        type: this.event.finish,

                    });
                })
        }
    }
}
