import React, { useCallback, useRef } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  autoGroupColumnDef,
  defaultColDef,
  dateFilterParams,
} from '../../../../../../../settings/General.settings';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import DateCell from '../../../../../../ag_grid_components/cell/Date.cell';

import { updateTableAsync } from '../../../../../../../functions/General.function';
import { message } from 'antd';
import { ViewerAPI } from '../../../../../../api';
import { dfmaStore } from '../DFMA.store';
import { viewerStore } from '../../../../../../../store/Viewer.store';

export default function DataTabTable() {
  const { setDFMALoading, setGridApiDataTabTable, parameters,
    rowDataTab, setCurrentRow, gridApiDataTabTable } =
    dfmaStore();
  const { treeModelData, viewer } = viewerStore()
  const gridRef = useRef();
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      minWidth: 120,
      sort: 'asc'
    },
    {
      headerName: 'Category',
      field: 'category',
      filter: 'agSetColumnFilter',
      minWidth: 120,
      sort: 'asc'
    },
    {
      headerName: 'Length',
      field: 'length',
      filter: 'agSetColumnFilter',
      maxWidth: 100,
      minWidth: 100,
    },
    {
      headerName: 'Height',
      field: 'height',
      filter: 'agSetColumnFilter',
      maxWidth: 100,
      minWidth: 100,
    },
    {
      headerName: 'Width',
      field: 'width',
      filter: 'agSetColumnFilter',
      maxWidth: 100,
      minWidth: 100,
    },
    {
      headerName: 'Volume',
      field: 'volume',
      filter: 'agSetColumnFilter',
      maxWidth: 100,
      minWidth: 100,
    },
    {
      headerName: 'Rebar Weight',
      field: 'rebar_weight',
      filter: 'agSetColumnFilter',
      maxWidth: 120,
      minWidth: 120,
    },
    {
      headerName: 'Casting Date',
      field: 'casting_date',
      filter: 'agDateColumnFilter',
      maxWidth: 150,
      minWidth: 150,
      filterParams: dateFilterParams,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Delivery Date',
      field: 'delivery_date',
      filter: 'agDateColumnFilter',
      maxWidth: 150,
      minWidth: 150,
      filterParams: dateFilterParams,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Install Date',
      field: 'install_date',
      filter: 'agDateColumnFilter',
      maxWidth: 150,
      minWidth: 150,
      filterParams: dateFilterParams,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 100,
      maxWidth: 100,
      suppressMenu: true,
      suppressSorting: true,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];


  const handleDelete = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setDFMALoading(true);
    try {
      await ViewerAPI.DFMAAPI.DeleteAll({ ids: [e.data.id] })
      await updateTableAsync(gridApiDataTabTable.api, { remove: [e.data] })
    } catch (ex) {
      message.warning(ex.message);
    }
    setDFMALoading(false);
  };
  const handleEdit = async (e) => {
    setCurrentRow(e)
  };
  const handleSelect = (e) => {
    if (parameters.length === 0 || !parameters) {
      alert('You need setup parameter before import')
      return
    }
    let parameter = _.find(parameters, v => v.name === 'Name')
    if (parameter.value) {
      let nodes = treeModelData.findNodesByParameter(parameter.value, e.data.name);
      debugger
      if (nodes.length !== 0) {
        let select = {}
        _.forEach(nodes, node => {
          if (!select[node.modelId]) {
            select[node.modelId] = []
          }
          select[node.modelId].push(node.dbId)
        })
        let result = []
        const models = viewer.impl.modelQueue().getModels();
        _.forEach(select, (v, k) => {
          let index = _.findIndex(models, i => i.id == k)
          if (index >= 0) {
            result.push({ model: models[index], ids: v })
          }
        })
        viewer.impl.selector.setAggregateSelection(result)
      }
    }

  }
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon='select'
          size='sssmall'
          type='save'
          onClick={handleSelect.bind(this, params)}
        />
        <IconButton
          icon='edit'
          size='sssmall'
          type='edit'
          onClick={handleEdit.bind(this, params)}
        />
        <IconButton
          icon='delete'
          size='sssmall'
          type='delete'
          onClick={handleDelete.bind(this, params)}
        />
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    setGridApiDataTabTable(params);
  }, []);
  return (
    <>
      <div style={{ width: '100%', height: '100%', padding: 5 }}>
        <div
          style={{ width: '100%', height: '100%' }}
          className='ag-theme-alpine'
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowDataTab}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows={true}
            onGridReady={onGridReady}
            groupDisplayType='multipleColumns'
            // rowGroupPanelShow='always'
            rowHeight={35}
            // pagination={true}
            // paginationPageSize={pageSizeModuleList}
            suppressPaginationPanel={true}
            context={false}
            suppressContextMenu={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
}
