import React from 'react';
import BlockZoneTable from '../table/BlockZone.table';
import BlockZoneToolbar from './BlockZone.toolbar';

export default function BlockZoneLayout() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 2,
        height: '100%',
        widthL: '100%',
        flexDirection: 'column',
      }}
    >
      <BlockZoneToolbar />
      <BlockZoneTable />
    </div>
  );
}
