/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Radio, Select, message } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { timelineStore } from '../Timeline.store';
import TableMappingExcel from '../table/MappingExcel.table';
import { toSnakeCase } from '../../../util/Gantt.util';
import { v4 } from 'uuid';
const gantt = window.gantt;

export default function FieldSelector() {
  const {
    setIsOpenFieldSelector,
    excelFile,
    timelineGantt,
    setDataSourceList,
    dataSourceList,
    setExcelFile
  } = timelineStore();
  const [dateFormat, setDateFormat] = useState('A');
  const [rowOneHeading, setRowOneHeading] = useState(true);
  const [dateFormatString, setDateFormatString] = useState('DD/MM/YYYY');
  const [mappingData, setMappingData] = useState();
  const [externalData, setExternalData] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [excelData, setExcelData] = useState();
  const close = () => {
    setExcelFile()
    setIsOpenFieldSelector();
  };

  const handleOk = async (e) => {
    var mapping = {};
    gridApi.api.forEachLeafNode((node) => {
      if (node.data.external) {
        mapping[node.data.external] = node.data.label;
      }
    });

    var ganttDataset = {
      data: [],
      links: [],
    };

    excelData.forEach(function (item) {
      var copy = {};
      for (var i in item) {
        if (mapping[i]) {
          copy[mapping[i]] = item[i];
        } else {
          copy[toSnakeCase(i)] = item[i];
        }
        copy.dbIds=[]
        copy.rule=''
        copy.total_cost=0
        copy.open = true;
        copy.visible= true
        if (copy.wbs) {
          var wbs = copy.wbs + '';
          copy.id = wbs;
          var parts = wbs.split('.');
          parts.pop();
          copy.parent = parts.join('.');
        }
      }
      ganttDataset.data.push(copy);
    });
    if (ganttDataset.data.length === 0) {
      message.warning('Cannot parse data');
      return;
    }
    let excelFileName =excelFile.name.split('.')
    let name = window.prompt('Please input the name',excelFileName[0] );
    let clone =[...dataSourceList]
    clone.push({id:v4(), name, data:ganttDataset, type:'excel'})
    setDataSourceList(clone);
    console.log(ganttDataset);
    close()
    // timelineGantt.clearAll();
    // timelineGantt.parse(ganttDataset);
  };

  useEffect(() => {
    if (excelFile) {
      handleLoadGantt();
    } else {
      close();
    }
  }, [excelFile]);
  const handleLoadGantt = () => {
    timelineGantt.importFromExcel({
      server: 'https://export.dhtmlx.com/gantt',
      data: excelFile,
      callback: function (project) {
        if (project) {
          let data = [
            { column: 'Wbs', external: '', label: 'wbs' },
            { column: 'Task name', external: '', label: 'text' },
            {
              column: 'Planned Start Date',
              external: '',
              label: 'start_date',
              isDate: true,
            },
            {
              column: 'Planned End Date',
              external: '',
              label: 'end_date',
              isDate: true,
            },
            {
              column: 'Actual Start Date',
              external: '',
              label: 'actual_start',
              isDate: true,
            },
            {
              column: 'Actual End Date',
              external: '',
              label: 'actual_end',
              isDate: true,
            },
            { column: 'Material Cost', external: '', label: 'material_cost' },
            { column: 'Labor Cost', external: '', label: 'labor_cost' },
            { column: 'Equipment Cost', external: '', label: 'equipment_cost' },
            { column: 'Task Type', external: '', label: 'task_type' },
          ];
          let temp = [''];
          if (project.length !== 0) {
            let firstItem = project[0];
            _.forEach(firstItem, (v, k) => {
              temp.push(k);
            });
          }
          setExternalData(temp);
          setMappingData(data);
          setExcelData(project);
        }
      },
    });
  };
  const handleChangeRadio = (type, e) => {
    setDateFormat(e.target.value);
  };
  const handleCheckbox = (e) => {
    setRowOneHeading(e.target.checked);
  };
  const handleInput = (e) => {
    setDateFormatString(e.target.value);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Field Selector'}
        isFullscreen={true}
        // styleBackDrop={{ position: 'abo' }}
        style={{ top: 'auto', width: '500px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          {/* <Checkbox onChange={handleCheckbox} checked={rowOneHeading}>
            Row 1 contains headings
          </Checkbox> */}
          {/* <Radio.Group
            value={dateFormat}
            onChange={handleChangeRadio.bind(this, 'dateFormat')}
          >
            <Radio value={'A'}>Automatic detect date/time format</Radio>
            <Radio value={'B'}>Use specific date/time format</Radio>
          </Radio.Group> */}

          {/* <Input
            value={dateFormatString}
            onChange={handleInput}
            disabled={dateFormat === 'A'}
          />
          <Select disabled={dateFormat === 'A'}>
            <Select.Option></Select.Option>
            <Select.Option></Select.Option>
            <Select.Option></Select.Option>
          </Select> */}

          <div style={{ height: 300 }}>
            <TableMappingExcel
              dataTable={mappingData}
              setGridApi={setGridApi}
              externalData={externalData}
            />
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
