import React, { useEffect } from "react";
import DMSRFATable from "./table/DMSRFA.table";
import DMSRFAToolbar from "./DMSRFA.toolbar";

import { dmsStore } from "../../../../../store/DMS.store";
import ModalDMSRFAAddRFA from "./modal/ModalDMSRFAAddRFA";
import { AppAPI } from "../../../../api";
import { appStore } from "../../../../../store/App.store";
import { message } from 'antd'
import ModalDMSRFAValidate from "./modal/ModalDMSRFAValidate";
import ModalDMSRFAView from "./modal/ModalDMSRFAView";
import ModalDMSRFAViewRFA from "./modal/ModalDMSRFAViewRFA";
import ModalDMSRFAEdit from "./modal/ModalDMSRFAEdit";
export default function DMSRFALayout() {

    const { isModalDMSRFAAddRFA, setGroupsOfUser,
        selectedDraingNodeValidate, isOpenRFAValidate,
        isOpenRFAViewRev, setSelectedRFARevNode,
        isModalDMSRFAViewRFA, isModalDMSRFAEditRFA } = dmsStore()
    const { user, setIsLoading } = appStore()
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            const groupUser = await AppAPI.DMSettingAPI.GetDMSSettingGroupByUser({ userId: user.id })
            setGroupsOfUser(groupUser)
        } catch (ex) {
            if (ex.message)
                message.warning(ex.message)
        }
        setIsLoading(false)
    }
    return (
        <>
            <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }} >
                <div style={{ height: '100%', width: '100%', display: "flex", flexDirection: 'column', gap: 5 }} >
                    <DMSRFAToolbar />
                    <DMSRFATable />
                </div>

            </div>


            {isModalDMSRFAAddRFA && <ModalDMSRFAAddRFA />}
            {isModalDMSRFAViewRFA && <ModalDMSRFAViewRFA />}
            {isModalDMSRFAEditRFA && <ModalDMSRFAEdit />}
            {isOpenRFAValidate && selectedDraingNodeValidate && <ModalDMSRFAValidate />}
            {isOpenRFAViewRev && setSelectedRFARevNode && <ModalDMSRFAView />}
        </>


    );
}


