import React, { useEffect, useState } from "react";
import { message } from 'antd';
import _ from "lodash";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import logo_rvt from '../../../../../../image/logo_rvt.svg'
import logo_nwd from '../../../../../../image/logo_nwd.svg'
import logo_nwc from '../../../../../../image/logo_nwc.svg'
import logo_ifc from '../../../../../../image/logo_ifc.svg'
import logo_blank from '../../../../../../image/logo_blank.svg'
import logo_dwf from '../../../../../../image/logo_dwf.svg'
import { requestBimGrid } from "../../../../../../functions/General.function";
import { appStore } from "../../../../../../store/App.store";
import { fileStatus } from "../../../../../../constant/Status.enum";

const icon = {
    rvt: logo_rvt,
    nwd: logo_nwd,
    nwc: logo_nwc,
    ifc: logo_ifc,
    blank: logo_blank,
    dwfx: logo_dwf,
    dwf: logo_dwf
}
const mapping = {
    'No Translate': 'not-set',
    'In Process': 'in-process',
    'Completed': 'completed'
}
export default function CardItem({ fileId, fileName, status }) {
    const navigate = useNavigate()
    const [search] = useSearchParams();
    const [logo, setLogo] = useState(icon.blank)
    const folderId = search.get('folderId')
    const type = search.get('type') ? search.get('type') : '3d'
    const { organizationId, projectId } = useParams()
    const { setIsLoading } = appStore()
    useEffect(() => {
        let split = fileName?.split('.')
        let ext = split[split.length - 1]
        if (icon[ext])
            setLogo(icon[ext])
    }, [fileName])
    const handleViewItem = async () => {
        setIsLoading(true)
        try {
            const reqFile = await requestBimGrid('get', '/api/file', null,
                { organizationId, projectId, id: fileId })
            debugger
            if (reqFile) {
                setIsLoading(false)
                navigate(`./viewer?folderId=${folderId}&type=${type}&fileId=${fileId}&versionId=${reqFile[0].versionId}`)
            }
        } catch (e) {
            message.warning(e.message)
        }
        setIsLoading(false)

    }

    return (
        <>
            <div
                onClick={handleViewItem.bind(this)}
                className='idd-card' style={{ width: 150 }} >
                <div className='card-content' >
                    <div className='cover' style={{
                        height: 100,
                        backgroundImage: `url("${logo}")`
                    }}
                    >
                        <div style={{ float: 'right' }}>

                        </div>
                    </div>
                </div>

                <div className={`card-document-name ${mapping[fileStatus[status]]}`} >
                    <div style={{
                        display: 'flex', padding: '0px 5px', flexDirection: 'column',
                        justifyContent: 'flex-start',
                        // alignItems: 'center'
                        height: 40,
                        wordBreak: 'break-all',
                        textOverflow: 'ellipsis'
                    }} >
                        <span className="main"
                            style={{
                                fontSize: 12,
                                whiteSpace: 'wrap',
                                overflow: 'hidden',
                            }} >{fileName}</span>

                    </div>
                </div>


            </div>


        </>



    )
}

