import React from 'react';

import _ from 'lodash';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';
import ButtonNav from '../../Button.nav';
// import { handleChangeFeature } from '../../../../functions/Viewer.function';

export default function ToolVDC({ handleChangeFeature }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, resetAppStore } = appStore();
  const { setIsOpenTopNav, setFeature, feature, docker, viewerLoading } =
    viewerStore();

  return (
    <div style={{ display: 'flex', gap: 5 }}>
      <ButtonNav
        url='https://img.icons8.com/wired/64/connected--v1.png'
        name='VR Connector'
        active={feature.includes('vdc_vr_connector')}
        onClick={handleChangeFeature.bind(
          this,
          'vdc_vr_connector',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />
    </div>
  );
}
