import { useEffect, useState } from 'react';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import { AppAPI } from '../../../../../api';
import IconButton from '../../../../../gen/button/IconButton.gen';

export default function ActionBlockZoneCell(props) {
  const {
    blockZoneGridApi,
    setIsOpenEditBlockZone,
    setNodeEditBlockZone,
    setIsOpenBlockZoneModel,
  } = workerStore();
  const { setIsLoading } = appStore();
  const [is3D, setIs3D] = useState();
  useEffect(() => {
    setIs3D(!props.node.data.path ? true : false);
  }, [props.node.data]);
  const handleEditItem = async (e) => {
    setNodeEditBlockZone(e.node);
    setIsOpenEditBlockZone(true);
  };
  const handleViewItem = async (e) => {
    setNodeEditBlockZone(e.node);
    setIsOpenBlockZoneModel(true);
  };
  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      await AppAPI.WorkerSettingAPI.DeleteBlockZone({
        id: e.node.data.id,
      });
      await updateTableAsync(blockZoneGridApi.api, {
        remove: [e.node.data],
      });
      debugger;
    } catch (ex) {}
    setIsLoading(false);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        alignItems: 'center',
        height: '100%',
      }}
    >
      <IconButton
        icon={'three_d'}
        size='sssmall'
        //   type={'delete'}
        disabled={is3D}
        onClick={handleViewItem.bind(this, props)}
      />
      <IconButton
        icon={'edit'}
        size='sssmall'
        //   type={'delete'}
        onClick={handleEditItem.bind(this, props)}
      />
      <IconButton
        icon={'delete'}
        size='sssmall'
        type={'delete'}
        onClick={handleDeleteItem.bind(this, props)}
      />
    </div>
  );
}
