/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

import _ from 'lodash';
import IconButton from '../../../../../../gen/button/IconButton.gen';

export default function PropertySetting({ item, items, setItems }) {
  const handleChange = (type, e) => {
    item[type] = e.target.value;
    setItems([...items]);
  };
  const handleDelete = () => {
    let index = _.findIndex(items, (i) => {
      return i.id == item.id;
    });
    if (index >= 0) {
      let clone = [...items];
      clone.splice(index, 1);
      setItems(clone);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', gap: 5 }}>
        <div
          style={{
            width: '50%',
          }}
        >
          <Input
            placeholder='Name'
            onChange={handleChange.bind(this, 'name')}
            value={item.name}
          />
        </div>
        <div
          style={{
            width: '50%',
          }}
        >
          <Input
            placeholder='Property'
            onChange={handleChange.bind(this, 'property')}
            value={item.property}
          />
        </div>
        <IconButton
          size='ssmall'
          type='delete'
          icon={'delete'}
          onClick={handleDelete}
        />
      </div>
    </>
  );
}
