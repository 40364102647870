import { message } from 'antd';
import { eachLimit } from 'async';
import axios from 'axios';
import _ from 'lodash';
import { getForgeTokenInternal } from 'src/functions/Auth.function';
import { appStore } from 'src/store/App.store';
import { viewerStore } from 'src/store/Viewer.store';

export const requestBimGrid = (
  method,
  url,
  data = {},
  params = {},
  isUpload = false
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers =
        method === 'post'
          ? isUpload
            ? {
                Authorization: `Bearer ${localStorage.bimGridToken}`,
                'Content-Type': 'multipart/form-data',
              }
            : {
                Authorization: `Bearer ${localStorage.bimGridToken}`,
                'Content-Type': 'application/json',
              }
          : {
              Authorization: `Bearer ${localStorage.bimGridToken}`,
            };
      let cloneParams = { ...params };
      if (!params?.organizationId)
        cloneParams.organizationId = localStorage.getItem(
          'bimgrid-organization-id'
        );
      if (!params?.projectId)
        cloneParams.projectId = localStorage.getItem('bimgrid-project-id');
      // let cloneParams = {
      //   ...params,
      //   organizationId: localStorage.getItem('bimgrid-organization-id'),
      //   projectId: localStorage.getItem('bimgrid-project-id'),
      // };
      let res = await axios({
        method,
        url,
        headers,
        data,
        params: cloneParams,
      });
      if (res?.data?.isSuccessful) {
        if (res.data.data) resolve(res.data.data);
        else resolve(null);
        if (res?.data?.message) {
          message.info(res?.data?.message);
        }
      } else {
        message.warning(res?.data?.message);
        resolve(null);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        let error;
        if (typeof err.response.data === 'string') {
          error = err.response.data;
        } else if (_.isObject(err.response.data)) {
          if (err.response.data.message) {
            error = err.response.data;
          }
        } else if (err.message) {
          error = err.message;
        } else {
          error = err.response.data.error?.description
            ? { message: err.response.data.error?.description }
            : { message: err?.response?.data.message };
        }

        reject(error);
      } else {
        reject({ message: 'Unknow' });
      }
    }
  });
};

export const resetAll = () => {
  localStorage.removeItem('bimGridToken');
  localStorage.removeItem('hello');
  localStorage.removeItem('bimgrid-organization-id');
  localStorage.removeItem('bimgrid-project-id');
  appStore.getState().resetAppStore();
  viewerStore.getState().resetViewerStore();
};

export const uploadFile = async (key, typeFile, blob) => {
  return new Promise(async (resolve, reject) => {
    let data2 = await requestBimGrid('get', '/api/s3/put-link', null, {
      key,
      typeFile: typeFile,
    });
    if (!data2) {
      window.location.reload();
      return;
    }
    await axios.put(data2.data, blob, {
      headers: {
        'Content-Type': typeFile,
      },
    });
    resolve();
  });
};

export const updateTableAsync = (api, data) => {
  return new Promise(async (resolve) => {
    await api.applyTransactionAsync(data, () => {
      resolve();
    });
  });
};

export const getAgGridRowNode = (api, field, data) => {
  return new Promise(async (resolve) => {
    let node = null;
    try {
      api.forEachLeafNode((rowNode) => {
        if (rowNode.data[field] === data[field]) {
          node = rowNode;
          throw new Error();
        }
      });
    } catch {
      resolve(node);
    }
  });
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const updateTreeData = (list, key, children) => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
        isLeaf: false,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
        isLeaf: false,
      };
    }
    return node;
  });
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const toSnakeCase = (name) => {
  return (name + '').toLowerCase().replace(/ /, '_');
};
export const camelToNormal = (string) => {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2');
};
export const camelToSnakeCase = (inputString) => {
  let outputString = '';
  for (let i = 0; i < inputString.length; i++) {
    let char = inputString.charAt(i);
    if (char.toUpperCase() === char) {
      outputString += '_' + char.toLowerCase();
    } else {
      outputString += char;
    }
  }
  // Check for leading underscore and remove it
  if (outputString.startsWith('_')) {
    outputString = outputString.slice(1);
  }
  return outputString;
};
export const snakeToCamelCase = (inputString) => {
  return inputString.replace(/_([a-z])/g, function (match, group1) {
    return group1.toUpperCase();
  });
};
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getRouteToNode = (rowNode) => {
  if (!rowNode.parent) {
    return [];
  }
  return [
    ...getRouteToNode(rowNode.parent),
    rowNode.key ? rowNode.key : rowNode.data.employeeName,
  ];
};

export const generateS3Path = (organizationId, projectId, feature, path) => {
  return `bimgrid/${organizationId}/${projectId}/${feature}/${path}`;
};

export const checkInput = (data, array) => {
  let result = true;
  debugger;
  _.forEach(data, (v) => {
    const index = _.findIndex(array, (i) => v.field === i);
    if (index >= 0) {
      if (!data[index].value) {
        message.warning(`Please input ${camelToNormal(v.name).toLowerCase()}`);
        result = false;
      }
    }
  });
  return result;
};

export const getBaseName = (path) => {
  const array = path.split('/');
  return array[array.length - 1];
};

export const generateRandomNumberExcludingArray = (
  excludeArray,
  start = 1,
  end = 100
) => {
  if (start > end) {
    throw new Error('Start must be less than or equal to end');
  }

  // Convert excludeArray to a Set for faster lookup
  const excludeSet = new Set(excludeArray);
  let randomNumber;

  do {
    randomNumber = Math.floor(Math.random() * (end - start + 1)) + start;
  } while (excludeSet.has(randomNumber));

  return randomNumber;
};

export const uploadFileToForge = async (
  forgeBucketId,
  file,
  fileName,
  setLoad
) => {
  return new Promise(async (resolve, reject) => {
    try {
      var numberofChunks = Math.ceil(file.size / chunkSize);
      let start = 0;
      let end = 0;
      let chunkCounter = 1;
      let data = [];
      while (chunkCounter <= numberofChunks) {
        chunkCounter++;
        console.log('created chunk: ', chunkCounter);
        end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        console.log('start ' + start + '-' + end);
        start = end;
        data.push(chunk);
      }

      let token = await getForgeTokenInternal();
      let urlGet = `https://developer.api.autodesk.com/oss/v2/buckets/${forgeBucketId}/objects/${fileName}/signeds3upload`;
      let urlPost = `https://developer.api.autodesk.com/oss/v2/buckets/${forgeBucketId}/objects/${fileName}/signeds3upload`;
      let res = await axios.get(urlGet, {
        params: { parts: numberofChunks, minutesExpiration: 60 },
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      let upload = [];
      _.forEach(res.data.urls, (v, k) => {
        upload.push(axios.put(v, data[k]));
      });
      await uploadForgeChunk(upload, setLoad, file.size);
      token = await getForgeTokenInternal();
      const item = await axios.post(
        urlPost,
        {
          uploadKey: res.data.uploadKey,
          size: file.size,
        },
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      resolve(item);
    } catch (ex) {
      reject(ex);
    }
  });
};
const chunkSize = 8 * 1000 * 1000;
const uploadForgeChunk = (upload, setLoad, size) => {
  return new Promise((resolve, reject) => {
    let count = 0;
    let numberofChunks = Math.ceil(size / chunkSize);
    message.loading({
      content: `Uploading... ${0}%`,
      style: {
        zIndex: 10000000,
      },
      key: 'upload',
      duration: 0,
    });
    eachLimit(
      upload,
      6,
      (task, callback) => {
        task.then(
          (res) => {
            let progress = (count / numberofChunks) * 95;
            if (setLoad) setLoad(progress);
            count++;
            message.loading({
              content: `Uploading... ${progress}%`,
              style: {
                zIndex: 10000000,
              },
              key: 'upload',
              duration: 0,
            });
            callback();
          },
          (err) => {
            console.log(err);
            callback(err);
          }
        );
      },
      async (err) => {
        message.destroy();
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve();
        }
      }
    );
  });
};
