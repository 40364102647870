import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShareOrganizationCell from 'src/components/layout/admin/organization/cell/ShareOrganization.cell';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../settings/General.settings';
import { appStore } from '../../../../store/App.store';
import IconButton from '../../../gen/button/IconButton.gen';

export default function OrganizationTable({ data, setGridApi, setRowNode }) {
  const navigate = useNavigate();
  const { setIsLoading, organization, user } = appStore();
  const gridRef = useRef();

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      const repData = await requestBimGrid(
        'delete',
        '/api/admin/organization',
        { organizationId: e.node.data.id }
      );
      if (repData) {
        await updateTableAsync(gridRef.current.api, { remove: [e.node.data] });
        gridRef.current.api.redrawRows();
      }
    } catch (ex) {
      message.warning(ex ?? ex.message ?? 'Error');
    }

    setIsLoading(false);
  };

  const handleEditItem = (e) => {
    setRowNode(e.node);
  };

  const handleViewItem = (e) => {
    navigate(`../organization/${e.node.data.id}/management`);
  };
  const [columns] = useState([
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      minWidth: 200,
      cellRendererSelector: (params) => {
        return {
          component: () => {
            return <span style={{ fontWeight: 'bold' }}> {params.value}</span>;
          },
        };
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agSetColumnFilter',
      minWidth: 200,
    },
    {
      headerName: 'Address',
      field: 'address',
      filter: 'agSetColumnFilter',
      minWidth: 200,
    },
    {
      headerName: 'Country',
      field: 'country',
      filter: 'agSetColumnFilter',
      minWidth: 200,
      maxWidth: 200,
    },

    {
      headerName: 'Phone',
      field: 'phone',
      filter: 'agSetColumnFilter',
      minWidth: 150,
      maxWidth: 150,
    },
    {
      headerName: 'Shared',
      field: 'shared',
      filter: 'agSetColumnFilter',
      minWidth: 100,
      maxWidth: 100,
      cellRendererSelector: () => {
        return {
          component: ShareOrganizationCell,
        };
      },
      cellStyle: { textAlign: '-webkit-center', alignContent: 'center' },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 120,
      maxWidth: 120,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
      },
    },
  ]);

  const handleAction = (params) => {
    console.log(params);
    return (
      params.data.created_by === user.email && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 5,
            alignItems: 'center',
            height: '100%',
          }}
        >
          <IconButton
            icon={'view'}
            size='sssmall'
            // iconStyle={{ background: '#000000' }}
            onClick={handleViewItem.bind(this, params)}
          />
          <IconButton
            icon={'edit'}
            size='sssmall'
            // iconStyle={{ background: '#000000' }}
            onClick={handleEditItem.bind(this, params)}
          />
          <IconButton
            icon={'delete'}
            size='sssmall'
            type={'delete'}
            // iconStyle={{ background: '#000000' }}
            onClick={handleDeleteItem.bind(this, params)}
          />
        </div>
      )
    );
  };

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params);
    },
    [setGridApi]
  );
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          suppressPaginationPanel={true}
          groupDefaultExpanded={1}
          rowHeight={30}
          headerHeight={35}
        />
      </div>
    </>
  );
}
