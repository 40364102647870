/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input, DatePicker, Select } from 'antd'
import _ from 'lodash';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import { dfmaStore } from '../DFMA.store';
import { ViewerAPI } from '../../../../../../api';
import { useSearchParams } from 'react-router-dom';
import { CATEGORY_PPVC, defaultParameters2 } from '../DFMA.constant';
import { updateTableAsync } from '../../../../../../../functions/General.function';
import moment from 'moment';


export default function EditRowModal() {
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const {
    setDFMALoading,
    gridApiDataTabTable,
    currentRow,
    setCurrentRow
  } = dfmaStore();

  const [result, setResult] = useState()
  useEffect(() => {
    debugger
    let result = {}
    let clone = { ...currentRow.data }
    for (let k in clone) {
      let v = clone[k]
      if (defaultParameters2.includes(k)) {
        if (k !== 'name' && k!=='category') {
          result[k] = _.toNumber(v)
        } else {
          result[k] = v
        }
      } else if (k === 'rebar_weight') {
        result[k] = _.toNumber(v)
      } else if (k === 'casting_date' || k === 'delivery_date' || k === 'install_date') {
        if (v)
          result[k] = moment(v)
        else
          result[k] = null
      }
    }
    // _.forEach(clone, (v, k) => {

    // })
    setResult(result)
  }, [])




  const close = () => {
    setCurrentRow();
  };

  const handleOk = async (e) => {
    if (!window.confirm('Are you sure to submit?')) return;
    setDFMALoading(true);
    try {
      debugger
      let repData = await ViewerAPI.DFMAAPI.Update({ data: result, id: currentRow.data.id })
      let data = currentRow.data
      _.forEach(repData, (v, k) => {
        if (data[k] !== undefined) {
          data[k] = v
        }
      })
      await updateTableAsync(gridApiDataTabTable.api, { update: [data] })
      close();
    } catch (ex) {
      message.warning(ex.message);
    }
    setDFMALoading(false);
  };
  const handleChangeInput = (type, v) => {
    result[type] = v.target.value
    setResult({ ...result })
  }
  const handleChangeNumber = (type, v) => {
    result[type] = _.toNumber(v.target.value)
    setResult({ ...result })
  }
  const handleChangeDate = (type, v) => {
    result[type] = v
    setResult({ ...result })
  }
  const handleChangeSelect =(type, v) => {
    result[type] = v
    setResult({ ...result })
  }
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add'}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={true}
      >
        {result && <div
          style={{
            display: 'flex',
            gap: 10,
            height: 'calc(100% - 450px)',
            overflow: 'auto',
            width: '100%',
            flexDirection: 'column'
          }}
        >
          {defaultParameters2.map(v =>
            <div style={{ display: 'flex' }} >
              <div style={{ width: 170 }}>
                <span style={{ fontWeight: 'bold' }} >{v.toUpperCase()}</span>
              </div>
              {v.toLowerCase() === 'name' ?
                <Input value={result[v.toLowerCase()]} onChange={handleChangeInput.bind(this, v)} /> :
                v.toLowerCase() === 'category' ?
                  <Select style={{width:'100%'}} value={result[v.toLowerCase()]}  onChange={handleChangeSelect.bind(this, v)}  >
                    {CATEGORY_PPVC.map(v =>
                      <Select.Option key={v} value={v}  > {v} </Select.Option>
                    )}
                  </Select>
                  :
                  v.toLowerCase() === 'rebar weight' ?
                    <Input min={0} type='number' value={result['rebar_weight']} onChange={handleChangeInput.bind(this, 'rebar_weight')} />
                    :
                    <Input min={0} type='number' value={result[v.toLowerCase()]} onChange={handleChangeNumber.bind(this, v)} />
              }
            </div>
          )}
          <div style={{ display: 'flex' }} >
            <div style={{ width: 170 }}>
              <span style={{ fontWeight: 'bold' }} >{'Casting Date'.toUpperCase()}</span>
            </div>
            <DatePicker style={{ width: '100%' }} value={result['casting_date']}
              onChange={handleChangeDate.bind(this, 'casting_date')}
            />
          </div>
          <div style={{ display: 'flex' }} >
            <div style={{ width: 170 }}>
              <span style={{ fontWeight: 'bold' }} >{'Delivery Date'.toUpperCase()}</span>
            </div>
            <DatePicker style={{ width: '100%' }} value={result['delivery_date']}
              onChange={handleChangeDate.bind(this, 'delivery_date')}
            />
          </div>
          <div style={{ display: 'flex' }} >
            <div style={{ width: 170 }}>
              <span style={{ fontWeight: 'bold' }} >{'Install Date'.toUpperCase()}</span>
            </div>
            <DatePicker style={{ width: '100%' }} value={result['install_date']}
              onChange={handleChangeDate.bind(this, 'install_date')}
            />
          </div>

        </div>}
      </ModalTemplate>
    </>
  );
}
