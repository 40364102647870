import { Input, Tag } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';




export default function DescriptionCellGroup(props) {
    const [group, setGroup] = useState([])
    const [name, setName] = useState('')
    const [root, setRoot] = useState({ name: '' })
    useEffect(() => {
        setRoot(props.node.allLeafChildren[0].data)

    }, [])


    return (
        // <div style={{ display: 'inline-block' }}>
        //     <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5, }}>
        //         <div style={{ display: 'flex', gap: 5, lineHeight: 'normal' }} >
        //             <span style={{ fontWeight: 'bold' }} >
        //                 Name:
        //             </span>
        //             <span>
        //                 {root?.name}
        //             </span>
        //         </div>
        //         <div style={{ overflowY: 'scroll', height: 50, backgroundColor: '#fafafa' , width:'100%'}} >
        //         {root?.description} 

        //         </div>
        //     </div>
        // </div>
        // <div className="full-width-panel">
        //     <div className="full-width-flag">
        //        sdsdsd
        //     </div>
        //     <div className="full-width-summary">
        //         <span className="full-width-title">{root.name}</span>
        //         <br />
        //         <label>
        //             <b>Population:</b>
        //             {root.name}
        //         </label>
        //         <br />
        //         <label>
        //             <b>Known For:</b>
        //             {root.name}
        //         </label>
        //         <br />
        //     </div>
        //     <div className="full-width-center">{root.description}</div>
        // </div>

        <div style={{ display: 'inline-block' }}>
            <div style={{backgroundColor:'gray', width:'100%'}}>
                dsd
            </div>
        </div>

    );
};