import moment from 'moment';
import _ from 'lodash';
import { EventDispatcher } from '../extensions/utils/EventDispatcher';
import { sleep } from '../../../../functions/General.function';
import { convertHexColorToVector4 } from '../../../../functions/Viewer.function';
import { v4 } from 'uuid';

export default class TimelineAnimation extends EventDispatcher {
  viewer;
  animation;
  animationData = [];
  gantt;
  amountNextDays = 1;
  appearanceSetting = {};
  currentDate;
  endDateValue;
  events = {
    playing: 'playing',
    endPlaying: 'endPlaying'
  };
  isPlaying = false;
  excludes = ['none', 'hide', 'model_appearance'];
  constructor(viewer) {
    super();
    this.viewer = viewer;
  }
  setGantt = (gantt) => {
    this.gantt = gantt;
  };
  setAmountNextDays = (number) => {
    this.amountNextDays = number;
  };
  setSetting = (rawSetting) => {
    this.appearanceSetting = {};
    console.log(rawSetting);
    rawSetting.forEach((v) => {
      this.appearanceSetting[v.name] = {
        startAppearance: !this.excludes.includes(v.startAppearance.color)
          ? convertHexColorToVector4(v.startAppearance.color)
          : v.startAppearance.color,
        endAppearance: !this.excludes.includes(v.endAppearance.color)
          ? convertHexColorToVector4(v.endAppearance.color)
          : v.endAppearance.color,
        earlyAppearance: !this.excludes.includes(v.earlyAppearance.color)
          ? convertHexColorToVector4(v.earlyAppearance.color)
          : v.earlyAppearance.color,
        lateAppearance: !this.excludes.includes(v.lateAppearance.color)
          ? convertHexColorToVector4(v.lateAppearance.color)
          : v.lateAppearance.color,
      };
    });
  };
  calculateData = () => {
    this.animationData = [];
    this.gantt.eachTask((task) => {
      let startDate = moment(task.start_date);
      let endDate = moment(task.start_date);
      let nextDate = startDate.clone().add(this.amountNextDays, 'days');
      let dbIds = [];
      if (task.attached === 'Attach by select') {
        dbIds = task.dbIds;
      } else {
      }
      this.animationData.push({
        task,
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf(),
        nextDate: nextDate.valueOf(),
        taskType: task.task_type,
        dbIds,
      });
    });
  };

  preStart = (startDate, endDate) => {
    this.viewer.hideAll();
    this.currentDate = startDate;
    this.endDateValue = endDate.valueOf();
    this.isPlaying = true;
    this.animation = requestAnimationFrame(this.play);
  };
  play = async () => {
    if (!this.isPlaying) return;
    this.dispatchEvent({
      type: this.events.playing,
      currentDate: this.currentDate,
    });
    let nextDate = this.currentDate.clone().add(this.amountNextDays, 'days');
    let nextDateValue = nextDate.valueOf();
    let currentDateValue = this.currentDate.valueOf();
    this.playIndividual(currentDateValue, nextDateValue);
    this.currentDate = nextDate;
    if (nextDateValue > this.endDateValue) {
      this.pause();
      return;
    }
    await sleep(1000);
    this.animation = requestAnimationFrame(this.play);
  };
  playIndividual = (currentDateValue, nextDateValue) => {
    this.gantt.batchUpdate(() => {
      for (let i = 0; i < this.animationData.length; i++) {
        let { task, startDate, endDate, dbIds } = this.animationData[i];
        task.visible = false;
        let taskType = task.task_type;
        if (startDate <= nextDateValue && startDate >= currentDateValue) {
          //start appearance
          if (this.appearanceSetting[taskType]) {
            let color = this.appearanceSetting[taskType].startAppearance;
            this.setColor(dbIds, color);
          }
          task.visible = true;
        } else if (endDate <= currentDateValue) {
          //end appearance
          if (this.appearanceSetting[taskType]) {
            let color = this.appearanceSetting[taskType].endAppearance;
            this.setColor(dbIds, color, taskType);
          }
          task.visible = true;
        }

        this.gantt.updateTask(task.id);
      }
    });
  };
  setColor = (dbIds, color) => {
    if (color !== 'none') {
      let colorTemp = color;
      if (color === 'model_appearance') {
        colorTemp = null;
      }
      dbIds.forEach((dbId) => {
        this.viewer.setThemingColor(
          dbId,
          colorTemp,
          this.viewer.impl.model,
          true
        );
        if (color === 'hide') {
          this.viewer.hide(dbId);
        } else {
          this.viewer.show(dbId);
        }
      });
    }
  };
  pause = () => {
    this.isPlaying = false;
    if (this.animation) cancelAnimationFrame(this.animation);
    this.animation = null;
    this.dispatchEvent({
      type: this.events.endPlaying,
    });
  };
}
export const calculateTimelineStep = (startDate, endDate, setting) => {
  let amountDays = 1;
  if (setting.interval.type === 'days') {
    amountDays = setting.interval.size;
  } else if (setting.interval.type === 'weeks') {
    amountDays = setting.interval.size * 7;
  } else if (setting.interval.type === 'years') {
    amountDays = setting.interval.size * 30;
  }
  let step =
    (amountDays * MILLISECOND_ONE_DAY * 100) /
    (endDate.valueOf() - startDate.valueOf());
  return { amountDays, step };
};

export const MILLISECOND_ONE_DAY = 1000 * 60 * 60 * 24;

export const DefaultColorTimeline = {
  Green: {
    id: v4(),
    name: 'Green',
    color: '#008000',
    transparency: 0,
  },
  Red: { id: v4(), name: 'Red', color: '#ff0000', transparency: 0 },
  Yellow: {
    id: v4(),
    name: 'Yellow',
    color: '#ffff00',
    transparency: 0,
  },
  White: { id: v4(), name: 'White', color: '#ffffff', transparency: 0 },
  Gray: { id: v4(), name: 'Gray', color: '#808080', transparency: 0 },
  Purple: { id: v4(), name: 'Purple', color: '#800080', transparency: 0 },
  Hide: {
    id: 'hide',
    name: 'Hide',
    color: 'hide',
    hide: true,
    fixed: true,
  },
  None: {
    id: 'none',
    name: 'None',
    color: 'none',
    hide: true,
    fixed: true,
  },
  ModelAppearance: {
    id: 'model_appearance',
    name: 'Model Appearance',
    color: 'model_appearance',
    hide: true,
    fixed: true,
  },
};
