import React, { useEffect, useState } from 'react';
import IconButton from '../../../gen/button/IconButton.gen';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { fiveDStore } from '../../../../store/FiveD.store';
import ListSheet from './components/ListSheet';
import { viewerStore } from '../../../../store/Viewer.store';
export default function DMSSidebar() {
  const { setIsOpenAddSheet } = fiveDStore();
  const [isOpen, setIsOpen] = useState(true);
  const { viewer } = viewerStore();
  useEffect(() => {
    if (viewer) {
      viewer.resize();
    }
  }, [viewer, isOpen]);
  const handleAddSheet = (e) => {
    setIsOpenAddSheet(true);
  };
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {isOpen && (
        <div className='idd-sidebar-five-d'>
          <div
            style={{
              display: 'flex',
              gap: 5,
              flexDirection: 'column',
              width: isOpen ? 300 : 0,
              // borderRight: '1px solid black',
            }}
          >
            <div style={{ display: 'flex' }}>
              <IconButton icon={'add'} size='ssmall' onClick={handleAddSheet} />
            </div>
            <div style={{ height: 1, backgroundColor: 'black' }} />
            <ListSheet />
          </div>

          <div
            style={{
              display: 'flex',
              gap: 5,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <IconButton
              icon='setting'
              size='small'
              type='save'
              border={false}
              active={location.pathname.includes('/dms/setting')}
              onClick={handleChange.bind(this, 'setting')}
            /> */}
          </div>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: isOpen ? 305 : 0,
          backgroundColor: '#2b435f',
          padding: 10,
          borderBottomRightRadius: 5,
          borderTopRightRadius: 5,
          zIndex: 100,
          //   width: 50,
          //   height: 50,
        }}
      >
        {!isOpen ? (
          <RightOutlined style={{ color: 'white' }} onClick={handleOpen} />
        ) : (
          <LeftOutlined style={{ color: 'white' }} onClick={handleOpen} />
        )}
      </div>
    </>
  );
}
