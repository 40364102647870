import React, { } from 'react';


import _ from 'lodash';
import { Typography } from 'antd';
import ButtonNav from '../../Button.nav';


import { viewerStore } from '../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';

export default function Tool4D({handleChangeFeature}) {
    const { user, resetAppStore } = appStore()
    const { setFeature, feature, docker, viewerLoading } = viewerStore()





    return (
        <div style={{ display: 'flex', gap: 5 }} >
            <ButtonNav
                url={'https://img.icons8.com/external-konkapp-detailed-outline-konkapp/40/000000/external-ruler-construction-konkapp-detailed-outline-konkapp.png'}
                name='Timeline'
                active={feature.includes('4d_timeline')}
                onClick={handleChangeFeature.bind(this, '4d_timeline', docker, feature, setFeature)}
                disabled={viewerLoading}
            />
            <ButtonNav
                url={'https://img.icons8.com/external-wanicon-lineal-wanicon/64/null/external-animation-art-and-design-wanicon-lineal-wanicon.png'}
                name='Animation'
                active={feature.includes('4d_animation')}
                onClick={handleChangeFeature.bind(this, '4d_animation', docker, feature, setFeature)}
                disabled={viewerLoading}
            />
         <ButtonNav
                url={'https://img.icons8.com/ios/50/module.png'}
                name='DFMA'
                active={feature.includes('4d_dfma')}
                onClick={handleChangeFeature.bind(this, '4d_dfma', docker, feature, setFeature)}
                disabled={viewerLoading}
            />


        </div>

    );
}
