import React, { } from 'react';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import { viewerStore } from '../../../../../store/Viewer.store';
import moment from 'moment'
import { colorStatus } from './View.constant';
import { Tooltip, Badge } from 'antd';
import { ImageScene } from '../../../../utils/Image.utlis';
import { viewImagePath } from './View.constant';

export default function ViewCard({ data, setType }) {
    const [search] = useSearchParams();
    const { setViewDetail } = viewerStore()
    const { organizationId, projectId } = useParams()

    const handleViewIssue = () => {
        setViewDetail(data)
        setType('detail')
    }
    return (
        <Badge.Ribbon text={data?.status} color={colorStatus[data.status]} style={{ top: 0, right: -8 }} >
            < >
                <div className='view-card'
                    onClick={handleViewIssue}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', height: 75 }} >
                        <ImageScene
                            url={viewImagePath(organizationId, projectId, `${data.file_id}/${data.id}/main.jpg`)}
                            height={75}
                            width={150}
                            isTooltip={true}
                            content={
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                    <div style={{ display: 'flex', gap: 5 }}>
                                        <span style={{ fontWeight: 'bolder' }}>Title:</span>
                                        <span>{data?.title}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 5 }}>
                                        <span style={{ fontWeight: 'bolder'}} >Description:</span>
                                        <p style={{ whiteSpace: 'break-spaces' }} >{data?.description}</p>
                                    </div>
                                </div>

                            }
                        />
                    </div>
                    <div style={{ height: 25, padding: '0px 5px', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                        <span>{data?.title}</span>
                    </div>
                </div>

            </>
        </Badge.Ribbon>


    );
}


