/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message,
    Input,
    Select
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AppAPI } from '../../../../../api';



export default function ModalDMSSettingAddGroup() {
    const { setIsModalDMSSettingAddGroup, setConsultantGroup, consultantGroup } = dmsStore()
    const { setIsLoading, } = appStore()
    const { projectId } = useParams()
    const [groupName, setGroupName] = useState()
    const [companies, setCompanies] = useState([])
    const [companyId, setCompanyId] = useState()
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            const companies = await AppAPI.AdminAPI.GetAllCompanyByOrganizationId()
            setCompanies(companies.filter(v => v.type === 'Consultant'))
        } catch (ex) {
            message.warning(ex)
        }
        setIsLoading(false)
    }
    const close = () => {
        setIsModalDMSSettingAddGroup(false)
    }

    const handleOk = async (e) => {
        if (!groupName) {
            message.warning('Please input group name')
            return
        }
        if (!companyId) {
            message.warning('Please select company name')
            return
        }
        setIsLoading(true)
        try {
            const dsmGroupData = await AppAPI.DMSettingAPI.CreateDMSSettingGroup({ name: groupName, companyId: companyId, projectId })
            const company = companies.find(v => { return v.id === companyId })
            dsmGroupData.companyName = company.name
            dsmGroupData.members_length = 0
            consultantGroup.push(dsmGroupData)
            setConsultantGroup([...consultantGroup])
            setIsLoading(false)
            close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
        }
    }
    const handleChange = (e) => {
        setGroupName(e.target.value)
    }
    const handleCompany = (e) => {
        setCompanyId(e)
    }

    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Add Group'}
                style={{ width: 300 }}
            >

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Group name:</span>
                    <Input placeholder='Company name' onChange={handleChange} value={groupName} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Company name:</span>
                    <Select placeholder='Group name' onChange={handleCompany} value={companyId} >
                        {companies.map(v =>
                            <Select.Option value={v.id} > {v.name} </Select.Option>

                        )}
                    </Select>
                </div>




            </ModalTemplate>
        </>
    );
}

