/* eslint-disable no-unused-vars */
import { message } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GetForgeItemStatus, TranslateForgeItemDirectly } from 'src/api';
import HorizontalDivider from 'src/components/atoms/HorizontalDivider';
import SafetyAreaList from 'src/components/layout/apps/worker/components/SafetyAreaList';
import ViewerForge from 'src/components/layout/viewer/Viewer.forge';
import { viewerStore } from 'src/store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import HubDeviceList from '../../components/HubDeviceList';
import HubDeviceTag from '../../components/HubDeviceTag';

export default function ModalBlockZoneModel() {
  const { setIsOpenBlockZoneModel, nodeEditBlockZone, hubDeviceSprite } =
    workerStore();
  const { setIsLoading } = appStore();
  const { viewer } = viewerStore();
  const [hubDevices, setHubDevices] = useState([]);
  const [objectId, setObjectId] = useState();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    const key = nodeEditBlockZone.data.path;
    try {
      if (!key) {
        throw new Error('Cannot find path');
      }
      const res = await GetForgeItemStatus({ objectId: key });
      if (res.data.status === 'success') {
        setObjectId(key);
      } else {
        let reg = res.data.progress.split('%');
        let progress = _.isNumber(_.toNumber(reg[0]))
          ? _.toNumber(reg[0])
          : 100;
        message.warning(`Translate ${progress === 0 ? 1 : progress}`);
        close();
      }
    } catch (ex) {
      if (ex.response.status === 404) {
        translateFile(key);
      } else {
        message.warning(ex.message);
        console.log(ex);
      }
      close();
    }
    setIsLoading(false);
  };
  const translateFile = async (objectId) => {
    try {
      await TranslateForgeItemDirectly({ objectId });
      message.warning('Start translate file');
    } catch (ex) {
      message.warning(ex.message);
    }
  };
  const close = () => {
    setIsOpenBlockZoneModel(false);
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        title={'Block zone model'}
        style={{ width: '90%', height: '90%' }}
        footer={null}
      >
        {objectId && (
          <div style={{ display: 'flex', gap: 5, height: '100%' }}>
            <div style={{ width: 'calc(100% - 200px)', height: '100%' }}>
              <ViewerForge name={'Tracking'} objectId={objectId} />
            </div>
            {viewer && (
              <div className='flex flex-col'>
                <div>
                  <SafetyAreaList
                    isViewer={false}
                    blockId={nodeEditBlockZone.data?.id}
                  />
                </div>
                <HorizontalDivider />
                <HubDeviceList
                  hubDevices={hubDevices}
                  setHubDevices={setHubDevices}
                  blockId={nodeEditBlockZone.data?.id}
                />
              </div>
            )}
          </div>
        )}
      </ModalTemplate>
      {hubDeviceSprite && <HubDeviceTag />}
    </>
  );
}
