/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input } from 'antd';
import ModalTemplate from '../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { appStore } from '../../../../../store/App.store';
import { fiveDStore } from '../../../../../store/FiveD.store';
import { v4 } from 'uuid';
import IconTextButton from '../../../../gen/button/IconTextButton.gen';
import IconButton from '../../../../gen/button/IconButton.gen';
import { AppAPI } from '../../../../api';
import {
  camelToNormal,
  snakeToCamelCase,
} from '../../../../../functions/General.function';

export default function ModalFiveDAddSheetColumn() {
  const { setIsLoading } = appStore();
  const { setIsOpenAddColumn, setSheetColumns, selectedSheet, sheetColumns } =
    fiveDStore();
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (sheetColumns) {
      const data = [];
      const constantCols = ['design_qty'];

      _.forEach(constantCols, (i) => {
        const index = _.findIndex(sheetColumns, (v) => v.id === i);
        if (index < 0) {
          data.push({
            id: i,
            parameter: '',
            name: camelToNormal(snakeToCamelCase(i)).toUpperCase(),
            isDefault: true,
          });
        }
      });

      setColumns([...data, ...sheetColumns]);
      // selectedSheet.columns.forEach(v=>{

      // })
    }
  }, []);
  const close = () => {
    setIsOpenAddColumn(false);
  };
  const handleChange = (item, type, e) => {
    item[type] = e.target.value;
    setColumns([...columns]);
  };

  const handleOk = async (e) => {
    let index = _.findIndex(columns, (v) => {
      return !v.name;
    });
    if (index >= 0) {
      message.warning('Please input column name');
      return;
    }
    try {
      setIsLoading(true);
      const repSheet = await AppAPI.FiveDSheetAPI.UpdateOneSheet({
        id: selectedSheet.id,
        data: { columns },
      });
      // selectedSheet.columns = columns;
      setSheetColumns(repSheet.columns);
      setIsLoading(false);
      close();
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };
  const handleAdd = () => {
    columns.push({ id: v4(), name: '', parameter: '' });
    setColumns([...columns]);
  };
  const handleDelete = (item) => {
    const index = _.findIndex(columns, (v) => v.id === item.id);
    if (index >= 0) {
      columns.splice(index, 1);
      setColumns([...columns]);
    }
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add Column'}
        style={{ maxWidth: 500, width: 500 }}
      >
        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <span style={{ fontWeight: 'bold', width: '50%' }}>Name:</span>
          <span style={{ fontWeight: 'bold', width: '50%' }}>
            Parameter Name:
          </span>
          <span style={{ fontWeight: 'bold', width: 30 }}></span>
        </div>

        {columns.map((v) => (
          <>
            <div
              style={{
                display: 'flex',
                gap: 5,
                alignItems: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              <Input
                style={{ width: '50%' }}
                placeholder='Please input'
                onChange={handleChange.bind(this, v, 'name')}
                value={v.name}
                disabled={v.isDefault}
              />
              <Input
                style={{ width: '50%' }}
                placeholder='Please input'
                onChange={handleChange.bind(this, v, 'parameter')}
                value={v.parameter}
              />
              <IconButton
                type='delete'
                icon='delete'
                size='ssmall'
                onClick={handleDelete.bind(this, v)}
              />
            </div>
          </>
        ))}

        <IconTextButton
          icon={'add'}
          type='save'
          size='ssmall'
          onClick={handleAdd}
        >
          Add Column
        </IconTextButton>
      </ModalTemplate>
    </>
  );
}
