import { message } from 'antd';
import { SendUserInvitaionAPI } from 'src/api';
import { ReactComponent as CheckCircleIcon } from 'src/assets/icons/general/check-circle.svg';
import IconButton from 'src/components/gen/button/IconButton.gen';
import { appStore } from 'src/store/App.store';

export default function InvitationCell(props) {
  const { setIsLoading } = appStore();
  const cellValue = props.valueFormatted
    ? props.valueFormatted
    : (props.value ?? 'Register');

  const resendEmail = async () => {
    setIsLoading(true);
    try {
      await SendUserInvitaionAPI({
        id: props.node.data.id,
        host: window.location.origin,
      });
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
    setIsLoading(false);
  };
  return (
    <div className='flex justify-center'>
      {cellValue === 'Invited' ? (
        <>
          {/* <div className='size-fit border border-primary-600 bg-gray-200 px-2 pb-0.5 pt-0.5 leading-normal'>
          {cellValue}
        </div> */}
          <IconButton size='sssmall' icon='send_mail' onClick={resendEmail} />
        </>
      ) : (
        <CheckCircleIcon className='text-green-600' />
      )}
    </div>
  );
}
