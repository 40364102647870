import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Table, Row, Col, Input, Button, message } from 'antd';
import _ from 'lodash'
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { AgGridReact } from 'ag-grid-react';
import { autoGroupColumnDef, defaultColDef, defaultColDefNotFlex } from '../../../../../../settings/General.settings';
import IconButton from '../../../../../gen/button/IconButton.gen';



export default function COBieTable({ columnData,dataTable }) {
    const [search] = useSearchParams();
    const { viewer } = viewerStore()
    const [columns, setColumns] = useState([]);






    useEffect(() => {
        if (columnData.length !== 0) {
            let index = _.findIndex(columnData, v=>{return v.headerName ==='Action'})
            if(index<0){
                columnData.splice(0, 0, {
                    enableRowGroup: false,
                    maxWidth: 60,
                    minWidth: 60,
                    headerName: 'Action',
                    lockVisible: true,
                    lockPosition: 'left',
                    suppressMovable: true,
                    suppressToolPanel: true,
                    cellRendererSelector: params => {
                        const isGroup = params.node.group;
                        return {
                            component: handleAction,
                            params: { isGroup: isGroup },
                        }
                    }
                })
            }

            setColumns(columnData)
        }
    }, [columnData])




    const handleSelect = (params) => {
        const models = viewer.impl.modelQueue().getModels();
        if (params.isGroup) {
            let nodes = params.node.allLeafChildren
            let temp = {}
            _.forEach(nodes, v => {
                if (!temp[v.data.modelId])
                    temp[v.data.modelId] = []
                temp[v.data.modelId].push(v.data.dbId)
            })
            let select =[]
            _.forEach(temp, (v, k)=>{
                let index = _.findIndex(models, v => { return v.id == k})
                select.push({ model: models[index], ids: v})
            })
            // viewer.select(temp)
            viewer.impl.selector.setAggregateSelection(select)
        } else {
            let index = _.findIndex(models, v => { return v.id === params.data.modelId })
            // viewer.select(params.data.dbId)
            viewer.impl.selector.setAggregateSelection([{ model: models[index], ids: [params.data.dbId] }])
        }
    }
    const handleAction = (params) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
                <IconButton
                    url='https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/20/000000/external-selection-graph-design-kiranshastry-lineal-kiranshastry-1.png'
                    size='small'
                    ghost={true}
                    border={false}
                    iconStyle={{ background: '#000000' }}
                    onClick={handleSelect.bind(this, params)}
                />
    
            </div>
        )
    }

    const onGridReady = useCallback(() => {

    }, []);
    return (
        <>
            <div style={{ width: '100%', height: '100%', padding: 5 }} >

                <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                    <AgGridReact
                        rowData={dataTable}
                        columnDefs={columns}
                        defaultColDef={defaultColDefNotFlex}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onGridReady={onGridReady}
                        // groupDisplayType='multipleColumns'
                        // rowGroupPanelShow='always'
                        rowHeight={30}
                        // pagination={true}
                        // paginationPageSize={pageSizeModuleList}
                        suppressPaginationPanel={true}
                        context={false}
                    // statusBar={statusBar}
                    // groupDefaultExpanded={2}
                    ></AgGridReact>


                </div>
            </div>

        </>

    );
}


