import { requestBimGrid } from '../../../functions/General.function';

export const GetOneWorkerPayRate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/pay-rate/get-one-pay-rate',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllWorkerPayRateByWorkerId = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/pay-rate/get-all-pay-rate-by-worker-id',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CreateWorkerPayRate = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/pay-rate/add-pay-rate',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneWorkerPayRate = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/pay-rate/update-one-pay-rate',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteWorkerPayRate = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/pay-rate/delete-one-pay-rate',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
