import _ from "lodash";

const THREE = window.THREE
export function getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, condition) {
  let temp = [];
  if (!instanceTree) {
    return temp;
  }
  let queue = [];
  queue.push(instanceTree.getRootId());
  while (queue.length > 0) {
    var node = queue.shift();
    if (instanceTree.getChildCount(node) !== 0) {
      instanceTree.enumNodeChildren(node, function (childrenIds) {
        queue.push(childrenIds);
      });
    }
    else {
      if (condition === 'all')
        temp.push(node);
      else
        if (viewer.isNodeVisible(node, model))
          temp.push(node);
    }
  }
  return temp;
}

export function getAllChildrenIds(instanceTree) {
  let temp = [];
  if (!instanceTree) {
    return temp;
  }
  let queue = [];
  queue.push(instanceTree.getRootId());
  while (queue.length > 0) {
    var node = queue.shift();
    if (instanceTree.getChildCount(node) !== 0) {
      instanceTree.enumNodeChildren(node, function (childrenIds) {
        temp.push(childrenIds);
        queue.push(childrenIds);
      });
    }
    else {
      temp.push(node);
    }
  }
  return temp;
}



export function getChildrenIds(instanceTree, parentId) {
  return new Promise(async resolve => {
    let temp = []
    let count = instanceTree.getChildCount(parentId)
    instanceTree.enumNodeChildren(parentId, function (childrenIds) {
      temp.push(childrenIds)
      count--
      if (count === 0) {
        resolve(temp)
      }
    });
  })
}
export function getLeafChildrenIds(instanceTree, parentId) {
  let temp = [];
  if (!instanceTree) {
    return temp;
  }
  let queue = [];
  queue.push(parentId);
  while (queue.length > 0) {
    var node = queue.shift();
    if (instanceTree.getChildCount(node) !== 0) {
      instanceTree.enumNodeChildren(node, function (childrenIds) {
        queue.push(childrenIds);
      });
    }
    else {
      temp.push(node);
    }
  }
  return temp;
}
export function getChildrenNode(instanceTree, parentId, model) {
  return new Promise(async resolve => {
    let temp = []
    let ids = await getChildrenIds(instanceTree, parentId);
    for (let i in ids) {
      let properties = await getProperties(model, ids[i])
      properties.isLeaf = instanceTree.getChildCount(ids[i]) === 0
      temp.push(properties)
    }
    resolve(temp)
  })
}


export async function getElementProperty(model, dbIds) {
  return new Promise(async resolve => {
    let temp = []
    for (var i in dbIds) {
      let properties = await getProperties(model, dbIds[i])
      temp.push(properties)
    }
    resolve(temp)
  })
}

export const getProperties = (model, id) => {
  return new Promise(resolve => {
    model.getProperties(id, (property) => {
      resolve(property)
    })
  })
}

export function getLeafFragIds(instanceTree, leafId) {
  return new Promise(resolve => {
    const fragIds = [];
    instanceTree.enumNodeFragments(leafId, function (fragId) {
      fragIds.push(fragId);
    });
    resolve(fragIds);
  })

}

export function resetTransform(viewer, instanceTree, leafId) {
  return new Promise(resolve => {
    instanceTree.enumNodeFragments(leafId, function (fragId) {
      const fragProxy = viewer.impl.getFragmentProxy(viewer.impl.model, fragId)
      fragProxy.position = null
      fragProxy.updateAnimTransform()
    });
    viewer.impl.sceneUpdated(true)
    resolve();
  })

}
export const normalize = (screenPoint, viewer) => {

  let viewport = viewer.navigation.getScreenViewport()

  let n = {
    x: (screenPoint.x - viewport.left) / viewport.width,
    y: (screenPoint.y - viewport.top) / viewport.height
  }

  return n
}


export const getHitPoint = (event, viewer) => {

  let screenPoint = {
    x: event.clientX,
    y: event.clientY
  }

  let n = normalize(screenPoint, viewer)

  let hitPoint = viewer.utilities.getHitPoint(n.x, n.y)

  return hitPoint
}

export const pointerToRaycaster = (camera, ray, pointer, rect) => {

  var pointerVector = new THREE.Vector3()
  var pointerDir = new THREE.Vector3()



  var x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1
  var y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1

  if (camera.isPerspective) {

    pointerVector.set(x, y, 0.5)

    pointerVector.unproject(camera)

    ray.set(camera.position,
      pointerVector.sub(
        camera.position).normalize())

  } else {

    pointerVector.set(x, y, -1)

    pointerVector.unproject(camera)

    pointerDir.set(0, 0, -1)

    ray.set(pointerVector,
      pointerDir.transformDirection(
        camera.matrixWorld))
  }

  return ray
}

export const  viewerSelect=(viewer, data)=>{
  viewer.impl.selector.setAggregateSelection(data);
} 


export function convertHexColorToVector4(value, transparent = 1) {
  let R = hexToR(value) / 255;
  let G = hexToG(value) / 255;
  let B = hexToB(value) / 255;
  return new window.THREE.Vector4(R, G, B, transparent)
}
function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16) }
function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16) }
function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16) }
function cutHex(h) { return (h.charAt(0) === "#") ? h.substring(1, 7) : h }

export function clearColorAllModel(viewer) {
  let models = viewer.impl.modelQueue().getModels();
  _.forEach(models, model => {
    viewer.clearThemingColors(model)
  })
}