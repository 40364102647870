import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Table, Row, Col, Input, Button, message } from 'antd';
import _ from 'lodash'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import AnimationCard from './Animation.card';
import { requestBimGrid } from '../../../../../functions/General.function';




export default function AnimationList({ setType, setLoading, ...props }) {
    const [search] = useSearchParams();
    const { viewer, setAnimationsData, animationsData } = viewerStore()
    const { organizationId, projectId } = useParams()
    const fileId = search.get('fileId')
    useEffect(() => {
        run()
    }, [])

    const run = async () => {
        setLoading(true)
        // let fileId = viewer.impl.model.getSeedUrn()
        let data = await requestBimGrid('get', '/api/animation/getByFileId', null, {
            organizationId, projectId, fileId,
            select: ['name', 'id', 'updated_at', 'updated_by']
        })
        if (data) {
            setAnimationsData(data)
        }
        setLoading(false)
    }

    return (
        <>
            <div style={{ width: '100%', height: '100%' }} >
                <div className='idd-list' style={{ height: '100%' }}>
                    {animationsData.map((i, idx) =>
                        <AnimationCard key={idx} data={i} setType={setType}
                            setLoading={setLoading} />
                    )}

                </div>

            </div>

        </>

    );
}


