import React from "react";
import DMSViewer from "../../../../../layout/apps/two_d/viewer/DMSViewer";

export default function DMSViewerPage() {


    return (
        <>
            <DMSViewer />

        </>

    );
}
