import React from "react";
import DMSMainLayout from "../../../../layout/apps/two_d/dms/DMSMain.layout";

export default function DMSPage() {


    return (
        <>
            <DMSMainLayout />

        </>

    );
}


