import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckResetPasswordAPI, ResetPasswordAPI } from 'src/api/auth';
import FullSceenLoader from 'src/components/molecules/FullSceenLoader';
import ResetPasswordModal from 'src/components/organisms/ResetPasswordModal';
import ResetPsswordForm from 'src/components/organisms/ResetPsswordForm';
const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [isCanReset, setIsCanReset] = useState(null);
  const [loading, setIsLoading] = useState();
  const [isChecking, setIsChecking] = useState(true);
  const [search] = useSearchParams();
  const resetId = search.get('resetId');
  const id = search.get('id');
  useEffect(() => {
    (async () => {
      try {
        debugger;
        console.log(id, resetId);
        await CheckResetPasswordAPI({
          id,
          resetId,
        });
        setIsChecking(false);
      } catch (ex) {
        debugger;
        setError(ex.message ?? ex ?? 'Error');
        setIsCanReset(false);
      }
      setIsChecking(false);
    })();
  }, []);

  const onClickReset = async (resetPasswordInput) => {
    setIsLoading(true);
    try {
      const data = await ResetPasswordAPI({
        id,
        resetId,
        password: resetPasswordInput.password,
      });
      if (data) {
        navigate('/');
      }
    } catch (ex) {
      setError(ex.message ?? ex ?? 'Error');
    }
    setIsLoading(false);
  };
  return (
    <>
      {(isChecking || loading) && <FullSceenLoader />}
      <ResetPsswordForm
        onClickReset={onClickReset}
        isLoading={loading}
        error={error}
      />

      {isCanReset === false && <ResetPasswordModal message={error} />}
    </>
  );
};

export default ResetPasswordPage;
