import React, { } from "react";
import DMSSettingGroupToolbar from "./DMSSettingGroup.toolbar";
import DMSSettingTable from "./table/DMSSetting.table";
import ListGroup from "./components/ListGroup";
import { dmsStore } from "../../../../../store/DMS.store";
import ModalDMSSettingAddGroup from "./modal/ModalDMSSettingAddGroup";
import ModalDMSSettingEditGroup from "./modal/ModalDMSSettingEditGroup";
import DMSSettingMemberToolbar from "./DMSSettingMember.toolbar";
import ModalDMSSettingAddMemberGroup from "./modal/ModalDMSSettingAddMemberGroup";




export default function DMSSettingLayout() {

    const { isModalDMSSettingAddGroup,
        isModalDMSSettingEditGroup,
        editConsultantGroupId,
        isModalDMSSettingAddMemberGroup,
        selectedConsultantGroupId
    } = dmsStore()


    return (
        <>
            <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }} >
                <div style={{ height: '100%', width: '100%', display: "flex", gap: 5 }} >
                    <div style={{
                        display: 'flex', height: '100%', width: 300,
                        flexDirection: 'column', gap: 5
                    }} >
                        <DMSSettingGroupToolbar />
                        <div style={{ height: 1, background: 'gray' }} ></div>
                        <ListGroup />
                    </div>
                    <div style={{ width: 1, background: 'gray', height: '100%' }} ></div>
                    <div style={{ height: '100%', width: 'calc(100% - 300px)' }}>
                        <DMSSettingMemberToolbar />
                        <div style={{ height: 1, background: 'gray' }} ></div>
                        <div style={{ height: 'calc(100% - 40px)', width: '100%' }}>
                            <DMSSettingTable />
                        </div>

                    </div>

                </div>

            </div >
            {isModalDMSSettingEditGroup && editConsultantGroupId && <ModalDMSSettingEditGroup />}
            {isModalDMSSettingAddGroup && <ModalDMSSettingAddGroup />}
            {isModalDMSSettingAddMemberGroup && selectedConsultantGroupId && <ModalDMSSettingAddMemberGroup />}
        </>


    );
}


