import React, { useEffect } from 'react';
import { Empty, message } from 'antd';
import { fiveDStore } from '../../../../store/FiveD.store';
import FiveDTable from './table/FiveDMain.table';
import FiveDSidebar from './FiveDSidebar';
import FiveDToolbar from './FiveD.toolbar';

import ModalFiveDAddSheet from './modal/ModalFiveDAddSheet';
import ModalFiveDEditSheet from './modal/ModalFiveDEditSheet';
import ModalFiveDAddSheetRow from './modal/ModalFiveDAddSheetRow';
import ModalFiveDAddSheetColumn from './modal/ModalFiveDAddSheetColumn';
import TextButton from '../../../gen/button/TextButton.gen';
import ModalFiveDEditSheetRow from './modal/ModalFiveDEditSheetRow';
import ViewerForge from '../../viewer/Viewer.forge';
import FiveDViewer from './FiveDViewer.';
import EmptySheet from './components/EmptySheet';
import { viewerStore } from '../../../../store/Viewer.store';
import { AppAPI } from '../../../api';
import { useParams } from 'react-router-dom';
import { appStore } from '../../../../store/App.store';
import { viewerOptions } from '../../viewer/Viewer.constant';
import _ from 'lodash';
import { openView, workDoneProgress } from './FiveD.utils';
export default function FiveDMainLayout() {
  const {
    isOpen3D,
    isOpenAddSheet,
    currentEditSheet,
    selectedSheet,
    isOpenAddRow,
    isOpenAddColumn,
    selectedRow,
    isOpenEditRow,
    resetFiveDStore,
    setListSheet,
    workDoneDate,
    sheetGridApi,
  } = fiveDStore();
  const { setIsLoading } = appStore();
  const { resetViewerStore, treeModelData, viewer } = viewerStore();
  const { projectId } = useParams();
  useEffect(() => {
    run();
    return () => {
      resetFiveDStore();
      resetViewerStore();
    };
  }, []);

  const run = async () => {
    setIsLoading(true);
    try {
      const repSheets = await AppAPI.FiveDSheetAPI.GetAllSheetByProject({
        projectId,
      });
      setListSheet(repSheets);
    } catch (ex) {
      if (ex.message) {
        message.warning(ex.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (treeModelData && viewer) {
      treeModelData.addEventListener('done', handleDone);
    }
    return () => {
      treeModelData?.removeEventListener('done', handleDone);
    };
  }, [treeModelData, viewer, sheetGridApi, workDoneDate]);
  const handleDone = async () => {
    if (window.fiveDViewData) {
      if (window.fiveDViewData.isInitView) {
        window.fiveDViewData.files.splice(0, 1);
      }
      setIsLoading(true);
      for (const index in window.fiveDViewData.files) {
        try {
          await openView(viewer, window.fiveDViewData.files[index]);
        } catch (ex) {
          if (ex.message) message.warning(ex.message);
        }
      }
      setIsLoading(false);
      window.fiveDViewData = null;
      workDoneProgress(workDoneDate, sheetGridApi, treeModelData, viewer);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }}>
        <FiveDSidebar />
        <div style={{ height: '100%', width: '100%' }}>
          <div
            style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }}
          >
            {selectedSheet ? (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 5,
                }}
              >
                <FiveDToolbar />
                <FiveDTable />
              </div>
            ) : (
              <EmptySheet />
            )}
            {isOpen3D && (
              <div style={{ width: '50%' }}>
                <FiveDViewer />
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenAddSheet && <ModalFiveDAddSheet />}
      {currentEditSheet && <ModalFiveDEditSheet />}
      {selectedSheet && isOpenAddRow && <ModalFiveDAddSheetRow />}
      {selectedSheet && isOpenAddColumn && <ModalFiveDAddSheetColumn />}
      {selectedRow && isOpenEditRow && <ModalFiveDEditSheetRow />}
    </>
  );
}
