import { requestBimGrid } from '../../../functions/General.function';

export const GetLastVersion = ( params={}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid('get', '/api/version/get-last-version', {}, params);
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
export const GetLastVersion_Share = ( params={}) => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await requestBimGrid('get', '/api/version/share/get-last-version', {}, params);
        resolve(data);
      } catch (ex) {
        reject(ex.message);
      }
    });
  };
