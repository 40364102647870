import React from 'react';
import { workerStore } from '../../../../../../store/Worker.store';
import { InputNumber } from 'antd';
export default function PayRateEditCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const { payRateEditData, setPayRateEditData } = workerStore();

  const handleChange = (e) => {
    payRateEditData[props.column.colId] = e;
    setPayRateEditData({ ...payRateEditData });
  };
  return (
    <div>
      {payRateEditData?.id !== props.node.data.id ? (
        cellValue
      ) : (
        <InputNumber
          value={payRateEditData.pay_rate}
          size='small'
          onChange={handleChange}
        />
      )}
    </div>
  );
}
