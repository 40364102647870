import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { validatePermission } from 'src/functions/Auth.function';
import { requestBimGrid } from 'src/functions/General.function';
import { AlertPermissionService } from 'src/services';
import { appStore } from 'src/store/App.store';
import { projectStore } from 'src/store/Docs.store';

export default function ProjectRoutes({ requireActions, containActions }) {
  const { setIsLoading, permission, organization } = appStore();
  const { setProject, project, resetProjectStore, isHideTabNav } =
    projectStore();
  const { organizationId, projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const { allowed, permissionsMissing } = validatePermission(
      permission,
      requireActions,
      containActions,
      organizationId,
      projectId
    );
    if (!allowed) {
      AlertPermissionService.showAlert(permissionsMissing, 0);
      navigate(`/organization/${organization.id}`);
    }
    if (projectId) {
      localStorage.setItem('bimgrid-project-id', projectId);
      run();
    }
    return () => {
      localStorage.removeItem('bimgrid-project-id');
      resetProjectStore();
    };
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid('get', '/api/project', null);
    if (data) {
      setProject(data);
    } else {
      setIsLoading(false);
      navigate(-1);
    }
    setIsLoading(false);
  };

  return (
    project && (
      <>
        <div style={{ height: `calc(100% - ${isHideTabNav ? '0px' : '0px'})` }}>
          <Outlet />
        </div>
      </>
    )
  );
}
