/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input, Select, DatePicker as DatePickerDayjs } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { workerStore } from '../../../../../../store/Worker.store';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { DATETYPE, NATIONALITY, OCCUPATION } from '../../Worker.constant';
import {
  checkInput,
  updateTableAsync,
} from '../../../../../../functions/General.function';
import { AppAPI } from '../../../../../api';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalAddWorkTime() {
  const { setIsOpenAddWorkTime, workTimeGridApi } = workerStore();
  const { setIsLoading } = appStore();
  const { projectId } = useParams();
  const [data, setData] = useState([
    {
      name: 'Date Type',
      field: 'date_type',
      value: null,
      type: 'select',
      data: DATETYPE,
    },
    {
      name: 'Time Type',
      field: 'time_type',
      value: null,
      type: 'input',
    },
    {
      name: 'Rate',
      field: 'rate',
      value: null,
      type: 'input',
    },
    {
      name: 'Remark',
      field: 'remark',
      value: null,
      type: 'input',
    },
  ]);
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenAddWorkTime(false);
  };

  const handleOk = async (e) => {
    if (!checkInput(data, ['date_type', 'time_type', 'rate'])) {
      return;
    }
    setIsLoading(true);
    try {
      const tmp = {};
      data.forEach((v) => {
        tmp[v.field] = v.value;
      });
      const resWorkTime = await AppAPI.WorkerSettingAPI.CreateWorkTime({
        projectId,
        data: tmp,
      });
      await updateTableAsync(workTimeGridApi.api, { add: [resWorkTime] });
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };
  const handleChange = (type, e) => {
    type.value = e.target.value;
    setData([...data]);
  };
  const handleChangeDate = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const handleChangeSelect = (type, e) => {
    type.value = e;
    setData([...data]);
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add work time'}
        style={{ width: 500 }}
      >
        {data.map((v) => (
          <>
            {v.type === 'input' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Input
                  placeholder='please, input'
                  onChange={handleChange.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'date' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <DatePicker
                  placeholder='please, input'
                  onChange={handleChangeDate.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'select' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Select
                  placeholder='please, input'
                  onChange={handleChangeSelect.bind(this, v)}
                  value={v.value}
                >
                  {v.data.map((v) => (
                    <Select.Option value={v}> {v} </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </>
        ))}
      </ModalTemplate>
    </>
  );
}
