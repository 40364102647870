import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';
import IconButton from 'src/components/gen/button/IconButton.gen';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../settings/General.settings';
import { appStore } from '../../../../store/App.store';

export default function AppPermissionTable({ data, setGridApi, setRowNode }) {
  const { setIsLoading } = appStore();
  const gridRef = useRef();
  const { organizationId, projectId } = useParams();

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to remove?')) return;
    setIsLoading(true);
    try {
      let data = await requestBimGrid('delete', '/api/permission', null, {
        organizationId,
        projectId,
        permissionId: e.node.data.id,
      });
      if (data) {
        await updateTableAsync(gridRef.current.api, { remove: [e.node.data] });
        gridRef.current.api.redrawRows();
      }
    } catch (ex) {
      message.warning(ex.message);
    }

    setIsLoading(false);
  };

  const handleEditItem = (e) => {
    setRowNode(e.node);
  };

  const [columns] = useState([
    {
      headerName: 'Display Name',
      field: 'display_name',
      filter: 'agSetColumnFilter',
      cellRendererSelector: (params) => {
        return {
          component: () => {
            return <span style={{ fontWeight: 'bold' }}> {params.value}</span>;
          },
        };
      },
    },
    {
      headerName: 'Group',
      field: 'group',
      filter: 'agSetColumnFilter',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',

      cellRendererSelector: (params) => {
        return {
          component: () => {
            return <span style={{ fontWeight: 'bold' }}> {params.value}</span>;
          },
        };
      },
    },

    {
      headerName: 'Description',
      field: 'description',
      filter: 'agSetColumnFilter',
      wrapText: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      wrapText: true,
    },

    {
      headerName: 'Action',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
      },
    },
  ]);
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon='edit'
          size='sssmall'
          onClick={handleEditItem.bind(this, params)}
        >
          Edit
        </IconButton>
        <IconButton
          type='delete'
          icon='delete'
          size='sssmall'
          onClick={handleDeleteItem.bind(this, params)}
        >
          Delete
        </IconButton>
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          suppressPaginationPanel={true}
          groupDefaultExpanded={1}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
