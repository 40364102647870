/* eslint-disable no-unused-vars */
import { Form, message } from 'antd';
import _ from 'lodash';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { AdminUpdateCompanyAPI } from 'src/api';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import LabelAndSelectForm from 'src/components/molecules/LabelAndSelectForm';
import Modal from 'src/components/molecules/Modal';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { appStore } from '../../../../../../store/App.store';

export default function ModalEditCompany({ setRowNode, rowNode, gridApi }) {
  const [form] = Form.useForm();
  const formRef = useRef();

  const { setIsLoading } = appStore();
  const { organizationId, projectId } = useParams();
  const close = () => {
    setRowNode();
  };

  const handleOk = async (e) => {
    if (!organizationId) return;
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};
      debugger;
      formData.forEach((value, key) => {
        data[key] = value;
      });

      const resData = await AdminUpdateCompanyAPI({
        ...data,
      });
      if (resData) {
        _.forEach(rowNode.data, (v, k) => {
          if (data[k] !== undefined) {
            rowNode.data[k] = data[k];
          }
        });
        await updateTableAsync(gridApi.api, { update: [rowNode.data] });
        gridApi.api.redrawRows();
        close();
      }
    } catch (ex) {
      if (ex.message) message.error(ex.message);
    }
    setIsLoading(false);
  };

  console.log(rowNode);

  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Edit Company'}>
        <div className='max-w-screen-sm'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndInputForm
              label='Company Name'
              name='name'
              isRequired
              placeholder='Enter company name'
              defaultValue={rowNode.data.name}
            />
            <LabelAndInputForm
              label='Company Short Name'
              name='short_name'
              isRequired
              placeholder='Enter company short name'
              defaultValue={rowNode.data.short_name}
            />

            <LabelAndSelectForm
              label='Company Type'
              name='type'
              placeholder='Select company type'
              isRequired
              defaultValue={rowNode.data.type}
              items={[
                'Main Contractor',
                'Sub Contractor',
                'Consultant',
                'Client',
              ].map((v) => (
                <option value={v}>{v}</option>
              ))}
            />

            <LabelAndInputForm
              label='Company Email'
              name='email'
              placeholder='Enter company email'
              defaultValue={rowNode.data.email}
            />

            <LabelAndInputForm
              label='Company Phone'
              name='phone'
              placeholder='Enter phone number'
              defaultValue={rowNode.data.phone}
            />
            <LabelAndInputForm
              label='Company Country'
              name='country'
              placeholder='Enter company country'
              defaultValue={rowNode.data.country}
            />
            <LabelAndInputForm
              label='Company Address'
              name='address'
              placeholder='Enter company address'
              defaultValue={rowNode.data.address}
            />
            <LabelAndInputForm
              label='Company City'
              name='city'
              placeholder='Enter company city'
              defaultValue={rowNode.data.city}
            />
            <LabelAndInputForm
              label='Company Postal Code'
              name='postal_code'
              placeholder='Enter company postal code'
              defaultValue={rowNode.data.postal_code}
            />
          </form>
        </div>
      </Modal>
    </>
  );
}
