import ModalAddWorkerDaily from 'src/components/layout/apps/worker/daily/modal/ModalAddWorkerDaily';
import ModalEditWorkerDaily from 'src/components/layout/apps/worker/daily/modal/ModalEditWorkerDaily';
import WorkerDailyTable from 'src/components/layout/apps/worker/daily/table/WorkerDaily.table';
import WorkerDailyToolbar from 'src/components/layout/apps/worker/daily/WorkerDailytoolbar';
import ModalTrackingModel from 'src/components/layout/apps/worker/modal/ModalTrackingModel';
import { workerStore } from 'src/store/Worker.store';

export default function WorkerDailyLayout() {
  const { isOpenAddWorkerDaily, isOpenEditWorkerDaily, isOpenTrackingModel } =
    workerStore();
  return (
    <>
      <div className='size-full p-1'>
        <WorkerDailyToolbar />
        <div className='flex h-[calc(100%-50px)] w-full gap-1'>
          <WorkerDailyTable />
        </div>
      </div>
      {isOpenAddWorkerDaily && <ModalAddWorkerDaily />}
      {isOpenEditWorkerDaily && <ModalEditWorkerDaily />}
      {isOpenTrackingModel && <ModalTrackingModel />}
    </>
  );
}
