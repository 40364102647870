/* eslint-disable no-unused-vars */
import React from 'react';

import _ from 'lodash';

export default function HeaderProperty() {
  return (
    <>
      <div style={{ display: 'flex', gap: 5 }}>
        <div
          style={{
            width: '50%',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Name</span>
        </div>
        <div
          style={{
            width: '50%',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Fixed Value/Property Name</span>
        </div>
        <div></div>
      </div>
    </>
  );
}
