import React from 'react';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { workerStore } from '../../../../../../store/Worker.store';

export default function HubDevicesToolbar() {
  const { setIsOpenAddHubDevices } = workerStore();
  const handleAddFolder = () => {
    setIsOpenAddHubDevices(true);
  };

  return (
    <>
      <div style={{ padding: '5px 2px' }}>
        <IconButton
          icon={'add'}
          size='ssmall'
          // type='save'
          onClick={handleAddFolder}
        ></IconButton>
      </div>
    </>
  );
}
