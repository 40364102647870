import create from 'zustand';

const initialState = {
  user: null,
  isLoading: false,
  isProcess: true,
  organization: null,
  organizations: [],
  locationPage: '',
  permission: [],
  widthApp: window.innerWidth,
  appName: null,
};
export const appStore = create((set) => ({
  ...initialState,
  setUser: (value) => set((state) => ({ user: value })),
  setPermission: (value) => set((state) => ({ permission: value })),
  setOrganization: (value) => set((state) => ({ organization: value })),
  setIsLoading: (value) => set((state) => ({ isLoading: value })),
  setIsProcess: (value) => set((state) => ({ isProcess: value })),
  setOrganizations: (value) => set((state) => ({ organizations: value })),
  setLocationPage: (value) => set((state) => ({ locationPage: value })),
  setWidthApp: (value) => set((state) => ({ widthApp: value })),
  setAppName: (value) => set((state) => ({ appName: value })),
  resetAppStore: () => set(initialState),
}));
