import { requestBimGrid } from '../../../functions/General.function';

export const CreateDMSMainFolder = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-main/add-folder', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const CreateDMSMainDrawing = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-main/add-drawing', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const UpdateDMSMain = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-main/update-one', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const GetDMSMainOne = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-main/get-one', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const DeleteDMSMainOne = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('delete', '/api/dms-main/delete-one', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const GetChidById = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-main/get-children-by-id', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};


