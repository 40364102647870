/* eslint-disable no-unused-vars */
import { message } from 'antd';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AdminAddCompanyAPI } from 'src/api';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import LabelAndSelectForm from 'src/components/molecules/LabelAndSelectForm';
import Modal from 'src/components/molecules/Modal';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { appStore } from '../../../../../../store/App.store';

export default function ModalAddCompany({ setClose, gridApi }) {
  const formRef = useRef();
  const { setIsLoading } = appStore();
  const { organizationId } = useParams();
  const close = () => {
    setClose(false);
  };

  const handleOk = async (e) => {
    if (!organizationId) return;
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};
      debugger;
      formData.forEach((value, key) => {
        data[key] = value;
      });

      const resData = await AdminAddCompanyAPI({
        ...data,
      });
      if (resData) {
        await updateTableAsync(gridApi.api, { add: [resData] });
        gridApi.api.redrawRows();
        close();
      }
    } catch (ex) {
      if (ex.message) message.error(ex.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Create Company'}>
        <div className='max-w-screen-sm'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndInputForm
              label='Company Name'
              name='name'
              isRequired
              placeholder='Enter company name'
            />
            <LabelAndInputForm
              label='Company Short Name'
              name='short_name'
              isRequired
              placeholder='Enter company short name'
            />

            <LabelAndSelectForm
              label='Company Type'
              name='type'
              placeholder='Select company type'
              isRequired
              items={[
                'Main Contractor',
                'Sub Contractor',
                'Consultant',
                'Client',
              ].map((v) => (
                <option value={v}>{v}</option>
              ))}
            />

            <LabelAndInputForm
              label='Company Email'
              name='email'
              placeholder='Enter company email'
            />

            <LabelAndInputForm
              label='Company Phone'
              name='phone'
              placeholder='Enter phone number'
            />
            <LabelAndInputForm
              label='Company Country'
              name='country'
              placeholder='Enter company country'
            />
            <LabelAndInputForm
              label='Company Address'
              name='address'
              placeholder='Enter company address'
            />
            <LabelAndInputForm
              label='Company City'
              name='city'
              placeholder='Enter company city'
            />
            <LabelAndInputForm
              label='Company Postal Code'
              name='postal_code'
              placeholder='Enter company postal code'
            />
          </form>
        </div>
      </Modal>
    </>
  );
}
