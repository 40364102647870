/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message,
    Input,
    Switch
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AppAPI } from '../../../../../api';
import { getRouteToNode } from '../../../../../../functions/General.function';



export default function ModalDMSMainEditFolder() {
    const { setIsModalDMSMainEditFolder, updateDMSMainNode, dmsMainGridApi } = dmsStore()
    const { setIsLoading, } = appStore()
    const [folderName, setFolderName] = useState()

    const close = () => {
        setIsModalDMSMainEditFolder(false)
    }

    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            let dmsData = await AppAPI.DMSMainAPI.GetDMSMainOne({ id: updateDMSMainNode.data.id })
            debugger
            setFolderName(dmsData.drawing_number)
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
            close()
        }

    }
    const handleOk = async (e) => {
        if (!folderName) {
            message.warning('Please input folder name')
            return
        }
        setIsLoading(true)
        try {
            const dmsData = await AppAPI.DMSMainAPI.UpdateDMSMain({ data: { drawing_number: folderName }, id: updateDMSMainNode.data.id })
            const route = getRouteToNode(updateDMSMainNode);
            dmsMainGridApi.api.applyServerSideTransaction({
                route: route.slice(0, route.length - 1),
                update: [dmsData],
            });
            setIsLoading(false)
            close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
        }
    }
    const handleChange = (e) => {
        setFolderName(e.target.value)
    }


    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Edit Folder'}
                style={{ width: 300 }}
            >
                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                        <span style={{ fontWeight: 'bold' }}>Folder name:</span>
                        <Input placeholder='Folder name' onChange={handleChange} value={folderName} />
                    </div>


                </div>


            </ModalTemplate>
        </>
    );
}

