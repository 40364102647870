import React from 'react';
import CostCodeTable from '../table/CostCode.table';
import CostCodeToolbar from './CostCode.toolbar';

export default function CostCodeLayout() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 2,
        height: '100%',
        widthL: '100%',
        flexDirection: 'column',
      }}
    >
      <CostCodeToolbar />
      <CostCodeTable />
    </div>
  );
}
