import { requestBimGrid } from '../../../../functions/General.function';

export const GetAll = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'get',
        '/api/share-qr-element/get-all',
        {},
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
export const GetOne = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'get',
        '/api/share-qr-element',
        {},
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
export const GetOne_Share = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'get',
        '/api/share-qr-element/share',
        {},
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
export const Create = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/share-qr-element',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const Update = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid('put', '/api/share-qr-element', body, {});
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const DeleteAll = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'delete',
        '/api/share-qr-element',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const GetFileQRElementSetting = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'get',
        '/api/share-qr-element/get-file',
        {},
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const SetFileQRElementSetting = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/share-qr-element/set-file',
        body,
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
