import { Popover, Upload } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { timelineStore } from '../../Timeline.store';

export default function TimelineDataSourceToolbar() {
  const { setIsOpenFieldSelector, excelFile, setExcelFile } = timelineStore();

  const handleChangeModel = (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        setExcelFile(info.file);
      }
    }
  };
  useEffect(() => {
    if (excelFile) {
      setIsOpenFieldSelector(true);
    }
  }, [excelFile]);

  const content = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Upload customRequest={handleChangeModel} showUploadList={false} accept='.xlsx' >
          <IconTextButton block={true} size='ssmall' type='save' icon={'excel'}>
            Add Excel
          </IconTextButton>
        </Upload>
        <Upload customRequest={handleChangeModel} showUploadList={false}>
          <IconTextButton block={true}size='ssmall' type='save' icon={'msproject'}>
            Add MS Project
          </IconTextButton>
        </Upload>
        <Upload customRequest={handleChangeModel} showUploadList={false}>
          <IconTextButton  block={true} size='ssmall' type='save' icon={'p6'}>
            Add P6 Project
          </IconTextButton>
        </Upload>
      </div>
    );
  };
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: 5,
          }}
        >
          <Popover
            content={content}
            zIndex={250}
            trigger='click'
            placement='bottomLeft'
          >
            <IconTextButton
              url={
                'https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/48/external-Import-files-tanah-basah-glyph-tanah-basah.png'
              }
              size='ssmall'
              // type='save'
            >
              Add
            </IconTextButton>
          </Popover>

  
        </div>
      </div>
    </>
  );
}

