import ModalEditPaymentMonth from 'src/components/layout/apps/worker/payment_month/modal/ModalEditPaymentMonth';
import WorkerPaymentMonthTable from 'src/components/layout/apps/worker/payment_month/table/WorkerPaymentMonth.table';
import WorkerPaymentMonthToolbar from 'src/components/layout/apps/worker/payment_month/WorkerPaymentMonth.toolbar';
import { workerStore } from '../../../../../store/Worker.store';

export default function WorkerPaymentMonthLayout() {
  const { isOpenEditWorkerPaymentMonth } = workerStore();
  return (
    <>
      <div style={{ padding: 5, height: '100%', widthL: '100%' }}>
        <WorkerPaymentMonthToolbar />
        <div className='flex h-[calc(100%-50px)] w-full gap-1'>
          <WorkerPaymentMonthTable />
        </div>
      </div>
      {isOpenEditWorkerPaymentMonth && <ModalEditPaymentMonth />}
    </>
  );
}
