import { requestBimGrid } from '../../../functions/General.function';

export const GetAllWorkPaymentMonthByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/payment-month/get-all-worker-payment-month-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const UpdateOneWorkPaymentMonth = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/payment-month/update-one-worker-payment-month',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
