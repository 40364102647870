import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { convertHexColorToVector4 } from '../../../../../../functions/Viewer.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { viewerStore } from '../../../../../../store/Viewer.store';
import IconButton from '../../../../../gen/button/IconButton.gen';
import ClashNameCell from '../../clash_detection/cell/ClashName.cell';
import ClashStatusCell from '../../clash_detection/cell/ClashStatus.cell';

let colorA = convertHexColorToVector4('#00FF00');
let colorB = convertHexColorToVector4('#FF0000');

export default function ClashCheckResultTable() {
  const [search] = useSearchParams();
  const { viewer, clashDetectionPoint, clashTestDetail } = viewerStore();

  const gridRef = useRef();
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: ClashNameCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditorPopup: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: ['New', 'Active', 'Reviewed', 'Approved', 'Resolved'],
        cellRenderer: ClashStatusCell,
        searchDebounceDelay: 500,
      },
      valueSetter: (params) => {
        if (params.newValue !== params.oldValue) {
          params.data.status = params.newValue;
          params.data.colorName = params.newValue.toLowerCase();
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: ['name'],
            force: true,
          });
          clashDetectionPoint.editExistingPoint(params.node.id, params.data);
          return true;
        }
        return false;
      },
    },
    // {
    //     headerName: 'Level',
    //     field: 'level',
    //     filter: 'agSetColumnFilter',
    // },
    // {
    //     headerName: 'Grid Intersect',
    //     field: 'gridIntersect',
    //     filter: 'agSetColumnFilter',
    // },
    // {
    //     headerName: 'Found',
    //     field: 'found',
    //     filter: 'agSetColumnFilter',
    // },
    // {
    //     headerName: 'Approved By',
    //     field: 'approvedBy',
    //     filter: 'agSetColumnFilter',
    // },
    // {
    //     headerName: 'Approved Date',
    //     field: 'approvedDate',
    //     filter: 'agSetColumnFilter',

    // },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Assigned To',
      field: 'assignedTo',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: 100,
      },
    },
    // {
    //     headerName: 'Distance',
    //     field: 'distance',
    //     filter: 'agSetColumnFilter',
    // },
    {
      headerName: 'Action',
      lockPosition: 'right',
      suppressMovable: true,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];

  useEffect(() => {
    if (clashTestDetail) {
      const models = viewer.impl.modelQueue().getModels();
      _.forEach(models, (model) => {
        viewer.clearThemingColors(model);
      });
      _.forEach(clashTestDetail, (v) => {
        viewer.setThemingColor(v.elementA.id, colorA, v.elementA.model, true);
        viewer.setThemingColor(v.elementB.id, colorB, v.elementB.model, true);
      });
    }
  }, [clashTestDetail]);
  useEffect(() => {
    if (gridRef.current) {
      clashDetectionPoint.addEventListener(
        'select',
        handleSelection.bind(this),
        false
      );
      return () => {
        clashDetectionPoint.removeEventListener(
          'select',
          handleSelection.bind(this),
          false
        );
      };
    }
  }, [gridRef.current]);

  const handleSelection = (e) => {
    // gridRef.current.api.deselectAll()
    // if(e.nodes.length>0){
    //     let index =e.nodes[0].index;
    //     let item = null
    //     let rowIndex =-1
    //      gridRef.current.api.forEachLeafNode((rowNode, i) => {
    //         if(rowNode.id ==index){
    //             rowIndex= _.toNumber(rowNode.id)
    //             item = rowNode.data
    //         }
    //     })
    //     if(rowIndex>=0){
    //         gridRef.current.api.setFocusedCell(rowIndex, 0)
    //         gridRef.current.api.ensureIndexVisible(rowIndex, 'top')
    //         gridRef.current.api.getRowNode(rowIndex).setSelected(true, true)
    //         let clone ={...item}
    //         clone.label = e.position
    //         setClashDetectionSelected(clone)
    //     }
    // }else{
    //     setClashDetectionSelected()
    // }
  };

  const handleSelect = (params) => {
    const models = viewer.impl.modelQueue().getModels();
    if (params.isGroup) {
      let nodes = params.node.allLeafChildren;
      let temp = {};
      _.forEach(nodes, (v) => {
        const modelAId = v.elementA.model.id;
        if (!temp[modelAId]) temp[modelAId] = [];
        temp[modelAId].push(v.elementA.id);

        const modelBId = v.elementA.model.id;
        if (!temp[modelBId]) temp[modelBId] = [];
        temp[modelBId].push(v.elementA.id);
      });
      let select = [];
      _.forEach(temp, (v, k) => {
        let index = _.findIndex(models, (v) => {
          return v.id == k;
        });
        select.push({ model: models[index], ids: v });
      });
      // viewer.select(temp)
      viewer.isolate(select[0].ids, select[0].model);
      viewer.impl.selector.setAggregateSelection(select);
      viewer.fitToView(select[0].ids, select[0].model);
    } else {
      const modelAId = params.data.elementA.model.id;
      const modelBId = params.data.elementB.model.id;
      let indexA = _.findIndex(models, (v) => {
        return v.id === modelAId;
      });
      let indexB = _.findIndex(models, (v) => {
        return v.id === modelBId;
      });
      let select = [];
      if (indexA === indexB) {
        select.push({
          model: models[indexA],
          ids: [params.data.elementA.id, params.data.elementB.id],
        });
      } else {
        select.push(
          { model: models[indexA], ids: [params.data.elementA.id] },
          { model: models[indexB], ids: [params.data.elementB.id] }
        );
      }
      viewer.isolate(select[0].ids, select[0].model);
      viewer.impl.selector.setAggregateSelection(select);
      viewer.fitToView(select[0].ids, select[0].model);
    }
  };
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          url='https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/20/000000/external-selection-graph-design-kiranshastry-lineal-kiranshastry-1.png'
          size='small'
          ghost={true}
          border={false}
          iconStyle={{ background: '#000000' }}
          onClick={handleSelect.bind(this, params)}
        />
        {/* <Button size='small' className={`prefab-custom-button ${(roleForAddEditModule[role] || user.isAdmin) ? 'delete' : 'disabled'}`} onClick={handleDeleteModule.bind(this, params.data)} type='danger'
          disabled={(roleForAddEditModule[role] || user.isAdmin)? false : false} ghost >Delete</Button> */}
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    // setGridApi(params)
  }, []);
  return (
    <>
      <div style={{ width: '100%', height: '100%', padding: 5 }}>
        <div
          style={{ width: '100%', height: '100%' }}
          className='ag-theme-alpine'
        >
          <AgGridReact
            ref={gridRef}
            rowData={clashTestDetail}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows={true}
            onGridReady={onGridReady}
            groupDisplayType='multipleColumns'
            rowGroupPanelShow='always'
            rowHeight={30}
            // pagination={true}
            // paginationPageSize={pageSizeModuleList}
            suppressPaginationPanel={true}
            context={false}
            suppressContextMenu={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
}
