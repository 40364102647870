import { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import { projectStore } from '../../../../../store/Docs.store';

import _ from 'lodash';
import IconButton from 'src/components/gen/button/IconButton.gen';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../settings/General.settings';

export default function RoleTable({ data, setGridApi, setRowNode }) {
  const navigate = useNavigate();
  const { setIsLoading } = appStore();
  const { fileData, setFileData, setFileName, type, folder, setIsAddFile } =
    projectStore();
  const gridRef = useRef();
  const [search] = useSearchParams();
  const { organizationId, projectId } = useParams();

  const handleDeleteItem = async (e) => {
    setIsLoading(true);
    let reqData = [];
    if (e.node.group) {
      let nodes = e.node.allLeafChildren;
      _.forEach(nodes, (v) => {
        reqData.push(v.data);
      });
    } else {
      reqData = [e.node.data];
    }
    setIsLoading(true);
    let userRole = await requestBimGrid(
      'delete',
      '/api/user/removeRole',
      { roleId: e.data.roleId, projectId, userId: e.data.userId },
      { organizationId, projectId }
    );
    if (userRole) {
      await updateTableAsync(gridRef.current.api, { remove: reqData });
    }
    setIsLoading(false);
  };

  const handleAddItem = (e) => {
    setRowNode(e.node);
  };
  const [columns, setColumns] = useState([
    {
      headerName: 'Role Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      rowGroup: true,
      hide: true,
      cellRendererSelector: (params) => {
        return {
          component: () => {
            return <span style={{ fontWeight: 'bold' }}> {params.value}</span>;
          },
        };
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Action',
      lockPosition: 'right',
      suppressMovable: true,

      minWidth: 70,
      maxWidth: 70,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        const level = params.node.level;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        } else {
          return {
            component: handleActionGroup,
          };
        }
      },
    },
  ]);
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };
  const handleActionGroup = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'add'}
          size='sssmall'
          onClick={handleAddItem.bind(this, params)}
        />
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          suppressPaginationPanel={true}
          groupDefaultExpanded={1}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
