import React, { } from "react";
import DMSMainTable from "./table/DMSMain.table";
import DMSMainToolbar from "./DMSMain.toolbar";
import { dmsStore } from "../../../../../store/DMS.store";
import ModalDMSMainAddFolder from "./modal/ModalDMSMainAddFolder";
import ModalDMSMainEditFolder from "./modal/ModalDMSMainEditFolder";
import ModalDMSMainAddDrawing from "./modal/ModalDMSMainAddDrawing";
import ModalDMSMainEditDrawing from "./modal/ModalDMSMainEditDrawing";



export default function DMSMainLayout() {

    const { isModalDMSMainAddFolder, isModalDMSMainEditFolder, updateDMSMainNode, isModalDMSMainEditDrawing
        , addDMSMainParentNode, isModalDMSMainAddDrawing
    } = dmsStore()


    return (
        <>
            <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }} >
                <div style={{ height: '100%', width: '100%', display: "flex", flexDirection: 'column', gap: 5 }} >
                    <DMSMainToolbar />
                    <DMSMainTable />
                </div>

            </div>
            {isModalDMSMainAddFolder && <ModalDMSMainAddFolder />}
            {isModalDMSMainEditFolder && updateDMSMainNode && <ModalDMSMainEditFolder />}
            {isModalDMSMainAddDrawing && addDMSMainParentNode && <ModalDMSMainAddDrawing />}
            {isModalDMSMainEditDrawing && updateDMSMainNode && <ModalDMSMainEditDrawing />}
        </>

    );
}


