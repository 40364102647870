import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { appStore } from 'src/store/App.store';

export default function ManagementOrganizationRoute() {
  const { organization, user } = appStore();
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [isProcess, setIsProcess] = useState(true);

  useEffect(() => {
    if (organization.owner_id !== user.id) {
      navigate('/admin/organizations');
      return;
    }
    setIsProcess(false);
  }, [organizationId]);

  return (
    <>
      {!isProcess && (
        <>
          <Outlet />
        </>
      )}
    </>
  );
}
