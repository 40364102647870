import React, { } from "react";
import { Typography } from 'antd';
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import IconButton from "../../../../../gen/button/IconButton.gen";


export default function CardFolder({ id, title }) {
    const [search, setSearchParams] = useSearchParams();
    const handleFolder = () => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('folderId', id);
        setSearchParams(queryParams);

    }

    return (
        <>
            <div style={{
                display: 'flex', padding: '0px 5px', gap: 5,
                alignItems: 'center',
                boxShadow: '0px 0px 2px 0px',
                width: 150
            }}
                onClick={handleFolder}
            >
                <IconButton icon={'folder'} border={false} size="small" />
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span style={{ fontWeight: 'bold' }} className="main" >{title}</span>
                </div>


            </div>


        </>



    )
}

