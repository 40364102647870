/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import { qrElementStore } from '../QRElement.store';
import { v4 } from 'uuid';
import QRCode from 'qrcode';
import { useParams, useSearchParams } from 'react-router-dom';
import { ViewerAPI } from '../../../../../../api';
import { viewerStore } from '../../../../../../../store/Viewer.store';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { message } from 'antd';
import { viewerSelect } from '../../../../../../../functions/Viewer.function';

export default function QRElementInformationModal() {
  const {
    currentElement,
    fileQRElementId,
    setCurrentElement,
    setQRElementLoading,
  } = qrElementStore();
  const [search] = useSearchParams();
  const { viewer } = viewerStore();
  const { organizationId, projectId } = useParams();
  const fileId = search.get('fileId');
  const [qrCode, setQrCode] = useState();
  const [properties, setProperties] = useState([]);
  const [url, setUrl] = useState('');
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setQRElementLoading(true);
    try {
      const url = `${window.location.origin}/share/qr-element?organizationId=${organizationId}&projectId=${projectId}&fileId=${fileId}&qrId=${fileQRElementId}&elementId=${currentElement.externalId}`;
      const img = await QRCode.toDataURL(url);
      setUrl(url);
      setQrCode(img);
      let resQRElement = await ViewerAPI.QRElementAPI.GetOne
      ({
        id: fileQRElementId,
      });
      let temp = [];
      _.forEach(resQRElement.properties, (v) => {
        let index = _.findIndex(currentElement.properties, (i) => {
          return i.displayName === v.property;
        });
        let value;
        if (index >= 0) {
          value = currentElement.properties[index].displayValue;
        }
        temp.push({
          name: v.name,
          value,
        });
      });
      setProperties(temp);
      handleSelect();
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRElementLoading(false);
  };
  const close = () => {
    setCurrentElement();
  };
  const handleSelect = () => {
    let model = viewer.impl.modelQueue().findModel(currentElement.modelId);
    viewerSelect(viewer,[{ model: model, ids: [currentElement.dbId] }]);
    viewer.fitToView([currentElement.dbId], model);
  };

  const handleOk = async (e) => {};

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'QR Element'}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={false}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            gap: 10,
            maxHeight: 'calc(100vh - 550px)',
            overflow: 'auto',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 5,
            }}
          >
            <IconTextButton
              icon={'select'}
              type='save'
              size='ssmall'
              onClick={handleSelect}
            >
              Select Element
            </IconTextButton>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                border: '1px solid black',
                borderRadius: 10,
                padding: 10,
                overflow: 'auto',
                height: '100%',
              }}
            >
              {properties.map((i) => (
                <div style={{ display: 'flex', gap: 5 }}>
                  <span style={{ fontWeight: 'bold' }}>{`${i.name}: `}</span>
                  <span style={{ fontWeight: '' }}>{i.value}</span>
                </div>
              ))}
            </div>
          </div>

          <div>
            <IconTextButton
              icon={'copy'}
              type='save'
              size='ssmall'
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(url);
                  message.info('Content copied to clipboard');
                } catch (err) {
                  message.error('Failed to copy: ', err);
                }
              }}
            >
              Copy
            </IconTextButton>
            <img src={qrCode} width={150} />
            <IconTextButton
              icon={'download'}
              type='save'
              size='ssmall'
              onClick={() => {
                var a = document.createElement('a');
                a.href = qrCode;
                a.download = 'QR Code.png';
                a.click();
              }}
            >
              Download
            </IconTextButton>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
