import { Form, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HorizontalDivider from 'src/components/atoms/HorizontalDivider';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import Modal from 'src/components/molecules/Modal';
import {
  requestBimGrid,
  resetAll,
} from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import { viewerStore } from '../../../../../store/Viewer.store';

export default function ModalSelectOrganization({ setClose }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setIsLoading, user, organization, resetAppStore } = appStore();
  const { resetViewerStore } = viewerStore();
  const [data, setData] = useState([]);
  const [organizationId, setOrganizationId] = useState('');
  const { projectId } = useParams();
  const close = () => {
    setClose(false);
  };

  const handleOk = async (e) => {
    form
      .validateFields()
      .then(async (values) => {
        setIsLoading(true);

        setIsLoading(false);
      })
      .catch((ex) => {
        message.error(ex);
      });
  };

  useEffect(() => {
    run();
  }, []);

  const run = async () => {
    try {
      setIsLoading(true);
      let respData = await requestBimGrid(
        'get',
        '/api/organization/getAllByUserId',
        null,
        { organizationId, projectId }
      );
      if (respData) {
        setData(respData);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };
  const handleAdminAccount = () => {
    navigate('/admin/organizations');
  };
  const handleChangeForm = (e) => {
    setOrganizationId(e);
  };
  const handleSelectOrganization = () => {
    navigate(`/organization/${organizationId}`);
  };
  const handleLogout = () => {
    resetAll(resetAppStore, resetViewerStore);
    navigate('/');
  };
  return (
    <>
      <Modal
        closeable={false}
        onClose={close}
        onOk={handleOk}
        title={'Select Organization'}
        footer={
          <>
            <div className='w-[500px] max-w-screen-md px-3.5 pb-4'>
              <PrimaryButton
                isFull
                onClick={handleSelectOrganization}
                type={!organizationId ? 'disabled' : 'primary'}
              >
                Select
              </PrimaryButton>
              <HorizontalDivider label={'OR'} />
              <PrimaryButton
                isFull
                onClick={handleAdminAccount}
                type={'primary'}
              >
                Setup new organization
              </PrimaryButton>
              <HorizontalDivider label={'OR'} />
              <PrimaryButton isFull onClick={handleLogout} type='delete'>
                {'Log out'}
              </PrimaryButton>
            </div>
          </>
        }
      >
        <div
          style={{
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <span className='text-base font-bold text-black dark:text-white'>
            Organization Name
          </span>
          <Select
            className='idd-search-input-form'
            showSearch
            onChange={handleChangeForm}
            value={organizationId}
          >
            {data.map((i) => (
              <Select.Option key={i?.id}> {i.name} </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
}
