export const colorCategory = {
    'Category 1': '#FFD966',
    'Category 2': 'orange',
    'Category 3': 'red',
}

export const colorStatus = {
    'Close': 'green',
    'Open': 'red',
}

export const issueImagePath = (organizationId,projectId,path) => {
    return `bimgrid/${organizationId}/${projectId}/issue/${path}`
}