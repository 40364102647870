import React, { useEffect, useState } from 'react';
import { Button, Form, message } from 'antd';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import { requestBimGrid } from '../../../../../functions/General.function';
import IconButton from '../../../../gen/button/IconButton.gen';
import { exportMarkup, exportMarkupImage } from '../../util/Coordination.util';
import IssueGen from './gen/issue.gen';
import { issueStore } from './Issue.store';





export default function IssueAdd({ setType, setLoading, coordinationPoint, isLinkClash, handleBackExt, clashPositions }) {
    const [search] = useSearchParams();
    const [form] = Form.useForm();
    const { viewer } = viewerStore()
    const { setIssueData, issueData } = issueStore()
    const [image, setImage] = useState()
    const [viewState, setViewState] = useState()
    const fileId = search.get('fileId')
    const versionId = search.get('versionId')
    const { organizationId, projectId } = useParams()
    const [isNewPoint, setIsNewPoint] = useState()
    const [point, newPoint] = useState()
    useEffect(() => {
        if (!viewer)
            setType('list')
        setLoading(true)
        coordinationPoint?.hidePointCloud()

        setTimeout(() => {
            setLoading(false)
            handleRefresh()
        }, 1000);
        return () => {
            coordinationPoint?.disableAddNewPoint()
            coordinationPoint?.unhidePointCloud()
        }
    }, [])

    useEffect(() => {
        if (isNewPoint) {
            coordinationPoint?.enableAddNewPoint(point)
        }
        else {
            let point = coordinationPoint?.disableAddNewPoint()
            newPoint(point)
        }
    }, [isNewPoint])
    const handleAdd = () => {
        if (!isLinkClash) {
            if (isNewPoint) {
                message.warning('You need to turn off pick point')
                return
            }
            if (!point) {
                message.warning('You need to pick a point')
                return
            }
        }
        form.validateFields()
            .then(async values => {
                setLoading(true)
                const markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                const tempModels = []
                const models = viewer.impl.modelQueue().getModels()
                _.forEach(models, v => {
                    if (v.id === viewer.impl.model.id) {
                        tempModels.push({
                            fileId, guid: viewer.impl.model.getDocumentNode().data.guid, versionId,
                            name: v.myData.loadOptions.modelNameOverride
                        })
                    } else {
                        tempModels.push({
                            fileId: v.myData.loadOptions.fileId, guid: v.myData.loadOptions.guid,
                            versionId: v.myData.loadOptions.versionId, name: v.myData.loadOptions.modelNameOverride
                        })
                    }
                })
                let clone = { ...values }
                clone.view_state = viewState
                clone.file_id = fileId
                clone.version_id = versionId
                clone.status = 'Open'
                clone.models = tempModels
                clone.positions =
                    isLinkClash ? clashPositions :
                        [{ x: point.geometry.attributes.position.array[0], y: point.geometry.attributes.position.array[1], z: point.geometry.attributes.position.array[2] }]
                clone.markup = await exportMarkup(viewer)
                // console.log(clone.position)
                let data = await requestBimGrid('post', '/api/issue', { data: clone, image, organizationId, projectId }, {
                    organizationId, projectId
                })
                if (data) {
                    let cloneIssueData = [...issueData]
                    data.colorName = data.category //! add color name
                    cloneIssueData.splice(0, 0, data)
                    setIssueData(cloneIssueData)
                    coordinationPoint?.setMarkupData(cloneIssueData)
                    if (handleBackExt)
                        handleBackExt()
                    else
                        handleBack()
                }
                setLoading(false)
            })
            .catch(ex => {
                setLoading(false)
            })
    }

    const handleRefresh = async () => {
        let img = await exportMarkupImage(viewer)
        setImage(img)
        setViewState(viewer.getState())
    }
    const handleBack = () => {
        setType('list')
    }
    const handleAddIconIssue = () => {
        setIsNewPoint(!isNewPoint)
    }
    return (
        <>
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={handleBackExt ? handleBackExt : handleBack} />
                    <span style={{ fontWeight: 'bold' }} >   New Issue</span>
                </div>
                {!isLinkClash &&
                    <div style={{ display: 'flex', gap: 5 }}>
                        <span style={{ fontWeight: 'bold' }} >You need to set issue point</span>
                        <IconButton
                            url={'https://img.icons8.com/ios-filled/50/null/hand-up.png'}
                            size='ssmall'
                            type={isNewPoint ? 'edit' : point ? 'save' : 'regular'}
                            onClick={handleAddIconIssue}
                        />

                    </div>
                }

            </div>

            <IssueGen
                image={image}
                form={form}
                handleRefresh={handleRefresh}
            />
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <Button className={`idd-custom-button block save`} onClick={handleAdd}
                >Create Issue</Button>

            </div>


        </>

    );
}


