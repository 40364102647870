import { Upload, Button, DatePicker, Tree } from 'antd';
import React, { useEffect, useRef, useState, } from 'react';
import _ from 'lodash';
import { appStore } from '../../../../../store/App.store';
import { viewerStore } from '../../../../../store/Viewer.store';


export default function LevelFilterElement() {
  const [loading, setLoading] = useState()
  const {treeModelData} = viewerStore()
  const [levelTree, setLevelTree] = useState([])
  // useEffect(() => {
  //   treeModelData.addEventListener('done', run)
  //   treeModelData.addEventListener('add', run)
  //   treeModelData.addEventListener('remove', run)
  //   return () =>{
  //       treeModelData.removeEventListener('done', run)
  //       treeModelData.removeEventListener('add', run)
  //       treeModelData.removeEventListener('remove', run)
  //   }
  // }, [])
  useEffect(()=>{
    run()
  },[])
  const run =()=>{
    let temp=[]
    _.forEach(treeModelData.level, v=>{
      temp.push({
        title:'name',
        key: v.id
      })
    })
    setLevelTree(temp) 
  }
  const updateTreeData = (list, key, children) =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
  const onLoadData = ({ key, children }) =>
    new Promise((resolve) => {
      if (children) {
        resolve();
        return;
      }
      setTimeout(() => {
        setLevelTree((origin) =>
          updateTreeData(origin, key, [
            {
              title: 'Child Node',
              key: `${key}-0`,
            },
            {
              title: 'Child Node',
              key: `${key}-1`,
            },
          ]),
        );
        resolve();
      }, 1000);
    });
  return (
    <>
       <Tree loadData={onLoadData} treeData={levelTree} />


    </>

  );
}

