import React, { useEffect, useRef, useState } from "react";
import { Button, Select, Tree } from 'antd';
import { viewerStore } from "../../../../../store/Viewer.store";
import _, { clone } from "lodash";
import Loader from "../../../../loader/LoaderApp";
import { DownOutlined } from '@ant-design/icons';
import { getChildrenIds, getChildrenNode, getElementProperty, getLeafChildrenIds, getProperties } from "../../../../../functions/Viewer.function";
import { updateTreeData } from "../../../../../functions/General.function";
import { checkClash, getAttributePosition } from "../../util/ClashDetection.util";
import { wrap } from 'comlink';
import Worker from '../../../../../workers/ClashDetection.worker';

const { Option } = Select;
let workers = []
export default function ClashCheckSelectModel() {
    const { setClashTestDetail, viewer, clashDetectionPoint } = viewerStore()
    const [loading, setLoading] = useState()
    const [type, setType] = useState('list')
    const ref = useRef()
    const [elementA, setElementA] = useState()
    const [elementB, setElementB] = useState()
    const [keysA, setKeysA] = useState()
    const [elementASelected, setElementASelected] = useState()
    const [elementBSelected, setElementBSelected] = useState()
    const refA = useRef()
    const refB = useRef()
    useEffect(() => {
        run()
        workers.push(
            wrap(new Worker()),
            wrap(new Worker()),
            wrap(new Worker())
        )
        return (() => {
            workers.forEach(v => {
                v.terminate()
            })
            workers = []
        })
    }, [])

    const run = async () => {
        const models = viewer.impl.modelQueue().getModels();
        let data = []
        for (let i in models) {
            const model = models[i]
            let instanceTree = model.getData().instanceTree;
            data.push({ key: model.myData.loadOptions.fileId, id: instanceTree.getRootId(), model: model, title: model.myData.loadOptions.modelNameOverride, isLeaf: false })
        }
        setElementA(data)
        setElementB(data)
    }
    const onLoadData = (side, { key, id, model, children }) =>
        new Promise(async (resolve) => {
            if (children) {
                resolve();
                return;
            }
            let data = await getChildrenNode(model.getData().instanceTree, id, model)
            _.forEach(data, v => {
                // if (!v.isLeaf) {

                // }
                delete v.properties
                v.id = v.dbId
                v.key = model.myData.loadOptions.fileId + "_" + v.dbId
                v.title = v.name
                v.className = 'idd-tree-node'
                v.model = model
                v.fileId = model.myData.loadOptions.fileId
            })
            if (side === 'A') {
                setElementA((origin) =>
                    updateTreeData(origin, key, data),
                );
            } else {
                setElementB((origin) =>
                    updateTreeData(origin, key, data),
                );
            }

            resolve();

        });

    const onCheck = (side, d, c) => {
        if (side === 'A') {
            setElementASelected(c.checkedNodes)
        } else {
            setElementBSelected(c.checkedNodes)
        }
    }
    const handleRunTest = async () => {
        console.log(refA.current)
        if (!elementASelected || !elementBSelected) {
            return
        }
        let tempA = []
        let checkA = []
        for (let i in elementASelected) {
            let node = elementASelected[i]
            if (node.isLeaf) {
                let clone = {
                    id: node.id,
                    name: node.title,
                    model: node.model,
                    key: node.key,
                    fileId: node.fileId
                }
                if (!checkA.includes(node.key)) {
                    tempA.push(clone)
                    checkA.push(node.key)
                }
            } else {
                const instanceTree = node.model.getData().instanceTree
                let ids = await getLeafChildrenIds(instanceTree, node.id)
                for (let i in ids) {
                    let properties = await getProperties(node.model, ids[i])
                    delete properties.properties
                    properties.id = properties.dbId
                    properties.key = node.model.myData.loadOptions.fileId + "_" + properties.dbId
                    properties.model = node.model
                    properties.fileId = node.model.myData.loadOptions.fileId
                    if (!checkA.includes(properties.key)) {
                        checkA.push(properties.key)
                        tempA.push(properties)
                    }

                }
            }
        }


        let tempB = []
        let checkB = []
        for (let i in elementBSelected) {
            let node = elementBSelected[i]
            if (node.isLeaf) {
                let clone = {
                    id: node.id,
                    name: node.title,
                    model: node.model,
                    key: node.key,
                    fileId: node.fileId
                }
                if (!checkB.includes(node.key)) {
                    tempB.push(clone)
                    checkB.push(node.key)
                }
            } else {
                const instanceTree = node.model.getData().instanceTree
                let ids = await getLeafChildrenIds(instanceTree, node.id)
                for (let i in ids) {
                    let properties = await getProperties(node.model, ids[i])
                    properties.isLeaf = instanceTree.getChildCount(ids[i]) === 0
                    delete properties.properties
                    properties.id = properties.dbId
                    properties.key = node.model.myData.loadOptions.fileId + "_" + properties.dbId
                    properties.model = node.model
                    properties.fileId = node.model.myData.loadOptions.fileId
                    if (!checkB.includes(properties.key)) {
                        checkB.push(properties.key)
                        tempB.push(properties)
                    }
                }
                // tempB = tempB.concat(data)
            }
        }


        // let result = await checkClash(tempA, tempB, viewer)

        let store = {}
        let total = []
        let mul = tempA.length * tempB.length
        _.forEach(tempA, a => {
            let keyA = a.key
            let modelA = a.model
            getAttributePosition(a.id, modelA.getData().instanceTree, viewer, modelA)
                .then(attributesA => {
                    _.forEach(tempB, b => {
                        let keyB = b.key
                        if (keyA === keyB) {
                            mul--
                            if (mul === 0) {
                                console.log(total)
                            }
                        } else {
                            let modelB = b.model
                            getAttributePosition(b.id, modelB.getData().instanceTree, viewer, modelB)
                                .then(attributesB => {
                                    total.push({ elA: attributesA, elB: attributesB })
                                    mul--
                                    if (mul === 0) {
                                        console.log(total)
                                    }
                                })
                        }

                    })
                })
        })
        return
        // for (let a in tempA) {
        //     let keyA = tempA[a].key
        //     let modelA = tempA[a].model
        //     let attributesA = await getAttributePosition(tempA[a].id, modelA.getData().instanceTree, viewer, modelA)
        //     for (var b in tempB) {
        //         let keyB = tempB[b].key
        //         if (keyA === keyB) continue
        //         let modelB = tempB[b].model
        //         let attributesB = await getAttributePosition(tempB[b].id, modelB.getData().instanceTree, viewer, modelB)
        //         total.push({ elA: attributesA, elB: attributesB })
        //     }
        // }
        // tempA.forEach(a => {
        //     tempB.forEach(b => {
        //         total.push({ elA: a, elB: b })
        //     })
        // })
        let partSize = Math.floor(total.length / 3)
        const part1 = total.slice(0, partSize);
        const part2 = total.slice(partSize, partSize * 2);
        const part3 = total.slice(partSize * 2);
        const part = [part1, part2, part3]
        let checkTest = []
        for (let i = 0; i < 3; i++) {
            if (part[i].length > 0) {
                let worker = workers[i]// wrap(new Worker())
                let run = worker.checkClash(part[i])
                checkTest.push(run)
                // workers.push(worker)
            }

        }
        let txt = await Promise.all(checkTest)
        let result = []
        // _.forEach(txt, (v, k) => {
        //     result = result.concat(v)
        //     workers[k].terminate()
        // })
        // setClashTestDetail(result)
        // _.forEach(result, v => {
        //     v.colorName = v.status.toLowerCase()
        // })
        // clashDetectionPoint.setMarkupData(result)
        // console.log(result)
    }
    return (
        <>
            <div style={{ display: 'flex', gap: 5, padding: 5, height: '100%', flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: 5, height: 'calc(100% - 30px)' }}>
                    <div style={{ border: '1px solid black', width: '50%', padding: 5, overflow: 'auto', backgroundColor: 'white', textOverflow: 'ellipsis' }} >
                        <Tree
                            ref={refA}
                            checkable
                            showLine
                            switcherIcon={<DownOutlined />}
                            defaultExpandedKeys={['0-0-0']}
                            // onSelect={onSelect}
                            treeData={elementA}
                            loadData={onLoadData.bind(this, 'A')}
                            onCheck={onCheck.bind(this, 'A')}
                        />
                    </div>
                    <div style={{ border: '1px solid black', width: '50%', padding: 5, overflow: 'auto', backgroundColor: 'white', textOverflow: 'ellipsis' }} >
                        <Tree
                            ref={refB}
                            checkable
                            showLine
                            switcherIcon={<DownOutlined />}
                            defaultExpandedKeys={['0-0-0']}
                            // onSelect={onSelect}
                            treeData={elementB}
                            loadData={onLoadData.bind(this, 'B')}
                            onCheck={onCheck.bind(this, 'B')}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                    <Button className={`idd-custom-button block save`} onClick={handleRunTest}
                    >Run Test</Button>

                </div>
            </div>

        </>
    );
}
