/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Tabs } from 'antd';
import _ from 'lodash';
import { dfmaStore } from '../DFMA.store';
import IconButton from '../../../../../../gen/button/IconButton.gen';

export default function ParameterItem({ item, index ,parameterSetup,setParameterSetup}) {



  const handleChange = (type, e) => {
    item[type] = e.target.value;
    setParameterSetup([...parameterSetup]);
  };


  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 5,
          flexDirection:'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', gap: 5, width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Input
              style={{ width: '100%' }}
              onChange={handleChange.bind(this, 'name')}
              placeholder='Name'
              value={item?.name}
              disabled
            />
          </div>
          <div style={{ width: '100%' }}>
            <Input
              rows={1}
              style={{ width: '100%' }}
              onChange={handleChange.bind(this, 'value')}
              placeholder='Parameter Name'
              value={item?.value}

            />
          </div>
        </div>

        {/* {!isView&&  <IconButton
          type='delete'
          size='ssmall'
          icon={'delete'}
          onClick={handleDelete}
        />} */}
      </div>
    </>
  );
}
