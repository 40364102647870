/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Input, Tabs, message } from 'antd';
import { qrDrawingStore } from '../QRDrawing.store';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import { useSearchParams, useParams } from 'react-router-dom';
import { ViewerAPI } from '../../../../../../api';
import QRDrawingPDF from '../components/QRDrawingPDF';
import AddProperties from '../components/AddProperties';
export default function PrintQRDrawingModal() {
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const {
    setIsPrintQRDrawing,
    setQRDrawingLoading,
    currentDrawing,
    setProperties,
  } = qrDrawingStore();
  const [name, setName] = useState('');

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setQRDrawingLoading(true);
    try {
      let resQRDrawing = await ViewerAPI.QRDrawingAPI.GetOne({
        id: currentDrawing.data.id,
      });
      setName(resQRDrawing.name);
      setProperties(resQRDrawing.properties);
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRDrawingLoading(false);
  };
  const close = () => {
    setIsPrintQRDrawing();
  };

  const handleOk = async (e) => {};

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={name}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={false}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            gap: 10,
            height: 'calc(100vh - 450px)',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <div
            style={{ display: 'flex', gap: 5, width: '100%', height: '100%' }}
          >
            <div style={{ width: '50%' }}>
              <AddProperties isView={true} />
            </div>
            <QRDrawingPDF />
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
