/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ParameterItem from './ParameterItem';


export default function AddParameter({ parameterSetup, setParameterSetup }) {





    return (
        <div style={{ width: '100%' }}>
            <p></p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                    overflow: 'auto',
                    // height: 'calc(100% - 50px)',
                }}
            >

                <div style={{ display: 'flex', gap: 5, width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <span style={{ fontWeight: 'bold' }}>Name</span>
                    </div>
                    <div style={{ width: '100%' }}>
                        <span style={{ fontWeight: 'bold' }}>Parameter Name</span>
                    </div>
                </div>
                {parameterSetup.map((v, k) => (
                    <ParameterItem item={v} key={k} index={k}
                        setParameterSetup={setParameterSetup}
                        parameterSetup={parameterSetup} />
                ))}

            </div>

        </div>
    );
}
