/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message,
    Input
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AppAPI } from '../../../../../api';
import { camelToSnakeCase, getRouteToNode, toSnakeCase } from '../../../../../../functions/General.function';



export default function ModalDMSMainAddDrawing() {
    const { setIsModalDMSMainAddDrawing, addDMSMainParentNode, setAddDMSMainParentNode, dmsMainGridApi } = dmsStore()
    const { setIsLoading, } = appStore()
    const { projectId } = useParams()
    const [data, setData] = useState({
        drawingName: '',
        drawingNumber: '',
        blockZone: '',
        level: '',
        drawingType: '',
        status: '',
        coordinatorInCharge: '',
        modellerDrafter: '',
        remark: '',
    })

    useEffect(() => {
        return () => {
            setAddDMSMainParentNode()
        }
    }, [])
    const close = () => {
        setIsModalDMSMainAddDrawing(false)
    }

    const handleOk = async (e) => {
        if (!data.drawingNumber) {
            message.warning('Please input drawing number')
            return
        }
        setIsLoading(true)
        try {
            let parentId = null
            debugger
            if (typeof addDMSMainParentNode === 'string' || addDMSMainParentNode instanceof String) {
                parentId = addDMSMainParentNode
            } else {
                parentId = addDMSMainParentNode.data.id
            }
            debugger
            const tmp = {}
            _.forEach(data, (v, k) => {
                const name = camelToSnakeCase(k)
                tmp[name] = v
            })
            debugger
            const dsmData = await AppAPI.DMSMainAPI.CreateDMSMainDrawing({ data: tmp, parentId, projectId })
            const route = addDMSMainParentNode ? getRouteToNode(addDMSMainParentNode) : [];
            debugger;
            dmsMainGridApi.api.applyServerSideTransaction({
                route: addDMSMainParentNode ? route : [],
                add: [dsmData],
            });

            setIsLoading(false)
            close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
        }
    }
    const handleChange = (type, e) => {
        const clone = { ...data }
        clone[type] = e.target.value
        setData(clone)
    }


    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Add Drawing'}
                style={{ width: 400 }}
            >

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Drawing number:</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'drawingNumber')} value={data.drawingNumber} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Drawing name:</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'drawingName')} value={data.drawingName} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Block/zone:</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'blockZone')} value={data.blockZone} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Level:</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'level')} value={data.level} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Drawing type</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'drawingType')} value={data.drawingType} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Status</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'status')} value={data.status} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Coordinator In Charge</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'coordinatorInCharge')} value={data.coordinatorInCharge} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Modeller/Drafter</span>
                    <Input placeholder='Please input' onChange={handleChange.bind(this, 'modellerDrafter')} value={data.modellerDrafter} />
                </div>

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Remark</span>
                    <Input.TextArea placeholder='Please input' onChange={handleChange.bind(this, 'modellerDrafter')} value={data.remark} />
                </div>

            </ModalTemplate>
        </>
    );
}

