import React, { useEffect, useState } from 'react';
import { Input, Button, Form, Select } from 'antd';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import {
    ArrowLeftOutlined} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import { requestBimGrid } from '../../../../../functions/General.function';
import IconButton from '../../../../gen/button/IconButton.gen';
import { appStore } from '../../../../../store/App.store';
import { viewImagePath } from './View.constant';



export default function ViewDetail({ setType, setLoading }) {
    const [search] = useSearchParams();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState()
    const { viewer, viewDetail, viewData, setViewData } = viewerStore()
    const { user } = appStore()
    const [image, setImage] = useState()
    const { organizationId, projectId } = useParams()
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setLoading(true)
        let data = await requestBimGrid('get', '/api/s3/get-link', null, {
            organizationId, projectId ,   key: viewImagePath(organizationId, projectId, `${viewDetail.file_id}/${viewDetail.id}/main.jpg`)
            })
        if (data) {
            setImage(data)
        }
        setLoading(false)
    }

    const handleUpdate = async () => {
        form.validateFields()
            .then(async values => {
                setLoading(true)
                let clone = { ...values }
                let data = await requestBimGrid('put', '/api/view', clone, {organizationId, projectId })
                if (data) {
                    let cloneViewData = [...viewData]
                    let index = _.findIndex(cloneViewData, v => { return v.id === viewDetail.id })
                    if (index >= 0) {
                        cloneViewData.splice(index, 1, { ...viewDetail, ...clone })
                    }
                    setViewData(cloneViewData)
                    setType('list')
                }
                setLoading(false)
            })
            .catch(ex => {
                setLoading(false)
            })
    }
    const handleBack = () => {
        setType('list')
    }
    const handleView = () => {
        viewer.restoreState(viewDetail.view_state)
    }
    return (
        <>
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={handleBack} />
                {/* <span style={{ fontWeight: 'bold' }} >  {`Issue #${viewDetail.issue_no}`}</span> */}

            </div>
            <div style={{ height: 'calc(100% - 75px)', overflow: 'auto', width: '100%', position: 'relative', padding: 5 }}>
                <div id={'issue-image'} style={{ backgroundImage: `url(${image})`, gap: 5, height: 200 }} >
                    <IconButton
                        url={'https://img.icons8.com/external-kmg-design-detailed-outline-kmg-design/40/000000/external-view-user-interface-kmg-design-detailed-outline-kmg-design.png'}
                        size='small'
                        onClick={handleView}
                    />
                </div>
                <Form
                    form={form}
                    autoComplete={false}
                    layout="vertical"
                // disabled
                >
                    <Form.Item
                        name='title'
                        rules={[{ required: true, message: 'Please input your title!', },]}
                        label='Title' initialValue={viewDetail.title}
                    >
                        <Input className='idd-input' disabled={viewDetail?.created_by !== user?.email}
                            placeholder="Title"
                        />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        rules={[{ required: true, message: 'Please input your description!', },]}
                        label='Description'
                        initialValue={viewDetail.description}
                    >
                        <Input.TextArea className='idd-input' disabled={viewDetail?.created_by !== user?.email}
                            placeholder="Description"
                        />
                    </Form.Item>
                    <Form.Item
                        name='status'
                        rules={[{ required: true, message: 'Please input your status!', },]}
                        label='Status' initialValue={viewDetail.status}
                    >
                        <Select placeholder='Status' className='idd-input' disabled={viewDetail?.created_by !== user?.email}>
                            <Select.Option value="Open">Open</Select.Option>
                            <Select.Option value="Close">Close</Select.Option>
                        </Select>
                    </Form.Item>


                </Form>
            </div>
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <Button className={`idd-custom-button block edit`} onClick={handleUpdate}
                >Update</Button>

            </div>


        </>

    );
}


