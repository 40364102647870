import React, { Suspense, useEffect, useRef } from 'react';
import { Canvas, useThree, extend } from '@react-three/fiber';
import { XR, createXRStore } from '@react-three/xr';
import { PCFSoftShadowMap } from 'three';
import {
  Sky,
  Stats,
  BakeShadows,
  AdaptiveDpr,
  AdaptiveEvents,
  Preload,
  PerspectiveCamera,
} from '@react-three/drei';
const store = createXRStore();

export default function VRCanvas(props) {
  const ref = useRef();

  function allowDrop(ev) {
    ev.preventDefault();
  }
  const handleDrop = async (ev) => {
    ev.preventDefault();
    const data = ev.dataTransfer.getData('text');
    //   const clone = [...listObjectScene]
    //   const parse = JSON.parse(data)
    //   parse.objectId = v4()
    //   clone.push(parse)
    //   setListObjectScene(clone)
  };

  return (
    <>
      <div style={{ position: 'absolute', top: 0, zIndex: 100 }}>
        <button onClick={() => store.enterAR()}>Enter AR</button>
      </div>

      <Canvas
        // shadows={setting.shadow.enabled}
        mode='concurrent'
        // performance={{ min: setting.display.value }}
        frameloop='demand' //dpr={[1, 2]}
        gl={{
          alpha: false,
          powerPreference: 'high-performance',
          antialias: false,
        }}
        dpr={window.devicePixelRatio}
        onCreated={({ gl, scene }) => {
          //   gl.outputEncoding = sRG;
          gl.shadowMap.autoUpdate = false;
          gl.shadowMap.type = PCFSoftShadowMap;
          //   setSceneLocal(scene);
          //   setGlD(gl);
        }}
        onDrop={handleDrop}
        onDragOver={allowDrop}
      >
        <Sky />
        <XR store={store}>
          {/* <Controllers />
          <Hands /> */}
          <Suspense fallback={null}>
            {/* <MainCharacter /> */}
            {/* <Room id={props.id} />
            {!isConnectRoom && <Plane />}
            {isConnectRoom && <City url={props.urlModel} />}
            {features?.isSelection && <Interaction3js />} */}
          </Suspense>
          {/* <Suspense fallback={null}>
            <ListItem />
          </Suspense> */}
        </XR>

        <fog attach='fog' args={['white', 15, 50]} />

        <AdaptiveDpr pixelated />
        <AdaptiveEvents />
        <BakeShadows />
        <Preload all />
      </Canvas>
      <Stats />
    </>
  );
}
