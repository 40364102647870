import { S3_BASED_PATH } from '../../../config';
import { generateS3Path, requestBimGrid } from '../../../functions/General.function';


export const docPath = (organizationId, projectId, path, id, currentVersion, name) => {
    const _path = `${path}/${id}/${currentVersion}/${name}`
    return generateS3Path(organizationId, projectId,
        S3_BASED_PATH.DMS_DOC, `${_path}`)
}


export const CreateDMSDocFolder = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-doc/add-folder', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const CreateDMSDocFile = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-doc/add-file', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const UpdateDMSDoc = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-doc/update-one', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const GetDMSDocOne = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-doc/get-one', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const GetDMSDocVersions = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-doc/get-versions', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const DeleteDMSDocOne = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('delete', '/api/dms-doc/delete-one', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const GetChidById = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-doc/get-children-by-id', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const ShareGetChidById = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-doc/share/get-children-by-id', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const DownloadFolder = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-doc/download-folder', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const GetShareDMSDocOne = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-doc/share/get-one', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};