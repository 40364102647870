import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { dfmaStore } from '../DFMA.store';
import { Pie } from 'react-chartjs-2';
import { DEFAULT_COLOR } from '../DFMA.constant';

const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    plugins: {
        title: {
            display: true,
            text: 'Chart.js Line Chart - Multi Axis',
            position: "bottom",
        },
    },
};

export default function TimelineChart() {
    const {
        progressTimelineData,
        timelineCurrentTime,
        timelineCategory
    } = dfmaStore();
    const [casted, setCasted] = useState()
    const [delivery, setDelivery] = useState()
    const [install, setInstall] = useState()
    useEffect(() => {
        if (progressTimelineData.length !== 0)
            generatePiechart(progressTimelineData)
    }, [progressTimelineData, timelineCurrentTime,timelineCategory])
    const generatePiechart = (data) => {
        let total =0
        let countCasted = 0
        let countDelivery = 0
        let countInstall = 0
        debugger
        _.forEach(data, v => {
            if (v.category !== timelineCategory) return
            _.forEach(v.listTime, i => {
                if (timelineCurrentTime.valueOf() < i.time) return
                if (i.status === 'Casting Completed') {
                    countCasted++
                }
                else if (i.status === 'Delivered to Site') {
                    countDelivery++
                } else if (i.status === 'Installed on Site') {
                    countInstall++
                }
            })
            total++
        })
        setCasted({

            labels: ['Not Start', 'Casting Completed'],
            datasets: [
                {
                    label: 'Amount:',
                    data: [total - countCasted, countCasted],
                    backgroundColor: [
                        DEFAULT_COLOR.NOTSTART,
                        DEFAULT_COLOR.CASTED,
                    ],
                },
            ],

        })
        setDelivery({
            labels: ['Not Start', 'Delivered to Site'],
            datasets: [
                {
                    label: 'Amount:',
                    data: [total - countDelivery, countDelivery],
                    backgroundColor: [
                        DEFAULT_COLOR.NOTSTART,
                        DEFAULT_COLOR.DELIVERY,
                    ],
                },
            ],

        })
        setInstall({

            labels: ['Not Start', 'Installed on Site'],
            datasets: [
                {
                    label: 'Amount:',
                    data: [total - countInstall, countInstall],
                    backgroundColor: [
                        DEFAULT_COLOR.NOTSTART,
                        DEFAULT_COLOR.INSTALL,
                    ],
                },
            ],

        })

    }
    return (
        <>
            <div style={{
                padding: 20,
                height: '200px',
                display: 'flex',
                // flexDirection: 'column'
                width: '100%',
                justifyContent: 'space-around',
                flexWrap: 'wrap'
            }} >
                <div style={{ height: 250, width: 250 }}>
                    {casted && <Pie data={casted} options={{
                        ...options, plugins: {
                            title: {
                                display: true,
                                text: 'Casting Completed',
                                position: "bottom",
                            },
                        },
                    }} />}
                </div>
                <div style={{ height: 250, width: 250 }}>
                    {delivery && <Pie data={delivery} options={{
                        ...options, plugins: {
                            title: {
                                display: true,
                                text: 'Delivered to Site',
                                position: "bottom",
                            },
                        },
                    }} />}
                </div>
                <div style={{ height: 250, width: 250 }}>
                    {install && <Pie data={install} options={{
                        ...options, plugins: {
                            title: {
                                display: true,
                                text: 'Installed on Site',
                                position: "bottom",
                            },
                        },
                    }} />}
                </div>


            </div>
        </>

    );
}
