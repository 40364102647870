import React from 'react';
import { Tabs } from 'antd';
import SuperviorListTable from '../table/SuperviorList.table';
import SuperviorListToolbar from './SuperviorList.toolbar';

export default function SuperviorListLayout() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 2,
        height: '100%',
        widthL: '100%',
        flexDirection: 'column',
      }}
    >
      <SuperviorListToolbar />
      <SuperviorListTable />
    </div>
  );
}
