import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function CardApp({ id, name, logo, app, handleViewItem }) {
  const navigate = useNavigate();
  const { projectId } = useParams();

  return (
    <>
      <div
        onClick={handleViewItem.bind(this, app)}
        className='idd-card'
        style={{ width: 150 }}
      >
        <div className='card-content'>
          <div
            className='cover'
            style={{
              height: 100,
              backgroundSize: 60,
              backgroundImage: `url("${logo}")`,
            }}
          >
            <div style={{ float: 'right' }}></div>
          </div>
        </div>

        <div className='card-name'>
          <div
            style={{
              display: 'flex',
              padding: '0px 5px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span className='main'>{name}</span>
          </div>
        </div>
      </div>
    </>
  );
}
