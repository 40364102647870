import React from 'react';
import { Button } from 'antd';
import _ from 'lodash';
import { issueStore } from './Issue.store';
import { IssueComponentType } from './constants/IssueComponentType';
import { requestBimGrid } from '../../../../../functions/General.function';
import { useParams, useSearchParams } from 'react-router-dom';
import IconTextButton from '../../../../gen/button/IconTextButton.gen';
import IconButton from '../../../../gen/button/IconButton.gen';
// import xl from 'excel4node'

export default function IssueToolbar() {
  const [search] = useSearchParams();
  const { issueLoading, setType, layoutType, setIssueLoading, setLayoutType } =
    issueStore();
  const fileId = search.get('fileId');
  const { organizationId, projectId } = useParams();
  const handleAdd = () => {
    setType(IssueComponentType.Add);
  };
  const handleExport = async () => {
    setIssueLoading(true);
    let data = await requestBimGrid(
      'post',
      '/api/issue/exportReport',
      { fileId },
      { organizationId, projectId }
    );
    if (data) {
      window.open(data);
    }
    setIssueLoading(false);
  };
  const handleLayoutType = (e) => {
    setLayoutType(e);
  };
  return (
    <>
      <div
        style={{
          padding: 10,
          display: 'flex',
          gap: 5,
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          <IconTextButton
            icon={'add'}
            size='ssmall'
            onClick={handleAdd}
            loading={issueLoading}
          >
            Add
          </IconTextButton>

          <IconTextButton
            icon={'export'}
            size='ssmall'
            onClick={handleExport}
            loading={issueLoading}
          >
            Export
          </IconTextButton>
        </div>
        <div style={{ display: 'flex', gap: 5 }}>
          <IconButton
            icon={'table'}
            size='ssmall'
            type={layoutType === 'table' ? 'save' : 'default'}
            onClick={handleLayoutType.bind(this, 'table')}
            loading={issueLoading}
          ></IconButton>
          <IconButton
            icon={'card'}
            size='ssmall'
            type={layoutType !== 'table' ? 'save' : 'default'}
            onClick={handleLayoutType.bind(this, 'card')}
            loading={issueLoading}
          ></IconButton>
        </div>
        {/* <Button
          className={`idd-custom-button block save`}
          onClick={handleAdd}
          loading={issueLoading}
        >
          Add
        </Button>
        <Button
          className={`idd-custom-button block save`}
          onClick={handleExport}
          loading={issueLoading}
          disabled={issueData.length === 0}
        >
          Export
        </Button> */}
      </div>
    </>
  );
}
