import React, { useEffect, useState } from 'react';
import { DMS_COLOR } from '../../dms/constant';
import _ from 'lodash';

export default function TotalConsultantCellGroup(props) {
  const [status, setStatus] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const tmp = {};
    _.forEach(props.data, (v, key) => {
      if (
        key.includes('consultant_') &&
        !key.includes('_consultant_') &&
        !key.includes('consultant_group')
      ) {
        _.forEach(v, (i, statusName) => {
          if (!tmp[statusName]) {
            tmp[statusName] = 0;
          }
          tmp[statusName] += i;
        });
      }
    });
    const status = [];
    _.forEach(tmp, (v, k) => {
      status.push({ status: k, amount: v });
    });
    setStatus(status);
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {status.map((v) => (
        <div
          style={{
            background: DMS_COLOR[v.status] ? DMS_COLOR[v.status].bg : '',
            color: DMS_COLOR[v.status] ? DMS_COLOR[v.status].color : '',
            width: `${v.amount * 100 - total}%`,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontWeight: 'bold' }}> {v.amount}</span>
        </div>
      ))}
    </div>
  );
}
