import moment from 'moment';
import { useEffect, useState } from 'react';

export default function TimeCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [date, setDate] = useState('');
  useEffect(() => {
    if (cellValue) {
      const v = moment(new Date(cellValue)).format('HH:mm:ss');
      props.node.data[`${props.column.colId}_processed`] = v;
      setDate(v);
    }
  }, [cellValue]);

  return <div>{date}</div>;
}
