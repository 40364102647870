import { message } from 'antd';
import { viewerOptions } from '../../viewer/Viewer.constant';
import _ from 'lodash';
import { AppAPI } from '../../../api';
import moment from 'moment';
import {
  clearColorAllModel,
  convertHexColorToVector4,
} from '../../../../functions/Viewer.function';

export const openView = async (viewer, item) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resVersion = await AppAPI.VersionAPI.GetLastVersion({
        fileId: item.fileId,
        select: [
          'version',
          'file.name',
          'file_id as fileId',
          'object_id as objectId',
          'version.id as versionId',
        ],
      });
      //  await requestBimGrid('get', '/api/version/get-last-version', null, )
      if (resVersion) {
        window.Autodesk.Viewing.Document.load(
          'urn:' + btoa(resVersion.objectId),
          onDocumentLoadSuccess.bind(this, resVersion, item, viewer),
          onDocumentLoadFailure
        );
      }
      resolve();
    } catch (ex) {
      reject(ex);
    }
  });
};
const onDocumentLoadSuccess = async (version, item, viewer, doc) => {
  debugger;
  let views3d = doc
    .getRoot()
    .search({ type: 'geometry', role: '3d', progress: 'complete' }, true);
  const itemSelected = { ...item, versionId: version.versionId, doc };

  const models = viewer.impl.modelQueue().getModels();
  const index1 = _.findIndex(models, (v) => {
    return v.myData.loadOptions.fileId === itemSelected.fileId;
  });
  if (index1 >= 0) {
    message.warning('This file was loaded');
    return;
  }
  const index = _.findIndex(views3d, (v) => {
    return v.data.guid === item.viewId;
  });
  // let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
  const svfUrl = itemSelected.doc.getViewablePath(views3d[index]);
  const aecModelData = await itemSelected.doc.downloadAecModelData();
  const globalOffset = viewer.model.getData().globalOffset;
  const loadOptions = {
    ...viewerOptions,
    ...{
      fileId: itemSelected.fileId,
      versionId: itemSelected.versionId,
      guid: views3d[index].data.guid,
      modelNameOverride: itemSelected.name,
      aecModelData: aecModelData,
      globalOffset,
    },
  };
  viewer.loadModel(
    svfUrl,
    loadOptions,
    onLoadModelSuccess.bind(this),
    onLoadModelError.bind(this)
  );
};
const onDocumentLoadFailure = (doc) => {
  message.warning('Cannot load this file');
};
const onLoadModelSuccess = (e) => {};
const onLoadModelError = (e) => {
  message.warning('Cannot load this file');
};

export const workDoneProgress = (date, gridApi, treeModelData, viewer) => {
  clearColorAllModel(viewer);
  const color = convertHexColorToVector4('#1890ff');
  debugger;
  gridApi.api.forEachLeafNode(async (node) => {
    if (node.data.actual_end && node.data.actual_start) {
      // const start = moment(node.data.actual_start);
      const end = moment(node.data.actual_end).startOf('month');
      if (end.isBefore(date) || end.isSame(date)) {
        const selector = node.data.object_3d;
        for (const index in selector) {
          const element = selector[index];
          _.forEach(element.ids, (exId) => {
            const node = treeModelData.findNodeByFileId(exId, element.fileId);
            if (node) {
              viewer.setThemingColor(node.dbId, color, node.model, true);
              viewer.show(node.dbId, node.model);
            }
          });
        }
      }
    }
  });
};
