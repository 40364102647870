/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { workerStore } from '../../../../../../store/Worker.store';
import {
  checkInput,
  updateTableAsync,
} from '../../../../../../functions/General.function';
// import { AppAPI } from '../../../../../api';
import { DatePicker as DatePickerDayjs } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import moment from 'moment';
import { AppAPI } from '../../../../../api';
import { updateWorkerListPayment } from '../../Worker.utits';
const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);

export default function ModalAddWorkerDaily() {
  const { setIsOpenAddWorkerDaily, workerDailyGridApi } = workerStore();
  const { setIsLoading } = appStore();
  const { projectId } = useParams();
  const [selectItems, setSelectItems] = useState({});
  const [data, setData] = useState({
    date: moment(new Date()),
    start_at: moment(new Date()).startOf('day').add(8, 'hours'),
    end_at: moment(new Date()).startOf('day').add(17, 'hours'),
  });
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const workerRes = await AppAPI.WorkerSettingAPI.GetAllWorkerListByProject(
        {
          projectId,
          select: ['worker_app_worker_list.name', 'worker_app_worker_list.id'],
        }
      );
      selectItems['worker'] = workerRes;
      const superviorRes =
        await AppAPI.WorkerSettingAPI.GetAllSuperviorListByProject({
          projectId,
          select: ['name', 'id'],
        });
      selectItems['supervior'] = superviorRes;
      const blockRes = await AppAPI.WorkerSettingAPI.GetAllBlockZoneByProject({
        projectId,
        select: ['block', 'id'],
      });
      selectItems['block'] = blockRes;
      const tradeRes = await AppAPI.WorkerSettingAPI.GetAllTrade({
        projectId,
        select: ['trade', 'id'],
      });
      selectItems['trade'] = tradeRes;
      const workTimeRes = await AppAPI.WorkerSettingAPI.GetAllWorkTimeByProject(
        {
          projectId,
          select: ['time_type', 'id'],
        }
      );
      selectItems['work_time'] = workTimeRes;

      const blockZoneRes =
        await AppAPI.WorkerSettingAPI.GetAllBlockZoneByProject({
          projectId,
          select: ['block', 'level', 'zone', 'id'],
        });
      blockZoneRes.forEach((v) => {
        v.name = `${v.block} - ${v.level} - ${v.zone} `;
      });
      selectItems['block'] = blockZoneRes;

      setSelectItems({ ...selectItems });
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenAddWorkerDaily(false);
  };

  const handleOk = async (e) => {
    let check = true;
    debugger;
    const tmp = [
      'date',
      'worker_id',
      'supervior_id',
      'trade',
      'end_at',
      'start_at',
      'work_time_id',
    ];
    _.forEach(tmp, (v) => {
      if (!data[v]) {
        message.warning(`Please fill all input`);
        check = false;
        return false;
      }
    });
    if (!check) {
      return;
    }
    setIsLoading(true);
    try {
      const workerDailyRes = await AppAPI.WorkerDailyAPI.CreateWorkerDaily({
        projectId,
        data: data,
      });
      await updateTableAsync(workerDailyGridApi.api, { add: [workerDailyRes] });
      await updateWorkerListPayment({
        data: { id: workerDailyRes.worker_id },
      });
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };

  const handleChangeSelect = (type, e) => {
    data[type] = e;
    setData({ ...data });
  };
  const handleDate = (type, e) => {
    data[type] = e;
    setData({ ...data });
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add worker daily'}
        style={{ width: 500 }}
      >
        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Date'}: `}</span>
          <DatePicker
            value={data.date}
            allowClear={false}
            onChange={handleDate.bind(this, 'date')}
            // format='HH:mm'
          />
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Start at'}: `}</span>
          <DatePicker.TimePicker
            value={data.start_at}
            picker='time'
            allowClear={false}
            onChange={handleDate.bind(this, 'start_at')}
            format='HH:mm'
          />
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'End at'}: `}</span>
          <DatePicker.TimePicker
            picker='time'
            value={data.end_at}
            allowClear={false}
            onChange={handleDate.bind(this, 'end_at')}
            format='HH:mm'
          />
        </div>
        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Worker'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'worker_id')}
            value={data?.worker_id}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['worker']?.map((v) => (
              <Select.Option name={v.name} value={v.id}>
                {' '}
                {v.name}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Supervior'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'supervior_id')}
            value={data?.supervior_id}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['supervior']?.map((v) => (
              <Select.Option name={v.email} value={v.id}>
                {' '}
                {v.email}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Trade'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'trade')}
            value={data?.trade}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['trade']?.map((v) => (
              <Select.Option name={v.trade} value={v.trade}>
                {' '}
                {v.trade}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Work type'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'work_time_id')}
            value={data?.work_time_id}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['work_time']?.map((v) => (
              <Select.Option name={v.time_type} value={v.id}>
                {' '}
                {v.time_type}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Location'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'block_id')}
            value={data?.block_id}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['block']?.map((v) => (
              <Select.Option name={v.name} value={v.id}>
                {' '}
                {v.name}{' '}
              </Select.Option>
            ))}
          </Select>
        </div>
        {/* <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{`${'Block'}: `}</span>
          <Select
            showSearch
            placeholder='Please input'
            onChange={handleChangeSelect.bind(this, 'block')}
            value={data?.block}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? '')
                .toLowerCase()
                .localeCompare((optionB?.name ?? '').toLowerCase())
            }
          >
            {selectItems['block']?.map((v) => (
              <Select.Option name={v.block} value={v.block}>
                {' '}
                {v.block}{' '}
              </Select.Option>
            ))}
          </Select>
        </div> */}
      </ModalTemplate>
    </>
  );
}
