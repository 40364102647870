import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import { qrDrawingStore } from '../QRDrawing.store';

export default function DetailCell(props) {
  const { setIsDetailDrawing, setCurrentDrawing } = qrDrawingStore();
  const handleOpen = () => {
    setCurrentDrawing(props.node)
    setIsDetailDrawing(true);
  };
  return (
    <IconButton
      icon={'detail'}
      size='ssmall'
      border={false}
      type='regular'
      onClick={handleOpen}
    ></IconButton>
  );
}
