import React, { useEffect } from 'react';
import _ from 'lodash';
import { qrDrawingStore } from './QRDrawing.store';
import QRDrawingTable from './table/QRDrawing.table';
import QRDrawingToolbar from './QRDrawing.toolbar';
import AddNewQRDrawingModal from './modal/AddNewQRDrawing.modal';
import { ViewerAPI } from '../../../../../api';
import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import DetailQRDrawing from './modal/DetailQRDrawing';
import PrintQRDrawingModal from './modal/PrintQRDrawing.modal';
export default function QRDrawingLayout() {
  const {
    resetQRDrawingStore,
    isAddNewDrawing,
    setDrawings,
    setQRDrawingLoading,
    isDetailDrawing,
    currentDrawing,
    isPrintQRDrawing
  } = qrDrawingStore();
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  useEffect(() => {
    run();
    return () => {
      resetQRDrawingStore();
    };
  }, []);
  const run = async () => {
    setQRDrawingLoading(true);
    try {
      let data = await ViewerAPI.QRDrawingAPI.GetAll({ fileId });
      setDrawings(data);
    } catch (ex) {
      message.warning();
    }
    setQRDrawingLoading(false);
  };
  return (
    <>
      <QRDrawingToolbar />
      <QRDrawingTable />
      {isAddNewDrawing && <AddNewQRDrawingModal />}
      {isDetailDrawing && currentDrawing && <DetailQRDrawing />}
      {isPrintQRDrawing && currentDrawing && <PrintQRDrawingModal />}
    </>
  );
}
