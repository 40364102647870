import React, { useLayoutEffect, useRef } from 'react';

import _ from 'lodash';
import { Tooltip } from 'antd';
import icons from '../constant/Icon.constant';

export default function IconStatic({
  url,
  size = '',
  ghost,
  active,
  onClick = () => { },
  style = {},
  iconStyle = {},
  border = true,
  title = '',
  tooltipColor = '#2a425e',
  tooltipPlacement = 'top',
  loading,
  disabled = false,
  circle = false,
  type = 'save',
  icon,
  isIcon
}) {
  const isGhost = ghost ? 'ghost' : '';
  const isBorder = border ? 'border' : '';
  const isLoading = loading ? 'loading' : '';
  const isCircle = circle ? 'circle' : '';
  const ref = useRef(null);

  return (
    <>
      <div style={{ display: 'flex', gap: 5 }}>
        <div
          ref={ref}
          className={`idd-icon ${type}  ${isGhost} ${active ? 'active' : ''
            } ${isBorder} ${size} ${isCircle}`}
        >
          <div
            className={`icon  ${isLoading}`}
            style={{ ...iconStyle, WebkitMaskImage: `url(${icons[icon] ? icons[icon] : url})` }}
          ></div>
        </div>
      </div>
    </>
  );
}
