import moment from 'moment';

export const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return <div className='box' style={{ ...style, ...viewStyle }} {...props} />;
};

export const defaultColDef = {
  cellClassRules: {
    groupBackground: (params) => {
      return params.node.group;
    },
  },
  flex: 1,
  // initialWidth: 70,
  sortable: true,
  resizable: true,
  enableRowGroup: true,
  // showRowGroup: true
};
export const defaultColDefNotFlex = {
  // flex: 1,
  // initialWidth: 70,
  sortable: true,
  resizable: true,
  enableRowGroup: true,
  // showRowGroup: true
};
export const autoGroupColumnDef = {
  cellRendererParams: {
    suppressCount: false,
  },
};

export const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    debugger;
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var cellDate = moment(cellValue).toDate();
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};
