import React from 'react';
import VRDesktop from './desktop/VR.desktop';

export default function VRLayout() {
  return (
    <>
      <VRDesktop />
    </>
  );
}
