/* eslint-disable no-unused-vars */
import React, {  } from 'react';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { timelineStore } from '../Timeline.store';
import ButtonGen from '../../../../../gen/button/common/Button.gen';
import IconButton from '../../../../../gen/button/IconButton.gen';

export default function DefineRule() {
  const { setIsOpenDefineRule, } =
    timelineStore();

  const close = () => {
    setIsOpenDefineRule();
  };

  const handleOk = async (e) => {
  
    close();
  };

  const handleAdd = () => {};
  const handleDelete = () => {};
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Rule Definitions'}
        isFullscreen={true}
        // styleBackDrop={{ position: 'abo' }}
        style={{ top: 'auto', width: '500px' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            height: '500px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 2,
            }}
          >
            <ButtonGen size='ssmall' onClick={handleAdd}>
              Add
            </ButtonGen>
            <ButtonGen type='delete' onClick={handleDelete} size='ssmall'>
              Delete
            </ButtonGen>
          </div>
          <div style={{ width: '100%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                border: '1px solid black',
                height: '250px',
                background: 'white',
                padding: 5,
                borderRadius: 5,
                // overflow:'auto'
              }}
            >
              {
                [].map((i) => (
                  <div
                    // onClick={handleSelect.bind(this, i)}
                    style={{
                      padding: 3,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', gap: 5 }}>
                        <span> {i?.name}</span>
                      </div>

                      <div style={{ display: 'flex', gap: 2, borderRadius: 5 }}>
                        <IconButton
                          icon='view'
                          size='sssmall'
                          type='save'
                          // onClick={handleViewItem.bind(this, i)}
                        />
                        <IconButton
                          icon='edit'
                          size='sssmall'
                          type='edit'
                          // onClick={handleViewItem.bind(this, params.data)}
                        />
                        <IconButton
                          icon='delete'
                          size='sssmall'
                          type='delete'
                          // onClick={handleDeleteItem.bind(this, i)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
