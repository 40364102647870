import { Badge } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppAPI } from '../../../../../api';

export default function HubDeviceStatusCell(props) {
  const [status, setStatus] = useState('Pending');
  const { projectId } = useParams();
  // useEffect(() => {
  //   if (props.data.uuid) {
  //     socket.on(
  //       `worker-tracking-hub-status-to-client-${projectId}`,
  //       checkStatus
  //     );
  //     return () => {
  //       socket.off(
  //         `worker-tracking-hub-status-to-client-${projectId}`,
  //         checkStatus
  //       );
  //     };
  //   }
  // }, [props.data.uuid]);
  // const checkStatus = (uuid) => {
  //   if (uuid === props.data.uuid) {
  //     setStatus('Online');
  //   }
  // };
  useEffect(() => {
    (async () => {
      if (props.data.uuid) {
        try {
          await AppAPI.WorkerTrackingAPI.GetHubDeviceStatus({
            projectId,
            uuid: props.data.uuid,
          });
          setStatus('Online');
        } catch (ex) {
          setStatus('Offline');
        }
      }
    })();
  }, [props.data.uuid]);

  return (
    <div style={{ display: 'flex', gap: 5 }}>
      <Badge
        status={
          status === 'Online'
            ? 'success'
            : status === 'Offline'
              ? 'default'
              : 'processing'
        }
      />
      {status}
    </div>
  );
}
