import { useEffect, useState } from 'react';
import { validatePermission } from 'src/functions/Auth.function';
import { appStore } from 'src/store/App.store';
import icons from '../constant/Icon.constant';
import ButtonGen from './common/Button.gen';

export default function IconButton({
  url,
  size = '',
  icon,
  requireActions = [],
  containActions = [],
  ...props
}) {
  const [allowed, setIsAllowed] = useState();
  const { permission } = appStore();
  useEffect(() => {
    const { allowed } = validatePermission(
      permission,
      requireActions,
      containActions
    );
    setIsAllowed(allowed);
  }, [permission]);
  if (!allowed) {
    return null;
  }

  return (
    <>
      <ButtonGen
        url={url}
        size={size}
        icon={icon}
        {...props}
        isIconButton={true}
      >
        <div
          className={`icon ${size}`}
          style={{
            WebkitMaskImage: `url(${icons[icon] ? icons[icon] : url})`,
            ...props.iconStyle,
          }}
        ></div>
      </ButtonGen>
    </>
  );
}
