import React from 'react';
/**
 * @param {string} className
 * @param {string} type
 * @param {string} placeholder - The placeholder text for the input element.
 * @param {string} name - The name attribute for the input element.
 * @param {boolean} isRequired - Whether the input element is required.
 * @param {function | undefined} onChange
 * @param {string} [defaultValue] - The default value of the input element (optional).
 * @returns {React.Element} The rendered input element.
 */
const InputTextAreaForm = ({
  className,
  type,
  placeholder,
  name,
  isRequired,
  defaultValue,
  onChange,
}) => {
  return (
    <textarea
      type={type}
      name={name}
      className={`${className} block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500`}
      placeholder={placeholder}
      required={isRequired}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export default InputTextAreaForm;
