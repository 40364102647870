import React, { useEffect, useState } from 'react';
import { Result, Button, message } from 'antd';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';
import ViewerShareForge from '../ViewerShare.forge';
import { AppAPI, ViewerAPI } from '../../../../../api';
import { appStore } from '../../../../../../store/App.store';
import { viewerStore } from '../../../../../../store/Viewer.store';
import _ from 'lodash';
import { viewerSelect } from '../../../../../../functions/Viewer.function';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';

function QRShareElementLayout(props) {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const qrId = search.get('qrId');
  const elementId = search.get('elementId');
  const projectId = search.get('projectId');
  const organizationId = search.get('organizationId');
  const [urn, setUrn] = useState();
  const [versionId, setVersionId] = useState();
  const [properties, setProperties] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState([]);
  const [currentElement, setCurrentElement] = useState();
  const { setIsLoading } = appStore();
  const { treeModelData, viewer } = viewerStore();
  useEffect(() => {
    if (!qrId) {
      return;
    }
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      let resQRElement = await ViewerAPI.QRElementAPI.GetOne_Share({
        id: qrId,
        organizationId,
        projectId,
      });
      if (!resQRElement) {
        throw new Error('Caaot find QR template');
      }
      setCurrentTemplate(resQRElement);

      let resVersion = await AppAPI.VersionAPI.GetLastVersion_Share({
        fileId: fileId,
        select: [
          'version',
          'file.name',
          'file_id as fileId',
          'object_id as objectId',
          'version.id as versionId',
        ],
        organizationId,
        projectId,
      });
      if (resVersion) {
        setVersionId(resVersion.id);
        setUrn(btoa(resVersion.objectId));
      }
    } catch (ex) {
      message.warning(ex.message);
    }
    setIsLoading(false);
  };
  const handleDone = (viewer) => {
    treeModelData.addEventListener('done', () => {
      let node = treeModelData.findNode(elementId);
      let temp = [];
      _.forEach(currentTemplate.properties, (v) => {
        let index = _.findIndex(node.properties, (i) => {
          return i.displayName === v.property;
        });
        let value;
        if (index >= 0) {
          value = node.properties[index].displayValue;
        }
        temp.push({
          name: v.name,
          value,
        });
      });
      debugger;
      setProperties(temp);
      setCurrentElement(node);
      handleSelect(node);
    });
  };
  const handleSelect = (node) => {
    debugger;
    let model = viewer.impl.modelQueue().findModel(node.modelId);
    viewerSelect(viewer, [{ model: model, ids: [node.dbId] }]);
    viewer.fitToView([node.dbId], model);
  };
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div style={{ position: 'absolute', zIndex: 100000, padding: 5 }}>
        {currentElement && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              border: '1px solid black',
              borderRadius: 10,
              padding: 10,
              overflow: 'auto',
              height: '100%',
            }}
          >
            <IconTextButton
              icon={'select'}
              type='save'
              size='ssmall'
              onClick={handleSelect.bind(this, currentElement)}
            >
              Select Element
            </IconTextButton>
            {properties.map((i) => (
              <div style={{ display: 'flex', gap: 5 }}>
                <span style={{ fontWeight: 'bold' }}>{`${i.name}: `}</span>
                <span style={{ fontWeight: '' }}>{i.value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {urn && (
        <ViewerShareForge
          urn={urn}
          fileId={fileId}
          versionId={versionId}
          callbackDone={handleDone}
        />
      )}
    </div>
  );
}

export default QRShareElementLayout;
