import { useSearchParams } from 'react-router-dom';
import { viewerStore } from '../../../store/Viewer.store';
import ViewerForge from './Viewer.forge';
// import TransformExtension from "./extensions/transform/TransformExtension";

export default function ViewerForgeLayout(props) {
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const versionId = search.get('versionId');
  const { versionData } = viewerStore();

  return (
    <>
      <ViewerForge
        fileId={fileId}
        versionId={versionId}
        name={versionData.name}
        objectId={versionData.object_id}
      />
      <div
        style={{ position: 'absolute', zIndex: 99, bottom: 10, opacity: '30%' }}
      >
        <img
          src='http://bimgrid.com.sg/wp-content/uploads/2020/08/cropped-BIMGrid-Pte-Ltd-01-1.png'
          width={150}
          alt='logo'
        />
      </div>
    </>
  );
}
