import _ from 'lodash';
import { useEffect, useState } from 'react';
// import 'moment-duration-format';
import moment from 'moment';
export default function TrackingCellGroup(props) {
  const [value, setValue] = useState();
  useEffect(() => {
    let totalDuration = moment.duration();

    _.forEach(props.node.allLeafChildren, (v) => {
      let data = v.data[props.column.colId];
      if (data) {
        const time = moment.duration(_.toNumber(data), 'minutes');
        totalDuration.add(time);
      }
    });
    if (!props.node.data) {
      props.node.data = {};
    }
    const t = formatDuration(totalDuration);
    props.node.data[props.column.colId] = t;
    setValue(t);
  }, []);

  function formatDuration(duration) {
    const days = Math.floor(duration.asDays());
    const hours = duration.hours().toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');

    if (days > 0) {
      return `${days} days ${hours}:${minutes}:${seconds}`;
    } else {
      return `${hours}:${minutes}:${seconds}`;
    }
  }
  return <div style={{ fontWeight: 'bold' }}>{value}</div>;
}
