/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Input, Radio, Select, message } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { timelineStore } from '../Timeline.store';
import TableMappingExcel from '../table/MappingExcel.table';
import { toSnakeCase } from '../../../util/Gantt.util';
import { v4 } from 'uuid';
const gantt = window.gantt;

export default function SimulationSetting() {
  const { setIsOpenSimulationSetting, settingSimulationData,setSettingSimulationData } = timelineStore();
  const [isOverrideSetting, setIsOverrideSetting] = useState(
    settingSimulationData.overrideDate.isEnable
  );
  const [view, setView] = useState(settingSimulationData.view);
  const [intervalSize, setIntervalSize] = useState(settingSimulationData.interval.size);
  const [intervalType, setIntervalType] = useState(settingSimulationData.interval.type);
  const [overrideDate, setOverrideDate] = useState(
    settingSimulationData.overrideDate
  );
  const close = () => {
    setIsOpenSimulationSetting();
  };

  const handleOk = async (e) => {
    let data= {
      overrideDate:{
        startDate: overrideDate.startDate,
        endDate:overrideDate.endDate,
        isEnable: isOverrideSetting
      },
      view:view,
      interval:{
        size:intervalSize,
        type:intervalType
      }
    }
    setSettingSimulationData(data)
    close()
  };
  const handleView = (e) => {
    setView(e.target.value);
  };
  const handleSize = (e) => {
    setIntervalSize(_.toNumber(e.target.value));
  };
  const handleType = (e) => {
    setIntervalType(e);
  };
  const handleOverride = (e) => {
    setIsOverrideSetting(e.target.checked);
  };
  const handleOverrideDate = (type, e) => {
    let clone = { ...overrideDate };
    clone[type] = e;
    setOverrideDate(clone);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Field Selector'}
        isFullscreen={true}
        // styleBackDrop={{ position: 'abo' }}
        style={{ top: 'auto', width: '500px' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            padding: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              backgroundColor: '#cfcfcf',
              padding: 10,
            }}
          >
            <Checkbox checked={isOverrideSetting} onChange={handleOverride}>
              Override Start / End Dates
            </Checkbox>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <span>Start Date</span>
              <DatePicker
                onChange={handleOverrideDate.bind(this, 'startDate')}
                disabled={!isOverrideSetting}
                value={overrideDate.startDate}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <span>End Date</span>
              <DatePicker
                onChange={handleOverrideDate.bind(this, 'endDate')}
                disabled={!isOverrideSetting}
                value={overrideDate.endDate}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              backgroundColor: '#cfcfcf',
              padding: 10,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <span>Interval Size</span>
              <div style={{ display: 'flex', gap: 5 }}>
                <Input
                  style={{ width: '50%' }}
                  onChange={handleSize}
                  value={intervalSize}
                  type='number'
                  min={1}
                />
                <Select
                  style={{ width: '50%' }}
                  onChange={handleType}
                  value={intervalType}
                >
                  <Select.Option value='days'>Days</Select.Option>
                  <Select.Option value='weeks'>Weeks</Select.Option>
                  <Select.Option value='months'>Months</Select.Option>
                </Select>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              backgroundColor: '#cfcfcf',
              padding: 10,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <span>View</span>
              <Radio.Group value={view} onChange={handleView}>
                <Radio value={'planned'}>Planned</Radio>
                <br />
                <Radio value={'planned a'}>Planned (Actual Differences)</Radio>
                <br />
                <Radio value={'planned against'}>Planned against</Radio>
                <br />
                <Radio value={'actual'}>Actual</Radio>
                <br />
                <Radio value={'actual a'}>Actual (Actual Differences)</Radio>
                <br />
              </Radio.Group>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
