import {
  Checkbox,
  DatePicker,
  Popover,
  Radio,
  Slider,
  Upload,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { timelineStore } from '../../Timeline.store';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import moment from 'moment';
import TimelineAnimation, {
  MILLISECOND_ONE_DAY,
  calculateTimelineStep,
} from '../../../../util/Timeline.util';
import { sleep } from '../../../../../../../functions/General.function';
import { viewerStore } from '../../../../../../../store/Viewer.store';

let animation;
let animationData = [];

export default function TimelineTasksToolbar() {
  const {
    setSettingSimulationData,
    setIsOpenSimulationSetting,
    timelineGantt,
    timelineSimulationGantt,
    currentTimelineDate,
    setCurrentTimelineDate,
    settingSimulationData,
    setTimelineSimulationStep,
    timelineSimulationStep,
    setTimelineSimulationDate,
    timelineSimulationDate,
    setTimelineAnimationControl,
    timelineAnimationControl,
    setIsTimelineSimulationPlaying,
    isTimelineSimulationPlaying
  } = timelineStore();
  const { viewer } = viewerStore();
  const [marker, setMarker] = useState({
    0: '',
    100: '',
  });
  const [sliderValue, setSliderValue] = useState(0);
  const [amountNextDays, setAmountNextDays] = useState(0);
  useEffect(() => {
    setTimelineAnimationControl(new TimelineAnimation(viewer));
    let data = {
      overrideDate: {
        startDate: moment(),
        endDate: moment(),
        isEnable: false,
      },
      view: 'planned',
      interval: {
        size: 1,
        type: 'days',
      },
    };
    setSettingSimulationData(data);
    return () => {
      setTimelineAnimationControl();
    };
  }, []);
  useEffect(() => {
    if (timelineAnimationControl) {
      timelineAnimationControl.addEventListener('playing', eventPlaying);
      timelineAnimationControl.addEventListener('endPlaying', eventEndPlaying);
      return () => {
        timelineAnimationControl.removeEventListener('playing', eventPlaying);
        timelineAnimationControl.removeEventListener('endPlaying', eventEndPlaying);
      };
    }
  }, [timelineAnimationControl, timelineSimulationDate]);
  const eventPlaying = (e) => {
    let currentTime =
      e.currentDate.valueOf() - timelineSimulationDate.startDate.valueOf();
    let time =
      (currentTime * 100) /
      (timelineSimulationDate.endDate.valueOf() -
        timelineSimulationDate.startDate.valueOf());
    setSliderValue(time);
    setCurrentTimelineDate(e.currentDate);
  };
  const eventEndPlaying = (e) => {
    setIsTimelineSimulationPlaying(false)
  };
  useEffect(() => {
    if (timelineSimulationGantt && timelineAnimationControl) {
      timelineAnimationControl.setGantt(timelineSimulationGantt);
    }
  }, [timelineSimulationGantt, timelineAnimationControl]);
  useEffect(() => {
    if (timelineSimulationGantt && timelineAnimationControl) {
      handleCalculateSlider();
    }
  }, [
    settingSimulationData,
    timelineSimulationGantt,
    timelineAnimationControl,
  ]);
  const handleSetting = () => {
    setIsOpenSimulationSetting(true);
  };

  const handlePlay = () => {
    setIsTimelineSimulationPlaying(true)
    setSliderValue(0);
    setCurrentTimelineDate(timelineSimulationDate.startDate);
    timelineAnimationControl.preStart(
      currentTimelineDate,
      timelineSimulationDate.endDate
    );
  };

  const handlePause = () => {
    timelineAnimationControl.pause();
  };
  const handleReload = () => {
    timelineSimulationGantt.clearAll();
    let ganttData = timelineGantt.ext.export_api._serializeAll();
    if (ganttData.data.length === 0) {
      message.warning('Please import data to "Tasks" tab');
      return;
    }
    timelineSimulationGantt.parse(ganttData);
    timelineAnimationControl.calculateData();
    handleCalculateSlider();
  };
  const handleCalculateSlider = () => {
    let startDate = settingSimulationData.overrideDate.startDate;
    let endDate = settingSimulationData.overrideDate.endDate;
    if (!settingSimulationData.overrideDate.isEnable) {
      let project = timelineGantt.getSubtaskDates();
      if (!project.start_date) {
        return null;
      }
      startDate = moment(project.start_date);
      endDate = moment(project.end_date);
    }

    const data = calculateTimelineStep(
      startDate,
      endDate,
      settingSimulationData
    );
    if (!data) return;
    const { amountDays, step } = data;
    setAmountNextDays(amountDays);
    setCurrentTimelineDate(startDate);
    setMarker({
      0: {
        label: <strong>{startDate.format('DD/MM/YYYY')}</strong>,
      },
      100: {
        label: <strong>{endDate.format('DD/MM/YYYY')}</strong>,
      },
    });
    setTimelineSimulationStep(step);
    setTimelineSimulationDate({
      startDate,
      endDate,
    });
    setSliderValue(0);
    timelineAnimationControl.setAmountNextDays(amountDays);
  };
  const handleChangeSlider = (e) => {
    viewer.hideAll();
    viewer.clearThemingColors(viewer.impl.mode); //! note one model
    let time =
      (e *
        (timelineSimulationDate.endDate.valueOf() -
          timelineSimulationDate.startDate.valueOf())) /
      100 /
      MILLISECOND_ONE_DAY;
    let date = timelineSimulationDate.startDate.clone().add(time, 'days');
    let nextDate = date.clone().add(amountNextDays, 'days');
    timelineAnimationControl.playIndividual(
      date.clone().valueOf(),
      nextDate.valueOf()
    );
    setCurrentTimelineDate(date);
    setSliderValue(e);
  };
  const handleChangeCurrentTimeline = (date) => {
    let currentTime =
      date.valueOf() - timelineSimulationDate.startDate.valueOf();
    let time =
      (currentTime * 100) /
      (timelineSimulationDate.endDate.valueOf() -
        timelineSimulationDate.startDate.valueOf());
    let nextDate = date.clone().add(amountNextDays, 'days');
    timelineAnimationControl.playIndividual(
      date.clone().valueOf(),
      nextDate.valueOf()
    );
    setCurrentTimelineDate(date);
    setSliderValue(time);
  };
  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: 5, padding: 5 }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          <div style={{ display: 'flex', gap: 2, width: 185 }}>
            <IconButton
              title='Move Backward'
              url={'https://img.icons8.com/ios-filled/50/skip-to-start--v1.png'}
              size='ssmall'
              disabled={isTimelineSimulationPlaying}
              // onClick={handleGantt.bind(this, 'moveBackward')}
            ></IconButton>
            <IconButton
              url={'https://img.icons8.com/glyph-neue/64/backward-button.png'}
              size='ssmall'
              disabled={isTimelineSimulationPlaying}
              // onClick={handleRedo}
            ></IconButton>
            <IconButton
              url={'https://img.icons8.com/ios-glyphs/30/play--v1.png'}
              size='ssmall' type='save' disabled={isTimelineSimulationPlaying}
              onClick={handlePlay.bind(this)}
            ></IconButton>
            <IconButton
              url={'https://img.icons8.com/ios-glyphs/30/pause--v1.png'}
              size='ssmall' type='delete' 
              onClick={handlePause.bind(this)}
              disabled={!isTimelineSimulationPlaying}
            ></IconButton>
            <IconButton
              url={'https://img.icons8.com/glyph-neue/64/backward-button.png'}
              size='ssmall'
              style={{ transform: 'rotate(180deg)' }}
              disabled={isTimelineSimulationPlaying}
              // onClick={handleRedo}
            ></IconButton>
            <IconButton
              url={'https://img.icons8.com/ios-filled/50/end--v1.png'}
              size='ssmall'
              disabled={isTimelineSimulationPlaying}
              // onClick={handleUndo}
            ></IconButton>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 2,
              width: 'calc(100% - 245px)',
              minWidth: 200,
              flex: 1,
            }}
          >
            <Slider
              step={timelineSimulationStep}
              style={{ width: '100%' }}
              marks={marker}
              onChange={handleChangeSlider}
              tipFormatter={null}
              value={sliderValue}
              disabled={isTimelineSimulationPlaying}
            />
          </div>

          <div>
            <IconButton
              url={'https://img.icons8.com/ios-glyphs/30/export.png'}
              size='ssmall'
              disabled={isTimelineSimulationPlaying}
              // onClick={handleUndo}
            ></IconButton>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <DatePicker
            value={currentTimelineDate}
            clearIcon={false}
            onChange={handleChangeCurrentTimeline}
            disabled={isTimelineSimulationPlaying}
          />
          <IconTextButton
            type='save'
            size='ssmall'
            onClick={handleReload}
            icon={'reload'}
            disabled={isTimelineSimulationPlaying}
          >
            Reload
          </IconTextButton>
          <IconTextButton
            size='ssmall'
            onClick={handleSetting}
            icon={'setting'}
            disabled={isTimelineSimulationPlaying}
          >
            Setting
          </IconTextButton>
        </div>
      </div>
    </>
  );
}
