import React from 'react';
import _ from 'lodash';
import TimelineTasksGantt from './components/tasks/TimelineTasks.gantt';
import { timelineStore } from './Timeline.store';
import { TimelineComponentType } from './constants/TimelineComponentType';
import TimelineTasksToolbar from './components/tasks/TimelineTasks.toolbar';

export default function TimelineTasks({}) {
  const { segmentKey } = timelineStore();
  return (
    <>
      <div
        style={{
          display: segmentKey === TimelineComponentType.Tasks ? 'flex' : 'none',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <TimelineTasksToolbar/>
        <TimelineTasksGantt />
      </div>
    </>
  );
}
