import React, { useEffect, useRef, useState } from 'react';
import { Result, Button, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { viewerStore } from '../../../store/Viewer.store';
import DockLayout from 'rc-dock';
import _ from 'lodash';
import ViewerForgeLayout from './Viewer.forge.layout';
import { extractQuantityGanttStore } from './featrue/extract_quantity_gantt/ExtractQuantityGantt.store';

const defaultLayout = {
  dockbox: {
    mode: 'horizontal',
    children: [
      // {
      //     id:'3d',
      //     mode: 'vertical',
      //     size: 200,
      //     children: [
      //         {
      //             id:'3d',
      //             tabs: [{ ...tab, id: 't1', title: 'Tab 1' },
      //             { ...tab, id: 't2', title: 'Tab 2' }],
      //         },

      //     ]
      // },
      {
        cached: true,
        size: 1000,
        tabs: [
          {
            id: 't5',
            title: 'Viewer',
            cached: true,
            content: <ViewerForgeLayout />,
          },
        ],
        panelLock: true,
      },
    ],
  },
  // floatbox: {
  //     mode: 'float',
  //     children: [
  //         {
  //             tabs: [
  //                 { ...tab, id: 't9', title: 'Tab 9', content: <div>Float</div> },
  //                 { ...tab, id: 't10', title: 'Tab 10' }
  //             ],
  //             x: 300, y: 150, w: 400, h: 300
  //         }
  //     ]
  // }
};
export default function ViewerLayout(props) {
  const navigate = useNavigate();
  const { viewer, setViewer, setDocker, setFeature, feature } = viewerStore();
  const { gantt5D } = extractQuantityGanttStore();
  const [search] = useSearchParams();
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setDocker(ref.current);
    }
  }, [ref.current]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <DockLayout
        ref={ref}
        defaultLayout={defaultLayout}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
        onLayoutChange={(newLayout, currentTabId, direction) => {
          console.log(newLayout, currentTabId, direction);

          if (direction === 'remove') {
            let clone = [...feature];
            let index = _.findIndex(feature, (v) => {
              return v === currentTabId;
            });
            if (index >= 0) {
              clone.splice(index, 1);
            }
            setFeature(clone);
          }
          if (viewer) {
            viewer.resize();
            setTimeout(() => {
              viewer.resize();
            }, 1000);
          }
          if (gantt5D) {
            setTimeout(() => {
              gantt5D?.render();
            }, 1000);
          }
        }}
      />
    </div>
  );
}
