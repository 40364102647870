/* eslint-disable no-unused-vars */
import { DatePicker, Form, Input, message, Select } from 'antd';
import { useEffect, useState } from 'react';

import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { requestBimGrid } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import ModalTemplate from '../../../../gen/modal/Modal.gen';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default function ModalEditUser({ rowNode, setRowNode, gridApi }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setIsLoading, user } = appStore();
  const [subGridApi, setSubGridApi] = useState();
  const [groupPermissions, setGroupPermissions] = useState([]);
  const { organizationId } = useParams();
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userCompanies, setUserCompanies] = useState([]);
  const { projectId } = useParams();
  const close = () => {
    setRowNode();
  };

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const result = await Promise.all([
        requestBimGrid('get', '/api/admin/role/getAllInProject'),
        requestBimGrid(
          'get',
          '/api/admin/organization/getAllCompanyInOrganization'
        ),
        requestBimGrid('get', '/api/user/getRoles', null, {
          organizationId,
          projectId,
          userId: rowNode.data.userId,
        }),
        requestBimGrid('get', '/api/user/getCompanies', null, {
          organizationId,
          projectId,
          userId: rowNode.data.userId,
        }),
      ]);
      setRoles(result[0]);
      setCompanies(result[1]);
      setUserRoles(
        result[2].map((v) => {
          return v.roleId;
        })
      );
      setUserCompanies(
        result[3].map((v) => {
          return v.companyId;
        })
      );
    } catch (ex) {
      message.warning(ex.message);
      close();
    }
    setIsLoading(false);
  };

  const handleAddRole = async (e) => {
    setIsLoading(true);
    let index = _.findIndex(userRoles, (v) => {
      return v === e;
    });
    if (index >= 0) return;
    let index1 = _.findIndex(roles, (v) => {
      return v.id === e;
    });
    if (index1 < 0) return;
    setIsLoading(true);
    let userRole = await requestBimGrid(
      'post',
      '/api/user/addRole',
      { roleId: roles[index1].id, projectId, userId: rowNode.data.userId },
      { organizationId, projectId }
    );
    if (userRole) {
      setUserRoles([...userRoles, e]);
      // await updateTableAsync(gridApi.api, { add: [userRole] })
    }
    setIsLoading(false);
    console.log(e);
  };
  const handleRemoveRole = async (e) => {
    let index = _.findIndex(userRoles, (v) => {
      return v === e;
    });
    if (index < 0) return;
    let index1 = _.findIndex(roles, (v) => {
      return v.id === e;
    });
    if (index1 < 0) return;
    setIsLoading(true);
    debugger;
    let userRole = await requestBimGrid('delete', '/api/user/removeRole', {
      roleId: roles[index1].id,
      projectId,
      userId: rowNode.data.userId,
    });
    if (userRole) {
      let clone = [...userRoles];
      clone.splice(index, 1);
      setUserRoles(clone);
      // await updateTableAsync(gridApi.api, { remove: [userRoles[index]] })
    }
    setIsLoading(false);
  };
  const handleAddCompany = async (e) => {
    let index = _.findIndex(userCompanies, (v) => {
      return v === e;
    });
    if (index >= 0) return;
    let index1 = _.findIndex(companies, (v) => {
      return v.id === e;
    });
    if (index1 < 0) return;
    setIsLoading(true);
    let userCompany = await requestBimGrid(
      'post',
      '/api/user/addCompany',
      {
        companyId: companies[index1].id,
        projectId,
        userId: rowNode.data.userId,
      },
      {
        organizationId,
        projectId,
      }
    );
    if (userCompany) {
      setUserCompanies([...userCompanies, e]);
      // await updateTableAsync(gridApi.api, { add: [userCompany] })
    }
    setIsLoading(false);
  };
  const handleRemoveCompany = async (e) => {
    if (!window.confirm('Are you sure to remove?')) return;
    let index = _.findIndex(userCompanies, (v) => {
      return v === e;
    });
    if (index < 0) return;
    let index1 = _.findIndex(companies, (v) => {
      return v.id === e;
    });
    if (index1 < 0) return;
    setIsLoading(true);
    let userRole = await requestBimGrid(
      'delete',
      '/api/user/removeCompany',
      { companyId: companies[index].id, projectId, userId: rowNode.data.id },
      { organizationId, projectId }
    );
    if (userRole) {
      let clone = [...userCompanies];
      clone.splice(index, 1);
      setUserCompanies(clone);
      // await updateTableAsync(gridApi.api, { remove: [userCompanies[index]] })
    }
    setIsLoading(false);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        title={'Edit User'}
        isFooter={false}
        // style={{width:300}}
      >
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout='vertical'
          className='idd-form'
          autoComplete='off'
          style={{ width: 500, maxWidth: '90vw' }}
        >
          <div>
            <Form.Item
              label='User Name'
              name='name'
              rules={[{ required: true }]}
              initialValue={rowNode.data.email}
            >
              <Input placeholder='Enter role name' disabled />
            </Form.Item>

            <Form.Item label='Roles'>
              <Select
                mode='multiple'
                value={userRoles}
                onDeselect={handleRemoveRole}
                onSelect={handleAddRole}
              >
                {roles?.map((i) => (
                  <Select.Option value={i.id} key={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label='Companies'>
              <Select
                mode='multiple'
                value={userCompanies}
                onDeselect={handleRemoveCompany}
                onSelect={handleAddCompany}
              >
                {companies?.map((i) => (
                  <Select.Option value={i.id} key={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>
      </ModalTemplate>
    </>
  );
}
