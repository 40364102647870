import { useEffect } from 'react';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import TopNavViewer from 'src/components/layout/viewer/navigation/TopNavViewer';
import { requestBimGrid } from 'src/functions/General.function';
import { appStore } from 'src/store/App.store';
import { projectStore } from 'src/store/Docs.store';
import { viewerStore } from 'src/store/Viewer.store';

export default function ViewerRoute(props) {
  const { setIsLoading } = appStore();
  const { setFileName, setIsHideTabNav } = projectStore();
  const {
    isOpenTopNav,
    viewer,
    resetViewerStore,
    versionData,
    setVersionData,
  } = viewerStore();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const folderId = search.get('folderId');
  const type = search.get('type');
  const fileId = search.get('fileId');
  const versionId = search.get('versionId');
  const { organizationId, projectId } = useParams();

  useEffect(() => {
    if (!folderId || !type || !fileId) {
      navigate(-1);
      return;
    }
    setIsHideTabNav(true);
    run();

    return () => {
      resetViewerStore();
      setIsHideTabNav(false);
    };
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid('get', '/api/version', null, {
      organizationId,
      projectId,
      versionId,
      select: [
        'version.id',
        'file.id as fileId',
        'file.name',
        'version.object_id',
        'version.version as version',
      ],
    });
    if (data) {
      setFileName(`${data?.name} (V${data?.version})`);
      setVersionData(data);
    } else {
      setIsLoading(false);
      navigate(-1);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (viewer) {
      viewer.resize();
    }
  }, [viewer, isOpenTopNav]);
  return (
    versionData && (
      <>
        <TopNavViewer />
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: `calc(100% - ${isOpenTopNav ? '130px' : '10px'})`,
          }}
        >
          <Outlet />
        </div>
      </>
    )
  );
}
