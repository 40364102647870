/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  message,
  Input,
  Select,
  Radio,
  Divider,
  Space,
  DatePicker as DatePickerDayjs,
} from 'antd';
import { appStore } from '../../../../../../store/App.store';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AppAPI } from '../../../../../api';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import DMSRFADrawingTable from '../table/DMSRFADrawing.table';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);

export default function SubmitForm({
  data,
  setData,
  isView,
  isEdit,
  isUpload = false,
}) {
  const {
    setIsOpenMainTable,
    categories,
    disciplines,
    setCategories,
    setDisciplines,
  } = dmsStore();
  const [companies, setCompanies] = useState([]);
  const [consultantGroups, setConsultantGroups] = useState([]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedMainConsultant, setSelectedMainConsultant] = useState();
  const { setIsLoading } = appStore();
  const [newCategory, setNewCategory] = useState();
  const [newDiscipline, setNewDiscipline] = useState();
  useEffect(() => {
    if (!isView) {
      run();
    }
  }, []);
  useEffect(() => {
    if (!isView) {
      handleRef();
    }
  }, [data.category, data.discipline]);

  const handleRef = async () => {
    if (data.category && data.discipline) {
      setIsLoading(true);
      try {
        const refNumberCount =
          await AppAPI.DMSRFAAPI.GetDMSRFARefByCategoryDiscipline({
            category: data.category,
            discipline: data.discipline,
          });
        data.refNumber = refNumberCount;
        setData({ ...data });
      } catch (ex) {
        message.warning(ex.message);
      }
      setIsLoading(false);
    } else {
      data.refNumber = 1;
      setData({ ...data });
    }
  };
  const run = async () => {
    setIsLoading(true);
    try {
      const consultantGroups = await AppAPI.DMSettingAPI.GetDMSSettingGroups();
      const companies = await AppAPI.AdminAPI.GetAllCompanyByOrganizationId();
      const mapping = {};
      _.forEach(companies, (v) => {
        mapping[v.id] = { name: v.name, id: v.id };
      });
      setCompanies(mapping);
      setConsultantGroups(consultantGroups);
      if (data.dmsGroupConsultantIds) {
        const tmp = handleConsultantCompany(
          data.dmsGroupConsultantIds,
          [],
          consultantGroups
        );
        setSelectedConsultants(tmp);
      }
    } catch (ex) {
      message.warning(ex.message);
    }
    setIsLoading(false);
  };
  const handleConsultantCompany = (
    ids,
    selectedConsultants,
    consultantGroups
  ) => {
    const tmp = [];
    _.forEach(ids, (id) => {
      let index1 = _.findIndex(selectedConsultants, (v) => {
        return v.id === id;
      });
      if (index1 < 0) {
        let index2 = _.findIndex(consultantGroups, (v) => {
          return v.id === id;
        });
        if (index2 >= 0) {
          let group = consultantGroups[index2];
          if (index2 >= 0) {
            if (companies[group.companyId]) {
              let index3 = _.findIndex(tmp, (v) => {
                return v.id === group.companyId;
              });
              if (index3 < 0) {
                tmp.push(companies[group.companyId]);
              }
            }
          }
        }
      }
    });
    return tmp;
  };
  const handleChange = (type, e) => {
    const clone = { ...data };
    clone[type] = e.target.value;
    setData(clone);
  };
  const handleDate = (type, e) => {
    const clone = { ...data };
    clone[type] = e;
    setData(clone);
  };
  const handleConsultantGroups = (ids) => {
    debugger;
    const tmp = handleConsultantCompany(
      ids,
      selectedConsultants,
      consultantGroups
    );
    let index = _.findIndex(tmp, (v) => {
      return v.id === selectedMainConsultant;
    });
    if (index < 0) {
      setSelectedMainConsultant();
    }
    if (tmp.length > 7) {
      return;
    }
    setSelectedConsultants(tmp);
    const clone = { ...data };
    clone.dmsGroupConsultantIds = ids;
    setData(clone);
  };
  const handleChangeMainConsultant = (e) => {
    setSelectedMainConsultant(e.target.value);
  };
  const handleChangeSelect = (type, e) => {
    const clone = { ...data };
    clone[type] = e;
    setData(clone);
  };
  const onChangeNewItem = (type, event) => {
    if (type === 'category') {
      setNewCategory(event.target.value);
    } else {
      setNewDiscipline(event.target.value);
    }
  };
  const handleAddNewItem = (type, event) => {
    if (type === 'category') {
      if (newCategory) {
        const unique = _.uniq([...categories, newCategory]);
        setCategories(unique);
        setNewCategory();
      }
    } else {
      if (newDiscipline) {
        const unique = _.uniq([...disciplines, newDiscipline]);
        setDisciplines(unique);
        setNewDiscipline();
      }
    }
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 15,
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 5,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span style={{ fontWeight: 'bold', width: 150 }}>Category:</span>
            <Select
              style={{
                width: '100%',
              }}
              placeholder='Please input'
              onChange={handleChangeSelect.bind(this, 'category')}
              value={data.category}
              disabled={isView}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      gap: 5,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Input
                      placeholder='Please enter category'
                      style={{ width: 'calc(100% - 120px)' }}
                      value={newCategory}
                      onChange={onChangeNewItem.bind(this, 'category')}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <IconTextButton
                      onClick={handleAddNewItem.bind(this, 'category')}
                      size='ssmall'
                      icon={'add'}
                      type={'save'}
                    >
                      Add item
                    </IconTextButton>
                  </div>
                </>
              )}
              options={categories.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: 5,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Discipline:</span>

            <Select
              style={{
                width: '100%',
              }}
              placeholder='Please input'
              onChange={handleChangeSelect.bind(this, 'discipline')}
              value={data.discipline}
              disabled={isView}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      gap: 5,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Input
                      placeholder='Please enter discipline'
                      style={{ width: 'calc(100% - 150px)' }}
                      value={newDiscipline}
                      onChange={onChangeNewItem.bind(this, 'discipline')}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <IconTextButton
                      onClick={handleAddNewItem.bind(this, 'discipline')}
                      size='ssmall'
                      icon={'add'}
                      type={'save'}
                    >
                      Add discipline
                    </IconTextButton>
                  </div>
                </>
              )}
              options={disciplines.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              gap: 5,
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <span style={{ fontWeight: 'bold', width: 125 }}>Ref Number:</span>
            <Input
              style={{ width: 400 }}
              placeholder='Please input'
              disabled={isView || isEdit || !data.category || !data.discipline}
              onChange={handleChange.bind(this, 'refNumber')}
              value={data.refNumber}
            />
          </div>
          {isUpload && (
            <div style={{ display: 'flex', gap: 10 }}>
              <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Date Submission:</span>
                <DatePicker
                  placeholder='Please input'
                  disabled={isView}
                  onChange={handleDate.bind(this, 'submissionDate')}
                  value={data.submissionDate}
                />
              </div>

              <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Date Reply:</span>
                <DatePicker
                  placeholder='Please input'
                  disabled={isView}
                  onChange={handleDate.bind(this, 'dueDate')}
                  value={data.dueDate}
                />
              </div>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', gap: 15, width: '100%' }}>
          <span style={{ fontWeight: 'bold', width: 125 }}>
            Consultant Groups:
          </span>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Select
              placeholder='Consultant'
              onChange={handleConsultantGroups}
              style={{ width: '100%' }}
              mode='multiple'
              value={data.dmsGroupConsultantIds}
              disabled={isView}
            >
              {consultantGroups.map((v) => (
                <Select.Option value={v.id}> {v.name} </Select.Option>
              ))}
            </Select>
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: 5,
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <span style={{ fontWeight: 'bold', width: 125, fontSize: 12 }}>
                  Consultants Name:
                </span>
                <span
                  style={{ width: 125, fontSize: 11, color: 'gray' }}
                >{`(Up to 7 companies)`}</span>
              </div>

              <Radio.Group
                // onChange={handleChangeMainConsultant}
                size='small'
                value={selectedMainConsultant}
                disabled={isView}
              >
                {selectedConsultants.map((v) => (
                  <Radio disvalue={v.id}>{v.name}</Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        </div>
        {isUpload && (
          <div
            style={{
              display: 'flex',
              gap: 5,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span style={{ fontWeight: 'bold', width: 125 }}>
              Requested by:
            </span>
            <Input
              placeholder='Please input'
              onChange={handleChange.bind(this, 'requestedBy')}
              style={{ width: 400 }}
              value={data.requestedBy}
            />
          </div>
        )}

        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span style={{ fontWeight: 'bold', width: 135 }}>Subject:</span>
          <Input
            placeholder='Please input'
            onChange={handleChange.bind(this, 'subject')}
            style={{ width: '100%' }}
            disabled={isView}
            value={data.subject}
          />
        </div>

        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span style={{ fontWeight: 'bold', width: 135 }}>Email content:</span>
          <Input.TextArea
            placeholder='Please input'
            onChange={handleChange.bind(this, 'content')}
            style={{ width: '100%' }}
            disabled={isView}
            value={data.content}
          />
        </div>

        {isUpload && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <div style={{ display: 'flex' }}>
              <IconTextButton
                icon='add_file'
                size='ssmall'
                type='save'
                onClick={() => {
                  setIsOpenMainTable(true);
                }}
              >
                Add Drawing to list
              </IconTextButton>
            </div>

            <DMSRFADrawingTable />
          </div>
        )}
      </div>
    </>
  );
}
