/* eslint-disable no-unused-vars */
import { Form, message, Tag } from 'antd';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AdminUpdateRoleAPI } from 'src/api/admin';
import HorizontalDivider from 'src/components/atoms/HorizontalDivider';
import LabelForm from 'src/components/atoms/LabelForm';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import LabelAndTextAreaForm from 'src/components/molecules/LabelAndTextAreaForm';
import Modal from 'src/components/molecules/Modal';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../../functions/General.function';
import { appStore } from '../../../../../../store/App.store';
import RoleGroupPermissionTable from '../table/RoleGroupPermission.table';

export default function ModalEditGroupPermission({
  setRowNode,
  rowNode,
  gridApi,
}) {
  const [form] = Form.useForm();
  const formRef = useRef();
  const { setIsLoading, user } = appStore();
  const [subGridApi, setSubGridApi] = useState();
  const [groupPermissions, setGroupPermissions] = useState();
  const { organizationId, projectId } = useParams();

  useEffect(() => {
    let temp = [];
    _.forEach(rowNode.allLeafChildren, (v) => {
      temp.push({
        id: v.data.id,
        name: v.data.groupPermissionName,
        groupPermissionId: v.data.groupPermissionId,
      });
    });
    setGroupPermissions(temp);
  }, []);

  const close = () => {
    setRowNode();
  };

  const handleOk = async (e) => {
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};
      formData.forEach((value, key) => {
        data[key] = value;
      });
      data.id = rowNode.allLeafChildren[0].data.id;
      const resData = await AdminUpdateRoleAPI({
        ...data,
      });
      if (resData) {
        let temp = [];
        _.forEach(rowNode.allLeafChildren, (v) => {
          v.data.name = data.name;
          v.data.description = data.description;
          temp.push(v.data);
        });
        await updateTableAsync(gridApi.api, { update: temp });
        gridApi.api.redrawRows();
        close();
      }
    } catch (ex) {
      if (ex.message) message.error(ex.message);
    }
    setIsLoading(false);
  };

  const handleAddGroupPermission = async (e) => {
    let clone = [...groupPermissions];
    let index = _.findIndex(clone, (v) => {
      return v.id === e.id;
    });
    if (index < 0) {
      setIsLoading(true);
      let cloneRow = { ...rowNode.allLeafChildren[0].data };
      const repData = await requestBimGrid(
        'post',
        '/api/admin/role/addGroupPermissionToRole',
        { groupPermissionId: e.id, roleId: cloneRow.id },
        {
          organizationId,
          projectId,
        }
      );
      if (repData) {
        //add row in main table
        cloneRow.groupPermissionId = e.id;
        cloneRow.groupPermissionName = e.name;
        await updateTableAsync(gridApi.api, { add: [cloneRow] });
        gridApi.api.redrawRows();
        //add row in modal table
        e.isAdded = true;
        clone.push(e);
        setGroupPermissions(clone);
        await updateTableAsync(subGridApi.api, { update: [e] });
        subGridApi.api.redrawRows();
      }
      setIsLoading(false);
    } else {
      message.warning('This permission was added');
    }
  };
  const handleRemoveGroupPermission = async (e) => {
    if (!window.confirm('Are you want to remove permission?')) return;
    let clone = [...groupPermissions];
    let index = _.findIndex(clone, (v) => {
      return v.groupPermissionId === e.id;
    });
    if (index >= 0) {
      setIsLoading(true);
      const reqData = await requestBimGrid(
        'delete',
        '/api/admin/role/removeGroupPermissionFormRole',
        {
          roleId: clone[index].id,
          groupPermissionId: clone[index].groupPermissionId,
        },
        { organizationId, projectId }
      );
      if (reqData) {
        //remove row in main table
        let indexMain = _.findIndex(rowNode.allLeafChildren, (v) => {
          return v.data.groupPermissionId === e.id;
        });
        if (indexMain >= 0) {
          await updateTableAsync(gridApi.api, {
            remove: [rowNode.allLeafChildren[index].data],
          });
          gridApi.api.redrawRows();
        }
        // remove in modal table
        e.isAdded = false;
        await updateTableAsync(subGridApi.api, { update: [e] });
        subGridApi.api.redrawRows();
        clone.splice(index, 1);
        setGroupPermissions(clone);
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Edit Role'}>
        <div className='w-[1000px] max-w-screen-lg'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndInputForm
              label='Group Permission Name'
              name='name'
              isRequired
              placeholder='Enter group permission name'
              defaultValue={rowNode?.allLeafChildren[0].data.name}
            />
            <LabelAndTextAreaForm
              label='Description'
              name='description'
              placeholder='Enter description'
              defaultValue={rowNode?.allLeafChildren[0].data.description}
            />
            <LabelForm label={'Permission'} />
            {groupPermissions?.map((i) => (
              <Tag
                onClose={handleRemoveGroupPermission.bind(this, i)}
                key={i.id}
                className='m-1 bg-primary-600 text-[14px] font-bold text-white'
              >
                {i?.name}
              </Tag>
            ))}
          </form>
          <HorizontalDivider />
          {groupPermissions && (
            <RoleGroupPermissionTable
              setGridApi={setSubGridApi}
              groupPermissions={groupPermissions}
              handleAddGroupPermission={handleAddGroupPermission}
              handleRemoveGroupPermission={handleRemoveGroupPermission}
            />
          )}
        </div>
      </Modal>
    </>
  );
}
