/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';

import _ from 'lodash';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import { qrElementStore } from '../QRElement.store';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import PropertySetting from '../components/Property.item';
import { v4 } from 'uuid';
import HeaderProperty from '../components/Property.header';
import ListTemplate from '../components/ListTemplate';
import ListProperty from '../components/ListProperty';

export default function QRElementSettingModal() {
  const [items, setItems] = useState([]);
  const { setIsShowSetting } = qrElementStore();
  useEffect(() => {}, []);
  const close = () => {
    setIsShowSetting();
  };

  const handleOk = async (e) => {};

  const handleNewRow = () => {
    let clone = [...items];
    clone.push({ name: '', property: '', id: v4() });
    setItems(clone);
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Setting'}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={false}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            gap: 10,
            height: 'calc(100vh - 450px)',
            overflow: 'auto',
          }}
        >
          <ListTemplate />

          <ListProperty />
        </div>
      </ModalTemplate>
    </>
  );
}
