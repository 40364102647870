import { Route } from 'react-router-dom';

import { VDCRoute } from 'src/routes/apps';
import VDCPage from '../components/pages/apps/vdc/VDC.Page';
import VRPage from '../components/pages/apps/vdc/VR.Page';
import ZoomPage from '../components/pages/apps/vdc/Zoom.Page';

export const VDCAppRoutes = [
  <Route path=':projectId'>
    <Route path='vdc' element={<VDCRoute />}>
      <Route index element={<VDCPage />} />
      <Route path='zoom' element={<ZoomPage />} />
      <Route path='vr' element={<VRPage />} />
    </Route>
  </Route>,
];
