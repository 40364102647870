import LabelForm from 'src/components/atoms/LabelForm';
import SelectForm from 'src/components/atoms/SelectForm';

/**
 * LabelAndInputForm component renders a label and an input form element.
 *
 * @param {string} label - The text content of the label.
 * @param {string} placeholder - The placeholder text for the input element.
 * @param {string} name - The name attribute for the input element.
 * @param {boolean} isRequired - Whether the input element is required.
 * @param {string} [defaultValue] - The default value of the input element (optional).
 * @param {JSX.Element} items
 * @returns {JSX.Element} The rendered label and input elements.
 */
const LabelAndSelectForm = ({
  label,
  placeholder,
  name,
  isRequired,
  defaultValue,
  items,
}) => {
  return (
    <div>
      <LabelForm name={name} label={label} isRequired={isRequired} />
      <SelectForm
        name={name}
        placeholder={placeholder}
        isRequired={isRequired}
        defaultValue={defaultValue}
      >
        {items}
      </SelectForm>
    </div>
  );
};

export default LabelAndSelectForm;
