import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DMS_COLOR } from '../../dms/constant';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { Tooltip } from 'antd';
import { dmsStore } from '../../../../../../store/DMS.store';

export default function ConsultantCell(props) {
  const {
    groupsOfUser,
    setIsOpenRFAValidate,
    setSelectedDraingNodeValidate,
    setSelectedRFAValidateConsultant,
    setIsOpenRFAViewRev,
    setSelectedRFARevNode,
    setSelectedRFAViewConsultant,
  } = dmsStore();
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [isAllow, setIsAllow] = useState();
  useEffect(() => {
    if (cellValue) {
      debugger;
      const index = groupsOfUser.findIndex((v) => {
        return (
          cellValue.companyId === v.companyId &&
          cellValue?.groupIds.includes(v.id)
        );
      });
      if (index >= 0) {
        setIsAllow(true);
      }
    }
    return () => {
      setIsAllow(false);
    };
  }, [cellValue, groupsOfUser]);

  const handleOpenValidate = () => {
    setSelectedRFAValidateConsultant(cellValue);
    setSelectedDraingNodeValidate(props.node);
    setIsOpenRFAValidate(true);
  };
  const handleOpenView = () => {
    debugger;
    setSelectedRFAViewConsultant(props.column.colId);
    setSelectedRFARevNode(props.node);
    setIsOpenRFAViewRev(true);
  };
  return (
    cellValue && (
      <div
        style={{
          display: 'flex',
          gap: 5,
          background: DMS_COLOR[cellValue.status]
            ? DMS_COLOR[cellValue.status].bg
            : '',
          color: DMS_COLOR[cellValue.status]
            ? DMS_COLOR[cellValue.status].color
            : '',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              padding: '0px 5px',
              width: 35,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Tooltip title={cellValue.name}>
              <span style={{ fontWeight: 'bold', width: 35 }}>
                {cellValue.companyShortName ?? cellValue.companyName}
              </span>
            </Tooltip>
          </div>
          <div>
            {/* <span style={{ fontWeight: 'bold', overflow: 'hidden' }}>- </span> */}
          </div>
          {cellValue?.date && (
            <div>
              <span
                style={{ fontWeight: 'bold', width: 35, overflow: 'hidden' }}
              >
                {moment(new Date(cellValue?.date)).format('DD/MM/YYYY')}
              </span>
            </div>
          )}
        </div>

        <div style={{ display: 'flex' }}>
          {cellValue.status === 'notstart' ? (
            <>
              {isAllow && (
                <IconButton
                  icon={'validate'}
                  size='sssmall'
                  type='save'
                  onClick={handleOpenValidate}
                />
              )}
            </>
          ) : (
            <>
              <IconButton
                icon={'view'}
                size='sssmall'
                type='save'
                onClick={handleOpenView}
              />
            </>
          )}
        </div>
      </div>
    )
  );
}
// cellValue.status === 'notstart'
