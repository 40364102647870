import { Input, DatePicker, message } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import { useSearchParams } from 'react-router-dom';
import { vrConnectorStore } from './VRConnector.store';
import { ViewerAPI } from '../../../../../api';
export default function VRConnectorToolbar() {
  const [search] = useSearchParams();

  const { setVRConnectorLoading, setIsAddNewVRModel } = vrConnectorStore();

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    try {
      debugger;
    } catch (ex) {
      message.warning(ex.message);
    }
  };
  const handleChange = async (e) => {
    setVRConnectorLoading(true);
    try {
    } catch (ex) {
      message.warning(ex.message);
    }
    setVRConnectorLoading(false);
  };
  const handleAdd = () => {
    setIsAddNewVRModel(true);
  };
  const handleReload = async () => {
    setVRConnectorLoading(true);
    // try {
    //   let data = await ViewerAPI.QRDrawingAPI.GetAll({ fileId });
    //   setDrawings(data);
    // } catch (ex) {
    //   message.warning();
    // }
    setVRConnectorLoading(false);
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 5,
          padding: 5,
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          <IconTextButton
            icon={'add'}
            type='regular'
            size='ssmall'
            onClick={handleAdd.bind(this)}
          >
            Create
          </IconTextButton>

          <IconTextButton
            icon={'reload'}
            type='regular'
            size='ssmall'
            onClick={handleReload.bind(this)}
          >
            Refresh
          </IconTextButton>
        </div>
      </div>
    </>
  );
}
