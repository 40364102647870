import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Tree } from 'antd';
import { useSearchParams, useParams } from 'react-router-dom';
import {
  FolderOutlined,
  FolderOpenOutlined,
  DownOutlined,
  FileOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import {
  requestBimGrid,
  updateTreeData,
} from '../../../../../functions/General.function';
import TreeFolder from '../tree/TreeFolder.tree';
import TreeFile from '../tree/TreeFile.tree';

const treeType = {
  folder: (d) => {
    return !d.expanded ? <FolderOutlined /> : <FolderOpenOutlined />;
  },
  file: (d) => {
    return !d.expanded ? <FileOutlined /> : <FileOutlined />;
  },
};

export default function TreeView({
  loading,
  setLoading,
  setOtherViews,
  otherViews,
  setItemSelected,
}) {
  const [search] = useSearchParams();
  const type = search.get('type') ? search.get('type') : '3d';
  const { organizationId, projectId } = useParams();
  const [treeData, setTreeData] = useState([]);
  useEffect(() => {
    run();
  }, []);

  const run = async () => {
    setLoading(true);
    let data = await requestBimGrid(
      'get',
      '/api/folder/getByProjectType',
      null,
      { organizationId, projectId, type }
    );
    if (data) {
      _.forEach(data, (v) => {
        if (!v.fileId) {
          v.icon = treeType.folder;
          v.title = <TreeFolder item={v} />;
          v.className = 'idd-tree-node';
        }
      });
      setTreeData(data);
    }
    setLoading(false);
  };
  const onLoadData = ({ key, children }) =>
    new Promise(async (resolve) => {
      if (children) {
        resolve();
        return;
      }
      let data = await requestBimGrid(
        'get',
        '/api/folder/getFolderChildAndFile',
        null,
        { organizationId, projectId, id: key }
      );
      if (data) {
        let temp = [];

        _.forEach(data, (v) => {
          if (!v.fileId) {
            if (v.key === key) return;
            v.icon = treeType.folder;
            v.title = <TreeFolder item={v} />;
            v.className = 'idd-tree-node';
            temp.push(v);
          } else {
            // v.icon = treeType.file
            v.key = v.fileId;
            v.title = (
              <TreeFile
                item={v}
                setOtherViews={setOtherViews}
                otherViews={otherViews}
                setItemSelected={setItemSelected}
              />
            );
            v.isLeaf = true;
            v.className = 'idd-tree-node';
            temp.push(v);
          }
        });
        temp.sort(function (a, b) {
          return a.fileId !== null
            ? 1
            : -1 || a?.fileName?.localeCompare(b.fileName);
        });
        setTreeData((origin) => updateTreeData(origin, key, temp));
        resolve();
      }
    });

  return (
    <>
      <div>
        <Tree
          blockNode
          showLine={<CheckCircleFilled />}
          showIcon={true}
          // defaultExpandedKeys={['0-0-0']}
          // onSelect={onSelect}
          treeData={treeData}
          loadData={onLoadData}
          switcherIcon={<DownOutlined />}
          // loadedKeys={loadedKeys}
          // onExpand={handleExpand}
        ></Tree>
      </div>
    </>
  );
}
