import { requestBimGrid } from '../../../functions/General.function';

export const GetOneWorkerList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-one-worker-list',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllWorkerListByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-worker-list-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateWorkerList = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-worker-list',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOnePayment = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-payment',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateRangePayment = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-range-payment',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneWorkerList = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-worker-list',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteWorkerList = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-worker-list',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
/////////
export const GetOneSuperviorList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-one-supervior-list',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllSuperviorListByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-supervior-list-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateSuperviorList = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-supervior-list',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneSuperviorList = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-supervior-list',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteSuperviorList = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-supervior-list',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
/////////
export const GetAllTrade = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-trade',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetOneCostCode = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-one-cost-code',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllCostCodeByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-cost-code-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateCostCode = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-cost-code',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneCostCode = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-cost-code',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteCostCode = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-cost-code',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
/////////
export const GetAllBlock = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-block',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetOneBlockZone = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-one-block-zone',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllBlockZoneByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-block-zone-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateBlockZone = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-block-zone',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneBlockZone = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-block-zone',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteBlockZone = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-block-zone',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetAllHubDeviceWBlockZoneByBlock = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-hub-device-block-zone-by-block',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CreateHubDeviceWBlockZone = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-hub-device-block-zone',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const UpdatesHubDevicesWBlockZone = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-hub-device-block-zone',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteHubDeviceWBlockZone = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-hub-device-zone',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
//////////
export const GetOneWorkTime = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-one-work-time',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllWorkTimeByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-work-time-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateWorkTime = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-work-time',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneWorkTime = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-work-time',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteWorkTime = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-work-time',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
////////////
export const GetOneHubDevices = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-one-hub-devices',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllHubDevicesByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-hub-devices-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateHubDevices = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-hub-devices',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneHubDevices = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-hub-devices',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteHubDevices = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-hub-devices',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
////////////
export const GetOneTrackingDevices = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-one-tracking-devices',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllTrackingDevicesByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/setting/get-all-tracking-devices-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateTrackingDevices = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/add-tracking-devices',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneTrackingDevices = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/setting/update-one-tracking-devices',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteTrackingDevices = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/setting/delete-one-tracking-devices',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
