import { Route } from 'react-router-dom';

import WorkerPaymentMonthLayout from 'src/components/layout/apps/worker/payment_month/WorkerPaymentMonth.layout';
import { WorkerRoute } from 'src/routes/apps';
import WorkerDailyPage from '../components/pages/apps/worker/WorkerDaily.Page';
import WorkerSettingPage from '../components/pages/apps/worker/WorkerSetting.Page';
import WorkerTrackPage from '../components/pages/apps/worker/WorkerTrack.Page';

export const WorkerAppRoutes = [
  <Route path=':projectId'>
    <Route path='worker' element={<WorkerRoute />}>
      <Route path='setting' element={<WorkerSettingPage />} />
      <Route path='daily' element={<WorkerDailyPage />} />
      <Route path='track' element={<WorkerTrackPage />} />
      <Route path='payment-month' element={<WorkerPaymentMonthLayout />} />
    </Route>
  </Route>,
];
