import _ from 'lodash';
import { Raycaster } from 'three';
import { workerStore } from '../../../../store/Worker.store';
import icons from '../../../gen/constant/Icon.constant';
import { EventDispatcher } from '../extensions/utils/EventDispatcher';
export default class TrackingDevices extends EventDispatcher {
  name;
  raycaster = new window.THREE.Raycaster();
  enabled = false;
  viewer;
  raycaster;
  camera;
  // offset;

  hubDevices;
  devices;
  style;
  hubStyle;
  currentItem;

  mouse = new window.THREE.Vector2();
  onDownPosition = new window.THREE.Vector2();
  onUpPosition = new window.THREE.Vector2();
  onMovePosition = new window.THREE.Vector2();

  event = {
    add: 'add',
    update: 'update',
    remove: 'remove',
  };
  //
  constructor(viewer, name = 'TrackingDevices') {
    super();
    this.viewer = viewer;
    this.raycaster = new Raycaster();
    // this.raycaster.params.PointCloud.threshold = 0.1;
    this.camera = viewer.impl.camera;
    // this.offset = viewer.impl.model.getData().globalOffset;
    this.name = name;
  }
  init = async (data) => {
    debugger;
    this.devices = data;
    const dataVizExtn = await this.viewer.loadExtension(
      'Autodesk.DataVisualization'
    );
    const DataVizCore = window.Autodesk.DataVisualization.Core;
    dataVizExtn.removeAllViewables();
    this.style = new DataVizCore.ViewableStyle(
      DataVizCore.ViewableType.SPRITE,
      new window.THREE.Color('white'),
      icons.worker_tracking
    );
    this.hubStyle = new DataVizCore.ViewableStyle(
      DataVizCore.ViewableType.SPRITE,
      new window.THREE.Color(0xff0000),
      icons.hub_device
    );
    this.reGenerateDevice();
    this.viewer.addEventListener(
      DataVizCore.MOUSE_HOVERING,
      this.onHubDeviceHovering
    );
    this.viewer.addEventListener(
      DataVizCore.MOUSE_CLICK,
      this.onHubDeviceClick
    );
  };
  load = () => {};
  unload = () => {
    const DataVizCore = window.Autodesk.DataVisualization.Core;
    this.viewer.addEventListener(
      DataVizCore.MOUSE_HOVERING,
      this.onHubDeviceHovering
    );
    this.viewer.addEventListener(
      DataVizCore.MOUSE_CLICK,
      this.onHubDeviceClick
    );
    this.dispose();
  };

  generateHubDevice = (dbId, point) => {
    const DataVizCore = window.Autodesk.DataVisualization.Core;

    const viewable = new DataVizCore.SpriteViewable(point, this.hubStyle, dbId);
    return viewable;
  };

  generateWorkerDevice = (dbId, point) => {
    const DataVizCore = window.Autodesk.DataVisualization.Core;
    const viewable = new DataVizCore.SpriteViewable(point, this.style, dbId);
    return viewable;
  };

  reGenerateDevice = async () => {
    const DataVizCore = window.Autodesk.DataVisualization.Core;
    const viewableData = new DataVizCore.ViewableData();
    viewableData.spriteSize = 24;
    const dataVizExtn = this.viewer.getExtension('Autodesk.DataVisualization');
    if (!dataVizExtn) return;
    dataVizExtn.removeAllViewables();
    const dbIds = [];
    let count = 0;
    _.forEach(this.devices, async (v, k) => {
      if (v.location) {
        count++;
        dbIds.push(count);
        const viewable = this.generateWorkerDevice(
          count,
          new window.THREE.Vector3().fromArray(v.location)
        );
        v.deviceId = count;
        viewableData.addViewable(viewable);
      }
    });

    _.forEach(this.hubDevices, async (v, k) => {
      if (v.location) {
        count++;
        dbIds.push(count);
        const viewable = this.generateHubDevice(
          count,
          new window.THREE.Vector3().fromArray(v.location)
        );
        v.deviceId = count;
        viewableData.addViewable(viewable);
      }
    });

    await viewableData.finish();
    dataVizExtn.addViewables(viewableData);
  };

  onHubDeviceHovering = (event) => {
    const dataVizExtn = this.viewer.getExtension('Autodesk.DataVisualization');
    let isTrackingDevice = true;
    let item = this.devices.find((v) => {
      return v.deviceId === event.dbId;
    });
    if (!item) {
      isTrackingDevice = false;
      item = this.hubDevices.find((v) => {
        return v.deviceId === event.dbId;
      });
    }
    debugger;
    if (event.hovering) {
      dataVizExtn.invalidateViewables([event.dbId], (viewable) => {
        return {
          scale: 1.5, // Restore the viewable size
          // url: 'https://img.icons8.com/ios/50/joystick.png',
        };
      });
      workerStore
        .getState()
        .setHubDeviceSprite({ item, event: event.originalEvent });
    } else {
      dataVizExtn.invalidateViewables([event.dbId], (viewable) => {
        return {
          scale: 1.0, // Restore the viewable size
          url: isTrackingDevice ? icons.worker_tracking : icons.hub_device,
        };
      });
      workerStore.getState().setHubDeviceSprite();
    }
  };
  onHubDeviceClick = () => {};
  dispose = () => {};
}
