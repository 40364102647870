import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Typography, Tree, Input, Button } from 'antd';
import { useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom';
import { appStore } from '../../../../../store/App.store';
import { projectStore } from '../../../../../store/Docs.store';
import { FolderFilled, FolderOutlined, FolderOpenOutlined, DownOutlined, FileOutlined, CaretUpFilled, CaretDownFilled, CheckCircleFilled } from '@ant-design/icons';
import { requestBimGrid } from '../../../../../functions/General.function';
import TreeFolder from './node/TreeFolder.tree';
import TreeFile from './node/TreeFile.tree';
import TreeViewList from './TreeView.list';
import Loader from '../../../../loader/LoaderApp';
import { viewerStore } from '../../../../../store/Viewer.store';
import TreeView from './TreeView.tree';
import SelectViewModal from './modal/SelectView.modal';






const Autodesk = window.Autodesk;
export default function TreeViewFeature() {
    const [search] = useSearchParams();
    const { user, setIsLoading } = appStore()
    const { setType, setFolder } = projectStore()
    const { organizationId, projectId } = useParams()
    const [itemSelected, setItemSelected] = useState()
    const [loading, setLoading] = useState()
    const [isOpen, setIsOpen] = useState([])
    const { viewer, listModel,setListModel } = viewerStore()
    const [otherViews, setOtherViews] = useState()
    useEffect(() => {
        if (viewer) {
            listModel.push(viewer.impl.model.myData.loadOptions)
            const models = viewer.impl.modelQueue().getModels();
            _.forEach(models, v => {
                if (v.myData.loadOptions.fileId !== viewer.impl.model.myData.loadOptions.fileId)
                    listModel.push(v.myData.loadOptions)
            })
        }
        return()=>{
            setListModel([])
        }
    }, [])
    useEffect(() => {
        if (viewer) {
            viewer.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, handleAddEvent)
            viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, handleUnLoadEvent)
        }
    }, [viewer, listModel])

    const handleAddEvent = (e) => {
        if (!e.model.myData) return
        let clone = [...listModel]
        clone.push(e.model.myData.loadOptions)
        setListModel(clone)
    }
    const handleUnLoadEvent = (e) => {
        // if (!e.model.myData) return
        // // const models = viewer.impl.modelQueue().getModels();
        // let clone = [...listModel]
        // let index = _.forEach(clone, v => { return v.loadOptions.fileId !== e.model.myData.loadOptions.fileId })
        // clone.splice(index ,1)
        // viewer.unloadModel(clone)
    }



    return (
        <>
            {loading && <div style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 11111111 }} >
                <Loader />
            </div>}
            <div style={{ display: isOpen ? 'block' : 'none', width: '100%', height: 'calc(100% - 15px)', overflow: 'auto' }} >
                <TreeViewList
                    setLoading={setLoading}
                    loading={loading}

                />
            </div>
            <div>
                <div style={{ width: '100%', height: '15px', display: 'flex', justifyContent: 'center', alignContent: 'center', backgroundColor: 'black' }}
                    onClick={setIsOpen.bind(this, !isOpen)} >
                    {isOpen ? <CaretUpFilled style={{ color: 'white' }} /> : <CaretDownFilled style={{ color: 'white' }} />}
                </div>
            </div>
            <div style={{ display: !isOpen ? 'flex' : 'none', width: '100%', height: '100%', padding: 2, flexDirection: 'column', gap: 5, overflow: 'auto' }} >
                {/* <div style={{ display: 'flex', gap: 2, alignItems: 'center' }} >
                <Input.Search className='idd-input' placeholder="Search" />
                <Button className={`idd-custom-button save`} icon={
                    <FolderFilled />
                } onClick={handleAddFolder.bind(this, projectId, null)}  >Add</Button>
            </div> */}
                <TreeView
                    setLoading={setLoading}
                    loading={loading}
                    setOtherViews={setOtherViews}
                    otherViews={otherViews}
                    setItemSelected={setItemSelected}
                />

            </div>
            {(otherViews && itemSelected) && <div style={{ position: 'absolute', height: '100%', width: '100%', top: 0, backgroundColor: '#d3d3d38a' }} >
                <SelectViewModal
                    setOtherViews={setOtherViews}
                    otherViews={otherViews}
                    setItemSelected={setItemSelected}
                    itemSelected={itemSelected}
                    setLoading={setLoading}
                    loading={loading}
                />

            </div>}
        </>


    );
}
