/* eslint-disable no-lone-blocks */
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import DateCell from '../../../../../ag_grid_components/cell/Date.cell';
import { AgGridReact } from 'ag-grid-react';

import ActionButtonCell from '../cell/ActionButton.cell';
import { dmsStore } from '../../../../../../store/DMS.store';
import { buildSql } from '../../../../../../functions/Table.function';
import { AppAPI } from '../../../../../api';
import REFNumberCell from '../cell/REFNumber.cell';
import ConsultantCell from '../cell/Cosultant.cell';
import ConsultantCellGroup from '../cell/Cosultant.cell.group';
import RevCell from '../cell/Rev.cell';
import _ from 'lodash';
import {
  generateRFAREVConsultantGroup,
  generateRFAREVFromApi,
} from '../utils/RevUtils';
import TotalConsultantCellGroup from '../cell/TotalCosultant.cell.group';
import TotalConsultantCell from '../cell/TotalCosultant.cell';

export default function DMSRFATable() {
  const { setDMSRFAGridApi, dmsRFAGridApi } = dmsStore();
  const { projectId } = useParams();
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'REF Number',
      field: 'ref_number',
      rowGroup: true,
      // flex: 1,
      minWidth: 400,
      cellRendererParams: {
        // checkbox: true,
        suppressCount: true,
        innerRenderer: REFNumberCell,
        // innerRendererParams: { },
      },
      colSpan: (params) => {
        if (params.node.level < 2) return 11;
        else return 1;
      },
      pinned: 'left',
      suppressMovable: true,
      // lockPosition: "left",
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: 'agSetColumnFilter',
    };
  }, []);

  const columnDefs = useMemo(() => {
    const consultants = [];
    for (let index = 1; index <= 7; index++) {
      consultants.push({
        headerName: `Consultant ${index}`,
        field: `consultant_${index}`,
        filter: 'agSetColumnFilter',
        minWidth: 200,
        suppressMovable: true,
        columnGroupShow: 'open',
        cellRendererSelector: (params) => {
          const isGroup = params.node.group;
          const level = params.node.level;
          if (!isGroup) {
            return {
              component: ConsultantCell,
            };
          } else if (level === 2) {
            return {
              component: ConsultantCellGroup,
            };
          }
        },
        cellStyle: { padding: 0 },
      });
    }
    return [
      {
        headerName: '',
        field: 'row_index',
        valueGetter: 'node.rowIndex + 1',
        pinned: 'left',
        lockPosition: 'left',
        minWidth: 50,
        maxWidth: 50,
        suppressFilter: true,
        suppressMovable: true,
        suppressToolPanel: true,
        suppressSorting: true,
        suppressMenu: true,
      },
      {
        headerName: 'Category',
        field: 'category',
        rowGroup: true,
        hide: true,
        lockVisible: true,
      },
      {
        headerName: 'Discipline',
        field: 'discipline',
        rowGroup: true,
        hide: true,
        lockVisible: true,
      },
      {
        headerName: 'REF Number',
        field: 'ref_number',
        rowGroup: true,
        hide: true,
        lockVisible: true,
      },
      {
        headerName: 'Rev',
        field: 'rev',
        pinned: 'left',
        minWidth: 60,
        maxWidth: 60,
        suppressFilter: true,
        suppressMovable: true,
        suppressToolPanel: true,
        suppressSorting: true,
        suppressMenu: true,
        filter: false,
        cellRendererSelector: (params) => {
          const level = params.node.level;
          if (level === 2) {
            return {
              component: RevCell,
            };
          }
          return undefined;
        },
        cellStyle: { padding: 0, textAlign: 'center' },
      },
      {
        headerName: 'Submission',
        suppressMovable: true,
        suppressStickyLabel: true,
        openByDefault: false,
        children: [
          {
            headerName: 'Drawing Number',
            field: 'drawing_number',
            filter: 'agSetColumnFilter',
            suppressMovable: true,
            minWidth: 300,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Drawing Name',
            field: 'drawing_name',
            filter: 'agSetColumnFilter',
            minWidth: 300,
            suppressMovable: true,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Requested By',
            field: 'requested_by',
            filter: 'agSetColumnFilter',
            suppressMovable: true,
            columnGroupShow: 'open',
          },

          {
            headerName: 'Submission Date',
            field: 'submission_date',
            filter: 'agSetColumnFilter',
            width: 130,
            maxWidth: 130,
            suppressMovable: true,
            columnGroupShow: 'closed',
            cellRendererSelector: (params) => {
              return {
                component: DateCell,
              };
            },
          },
          {
            headerName: 'Due Date',
            field: 'due_date',
            filter: 'agSetColumnFilter',
            width: 120,
            maxWidth: 120,
            suppressMovable: true,
            columnGroupShow: 'closed',
            cellRendererSelector: (params) => {
              return {
                component: DateCell,
              };
            },
          },
        ],
      },
      {
        headerName: 'Consultants',
        suppressMovable: true,
        suppressStickyLabel: true,
        openByDefault: true,
        children: [
          ...consultants,
          {
            field: 'totalConsultants',
            columnGroupShow: 'closed',
            suppressMovable: true,
            cellRendererSelector: (params) => {
              const level = params.node.level;
              if (level === 2) {
                return {
                  component: TotalConsultantCellGroup,
                };
              } else if (level === 3) {
                return {
                  component: TotalConsultantCell,
                };
              }
              return undefined;
            },
            cellStyle: { padding: 0 },
          },
        ],
      },
      // ...consultants,
      {
        headerName: '',
        field: 'action',
        minWidth: 100,
        maxWidth: 100,
        lockPosition: 'right',
        pinned: 'right',
        suppressFilter: true,
        suppressMovable: true,
        suppressToolPanel: true,
        suppressSorting: true,
        suppressMenu: true,
        filter: false,
        cellRendererSelector: (params) => {
          return {
            component: ActionButtonCell,
          };
        },
      },
    ];
  }, []);

  const runRequest = ({ request, parentNode, api }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const groupKeys = request.groupKeys;
        let limit = '';
        let offset = '';
        let level = groupKeys.length;
        {
          if (request.endRow !== undefined && request.startRow !== undefined) {
            limit = request.endRow - request.startRow;
            offset = request.startRow;
          }
        }
        const rawSqlRow = buildSql(request, projectId);
        const rawSqlLastRowIndex = buildSql(
          { ...request, startRow: undefined, endRow: undefined },
          projectId
        );
        const data = await AppAPI.DMSRFAAPI.GetDMSRFAData({
          rawSqlRow,
          rawSqlLastRowIndex,
          projectId,
          level,
          groupKeys,
          limit,
          offset,
          rev: parentNode?.data?.rev,
          dmsRFAId: parentNode?.data?.id,
        });

        if (data) {
          if (level === 3) {
            const { rows, parent } = generateRFAREVFromApi(
              data.rowData,
              parentNode.data.consultant_groups
            );
            data.rowData = rows;
            parentNode.setData({ ...parentNode.data, ...parent });
          } else if (level === 2) {
            _.forEach(data.rowData, (v) => {
              v.consultant_groups = generateRFAREVConsultantGroup(v);
              // let consultant
              // _.forEach(v.consultant,( i,k)=>{
              //   const consultantname = `consultant_${k + 1}`
              // })
            });
          }
          resolve(data);
        } else {
          reject();
        }
        resolve();
      } catch {
        reject();
      }
    });
  };
  const onGridReady = useCallback((params) => {
    setDMSRFAGridApi(params);
    var datasource = {
      getRows: async (params) => {
        try {
          const response = await runRequest(params);
          params.success({
            rowData: response.rowData,
            rowCount: response.lastRow,
          });
        } catch {
          params.fail();
        }
      },
    };
    params.api.setServerSideDatasource(datasource);
  }, []);
  const onFirstDataRendered = useCallback((params) => {
    params.columnApi.moveColumn('row_index', 0);
    // debugger
    // const allCols = params.columnApi.getAllGridColumns();
    // const columnStates = [];

    // allCols.forEach((col) => {
    //   if (col.colId === 'ag-Grid-AutoColumn') {
    //     let colDef = col.colDef
    //     colDef.lockPosition = "left"
    //     colDef.suppressMovable = true
    //     col.setColDef(colDef, colDef)
    //     // let colDef = col.colDef
    //     // colDef.lockPosition = "left"
    //     columnStates.push({
    //       colId: col.colId,
    //       colDef
    //     });
    //   }
    // });
    // params.columnApi.applyColumnState({ state: columnStates });
  }, []);
  // const getRowId = useMemo(() => {
  //   return (params) => params.data.id
  // }, []);
  const getServerSideGroupKey = useCallback((dataItem) => {
    return dataItem.id;
  }, []);
  const isServerSideGroupOpenByDefault = useCallback((params) => {
    return params.rowNode.level < 2;
  }, []);
  const onSortChanged = useCallback((params) => {
    params.api.refreshCells();
  }, []);
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          rowHeight={30}
          headerHeight={35}
          onGridReady={onGridReady}
          suppressContextMenu={true}
          onFirstDataRendered={onFirstDataRendered}
          // getRowId={getRowId}

          isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
          // isServerSideGroup={isServerSideGroup}
          getServerSideGroupKey={getServerSideGroupKey}
          rowModelType={'serverSide'}
          onSortChanged={onSortChanged}
          rowSelection='multiple'
        />
      </div>
    </>
  );
}
