import moment from 'moment';
import { useEffect, useState } from 'react';
import { logoWithName } from '../../../../constant/LinkImage';
import { AppAPI } from '../../../api';
import IconButton from '../../../gen/button/IconButton.gen';

export default function CardProject({
  id,
  name,
  duration,
  img_path,
  handleViewItem,
  handleEditItem,
  handleDeleteItem,
  handleSettingItem,
}) {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [img, setImg] = useState();
  useEffect(() => {
    debugger;
    run();
  }, [duration, img_path]);

  const run = async () => {
    setEnd(moment(new Date(duration[1])).format('DD/MM/YYYY'));
    setStart(moment(new Date(duration[0])).format('DD/MM/YYYY'));
    if (img_path) {
      const linkData = await AppAPI.S3API.GetPublicLink({ key: img_path });
      setImg(linkData);
    }
  };

  return (
    <>
      <div
        onClick={handleViewItem.bind(this, id, {
          requireActions: ['view.project'],
        })}
        className='idd-card'
      >
        <div className='card-content'>
          <div
            className='cover'
            style={{
              backgroundImage: `url("${img ?? logoWithName}")`,
            }}
          >
            <div style={{ float: 'right' }}></div>
          </div>
        </div>

        <div className='card-name'>
          <div
            style={{
              display: 'flex',
              padding: '0px 5px',
              flexDirection: 'column',
              gap: 5,
            }}
          >
            <span className='main'>{name}</span>
            <span className='sub'>{`${start} - ${end}`}</span>
          </div>
        </div>

        <div
          className='card-action'
          style={{ position: 'absolute', top: 0, right: 0, padding: 5 }}
        >
          <div style={{ display: 'flex', gap: 2 }}>
            <IconButton
              icon={'edit'}
              size='ssmall'
              onClick={handleEditItem.bind(this, id)}
              requireActions={['update.project']}
            />
            <IconButton
              icon={'setting'}
              size='ssmall'
              onClick={handleSettingItem.bind(this, id)}
              requireActions={['update.project']}
            />
            <IconButton
              icon={'delete'}
              size='ssmall'
              type='delete'
              requireActions={['delete.project']}
              onClick={handleDeleteItem.bind(this, id)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
