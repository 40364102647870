import React, { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import { hourRangeFormat, numberWithCommas } from '../../../../util/Gantt.util';
import { timelineStore } from '../../Timeline.store';

const Gantt = window.Gantt;

export default function TimelineSimulationGantt() {
  const { setTimelineSimulationGantt, timelineSimulationGantt } =
    timelineStore();
  const currentTimelineGantt = useRef(timelineSimulationGantt);
  const setCurrentTimelineGantt = (data) => {
    currentTimelineGantt.current = data;
  };
  useEffect(() => {
    setCurrentTimelineGantt(timelineSimulationGantt);
  }, [timelineSimulationGantt]);
  const ref = useCallback((node) => {
    if (!node) {
      currentTimelineGantt.current?.destructor();
      setTimelineSimulationGantt(null);
      return;
    }
    const ganttInstance = Gantt.getGanttInstance();
    ganttInstance.config.grid_resize = true;
    ganttInstance.config.wide_form = false;
    ganttInstance.config.drag_move = false;
    ganttInstance.config.drag_links = false;
    ganttInstance.config.drag_progress = false;

    // ganttInstance.config.auto_types = true;
    ganttInstance.config.date_format = '%Y-%m-%d %H:%i';
    ganttInstance.plugins({
      fullscreen: true,
      drag_timeline: true,
      auto_scheduling: true,
      undo: true,
      tooltip: true,
      marker: true,
      critical_path: true,
      grouping: true,
      multiselect: true,
      export_api: true,
    });

    var zoomConfig = {
      levels: [
        [
          { unit: 'month', format: '%M %Y', step: 1 },
          {
            unit: 'week',
            step: 1,
            format: function (date) {
              var dateToStr = ganttInstance.date.date_to_str('%d %M');
              var endDate = ganttInstance.date.add(date, -6, 'day');
              var weekNum = ganttInstance.date.date_to_str('%W')(date);
              return (
                'Week #' +
                weekNum +
                ', ' +
                dateToStr(date) +
                ' - ' +
                dateToStr(endDate)
              );
            },
          },
        ],
        [
          { unit: 'month', format: '%M %Y', step: 1 },
          { unit: 'day', format: '%d %M', step: 1 },
        ],
        [
          { unit: 'day', format: '%d %M', step: 1 },
          { unit: 'hour', format: hourRangeFormat(12), step: 12 },
        ],
        [
          { unit: 'day', format: '%d %M', step: 1 },
          { unit: 'hour', format: hourRangeFormat(6), step: 6 },
        ],
        [
          { unit: 'day', format: '%d %M', step: 1 },
          { unit: 'hour', format: '%H:%i', step: 1 },
        ],
      ],
      // startDate: new Date(2018, 02, 27),
      // endDate: new Date(2018, 03, 20),
      useKey: 'ctrlKey',
      trigger: 'wheel',
      element: function () {
        return ganttInstance.$root.querySelector('.gantt_task');
      },
    };
    ganttInstance.attachEvent('onBeforeTaskDisplay', function (id, task) {
      if (task.visible) {
        return true;
      }
      return false;
    });
    ganttInstance.config.columns = [
      {
        name: 'text',
        label: 'Task name',
        tree: true,
        width: 200,
        min_width: 200,
        resize: true,
      },
      {
        name: 'start_date',
        label: 'Planned Start',
        width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'end_date',
        label: 'Planned End',
        width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'actual_start',
        label: 'Actual Start',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          return item?.actual_start;
        },
      },
      {
        name: 'actual_end',
        label: 'Actual End',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          return item?.actual_end;
        },
      },
      {
        name: 'attached',
        label: 'Attached',
        // width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'task_type',
        label: 'Task Type',
        width: 100,
        align: 'center',
        resize: true,
      },
      {
        name: 'total_cost',
        label: 'Total Cost',
        width: 100,
        align: 'center',
        resize: true,
        template: function (item) {
          return numberWithCommas(item.total_cost.toFixed(0));
        },
      },
    ];

    ganttInstance.ext.zoom.init(zoomConfig);
    ganttInstance.config.layout = {
      css: 'gantt_container',
      cols: [
        {
          width: 400,
          min_width: 300,
          rows: [
            {
              view: 'grid',
              scrollX: 'gridScroll',
              scrollable: true,
              scrollY: 'scrollVer',
            },
            { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' },
          ],
        },
        { resizer: true, width: 1 },
        {
          rows: [
            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
          ],
        },
        { view: 'scrollbar', id: 'scrollVer' },
      ],
    };
    ganttInstance.attachEvent("onBeforeLightbox", function(id){
      return false;
    });
    ganttInstance.init(node);

    setTimelineSimulationGantt(ganttInstance);
  }, []);
  useEffect(() => {
    if (timelineSimulationGantt) {
      let node = document.getElementById('idd-timeline-simulation-gantt');
      new ResizeObserver(handleResize).observe(node);
    }
  }, [timelineSimulationGantt]);
  const handleResize = () => {
    currentTimelineGantt.current?.render();
  };

  return (
    <>
      <div
        style={{ height: '-webkit-fill-available' }}
        id='idd-timeline-simulation-gantt'
      >
        <div style={{ height: '100%', width: '100%' }} ref={ref}></div>
      </div>
    </>
  );
}
