import { Navbar } from 'flowbite-react';
import React from 'react';
import { Link } from 'react-router-dom';
import AppLogo from 'src/components/atoms/AppLogo';
import BreadcrumbNav from 'src/components/molecules/BreadcrumNav';
import UserNav from 'src/components/molecules/UserNav';

/**
 * @returns {React.Element} The rendered input element.
 */
const TopNav = () => {
  return (
    <Navbar
      fluid
      rounded
      className='border-b border-gray-400 bg-white dark:border-white dark:bg-gray-900'
    >
      <div className='flex content-center items-center gap-2'>
        <Link to='/'>
          <AppLogo />
        </Link>
      </div>

      <UserNav />

      <Navbar.Collapse>
        <BreadcrumbNav />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNav;
