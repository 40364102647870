import React from 'react';
import { ReactComponent as SpinnerIcon } from 'src/assets/icons/loader/spinner.svg';

/**
 * @param {React.Element | null} children
 * @param {boolean} isFull
 * @param {boolean} isLoading
 * @param {Function} onClick
 * @param {string} type
 * @param {string} className
 * @returns {React.Element}
 */
const IconButton = ({
  children,
  isLoading,
  onClick,
  type = 'primary',
  className,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={type === 'disabled' ?? false}
      className={`${className} inline-flex h-fit w-fit items-center bg-primary-600 p-1 text-center text-sm font-medium text-white hover:bg-white hover:text-black focus:outline-none focus:ring-4 focus:ring-primary-300 dark:text-primary-500 dark:hover:bg-primary-500 dark:hover:text-white dark:focus:ring-primary-800`}
    >
      {isLoading ? (
        <>
          <SpinnerIcon />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default IconButton;
