import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { appStore } from '../../../../../store/App.store';
import { viewerStore } from '../../../../../store/Viewer.store';
import { aecModelDataToLevels } from '../../extensions/level/LevelUtils';

// Time in seconds to fade in/out ghosted floors when hovering over the floor selector panel
const GhostFloorFadingTime = 0.5;

// Opacity for ghost floors when fade-in is finished.
const MaxGhostFloorOpacity = 0.2;

// These should actually be infinity and -infinity, but since the values are passed to a shader,
// we have to use large finite values instead. Setting as cutplane elevations actually corresponds to
// switching cutplanes off. But, changes the number of cutplanes triggers expesnive shader recompiles.
const MaxZLimit = 1e20;
const MinZLimit = -MaxZLimit;
const THREE = window.THREE;
const Autodesk = window.Autodesk;
const CutPlaneSetName = 'LevelFilter';
export default function LevelFilterTree({
  loading,
  setLoading,
  setOtherViews,
  otherViews,
  setItemSelected,
}) {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { user, setIsLoading } = appStore();
  const { organizationId, projectId } = useParams();
  const { viewer, listModel } = viewerStore();
  const [modelLevel, setModelLevel] = useState([]);
  const [currentLevel, setCurrentLevel] = useState();
  useEffect(() => {
    run();
  }, [viewer?.impl?.modelQueue()?.getModels()?.length]);
  useEffect(() => {
    return () => {
      _applySelectedFloorSection();
    };
  }, []);
  const run = async () => {
    let temp = [];
    const models = viewer.impl.modelQueue().getModels();
    const placementTf = viewer.impl.model?.getData()?.placementWithOffset; //|| this.floorDataTransform;
    const modelTransform = viewer.impl.model?.getModelTransform();
    let checkModel = true;
    for (let i in models) {
      const aecModelData = models[i].myData.loadOptions?.aecModelData;
      if (aecModelData) {
        let level = aecModelDataToLevels(
          aecModelData,
          placementTf,
          modelTransform
        );
        temp.push({
          model: models[i].id,
          name: models[i].myData.loadOptions?.modelNameOverride,
          fileId: models[i].myData.loadOptions?.fileId,
          level,
          isOpen: true,
        });
      }
      if (currentLevel?.fileId === models[i].myData.loadOptions?.fileId) {
        checkModel = false;
      }
      // const aecModelData = await doc.downloadAecModelData()
    }
    if (checkModel) {
      setCurrentLevel(null);
    }
    setModelLevel(temp);
    const sb = viewer.getExtension('Autodesk.AEC.LevelsExtension');
    // console.log(sb)
    // sb.updateFloorsData()
    // _.forEach(sb.floorSelector._floors, (o) => console.log(o))
  };

  const handleCollapse = (k) => {
    let clone = [...modelLevel];
    clone[k].isOpen = !clone[k].isOpen;
    setModelLevel(clone);
  };
  useEffect(() => {
    _applySelectedFloorSection(currentLevel);
  }, [currentLevel]);
  const _applySelectedFloorSection = (currentLevel) => {
    // If no floor section is active, set cutplanes to maximum range.
    // Doing this instead of clearing them avoids the repeated shader recompile
    const zMin = currentLevel?.zMin;
    const zMax = currentLevel?.zMax;
    _applyFloorSection(zMin, zMax);
  };
  const _applyFloorSection = (zMin, zMax) => {
    viewer.impl.setCutPlaneSet(CutPlaneSetName, null);
    // reset the defined z values to the minimum in case the value is not specified
    if (!Number.isFinite(zMin)) {
      zMin = MinZLimit;
    }
    if (!Number.isFinite(zMax)) {
      zMax = MaxZLimit;
    }
    const planes = [
      new THREE.Vector4(0, 0, -1, zMin),
      new THREE.Vector4(0, 0, 1, -zMax),
    ];
    viewer.impl.setCutPlaneSet(CutPlaneSetName, planes);
  };
  const handleCheckBox = (i) => {
    if (i?.guid === currentLevel?.guid && currentLevel !== null) {
      setCurrentLevel(null);
      return;
    }
    setCurrentLevel(i);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          gap: 5,
          flexDirection: 'column',
        }}
      >
        {modelLevel.map((i, k) => (
          <div className='idd-viewer-card'>
            <div className='header'>
              <div>
                <span>{i?.name}</span>
              </div>
              <div onClick={handleCollapse.bind(this, k)}>
                {i.isOpen ? <CaretDownFilled /> : <CaretUpFilled />}
              </div>
            </div>
            <div className={`body ${i.isOpen ? '' : 'hide'}`}>
              {i.level.map((i) => (
                <div
                  style={{ display: 'flex', gap: 5 }}
                  onClick={handleCheckBox.bind(this, i, k)}
                >
                  <Checkbox checked={currentLevel?.guid === i.guid} />
                  {i.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
