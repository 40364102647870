// src/services/alertService.js
let listeners = [];
let alertState = null;

const AlertPermissionService = {
  subscribe: (listener) => {
    listeners.push(listener);
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getAlert: () => alertState,
  showAlert: (permissionsMissing, duration = 0) => {
    alertState = { permissionsMissing, duration };
    listeners.forEach((listener) => listener(alertState));
    if (duration !== 0) {
      setTimeout(() => {
        AlertPermissionService.hideAlert();
      }, duration);
    }
  },
  hideAlert: () => {
    alertState = null;
    listeners.forEach((listener) => listener(alertState));
  },
  dismissAlert: () => {
    alertState = null;
    listeners.forEach((listener) => listener(alertState));
  },
};

export default AlertPermissionService;
