/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message } from 'antd'
import _ from 'lodash';

import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import AddParameter from '../components/AddParameter';
import { dfmaStore } from '../DFMA.store';
import { ViewerAPI } from '../../../../../../api';
import { useSearchParams } from 'react-router-dom';
import { defaultParameters } from '../DFMA.constant';



export default function SetupParameterModal() {
    const [search] = useSearchParams();
    const fileId = search.get('fileId');
    const {
        setIsOpenSetupParameter,
        setDFMALoading,
        parameters,
        setParameters
    } = dfmaStore();
    const [parameterSetup, setParameterSetup] = useState([])

    useEffect(() => {
        if (parameters.length === 0 || !parameters) {
            let temp = []
            defaultParameters.forEach(v => {
                temp.push({ name: v, value: '' })
            })
            setParameterSetup(temp)
        } else {
            debugger
            let temp = []
            defaultParameters.forEach(v => {
                let index = _.findIndex(parameters, i => i.name === v)
                if (index >= 0)
                    temp.push({ name: v, value: parameters[index].value })
                else
                    temp.push({ name: v, value: '' })
            })
            // parameters.forEach(v => {
            //     temp.push({ name: v.name, value: v.value })
            // })
            setParameterSetup(temp)
        }
        return () => {
            setParameterSetup([])
        }

    }, []);


    const close = () => {
        setIsOpenSetupParameter();
    };

    const handleOk = async (e) => {
        if (!window.confirm('Are you sure to submit?')) return;
        setDFMALoading(true);
        try {
            await ViewerAPI.DFMAAPI.CreateSetup({ parameters: parameterSetup, fileId: fileId })
            setParameters(parameterSetup)
            close();
        } catch (ex) {
            message.warning(ex.message);
        }
        setDFMALoading(false);
    };

    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Setup'}
                styleBackDrop={{ position: 'absolute' }}
                style={{ top: 'auto', width: '90%' }}
                isFooter={true}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: 10,
                        // height: 'calc(100vh - 450px)',
                        overflow: 'auto',
                        width: '100%',
                    }}
                >
                    <AddParameter parameterSetup={parameterSetup} setParameterSetup={setParameterSetup} />
                </div>
            </ModalTemplate>
        </>
    );
}
