import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import SizeCell from "../../../../../ag_grid_components/cell/Size.cell";
import DateCell from "../../../../../ag_grid_components/cell/Date.cell";
import { AgGridReact } from 'ag-grid-react';

import FileBrowserCell from "../../../../../ag_grid_components/cell/FileBrowser.cell";
import { AppAPI } from "../../../../../api";
// import ActionButtonCell from "../cell/ActionButton.cell";
import { dmsStore } from "../../../../../../store/DMS.store";
import TagCell from "../../../../../ag_grid_components/cell/Tag.cell";

export default function DMSDocTable() {
  const { setDMSRFADocGridApi } = dmsStore()
  const { projectId } = useParams()
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Files",
      field: "name",
      // minWidth: 330,
      cellRendererParams: {
        // checkbox: true,
        suppressCount: true,
        innerRenderer: FileBrowserCell,
        // innerRendererParams: { setIsLoading, pathDir, role, isAdmin, isWHUser },
      },
      colSpan: params => params.data.is_folder ? 3 : 1,
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: "agSetColumnFilter",
    };
  }, []);

  const columnDefs = [
    {
      headerName: "",
      valueGetter: "node.rowIndex + 1",
      pinned: 'left',
      lockPosition: "left",
      minWidth: 50,
      maxWidth: 50,
      suppressFilter: true,
      suppressMovable: true,
      suppressToolPanel: true,
      suppressSorting: true,
      suppressMenu: true,
    },
    {
      field: "size",
      headerName: "Size",
      width: 100,
      maxWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        const isFolder = params.data.isFolder;
        if (!isGroup && !isFolder) {
          return {
            component: SizeCell,
          };
        }
        return undefined;
      },
    },
    {
      field: "current_version",
      headerName: "Version",
      minWidth: 100,
      maxWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        const isFolder = params.data.is_folder;
        if (!isGroup && !isFolder) {
          return {
            component: TagCell,
            params: {
              tagStyle: { backgroundColor: '#2f465e', color: 'white' }
            },
          };
        }
        return undefined;
      },
      cellStyle: { textAlign: "center" },
    },

    {
      field: "updated_at",
      headerName: "Date",
      width: 95,
      maxWidth: 95,
      cellRendererSelector: (params) => {
        return {
          component: DateCell,
        };
      },
    },

    {
      field: "updated_by",
      headerName: "By",
      width: 100,
      maxWidth: 180,
    }
  ];


  const runRequest = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await AppAPI.DMSDocAPI.GetChidById({
          parentId: id,
        });
        if (data) resolve(data);
        else {
          reject();
        }
      } catch {
        reject();
      }
    });
  };
  const onGridReady = useCallback((params) => {
    setDMSRFADocGridApi(params)
    var datasource = {
      getRows: async (params) => {
        // console.log('ServerSideDatasource.getRows: params = ', params);
        const request = params.request;
        const groupKeys =
          params.parentNode.level === -1
            ? [projectId]
            : params.request.groupKeys;
        try {
          if (groupKeys.length !== 0) {
            let _id = groupKeys[groupKeys.length - 1];
            const allRows = await runRequest(_id);
            const doingInfinite =
              request.startRow != null && request.endRow != null;
            const result = doingInfinite
              ? {
                rowData: allRows.slice(request.startRow, request.endRow),
                rowCount: allRows.length,
              }
              : { rowData: allRows };
            params.success(result);
          }
        } catch {
          params.fail();
        }
      },
    };
    params.api.setServerSideDatasource(datasource);
  }, []);

  const getDataPath = useMemo(() => {
    return (data) => data.path;
  }, []);
  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);
  const isServerSideGroup = useCallback((dataItem) => {
    return dataItem.is_folder;
  }, []);
  const getServerSideGroupKey = useCallback((dataItem) => {
    return dataItem.id;
  }, []);
  const isServerSideGroupOpenByDefault = useCallback((params) => {
    return params.rowNode.level < 2;
  }, []);
  const onSortChanged = useCallback((params) => {
    params.api.refreshCells()
  }, []);
  const isRowSelectable = useCallback((params) => {
    return !params.data.is_folder;
  }, []);
  return (
    <div style={{ display: 'flex', gap: 15, flexDirection: 'column', width: '100%', height: 500 }} >
      <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          treeData={true}
          rowHeight={30}
          headerHeight={35}
          onGridReady={onGridReady}
          suppressContextMenu={true}
          getDataPath={getDataPath}
          getRowId={getRowId}
          masterDetail={true}
          isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
          isServerSideGroup={isServerSideGroup}
          getServerSideGroupKey={getServerSideGroupKey}
          rowModelType={"serverSide"}
          onSortChanged={onSortChanged}
          rowSelection='single'
          isRowSelectable={isRowSelectable}
        />

      </div>


    </div>

  );
}


