import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { appStore } from "../../../../../store/App.store";
import { requestBimGrid } from "../../../../../functions/General.function";
import _ from 'lodash'
import CardFolder from "./card/CardFolder";
import { message } from 'antd'
import CardItem from "./card/Carditem";
export default function Project3DSectionMobile() {
    const navigate = useNavigate()
    const [search, setSearchParams] = useSearchParams();
    const folderId = search.get('folderId')
    const type = search.get('type') ? search.get('type') : '3d'
    const { setIsLoading } = appStore()
    const [folders, setFolders] = useState([])
    const [items, setItems] = useState([])
    const { organizationId, projectId } = useParams()
    useEffect(() => {
        run()
    }, [folderId])
    const run = async () => {
        setIsLoading(true)
        try {
            let data
            if (!folderId || folderId === 'undefined') {
                data = await requestBimGrid('get', '/api/folder/getByProjectType', null,
                    { organizationId, projectId, type })
            } else {
                data = await requestBimGrid('get', '/api/folder/getFolderChildAndFile', null, {
                    organizationId, projectId, id: folderId
                })
            }
            let folders = []
            let items = []
            setFolders([...folders])
            setItems([...items])
            setTimeout(() => {
                if (data) {
                    _.forEach(data, v => {
                        if (!v.fileName) {
                            folders.push(v)
                        } else {
                            items.push(v)
                        }
                    })
                    console.log(folders)
                }
                setFolders([...folders])
                setItems([...items])
                setIsLoading(false)
            }, 500);
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }

    }

    return (
        <div style={{
            display: 'flex', gap: 5, height: '100%', widthL: '100%',
            flexDirection: 'column', padding: 5
        }} >

            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div>
                    <span style={{ fontSize: 16, fontWeight: 'bold', color: 'gray' }}>Folders</span>
                </div>
                <div className="idd-list" style={{ gap: 10 }}>
                    {folders.map((v, idx) =>
                        <CardFolder key={idx} id={v.key} {...v} />

                    )}
                </div>

            </div>

            <div>
                <div>
                    <span style={{ fontSize: 16, fontWeight: 'bold', color: 'gray' }}>Docuemnts</span>
                </div>

                <div className="idd-list" style={{ gap: 10 }}>
                    {items.map((v, idx) =>
                        <CardItem key={idx} {...v} />

                    )}
                </div>

            </div>
        </div>

    );
}


