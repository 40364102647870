import { Upload, Button, DatePicker, Tree } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { viewerStore } from '../../../../../../store/Viewer.store';
import TreeQRElement from './components/node/TreeQRElement';

export default function QRElementTree() {
  const [loading, setLoading] = useState();
  const { treeModelData } = viewerStore();
  const [levelTree, setLevelTree] = useState([]);
  // useEffect(() => {
  //   treeModelData.addEventListener('done', run)
  //   treeModelData.addEventListener('add', run)
  //   treeModelData.addEventListener('remove', run)
  //   return () =>{
  //       treeModelData.removeEventListener('done', run)
  //       treeModelData.removeEventListener('add', run)
  //       treeModelData.removeEventListener('remove', run)
  //   }
  // }, [])
  useEffect(() => {
    run();
  }, []);
  const run = () => {
    let temp = [];
    _.forEach(treeModelData.data, (v) => {
      temp.push({
        title: v.name,
        key: v.id,
        children: v.children,
      });
    });
    console.log(treeModelData);
    setLevelTree(temp);
  };
  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  const onLoadData = ({ key, children }) =>
    new Promise((resolve) => {
      debugger;
      if (children) {
        let temp = [];
        _.forEach(children, (v) => {
          temp.push({
            title: <TreeQRElement item={v} isLeaf={v.children.length ===0} />,
            key: v.externalId,
            children: v.children,
            isLeaf:v.children.length ===0,
            dbId:v.dbId,
            modelId:v.modelId
          });
        });
        setLevelTree((origin) => updateTreeData(origin, key, temp));
        resolve();
        return;
      }
      resolve();
    });
  return (
    <div style={{ overflow: 'auto', height: 'calc(100vh - 260px)' }}>
      <Tree blockNode loadData={onLoadData} treeData={levelTree} />
    </div>
  );
}
