import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import FullSceenLoader from 'src/components/molecules/FullSceenLoader';
import {
  ForgotPasswordPage,
  LoginPage,
  ResetPasswordPage,
  SignUpPage,
} from 'src/components/pages/auth';
import {
  ConfirmNotificationPage,
  ErrorPage,
  NotFoundPage,
} from 'src/components/pages/general';
import { UserSettingPage } from 'src/components/pages/user';
import { OrganizationRoute } from 'src/routes/admin';
import { AuthRoute, ConfirmRoute, PermissionRoute } from 'src/routes/auth';
import { ProjectRoute, RootRoute } from 'src/routes/main';
import { appStore } from 'src/store/App.store';
import { AdminAppRoutes, SuperAdminAppRoutes } from './apps/Admin.app';
import { DigiCheckAppRoutes } from './apps/Digicheck.app';
import { DMSAppRoutes, DMSShareAppRoutes } from './apps/DMS.app';
import { FiveDAppRoutes } from './apps/FiveDCMS.app';
import { ThreeDICSAppRoutes, ThreeDShareAppRoutes } from './apps/ThreeDICS.app';
import { VDCAppRoutes } from './apps/VDC.app';
import { WorkerAppRoutes } from './apps/Worker.app';
import ProjectAppPage from './components/pages/project/ProjectApp.Page';
import ProjectManagementPage from './components/pages/project/ProjectManagement.Page';

import UserInvitationPage from 'src/components/pages/auth/UserInvitationPage';
import NoUserPlanPage from 'src/components/pages/general/NoUserPlanPage';
import { ProjectListPage } from 'src/components/pages/main';

// document.documentElement.classList.add('dark');
function App() {
  const { isLoading, setWidthApp } = appStore();
  useEffect(() => {
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, []);
  const windowResize = () => {
    setWidthApp(window.innerWidth);
  };
  return (
    <>
      {isLoading && <FullSceenLoader />}
      <Routes>
        <Route errorElement={<ErrorPage />}>
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
          <Route element={<RootRoute />}>
            <Route path='/' element={<LoginPage />} />
            <Route path='/sign-up' element={<SignUpPage />} />
            <Route path='/reset-password' element={<ResetPasswordPage />} />
            <Route path='/user-invitation' element={<UserInvitationPage />} />
          </Route>

          {/* <Route path='/' element={<HomePage />} /> */}

          <Route element={<ConfirmRoute />} errorElement={<ErrorPage />}>
            <Route
              path='/confirm-notification'
              element={<ConfirmNotificationPage />}
            />
            <Route element={<AuthRoute />}>
              <Route path='/no-plan' element={<NoUserPlanPage />} />
              <Route path='user-setting' element={<UserSettingPage />} />
              {AdminAppRoutes}
              <Route path='organization' element={<OrganizationRoute />}>
                <Route element={<PermissionRoute />}>
                  <Route path=':organizationId' element={<ProjectListPage />} />
                  <Route
                    path=':organizationId/project'
                    element={<ProjectRoute requireActions={['view.project']} />}
                  >
                    <Route path=':projectId' />
                    <Route
                      path=':projectId/apps'
                      element={<ProjectAppPage />}
                    />
                    {ThreeDICSAppRoutes}
                    {DMSAppRoutes}
                    {FiveDAppRoutes}
                    {WorkerAppRoutes}
                    {VDCAppRoutes}
                    {DigiCheckAppRoutes}
                    <Route
                      path=':projectId/management'
                      element={
                        <ProjectManagementPage
                          requireActions={['update.project']}
                        />
                      }
                    />
                  </Route>
                </Route>
              </Route>
              {SuperAdminAppRoutes}
            </Route>
          </Route>

          <Route path='share'>
            {ThreeDShareAppRoutes}
            {DMSShareAppRoutes}
          </Route>
        </Route>

        <Route path='*' exact element={<NotFoundPage />} />
      </Routes>
      {/* <ScrollRestoration /> */}
    </>
  );
}

export default App;
