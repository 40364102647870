import React from "react";
import IconButton from "../../../../../../gen/button/IconButton.gen"
import { appStore } from "../../../../../../../store/App.store";

import { message } from 'antd'
import { generateS3Path } from "../../../../../../../functions/General.function";
import { useParams } from "react-router-dom";
import { S3_BASED_PATH } from "../../../../../../../config";
import { AppAPI } from "../../../../../../api/index";
const ActionButtonCell = (params) => {
    const { setIsLoading } = appStore()
    const { organizationId, projectId } = useParams()


    const handleDownloadItem = async () => {
        if (!window.confirm('Are you sure to download?')) return
        if (params.node.data.is_folder) {
            setIsLoading(true)
            try {
                const link = await AppAPI.DMSDocAPI.DownloadFolder({ path: params.node.data.path })
                debugger
                window.open(link, "_blank")
            } catch (ex) {
                message.warning(ex.message)
            }
            setIsLoading(false)
        } else {
            setIsLoading(true)
            try {
                const key = generateS3Path(organizationId, projectId,
                    S3_BASED_PATH.DMS_DOC, `${params.node.data.path}/${params.node.data.id}/${params.node.data.current_version}/${params.node.data.name}`)
                const link = await AppAPI.S3API.GetPublicLink({ key })
                window.open(link, "_blank")
            } catch (ex) {
                message.warning(ex.message)
            }
            setIsLoading(false)
        }
    }
    // const handleViewItem = async () => {
    //     setIsLoading(true)
    //     try {
    //         const key = generateS3Path(organizationId, projectId,
    //             S3_BASED_PATH.DMS_DOC, `${params.node.data.path}/${params.node.data.id}/${params.node.data.current_version}/${params.node.data.name}`)
    //         const link = await AppAPI.S3API.GetPublicLink({ key })
    //         window.open(link, "_blank")
    //     } catch (ex) {
    //         message.warning(ex.message)
    //     }
    //     setIsLoading(false)
    // }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center', height: '100%' }}>
            {/* {!params.node.data.is_folder &&
                <IconButton
                    icon={'view'}
                    size='sssmall'
                    iconStyle={{ background: '#000000' }}
                    onClick={handleViewItem.bind(this, params)}
                />
            } */}
            <IconButton
                icon={'download'}
                size='sssmall'
                // type={'save'}
                iconStyle={{ background: '#000000' }}
                onClick={handleDownloadItem.bind(this, params)}
            />


        </div>
    )
}

export default ActionButtonCell