import React from 'react';

/**
 * @param {React.Element} children
 * @returns {React.Element} The rendered input element.
 */
const BackDrop = ({ children }) => {
  return (
    <div className='fixed top-0 flex size-full items-center justify-center overflow-auto bg-primary-200/30 backdrop-blur-sm'>
      {children}
    </div>
  );
};

export default BackDrop;
