import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { requestBimGrid } from '../../../../../functions/General.function';
import {
  clearColorAllModel,
  getAllChildrenIds,
  getElementProperty,
} from '../../../../../functions/Viewer.function';

import { viewerStore } from '../../../../../store/Viewer.store';
import LoadModel from '../../util/LoadModel.util';
import { clashDetectionStore } from './ClashDetection.store';
import ClashDetectionDetailPopup from './popup/ClashDectection.detail.popup';
import ClashDetectionTable from './table/ClashDetectionResult.table';

export default function ClashDetectionDetail() {
  const [search] = useSearchParams();
  const {
    setClashDetectionDetail,
    clashDetectionPoint,
    clashDetectionSelected,
    clashDetectionDetail,
    clashDetectionClashTestSelected,
    setClashDetectionClashTestSelected,
    setClashDetectLoading,
  } = clashDetectionStore();
  const { viewer } = viewerStore();
  const fileId = search.get('fileId');

  const { organizationId, projectId } = useParams();
  const [gridApi, setGridApi] = useState();
  useEffect(() => {
    runCheckModel();
    return () => {
      clearColorAllModel(viewer);
      clashDetectionPoint.setMarkupData([]);
      setClashDetectionDetail();
    };
  }, []);
  const handleBack = () => {
    setClashDetectionClashTestSelected();
  };
  const runCheckModel = async () => {
    try {
      setClashDetectLoading(true);
      let models = viewer.impl.modelQueue().getModels();
      let sources = clashDetectionClashTestSelected.selection_a.concat(
        clashDetectionClashTestSelected.selection_b
      );
      sources = _.uniq(sources);
      let missing = []; //['1638409824940-test.nwd']
      _.forEach(sources, (v) => {
        let index = _.findIndex(models, (i) => {
          return v === i.myData.loadOptions.modelNameOverride;
        });
        if (index < 0) {
          missing.push(v);
        }
      });
      let files = [];
      if (missing.length !== 0) {
        let resModels = await requestBimGrid(
          'get',
          '/api/clashDetection/getBySources',
          null,
          {
            organizationId,
            projectId,
            sources: missing,
          }
        );
        if (resModels) {
          files = resModels;
        }
      }
      const loadModel = new LoadModel(viewer);

      loadModel.addEventListener(
        'finish',
        async () => {
          const json = await runLoadTestResult();
          await runMapping(json);
          runLoadMarkup(json);
          setClashDetectionDetail(json);
          // console.log(json)
          setClashDetectLoading(false);
          loadModel.unload();
        },
        false
      );
      loadModel.load(files);
    } catch (ex) {
      message.warning(ex.message);
      setClashDetectLoading(false);
    }
  };
  const runLoadTestResult = () => {
    return new Promise(async (resolve) => {
      const key = `clashDetection/${organizationId}/${projectId}/${clashDetectionClashTestSelected.clashDetectionId}/${clashDetectionClashTestSelected.id}.json`;
      let url = await requestBimGrid('get', '/api/s3/get-link', null, {
        organizationId,
        projectId,
        key,
      });
      const response = await fetch(url);
      const json = await response.json();
      resolve(json);
    });
  };
  const runLoadMarkup = (json) => {
    let offset = viewer.model.getData().globalOffset;
    let clone = [...json];
    _.forEach(clone, (v, k) => {
      v.colorName = v.status.toLowerCase();
      v.position.x = +v.position.x - offset.x;
      v.position.y = +v.position.y - offset.y;
      v.position.z = +v.position.z - offset.z;
    });
    clashDetectionPoint.setMarkupData(clone);
    clashDetectionPoint.highLightClash();
  };
  const runMapping = async (json) => {
    return new Promise(async (resolve) => {
      debugger;
      let models = viewer.impl.modelQueue().getModels();
      let data = {};
      for (let index in models) {
        let model = models[index];
        let split = model.myData.loadOptions.modelNameOverride.split('.');
        let extension = split[split.length - 1];
        let instanceTree = model.getData().instanceTree;
        let temp = getAllChildrenIds(instanceTree, viewer, model, 'all');
        let properties = await getElementProperty(model, temp);
        if (extension?.toLowerCase() === 'rvt') {
          _.forEach(properties, (v) => {
            if (
              v.externalId === '2632a2eb-c20e-40b7-8fc4-4ed71ba5199d-000a452a'
            ) {
              debugger;
            }
            data[v.externalId] = { id: v.dbId, model: model };
          });
        } else {
          _.forEach(properties, (v) => {
            let idx = _.findIndex(v.properties, (i) => {
              return i.displayName === 'GUID';
            });
            if (idx >= 0) {
              data[v.properties[idx].displayValue] = {
                id: v.dbId,
                model: model,
              };
            }
          });
        }
      }
      _.forEach(json, (v) => {
        if (data[v.elA]) {
          v.objA = data[v.elA];
        }
        if (data[v.elB]) {
          v.objB = data[v.elB];
        }
      });
      resolve();
    });
  };
  const handLeUpdate = async () => {
    if (!window.confirm('Are you sure to update?')) return;
    try {
      let offset = viewer.model.getData().globalOffset;
      setClashDetectLoading(true);
      let clashTestData = {
        file_id: fileId,
        name: clashDetectionClashTestSelected.name,
      };
      let clashTestVersionData = {
        clashes: clashDetectionClashTestSelected.clashes,
        new: 0,
        active: 0,
        reviewed: 0,
        approved: 0,
        resolved: 0,
        selection_a: clashDetectionClashTestSelected.selection_a,
        selection_b: clashDetectionClashTestSelected.selection_b,
      };
      let json = [];
      gridApi.api.forEachLeafNode((rowNode) => {
        if (rowNode.data.status === 'New') {
          clashTestVersionData.new++;
        } else if (rowNode.data.status === 'Active') {
          clashTestVersionData.active++;
        } else if (rowNode.data.status === 'Reviewed') {
          clashTestVersionData.reviewed++;
        } else if (rowNode.data.status === 'Approved') {
          clashTestVersionData.approved++;
        } else if (rowNode.data.status === 'Resolved') {
          clashTestVersionData.resolved++;
        } else if (rowNode.data.status === 'Resolved') {
          clashTestVersionData.resolved++;
        }
        // clashTestVersionData.clashes.push({
        //   distance: rowNode.data['distance'],
        //   elA: rowNode.data['elA'],
        //   elB: rowNode.data['elB'],
        //   found: rowNode.data['found'],
        //   grid: rowNode.data['grid'],
        //   level: rowNode.data['level'],
        //   name: rowNode.data['name'],
        //   status: rowNode.data['status'],
        //   position: {
        //     x: rowNode.data['position'].x + offset.x,
        //     y: rowNode.data['position'].y + offset.y,
        //     z: rowNode.data['position'].z + offset.z,
        //   }
        // })
        json.push({
          name: rowNode.data.name,
          status: rowNode.data.status,
          level: rowNode.data.level,
          grid: rowNode.data.grid,
          distance: rowNode.data.distance,
          found: rowNode.data.found,
          elA: rowNode.data.elA,
          elB: rowNode.data.elB,
          position: {
            x: rowNode.data['position'].x + offset.x,
            y: rowNode.data['position'].y + offset.y,
            z: rowNode.data['position'].z + offset.z,
          },
        });
      });
      const blob = new Blob([JSON.stringify(json)], {
        type: 'application/json',
      });
      const fileJson = new File([blob], 'data.json', {
        type: 'application/json',
      });
      const formData = new FormData();
      console.log(URL.createObjectURL(fileJson));
      formData.append('file', fileJson);
      formData.append('clashTestData', JSON.stringify(clashTestData));
      formData.append(
        'clashTestVersionData',
        JSON.stringify(clashTestVersionData)
      );
      let resClash = await requestBimGrid(
        'post',
        '/api/clashDetection',
        formData,
        {
          organizationId,
          projectId,
        },
        true
      );
      if (resClash) {
        // let node = await getAgGridRowNode(gridApi.api, 'clashDetectionId', resClash)
        // if(!node){
        //   await updateTableAsync(gridApi.api, { add: [resClash] })
        //   gridApi.api.redrawRows()
        // }else{
        //   // let clone ={...node.data, resClash}
        //   node.data.name=resClash.name
        //   node.data.version=resClash.version
        //   node.data.new=resClash.new
        //   node.data.active=resClash.active
        //   node.data.reviewed=resClash.reviewed
        //   node.data.approved=resClash.approved
        //   node.data.resolved=resClash.resolved
        //   await updateTableAsync(gridApi.api, { update: [node.data] })
        //   gridApi.api.redrawRows()
        // }
        setClashDetectionClashTestSelected(resClash);
      }
      setClashDetectLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setClashDetectLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          padding: 5,
          display: 'flex',
          gap: 5,
          alignItems: 'center',
          height: 35,
        }}
      >
        <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={handleBack} />
        <span style={{ fontWeight: 'bold' }}> Clash Result</span>
      </div>
      {clashDetectionDetail && (
        <>
          <div style={{ height: 'calc(100% - 75px)', width: '100%' }}>
            <ClashDetectionTable setGridApi={setGridApi} />
          </div>

          <div
            style={{
              padding: 5,
              display: 'flex',
              gap: 5,
              alignItems: 'center',
            }}
          >
            <Button
              className={`idd-custom-button edit block`}
              onClick={handLeUpdate}
            >
              Update Clashes
            </Button>
          </div>
        </>
      )}

      {clashDetectionSelected && <ClashDetectionDetailPopup />}
    </>
  );
}
