export default function PlanCell(props) {
  const cellValue = props.valueFormatted
    ? props.valueFormatted
    : (props.value ?? 'No Plan');

  return (
    <div className='size-fit border border-primary-600 bg-gray-200 px-2 pb-0.5 pt-0.5 leading-normal'>
      {cellValue}
    </div>
  );
}
