import create from 'zustand';
import { IssueComponentType } from './constants/IssueComponentType';

const initialState = {
  type: IssueComponentType.List,
  issueLoading: false,

  issueData: [],
  issueDetail: null,
  layoutType: 'table',
};
export const issueStore = create((set) => ({
  ...initialState,
  setType: (value) => set((state) => ({ type: value })),
  setIssueLoading: (value) => set((state) => ({ issueLoading: value })),

  setIssueData: (value) => set((state) => ({ issueData: value })),
  setIssueDetail: (value) => set((state) => ({ issueDetail: value })),

  setLayoutType: (value) => set((state) => ({ layoutType: value })),
  resetIssueStore: () => set(initialState),
}));
