import React, { useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import ButtonGen from './common/Button.gen';
import icons from '../constant/Icon.constant';

export default function TextButton({
  url,
  size = '',
  icon,
  children,
  ...props
}) {
  return (
    <>
      <ButtonGen size={size} {...props} isIconButton={false}>
        <div style={{ display: 'flex', gap: 2, alignContent:'center', justifyContent:'center', alignItems:'center' }}>
  
          <span className={`text ${size}`}> {children}</span>
        </div>
      </ButtonGen>
    </>
  );
}
