

export const DMS_COLOR = {
    notstart: { color: 'black', bg: '#dfe4ea' },
    approve: { color: 'white', bg: '#006400' },
    reject: { color: 'white', bg: '#b33939' }
}

export const DMS_STATUS = [
    { id: 'approve', name: 'Approve' },
    { id: 'reject', name: 'Reject' }
]
