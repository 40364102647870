import React from "react";
import QRShareDrawingPageLayout from "../../../../layout/share/apps/three_d/qr_element/QRShareDrawing.layout";


function QRShareDrawingPage(props) {

  return (
    <>
      <QRShareDrawingPageLayout />

    </>

  );
}

export default (QRShareDrawingPage);
