import create from 'zustand';

const initialState = {
  versionData: null,
  viewer: null,
  aecModelData: null,
  is3D: true,
  viewerLoading: true,
  treeModelData: null,

  feature: [],
  isOpenTopNav: true,
  docker: null,

  viewData: [],
  viewDetail: null,
  animationsData: [],
  animationDetail: null,
  animationStepsData: [],
  animationStepDetail: null,

  clashTestDetail: null,

  listModel: [],
};
export const viewerStore = create((set) => ({
  ...initialState,
  setVersionData: (value) => set((state) => ({ versionData: value })),
  setViewer: (value) => set((state) => ({ viewer: value })),
  setIs3D: (value) => set((state) => ({ is3D: value })),
  setViewerLoading: (value) => set((state) => ({ viewerLoading: value })),
  setAecModelData: (value) => set((state) => ({ aecModelData: value })),
  setTreeModalData: (value) => set((state) => ({ treeModelData: value })),

  setFeature: (value) => set((state) => ({ feature: value })),
  setIsOpenTopNav: (value) => set((state) => ({ isOpenTopNav: value })),
  setDocker: (value) => set((state) => ({ docker: value })),

  setViewData: (value) => set((state) => ({ viewData: value })),
  setViewDetail: (value) => set((state) => ({ viewDetail: value })),
  setAnimationsData: (value) => set((state) => ({ animationsData: value })),
  setAnimationDetail: (value) => set((state) => ({ animationDetail: value })),
  setAnimationStepsData: (value) =>
    set((state) => ({ animationStepsData: value })),
  setAnimationStepDetail: (value) =>
    set((state) => ({ animationStepDetail: value })),

  setClashTestDetail: (value) => set((state) => ({ clashTestDetail: value })),

  setListModel: (value) => set((state) => ({ listModel: value })),
  resetViewerStore: () => set(initialState),
}));
