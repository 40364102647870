import React, { useState } from "react";
import _ from "lodash";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import COBieTable from "../table/COBie.table";
import ParameterSelector from "../../ExtractQuantity/ParameterSelector";

export default function COBieGeneral() {
    const [isOpen, setIsOpen] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    return (
        <>
            <div style={{ display: isOpen ? 'block' : 'none' }} >
                <ParameterSelector setIsOpen={setIsOpen} setDataTable={setDataTable} setColumnData={setColumnData} />
            </div>
            <div>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', backgroundColor: 'black' }} onClick={setIsOpen.bind(this, !isOpen)} >
                    {isOpen ? <CaretUpFilled style={{ color: 'white' }} /> : <CaretDownFilled style={{ color: 'white' }} />}
                </div>
            </div>
            <div style={{ height: '100%' }}>
                <COBieTable
                    columnData={columnData}
                    dataTable={dataTable}
                />

            </div>
        </>
    );
}
