import React, { useEffect } from 'react';
import _ from 'lodash';
import { dfmaStore } from './DFMA.store';
import DFMALayout from './DFMA.layout';
import Loader from '../../../../../loader/LoaderApp';

export default function DFMAFeature() {
  const { dfmaLoading,resetDFMAStore } = dfmaStore();
  useEffect(() => {
    return()=>{
      resetDFMAStore()
    }
  }, [])
  return (
    <>
      {dfmaLoading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 11111111,
          }}
        >
          <Loader />
        </div>
      )}
      <>
        <DFMALayout />
      </>
    </>
  );
}
