import React from 'react';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';

/**
 * @param {React.Element} children
 * @returns {React.Element} The rendered input element.
 */
const UserAccountInformation = () => {
  return (
    <>
      <p className='py-2 text-xl font-semibold'>Information</p>
      <div className='flex flex-col sm:flex-row sm:items-center'>
        <LabelAndInputForm
          type='name'
          name='name'
          label={'User Name'}
          placeholder='John'
          isRequired
        />
      </div>
      <div className='pt-5'>
        <PrimaryButton>Update Information</PrimaryButton>
      </div>
    </>
  );
};

export default UserAccountInformation;
