import { Checkbox, Popover, Radio, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { timelineStore } from '../../Timeline.store';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import ButtonGen from '../../../../../../gen/button/common/Button.gen';
import { viewerStore } from '../../../../../../../store/Viewer.store';
import { CascadeAction, DisplayTable, SingularAction } from '../../constants/TimelineComponentType';


const displayName = ['Basic', 'Standard', 'Extend'];
export default function TimelineTasksToolbar() {
  const { viewer } = viewerStore();
  const { timelineGantt ,setIsOpenDefineRule} = timelineStore();
  const [displayNumber, setDisplayNumber] = useState(0);
  const handleRedo = () => {
    timelineGantt.ext.undo.undo();
  };
  const handleUndo = () => {
    timelineGantt.ext.undo.undo();
  };
  var actions = {
    undo: function () {
      timelineGantt.ext.undo.undo();
    },
    redo: function () {
      timelineGantt.ext.undo.redo();
    },
    indent: function indent(task_id) {
      var prev_id = timelineGantt.getPrevSibling(task_id);
      while (timelineGantt.isSelectedTask(prev_id)) {
        var prev = timelineGantt.getPrevSibling(prev_id);
        if (!prev) break;
        prev_id = prev;
      }
      if (prev_id) {
        var new_parent = timelineGantt.getTask(prev_id);
        timelineGantt.moveTask(
          task_id,
          timelineGantt.getChildren(new_parent.id).length,
          new_parent.id
        );
        new_parent.type = timelineGantt.config.types.project;
        new_parent.$open = true;
        timelineGantt.updateTask(task_id);
        timelineGantt.updateTask(new_parent.id);
        return task_id;
      }
      return null;
    },
    outdent: function outdent(task_id, initialIndexes, initialSiblings) {
      var cur_task = timelineGantt.getTask(task_id);
      var old_parent = cur_task.parent;
      if (
        timelineGantt.isTaskExists(old_parent) &&
        old_parent != timelineGantt.config.root_id
      ) {
        var index = timelineGantt.getTaskIndex(old_parent) + 1;
        var prevSibling = initialSiblings[task_id].first;

        if (timelineGantt.isSelectedTask(prevSibling)) {
          index += initialIndexes[task_id] - initialIndexes[prevSibling];
        }
        timelineGantt.moveTask(
          task_id,
          index,
          timelineGantt.getParent(cur_task.parent)
        );
        if (!timelineGantt.hasChild(old_parent))
          timelineGantt.getTask(old_parent).type =
            timelineGantt.config.types.task;
        timelineGantt.updateTask(task_id);
        timelineGantt.updateTask(old_parent);
        return task_id;
      }
      return null;
    },
    del: function (task_id) {
      if (timelineGantt.isTaskExists(task_id))
        timelineGantt.deleteTask(task_id);
      return task_id;
    },
    moveForward: function (task_id) {
      shiftTask(task_id, 1);
    },
    moveBackward: function (task_id) {
      shiftTask(task_id, -1);
    },
  };
  const shiftTask = (task_id, direction) => {
    var task = timelineGantt.getTask(task_id);
    task.start_date = timelineGantt.date.add(task.start_date, direction, 'day');
    task.end_date = timelineGantt.calculateEndDate(
      task.start_date,
      task.duration
    );
    timelineGantt.updateTask(task.id);
  };
  useEffect(() => {
    if (timelineGantt) {
      timelineGantt.performAction = function (actionName) {
        var action = actions[actionName];
        if (!action) return;

        if (SingularAction[actionName]) {
          action();
          return;
        }

        timelineGantt.batchUpdate(function () {
          // need to preserve order of items on indent/outdent,
          // remember order before changing anything:
          var indexes = {};
          var siblings = {};
          timelineGantt.eachSelectedTask(function (task_id) {
            timelineGantt.ext.undo.saveState(task_id, 'task');
            indexes[task_id] = timelineGantt.getTaskIndex(task_id);
            siblings[task_id] = {
              first: null,
            };

            var currentId = task_id;
            while (
              timelineGantt.isTaskExists(
                timelineGantt.getPrevSibling(currentId)
              ) &&
              timelineGantt.isSelectedTask(
                timelineGantt.getPrevSibling(currentId)
              )
            ) {
              currentId = timelineGantt.getPrevSibling(currentId);
            }
            siblings[task_id].first = currentId;
          });

          var updated = {};
          timelineGantt.eachSelectedTask(function (task_id) {
            if (CascadeAction[actionName]) {
              if (!updated[timelineGantt.getParent(task_id)]) {
                var updated_id = action(task_id, indexes, siblings);

                updated[updated_id] = true;
              } else {
                updated[task_id] = true;
              }
            } else {
              action(task_id, indexes);
            }
          });
        });
      };
    }
  }, [timelineGantt]);
  const handleGantt = (name) => {
    timelineGantt.performAction(name);
  };
  useEffect(() => {
    if (timelineGantt) {
      let count = displayNumber;
      var columns = timelineGantt.config.columns;
      let columnName = [];
      for (let index = 0; index <= count; index++) {
        columnName = columnName.concat(DisplayTable[index]);
      }
      columns.forEach((column) => {
        column.hide = true;
        if (columnName.includes(column.name)) {
          column.hide = false;
        }
      });
      timelineGantt.render();
    }
  }, [displayNumber, timelineGantt]);
  const handleChangeColumn = (e) => {
    setDisplayNumber(e.target.value);
  };
  const content = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Radio.Group value={displayNumber} onChange={handleChangeColumn}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Radio value={0}>Basic</Radio>
            <div />
            <Radio value={1}>Standard</Radio>
            <div />
            <Radio value={2}>Extend</Radio>
          </div>
        </Radio.Group>
      </div>
    );
  };
  const handleAttach = (type) => {
    if (type !== 'delete') {
      let dbIds = viewer.getSelection();
      if (dbIds.length === 0) {
        message.warning('Please select a element');
        return;
      }
      let taskIds = timelineGantt.getSelectedTasks();
      if (taskIds.length === 0) {
        message.warning('Please select a task');
        return;
      }
      taskIds.forEach((v) => {
        let task = timelineGantt.getTask(v);
        task.attached = 'Attach by select';
        // if (!task.dbIds) task.dbIds = [];
        if (type === 'attach') {
          task.dbIds = dbIds;
        } else {
          task.dbIds = task.dbIds.concat(dbIds);
          task.dbIds = new Set(task.dbIds);
        }
      });
      timelineGantt.render();
    } else {
      let taskIds = timelineGantt.getSelectedTasks();
      if (taskIds.length === 0) {
        if (!window.confirm('Are you want to clear attachment for all task'))
          return;
        timelineGantt.eachTask((task) => {
          task.attached = '';
          task.dbIds = [];
        });
      } else {
        if (!window.confirm('Are you want to clear attachment for these tasks'))
          return;
        taskIds.forEach((v) => {
          let task = timelineGantt.getTask(v);
          task.attached = '';
          task.dbIds = [];
        });
      }

      timelineGantt.render();
    }
  };
  const handleRule=()=>{
    setIsOpenDefineRule(true)
  }
  const contentAttach = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <ButtonGen size='ssmall' onClick={handleAttach.bind(this, 'attach')} block={true} >
          Attach Current Selection
        </ButtonGen>
        <ButtonGen size='ssmall' onClick={handleAttach.bind(this, 'append')} block={true}>
          Append Current Selection
        </ButtonGen>
      </div>
    );
  };
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
            padding: 5,
          }}
        >
          <div style={{ display: 'flex', gap: 2 }}>
            <IconTextButton
              url={'https://img.icons8.com/ios-filled/50/undo.png'}
              size='ssmall'
              onClick={handleUndo}
            >
              Undo
            </IconTextButton>
            <IconTextButton
              url={'https://img.icons8.com/ios-filled/50/redo.png'}
              size='ssmall'
              onClick={handleRedo}
            >
              Redo
            </IconTextButton>
            <IconButton
              title='Delete'
              icon={'delete'}
              size='ssmall'
              onClick={handleGantt.bind(this, 'del')}
            ></IconButton>
          </div>
          <div style={{ display: 'flex', gap: 2 }}>
            <IconButton
              title='Move Backward'
              url={'https://img.icons8.com/ios-glyphs/30/left.png'}
              size='ssmall'
              onClick={handleGantt.bind(this, 'moveBackward')}
            ></IconButton>
            <IconButton
              title='Move Forward'
              url={'https://img.icons8.com/ios-glyphs/30/right--v1.png'}
              size='ssmall'
              onClick={handleGantt.bind(this, 'moveForward')}
            ></IconButton>

            <IconButton
              title='Indent'
              url={'https://img.icons8.com/ios-filled/50/indent.png'}
              size='ssmall'
              onClick={handleGantt.bind(this, 'indent')}
            ></IconButton>
            <IconButton
              title='Outdent'
              url={'https://img.icons8.com/ios-filled/50/outdent.png'}
              size='ssmall'
              onClick={handleGantt.bind(this, 'outdent')}
            >
              Outdent
            </IconButton>
          </div>
          <div style={{ display: 'flex', gap: 2 }}>
            <Popover
              content={contentAttach}
              zIndex={10}
              trigger='click'
              placement='bottomLeft'
            >
              <IconTextButton
                url={'https://img.icons8.com/ios-filled/50/chain.png'}
                size='ssmall'
                // type='save'
              >
                Attach
              </IconTextButton>
            </Popover>
            <IconTextButton
                url={'https://img.icons8.com/ios-filled/50/rules.png'}
                size='ssmall'
                // type='save'
                onClick={handleRule}
              >
                Rules
              </IconTextButton>
            <IconButton
              title='Clear Attachment'
              icon={'delete'}
              size='ssmall'
              onClick={handleAttach.bind(this, 'delete')}
            ></IconButton>
          </div>
          <div style={{ display: 'flex', gap: 2 }}>
            <Popover
              content={content}
              zIndex={10}
              trigger='click'
              placement='bottomLeft'
            >
              <IconTextButton
                url={'https://img.icons8.com/ios-filled/50/data-sheet.png'}
                size='ssmall'
                // type='save'
              >
                {displayName[displayNumber]}
              </IconTextButton>
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
}

// events.push(
//   timelineGantt.attachEvent('onTaskClick', (id, e) => {
//     if (window.viewer) {
//       let task = timelineGantt.getTask(id);
//       console.log(task);
//       window.viewer.search(task.id, onSuccessCallback, onErrorCallback, [
//         'Task ID',
//         'Activity ID',
//       ]);
//     }
//   })
// );
