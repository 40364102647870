import React, { useEffect, useState } from 'react';
import { Input, Tabs, message } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { ViewerAPI } from '../../../../../api';
import { appStore } from '../../../../../../store/App.store';
import _ from 'lodash';
import AddProperties from '../../../../viewer/featrue/share/qr_drawing/components/AddProperties';
import AddDocument from '../../../../viewer/featrue/share/qr_drawing/components/AddDocument';
import QRDrawingPDF from '../../../../viewer/featrue/share/qr_drawing/components/QRDrawingPDF';
import { qrDrawingStore } from '../../../../viewer/featrue/share/qr_drawing/QRDrawing.store';

function QRShareDrawingPageLayout(props) {
  const [search] = useSearchParams();
  const qrId = search.get('qrId');

  const [name, setName] = useState('');
  const { setIsLoading } = appStore();
  const { setProperties, setDocuments, setCurrentDrawing } = qrDrawingStore();

  useEffect(() => {
    if (!qrId) {
      return;
    }
    run();
  }, []);

  const run = async () => {
    setIsLoading(true);
    try {
      let resQRDrawing = await ViewerAPI.QRDrawingAPI.GetOne_Share({
        id: qrId,
      });
      setCurrentDrawing({ data: { id: qrId } });
      setName(resQRDrawing.name);
      setProperties(resQRDrawing.properties);
      setDocuments(resQRDrawing.documents);
    } catch (ex) {
      message.warning(ex.message);
    }
    setIsLoading(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        // flexDirection: 'column',
        gap: 10,
        height: 'calc(100% - 0px)',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <div style={{ display: 'flex', gap: 5, width: '100%', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            gap: 5,
            width: '50%',
            height: '100%',
            flexDirection: 'column',
            padding: 15,
          }}
        >
          <div style={{ display: 'flex', gap: 5 }}>
            <span style={{ fontWeight: 'bold', fontSize: 18 }}>{name}</span>
          </div>

          <div style={{ width: '100%' }}>
            <Tabs type='card' className='idd-custom-tabs'>
              <Tabs.TabPane tab='Properties' key='properties' forceRender>
                <AddProperties isView={true} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Document' key='document' forceRender>
                <AddDocument isView={true} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>

        <QRDrawingPDF />
      </div>
    </div>
  );
}

export default QRShareDrawingPageLayout;
