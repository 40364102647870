import React, { useEffect } from 'react';
import _ from 'lodash';
import { vrConnectorStore } from './VRConnector.store';
import VRConnectorLayout from './VRConnector.layout';
import Loader from '../../../../../loader/LoaderApp';

export default function VRConnectorFeature(props) {
  const { vrConnectorLoading } = vrConnectorStore();
  useEffect(() => {}, []);

  return (
    <>
      {vrConnectorLoading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 11111111,
          }}
        >
          <Loader />
        </div>
      )}
      <>
        <VRConnectorLayout />
      </>
    </>
  );
}
