const gantt = window.gantt;

export const calculateSummaryProgress = (gantt, task) => {
  if (task.type !== gantt.config.types.project || !task.$group_id)
    return task.progress;
  let totalToDo = 0;
  let totalDone = 0;
  gantt.eachTask(function (child) {
    if (child.type !== gantt.config.types.project) {
      totalToDo += child.duration;
      totalDone += (child.progress || 0) * child.duration;
    }
  }, task.id);
  if (!totalToDo) return 0;
  else return totalDone / totalToDo;
};
export const refreshSummaryProgress = (id, submit, gantt) => {
  if (!gantt.isTaskExists(id)) return;

  var task = gantt.getTask(id);
  var newProgress = calculateSummaryProgress(gantt, task);

  if (newProgress !== task.progress) {
    task.progress = newProgress;

    if (!submit) {
      gantt.refreshTask(id);
    } else {
      gantt.updateTask(id);
    }
  }

  if (!submit && gantt.getParent(id) !== gantt.config.root_id) {
    refreshSummaryProgress(gantt.getParent(id), submit, gantt);
  }
};

export const createInputType = (gantt) => {
  gantt.form_blocks['input'] = {
    render: function (sns) {
      console.log(sns);

      return (
        `<div class='gantt_cal_ltext' style='height:${sns.height}px;'>` +
        `<input id='editor' type='number' placeholder='${
          sns.map_to
        }' style='width:100%'   ${sns.readonly ? 'disabled' : ''} />` +
        '</div>'
      );
    },
    set_value: function (node, value, task) {
      node.querySelector('#editor').value = value || 0;
    },
    get_value: function (node, task) {
      return node.querySelector('#editor').value * 1;
    },
    focus: function (node) {
      var a = node.querySelector('#editor');
      a.select();
      a.focus();
    },
  };
};

export const createActualDatePickerType = (gantt) => {
  gantt.form_blocks['actual-datepicker'] = {
    render: function (sns) {
      console.log(sns);
      return (
        `<div class='gantt_cal_ltext' style='height:${sns.height}px;'>` +
        "<div style='width:100%; display:flex;gap:5px'>" +
        `<input id='editor-start' type='date' placeholder='Start Date' style='width:100%'/>` +
        `<input id='editor-end' type='date' placeholder='End Date' style='width:100%'/>` +
        '</div>' +
        '</div>'
      );
    },
    set_value: function (node, value, task) {
      node.querySelector('#editor-start').value = task.actual_start;
      node.querySelector('#editor-end').value = task.actual_end;
    },
    get_value: function (node, task) {
      task.actual_start = node.querySelector('#editor-start').value;
      task.actual_end = node.querySelector('#editor-end').value;
      return true;
    },
    focus: function (node) {
      var a = node.querySelector('#editor-start');
      a.select();
      a.focus();
    },
  };
};
export const hourRangeFormat = function (step) {
  var hourToStr = gantt.date.date_to_str('%H:%i');
  return function (date) {
    var intervalEnd = new Date(gantt.date.add(date, step, 'hour') - 1);
    return hourToStr(date) + ' - ' + hourToStr(intervalEnd);
  };
};

export const numberWithCommas = (x) => {
  return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
export const toSnakeCase = (name) => {
  return (name + '').toLowerCase().replace(/ /, '_');
};


