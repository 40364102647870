import { requestBimGrid } from 'src/functions/General.function';

export const MeAPI = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('get', '/api/user');
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetAllUserPermissionAPI = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('get', '/api/user/getAllPerrmsion');
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const UpdateUserAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/user', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const SendUserInvitaionAPI = ({ id, host }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/auth/send-user-invitaion',
        { id, host }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const RegisterUserInvitaionAPI = ({ id, name, password }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/auth/register-user-invitaion',
        { id, name, password }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
