import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import IconButton from 'src/components/gen/button/IconButton.gen';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../settings/General.settings';
import { appStore } from '../../../../../store/App.store';
import { projectStore } from '../../../../../store/Docs.store';
import DateCell from '../../../../ag_grid_components/cell/Date.cell';

export default function UserTable({ data, setGridApi, setRowNode }) {
  const navigate = useNavigate();
  const { setIsLoading, user } = appStore();
  const {} = projectStore();
  const gridRef = useRef();
  const [search] = useSearchParams();
  const { organizationId, projectId } = useParams();
  useEffect(() => {}, []);
  const handleEdit = async (e) => {
    setRowNode(e.node);
  };
  const handleRemove = async (e) => {
    if (e.node.data.id === user.id) {
      let check = window.prompt(
        'Are you want to leave this project? please input "Confirm" to process'
      );
      if (check.toLowerCase() !== 'confirm') return;
    } else {
      if (!window.confirm('Are you sure to remove?')) return;
    }
    setIsLoading(true);
    let repData = await requestBimGrid(
      'delete',
      '/api/project/removeUserFromProject',
      {
        userId: e.node.data.id,
        organizationId,
        projectId,
      },
      { organizationId, projectId }
    );
    if (repData) {
      if (e.node.data.id === user.id) {
        navigate('../');
      } else {
        await updateTableAsync(gridRef.current.api, { remove: [e.node.data] });
        gridRef.current.api.redrawRows();
      }
    }
    setIsLoading(false);
  };
  const [columns] = useState([
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agSetColumnFilter',
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: () => {
              return (
                <span style={{ fontWeight: 'bold' }}> {params.value}</span>
              );
            },
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Created By',
      field: 'created_by',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agSetColumnFilter',
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 70,
      maxWidth: 70,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ]);
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          onClick={handleEdit.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleRemove.bind(this, params)}
        />
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          suppressPaginationPanel={true}
          groupDefaultExpanded={2}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
