import { requestBimGrid } from 'src/functions/General.function';

export const AddUserToOrganizationAPI = ({ newEmail }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/admin/organization/addUserToOrganization',
        { newEmail: newEmail }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AddUserToOrganizationWithEmail = ({ newEmail, host }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/admin/organization/addUserToOrganizationWithEmail',
        { newEmail: newEmail, host }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AdminAddCompanyAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/admin/company', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AdminUpdateCompanyAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('put', '/api/admin/company', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AdminDeleteCompanyAPI = ({ companyId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('delete', '/api/admin/company', {
        companyId,
      });
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AdminAddGroupPermissionAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/admin/groupPermission',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AdminUpdateGroupPermissionAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'put',
        '/api/admin/groupPermission',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AdminAddRoleAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/admin/role', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const AdminUpdateRoleAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('put', '/api/admin/role', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
