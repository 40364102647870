import React from 'react';

import UserSettingTab from 'src/components/molecules/UserSettingTab';
import UserAccountSetting from 'src/components/organisms/UserAccountSetting';
import { userSettingStore } from 'src/store/UserSetting.store';

/**
 * @param {React.Element} children
 * @returns {React.Element} The rendered input element.
 */
const UserSettingPage = () => {
  const { tabName } = userSettingStore();
  return (
    <>
      <div class='mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto'>
        <h1 class='border-b py-6 text-4xl font-semibold'>Settings</h1>
        <div class='grid grid-cols-8 pt-3 sm:grid-cols-10'>
          <UserSettingTab />

          <div class='col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow'>
            {tabName === 'Accounts' && <UserAccountSetting />}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettingPage;
