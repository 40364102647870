import { requestBimGrid } from '../../../functions/General.function';

export const GetOneSheet = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/five-d/sheet/get-one-sheet',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetSheetDataById = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/five-d/sheet/get-sheet-data-by-id',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllSheetByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/five-d/sheet/get-all-sheet-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CreateSheet = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/five-d/sheet/add-sheet',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateSheetData = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/five-d/sheet/add-sheet-data',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneSheet = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/five-d/sheet/update-one-sheet',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneSheetData = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/five-d/sheet/update-one-sheet-data',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateManySheetData = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/five-d/sheet/update-many-sheet-data',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteSheet = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/five-d/sheet/delete-one-sheet',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const DeleteSheetData = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/five-d/sheet/delete-one-sheet-data',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
