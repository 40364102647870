import React, { useEffect, useState } from 'react';
import { fiveDStore } from '../../../../store/FiveD.store';
import ViewerForge from '../../viewer/Viewer.forge';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ListFileViewer from './components/ListFileViewer';
import { viewerStore } from '../../../../store/Viewer.store';
import SelectViewModal from '../../viewer/featrue/tree_view/modal/SelectView.modal';
export default function FiveDViewer() {
  const { threeDVieweData, setThreeDVieweData } = fiveDStore();
  const { setListModel, resetViewerStore } = viewerStore();
  const [isOpen, setIsOpen] = useState(true);
  const [otherViews, setOtherViews] = useState();
  const [itemSelected, setItemSelected] = useState();
  const [loading, setLoading] = useState();
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    return () => {
      debugger;
      setThreeDVieweData();
      setListModel([]);
      resetViewerStore();
    };
  }, []);
  return (
    <>
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        <div style={{ position: 'fixed', zIndex: 100 }}>
          <div
            style={{
              width: isOpen ? 250 : 0,
              overflow: 'hidden',
              background: 'white',
            }}
          >
            <ListFileViewer
              setOtherViews={setOtherViews}
              otherViews={otherViews}
              setItemSelected={setItemSelected}
              itemSelected={itemSelected}
            />
          </div>

          <div
            style={{
              position: 'absolute',
              top: 0,
              left: isOpen ? 250 : 0,
              backgroundColor: '#2b435f',
              padding: 10,
              borderBottomRightRadius: 5,
              borderTopRightRadius: 5,
              zIndex: 100,
              //   width: 50,
              //   height: 50,
            }}
          >
            {!isOpen ? (
              <RightOutlined style={{ color: 'white' }} onClick={handleOpen} />
            ) : (
              <LeftOutlined style={{ color: 'white' }} onClick={handleOpen} />
            )}
          </div>
        </div>

        {otherViews && itemSelected && (
          <div
            style={{
              height: '100%',
              width: '100%',
              top: 0,
              backgroundColor: '#d3d3d38a',
            }}
          >
            <SelectViewModal
              setOtherViews={setOtherViews}
              otherViews={otherViews}
              setItemSelected={setItemSelected}
              itemSelected={itemSelected}
              setLoading={setLoading}
              loading={loading}
            />
          </div>
        )}
        {threeDVieweData && (
          <div style={{ width: '100%', height: '100%' }}>
            <ViewerForge
              fileId={threeDVieweData.fileId}
              versionId={threeDVieweData.versionId}
              name={threeDVieweData.name}
              objectId={threeDVieweData.objectId}
              viewId={threeDVieweData.viewId}
            />
          </div>
        )}
      </div>
    </>
  );
}
