import create from 'zustand';
import { TimelineComponentType } from './constants/TimelineComponentType';
import moment from 'moment';

const initialState = {
  timelineGantt: null,

  segmentKey: TimelineComponentType.Tasks,

  isOpenFieldSelector: false,
  isOpenAppearanceDefinitions: false,
  isOpenSimulationSetting: false,
  isOpenDefineRule: false,

  //#region data source
  excelFile: null,
  dataSelected: null,
  dataSourceList: [],
  //#endregion

  //#region configuration
  appearanceData: [],
  configurationData: [],
  //#endregion

  //#region simulation
  timelineSimulationGantt: null,
  settingSimulationData: {},
  currentTimelineDate: null,
  timelineSimulationDate: null,
  timelineSimulationStep: 0,
  timelineAnimationControl: null,
  isTimelineSimulationPlaying: false,
  //#endregion
};
export const timelineStore = create((set) => ({
  ...initialState,
  setTimelineGantt: (value) => set((state) => ({ timelineGantt: value })),
  setTimelineSimulationGantt: (value) =>
    set((state) => ({ timelineSimulationGantt: value })),
  setSegmentKey: (value) => set((state) => ({ segmentKey: value })),

  setIsOpenFieldSelector: (value) =>
    set((state) => ({ isOpenFieldSelector: value })),
  setIsOpenAppearanceDefinitions: (value) =>
    set((state) => ({ isOpenAppearanceDefinitions: value })),
    setIsOpenDefineRule: (value) =>
    set((state) => ({ isOpenDefineRule: value })),

  setExcelFile: (value) => set((state) => ({ excelFile: value })),
  setDataSourceList: (value) => set((state) => ({ dataSourceList: value })),
  setDataSelected: (value) => set((state) => ({ dataSelected: value })),
  setAppearanceData: (value) => set((state) => ({ appearanceData: value })),
  setConfigurationData: (value) =>
    set((state) => ({ configurationData: value })),
  setIsOpenSimulationSetting: (value) =>
    set((state) => ({ isOpenSimulationSetting: value })),
  setSettingSimulationData: (value) =>
    set((state) => ({ settingSimulationData: value })),

  setCurrentTimelineDate: (value) =>
    set((state) => ({ currentTimelineDate: value })),
  setTimelineSimulationDate: (value) =>
    set((state) => ({ timelineSimulationDate: value })),
  setTimelineSimulationStep: (value) =>
    set((state) => ({ timelineSimulationStep: value })),

  setTimelineAnimationControl: (value) =>
    set((state) => ({ timelineAnimationControl: value })),
  setIsTimelineSimulationPlaying: (value) =>
    set((state) => ({ isTimelineSimulationPlaying: value })),

  resetTimelineStore: () => set(initialState),
}));
