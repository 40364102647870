import React from 'react';
/**
 * @param {React.Element} children - The text content of the label.
 * @returns {React.Element} The rendered label element.
 */
const ModalHeader = ({ children }) => {
  return <h3 class='text-lg font-bold text-white'>{children}</h3>;
};

export default ModalHeader;
