import _ from "lodash";
import moment from "moment";


export function timelineChangeProgress(
  timelinepanel,
  items,
  e,
  state,
  startName,
  endName,
  timeline,
  check = "progressChart",
  func
) {
  if (timeline !== null) var time = timelinepanel.getCustomTime(timeline);
  let date = moment(e.time);
  let dateMin = moment(e.time).startOf("days");
  let dateMax = moment(e.time).endOf("days");
  let dateLimitMin = moment(state.actionMinDate).startOf("month");
  let dateLimitMax = moment(state.actionMaxDate).endOf("month");
  let dateMinCurrent = moment(timelinepanel.getCustomTime(startName));
  let dateMaxCurrent = moment(timelinepanel.getCustomTime(endName));
  if (e.id === startName) {
    if (dateMin >= dateLimitMax.startOf("day")) {
      timelinepanel.setCustomTime(dateLimitMax.startOf("day"), startName);
      if (timeline !== null)
        timelinepanel.setCustomTime(dateLimitMax.startOf("day"), timeline);
      let item = items.get(1);
      item.end = dateLimitMax.startOf("day");
      items.update(item);

      state.timeValue = {
        min: dateLimitMax.startOf("day"),
        max: state.timeValue.max,
      };
      state.currentTime = dateLimitMax.startOf("day");

      // excuteFuncton(check, _this);
    } else if (dateMin > dateLimitMin.startOf("day")) {
      if (dateMin >= dateMaxCurrent.startOf("day")) {
        timelinepanel.setCustomTime(dateMaxCurrent.startOf("day"), startName);
        if (timeline !== null)
          timelinepanel.setCustomTime(dateMaxCurrent.startOf("day"), timeline);
        let item = items.get(1);
        item.end = dateMaxCurrent.startOf("day");
        items.update(item);

        state.timeValue = {
          min: dateMaxCurrent.startOf("day"),
          max: state.timeValue.max,
        };
        state.currentTime = dateMaxCurrent.startOf("day");
        excuteFuncton(check, func);
      } else {
        timelinepanel.setCustomTime(dateMin, startName);
        if (timeline !== null && time <= dateMinCurrent.startOf("day"))
          timelinepanel.setCustomTime(dateMin, timeline);
        let item = items.get(1);
        item.end = dateMin;
        items.update(item);

        state.timeValue = {
          min: dateMin,
          max: state.timeValue.max,
        };
        state.currentTime =
          timeline !== null && time <= dateMinCurrent.startOf("day")
            ? dateMin
            : time;

        excuteFuncton(check, func);
      }
    } else if (dateMin <= dateLimitMin.startOf("day")) {
      timelinepanel.setCustomTime(dateLimitMin.startOf("day"), startName);
      let item = items.get(1);
      item.end = dateLimitMin.startOf("day");
      items.update(item);
      state.timeValue = {
        min: dateLimitMin.startOf("day"),
        max: state.timeValue.max,
      };

      excuteFuncton(check, func);
    }
  } else if (e.id === endName) {
    if (dateMax <= dateLimitMin.endOf("days")) {
      timelinepanel.setCustomTime(dateLimitMin.endOf("days"), endName);
      if (timeline !== null)
        timelinepanel.setCustomTime(dateLimitMin.endOf("days"), timeline);
      let item = items.get(2);
      item.start = dateLimitMin.endOf("days");
      items.update(item);

      state.timeValue = {
        min: state.timeValue.min,
        max: dateLimitMin.endOf("days"),
      };
      state.currentTime = dateLimitMin.endOf("days");

      excuteFuncton(check, func);
    } else if (dateMax < dateLimitMax.endOf("days")) {
      if (dateMax <= dateMinCurrent.endOf("day")) {
        timelinepanel.setCustomTime(dateMinCurrent.endOf("day"), endName);
        if (timeline !== null)
          timelinepanel.setCustomTime(dateMinCurrent.endOf("day"), timeline);
        let item = items.get(2);
        item.start = dateMinCurrent.endOf("day");
        items.update(item);

        state.timeValue = {
          min: state.timeValue.min,
          max: dateMinCurrent.endOf("day"),
        };
        state.currentTime = dateMinCurrent.endOf("day");

        excuteFuncton(check, func);
      } else {
        timelinepanel.setCustomTime(dateMax, endName);
        if (timeline !== null && time >= dateMaxCurrent.endOf("day"))
          timelinepanel.setCustomTime(dateMax, timeline);
        let item = items.get(2);
        item.start = dateMax;
        items.update(item);

        state.timeValue = { min: state.timeValue.min, max: dateMax };
        state.currentTime =
          timeline !== null && time >= dateMaxCurrent.endOf("day")
            ? dateMax
            : time;

        excuteFuncton(check, func);
      }
    } else if (dateMax >= dateLimitMax.endOf("days")) {
      timelinepanel.setCustomTime(dateLimitMax.endOf("days"), endName);
      let item = items.get(2);
      item.start = dateLimitMax.endOf("day");
      items.update(item);

      state.timeValue = {
        min: state.timeValue.min,
        max: dateLimitMax.endOf("day"),
      };

      excuteFuncton(check, func);
    }
  } else if (e.id === timeline) {
    if (date >= dateMaxCurrent) {
      timelinepanel.setCustomTime(dateMaxCurrent, timeline);
      state.currentTime = dateMaxCurrent;
      // _this.setState({ currentTime: dateMaxCurrent }, () => {
      //   excuteFuncton(check, _this);
      // });
      excuteFuncton(check, func);
    } else if (date <= dateMinCurrent) {
      timelinepanel.setCustomTime(dateMinCurrent, timeline);
      state.currentTime = dateMinCurrent;

      // _this.setState({ currentTime: dateMinCurrent }, () => {
      //   excuteFuncton(check, _this);
      // });
      excuteFuncton(check, func);
    } else {
      timelinepanel.setCustomTime(date, timeline);
      state.currentTime = date;
      // _this.setState({ currentTime: date }, () => {
      //   excuteFuncton(check, _this);
      // });
      excuteFuncton(check, func);
    }
  }
}


function excuteFuncton(check, _this) {
   if (check === "progressChart") _this.showElementByDateRange();
}
