export const COBieColumnTable = {
    Space: [
        {
            headerName: 'COBie',
            field: 'COBie',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.CreatedBy',
            field: 'COBie.CreatedBy',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.CreatedOn',
            field: 'COBie.CreatedOn',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Space.Name',
            field: 'COBie.Space.Name',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Space.Description',
            field: 'COBie.Space.Description',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Space.RoomTag',
            field: 'COBie.Space.RoomTag',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Occupancy',
            field: 'Occupancy',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Department',
            field: 'Department',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Comments',
            field: 'Comments',
            filter: 'agSetColumnFilter',
        },
    ],
    Type:[],
    Floor:[
        {
            headerName: 'COBie.Type',
            field: 'COBie.Type',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Assembly Code',
            field: 'Assembly Code',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.Category',
            field: 'COBie.Type.Category',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.Description',
            field: 'COBie.Type.Description',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.Manufacturer',
            field: 'COBie.Type.Manufacturer',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.ModelNumber',
            field: 'COBie.Type.ModelNumber',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.ReplacementCost',
            field: 'COBie.Type.ReplacementCost',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.ExpectedLife',
            field: 'COBie.Type.ExpectedLife',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.WarrantyDescription',
            field: 'COBie.Type.WarrantyDescription',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Assembly Description',
            field: 'Assembly Description',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Description',
            field: 'Description',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Manufacturer',
            field: 'Manufacturer',
            filter: 'agSetColumnFilter',
        },
    ],
    Door:[
        {
            headerName: 'COBie',
            field: 'COBie',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Assembly Code',
            field: 'Assembly Code',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.Category',
            field: 'COBie.Type.Category',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.Description',
            field: 'COBie.Type.Description',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.Manufacturer',
            field: 'COBie.Type.Manufacturer',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.ModelNumber',
            field: 'COBie.Type.ModelNumber',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.ReplacementCost',
            field: 'COBie.Type.ReplacementCost',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.ExpectedLife',
            field: 'COBie.Type.ExpectedLife',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'COBie.Type.WarrantyDescription',
            field: 'COBie.Type.WarrantyDescription',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Assembly Description',
            field: 'Assembly Description',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Description',
            field: 'Description',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Manufacturer',
            field: 'Manufacturer',
            filter: 'agSetColumnFilter',
        },
    ]
}