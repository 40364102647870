import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageHeader from 'src/components/atoms/PageHeader';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { requestBimGrid } from '../../../../functions/General.function';
import { appStore } from '../../../../store/App.store';
import ModalEdiRole from './modal/ModalEditRole';
import RoleTable from './table/Role.table';
export default function LayoutRole() {
  const { setIsLoading } = appStore();
  const [data, setData] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [rowNode, setRowNode] = useState();
  const { organizationId, projectId } = useParams();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid('get', '/api/user/getAllRole', null, {
      organizationId,
      projectId,
    });
    if (data) {
      setData(data);
    }
    setIsLoading(false);
  };

  return (
    <>
      <TopPageToolbar left={<PageHeader>Project Role</PageHeader>} />

      <RoleTable data={data} setGridApi={setGridApi} setRowNode={setRowNode} />
      {rowNode && (
        <ModalEdiRole
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
