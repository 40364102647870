import { EventDispatcher } from '../utils/EventDispatcher'
import './TransformGizmos'
const Autodesk = window.Autodesk
const THREE = window.THREE



export default class TransformAnimationTool extends EventDispatcher {


  constructor( name) {
    super()
    this.active = false
    this._viewer = null
    this._hitPoint = null
    this._isDragging = false
    this._transformMesh = null
    this._selectedFragProxyMap = {}
    this._transformControlTx = null
    this.onTxChange =
      this.onTxChange.bind(this)
    this.onCameraChanged =
      this.onCameraChanged.bind(this)
    this.event = {
      type: 'end'
    };
    this.position = null
    this.name = name
    delete this.register;
    delete this.deregister;
    delete this.activate;
    delete this.deactivate;
    delete this.getPriority;
    delete this.handleMouseMove;
    delete this.handleButtonDown;
    delete this.handleButtonUp;
    delete this.handleSingleClick;

    this.names = [name];
  }

  register() {
    console.log('Transform Animation registered.');
  }

  deregister() {
    console.log('Transform Animation unregistered.');
  }

  getPriority() {
    return 1; // Use any number higher than 0 (the priority of all default tools)
  }

  handleSingleClick(event, button) {
    return false;
  }
  createTransformMesh() {

    var material = new THREE.MeshPhongMaterial(
      { color: 0xff0000 })

    this._viewer.impl.matman().addMaterial(
      'transform-tool-material',
      material,
      true)

    var sphere = new THREE.Mesh(
      new THREE.SphereGeometry(0.0001, 5),
      material)

    sphere.position.set(0, 0, 0)

    return sphere
  }

  onTxChange() {
    if (this._isDragging && this._transformControlTx.visible) {
      // var translation = new THREE.Vector3(
      //   this._transformMesh.position.x - this._selection.model.offset.x,
      //   this._transformMesh.position.y - this._selection.model.offset.y,
      //   this._transformMesh.position.z - this._selection.model.offset.z)
      for (var fragId in this._selectedFragProxyMap) {
        var fragProxy = this._selectedFragProxyMap[fragId]
        var position = new THREE.Vector3(
          this._transformMesh.position.x - fragProxy.offset.x,
          this._transformMesh.position.y - fragProxy.offset.y,
          this._transformMesh.position.z - fragProxy.offset.z)
        fragProxy.position = position
        fragProxy.updateAnimTransform()
      }
      this.position = this._transformMesh.position
    }
    this._viewer.impl.sceneUpdated(true)
  }

  setSelection(e) {
    this._selection = e
  }
  onCameraChanged() {

    if (this._transformControlTx) {

      this._transformControlTx.update()
    }
  }


//viewer.model.getData().globalOffset
  initializeSelection(hitPoint) {

    this._selectedFragProxyMap = {}

    var modelTransform = this._selection.model.transform ||
      { translation: { x: 0, y: 0, z: 0 } }

    this._selection.model.offset = {
      x: hitPoint.x - modelTransform.translation.x,
      y: hitPoint.y - modelTransform.translation.y,
      z: hitPoint.z - modelTransform.translation.z
    }

    this._transformControlTx.visible = true

    this._transformControlTx.setPosition(
      hitPoint)

    this._transformControlTx.addEventListener(
      'change', this.onTxChange)

    this._viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.onCameraChanged)

    this._selection.fragIdsArray.forEach((fragId) => {

      var fragProxy = this._viewer.impl.getFragmentProxy(
        this._selection.model,
        fragId)

      fragProxy.getAnimTransform()

      fragProxy.offset = {

        x: hitPoint.x - fragProxy.position.x,
        y: hitPoint.y - fragProxy.position.y,
        z: hitPoint.z - fragProxy.position.z
      }

      this._selectedFragProxyMap[fragId] = fragProxy
    })
  }

  clearSelection() {

    if (this.active) {

      this._selection = null

      this._selectedFragProxyMap = {}

      this._transformControlTx.visible = false

      this._transformControlTx.removeEventListener(
        'change', this.onTxChange)

      this._viewer.removeEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        this.onCameraChanged)

      this._viewer.impl.sceneUpdated(true)
    }
  }


  normalize(screenPoint) {

    var viewport = this._viewer.navigation.getScreenViewport()

    var n = {
      x: (screenPoint.x - viewport.left) / viewport.width,
      y: (screenPoint.y - viewport.top) / viewport.height
    }

    return n
  }


  activate(name, viewer) {
    this._viewer =viewer
    if (!this.active) {

      var sds = this._viewer.getExtension('Autodesk.Viewing.FusionOrbit')
      sds.deactivate()
      this.active = true

      this._viewer.select([])

      var bbox = this._viewer.model.getBoundingBox()

      this._viewer.impl.createOverlayScene(this.name + '_TransformToolOverlayAnimation')

      this._transformControlTx = new THREE.TransformControls(
        this._viewer.impl.camera,
        this._viewer.impl.canvas,
        "translate")

      this._transformControlTx.setSize(
        bbox.getBoundingSphere().radius * 5)

      this._transformControlTx.visible = false

      this._viewer.impl.addOverlay(
        this.name + '_TransformToolOverlayAnimation',
        this._transformControlTx)

      this._transformMesh = this.createTransformMesh()

      this._transformControlTx.attach(
        this._transformMesh)

    }
  }

  deactivate(name) {

    if (this.active) {

      this.active = false

      this._viewer.impl.removeOverlay(
        this.name + '_TransformToolOverlayAnimation',
        this._transformControlTx)

      this._transformControlTx.removeEventListener(
        'change',
        this.onTxChange)

      this._viewer.impl.removeOverlayScene(
        this.name + '_TransformToolOverlayAnimation')

      this._viewer.removeEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        this.onCameraChanged)
    }
  }

  handleButtonDown(event) {
    this._isDragging = true
    this.position = null
    if (this._transformControlTx.onPointerDown(event))
      return true
    return false
  }
  handleMouseMove(event) {
    if (this._isDragging) {
      if (this._transformControlTx.onPointerMove(event)) {
        return true
      }
      return false
    }
    if (this._transformControlTx.onPointerHover(event))
      return true
    return false
  }
  handleButtonUp(event) {
    this._isDragging = false
    if (this.position) {
      this.dispatchEvent({
        type: 'end',
        position: this.position
      });
    }
    if (this._transformControlTx.onPointerUp(event))
      return true
    return false
  }
}

Object.assign(EventDispatcher.prototype);