import React from 'react';
import { Tabs } from 'antd';
import WorkerListLayout from './worker_list/WorkerList.layout';
import { workerStore } from '../../../../../store/Worker.store';
import ModalAddWorkerList from './modal/ModalAddWorkerList';
import ModalEditWorkerList from './modal/ModalEditWorkerList';
import SuperviorListLayout from './supervior_list/SuperviorList.layout';
import ModalAddSuperviorList from './modal/ModalAddSuperviorList';
import ModalEditSuperviorList from './modal/ModalEditSuperviorList';
import CostCodeLayout from './cost_code/CostCode.layout';
import ModalAddCostCode from './modal/ModalAddCostCode';
import ModalEditCostCode from './modal/ModalEditCostCode';
import BlockZoneLayout from './block_zone/BlockZone.layout';
import ModalAddBlockZone from './modal/ModalAddBlockZone';
import ModalEditBlockZone from './modal/ModalEditBlockZone';
import WorkTimeLayout from './work_time/WorkTime.layout';
import ModalAddWorkTime from './modal/ModalAddWorkTime';
import ModalEditWorkTime from './modal/ModalEditWorkTime';
import ModalEditMoneyWorkerList from './modal/ModalEditMoneyWorkerList';
import ModalBlockZoneModel from './modal/ModalBlockZoneModel';
import HubDevicesLayout from './hub_devices/HubDevices.layout';
import ModalAddHubDevices from './modal/ModalAddHubDevices';
import ModalEditHubDevices from './modal/ModalEditHubDevices';
import TrackingDevicesLayout from './tracking_devices/TrackingDevices.layout';
import ModalAddTrackingDevices from './modal/ModalAddTrackingDevices';
import ModalEditTrackingDevices from './modal/ModalEditTrackingDevices';

export default function WorkerSettingLayout() {
  const {
    isOpenAddWokerList,
    isOpenEditWokerList,
    nodeEditWorkerList,
    isOpenAddSuperviorList,
    isOpenEditSuperviorList,
    nodeEditSuperviorList,
    isOpenAddCostCode,
    isOpenEditCostCode,
    nodeEditCostCode,
    isOpenAddBlockZone,
    isOpenEditBlockZone,
    nodeEditBlockZone,
    isOpenAddWorkTime,
    nodeEditWorkTime,
    isOpenEditWorkTime,
    isOpenMoneyWokerList,
    isOpenBlockZoneModel,
    isOpenAddHubDevices,
    isOpenEditHubDevices,
    isOpenAddTrackingDevices,
    isOpenEditTrackingDevices,
  } = workerStore();
  return (
    <>
      <div style={{ padding: 5, height: '100%', widthL: '100%' }}>
        <div
          style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }}
        >
          <Tabs
            className='idd-custom-tabs'
            style={{ width: '100%' }}
            //   type='card'
            destroyInactiveTabPane
            // onChange={handleChangeTab}
            // activeKey={appName}
          >
            <Tabs.TabPane tab='Worker list' key='worker'>
              <WorkerListLayout />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Supervior list' key='supervior'>
              <SuperviorListLayout />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Cost code' key='cost'>
              <CostCodeLayout />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Block/Zone' key='block'>
              <BlockZoneLayout />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab='Unit' key='unit'></Tabs.TabPane> */}
            <Tabs.TabPane tab='Work time' key='time'>
              <WorkTimeLayout />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Hub devices' key='hub_devices'>
              <HubDevicesLayout />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Tracking devices' key='tracking_devices'>
              <TrackingDevicesLayout />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
      {isOpenAddWokerList && <ModalAddWorkerList />}
      {isOpenEditWokerList && nodeEditWorkerList && <ModalEditWorkerList />}
      {isOpenMoneyWokerList && nodeEditWorkerList && (
        <ModalEditMoneyWorkerList />
      )}
      {isOpenAddSuperviorList && <ModalAddSuperviorList />}
      {isOpenEditSuperviorList && nodeEditSuperviorList && (
        <ModalEditSuperviorList />
      )}
      {isOpenAddCostCode && <ModalAddCostCode />}
      {isOpenEditCostCode && nodeEditCostCode && <ModalEditCostCode />}
      {isOpenAddBlockZone && <ModalAddBlockZone />}
      {isOpenEditBlockZone && nodeEditBlockZone && <ModalEditBlockZone />}
      {isOpenAddWorkTime && <ModalAddWorkTime />}
      {isOpenEditWorkTime && nodeEditWorkTime && <ModalEditWorkTime />}
      {isOpenBlockZoneModel && <ModalBlockZoneModel />}

      {isOpenAddHubDevices && <ModalAddHubDevices />}
      {isOpenEditHubDevices && <ModalEditHubDevices />}

      {isOpenAddTrackingDevices && <ModalAddTrackingDevices />}
      {isOpenEditTrackingDevices && <ModalEditTrackingDevices />}
    </>
  );
}
