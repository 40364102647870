import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckCircleIcon } from 'src/assets/icons/general/check-circle.svg';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import NotificationModal from 'src/components/molecules/NotificationModal';

/**
 * @returns {React.Element} The rendered input element.
 */
const ForgotPasswordModal = () => {
  const navigate = useNavigate();
  const handleCLick = useCallback(() => {
    navigate('/');
  }, []);
  return (
    <NotificationModal>
      <div className='pad flex items-center justify-center text-primary-400 dark:text-primary-800'>
        {' '}
        <CheckCircleIcon className='size-10 text-primary-600' />
      </div>

      <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
        An email has been sent to your registered email address with
        instructions to reset your password.
      </h3>
      <div className='flex items-center justify-center'>
        <PrimaryButton className='px-9' onClick={handleCLick}>
          {' '}
          OK
        </PrimaryButton>
      </div>
    </NotificationModal>
  );
};

export default ForgotPasswordModal;
