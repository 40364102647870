/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Tabs, message } from 'antd';
import _ from 'lodash';
import { qrDrawingStore } from '../QRDrawing.store';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import AddProperties from '../components/AddProperties';
import AddDocument from '../components/AddDocument';
import { ViewerAPI } from '../../../../../../api';
import { useSearchParams } from 'react-router-dom';
export default function DetailQRDrawing() {
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const {
    setIsDetailDrawing,
    setQRDrawingLoading,
    currentDrawing,
    setProperties,
    setDocuments,
  } = qrDrawingStore();
  const [name, setName] = useState('');

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setQRDrawingLoading(true);
    try {
      let resQRDrawing = await ViewerAPI.QRDrawingAPI.GetOne({
        id: currentDrawing.data.id,
      });
      setName(resQRDrawing.name);
      setProperties(resQRDrawing.properties);
      setDocuments(resQRDrawing.documents)
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRDrawingLoading(false);
  };
  const close = () => {
    setIsDetailDrawing();
  };

  const handleOk = async (e) => {
    return
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Detail'}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={true}
      >
        <div
          style={{
            display: 'flex',
            gap: 10,
            height: 'calc(100vh - 450px)',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 10,
            }}
          >
            <div style={{}}>
              <div style={{ width: '100%', display: 'flex', gap: 5 }}>
                <span style={{ fontWeight: 'bold', width: 100 }}>Name:</span>
                <Input
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  value={name}
                  readOnly={true}
                />
              </div>
            
            </div>
            <div>
              <Tabs type='card' className='idd-custom-tabs'>
                <Tabs.TabPane tab='Properties' key='properties' forceRender>
                  <AddProperties isView={true} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Document' key='document' forceRender>
                  <AddDocument isView={true} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
