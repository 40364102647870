import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '../../../gen/button/IconButton.gen';
import TextButton from '../../../gen/button/TextButton.gen';

export default function WorkerSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (e) => {
    navigate(e);
  };

  return (
    <div className='idd-sidebar-dms'>
      <div
        style={{
          display: 'flex',
          gap: 5,
          flexDirection: 'column',
        }}
      >
        <TextButton
          size='sssmall'
          type='save'
          active={location.pathname.includes('/worker/daily')}
          onClick={handleChange.bind(this, 'daily')}
        >
          Daily
        </TextButton>
        <TextButton
          size='sssmall'
          type='save'
          active={location.pathname.includes('/worker/track')}
          onClick={handleChange.bind(this, 'track')}
        >
          Track
        </TextButton>

        <TextButton
          size='sssmall'
          type='save'
          active={location.pathname.includes('/worker/payment-month')}
          onClick={handleChange.bind(this, 'payment-month')}
        >
          PM
        </TextButton>
      </div>

      <div
        style={{
          display: 'flex',
          gap: 5,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconButton
          icon='setting'
          size='small'
          type='save'
          border={false}
          active={location.pathname.includes('/worker/setting')}
          onClick={handleChange.bind(this, 'setting')}
        />
      </div>
    </div>
  );
}
