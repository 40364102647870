import React from 'react';
import { userSettingStore } from 'src/store/UserSetting.store';

const TABS = ['Accounts', 'Billing', 'Notifications', 'Integrations'];
/**
 * @param {React.Element} children
 * @returns {React.Element} The rendered input element.
 */
const UserSettingTab = () => {
  const { setTabName, tabName } = userSettingStore();
  const handleCick = (type) => {
    setTabName(type);
  };
  return (
    <>
      <div class='relative my-4 w-56 sm:hidden'>
        <input
          class='peer hidden'
          type='checkbox'
          name='select-1'
          id='select-1'
        />
        <label
          for='select-1'
          class='flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring'
        >
          {tabName}
        </label>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          class='pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          stroke-width='2'
        >
          <path
            stroke-linecap='round'
            stroke-linejoin='round'
            d='M19 9l-7 7-7-7'
          />
        </svg>
        <ul class='max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3'>
          {TABS.map((tab) => (
            <li
              class='cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white'
              onClick={handleCick.bind(this, tab)}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      <div class='col-span-2 hidden sm:block'>
        <ul>
          {TABS.map((tab) => (
            <li
              class={`mt-5 cursor-pointer border-l-2 ${tab === tabName ? 'border-l-primary-700' : 'border-transparent'} px-2 py-2 font-semibold text-primary-700 transition hover:border-l-primary-700 hover:text-primary-700`}
              onClick={handleCick.bind(this, tab)}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default UserSettingTab;
