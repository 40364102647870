import { requestBimGrid } from 'src/functions/General.function';

export const LoginAPI = ({ email, password }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/auth', {
        email,
        password,
      });
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const SignUpAPI = ({ email, password, name }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/auth/signup', {
        email,
        password,
        name,
      });
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const LoginMicrosoftAPI = ({ email }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/auth/microsoft-account', {
        email,
      });
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const ForgotPasswordAPI = ({ email, host }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/auth/forgot-password', {
        email,
        host,
      });
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const ResetPasswordAPI = ({ id, resetId, password }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/auth/reset-password', {
        id,
        resetId,
        password,
      });
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CheckResetPasswordAPI = ({ id, resetId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/auth/check-reset-password',
        {
          id,
          resetId,
        }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const CheckUserInvitationAPI = ({ id }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/auth/check-user-invitaion',
        {
          id,
        }
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
