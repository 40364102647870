import React, { useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import ButtonGen from './common/Button.gen';
import icons from '../constant/Icon.constant';

export default function IconTextButton({
  url,
  size = '',
  icon,
  children,
  ...props
}) {
  return (
    <>
      <ButtonGen size={size} {...props}>
        <div
          style={{
            display: 'flex',
            gap: 2,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <div
            className={`icon ${size} `}
            style={{
              WebkitMaskImage: `url(${icons[icon] ? icons[icon] : url})`,
            }}
          ></div>
          <span className={`text ${size}`}> {children}</span>
        </div>
      </ButtonGen>
    </>
  );
}
