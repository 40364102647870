import { Progress } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetForgeItemStatus } from 'src/api';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../functions/General.function';

export default function ProgressCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [progress, setProgress] = useState(0);
  const { organizationId, projectId } = useParams();

  useEffect(() => {
    if (props.status === 1) {
      setProgress(1);
      run();
    }
  }, []);
  const run = async () => {
    try {
      debugger;
      const res = await GetForgeItemStatus({ objectId: props.data.objectId });
      if (res.data.status === 'success') {
        setProgress(99);
        let data = await requestBimGrid(
          'put',
          '/api/version',
          { id: props.data.versionId, status: 99 },
          { organizationId, projectId }
        );
        if (data) {
          var rowNode = props.node;
          rowNode.data.status = 99;
          await updateTableAsync(props.api, { update: [rowNode] });
          props.api.redrawRows();
          setProgress(100);
        }
      } else {
        var reg = res.data.progress.split('%');
        let progress = _.isNumber(_.toNumber(reg[0]))
          ? _.toNumber(reg[0])
          : 100;
        setProgress(progress === 0 ? 1 : progress);
        setTimeout(() => {
          run();
        }, 5000);
      }
    } catch {}
  };

  return props.status === 1 ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
        alignItems: 'center',
      }}
    >
      <div style={{ fontWeight: 'bold', height: 30 }}> {cellValue}</div>
      {progress !== 100 && progress !== 0 && (
        <Progress percent={progress} steps={5} />
      )}
    </div>
  ) : (
    <>
      <span style={{ fontWeight: 'bold' }}> {cellValue}</span>
    </>
  );
}
