import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import DMSSidebar from 'src/components/layout/routes/dms/DMSSidebar';
import { appStore } from 'src/store/App.store';

export default function DMSRoute() {
  const { setAppName } = appStore();
  useEffect(() => {
    setAppName('dms');
    return () => {
      setAppName(null);
    };
  }, []);

  return (
    <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }}>
      <DMSSidebar />
      <div style={{ height: '100%', width: '100%' }}>
        <Outlet />
      </div>
    </div>
  );
}
