import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { resetAll } from 'src/functions/General.function';
import { appStore } from 'src/store/App.store';
/**
 * @returns {React.Element} The rendered input element.
 */
const UserNav = () => {
  const navigate = useNavigate();
  const { user } = appStore();
  const handleLogout = () => {
    resetAll();
    navigate('/');
  };
  const handleAdminSetup = () => {
    navigate('/admin/organizations');
  };
  const handleSuperAdminSetup = () => {
    navigate('/super-admin');
  };
  const handleUserSetting = () => {
    navigate('/user-setting');
  };
  return (
    <div className='z-[999] flex md:order-2'>
      <Dropdown
        arrowIcon={false}
        inline
        label={
          <Avatar
            alt='User settings'
            // img='https://flowbite.com/docs/images/people/profile-picture-5.jpg'
            rounded
          />
        }
      >
        <Dropdown.Header>
          <span className='block text-sm'>
            {user.name !== '' ? user.name : 'N/A'}
          </span>
          <span className='block truncate text-sm font-medium'>
            {user.email}
          </span>
        </Dropdown.Header>

        <Dropdown.Item onClick={handleAdminSetup}>Admin Settings</Dropdown.Item>
        {user.is_admin && (
          <Dropdown.Item onClick={handleSuperAdminSetup}>
            Super Admin Settings
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={handleUserSetting}>User Settings</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
      </Dropdown>
      <Navbar.Toggle />
    </div>
  );
};

export default UserNav;
