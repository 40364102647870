import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { updateTableAsync } from '../../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import TimeCell from '../../../../../ag_grid_components/cell/Time.cell';
import { AppAPI } from '../../../../../api';
import IconButton from '../../../../../gen/button/IconButton.gen';
import BlockZoneCell from '../../cell/BlockZone.cell';
import TrackingTimeCell from '../../cell/TrackingTime.cell';
import { updateWorkerListPayment } from '../../Worker.utits';

export default function WorkerDailyTable() {
  const { setIsLoading } = appStore();
  const {
    setWorkerDailyGridApi,
    workerDailyGridApi,
    setIsOpenEditWorkerDaily,
    setNodeEditWorkerDaily,
    setDataWorkerDaily,
    dataWorkerDaily,
    dailyDate,
    setIsRealtimeTracking,
  } = workerStore();
  const gridRef = useRef();
  const { projectId } = useParams();
  useEffect(() => {
    setIsRealtimeTracking(true);
    run();
  }, [dailyDate]);
  const run = async () => {
    setIsLoading(true);
    try {
      const workerListRes =
        await AppAPI.WorkerDailyAPI.GetAllWorkerDailyByProjectDate({
          project_id: projectId,
          date: dailyDate,
        });
      if (workerListRes) setDataWorkerDaily(workerListRes);
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      await AppAPI.WorkerDailyAPI.DeleteWorkerDaily({
        id: e.node.data.id,
      });
      await updateTableAsync(workerDailyGridApi.api, { remove: [e.node.data] });
      await updateWorkerListPayment({
        data: { id: e.node.data.worker_id },
      });
      debugger;
    } catch (ex) {}
    setIsLoading(false);
  };
  const handleEditItem = async (e) => {
    setNodeEditWorkerDaily(e.node);
    setIsOpenEditWorkerDaily(true);
  };
  const columns = [
    {
      headerName: 'Supervior name',
      field: 'supervior_name',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Employee Code',
      field: 'employee_code',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Worker name',
      field: 'worker_name',
      filter: 'agSetColumnFilter',
      minWidth: 200,
    },

    {
      headerName: 'Trade',
      field: 'trade',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Start at',
      field: 'start_at',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: TimeCell,
        };
      },
    },
    {
      headerName: 'End at',
      field: 'end_at',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: TimeCell,
        };
      },
    },
    {
      headerName: 'Tracking time',
      field: 'tracking_time',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup)
          return {
            component: TrackingTimeCell,
          };
        return undefined;
      },
    },
    {
      headerName: 'Time type',
      field: 'time_type',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Location',
      field: 'block',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: BlockZoneCell,
        };
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleEditItem.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setWorkerDailyGridApi(params);
  }, []);

  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine dark:bg-red-400'
      >
        <AgGridReact
          ref={gridRef}
          rowData={dataWorkerDaily}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
          groupDefaultExpanded={2}
        ></AgGridReact>
      </div>
    </>
  );
}
