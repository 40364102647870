import React, { useEffect, useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';

export default function TableMappingExcel({
  dataTable,
  setGridApi,
  externalData,
}) {
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(() => {
    const columns = [
      {
        headerName: 'Column',
        field: 'column',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'External Field Name',
        field: 'external',
        filter: 'agSetColumnFilter',
        editable: true,
        cellEditorSelector: () => {
          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: externalData,
            },
            popup: true,
          };
        },
      },
    ];
    setColumnDefs(columns);
  }, [externalData]);

  const onGridReady = useCallback((node) => {
    setGridApi(node);
  }, []);
  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div
          style={{ width: '100%', height: '100%' }}
          className='ag-theme-alpine'
        >
          <AgGridReact
            rowHeight={30}
            rowData={dataTable}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows={true}
            onGridReady={onGridReady}
            suppressPaginationPanel={true}
            context={false}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
}
