/* eslint-disable no-useless-escape */
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AddUserToOrganizationAPI,
  AddUserToOrganizationWithEmail,
} from 'src/api';
import { ReactComponent as PlusCircleIcon } from 'src/assets/icons/general/plus-circle.svg';
import InputForm from 'src/components/atoms/InputForm';
import PageHeader from 'src/components/atoms/PageHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import UserInvationModal from 'src/components/organisms/UserInvationModal';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import UserTable from './table/User.table';

export default function LayoutUser() {
  const navigate = useNavigate();
  const { organizationId, projectId } = useParams();
  const [data, setData] = useState();
  const { setIsLoading } = appStore();
  const [name, setName] = useState('');
  const [, setRowNode] = useState();
  const [gridApi, setGridApi] = useState();
  const [userInvatationModal, setUserInvatationModal] = useState();

  useEffect(() => {
    if (!organizationId) {
      navigate(-1);
      return;
    }
    run();
  }, []);
  const run = async () => {
    try {
      setIsLoading(true);
      let data = await requestBimGrid(
        'get',
        '/api/admin/organization/getAllUserInOrganization',
        null,
        { organizationId, projectId }
      );
      if (data) {
        setData(data);
      }
    } catch (ex) {
      message.warning(ex.message);
    }
    setIsLoading(false);
  };
  const handleAddName = (e) => {
    setName(e.target.value);
  };
  const handleAddNewUser = async () => {
    try {
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(name)) {
        message.warning('You should input email');
        return;
      }
      if (!window.confirm('Are you want to add new user?')) return;
      setIsLoading(true);
      const repData = await AddUserToOrganizationAPI({ newEmail: name });
      if (repData !== 'user') {
        await updateTableAsync(gridApi.api, { add: [repData] });
      } else {
        setUserInvatationModal(name);
      }
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
    setIsLoading(false);
  };
  const handleAddNewUserWithEmail = async () => {
    setIsLoading(true);
    try {
      const repData = await AddUserToOrganizationWithEmail({
        newEmail: name,
        host: window.location.origin,
      });
      if (repData) {
        await updateTableAsync(gridApi.api, { add: [repData] });
        setUserInvatationModal();
      }
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
    setIsLoading(false);
  };
  const handleCloseInvitationModal = async () => {
    setUserInvatationModal();
  };
  if (!data) {
    return null;
  }
  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader>Organization User</PageHeader>
          </>
        }
        right={
          <>
            <InputForm
              className='w-80'
              placeholder='Enter email to add'
              onChange={handleAddName}
            />
            <PrimaryButton disabled={!name} onClick={handleAddNewUser}>
              <PlusCircleIcon className='size-4' />
              Add
            </PrimaryButton>
          </>
        }
      />
      <UserTable data={data} setGridApi={setGridApi} setRowNode={setRowNode} />
      {userInvatationModal && (
        <UserInvationModal
          name={userInvatationModal}
          onClose={handleCloseInvitationModal}
          onSendEmail={handleAddNewUserWithEmail}
        />
      )}
    </>
  );
}
