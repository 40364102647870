import React from 'react';
import icons from '../../../gen/constant/Icon.constant';
import CardApp from '../../../gen/card/CardApp.gen';
import { useNavigate } from 'react-router-dom';

const data = [
  {
    name: 'Zoom',
    logo: icons.zoom_meeting,
    app: 'zoom',
  },
  {
    name: 'VR',
    logo: icons.vr,
    app: 'vr',
  },
];
export default function VDCPage() {
  const navigate = useNavigate();
  const handleViewItem = (app) => {
    navigate(`${app}`);
  };

  return (
    <>
      <div className='idd-list'>
        {data.map((v, idx) => (
          <CardApp key={idx} {...v} handleViewItem={handleViewItem} />
        ))}
      </div>
    </>
  );
}
