import React, { useCallback, useRef } from 'react';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import {
  autoGroupColumnDef,
  defaultColDef,
  dateFilterParams,
} from '../../../../../../../settings/General.settings';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import DateCell from '../../../../../../ag_grid_components/cell/Date.cell';
import { updateTableAsync } from '../../../../../../../functions/General.function';
import { message } from 'antd';
import { AppAPI, ViewerAPI } from '../../../../../../api';
import { vrConnectorStore } from '../VRConnector.store';
import VRModelStatusCell from '../cell/VRModelStatus.cell';

export default function VRModelTable({}) {
  const {
    vrModelData,
    setGridApiVRModel,
    setVRConnectorLoading,
    gridApiVRModel,
    setIsOpenEditVRModel,
    setNodeEditVRModel,
  } = vrConnectorStore();
  const gridRef = useRef();
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      maxWidth: 120,
      minWidth: 120,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: VRModelStatusCell,
            params: {},
          };
        }
        return undefined;
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      headerName: 'Creator',
      field: 'updated_by',
      filter: 'agSetColumnFilter',
      minWidth: 160,
    },
    {
      headerName: 'Date',
      field: 'updated_at',
      filter: 'agDateColumnFilter',
      maxWidth: 150,
      minWidth: 150,
      filterParams: dateFilterParams,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
            params: {},
          };
        }
        return undefined;
      },
    },

    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 100,
      maxWidth: 100,
      suppressMenu: true,
      suppressSorting: true,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];

  const handleView = async (e) => {};

  const handleEdit = async (e) => {
    setIsOpenEditVRModel(true);
    setNodeEditVRModel(e);
  };
  const handleDelete = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setVRConnectorLoading(true);
    try {
      await AppAPI.VDCVRAPI.DeleteVRModel({ id: e.data.id });
      await updateTableAsync(gridApiVRModel.api, { remove: [e.data] });
    } catch (ex) {
      message.warning(ex.message);
    }
    setVRConnectorLoading(false);
  };
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon='view'
          size='sssmall'
          type='save'
          onClick={handleView.bind(this, params)}
        />

        <IconButton
          icon='edit'
          size='sssmall'
          type='edit'
          onClick={handleEdit.bind(this, params)}
        />
        <IconButton
          icon='delete'
          size='sssmall'
          type='delete'
          onClick={handleDelete.bind(this, params)}
        />
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    setGridApiVRModel(params);
  }, []);
  return (
    <>
      <div style={{ width: '100%', height: '100%', padding: 5 }}>
        <div
          style={{ width: '100%', height: '100%' }}
          className='ag-theme-alpine'
        >
          <AgGridReact
            ref={gridRef}
            rowData={vrModelData}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows={true}
            onGridReady={onGridReady}
            groupDisplayType='multipleColumns'
            // rowGroupPanelShow='always'
            rowHeight={35}
            // pagination={true}
            // paginationPageSize={pageSizeModuleList}
            suppressPaginationPanel={true}
            context={false}
            suppressContextMenu={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
}
