import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Tree, Input, Button } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { appStore } from '../../../../../../store/App.store';
import { projectStore } from '../../../../../../store/Docs.store';
import { } from 'antd/lib/tree-select';
import { FolderFilled, FolderOutlined, FolderOpenOutlined, DownOutlined } from '@ant-design/icons';
import { requestBimGrid } from '../../../../../../functions/General.function';
import ProjectTree from '../tree/Project.tree';


const treeType = {
    'folder': (d) => {
        return (!d.expanded ? <FolderOutlined /> : <FolderOpenOutlined />)
    }
}

export default function ProjectSidebar(props) {
    const navigate = useNavigate()
    const [search,] = useSearchParams();
    const { setIsLoading } = appStore()
    const { setFolder } = projectStore()
    const type = search.get('type') ? search.get('type') : '3d'
    const { organizationId, projectId } = useParams()
    const [treeData, setTreeData] = useState([]);
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        let data = await requestBimGrid('get', '/api/folder/getByProjectType', null,
            { organizationId, projectId, type })
        if (data) {
            _.forEach(data, v => {
                if (!v.isLeaf) {
                    v.icon = treeType.folder
                    v.title = <ProjectTree
                        item={v}
                        projectId={projectId}
                        addFolder={handleAddFolder}
                    />
                    v.className = 'idd-tree-node'
                }
            })
            setTreeData(data)
        }
        setIsLoading(false)
    }
    const onLoadData = ({ key, children }) =>
        new Promise(async (resolve) => {
            if (children) {
                resolve();
                return;
            }
            let data = await requestBimGrid('get', '/api/folder/getFolderChild', null, { organizationId, projectId, id: key })
            if (data) {
                _.forEach(data, v => {
                    if (!v.isLeaf) {
                        v.icon = treeType.folder
                        v.title = <ProjectTree
                            item={v}
                            projectId={projectId}
                            addFolder={handleAddFolder}
                        />
                        v.className = 'idd-tree-node'
                    }
                })
                setTreeData((origin) =>
                    updateTreeData(origin, key, data),
                );
                resolve();
            }

        });

    const handleAddFolder = async (projectId, parentId = null) => {
        let name = window.prompt('Please fill your folder name', 'New folder')
        if (!name) return
        const temp = {
            name, project_id: projectId, type, parent_id: parentId, is_file: false
        }
        let data = await requestBimGrid('post', '/api/folder', temp, { organizationId, projectId })
        if (data) {
            navigate(0)
        }
    }
    const handleExpand = async (keys, e) => {
        console.log(e.node)
        // if (e.expanded) {
        //     e.node.loading =true
        //     setTreeData([...treeData])
        //     // setLoadedKeys([...loadedKeys, e.node.key])
        //     let data = await requestBimGrid('get', '/api/folder/getFolderChild', null, { id: e.node.key ,organizationId ,projectId})
        //     if (data) {
        //         // let nodes= updateTreeData(treeData, e.node.key, data)
        //         // console.log(nodes)
        //         // setTreeData({...nodes})
        //         // setTreeData((origin) =>
        //         //     updateTreeData(origin, e.node.key, data)
        //         // )
        //         // let clone = [...loadedKeys]
        //         //     let index = _.findIndex(clone, v => { return v === e.node.key })
        //         //     if (index >= 0) {
        //         //         clone.splice(index, 1)
        //         //         setLoadedKeys(clone)
        //         //     }
        //     }
        // }
    }
    const updateTreeData = (list, key, children) => {
        return list.map((node) => {
            if (node.key === key) {
                return {
                    ...node,
                    children,
                    isLeaf: false
                };
            }
            if (node.children) {
                return {
                    ...node,
                    children: updateTreeData(node.children, key, children),
                    isLeaf: false
                };
            }
            return node;
        });
    }
    const handleSelect = async (keys, e) => {
        if (e.selected) {
            setFolder(e.node)
        }
    }


    return (
        <div style={{ width: '100%', height: '100%', padding: 2, display: 'flex', flexDirection: 'column', gap: 5 }} >
            <div style={{ display: 'flex', gap: 2, alignItems: 'center' }} >
                <Input.Search className='idd-input' placeholder="Search" />
                <Button className={`idd-custom-button save`} icon={
                    <FolderFilled />
                } onClick={handleAddFolder.bind(this, projectId, null)}  >Add</Button>
            </div>
            <div>
                <Tree
                    blockNode
                    showLine={true}
                    showIcon={true}
                    // defaultExpandedKeys={['0-0-0']}
                    // onSelect={onSelect}
                    treeData={treeData}
                    loadData={onLoadData}
                    onSelect={handleSelect}
                    switcherIcon={<DownOutlined />}
                // loadedKeys={loadedKeys}
                // onExpand={handleExpand}
                >

                </Tree>
            </div>

        </div>

    );
}
