import _ from "lodash";
import { getHitPoint, pointerToRaycaster } from "../../../../functions/Viewer.function";
import { EventDispatcher } from "../extensions/utils/EventDispatcher";
import MarkupPoint from "./MarkupPoint.util";



const Autodesk = window.Autodesk
const avp = Autodesk.Viewing.Private;
const THREE = window.THREE
export default class CoordinationPoint extends MarkupPoint {

    colors = {
        'Category 1': new THREE.Color('#FFD966'),
        'Category 2': new THREE.Color('orange'),
        'Category 3': new THREE.Color('red'),
    }
    // color = new THREE.Color('#FF9900')
    colorNew = new THREE.Color('red')
    viewer = null


    constructor(viewer, name = 'coordination') {
        super(viewer, name)
        this.viewer = viewer
    }

    active = async () => {
        this.viewer.canvas.addEventListener('mousemove', this.eventMoveCoordination);
        this.load()
    }
    dispose = () => {
        this.viewer?.canvas.removeEventListener('pointerdown', this.eventAddNewCoordination);
        this.viewer?.canvas.removeEventListener('mousemove', this.eventMoveCoordination,);
        this.unload()
    }


    eventMoveCoordination = (event) => {
        this.hoverPoint(event);
    }

    eventAddNewCoordination = (event) => {
        this.addNewPoint(event)
    }

    initMesh_PointCloud = () => { //!note: override method
        this.viewer.impl.removeOverlayScene(this.name);
        this.viewer.impl.createOverlayScene(this.name);
        let position = []
        let color = []
        let sizeAp = []
        this.geometry = new THREE.Geometry();
        this.bufferGeometry = new THREE.BufferGeometry();
        this.markupItems.forEach((item, k) => {
            item.positions.forEach(i => {
                const point = (new THREE.Vector3(i.x, i.y, i.z));
                this.geometry.vertices.push(point);
                this.geometry.colors.push(this.colors[item.colorName]);
                position.push(i.x, i.y, i.z);
                color.push(this.colors[item.colorName].r, this.colors[item.colorName].g, this.colors[item.colorName].b)
                sizeAp.push(0.1, 1)
            })
        });
        this.pointCloud = new THREE.PointCloud(this.geometry, this.material);
        // this.pointCloud.position.copy(new THREE.Vector3(-this.offset.x, -this.offset.y, -this.offset.z))
        let positionAttribute = new THREE.BufferAttribute(new Float32Array(position), 3);
        let colorAttribute = new THREE.BufferAttribute(new Float32Array(color), 3);
        let sizeApAttribute = new THREE.BufferAttribute(new Float32Array(sizeAp), 2);
        this.bufferGeometry.setAttribute("position", positionAttribute);
        this.bufferGeometry.setAttribute("color", colorAttribute);
        this.bufferGeometry.setAttribute("sizeAp", sizeApAttribute);
        this.bufferGeometry.isPoints = true;
        this.pointCloudVis = new THREE.Mesh(this.bufferGeometry, this.material);
        // this.pointCloudVis.position.copy(new THREE.Vector3(-this.offset.x, -this.offset.y, -this.offset.z))
        this.viewer.impl.addOverlay(this.name, this.pointCloudVis);

    }


}

export const exportMarkupImage = async (viewer) => {
    return new Promise(resolve => {
        const markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
        const screenshot = new Image();
        screenshot.src = viewer.canvas.toDataURL("image/png");
        screenshot.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = viewer.canvas.clientWidth;
            canvas.height = viewer.canvas.clientHeight;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);
            try {
                markupCore.renderToCanvas(ctx,
                    async () => {
                        const blob = await new Promise(resolve => resolve(canvas.toDataURL()));
                        resolve(blob)
                    })
            } catch {
                resolve(screenshot.src)
            }

        }
    })
}

export const exportMarkup = async (viewer) => {
    return new Promise(resolve => {
        const markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
        try {
            const mark = markupCore.generateData()
            resolve(mark)
        } catch {
            resolve()
        }
    })
}

