import React, { useEffect, useState } from 'react';

export default function RFARefCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [name, setName] = useState('');
  useEffect(() => {
    debugger;
    if (cellValue) {
      const name = `${props.node.data.category}/${props.node.data.discipline}/${
        props.node.data.ref_number
      } : ${props.node.data.current_rev || 0}`;
      setName(name);
    }
  }, [cellValue]);
  return <>{name}</>;
}
