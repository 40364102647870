import React, { } from 'react';
import { } from 'antd/lib/tree-select';
import { FolderAddOutlined, DeleteOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons';
import { requestBimGrid } from '../../../../../../functions/General.function';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '../../../../../gen/button/IconButton.gen';


export default function ProjectTree({ item, projectId, addFolder }) {
    const navigate = useNavigate()
    const { organizationId } = useParams()
    const handleAddFolder = (e) => {
        console.log(item)
        addFolder(projectId, item.key)
        e.preventDefault()
        e.stopPropagation()
    }
    const editFolder = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        let name = window.prompt('Please fill folder rename', item.name)
        if (!name) return
        const temp = {
            id: item.key, name
        }
        let data = await requestBimGrid('put', '/api/folder', temp, { organizationId, projectId })
        if (data) {
            navigate(0)
        }
    }
    return (
        // <Popover placement="topLeft" title={e.title} content={menu} trigger="click">

        // </Popover>
        <div style={{ display: 'flex', justifyContent: 'space-between', }} >
            <span>{item.name}</span>
            <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <IconButton
                    size='sssmall'
                    icon={'add_folder'}
                    // type='regular'
                    // border={false}
                    onClick={handleAddFolder}
                />
                <IconButton
                    size='sssmall'
                    icon={'edit'}
                    // type='edit'
                    // border={false}
                    onClick={handleAddFolder}
                />
                <IconButton
                    size='sssmall'
                    icon={'delete'}
                    type='delete'
                    // border={false}
                    onClick={handleAddFolder}
                />
                {/* <MoreOutlined /> */}
            </div>
        </div>
    )
}
