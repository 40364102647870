import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Project3DSectionDesktop from '../../../three_d/desktop/Project3DSection.desktop';

export default function ProjectNav(props) {
  const [search, setSearchParams] = useSearchParams();
  const [type, setType] = useState(
    search.get('type') ? search.get('type') : '3d'
  );

  const handleChangeTab = (e) => {
    const newQueryParameters = new URLSearchParams();
    newQueryParameters.set('type', e);
    setSearchParams(newQueryParameters);
    setType(e);
  };

  return (
    <div
      style={{ width: '100%', padding: '0px 0px', height: 'calc(100% - 0px)' }}
    >
      <Project3DSectionDesktop />
    </div>
  );
}
