import _ from 'lodash';
import create from 'zustand';

const initialState = {
  vrConnectorLoading: false,
  isAddNewVRModel: false,
  isOpenEditVRModel: false,

  vrModelData: [],
  gridApiVRModel: null,
  nodeEditVRModel: null,
};
export const vrConnectorStore = create((set) => ({
  ...initialState,

  setVRConnectorLoading: (value) =>
    set((state) => ({ vrConnectorLoading: value })),
  setIsAddNewVRModel: (value) => set((state) => ({ isAddNewVRModel: value })),
  setIsOpenEditVRModel: (value) =>
    set((state) => ({ isOpenEditVRModel: value })),

  setVRModelData: (value) => set((state) => ({ vrModelData: value })),
  setGridApiVRModel: (value) => set((state) => ({ gridApiVRModel: value })),
  setNodeEditVRModel: (value) => set((state) => ({ nodeEditVRModel: value })),
  resetVRConnectorStore: () => set(initialState),
}));
