/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import SubmitForm from '../components/SubmitForm';
import { AppAPI } from '../../../../../api';
import { snakeToCamelCase } from '../../../../../../functions/General.function';




export default function ModalDMSRFAViewRFA() {
    const { setIsModalDMSRFViewRFA, viewDMSRFANode } = dmsStore()
    const { setIsLoading, } = appStore()
    const [data, setData] = useState({
        category: '',
        discipline: '',
        refNumber: '',
        submissionDate: null,
        dueDate: null,
        requestedBy: '',
        subject: '',
        content: '',
        dmsGroupConsultantIds: []
    })



    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            debugger
            const rfaData = await AppAPI.DMSRFAAPI.GetDMSRFAOne({ id: viewDMSRFANode.data.id })
            const tmp = {}
            _.forEach(rfaData, (v, k) => {
                const name = snakeToCamelCase(k)
                tmp[name] = v
            })
            setData(tmp)
        } catch (ex) {
            if (ex.message)
                message.warning(ex.message)
        }
        setIsLoading(false)
    }
    const close = () => {
        setIsModalDMSRFViewRFA()
    }



    return (
        <>
            <ModalTemplate
                onClose={close}
                title={'View RFA'}
                style={{ width: '80%' }}
                footer={false}
            >
                <div>
                    <SubmitForm data={data} setData={setData} isUpload={false} isView={true} />
                </div>

            </ModalTemplate >
        </>
    );
}

