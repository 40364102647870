import React from "react";
import IconButton from "../../../../../gen/button/IconButton.gen"
import { appStore } from "../../../../../../store/App.store";
import { dmsStore } from "../../../../../../store/DMS.store";

import { message } from 'antd'
import { getRouteToNode } from "../../../../../../functions/General.function";
import { useParams } from "react-router-dom";
import { AppAPI } from "../../../../../api/index";
const ActionButtonCell = (params) => {
    const { setIsLoading } = appStore()
    const { setAddDMSMainParentNode, setIsModalDMSMainAddFolder,
        setIsModalDMSMainAddDrawing, dmsMainGridApi,
        setUpdateDMSMainNode,
        setIsModalDMSMainEditFolder, setIsModalDMSMainEditDrawing } = dmsStore()
    const handleAddFolder = () => {
        setIsModalDMSMainAddFolder(true)
        setAddDMSMainParentNode(params.node)
    }
    const handleAddFile = () => {
        setIsModalDMSMainAddDrawing(true)
        setAddDMSMainParentNode(params.node)
    }
    const handleDeleteItem = async () => {
        if (!window.confirm('Are you sure to delete?')) return
        setIsLoading(true)
        try {
            await AppAPI.DMSMainAPI.DeleteDMSMainOne({ path: params.node.data.path })
            const route = getRouteToNode(params.node);
            dmsMainGridApi.api.applyServerSideTransaction({
                route: route.slice(0, route.length - 1),
                remove: [params.node.data],
            });
        } catch (ex) {
            message.warning(ex.message)
        }
        setIsLoading(false)

    }
    const handleEditItem = () => {
        if (params.node.data.is_folder) {
            setIsModalDMSMainEditFolder(true)
        } else {
            setIsModalDMSMainEditDrawing(true)
        }
        setUpdateDMSMainNode(params.node)
    }

    // const handleViewItem = async () => {

    //     const split = params.node.data.name?.toLowerCase().split('.')
    //     const fileExt = split[split.length - 1]
    //     if (fileExt === 'pdf' || fileExt === 'dwf' || fileExt === 'dwfx') {
    //         const url = `${window.location.origin}/organization/${organizationId}/project/${projectId}/dms/viewer?fileId=${params.node.data.id}`;
    //         window.open(url)
    //     } else {
    //         message.warning('Only pdf, dwf and dwfx are able to view')
    //     }

    // }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center', height: '100%' }}>
            {params.node.data.is_folder &&
                <>
                    <IconButton
                        icon={'add_folder'}
                        size='sssmall'
                        // type={'save'}
                        iconStyle={{ background: '#000000' }}
                        onClick={handleAddFolder.bind(this, params)}
                    />
                    <IconButton
                        icon={'add_file'}
                        size='sssmall'
                        // type={'save'}
                        iconStyle={{ background: '#000000' }}
                        onClick={handleAddFile.bind(this, params)}
                    />

                </>

            }
            <IconButton
                icon={'edit'}
                size='sssmall'
                // type={'edit'}
                iconStyle={{ background: '#000000' }}
                onClick={handleEditItem.bind(this, params)}
            />
            {/* {!params.node.data.is_folder &&
                <IconButton
                    icon={'view'}
                    size='sssmall'
                    iconStyle={{ background: '#000000' }}
                    onClick={handleViewItem.bind(this, params)}
                />
            } */}


            <IconButton
                icon={'delete'}
                size='sssmall'
                type={'delete'}
                iconStyle={{ background: '#000000' }}
                onClick={handleDeleteItem.bind(this, params)}
            />
        </div>
    )
}

export default ActionButtonCell