/* eslint-disable no-unused-vars */
import { DatePicker, Form, Input, message } from 'antd';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import Modal from 'src/components/molecules/Modal';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default function ModalEditOrganization({
  setRowNode,
  rowNode,
  gridApi,
}) {
  const [form] = Form.useForm();
  const { organizationId, projectId } = useParams();
  const { setIsLoading, user } = appStore();
  const close = () => {
    setRowNode();
  };

  const handleOk = async (e) => {
    form
      .validateFields()
      .then(async (values) => {
        setIsLoading(true);
        _.forEach(values, (v, k) => {
          rowNode.data[k] = values[k];
        });
        let resData = await requestBimGrid(
          'put',
          '/api/admin/organization',
          rowNode.data,
          { organizationId, projectId }
        );
        if (resData) {
          await updateTableAsync(gridApi.api, { update: [resData] });
          gridApi.api.redrawRows();
          close();
        }

        setIsLoading(false);
      })
      .catch((ex) => {
        message.error(ex);
      });
  };

  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Edit Organization'}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout='vertical'
          className='idd-form'
          autoComplete='off'
          style={{ width: '100vh', maxWidth: 400 }}
        >
          <div>
            <Form.Item
              label='Organization Name'
              name='name'
              rules={[{ required: true }]}
              initialValue={rowNode.data.name}
            >
              <Input placeholder='Enter organization name' />
            </Form.Item>

            <Form.Item
              label='Organization Email'
              name='email'
              initialValue={rowNode.data.email}
            >
              <Input placeholder='Enter email' />
            </Form.Item>

            <Form.Item
              label='Organization Phone'
              name='phone'
              initialValue={rowNode.data.phone}
            >
              <Input type='number' placeholder='Enter phone number' />
            </Form.Item>

            <Form.Item
              label='Organization Country'
              name='country'
              initialValue={rowNode.data.country}
            >
              <Input placeholder='Enter organization country' />
            </Form.Item>

            <Form.Item
              label='Organization Address'
              name='address'
              initialValue={rowNode.data.address}
            >
              <Input placeholder='Enter organization address' />
            </Form.Item>

            <Form.Item
              label='Organization City'
              name='city'
              initialValue={rowNode.data.city}
            >
              <Input placeholder='Enter organization city' />
            </Form.Item>

            <Form.Item
              label='Organization Postal Code'
              name='postal_code'
              initialValue={rowNode.data.postal_code}
            >
              <Input placeholder='Enter organization postal code' />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}
