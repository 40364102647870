import { Outlet } from 'react-router-dom';
import AlertPermissionPopup from 'src/components/molecules/AlertPermissionPopup';
import TopNav from 'src/components/organisms/TopNav';
// import TopNav from 'src/components/navigation/TopNavigation';

export default function AuthRoute() {
  return (
    <>
      <AlertPermissionPopup />
      <TopNav />

      <div className='h-[calc(100%-61px)] overflow-auto bg-white dark:bg-gray-900'>
        <Outlet />
      </div>
    </>
  );
}
