import { useEffect, useState } from 'react';
import { ReactComponent as PlusCircleIcon } from 'src/assets/icons/general/plus-circle.svg';
import PageHeader from 'src/components/atoms/PageHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { USER_PLAN } from 'src/config';
import { requestBimGrid } from '../../../../functions/General.function';
import { appStore } from '../../../../store/App.store';
import ModalAddOrganization from './modal/ModalAddOrganization';
import ModalEditOrganization from './modal/ModalEditOrganization';
import OrganizationTable from './Organization.table';

export default function LayoutOrganization() {
  const { setIsLoading, user } = appStore();
  const [data, setData] = useState([]);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [rowNode, setRowNode] = useState();
  const [gridApi, setGridApi] = useState();

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid(
      'get',
      '/api/organization/getAllByUserId',
      null
    );
    if (data) {
      setData(data);
    }
    setIsLoading(false);
  };
  const handleOpenAdd = () => {
    setIsModalAdd(true);
  };

  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader>Organization List</PageHeader>
          </>
        }
        right={
          user.plan !== USER_PLAN.NoPlan && (
            <PrimaryButton onClick={handleOpenAdd}>
              <PlusCircleIcon className='size-4' />
              Add
            </PrimaryButton>
          )
        }
      />

      <OrganizationTable
        data={data}
        setGridApi={setGridApi}
        setRowNode={setRowNode}
      />

      {isModalAdd && (
        <ModalAddOrganization gridApi={gridApi} setClose={setIsModalAdd} />
      )}
      {rowNode && (
        <ModalEditOrganization
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
