/* eslint-disable no-unused-vars */
import { message } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WorkerTimeline from 'src/components/layout/apps/worker/components/WorkerTimeline';
import { viewerStore } from '../../../../../store/Viewer.store';
import { workerStore } from '../../../../../store/Worker.store';
import { AppAPI } from '../../../../api';
import TrackingDevices from '../../../viewer/util/TrackingDevices.util';

export default function WorkerTrackingDeviceList({
  workerDevice,
  setWorkerDevice,
  node,
}) {
  const {
    dailyDate,
    nodeEditWorkerDaily,
    setTrackingDevices,
    trackingDevices,
  } = workerStore();
  const { viewer } = viewerStore();
  const { projectId } = useParams();

  useEffect(() => {
    const runLocal = async () => {
      try {
        const resWorkerTracking =
          await AppAPI.WorkerDailyAPI.GetWorkerTrackingDevicesByBlock({
            projectId,
            blockId: nodeEditWorkerDaily.data.block_id,
            date: node.data.date ?? dailyDate,
          });
        setWorkerDevice(resWorkerTracking);
        const trackingDevices = new TrackingDevices(viewer);
        await trackingDevices.init(resWorkerTracking);
        setTrackingDevices(trackingDevices);
        // setTimeout(() => {
        //   setTrackingDevices({ ...trackingDevices });
        // }, 60000);
      } catch (e) {
        if (e.message) message.warning(e.message);
      }
    };
    viewer.waitForLoadDone().then((res) => {
      runLocal();
    });
  }, []);

  return (
    <div className='flex size-full h-[300px] flex-col overflow-auto'>
      {workerDevice?.map((v, k) => (
        <div className='flex w-full content-center items-center justify-between'>
          <WorkerTimeline index={k} item={v} node={node} />
        </div>
      ))}
    </div>
  );
}
