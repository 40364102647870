/**
 * @param {string} name
 * @param {boolean} isRequired
 * @param {boolean} [defaultChecked]
 * @returns {JSX.Element}
 */
const CheckboxForm = ({ name, isRequired, defaultChecked }) => {
  return (
    <input
      type='checkbox'
      name={name}
      required={isRequired}
      defaultChecked={defaultChecked}
      className='focus:ring-3 h-4 w-4 rounded border border-gray-300 bg-gray-50 focus:ring-primary-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600'
    />
  );
};

export default CheckboxForm;
