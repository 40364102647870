import { io } from 'socket.io-client';
// import { browserDetection, getOperatorSystem } from './DetectSystem.fuction';
// import isMobile from 'is-mobile';
// import axios from 'axios';
const URL = window.location.host.includes('localhost')
  ? 'http://localhost:9002'
  : window.location.origin;

export const socket = io(URL);

export const trackingUser = (email) => {
  try {
    socket.on('message', function (message) {
      localStorage.setItem('bimgridapp-socketid', message);
    });
    // axios
    //   .get('https://ipapi.co/json/')
    //   .then(function (data) {
    //     socket.emit('client-send-username', {
    //       email: email,
    //       isMobile: isMobile(),
    //       browser: browserDetection(),
    //       country: data.country.toLowerCase(),
    //       IP: data.ip,
    //       system: getOperatorSystem(),
    //       location: [],
    //     });
    //   })
    //   .catch(function () {
    //     socket.emit('client-send-username', {
    //       email: email,
    //       isMobile: isMobile(),
    //       browser: browserDetection(),
    //       country: '',
    //       IP: '0.0.0.0',
    //       system: getOperatorSystem(),
    //       location: [],
    //     });
    //   });
  } catch (ex) {
    console.log(ex);
  }
};
