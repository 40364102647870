import { useEffect } from 'react';
import { getForgeTokenViewer } from '../../../functions/Auth.function';
import { viewerStore } from '../../../store/Viewer.store';
import { TreeModelData } from './util/ExtractQuantity.util';
import { viewerOptions } from './Viewer.constant';
// import TransformExtension from "./extensions/transform/TransformExtension";

const Autodesk = window.Autodesk;

export default function ViewerForge({
  fileId,
  versionId,
  objectId,
  name,
  viewId,
}) {
  const {
    viewer,
    setViewer,
    setViewerLoading,
    setAecModelData,
    setIs3D,
    setTreeModalData,
    treeModelData,
  } = viewerStore();

  useEffect(() => {
    const treeModelDataLocal = new TreeModelData();
    setTreeModalData(treeModelDataLocal);
    return () => {
      treeModelDataLocal.destroy();
      setTreeModalData();
    };
  }, []);
  //'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6emhqdDFzeTFodWh0Z3B0bHhpeWZwc2lzbjdkZm5jeGctYzF0ZXN0L0NsYWRpbmclMjBNb2RlbC1Ob3J0aCtFYXN0K1dlc3QucnZ0'//search.get("urn")
  useEffect(() => {
    if (treeModelData) {
      console.log('init viewer');
      window.devicePixelRatio = 1;
      launchViewer();
    }
  }, [treeModelData]);
  useEffect(() => {
    return () => {
      if (viewer) {
        console.log('clear');
        viewer.tearDown();
        viewer.finish();

        setViewer(null);
      }
    };
  }, [viewer]);

  const launchViewer = () => {
    var options = {
      env: 'AutodeskProduction',
      useConsolidation: true,
      useADP: false,
      getAccessToken: getForgeTokenViewer,
    };

    Autodesk.Viewing.Initializer(options, () => {
      let viewer = new Autodesk.Viewing.GuiViewer3D(
        document.getElementById('forgeViewer'),
        {
          extensions: [
            'Autodesk.DocumentBrowser',
            // "Autodesk.AEC.LevelsExtension",
            'Autodesk.AEC.Minimap3DExtension',
            'Autodesk.AEC.Hypermodeling',
            'Autodesk.Viewing.MarkupsCore',
            'Autodesk.Viewing.MarkupsGui',
          ],
        }
      );
      setViewer(viewer);
    });
  };
  useEffect(() => {
    if (viewer) {
      setViewerLoading(true);
      viewer.start();
      viewer.waitForLoadDone().then((res) => {
        setViewerLoading(false);
      });
      const documentId = 'urn:' + btoa(objectId);
      viewer.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        eventGeometryLoaded
      );
      viewer.addEventListener(
        Autodesk.Viewing.OBJECT_TREE_LOAD_PROGRESS_EVENT,
        eventTreeProgress
      );
      viewer.addEventListener(
        Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
        eventAddModel
      );
      viewer.addEventListener(
        Autodesk.Viewing.MODEL_REMOVED_EVENT,
        eventRemoveModel
      );
      // unloadForgeExtension(viewer)
      Autodesk.Viewing.Document.load(
        documentId,
        onDocumentLoadSuccess.bind(this),
        onDocumentLoadFailure.bind(this)
      );
      return () => {
        viewer?.removeEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          eventGeometryLoaded
        );
        viewer?.removeEventListener(
          Autodesk.Viewing.OBJECT_TREE_LOAD_PROGRESS_EVENT,
          eventTreeProgress
        );
        viewer?.removeEventListener(
          Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
          eventAddModel
        );
        viewer?.removeEventListener(
          Autodesk.Viewing.MODEL_REMOVED_EVENT,
          eventRemoveModel
        );
      };
    }
  }, [viewer]);

  const onDocumentLoadSuccess = async (doc) => {
    const aecModelData = await doc.downloadAecModelData();
    // console.log(aecModelData)
    setAecModelData(aecModelData);
    let viewables = doc.getRoot().getDefaultGeometry();
    if (viewId) {
      const views = doc
        .getRoot()
        .search(
          { type: 'geometry', role: '3d', progress: 'complete', guid: viewId },
          true
        );
      if (views.length !== 0) {
        viewables = views[0];
      }
    }
    setIs3D(viewables.data.role === '3d');
    // viewer.waitForLoadDone()
    //   .then(async () => {
    //     const models = viewer.impl.modelQueue().getModels();
    //     treeModelData.init(models)
    //   })
    viewer
      .loadDocumentNode(doc, viewables, {
        ...viewerOptions,
        ...{
          fileId,
          versionId,
          modelNameOverride: name,
          aecModelData: aecModelData,
        },
      })
      .then((i) => {});
  };

  const eventTreeProgress = () => {
    setViewerLoading(true);
  };
  const eventGeometryLoaded = (e) => {
    setIs3D(e.model.is3d());
    viewer.waitForLoadDone().then(async () => {
      // viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, eventExtensionLoaded);
      // viewer.loadExtension(SelectFaceExtension)
      viewer.loadExtension('Autodesk.ViewCubeUi').then(function (item) {
        item.showTriad(true);
      });
      const markupCore = await viewer.getExtension(
        'Autodesk.Viewing.MarkupsCore'
      );
      markupCore.addEventListener(
        Autodesk.Viewing.Extensions.Markups.Core.EVENT_EDITMODE_ENTER,
        (e) => console.log('enter')
      );
      markupCore.addEventListener(
        Autodesk.Viewing.Extensions.Markups.Core.EVENT_EDITMODE_LEAVE,
        (e) => console.log('exit')
      );
      setViewerLoading(false);
      // if(viewer.impl.model.is3d())
      // viewer.loadExtension(TransformExtension)
    });
  };
  const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
      'onDocumentLoadFailure() - errorCode:' +
        viewerErrorCode +
        '\n- errorMessage:' +
        viewerErrorMsg
    );
  };
  const eventAddModel = (e) => {
    viewer.waitForLoadDone().then(async () => {
      const models = viewer.impl.modelQueue().getModels();
      treeModelData?.add(models);
    });
  };
  const eventRemoveModel = (e) => {
    const models = viewer.impl.modelQueue().getModels();
    treeModelData?.remove(models);
  };

  return (
    <>
      <div
        style={{ position: 'relative', width: '100%', height: '100%' }}
        id='forgeViewer'
      ></div>
    </>
  );
}
