import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import IssueCard from './card/Issue.card';
import { requestBimGrid } from '../../../../../functions/General.function';
import { issueStore } from './Issue.store';

export default function IssueList() {
  const [search] = useSearchParams();
  const { setIssueLoading, setIssueData, issueData, setType } = issueStore();
  const { organizationId, projectId } = useParams();
  const fileId = search.get('fileId');
  // useEffect(() => {
  //     run()
  // }, [])

  // const run = async () => {
  //     setIssueLoading(true)
  //     let data = await requestBimGrid('get', '/api/issue/getByFileId', null, { organizationId, projectId, fileId })
  //     if (data) {
  //         setIssueData(data)
  //         let temp =[]
  //         data.forEach(v => {
  //             if (v.status === 'Open'){
  //                 v.colorName = v.category
  //                 temp.push(v)
  //             }
  //         })
  //         coordinationPoint.setMarkupData(temp)
  //     }
  //     setIssueLoading(false)
  // }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div className='idd-list' style={{ height: '100%' }}>
          {issueData.map((i, idx) => (
            <IssueCard key={idx} data={i} />
          ))}
        </div>
      </div>
    </>
  );
}
