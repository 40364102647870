import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TextButton from '../../../gen/button/TextButton.gen';
import IconButton from '../../../gen/button/IconButton.gen';

export default function DMSSidebar() {
    const navigate = useNavigate()
    const location = useLocation()
    const handleChange = (e) => {
        navigate(e)
    }

    return (
        <div
            className='idd-sidebar-dms'
        >
            <div
                style={{
                    display: 'flex',
                    gap: 5,
                    flexDirection: 'column'
                }}
            >
                <TextButton size='sssmall' type='save' active={location.pathname.includes('/dms/main')}
                    onClick={handleChange.bind(this, 'main')} >
                    DMS
                </TextButton>
                <p />
                <TextButton size='sssmall' type='save'
                    active={location.pathname.includes('/dms/sum')}
                    onClick={handleChange.bind(this, 'sum')}>
                    SUM
                </TextButton>
                <TextButton size='sssmall' type='save'
                    active={location.pathname.includes('/dms/rfa')}
                    onClick={handleChange.bind(this, 'rfa')}>
                    RFA
                </TextButton>
                <TextButton size='sssmall' type='save'
                    active={location.pathname.includes('/dms/doc')}
                    onClick={handleChange.bind(this, 'doc')}>
                    DOC
                </TextButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: 5,
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <IconButton icon='setting' size='small' type='save' border={false}
                    active={location.pathname.includes('/dms/setting')}
                    onClick={handleChange.bind(this, 'setting')}
                />

            </div>
        </div>
    );
}
