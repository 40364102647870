import React, { useEffect, useRef, useState, } from 'react';
import _ from 'lodash';
import ExtractQuantityGanttToolbar from './COBie.toolbar';
import { cobieStore } from './COBie.store';
import { Tabs } from 'antd';
import { COBieColumnTable } from './constants/COBieColumnTable';
import COBieSpace from './components/COBieSpace';
import COBieType from './components/COBieDoor';
import COBieFloor from './components/COBieFloor';
import COBieDoor from './components/COBieDoor';
import COBieGeneral from './components/COBieGeneral';


export default function ExtractQuantityGantt() {
  const [activeKey, setActiveKey] = useState('1')
  const [items, setItems] = useState([]);
  const { } = cobieStore()
  const newTabIndex = useRef(0);

  const handleChange = (e) => {
    setActiveKey(e)
  }

  const add = () => {
    let name = window.prompt('Please fill your tab name', 'New Tab')
    if (!name) return
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...items];
    newPanes.push({
      label: name,
      key: newActiveKey,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };
  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };
  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };
  return (
    <>
      {/* <ExtractQuantityGanttToolbar /> */}
      <div style={{ height: 'calc(100% - 0px)' }} >
        <div style={{ height: '100%', width: "100%" }}  >
          <Tabs className='idd-custom-tabs cobie' activeKey={activeKey} type="editable-card" item
            onChange={handleChange} onEdit={onEdit}  >
            <Tabs.TabPane tab="Space" key="Space" closable={false} >
              {activeKey === 'Space' && <COBieSpace />}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Floor" key="Floor" closable={false} >
              {activeKey === 'Floor' && <COBieFloor />}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Door" key="Door" closable={false} >
              {activeKey === 'Door' && <COBieDoor />}
            </Tabs.TabPane>
            {items.map((v,k) =>
              <Tabs.TabPane tab={v.label} key={v.key} >
                {activeKey === v.key && <COBieGeneral />}
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </div>


    </>

  );
}

