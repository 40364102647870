import { requestBimGrid } from '../../../functions/General.function';

export const GetAllCompanyByOrganizationId = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data =
                await requestBimGrid('get', '/api/admin/company/getAllByOrganizationId', null)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const GetAllUserInOrganization = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data =
                await requestBimGrid('get', '/api/admin/organization/getAllUserInOrganization',
                    null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};