import React from "react";
import QRShareElementLayout from "../../../../layout/share/apps/three_d/qr_element/QRShareElement.layout";

function QRElementPage() {


  return (
    <>
      <QRShareElementLayout />

    </>

  );
}

export default (QRElementPage);
