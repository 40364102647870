import React from 'react';
import { message } from 'antd';
import { appStore } from '../../../../../store/App.store';
import IconButton from '../../../../gen/button/IconButton.gen';
import { fiveDStore } from '../../../../../store/FiveD.store';
import { AppAPI } from '../../../../api';
import { updateTableAsync } from '../../../../../functions/General.function';
const ActionButtonCell = (params) => {
  const { setIsLoading } = appStore();
  const { setSelectedRow, setIsOpenEditRow, sheetGridApi } = fiveDStore();

  const handleEditItem = () => {
    setSelectedRow(params.node);
    setIsOpenEditRow(true);
  };

  const handleViewItem = async () => {};

  const handleDelete = async () => {
    if (!window.confirm('Are you sure to delete?')) return;
    try {
      debugger;
      setIsLoading(true);
      await AppAPI.FiveDSheetAPI.DeleteSheetData({
        id: params.node.data.id,
      });
      await updateTableAsync(sheetGridApi.api, {
        remove: [params.node.data],
      });
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        alignItems: 'center',
        height: '100%',
      }}
    >
      <IconButton
        icon={'view'}
        size='sssmall'
        iconStyle={{ background: '#000000' }}
        onClick={handleViewItem.bind(this, params)}
      />
      <IconButton
        icon={'edit'}
        size='sssmall'
        // type={'edit'}
        iconStyle={{ background: '#000000' }}
        onClick={handleEditItem.bind(this, params)}
      />

      <IconButton
        type='delete'
        icon='delete'
        size='ssmall'
        onClick={handleDelete.bind(this, params)}
      />
    </div>
  );
};

export default ActionButtonCell;
