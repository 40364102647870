import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { MeAPI } from 'src/api';
import { resetAll } from 'src/functions/General.function';
import { trackingUser } from 'src/functions/Reattime.function';
import { appStore } from 'src/store/App.store';
import { viewerStore } from 'src/store/Viewer.store';

export default function ConfirmRoute() {
  const { setIsLoading, setUser, resetAppStore, user } = appStore();
  const { resetViewerStore } = viewerStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.bimGridToken) {
      run();
    } else {
      navigate('/');
    }
  }, []);

  const run = async () => {
    try {
      setIsLoading(true);
      const data = await MeAPI();
      if (data) {
        setIsLoading(false);
        if (!data.is_confirm) {
          navigate('/confirm-notification');
        }
        setUser(data);
        trackingUser(data.email);
      } else {
        resetAll(resetAppStore, resetViewerStore);
        navigate('/');
      }
      setIsLoading(false);
    } catch {
      resetAll(resetAppStore, resetViewerStore);
      navigate('/');
      setIsLoading(false);
    }
  };
  return <>{user && <Outlet />}</>;
}
