import { requestBimGrid } from '../../../../functions/General.function';


export const GetOneSetup = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'get',
        '/api/dfma/setup',
        {},
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};


export const CreateSetup = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/dfma/setup',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const GetOne = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'get',
        '/api/dfma',
        {},
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const GetAll = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'get',
        '/api/dfma/get-all',
        {},
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
export const Import = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/dfma/import',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const Create = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/dfma',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const Update = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid('put', '/api/dfma', body, {});
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const DeleteAll = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'delete',
        '/api/dfma',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};



