/* eslint-disable no-unused-vars */
import { Button } from 'antd';
import IconButton from '../button/IconButton.gen';

export default function ModalTemplate({
  onOk,
  onClose,
  title,
  children,
  footer = true,
  style = {},
  styleBackDrop = {},
  isFullscreen = false,
  isFooter = true,
  closeable = true,
}) {
  const defaultFooter = () => {
    return (
      <div className='footer'>
        <Button block type='primary' danger onClick={onClose}>
          Cancel
        </Button>
        <Button block type='primary' onClick={onOk.bind(this)}>
          Save
        </Button>
      </div>
    );
  };

  const defaultBackDrop = () => {
    if (isFullscreen) {
      return {
        position: 'fixed',
        top: 0,
        left: 0,
      };
    } else {
      return {
        position: 'absolute',
      };
    }
  };

  return (
    <>
      <div
        className='idd-backdrop'
        style={{ ...defaultBackDrop(), ...styleBackDrop }}
      >
        <div className='idd-modal-template' style={style}>
          <div className='header'>
            <span>{title}</span>
            {closeable && (
              <IconButton
                icon={'close'}
                onClick={onClose}
                type='delete'
                // icon={<CloseOutlined />}
                size='ssmall'
                border={false}
              />
            )}
          </div>
          {/* <div className='idd-divider' /> */}
          <div className='body'>{children}</div>
          {isFooter && (
            <>
              {footer === true ? (
                <>
                  <div className='idd-divider' />
                  {defaultFooter()}
                </>
              ) : footer === false ? (
                <></>
              ) : (
                <>
                  <div className='idd-divider' />
                  {footer}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
