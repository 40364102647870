import React, { useEffect, useState } from 'react';
import Sketch from '@uiw/react-color-sketch';
import { Popover } from 'antd';
import { hexToRgba } from '@uiw/color-convert';

export default function CellColor(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [color, setColor] = useState(cellValue);
  const [alpha, setAlpha] = useState(1);
  console.log(alpha);
  return !props?.data?.fixed ? (
    <Popover
      zIndex={1000000000}
      overlayStyle={{ padding: 0 }}
      placement='right'
      content={
        <Sketch
          color={color}
          disableAlpha={true}
          onChange={(color) => {
            setAlpha(color.rgba.a);
            setColor(color.hex);
          }}
        />
      }
      trigger='click'
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: '#fff',
          backgroundImage:
            'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")',
        }}
      ></div>
      <div
        style={{
          zIndex: 10,
          background: color,
          width: '100%',
          height: '100%',
          opacity: alpha,
          position: 'relative',
        }}
      ></div>
    </Popover>
  ) : (
    <div
      style={{ backgroundColor: color, width: '100%', height: '100%' }}
    ></div>
  );
}
