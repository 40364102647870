/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import SubmitForm from '../components/SubmitForm';
import { AppAPI } from '../../../../../api';
import { camelToSnakeCase, snakeToCamelCase } from '../../../../../../functions/General.function';
import { useNavigate } from 'react-router-dom'



export default function ModalDMSRFAEditRFA() {
    const { setIsModalDMSRFEditRFA, editDMSRFANode } = dmsStore()
    const { setIsLoading, } = appStore()
    const [data, setData] = useState({
        category: '',
        discipline: '',
        refNumber: '',
        submissionDate: null,
        dueDate: null,
        requestedBy: '',
        subject: '',
        content: '',
        dmsGroupConsultantIds: []
    })
    const navigate = useNavigate()


    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            debugger
            const rfaData = await AppAPI.DMSRFAAPI.GetDMSRFAOne({ id: editDMSRFANode.data.id })
            const tmp = {}
            _.forEach(rfaData, (v, k) => {
                const name = snakeToCamelCase(k)
                tmp[name] = v
            })
            setData(tmp)
        } catch (ex) {
            if (ex.message)
                message.warning(ex.message)
        }
        setIsLoading(false)
    }
    const close = () => {
        setIsModalDMSRFEditRFA()
    }

    const handleOk = async () => {
        // if (!checkInput(['refNumber', 'category', 'discipline', 'submissionDate', 'dueDate', 'requestedBy'])) {
        //     return
        // }
        setIsLoading(true)
        try {
            const clone = {
                category: data.category,
                discipline: data.category,
                submissionDate: data.submissionDate,
                dueDate: data.dueDate,
                requestedBy: data.requestedBy,
                subject: data.subject,
                content: data.content,
                dmsGroupConsultantIds: data.dmsGroupConsultantIds,
            }
            const tmp = {}
            _.forEach(clone, (v, k) => {
                const name = camelToSnakeCase(k)
                tmp[name] = v
            })
            await AppAPI.DMSRFAAPI.UpdateDMSRFA({ id: editDMSRFANode.data.id, data: tmp })
            navigate(0)
            setIsLoading(false)
            close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
        }

    }


    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Edit RFA'}
                style={{ width: '80%' }}
            >
                <div>
                    <SubmitForm data={data} setData={setData} isUpload={false} isEdit={true} />
                </div>

            </ModalTemplate >
        </>
    );
}

