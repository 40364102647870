import CheckboxForm from 'src/components/atoms/CheckboxForm';

/**
 * TermCheckbox component renders a checkbox with a label and a link to the Terms and Conditions.
 *
 * @returns {JSX.Element} The rendered checkbox and label elements with a link.
 */
const TermCheckbox = () => {
  return (
    <div className='flex items-start'>
      <div className='flex items-center h-5'>
        <CheckboxForm name='terms' isRequired />
      </div>
      <div className='ml-3 text-sm'>
        <label
          htmlFor='terms'
          className='font-light text-gray-500 dark:text-gray-300'
        >
          I accept the{' '}
          <a
            className='font-medium text-primary-600 hover:underline dark:text-primary-500'
            href='#'
          >
            Terms and Conditions
          </a>
        </label>
      </div>
    </div>
  );
};

export default TermCheckbox;
