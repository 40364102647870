import _ from 'lodash';
import create from 'zustand';

const initialState = {
  qrDrawingLoading: false,
  drawings: [],
  properties: [],
  documents: [],

  currentDrawing: null,
  gridApiMain: null,

  isDetailDrawing: false,
  isAddNewDrawing: false,
  isPrintQRDrawing: false,
};
export const qrDrawingStore = create((set) => ({
  ...initialState,

  setQRDrawingLoading: (value) => set((state) => ({ qrDrawingLoading: value })),
  setDrawings: (value) => set((state) => ({ drawings: value })),
  setProperties: (value) => set((state) => ({ properties: value })),
  setDocuments: (value) => set((state) => ({ documents: value })),
  setAddDocument: (value) =>
    set((state) => {
      let index = _.findIndex(state.documents, (v) => v.name === value.name);
      if (index < 0) {
        return {
          documents: [...state.documents, value],
        };
      } else {
        return { documents: state.documents.splice(index, 1, value) };
      }
    }),
  setRemoveDocument: (value) =>
    set((state) => ({
      documents: state.documents.filter((item) => item.name !== value.name),
    })),

  setCurrentDrawing: (value) => set((state) => ({ currentDrawing: value })),
  setGridApiMain: (value) => set((state) => ({ gridApiMain: value })),

  setIsDetailDrawing: (value) => set((state) => ({ isDetailDrawing: value })),
  setIsAddNewDrawing: (value) => set((state) => ({ isAddNewDrawing: value })),
  setIsPrintQRDrawing: (value) => set((state) => ({ isPrintQRDrawing: value })),

  resetQRDrawingStore: () => set(initialState),
}));
