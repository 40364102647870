import axios from 'axios';
import { requestBimGrid } from '../../../functions/General.function';

export const UploadPutLink = async (key, typeFile, blob) => {
  return new Promise(async (resolve, reject) => {
    let resPutLink = await requestBimGrid('get', '/api/s3/put-link', null, {
      key,
      typeFile: typeFile,
    });
    if (resPutLink) {
      await axios.put(resPutLink, blob, {
        headers: {
          'Content-Type': typeFile,
        },
      });
    }
    resolve(key);
  });
};

export const GetPublicLink = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid('get', '/api/s3/get-link', {}, params);
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};

export const CopyLinkToDes = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid('post', '/api/s3/copy-link', {}, params);
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};