import { LoadingOutlined } from "@ant-design/icons";
// import { Popover2 } from "@blueprintjs/popover2";
import { Spin, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { cloudFront } from "../../config";
import { requestBimGrid } from "../../functions/General.function";
import { useParams } from "react-router-dom";


  


export function ImageScene({ url, height = 100, width = 100,  content, isTooltip = false }) {
    const [img, setImg] = useState()
    const [isLoading, setIsLoading] = useState()
    const { organizationId, projectId } = useParams()
    useEffect(() => {

        run()
    }, [])

    const run = async () => {
        setIsLoading(true)
        let data = await requestBimGrid('get', '/api/s3/get-link', null, {organizationId, projectId, key: url })
        if (data) {
            setImg(data)
        }
        // setImg(cloudFront +url)
        setIsLoading(false)
    }
    return (
        <>
            {isLoading ?
                <div style={{ width: width, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <Spin indicator={
                        <LoadingOutlined style={{ fontSize: 30, color: 'white' }} spin />
                    } />
                </div>
                :
                img ?
                    <div>
                        {isTooltip ?
                            <Popover content={content}
                            style={{padding:0}}
                            >
                                <img src={img} height={height} width={width} />
                            </Popover>
                            :
                            <img src={img} height={height} width={width} />
                        }
                    </div>
                    :
                    <div style={{ height: height }} >No Image</div>
            }
        </>



    );
}
