import React from 'react';
/**
 * @param {string} label
 * @returns {React.Element}
 */

const HorizontalDivider = ({ label }) => {
  return (
    <>
      <div className='flex items-center justify-center w-full pt-2 pb-2 '>
        <hr className='w-full h-px my-2 bg-gray-200 border-0 dark:bg-gray-700' />
        <span className='absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900'>
          {label}
        </span>
      </div>
    </>
  );
};

export default HorizontalDivider;
