import React, { useState } from "react";
import { projectStore } from "../../../../store/Docs.store";
import ModalAddFile from "../three_d/modal/ModalAddFile";
import { isMobile } from 'is-mobile'
import ProjectMobile from "./main/mobile/Project.mobile";
import ProjectDesktop from "./main/desktop/Project.desktop";

export default function LayOutProject() {

    const { isAddFile } = projectStore()
    const isMobileApp = isMobile()
    return (
        <>
            {isMobileApp ?
                <ProjectMobile />
                :
                <ProjectDesktop />
            }

            {
                isAddFile &&
                <ModalAddFile />
            }

        </>

    );
}


