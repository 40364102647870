/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox,
    Upload,
    Modal,
    message
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { viewerStore } from '../../../../../../store/Viewer.store';
import _ from 'lodash';
import { viewerOptions } from '../../../Viewer.constant';

const { RangePicker } = DatePicker;
const { TextArea } = Input;


export default function EditAnimationStepModal({ setLoading }) {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const { viewer, animationStepDetail, setAnimationStepDetail ,setAnimationStepsData, animationStepsData} = viewerStore()
    const [time, setTime] = useState(animationStepDetail?.time)
    const [name, setName] = useState(animationStepDetail?.name)
    const close = () => {
        setAnimationStepDetail()

    }

    const handleOk = async (e) => {
        setLoading(true)
        animationStepDetail.name = name
        animationStepDetail.time = time
        let clone =[...animationStepsData]
        setAnimationStepsData([...animationStepsData])
        setAnimationStepDetail()
        setLoading(false)

    }
    const handleChangeName = (e) => {
        setName(e.target.value)
    }

    const handleChangeTime = (e) => {
        setTime(e.target.value)
    }

    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={animationStepDetail?.name}
                styleBackDrop={{ position: 'absolute' }}
                style={{ top: 'auto', width: '50%' }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }} >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <span style={{ fontWeight: 'bold' }}>Name:</span>
                        <Input placeholder='Name'  value={name} onChange={handleChangeName} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <span style={{ fontWeight: 'bold' }}>Time:</span>
                        <Input placeholder='Name' type='number' min={0.1}  value={time}  onChange={handleChangeTime}/>
                    </div>
                </div>
            </ModalTemplate>
        </>
    );
}

