import { Upload, Button, DatePicker, Select, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { qrElementStore } from './QRElement.store';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { ViewerAPI } from '../../../../../api';
import { useSearchParams } from 'react-router-dom';

export default function QRElementToolbar() {
  const {
    setIsShowSetting,
    setAllTemplate,
    allTemplate,
    setQRElementLoading,
    qrElementLoading,
    setFileQRElementId,
    fileQRElementId,
    setCurrentElement,
  } = qrElementStore();
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const { viewer, treeModelData } = viewerStore();
  // const [fileQRElementId, setFileQRElementId] = useState();

  const handleOpenSetting = () => {
    setIsShowSetting(true);
  };
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    try {
      let apis = [
        ViewerAPI.QRElementAPI.GetAll(),
        ViewerAPI.QRElementAPI.GetFileQRElementSetting({ fileId }),
      ];
      let resutl = await Promise.all(apis);
      setAllTemplate(resutl[0]);
      setFileQRElementId(resutl[1].share_qr_element_setting_id);
      debugger;
    } catch (ex) {
      message.warning(ex.message);
    }
  };
  const handleChange = async (e) => {
    setQRElementLoading(true);
    try {
      let data = { fileId, fileQRElementId: e };
      debugger;
      await ViewerAPI.QRElementAPI.SetFileQRElementSetting(data);
      setFileQRElementId(data.fileQRElementId)
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRElementLoading(false);
  };
  const handleSelect = () => {
    let selection = viewer.getAggregateSelection();
    if (selection.length !== 0) {
      let modelId = selection[0].model.id;
      let dbId = selection[0].selection[0];
      debugger;
      if (!fileQRElementId) return;
      let node = treeModelData.findNodeByModelId(dbId, modelId);
      if (node) setCurrentElement(node);
    }
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 5,
          padding: 5,
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          <IconButton
            icon={'setting'}
            size='ssmall'
            type='save'
            onClick={handleOpenSetting.bind(this)}
          />
          <IconButton
            icon={'select'}
            size='ssmall'
            type='save'
            onClick={handleSelect.bind(this)}
          />
        </div>

        <Select
          style={{ width: 150 }}
          value={fileQRElementId}
          placeholder='Setting Template'
          onChange={handleChange}
          loading={qrElementLoading}
        >
          {allTemplate.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </>
  );
}
