import create from 'zustand'
import { ClashComponentType } from './constants/ClashComponentType'


const initialState = {
    type: ClashComponentType.List,
    clashDetectLoading:false,

    clashDetectionPoint: null,
    clashDetectionClashTests: [],
    clashDetectionClashTestSelected: null,
    clashDetectionDetail: null,
    clashDetectionSelected: null,
    clashPositions:[]
}
export const clashDetectionStore = create((set) => ({
    ...initialState,
    setType: (value) => set((state) => ({ type: value })),
    setClashDetectLoading: (value) => set((state) => ({ clashDetectLoading: value })),

    setClashDetectionPoint: (value) => set((state) => ({ clashDetectionPoint: value })),
    setClashDetectionClashTests: (value) => set((state) => ({ clashDetectionClashTests: value })),
    setClashDetectionDetail: (value) => set((state) => ({ clashDetectionDetail: value })),
    setClashDetectionClashTestSelected: (value) => set((state) => ({ clashDetectionClashTestSelected: value })),
    setClashDetectionSelected: (value) => set((state) => ({ clashDetectionSelected: value })),
    setClashPositions: (value) => set((state) => ({ clashPositions: value })),

    resetClashDetectionStore: () => set(initialState)
}))