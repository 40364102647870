/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { qrElementStore } from '../QRElement.store';
import TemplateItem from './Template.item';
import { ViewerAPI } from '../../../../../../api';
import { message } from 'antd';

export default function ListTemplate() {
  const { allTemplate, setAllTemplate, setQRElementLoading } = qrElementStore();

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setQRElementLoading(true);
    try {
      debugger
      let resQRElement = await ViewerAPI.QRElementAPI.GetAll();
      setAllTemplate(resQRElement);
    } catch {}
    setQRElementLoading(false);
  };
  const handleAdd = async () => {
    try {
      let name = window.prompt('Please input name', '');
      if (!name) return;
      setQRElementLoading(true);
      let data = {
        name,
        properties: [],
      };
      debugger;
      let resQRElement = await ViewerAPI.QRElementAPI.Create({
        shareQRElementSettingData: data,
      });
      let clone = [...allTemplate];
      clone.push(resQRElement);
      setAllTemplate(clone);
      setQRElementLoading(false);
    } catch (ex) {
      setQRElementLoading(false);
    }
  };
  return (
    <>
      <div
        style={{
          width: '30%',
          padding: 5,
          gap: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          <IconTextButton
            icon={'add'}
            size='ssmall'
            block={false}
            onClick={handleAdd}
            type='save'
          >
            Add
          </IconTextButton>
        </div>
        <div
          style={{
            padding: 5,
            height: '100%',
            gap: 5,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          {allTemplate?.map((v, k) => (
            <TemplateItem key={k} item={v} />
          ))}
        </div>
      </div>
    </>
  );
}
