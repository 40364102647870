import React from 'react';
import { appStore } from 'src/store/App.store';

/**
 * @param {React.Element} children
 * @returns {React.Element} The rendered input element.
 */
const UserAccountEmail = () => {
  const { user } = appStore();
  return (
    <>
      <p className='py-2 text-xl font-semibold'>Email Address</p>
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
        <p className='text-gray-600'>
          Your email address is <strong>{user.email}</strong>
        </p>
      </div>
    </>
  );
};

export default UserAccountEmail;
