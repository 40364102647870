import { requestBimGrid } from '../../../functions/General.function';

export const CreateDMSSettingGroup = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-setting/add-group', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const UpdateDMSSettingGroup = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-setting/update-one-group', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};
export const GetDMSSettingGroups = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-setting/get-groups', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
}
export const GetDMSSettingGroupByUser = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-setting/get-group-by-user', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const GetDMSSettingGroupOne = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-setting/get-one-group', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const DeleteDMSSettingGroupOne = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('delete', '/api/dms-setting/delete-one-group', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const GetDMSSettingMembersGroup = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/dms-setting/get-members-group', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const CreateDMSSettingMemberGroup = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/dms-setting/add-one-member-group', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const DeleteDMSSettingMemberGroupOne = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('delete', '/api/dms-setting/delete-one-member-group', body)
            resolve(data);
        } catch (ex) {
            reject(ex);
        }
    });
};