import React, { } from "react";
import IconButton from "../../../../gen/button/IconButton.gen";
import { dmsStore } from "../../../../../store/DMS.store";
import IconTextButton from "../../../../gen/button/IconTextButton.gen";



export default function DMSSettingGroupToolbar() {
  const { setIsModalDMSSettingAddGroup } = dmsStore()
  const handleAddFolder = () => {
    setIsModalDMSSettingAddGroup(true)
  }


  return (
    <div style={{ padding: '5px 2px' }}>
      <IconTextButton
        icon={'add'}
        size="ssmall"
        type='save'
        onClick={handleAddFolder}
      >Add Group</IconTextButton>
    </div>

  );
}