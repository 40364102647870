/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { message, Input } from 'antd';
import ModalTemplate from '../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { appStore } from '../../../../../store/App.store';
import { fiveDStore } from '../../../../../store/FiveD.store';
import { v4 } from 'uuid';
import { AppAPI } from '../../../../api';
import { useParams } from 'react-router-dom';

export default function ModalFiveDAddSheet() {
  const { setIsLoading } = appStore();
  const { setIsOpenAddSheet, setListSheet, listSheet } = fiveDStore();
  const [name, setName] = useState();
  const { projectId } = useParams();
  const close = () => {
    setIsOpenAddSheet(false);
  };
  const handleChange = (type, e) => {
    setName(e.target.value);
  };

  const handleOk = async (e) => {
    if (!name) {
      return;
    }
    try {
      setIsLoading(true);
      const repAddSheet = await AppAPI.FiveDSheetAPI.CreateSheet({
        name,
        projectId,
        columns: [],
      });
      listSheet.push(repAddSheet);
      setListSheet([...listSheet]);
      setIsLoading(false);
      close();
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add Sheet'}
        style={{ maxWidth: 500, width: 500 }}
      >
        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <span style={{ fontWeight: 'bold', width: 125 }}>Sheet Name:</span>
          <Input
            style={{ width: 400 }}
            placeholder='Please input'
            onChange={handleChange.bind(this, 'name')}
            value={name}
          />
        </div>
      </ModalTemplate>
    </>
  );
}
