import { Route } from 'react-router-dom';
import {
  OrganizationManagementPage,
  OrganizationPage,
} from 'src/components/pages/admin';
import { SuperAdminUserManagementPage } from 'src/components/pages/super_admin';
import {
  AdminRoute,
  ManagementOrganizationRoute,
  OrganizationRoute,
  SuperAdminRoute,
} from 'src/routes/admin';

export const AdminAppRoutes = [
  <Route key={'admin'} path='admin' element={<AdminRoute />}>
    <Route
      key='organizations'
      path='organizations'
      element={<OrganizationPage />}
    />
    <Route
      key='organization/:organizationId'
      path='organization/:organizationId'
      element={<OrganizationRoute />}
    >
      <Route element={<ManagementOrganizationRoute />}>
        <Route
          key='management'
          path='management'
          element={<OrganizationManagementPage />}
        />
      </Route>
    </Route>
  </Route>,
];

export const SuperAdminAppRoutes = [
  <Route key={'super-admin-route'} element={<SuperAdminRoute />}>
    <Route
      key='super-admin'
      path='super-admin'
      element={<SuperAdminUserManagementPage />}
    />
  </Route>,
];
