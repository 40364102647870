import React from 'react';

import _ from 'lodash';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';
import ButtonNav from '../../Button.nav';
// import { handleChangeFeature } from '../../../../functions/Viewer.function';

export default function ToolQR({ handleChangeFeature }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, resetAppStore } = appStore();
  const { setIsOpenTopNav, setFeature, feature, docker, viewerLoading } =
    viewerStore();

  return (
    <div style={{ display: 'flex', gap: 5 }}>
      <ButtonNav
        url={'https://img.icons8.com/wired/32/qr-code.png'}
        name='QR'
        active={feature.includes('qr_element')}
        onClick={handleChangeFeature.bind(
          this,
          'qr_element',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />

      <ButtonNav
        url={'https://img.icons8.com/ios/50/serial-tasks--v1.png'}
        name='QR Drawing'
        active={feature.includes('qr_drawing')}
        onClick={handleChangeFeature.bind(
          this,
          'qr_drawing',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />
    </div>
  );
}
