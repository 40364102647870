import { useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../settings/General.settings';
import { appStore } from '../../../../../store/App.store';
import IconButton from '../../../../gen/button/IconButton.gen';

export default function GroupRole({ data, setGridApi, setRowNode }) {
  const { setIsLoading } = appStore();
  const gridRef = useRef();
  const { organizationId, projectId } = useParams();

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    let repData = await requestBimGrid(
      'delete',
      '/api/admin/role',
      { roleId: e.node.allLeafChildren[0].data.id },
      { organizationId, projectId }
    );
    if (repData) {
      let temp = [];
      _.forEach(e.node.allLeafChildren, (v) => {
        temp.push(v.data);
      });
      await updateTableAsync(gridRef.current.api, { remove: temp });
      gridRef.current.api.redrawRows();
    }
    setIsLoading(false);
  };

  const handleEditItem = (e) => {
    setRowNode(e.node);
  };

  const handleRemovePermission = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    const reqData = await requestBimGrid(
      'delete',
      '/api/admin/role/removeGroupPermissionFormRole',
      {
        roleId: e.data.id,
        groupPermissionId: e.data.groupPermissionId,
      },
      { organizationId, projectId }
    );
    if (reqData) {
      await updateTableAsync(gridRef.current.api, { remove: [e.data] });
      gridRef.current.api.redrawRows();
    }
    setIsLoading(false);
  };
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      rowGroup: true,
      hide: true,
      rowHeight: 100,
    },

    {
      headerName: 'Group Permission Name',
      field: 'groupPermissionName',
      filter: 'agSetColumnFilter',
      // rowGroup: true,
      // hide: true,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Group Permission Description',
      field: 'groupPermissionDescription',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 120,
      maxWidth: 120,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        const level = params.node.level;
        if (isGroup) {
          if (level === 0) {
          }
          return {
            component: handleActionGroupPermission,
          };
        } else {
          // return {
          //   component: handleAction,
          // };
        }
      },
    },
  ];
  const handleActionGroupPermission = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          onClick={handleEditItem.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };

  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleRemovePermission.bind(this, params)}
        />
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);
  const getRowHeight = useCallback((params) => {
    // return 100px height for full width rows
    // if (params.node.group && params.node.level === 0) {
    //     return 70;
    // }
  }, []);

  const groupRowRendererParams = useMemo(() => {
    return {
      // innerRenderer: DescriptionCellGroup,
      suppressCount: true,
    };
  });
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='singleColumn'
          suppressPaginationPanel={true}
          groupDefaultExpanded={2}
          groupRowRendererParams={groupRowRendererParams}
          getRowHeight={getRowHeight}
          rowHeight={30}
          headerHeight={35}
          // isFullWidthRow={isFullWidthRow}
          // fullWidthCellRenderer={fullWidthCellRenderer}
        ></AgGridReact>
      </div>
    </>
  );
}
