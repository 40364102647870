import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginAPI } from 'src/api';
import FullSceenLoader from 'src/components/molecules/FullSceenLoader';
import SignInForm from 'src/components/organisms/SignInForm';
const LoginPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setIsLoading] = useState();
  const onClickLogin = async (loginInput) => {
    setIsLoading(true);
    try {
      const data = await LoginAPI({
        email: loginInput.email,
        password: loginInput.password,
      });
      if (data) {
        localStorage.bimGridToken = data.token;
        navigate('/organization');
      }
    } catch (ex) {
      debugger;
      setError(ex.message ?? ex ?? 'Error');
    }
    setIsLoading(false);
  };
  return (
    <>
      {loading && <FullSceenLoader />}
      <SignInForm
        onClickLogin={onClickLogin}
        isLoading={loading}
        error={error}
      />
    </>
  );
};

export default LoginPage;
