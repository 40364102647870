import { useEffect, useState } from 'react';
import { ReactComponent as CheckCircleIcon } from 'src/assets/icons/general/check-circle.svg';
import { appStore } from 'src/store/App.store';

export default function ShareOrganizationCell(props) {
  const [isShared, setIsShared] = useState();
  const { user } = appStore();
  useEffect(() => {
    setIsShared(props.node.data.created_by !== user.email);
  }, []);
  return isShared ? <CheckCircleIcon className='text-green-600' /> : <></>;
}
