import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Table, Row, Col, Input, Button, message } from 'antd';
import _ from 'lodash'
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { AgGridReact } from 'ag-grid-react';
import { autoGroupColumnDef, defaultColDef } from '../../../../../../settings/General.settings';
import IconButton from '../../../../../gen/button/IconButton.gen';
import ClashNameCell from '../cell/ClashName.cell';
import ClashStatusCell from '../cell/ClashStatus.cell';
import ClashDistanceCell from '../cell/ClashDistance.cell';
import { ClashComponentType } from '../constants/ClashComponentType';
import { clashDetectionStore } from '../ClashDetection.store';



export default function ClashDetectionResultTable({ setGridApi }) {
    const { viewer } = viewerStore()
    const { clashDetectionPoint, setClashDetectionSelected, clashDetectionDetail, setClashPositions } = clashDetectionStore()
    const { setType } = clashDetectionStore()
    const gridRef = useRef();
    const columns = [
        {
            headerName: 'Name',
            field: 'name',
            filter: 'agSetColumnFilter',
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: ClashNameCell,
                    }
                }
                return undefined;
            }
        },
        {
            headerName: 'Status',
            field: 'status',
            filter: 'agSetColumnFilter',
            editable: true,
            cellEditorPopup: true,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
                values: ['New', 'Active', 'Reviewed', 'Approved', 'Resolved'],
                cellRenderer: ClashStatusCell,
                searchDebounceDelay: 500
            },
            valueSetter: params => {
                if (params.newValue !== params.oldValue) {
                    params.data.status = params.newValue;
                    params.data.colorName = params.newValue.toLowerCase();
                    params.api.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true })
                    clashDetectionPoint.editExistingPoint(params.node.id, params.data)
                    return true;
                }
                return false;
            }
        },
        {
            headerName: 'Level',
            field: 'level',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Grid Intersect',
            field: 'grid',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Found',
            field: 'found',
            filter: 'agSetColumnFilter',
        },
        // {
        //     headerName: 'Approved By',
        //     field: 'approvedBy',
        //     filter: 'agSetColumnFilter',
        // },
        // {
        //     headerName: 'Approved Date',
        //     field: 'approvedDate',
        //     filter: 'agSetColumnFilter',

        // },
        // {
        //     headerName: 'Description',
        //     field: 'description',
        //     filter: 'agSetColumnFilter',
        // },
        // {
        //     headerName: 'Assigned To',
        //     field: 'assignedTo',
        //     filter: 'agSetColumnFilter',
        //     editable: true,
        //     cellEditor: 'agTextCellEditor',
        //     cellEditorParams: {
        //         maxLength: 100,
        //     },
        // },
        {
            headerName: 'Distance',
            field: 'distance',
            filter: 'agSetColumnFilter',
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: ClashDistanceCell,
                    }
                }
                return undefined;
            }
        },
        {
            headerName: '',
            lockPosition: 'right',
            minWidth: 70, maxWidth: 70,
            suppressMovable: true,
            cellRendererSelector: params => {
                return {
                    component: handleAction,
                }
            }
        },
    ]


    useEffect(() => {
        if (gridRef.current) {
            clashDetectionPoint.addEventListener('select', handleSelection.bind(this), false)
            return () => {
                clashDetectionPoint.removeEventListener('select', handleSelection.bind(this), false)
            }
        }
    }, [gridRef.current])

    const handleSelection = (e) => {
        gridRef.current.api.deselectAll()
        if (e.nodes.length > 0) {
            let index = e.nodes[0].index;
            let item = null
            let rowIndex = -1
            gridRef.current.api.forEachLeafNode((rowNode, i) => {
                if (rowNode.id == index) {
                    rowIndex = _.toNumber(rowNode.id)
                    item = rowNode.data
                }
            })
            if (rowIndex >= 0) {
                gridRef.current.api.setFocusedCell(rowIndex, 0)
                gridRef.current.api.ensureIndexVisible(rowIndex, 'top')
                gridRef.current.api.getRowNode(rowIndex).setSelected(true, true)
                let clone = { ...item }
                clone.label = e.position
                setClashDetectionSelected(clone)
            }

        } else {
            gridRef.current.api.deselectAll()
            setClashDetectionSelected()
        }
    }


    const handleSelect = (params) => {
        gridRef.current.api.deselectAll()
        // const models = viewer.impl.modelQueue().getModels();
        // if (params.isGroup) {
        //     let nodes = params.node.allLeafChildren
        //     let temp = {}
        //     _.forEach(nodes, v => {
        //         const modelAId = v.objA.model.id
        //         if (!temp[modelAId])
        //             temp[modelAId] = []
        //         temp[modelAId].push(v.objA.id)

        //         const modelBId = v.objA.model.id
        //         if (!temp[modelBId])
        //             temp[modelBId] = []
        //         temp[modelBId].push(v.objA.id)
        //     })
        //     let select = []
        //     _.forEach(temp, (v, k) => {
        //         let index = _.findIndex(models, v => { return v.id == k })
        //         select.push({ model: models[index], ids: v })
        //     })
        //     // viewer.select(temp)
        //     viewer.isolate(select[0].ids, select[0].model)
        //     viewer.impl.selector.setAggregateSelection(select)
        //     viewer.fitToView(select[0].ids, select[0].model)
        // } else {
        //     const modelAId = params.data.objA.model.id
        //     const modelBId = params.data.objB.model.id
        //     let indexA = _.findIndex(models, v => { return v.id === modelAId })
        //     let indexB = _.findIndex(models, v => { return v.id === modelBId })
        //     let select = []
        //     if (indexA === indexB) {
        //         select.push({ model: models[indexA], ids: [params.data.objA.id, params.data.objB.id] })
        //     } else {
        //         select.push(
        //             { model: models[indexA], ids: [params.data.objA.id] },
        //             { model: models[indexB], ids: [params.data.objB.id] }
        //         )
        //     }
        //     viewer.isolate(select[0].ids, select[0].model)
        //     viewer.impl.selector.setAggregateSelection(select)
        //     viewer.fitToView(select[0].ids, select[0].model)
        // }
        clashDetectionPoint.selectClash(params)
    }
    const handleIssue = (params) => {
        let positions = []
        if (params.node.group) {
            let nodes = params.node.allLeafChildren
            _.forEach(nodes, v => {
                positions.push(v.data.position)
            })
        } else {
            positions.push(params.data.position)
        }
        clashDetectionPoint.hidePointCloud()
        setClashPositions(positions)
        setType(ClashComponentType.LinkIssue)
    }
    const handleAction = (params) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
                <IconButton
                    icon={'select'}
                    size='sssmall'
                    ghost={true}
                    border={false}
                    iconStyle={{ background: '#000000' }}
                    onClick={handleSelect.bind(this, params)}
                />
                <IconButton
                    icon={'issue'}
                    size='sssmall'
                    ghost={true}
                    border={false}
                    iconStyle={{ background: '#000000' }}
                    onClick={handleIssue.bind(this, params)}
                />
            </div>
        )
    }

    const onGridReady = useCallback((params) => {
        setGridApi(params)
    }, []);
    return (
        <>
            <div style={{ width: '100%', height: '100%', padding: 5 }} >

                <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={clashDetectionDetail}
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onGridReady={onGridReady}
                        groupDisplayType='multipleColumns'
                        rowGroupPanelShow='always'
                        rowHeight={30}
                        // pagination={true}
                        // paginationPageSize={pageSizeModuleList}
                        suppressPaginationPanel={true}
                        context={false}
                        suppressContextMenu={true}

                    ></AgGridReact>


                </div>
            </div>

        </>

    );
}


