import React, { useEffect, useState } from 'react';
import moment from 'moment';

export default function StatusCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [status, setStatus] = useState('Active');
  useEffect(() => {
    if (props.data.transer_out_date) {
      setStatus('Unactive');
    }
  }, [props.data.transer_out_date]);

  return <div>{status}</div>;
}
