/* eslint-disable no-unused-vars */
import { DatePicker as DatePickerDayjs, Input, message, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import { AppAPI } from '../../../../../api';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalEditPaymentMonth() {
  const navigate = useNavigate();
  const {
    setIsOpenEditWorkerPaymentMonth,
    workerListGridApi,
    nodeEditWorkerPaymentMonth,
    paymentMonthDate,
  } = workerStore();
  const { setIsLoading } = appStore();
  const [selectItems, setSelectItems] = useState({});
  const [data, setData] = useState([
    {
      name: 'Gross Additions - Shift Allowance',
      field: 'gross_shift_allowance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Ordinary) - Addition - Additional Pay (RD)',
      field: 'a_cdf_o_a_additional_pay',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Ordinary) - Addition - Adjustment',
      field: 'a_cdf_o_a_adjustment',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Ordinary) - Addition - Allowance (Employment)',
      field: 'a_cdf_o_a_allowance_e',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Ordinary) - Addition - Salary In Advance',
      field: 'a_cdf_o_a_salary_in_advance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Ordinary) - Addition - Supervisor Allowance',
      field: 'a_cdf_o_a_supervisor_allowance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Ordinary) - Deduction - Adjustment',
      field: 'a_cdf_o_d_adjustment',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Ordinary) - Deduction - Salary In Advance',
      field: 'a_cdf_o_d_salary_in_advance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Additional) - Addition - Incentive',
      field: 'a_cdf_a_a_incentive',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance With CPF (Additional) - Deduction - Incentive',
      field: 'a_cdf_a_d_incentive',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Addition - Adjustment On Last/Next Month',
      field: 'a_wo_cdf_a_adjustment_next_month',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Addition - MC',
      field: 'a_wo_cdf_a_mc',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Addition - Meal Allowance',
      field: 'a_wo_cdf_a_meal_allowance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Addition - Productivity Payment',
      field: 'a_wo_cdf_a_productivity_payment',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Addition - Skill Allowance',
      field: 'a_wo_cdf_a_skill_allowance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Addition - Work Allowance (CV)',
      field: 'a_wo_cdf_a_work_allowance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Deduction - Annual Leave Deduction',
      field: 'a_wo_cdf_d_annual_leave_deduction',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Deduction - Dormitory',
      field: 'a_wo_cdf_d_dormitory',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Deduction - Loan',
      field: 'a_wo_cdf_d_loan',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Deduction - Meal Allowance',
      field: 'a_wo_cdf_d_meal_allowance',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Allowance Without CPF - Deduction - Meal Allowance 1',
      field: 'a_wo_cdf_d_meal_allowance_1',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Gross Deductions Without CPF - Deduction - No Pay Day',
      field: 'gross_d_wo_cdf_d_no_pay_day',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Gross Deductions Without CPF - Deduction - CC',
      field: 'gross_d_wo_cdf_d_cc',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Gross Deductions Without CPF - Deduction - CDAC',
      field: 'gross_d_wo_cdf_d_cdac',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Gross Deductions Without CPF - Deduction - ECF',
      field: 'gross_d_wo_cdf_d_ecf',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Gross Deductions Without CPF - Deduction - MBMF',
      field: 'gross_d_wo_cdf_d_mbmf',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Gross Deductions Without CPF - Deduction - SINDA',
      field: 'gross_d_wo_cdf_d_sinda',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Bonus',
      field: 'bonus',
      value: null,
      type: 'input_number',
    },
    {
      name: 'Gross Wages',
      field: 'gross_wages',
      value: null,
      type: 'input_number',
    },
    {
      name: 'CPF Wages',
      field: 'cpf_wages',
      value: null,
      type: 'input_number',
    },
    {
      name: 'SDF',
      field: 'sdf',
      value: null,
      type: 'input_number',
    },
    {
      name: 'FWL',
      field: 'fwl',
      value: null,
      type: 'input_number',
    },
    {
      name: 'CPF Contribution - Employer CPF',
      field: 'employer_cpf',
      value: null,
      type: 'input_number',
    },
    {
      name: 'CPF Contribution - Employee CPF',
      field: 'employee_cpf',
      value: null,
      type: 'input_number',
    },
    {
      name: 'CPF Contribution - Total',
      field: 'total_cpf',
      value: null,
      type: 'input_number',
    },
  ]);
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);

    try {
      _.forEach(nodeEditWorkerPaymentMonth.data, (v, k) => {
        const index = _.findIndex(data, (i) => {
          return i.field === k;
        });
        if (index >= 0) {
          if (v) {
            if (data[index].type === 'date')
              data[index].value = moment(new Date(v));
            else data[index].value = v;
          }
        }
      });
      setData([...data]);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenEditWorkerPaymentMonth(false);
  };

  const handleOk = async (e) => {
    setIsLoading(true);
    try {
      debugger;
      const tmp = {};
      data.forEach((v) => {
        if (nodeEditWorkerPaymentMonth.data[v.field] !== undefined) {
          tmp[v.field] = v.value;
        }
      });
      tmp.worker_id = nodeEditWorkerPaymentMonth.data.worker_id;
      tmp.month = paymentMonthDate[0];
      const resWorker =
        await AppAPI.WorkerPaymentMonthAPI.UpdateOneWorkPaymentMonth({
          id: nodeEditWorkerPaymentMonth.data.id,
          data: tmp,
        });
      // _.forEach(resWorker, (v, k) => {
      //   if (nodeEditWorkerPaymentMonth.data[k] !== undefined) {
      //     nodeEditWorkerPaymentMonth.data[k] = v;
      //   }
      // });
      navigate(0);
      // await updateTableAsync(workerListGridApi.api, {
      //   update: [nodeEditWorkerPaymentMonth.data],
      // });
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };
  const handleChange = (type, e) => {
    type.value = e.target.value;
    setData([...data]);
  };
  const handleChangeDate = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const handleChangeSelect = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const handleChangeNumber = (type, e) => {
    type.value = e.target.value * 1;
    setData([...data]);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Edit payment'}
        style={{ width: 500 }}
      >
        <div className='flex flex-col gap-2'>
          {data.map((v) => (
            <>
              {v.type === 'input' && (
                <div
                  style={{ display: 'flex', gap: 5, flexDirection: 'column' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                  <Input
                    placeholder='Please, input'
                    onChange={handleChange.bind(this, v)}
                    value={v.value}
                  />
                </div>
              )}
              {v.type === 'input_number' && (
                <div
                  style={{ display: 'flex', gap: 5, flexDirection: 'column' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                  <Input
                    type='number'
                    placeholder='Please, input'
                    onChange={handleChangeNumber.bind(this, v)}
                    value={v.value}
                    className='w-full'
                    min={0}
                  />
                </div>
              )}
              {v.type === 'date' && (
                <div
                  style={{ display: 'flex', gap: 5, flexDirection: 'column' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                  <DatePicker
                    placeholder='Please, input'
                    onChange={handleChangeDate.bind(this, v)}
                    value={v.value}
                  />
                </div>
              )}
              {v.type === 'select' && (
                <div
                  style={{ display: 'flex', gap: 5, flexDirection: 'column' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                  <Select
                    placeholder='Please, input'
                    onChange={handleChangeSelect.bind(this, v)}
                    value={v.value}
                  >
                    {_.isArray(v.data)
                      ? v.data.map((v) => (
                          <Select.Option value={v}> {v} </Select.Option>
                        ))
                      : selectItems['tracking_devices']?.map((v) => (
                          <Select.Option name={v.name} value={v.id}>
                            {' '}
                            {v.name}{' '}
                          </Select.Option>
                        ))}
                  </Select>
                </div>
              )}
            </>
          ))}
        </div>
      </ModalTemplate>
    </>
  );
}
