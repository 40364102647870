import React, { useEffect } from 'react';
import _ from 'lodash';

// import ExtractQuantityGantt from './ExtractQuantityGantt.gantt';
import { qrDrawingStore } from './QRDrawing.store';
import QRDrawingLayout from './QRDrawing.layout';
import Loader from '../../../../../loader/LoaderApp';

export default function QRDrawingFeature(props) {
  const { qrDrawingLoading } = qrDrawingStore();
  useEffect(() => {}, []);

  return (
    <>
      {qrDrawingLoading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 11111111,
          }}
        >
          <Loader />
        </div>
      )}
      <>
        <QRDrawingLayout />
      </>
    </>
  );
}
