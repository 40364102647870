import { useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../settings/General.settings';
import { adminStore } from '../../../store/Admin.store';
import DateCell from '../../ag_grid_components/cell/Duration.cell';
import ImageCell from '../../ag_grid_components/cell/Image.cell';
import IconButton from '../../gen/button/IconButton.gen';

export default function ProjectListTable({
  handleViewItem,
  handleEditItem,
  handleDeleteItem,
  handleSettingItem,
}) {
  const { projects } = adminStore();

  const [columns] = useState([
    {
      headerName: 'Image',
      field: 'img_path',
      maxWidth: 150,
      width: 150,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: ImageCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      // cellRendererSelector: params => {
      //   const isGroup = params.node.group;
      //   if (!isGroup) {
      //     return {
      //       component: () => {
      //         return (
      //           <span onClick={gotoProject.bind(this, params.node.data.id)} style={{ fontWeight: 'bold' }} > {params.value}</span>
      //         )

      //       }
      //     }
      //   }
      //   return undefined
      // }
    },
    {
      headerName: 'Type',
      field: 'type',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Duration',
      field: 'duration',
      filter: 'agSetColumnFilter',
      minWidth: 200,
      maxWidth: 200,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 120,
      maxWidth: 120,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ]);
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'view'}
          size='sssmall'
          type={'save'}
          requireActions={['view.project']}
          onClick={handleViewItem.bind(this, params.data.id, {
            requireActions: ['view.project'],
          })}
        />
        <IconButton
          icon={'edit'}
          size='sssmall'
          type={'edit'}
          requireActions={['update.project']}
          onClick={handleEditItem.bind(this, params.data.id)}
        />
        <IconButton
          icon={'setting'}
          size='sssmall'
          type={'edit'}
          requireActions={['update.project']}
          onClick={handleSettingItem.bind(this, params.data.id)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          requireActions={['delete.project']}
          onClick={handleDeleteItem.bind(this, params.data.id)}
        />
      </div>
    );
  };

  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          rowData={projects}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          // onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          // rowHeight={50}
          // pagination={true}
          // paginationPageSize={pageSizeModuleList}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          groupDefaultExpanded={2}
          rowHeight={35}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
