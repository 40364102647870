import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LayOutProject from 'src/components/layout/apps/three_d/Project.layout';
import { projectStore } from 'src/store/Docs.store';
export default function ThreeDFolderPage() {
  const navigate = useNavigate();
  const { setFileName } = projectStore();
  const handleProject = (e) => {
    // navigate(`/docs/files?projectId=${e}`)
    navigate(`viewer`);
  };
  useEffect(() => {
    return () => {
      setFileName('');
    };
  }, []);

  return (
    <>
      <LayOutProject gotoProject={handleProject} />
    </>
  );
}
