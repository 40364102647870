import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import 'moment-duration-format';
import { useEffect, useState } from 'react';
import { workerStore } from 'src/store/Worker.store';
import { WorkerTrackingAPI } from '../../../../api/app';
import IconButton from '../../../../gen/button/IconButton.gen';
export default function TrackingTimeCell(props) {
  const [trackingTime, setTrackingTime] = useState('00:00:00');
  const [trackingTimeUpdate, setTrackingTimeUpdate] = useState('');
  const [isLoading, setIsLoading] = useState();
  const { dailyDate } = workerStore();
  useEffect(() => {
    if (props.node.data.tracking_time && props.node.data.tracking_time != 0) {
      const time = moment.duration(
        _.toNumber(props.node.data.tracking_time),
        'minutes'
      );

      setTrackingTime(time.format('HH:mm:ss'));
      setTrackingTimeUpdate(
        moment(new Date(props.node.data.tracking_time_updated_at)).format(
          'DD/MM/YYYY HH:mm:ss'
        )
      );
    }
  }, []);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const time = await WorkerTrackingAPI.GetTrackingTimeByWorkerId({
        workerId: props.node.data.worker_id,
        id: props.node.data.id,
        currentDate: props.node.data.date ?? dailyDate,
      });
      setTrackingTime(time);
      setTrackingTimeUpdate(moment().format('DD/MM/YYYY hh:mm:ss'));
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    props.node.data[`${props.column.colId}_processed`] = trackingTime;
  }, [trackingTime]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>{trackingTime}</div>

      <div>
        <IconButton
          title={trackingTimeUpdate}
          size='sssmall'
          icon='reload'
          onClick={handleClick}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
