import React, { useEffect, useState } from "react";
import DMSDocShareTable from "./table/DMSDocShare.table";
import { appStore } from "../../../../../../store/App.store";
import { message } from 'antd'
import { AppAPI } from "../../../../../api";
import { useSearchParams, useNavigate } from "react-router-dom";
import IconTextButton from "../../../../../gen/button/IconTextButton.gen";

export function DMSDocShareLayout(props) {
  const navigate = useNavigate()
  const [search] = useSearchParams();
  const { setIsLoading } = appStore();
  const [folder, setFolder] = useState()
  const folderId = search.get("folderId");
  useEffect(() => {
    if (!folderId) {
      message.warning('Cannot find folder id')
      navigate(-1)
      return
    }
    run()
  }, [folderId])
  const run = async () => {
    setIsLoading(true)
    try {
      const folder = await AppAPI.DMSDocAPI.GetShareDMSDocOne({ id: folderId })
      setFolder(folder)
    } catch (e) {
      message.warning(e.message)
    }
    setIsLoading(false)
  }
  const handleDownload = async () => {
    if (!window.confirm('Are you sure to download?')) return
    setIsLoading(true)
    try {
      const link = await AppAPI.DMSDocAPI.DownloadFolder({ path: folder.path })
      window.open(link, "_blank")
    } catch (ex) {
      message.warning(ex.message)
    }
    setIsLoading(false)
  }
  return (
    <>
      {
        folder &&
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }} >
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5, alignItems: 'center' }} >
            <span style={{ fontWeight: 'bold', fontSize: 18 }} >
              <span>Folder Name:</span>
              <span style={{ color: '#2f465e' }} >    {` ${folder.name}`}</span>
            </span>
            <IconTextButton type='save' size="small" icon={'download'}
              onClick={handleDownload}
            > Download</IconTextButton>
          </div>
          <DMSDocShareTable />
        </div>
      }

    </>
  );
}
