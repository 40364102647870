import React from 'react';
import TrackingDevicesTable from '../table/TrackingDevices.table';
import TrackingDevicesToolbar from './TrackingDevices.toolbar';

export default function TrackingDevicesLayout() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 2,
        height: '100%',
        widthL: '100%',
        flexDirection: 'column',
      }}
    >
      <TrackingDevicesToolbar />
      <TrackingDevicesTable />
    </div>
  );
}
