import React, { useEffect } from 'react';
import _ from 'lodash';


// import ExtractQuantityGantt from './ExtractQuantityGantt.gantt';
import { qrElementStore } from './QRElement.store';
import QRElementLayout from './QRElement.layout';
import Loader from '../../../../../loader/LoaderApp';

export default function QRElementFeature(props) {
  const { qrElementLoading } = qrElementStore();
  useEffect(() => {}, []);

  return (
    <>
      {qrElementLoading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 11111111,
          }}
        >
          <Loader />
        </div>
      )}
      <>
        <QRElementLayout />
      </>
    </>
  );
}
