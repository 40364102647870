import React, { useEffect, useRef, useState } from 'react';
import { qrDrawingStore } from '../QRDrawing.store';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import QRCode from 'qrcode';
import { useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
let textSize = 14;

let ddd = [
  {
    name: 'sdsds',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '2312312',
  },
  {
    name: 'sdsds1',
    value: '23123122222222222222222222222222222222222222222222',
  },
  {
    name: 'sdsds1',
    value: '23123122222222222222222222222222222222222222222222',
  },
  {
    name: 'sdsds1',
    value: '23123122222222222222222222222222222222222222222222',
  },
];

export default function QRDrawingPDF(props) {
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const { properties, currentDrawing } = qrDrawingStore();
  const { organizationId, projectId } = useParams();
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      debugger;
      createPdf(properties);
    }
  }, [ref.current, properties]);
  const createPdf = async (documents) => {
    const url = `${window.location.origin}/share/qr-drawing?organizationId=${organizationId}&projectId=${projectId}&fileId=${fileId}&qrId=${currentDrawing.data.id}`;
    const img = await QRCode.toDataURL(url);

    
    let yValues = [];
    let baseY = 20;
    let text = [];
    _.forEach(documents, (v) => {
      text.push(`${v.name} :${v.value}`);
      yValues.push(baseY);
      baseY = baseY +20;
    });
    let min = Math.max(
      135,
      yValues.length !== 0 ?Math.abs( yValues[yValues.length - 1] + 50 ): 0
    );

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([350, min]);
    debugger;

    baseY = page.getHeight() - 20;
    page.drawText(text.join('\n'), {
      x: 10,
      y: baseY,
      size: textSize,
      // maxWidth: 350
    });
    // _.forEach(documents, (v,k) => {
    //   page.drawText(`${v.name} :${v.value}`, {
    //     x: 10,
    //     y: baseY,
    //     size: textSize,
    //     // color: rgb(0, 0.53, 0.71),
    //   });
    //   baseY =baseY -20
    // });

    const jpgImage = await pdfDoc.embedPng(img);
    const jpgDims = jpgImage.scale(0.5);
    page.drawImage(jpgImage, {
      x: page.getWidth() - jpgDims.width,
      y: page.getHeight() - jpgDims.height,
      width: jpgDims.width,
      height: jpgDims.height,
    });

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    ref.current.src = pdfDataUri;
  };
  const handleReload = () => {
    const url = `${window.location.origin}/share/qr-drawing?organizationId=${organizationId}&projectId=${projectId}&fileId=${fileId}&qrId=${currentDrawing.data.id}`;
    navigator.clipboard.writeText(url);
  };
  return (
    <div style={{ width: '50%', height: '100%' }}>
      <IconTextButton
        icon={'copy'}
        type='save'
        size='ssmall'
        onClick={handleReload.bind(this)}
      >
       Copy
      </IconTextButton>
      <div style={{ width: '100%', height: 'calc(100% - 35px)' }}>
        <iframe ref={ref} style={{ width: '100%', height: '100%' }}></iframe>
      </div>
    </div>
  );
}
