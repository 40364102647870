/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Upload } from 'antd';
import _ from 'lodash';
import { qrDrawingStore } from '../QRDrawing.store';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import PropertyItem from './PropertyItem';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import { AppAPI, ViewerAPI } from '../../../../../../api';

export default function AddDocument({ isView }) {
  const {
    documents,
    setAddDocument,
    setQRDrawingLoading,
    setRemoveDocument,
    setDocuments,
  } = qrDrawingStore();
  useEffect(() => {
    return () => {
      setDocuments([]);
    };
  }, []);

  const handleImportFile = async (info) => {
    setQRDrawingLoading(true);
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        setAddDocument(info.file);
      }
    }
    setQRDrawingLoading(false);
  };
  const handleDelete = (item) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setRemoveDocument(item);
  };
  const handleDownload = async (item) => {
    let resLink = await AppAPI.S3API.GetPublicLink({ key: item.key });
    window.open(resLink);
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          overflow: 'auto',
          height: 200,
        }}
      >
        {documents.map((v, k) => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{v.name}</div>
            {isView ? (
              <IconButton
                type='save'
                size='ssmall'
                icon={'download'}
                onClick={handleDownload.bind(this, v)}
              />
            ) : (
              <IconButton
                type='delete'
                size='ssmall'
                icon={'delete'}
                onClick={handleDelete.bind(this, v)}
              />
            )}
          </div>
        ))}
      </div>
      {!isView && (
        <Upload
          style={{ width: '100%' }}
          customRequest={handleImportFile}
          showUploadList={false}
          className='idd-upload-input'
        >
          <IconTextButton icon={'upload'} size='ssmall' type='save'>
            Add Document
          </IconTextButton>
        </Upload>
      )}
    </>
  );
}
