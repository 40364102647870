/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message,
    Select
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { AppAPI } from '../../../../../api';



export default function ModalDMSSettingAddMemberGroup() {
    const { setIsModalDMSSettingAddMemberGroup, selectedConsultantGroupId,
        consultantGroup, setConsultantGroup,
        dmsSettingGridApi } = dmsStore()
    const { setIsLoading, } = appStore()
    const [users, setUsers] = useState([])
    const [userId, setUserId] = useState()
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            const users = await AppAPI.AdminAPI.GetAllUserInOrganization({ isIncludes: true })
            setUsers(users)
        } catch (ex) {
            message.warning(ex)
        }
        setIsLoading(false)
    }
    const close = () => {
        setIsModalDMSSettingAddMemberGroup(false)
    }

    const handleOk = async (e) => {
        if (!userId) {
            message.warning('Please select company name')
            return
        }
        setIsLoading(true)
        try {
            const userData = await AppAPI.DMSettingAPI.CreateDMSSettingMemberGroup({
                userId, id: selectedConsultantGroupId
            })
            await updateTableAsync(dmsSettingGridApi.api, { add: [userData] })
            const index = consultantGroup.findIndex(v => { return v.id === selectedConsultantGroupId })
            if (index >= 0) {
                consultantGroup[index].member_length++
                setConsultantGroup([...consultantGroup])
            }
            setIsLoading(false)
            // close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
        }
    }

    const handleCompany = (e) => {
        setUserId(e)
    }

    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Add Member'}
                style={{ width: 300 }}
            >



                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Company name:</span>
                    <Select placeholder='Group name' onChange={handleCompany}
                        value={userId} >
                        {users.map(v =>
                            <Select.Option value={v.id} > {v.email} </Select.Option>

                        )}
                    </Select>
                </div>




            </ModalTemplate>
        </>
    );
}

