import { useEffect, useState } from 'react';
import { workerStore } from '../../../../../store/Worker.store';
import IconButton from '../../../../gen/button/IconButton.gen';

export default function BlockZoneCell(props) {
  const [name, setName] = useState('');
  const { setIsOpenTrackingModel, setNodeEditWorkerDaily } = workerStore();
  useEffect(() => {
    if (props.node.data?.block_id) {
      setName(
        `${props.node.data.block} - ${props.node.data.level} - ${props.node.data.zone} `
      );
    }
  }, [props.node.data?.block_id]);

  const handleClick = () => {
    setNodeEditWorkerDaily(props.node);
    setIsOpenTrackingModel(true);
  };
  useEffect(() => {
    props.node.data[`${props.column.colId}_processed`] = name;
  }, [name]);
  return (
    name && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {name}
        <div>
          <IconButton
            size='sssmall'
            icon='tracking'
            disabled={!props.node.data?.path}
            onClick={handleClick}
          />
        </div>
      </div>
    )
  );
}
