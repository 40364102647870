/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Modal,
  message,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { viewerStore } from '../../../../../../store/Viewer.store';
import _ from 'lodash';
import { viewerOptions } from '../../../Viewer.constant';
import { appStore } from '../../../../../../store/App.store';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default function SelectViewModal({
  otherViews,
  setOtherViews,
  setItemSelected,
  itemSelected,
  setLoading,
}) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { viewer, listModel, setListModel } = viewerStore();
  const { setIsLoading } = appStore();
  const [view, setView] = useState();
  const close = () => {
    setItemSelected();
    setOtherViews();
  };

  const handleOk = async (e) => {
    if (!view) return;
    setIsLoading(true);
    const models = viewer.impl.modelQueue().getModels();
    let index1 = _.findIndex(models, (v) => {
      return v.myData.loadOptions.fileId === itemSelected.fileId;
    });
    if (index1 >= 0) {
      message.warning('This file was loaded');
      return;
    }
    let index = _.findIndex(otherViews, (v) => {
      return v.data.guid === view;
    });
    // let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
    const svfUrl = itemSelected.doc.getViewablePath(otherViews[index]);
    const aecModelData = await itemSelected.doc.downloadAecModelData();
    const globalOffset = viewer.model.getData().globalOffset;
    const loadOptions = {
      ...viewerOptions,
      ...{
        fileId: itemSelected.fileId,
        versionId: itemSelected.versionId,
        guid: otherViews[index].data.guid,
        modelNameOverride: itemSelected.fileName,
        aecModelData: aecModelData,
        globalOffset,
      },
    };

    viewer.loadModel(
      svfUrl,
      loadOptions,
      onLoadModelSuccess.bind(this),
      onLoadModelError.bind(this)
    );
  };
  const handleChange = (ev) => {
    setView(ev);
  };
  const onLoadModelSuccess = (e) => {
    setIsLoading(false);
    close();
  };
  const onLoadModelError = (e) => {
    console.log(e);
    message.warning('Cannot load this file');
    setIsLoading(false);
  };

  return (
    <>
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}  >
        <div style={{ width: '50%', height: '100%', padding: 10, backgroundColor: 'white' }} >
          <div display >
            <h4>Views</h4>
          </div>
          <p />
          
        </div>

      </div> */}
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={itemSelected.fileName}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '50%' }}
      >
        <Select
          showSearch
          style={{ width: '100%' }}
          zIndex={10000000}
          onChange={handleChange}
          value={view}
        >
          {otherViews?.map((i) => (
            <Select.Option key={i?.data.guid} value={i?.data.guid}>
              {' '}
              {i?.data.name}{' '}
            </Select.Option>
          ))}
        </Select>
      </ModalTemplate>
    </>
  );
}
