import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Timeline, DataSet } from "vis-timeline/standalone";
import moment from "moment";
import { Select, message } from "antd";
import { dfmaStore } from "../DFMA.store";
import { viewerStore } from "../../../../../../../store/Viewer.store";
import { convertHexColorToVector4, getAllModelsElementdbIdsWithCondition } from "../../../../../../../functions/Viewer.function";
import IconTextButton from "../../../../../../gen/button/IconTextButton.gen";
import { timelineChangeProgress } from "../DFMA.functions";
import { CATEGORY_PPVC, DEFAULT_COLOR } from "../DFMA.constant";


export default function TimelineProgress({ refDataTimeLine }) {
  const { progressTimeline,
    setProgressTimeline,
    rowDataTab, parameters,
    progressTimelineData,
    setProgressTimelineData,
    setDFMALoading,
    timelineCategory,
    setTimelineCategory,
    setTimelineCurrentTime } = dfmaStore()
  const { viewer, treeModelData } = viewerStore()

  const [showAllElement, setShowAllElement] = useState(true);
  const [modeDisplayTimeline, setModeDisplayTimeline] = useState("M");

  useEffect(() => {
    run()
  }, [timelineCategory]);

  const run = async () => {
    if (parameters.length === 0 || !parameters) {
      alert('You need setup parameter')
      return
    }
    setDFMALoading(true)

    const models = viewer.impl.modelQueue().getModels();
    for (var k in treeModelData.selector) {
      let index = _.findIndex(models, v => v.id == k)
      if (index >= 0) {
        refDataTimeLine.current.allSelector =
          refDataTimeLine.current.allSelector.concat({ model: models[index], ids: treeModelData.selector[k] })
      }

    }
    let result = []
    let parameter = _.find(parameters, v => v.name === 'Name')
    let actionMinDate = moment(new Date(2999, 1, 1))
    let actionMaxDate = moment(new Date(1999, 1, 1))

    _.forEach(rowDataTab, v => {
      // if (v.category !== timelineCategory) return
      let status = ''
      let listTime = []
      if (v.casting_date) {
        status = 'Casting Completed'
        let parse = moment(v.casting_date)
        listTime.push({ time: parse.valueOf(), status })
        if (actionMinDate.isAfter(parse)) {
          actionMinDate = parse
        }
        if (actionMaxDate.isBefore(parse)) {
          actionMaxDate = parse
        }
      }
      if (v.delivery_date) {
        status = 'Delivered to Site'
        let parse = moment(v.delivery_date)
        listTime.push({ time: parse.valueOf(), status })
        if (actionMinDate.isAfter(parse)) {
          actionMinDate = parse
        }
        if (actionMaxDate.isBefore(parse)) {
          actionMaxDate = parse
        }
      }
      if (v.install_date) {
        status = "Installed on Site"
        let parse = moment(v.install_date)
        listTime.push({ time: parse.valueOf(), status })
        if (actionMinDate.isAfter(parse)) {
          actionMinDate = parse
        }
        if (actionMaxDate.isBefore(parse)) {
          actionMaxDate = parse
        }
      }

      let selector = []
      if (parameter.value) {
        let nodes = treeModelData.findNodesByParameter(parameter.value, v.name);
        if (nodes.length !== 0) {

          let select = {}
          _.forEach(nodes, node => {
            if (!select[node.modelId]) {
              select[node.modelId] = []
            }
            select[node.modelId].push(node.dbId)
          })
          const models = viewer.impl.modelQueue().getModels();
          _.forEach(select, (v, k) => {
            let index = _.findIndex(models, i => i.id == k)
            if (index >= 0) {
              selector.push({ model: models[index], ids: v })
            }
          })
        }
      }

      result.push({ listTime, status, selector, category: v.category })
    })

    if (!actionMinDate)
      actionMinDate = moment(new Date(2000, 1, 1))
    if (!actionMaxDate)
      actionMaxDate = moment(new Date(2099, 1, 1))
    refDataTimeLine.current.timeValue = {
      min: actionMinDate.startOf("months"),
      max: actionMaxDate.endOf("months"),
    };
    refDataTimeLine.current.actionMaxDate = actionMaxDate.toDate();
    refDataTimeLine.current.actionMinDate = actionMinDate.toDate();
    setProgressTimelineData(result)
    setDFMALoading(false)
  };

  useEffect(() => {
    if (progressTimelineData.length !== 0) {
      generateTimelineChart()
    }
  }, [progressTimelineData])
  const generateTimelineChart = () => {
    if (progressTimeline !== null) progressTimeline.destroy();
    var container = document.getElementById("timeline-progress-ppvc");
    let groups = new DataSet();
    groups.add({ id: 0, content: "Casting Completed" });
    groups.add({ id: 3, content: "Delivered to Site" });
    groups.add({ id: 4, content: "Installed on Site" });

    let items = new DataSet(
      generateItemforTimeline(
        modeDisplayTimeline,
        refDataTimeLine.current.actionMaxDate,
        refDataTimeLine.current.actionMinDate,
        true
      )
    );
    var options = {
      width: "100%",
      height: "100%",
      horizontalScroll: true,
      zoomKey: "ctrlKey",
      verticalScroll: true,
      stack: false,
      stackSubgroups: false,
      tooltip: { followMouse: true, overflowMethod: "cap" },
      min: new Date(2000, 1, 1), // lower limit of visible range
      max: new Date(2100, 1, 1),
      zoomMin: 1000 * 60 * 60 * 24 * 17,
    };
    let timelinepanel = new Timeline(container, items, groups, options);

    refDataTimeLine.current.groups = groups;
    refDataTimeLine.current.items = items;
    setProgressTimeline(timelinepanel);

    timelinepanel.addCustomTime(
      moment(refDataTimeLine.current.actionMinDate).startOf("month"),
      "custom-timeline-start-progress-ppvc"
    );
    timelinepanel.addCustomTime(
      moment(refDataTimeLine.current.actionMaxDate).endOf("month"),
      "custom-timeline-end-progress-ppvc"
    );
    timelinepanel.addCustomTime(
      refDataTimeLine.current.currentTime,
      "custom-timeline-progress-ppvc"
    );
    showElementByDateRange();
  };


  useEffect(() => {
    if (progressTimeline) {
      progressTimeline.on("rangechanged", moveTimelineToCurrentDate);
      progressTimeline.on("timechange", timelineChange);
      progressTimeline.on("doubleClick", doubleClickTimeline);
      progressTimeline.on("select", selectTimeline);
      return () => {
        progressTimeline.off("rangechanged", moveTimelineToCurrentDate);
        progressTimeline.off("timechange", timelineChange);
        progressTimeline.off("doubleClick", doubleClickTimeline);
        progressTimeline.off("select", selectTimeline);
      };
    }
  }, [progressTimeline, refDataTimeLine.current, showAllElement]);
  const timelineChange = (e) => {
    timelineChangeProgress(
      progressTimeline,
      refDataTimeLine.current.items,
      e,
      refDataTimeLine.current,
      "custom-timeline-start-progress-ppvc",
      "custom-timeline-end-progress-ppvc",
      "custom-timeline-progress-ppvc",
      "progressChart",
      {
        showElementByDateRange,
      }
    );
    setTimelineCurrentTime(moment(e.time))
  };
  const moveTimelineToCurrentDate = (e) => {
    if (!e.byUser) {
      progressTimeline.setWindow(
        moment(refDataTimeLine.current.actionMinDate)
          .startOf("month")
          .subtract(1, "months"),
        moment(refDataTimeLine.current.actionMaxDate)
          .endOf("month")
          .add(1, "months")
      );
      progressTimeline.off("rangechanged", moveTimelineToCurrentDate);
    }
  };
  const doubleClickTimeline = (e) => {
    if (e.what == "item") {
      // handleTableDetail(refDataTimeLine.current.items.get(e.item));
    } else {
      let check = true;
      if (e.customTime === "custom-timeline-start-progress-ppvc") {
        check = false;
      } else if (e.customTime === "custom-timeline-end-progress-ppvc") {
        check = false;
      }
      if (check)
        progressTimeline.setWindow(
          moment(refDataTimeLine.current.actionMinDate)
            .startOf("month")
            .subtract(1, "months"),
          moment(refDataTimeLine.current.actionMaxDate)
            .endOf("month")
            .add(1, "months")
        );
    }
  };
  const selectTimeline = (properties) => {
    debugger
    var item = refDataTimeLine.current.items.get(properties.items[0]);
    let temp = [];
    _.forEach(item.selectors, (v) => {
      temp = temp.concat(v.selector);
    });
    viewer.impl.selector.setAggregateSelection(temp)
  };


  const generateItemforTimeline = (
    check,
    actionMaxDate,
    actionMinDate,
    first = false
  ) => {
    let temp = {};
    _.forEach(progressTimelineData, (v) => {
      debugger
      if (v.category !== timelineCategory) return
      _.forEach(v.listTime, (list) => {
        let date = "";
        if (check === "D") date = moment(list.time).format("YYYY-MM-DD");
        else if (check === "M") date = moment(list.time).format("YYYY-MM");
        else if (check === "Y") date = moment(list.time).format("YYYY");
        if (!temp[date]) temp[date] = [];
        temp[date].push({
          selector: v.selector,
          status: v.status,
        });
      });
    });
    debugger
    let tempn = [
      {
        id: 1,
        start: moment(new Date(0, 1, 1)).startOf("month"),
        end: first
          ? moment(actionMinDate).startOf("month")
          : refDataTimeLine.current.timeValue.min,
        type: "background",
        className: "custom-background-start",
      },
      {
        id: 2,
        start: first
          ? moment(actionMaxDate).endOf("month")
          : refDataTimeLine.current.timeValue.max,
        end: moment(new Date(2500, 1, 1)).startOf("month"),
        type: "background",
        className: "custom-background-end",
      },
    ];
    let listStatus = [
      "Casting Completed",
      "Delivered to Fit-out Yard",
      "Fit Out Completed",
      "Delivered to Site",
      "Installed on Site",
    ];
    let count = 3;
    _.forEach(temp, (item, key) => {
      _.forEach(listStatus, (v1, k1) => {
        let d = _.filter(item, (o) => {
          return o.status.includes(v1);
        });
        delete d.status
        if (check === "D")
          tempn.push({
            id: count,
            start: moment(key).startOf("day"),
            end: moment(key).endOf("day"),
            content: d.length.toString(),
            group: k1,
            className: v1.replace(/\s/g, ""),
            selectors: d,
          });
        else if (check === "M")
          tempn.push({
            id: count,
            start: moment(key).startOf("month"),
            end: moment(key).endOf("month"),
            content: d.length.toString(),
            group: k1,
            className: v1.replace(/\s/g, ""),
            selectors: d,
          });
        else if (check === "Y")
          tempn.push({
            id: count,
            start: moment(key).startOf("year"),
            end: moment(key).endOf("year"),
            content: d.length.toString(),
            group: k1,
            className: v1.replace(/\s/g, ""),
            selectors: d,
          });
        count++;
      });
    });
    return tempn;
  };

  const showElementByDateRange = () => {
    viewer.showAll();
    viewer.clearThemingColors(viewer.model);
    if (!showAllElement) {
      refDataTimeLine.current.allSelector.forEach(o => {
        let model = o.model
        _.forEach(o.ids, i => {
          viewer.hide(i, model);
        })
      })
    }
    let colorCasted = convertHexColorToVector4(DEFAULT_COLOR.CASTED);
    let colorDelivered = convertHexColorToVector4(DEFAULT_COLOR.DELIVERY);
    let colorInstalled = convertHexColorToVector4(DEFAULT_COLOR.INSTALL);
    // let colorDeliveredToFitout = convertHexColorToVector4("#52c41a")
    // let colorCompletedFitout = convertHexColorToVector4("#fadb14")
    debugger
    _.forEach(progressTimelineData, (v) => {
      if (v.status !== "") {
        _.forEach(v.listTime, (list) => {
          if (
            refDataTimeLine.current.timeValue.min.valueOf() <= list.time &&
            refDataTimeLine.current.currentTime.valueOf() >= list.time
          ) {
            if (list.status === "Casting Completed") {
              v.selector.forEach(o => {
                let model = o.model
                _.forEach(o.ids, i => {
                  viewer.setThemingColor(i, colorCasted);
                  viewer.show(i, model);
                })
              })

            } else if (list.status === "Delivered to Site") {
              v.selector.forEach(o => {
                let model = o.model
                _.forEach(o.ids, i => {
                  viewer.setThemingColor(i, colorDelivered);
                  viewer.show(i, model);
                })
              })
            } else if (list.status === "Installed on Site") {
              v.selector.forEach(o => {
                let model = o.model
                _.forEach(o.ids, i => {
                  viewer.setThemingColor(i, colorInstalled);
                  viewer.show(i, model);
                })
              })
            }
          }
        });
      }
    });
  };
  const handleChangeMode = (e) => {
    refDataTimeLine.current.items.clear();
    refDataTimeLine.current.items.add(generateItemforTimeline(e));
    setModeDisplayTimeline(e);
  };
  const handleChangeCategory = (v) => {
    setTimelineCategory(v)
  }
  return (
    <>
      <div
        style={{ display: "flex", gap: 5, justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          <IconTextButton
            icon={"reload"}
            size="ssmall"
            type={"save"}
            onClick={() => {
              run()
            }}
            disabled={timelineCategory === 'All'}
          >Reload</IconTextButton>
          {timelineCategory !== 'All' && <IconTextButton
            icon={"view"}
            size="ssmall"
            type={showAllElement ? "save" : "regular"}
            onClick={() => setShowAllElement(!showAllElement)}
            disabled={timelineCategory === 'All'}
          >Show All Element</IconTextButton>}
        </div>
        <div style={{ gap: 5, display: 'flex', }}>
          <Select style={{ minWidth: 50, maxWidth: 100, width: 100 }}
            value={timelineCategory} onChange={handleChangeCategory} >
            <Select.Option key={'All'} value={'All'}  > {'All'} </Select.Option>
            {CATEGORY_PPVC.map(v =>
              <Select.Option key={v} value={v}  > {v} </Select.Option>
            )}
          </Select>

          <Select
            value={modeDisplayTimeline}
            style={{ zIndex: 1000000 }}
            onChange={handleChangeMode}
            popupClassName="bim-precast-select"
            disabled={timelineCategory === 'All'}
          >
            <Select.Option key={"D"}>D</Select.Option>
            <Select.Option key={"M"}>M</Select.Option>
            <Select.Option key={"Y"}>Y</Select.Option>
          </Select>

        </div>

      </div>
      <p />
      <div
        id="timeline-progress-ppvc"
        style={{
          backgroundColor: "white",
          color: "#000",
          position: "absolute !important",
          width: "100%",
          bottom: 0,
          margin: 0,
          height: "300px",
          display: timelineCategory === 'All' ? 'none' : 'block'
        }}
      />

    </>
  );
}
