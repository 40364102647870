/* eslint-disable no-unused-vars */
import React, { } from 'react';
import {
    Progress
} from 'antd';
import _ from 'lodash';
import IconStatic from '../../../../../gen/icon/IconStatic.gen';



export default function UploadProgress({ done, total }) {




    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 5, alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconStatic icon={'warning'} size='small' />
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Please do not close the app when upload</span>
                </div>


                <Progress percent={done / total * 100} showInfo={false} strokeColor={'#2b435f'}
                    strokeWidth={15}
                />
                <span style={{ fontSize: 16 }}>
                    Uploaded:
                    <span style={{ fontWeight: 'bold' }} > {`${done}`}</span>
                    <span> | </span>
                    Total:
                    <span style={{ fontWeight: 'bold' }}>
                        {` ${total}`}
                    </span>
                </span>

            </div>
        </>
    );
}

