import React, { useEffect, useState } from "react";
import { Result, Button, message, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import DrawerViewer from "../../../../gen/drawer/Drawer.gen";
import { viewerStore } from "../../../../../store/Viewer.store";
import _ from "lodash";

import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import ViewList from "./View.list";
import ViewAdd from "./View.add";
import ViewDetail from "./View.detail";
import Loader from "../../../../loader/LoaderApp";
const { Option } = Select;
export default function IssueFeature(props) {
    const {  viewDetail, setViewData, setViewDetail } = viewerStore()
    const [loading, setLoading] = useState()
    const [type, setType] = useState('list')
    useEffect(() => {
        return () => {
            setViewData([])
            setViewDetail(null)
        }
    }, [])
    const handleAdd = () => {
        setType('add')
    }

    return (
        <>
            {loading && <div style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 11111111 }} >
                <Loader />
            </div>}
            <div style={{ display: type === 'list' ? 'block' : 'none', height: '100%' }}>
                <div style={{ padding: 10, }}>
                    <Button className={`idd-custom-button block save`} onClick={handleAdd} loading={loading}
                    >Add</Button>
                </div>
                <div style={{ height: 'calc(100% - 50px)', overflow: 'auto', width: '100%', position: 'relative' }}>
                    <ViewList
                        setType={setType}
                        setLoading={setLoading}
                        loading={loading}
                    />
                </div>
            </div>

            {type === 'add' &&
                <ViewAdd
                    setType={setType}
                    setLoading={setLoading}
                    loading={loading}
                />
            }
            {(type === 'detail' && viewDetail) &&
                <ViewDetail
                    setType={setType}
                    setLoading={setLoading}
                    loading={loading}
                />
            }

        </>
    );
}


