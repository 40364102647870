import { ReactComponent as CloseIcon } from 'src/assets/icons/general/close.svg';
import { ReactComponent as InforCircleIcon } from 'src/assets/icons/general/info-circle.svg';
/**
 * @param {string[]} permissionsMissing
 * @returns
 */
const AlertPermission = ({ permissionsMissing = [], handleDismiss }) => {
  return (
    <div
      className='mb-4 flex min-w-[350px] rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400'
      role='alert'
    >
      <InforCircleIcon className='size-5 pr-1' />
      <span className='sr-only'>Danger</span>
      <div className='w-full'>
        <span className='font-medium'>You missing perrmissions:</span>
        <ul className='mt-1.5 max-h-[300px] list-inside list-disc overflow-auto'>
          {permissionsMissing.map((v) => (
            <li>{v.toUpperCase()}</li>
          ))}
        </ul>
      </div>
      <button
        onClick={handleDismiss}
        className='absolute right-0 top-0 ml-4 p-4 text-red-800 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300'
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default AlertPermission;
