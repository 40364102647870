import React, { useRef } from 'react';
import _ from 'lodash';
import { dfmaStore } from '../DFMA.store';
// import TimelineTabToolbar from './TimelineTabToolbar';
import TimelineProgress from './TimelineProgress';
import TimelineChart from './TimelineChart';
import moment from 'moment';
import TimelineChartOverall from './TimelineChartOverall';

export default function TimelineTab() {
  const {timelineCategory} = dfmaStore()
  const refDataTimeLine = useRef({
    items: null,
    groups: null,
    allSelector: [],
    currentTime: moment()
  });

  return (
    <>
      <div style={{
        height: 'calc(100vh - 260px)',
        display: 'flex',
        flexDirection: 'column', overflow: 'auto'
      }} >
                <TimelineProgress refDataTimeLine={refDataTimeLine} />
        {timelineCategory !== 'All' ? 
             <TimelineChart refDataTimeLine={refDataTimeLine} />
          :
          <TimelineChartOverall refDataTimeLine={refDataTimeLine} />
        }


      </div>
    </>

  );
}
