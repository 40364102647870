/* eslint-disable no-unused-vars */
import { message } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AppAPI } from 'src/components/api';
import DrawingSafetyArea from 'src/components/layout/viewer/util/DrawingSafetyArea.util';
import { appStore } from '../../../../../store/App.store';
import { viewerStore } from '../../../../../store/Viewer.store';
import IconButton from '../../../../gen/button/IconButton.gen';
import IconStatic from '../../../../gen/icon/IconStatic.gen';

export default function SafetyAreaList({ isViewer, blockId }) {
  const { setIsLoading } = appStore();
  const { viewer } = viewerStore();
  const [drawingSafetyArea, setDrawingSafetyArea] = useState();
  const [isEnabled, setIsEnabled] = useState();
  const [safetyAreaList, setSafetyAreaList] = useState([]);
  useEffect(() => {
    if (drawingSafetyArea && viewer) {
      drawingSafetyArea.viewer = viewer;
    }
  }, [drawingSafetyArea, viewer]);
  useEffect(() => {
    if (drawingSafetyArea) {
      drawingSafetyArea.addEventListener(
        drawingSafetyArea.event.add,
        hanleAddDevice
      );
      drawingSafetyArea.addEventListener(
        drawingSafetyArea.event.update,
        hanleUpdateDevice
      );
      drawingSafetyArea.addEventListener(
        drawingSafetyArea.event.esc,
        handleEsc
      );
      return () => {
        drawingSafetyArea.removeEventListener(
          drawingSafetyArea.event.add,
          hanleAddDevice
        );
        drawingSafetyArea.removeEventListener(
          drawingSafetyArea.event.update,
          hanleUpdateDevice
        );
        drawingSafetyArea.addEventListener(
          drawingSafetyArea.event.esc,
          handleEsc
        );
      };
    }
  }, [drawingSafetyArea, safetyAreaList]);
  const handleEsc = () => {
    setIsEnabled(false);
  };
  const hanleAddDevice = async (e) => {
    setIsLoading(true);
    try {
      safetyAreaList.push({
        id: e.id,
        name: `Safety area ${safetyAreaList.length + 1}`,
        points: e.points,
        holes: e.holes,
      });
      setSafetyAreaList([...safetyAreaList]);
      setIsEnabled(false);
    } catch (e) {
      if (e.message) message.warning(e.message);
    }
    setIsLoading(false);
  };
  const hanleUpdateDevice = async (e) => {
    setIsLoading(true);
    try {
      const index = _.findIndex(safetyAreaList, (v) => v.id === e.id);
      if (index >= 0) {
        safetyAreaList[index].points = e.points;
        safetyAreaList[index].holes = e.holes;
        safetyAreaList[index].active = false;
      }
      setSafetyAreaList([...safetyAreaList]);
      setIsEnabled(false);
    } catch (e) {
      if (e.message) message.warning(e.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const runLocal = async () => {
      setIsLoading(true);
      try {
        const drawingSafetyArea = new DrawingSafetyArea(viewer);
        const result = await AppAPI.WorkerSettingAPI.GetOneBlockZone({
          id: blockId,
        });
        console.log(result);
        await drawingSafetyArea.init(result.safety_area);
        setDrawingSafetyArea(drawingSafetyArea);
        setSafetyAreaList(result.safety_area);
      } catch (ex) {
        message.warning(ex.message);
      }
      setIsLoading(false);

      return () => {
        if (drawingSafetyArea) {
          drawingSafetyArea.unload();
        }
      };
    };

    viewer.waitForLoadDone().then((res) => {
      runLocal();
    });
  }, []);
  const handleAdd = async (item) => {
    const isDone = await viewer?.isLoadDone();
    if (!isDone) {
      message.warning('You have to wait the model finish loading');
      return;
    }
    if (item.active) {
      drawingSafetyArea.setEnabled(false);
      item.active = false;
    } else {
      drawingSafetyArea.setEnabled(true, 2, item.id, item.points, item.holes);
      item.active = true;
    }

    setSafetyAreaList([...safetyAreaList]);
  };
  const handleDelete = async (item) => {
    setIsLoading(true);
    try {
      const index = _.findIndex(safetyAreaList, (v) => v.id === item.id);
      if (index >= 0) {
        safetyAreaList.splice(index, 1);
        drawingSafetyArea.removeMesh(item.id);
      }

      setSafetyAreaList([...safetyAreaList]);
    } catch (e) {
      if (e.message) message.warning(e.message);
    }
    setIsLoading(false);
  };
  const handleDrawing = () => {
    setIsEnabled(!isEnabled);
  };
  useEffect(() => {
    if (drawingSafetyArea) {
      drawingSafetyArea.setEnabled(isEnabled);
    }
  }, [drawingSafetyArea, isEnabled]);
  const hadnleSave = async () => {
    setIsLoading(true);
    try {
      const tmp = {
        id: blockId,
        safety_area: [],
      };
      safetyAreaList.forEach((v) => {
        tmp.safety_area.push({
          points: v.points,
          holes: v.holes,
          name: v.name,
        });
      });
      console.log(tmp);
      await AppAPI.WorkerSettingAPI.UpdateOneBlockZone({
        data: JSON.stringify(tmp),
      });
    } catch (ex) {
      message.warning(ex.message);
    }
    setIsLoading(false);
  };
  const handleEdit = (item) => {
    const name = window.prompt('Safety area name', item.name);
    if (name) {
      item.name = name;
      setSafetyAreaList([...safetyAreaList]);
    }
  };
  return (
    <>
      {!isViewer && (
        <div className='flex gap-1'>
          <IconButton
            type={`regular`}
            icon={'save'}
            size='sssmall'
            onClick={hadnleSave}
          />
          <IconButton
            type={`${isEnabled ? 'save' : 'regular'}`}
            icon={'edit'}
            size='sssmall'
            onClick={handleDrawing}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          gap: 5,
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>Safety Area</span>
        <div className='flex size-full h-[300px] flex-col overflow-auto'>
          {safetyAreaList?.map((v) => (
            <div
              style={{
                display: 'flex',

                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <div className='flex gap-1'>
                <IconStatic size='sssmall' icon='area' />
                <span className='overflow-hidden'>{`${v.name}`}</span>
              </div>

              <div style={{ display: 'flex', gap: 5 }}>
                {!isViewer && (
                  <>
                    <IconButton
                      size='sssmall'
                      icon='hole'
                      type={v.active ? 'save' : 'default'}
                      onClick={handleAdd.bind(this, v)}
                    />
                    <IconButton
                      size='sssmall'
                      icon='edit'
                      type={'default'}
                      onClick={handleEdit.bind(this, v)}
                    />
                    <IconButton
                      size='sssmall'
                      icon='delete'
                      type={'delete'}
                      onClick={handleDelete.bind(this, v)}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
