import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import AlertLogin from 'src/components/atoms/AlertLogin';
import AppLogo from 'src/components/atoms/AppLogo';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import TermCheckbox from 'src/components/molecules/TermCheckbox';

/**
 * @param {function(): void} onClickReset - Function to handle login submission.
 * @param {boolean} isLoading - Indicates if the form is submitting.
 * @param {any} error - Apollo error object if there was an error during login.
 * @returns {JSX.Element} The rendered SignInForm component.
 */
const ForgotPsswordForm = ({ onClickReset, error, isLoading }) => {
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const email = formData.get('email');
      onClickReset({ email });
    },
    [onClickReset]
  );

  return (
    <>
      <section className='bg-gray-50 dark:bg-gray-900'>
        <div className='mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0'>
          <div className='mb-6'>
            <AppLogo />
          </div>

          <div className='w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0'>
            <div className='space-y-4 p-6 sm:p-8 md:space-y-6'>
              <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl'>
                Forgot password
              </h1>
              <p className='!mt-2 text-sm font-light text-gray-500 dark:text-gray-400'>
                {` Don't fret! Just type in Email and we will send you a code to
                reset your password!`}
              </p>
              {error && <AlertLogin message={error} />}
              <form className='space-y-4 md:space-y-6' onSubmit={handleSubmit}>
                <LabelAndInputForm
                  label='Email'
                  type='email'
                  name='email'
                  placeholder='name@company.com'
                  isRequired={true}
                />
                <TermCheckbox />
                <PrimaryButton
                  isFull
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Forgot password
                </PrimaryButton>
                <p className='text-sm font-light text-gray-500 dark:text-gray-400'>
                  Already have an account?{' '}
                  <Link
                    to={'/'}
                    className='font-medium text-primary-600 hover:underline dark:text-primary-500'
                  >
                    Login here
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPsswordForm;
