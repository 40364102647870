import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'src/components/atoms/PrimaryButton';

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleGoToMainPage = () => {
    navigate('/');
  };
  return (
    <>
      <section className='h-full max-h-full bg-white dark:bg-gray-900'>
        <div className='mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16'>
          <div className='mx-auto max-w-screen-sm text-center'>
            <h1 className='mb-4 text-7xl font-extrabold tracking-tight text-red-600 dark:text-red-500 lg:text-9xl'>
              400
            </h1>
            <p className='mb-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white md:text-4xl'>
              Something's missing.
            </p>

            <div className='flex justify-center'>
              <PrimaryButton onClick={handleGoToMainPage} isFull={false}>
                Back to Main page
              </PrimaryButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
