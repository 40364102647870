import { useNavigate } from 'react-router-dom';
import IconButton from '../../../gen/button/IconButton.gen';
import CardApp from '../../../gen/card/CardApp.gen';
import icons from '../../../gen/constant/Icon.constant';

const data = [
  {
    name: 'Checklist',
    logo: icons.checklist,
    app: 'checklist',
  },
  // {
  //   name: 'VR',
  //   logo: icons.vr,
  //   app: 'vr',
  // },
];
export default function DigicheckPage() {
  const navigate = useNavigate();
  const handleViewItem = (app) => {
    navigate(`${app}`);
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        <IconButton icon={'back'} border={false} onClick={handleBack} />
        <span style={{ fontSize: 18, fontWeight: '700' }}>Digicheck</span>
      </div>
      <div className='idd-list'>
        {data.map((v, idx) => (
          <CardApp key={idx} {...v} handleViewItem={handleViewItem} />
        ))}
      </div>
    </div>
  );
}
