import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import { appStore } from "../../../../../../store/App.store";
import { message } from 'antd'
import IconButton from "../../../../../gen/button/IconButton.gen";
import { autoGroupColumnDef, defaultColDef } from "../../../../../../settings/General.settings";
import { dmsStore } from "../../../../../../store/DMS.store";
import { AppAPI } from "../../../../../api";
import { updateTableAsync } from "../../../../../../functions/General.function";


export default function DMSSettingTable() {
    const { setIsLoading } = appStore()
    const gridRef = useRef();
    const { setDMSSettingGridApi, selectedConsultantGroupId, dmsSettingGridApi,
        consultantGroup, setConsultantGroup } = dmsStore()
    const [data, setData] = useState([])
    useEffect(() => {
        if (selectedConsultantGroupId) {
            setData([])
            run()
        } else {
            setData([])
        }
    }, [selectedConsultantGroupId])
    const run = async () => {
        setIsLoading(true)
        try {
            setTimeout(async () => {
                const groupMembers = await AppAPI.DMSettingAPI.GetDMSSettingMembersGroup({ id: selectedConsultantGroupId })
                setData(groupMembers)
                setIsLoading(false)
            }, 200);
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }

    }


    const handleDeleteItem = async (e) => {

        if (!window.confirm('Are you sure to delete?')) return
        setIsLoading(true)
        try {
            await AppAPI.DMSettingAPI.DeleteDMSSettingMemberGroupOne({ id: selectedConsultantGroupId, userId: e.node.data.id })
            await updateTableAsync(dmsSettingGridApi.api, { remove: [e.node.data] })
            debugger
            const index = consultantGroup.findIndex(v => { return v.id === selectedConsultantGroupId })
            if (index >= 0) {
                consultantGroup[index].member_length--
                setConsultantGroup([...consultantGroup])
            }

        } catch (ex) {

        }
        setIsLoading(false)
    }

    const columns = [
        {
            headerName: 'Email',
            field: 'email',
            filter: 'agSetColumnFilter',
            minWidth: 200,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: () => {
                            return (
                                <span style={{ fontWeight: 'bold' }} > {params.value}</span>
                            )

                        }
                    }
                }
                return undefined
            }
        },
        {
            headerName: 'Name',
            field: 'name',
            filter: 'agSetColumnFilter',
            width: 100,
            minWidth: 100,
        },
        {
            headerName: 'Type',
            field: 'type',
            filter: 'agSetColumnFilter',
            width: 100,
            minWidth: 100,
        },
        {
            headerName: '',
            lockPosition: 'right',
            suppressMovable: true,
            suppressMenu: true,
            suppressSorting: true,
            minWidth: 80, maxWidth: 80,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: handleAction,
                    }
                }
                return undefined;
            }
        },
    ]
    const handleAction = (params) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center', height: '100%' }}>
                <IconButton
                    icon={'delete'}
                    size='sssmall'
                    type={'delete'}
                    onClick={handleDeleteItem.bind(this, params)}
                />

            </div>
        )
    }
    const onGridReady = useCallback((params) => {
        setDMSSettingGridApi(params)
    }, []);

    return (
        <>
            <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    rowData={data}
                    columnDefs={columns}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    animateRows={true}
                    groupDisplayType='multipleColumns'
                    onGridReady={onGridReady}
                    suppressPaginationPanel={true}
                    suppressContextMenu={true}
                    rowHeight={30}
                    headerHeight={35}
                ></AgGridReact>


            </div>

        </>

    );
}


