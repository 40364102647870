import React, { } from "react";
import { adminStore } from "../../../store/Admin.store";

import CardProject from "./card/Card.project";

export default function ProjectListCard(props) {
    const { projects } = adminStore()

    return (
        <>

            <div className="idd-list" >
                {projects.map((v, idx) =>
                    <CardProject key={idx} {...v}  {...props} />

                )}

            </div>
        </>

    );
}


