import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Form, Checkbox, Radio, DatePicker, Select } from 'antd';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';

import IconButton from '../../../../../gen/button/IconButton.gen';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';
import moment from 'moment';
import { issueStore } from '../Issue.store';







export default function IssueGen({ image, isEdit = false, form, handleView, handleRefresh }) {
    const { user } = appStore()
    const { issueDetail } = issueStore()



    return (
        <>
            <div style={{ height: 'calc(100% - 75px)', overflow: 'auto', width: '100%', position: 'relative', padding: 5 }}>
                <div id={'issue-image'} style={{ backgroundImage: `url(${image})`, gap: 5, height: 200 }} >
                    {isEdit ?
                        <>
                            <IconButton
                                url={'https://img.icons8.com/external-kmg-design-detailed-outline-kmg-design/40/000000/external-view-user-interface-kmg-design-detailed-outline-kmg-design.png'}
                                size='small'
                                onClick={handleView}
                            />
                        </>
                        :
                        <>
                            <IconButton
                                url={'https://img.icons8.com/ios-glyphs/40/000000/refresh--v1.png'}
                                size='small'
                                onClick={handleRefresh}
                            />
                        </>
                    }

                </div>

                <Form
                    form={form}
                    autoComplete={false}
                    layout="vertical"
                // disabled
                >
                    <Form.Item
                        name='title'
                        rules={[{ required: true, message: 'Please input your title!', },]}
                        label='Title' initialValue={issueDetail?.title}
                    >
                        <Input className='idd-input' disabled={isEdit ? issueDetail?.created_by !== user?.email : false}
                            placeholder="Title"
                        />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        rules={[{ required: true, message: 'Please input your description!', },]}
                        label='Description'
                        initialValue={issueDetail?.description}
                    >
                        <Input.TextArea className='idd-input' disabled={isEdit ? issueDetail?.created_by !== user?.email : false}
                            placeholder="Description"
                        />
                    </Form.Item>
                    <Form.Item
                        name='discipline'
                        rules={[{ required: true, message: 'Please input your discipline!', },]}
                        label='Discipline'
                        initialValue={issueDetail?.discipline}
                    >
                        <Radio.Group
                            disabled={isEdit ? issueDetail?.created_by !== user?.email : false}
                        >
                            <Radio value="Archi">Archi</Radio>
                            <Radio value="C&S">C&S</Radio>
                            <Radio value="M&E">M&E</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='due_date'
                        rules={[{ required: true, message: 'Please input your due date!', },]}
                        label='Due Date'
                        initialValue={moment(issueDetail?.due_date)}
                    >
                        <DatePicker className='idd-input block' placeholder='Due Date' disabled={isEdit ? issueDetail?.created_by !== user?.email : false} />
                    </Form.Item>
                    <Form.Item
                        name='type'
                        rules={[{ required: true, message: 'Please input your type!', },]}
                        label='Type'
                        initialValue={issueDetail?.type}
                    >
                        <Select placeholder='Type' disabled={isEdit ? issueDetail?.created_by !== user?.email : false}
                            className='idd-input'>
                            <Select.Option value="Clash 1">Clash 1</Select.Option>
                            <Select.Option value="Clash 2">Clash 2</Select.Option>
                            <Select.Option value="Clash 3">Clash 3</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='category'
                        rules={[{ required: true, message: 'Please input category!', },]}
                        label='Category' initialValue={issueDetail?.category}
                    >
                        <Select placeholder='Category' disabled={isEdit ? issueDetail?.created_by !== user?.email : false}
                            className='idd-input'>
                            <Select.Option style={{ color: '#FFD966' }} value="Category 1">Category 1</Select.Option>
                            <Select.Option style={{ color: 'orange' }} value="Category 2">Category 2</Select.Option>
                            <Select.Option style={{ color: 'red' }} value="Category 3">Category 3</Select.Option>
                        </Select>
                    </Form.Item>
              {isEdit &&      <Form.Item
                        name='status'
                        rules={[{ required: true, message: 'Please input your status!', },]}
                        label='Status' initialValue={issueDetail?.status}
                    >
                        <Select placeholder='Status' className='idd-input'
                            disabled={isEdit ? issueDetail?.created_by !== user?.email : false}>
                            <Select.Option value="Open">Open</Select.Option>
                            <Select.Option value="Close">Close</Select.Option>
                        </Select>
                    </Form.Item>}
                    <Form.Item
                        name='action_part'
                        rules={[{ required: true, message: 'Please input action part!', },]}
                        label='Action part' initialValue={issueDetail?.action_part}
                    >
                        <Checkbox.Group
                            disabled={isEdit ? issueDetail?.created_by !== user?.email : false}
                        >
                            <Row wrap >
                                <Col span={8}>
                                    <Checkbox value="Maincon">Maincon</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="Consultant">Consultant</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="ACMV">ACMV</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="P&S">P&S</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="ELEC">ELEC</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="FP">FP</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="ARCH">ARCH</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="CIVIL">CIVIL</Checkbox>
                                </Col>
                            </Row>


                        </Checkbox.Group>
                    </Form.Item>

                </Form>
            </div>
        </>
    );
}



