import React, { useEffect } from 'react';
import _ from 'lodash';
import { vrConnectorStore } from './/VRConnector.store';
import VRConnectorTable from './table/VRConnector.table';
import VRConnectorToolbar from './VRConnector.toolbar';
import { useSearchParams } from 'react-router-dom';
import AddNewVRModelModal from './modal/AddNewVRModel.modal';
import { message } from 'antd';
import { AppAPI } from '../../../../../api';
import EditVRModelModal from './modal/EditVRModel.modal';
export default function VRConnectorLayout() {
  const {
    resetVRConnectorStore,
    setVRConnectorLoading,
    isAddNewVRModel,
    isOpenEditVRModel,
    nodeEditVRModel,
  } = vrConnectorStore();
  const [search] = useSearchParams();
  const projectId = search.get('projectId');
  const { setVRModelData } = vrConnectorStore();
  useEffect(() => {
    run();
    return () => {
      resetVRConnectorStore();
    };
  }, []);
  const run = async () => {
    setVRConnectorLoading(true);
    try {
      const data = await AppAPI.VDCVRAPI.getAllVRModelByProject({ projectId });
      setVRModelData(data);
    } catch (ex) {
      if (ex.message) {
        message.warning(ex.message);
      }
    }
    setVRConnectorLoading(false);
  };
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <VRConnectorToolbar />
        <VRConnectorTable />
      </div>

      {isAddNewVRModel && <AddNewVRModelModal />}
      {isOpenEditVRModel && nodeEditVRModel && <EditVRModelModal />}
    </>
  );
}
