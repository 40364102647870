/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AgGridReact } from 'ag-grid-react';
import { autoGroupColumnDef } from '../../../../../../settings/General.settings';
import FilePDFUploadCell from '../cell/FilePDFUpload.cell';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { updateTableAsync } from '../../../../../../functions/General.function';
import FileModelUploadCell from '../cell/FileModelpload.cell';



export default function DMSRFADrawingTable() {
    const { drawingList, setDMSRFADrawingListGridApi, dmsRFADrawingListGridApi } = dmsStore()
    const gridRef = useRef();

    const columns = [
        {
            headerName: "Drawing Number",
            field: "drawing_number",
            filter: 'agSetColumnFilter',
            minWidth: 400,
            maxWidth: 700,
            sort: 'desc',
        },
        {
            headerName: 'Drawing Name',
            field: 'drawing_name',
            filter: 'agSetColumnFilter',
            minWidth: 400,
            maxWidth: 700,
        },
        {
            headerName: 'Coordinator In Charge',
            field: 'coordinator_in_charge',
            filter: 'agSetColumnFilter',
            minWidth: 300,
            maxWidth: 700,
        },
        // {
        //     headerName: 'Rev',
        //     field: 'rev',
        //     filter: 'agSetColumnFilter',
        //     maxWidth: 80,
        //     minWidth: 80,
        // },
        {
            headerName: 'File PDF',
            field: 'pdf',
            filter: 'agSetColumnFilter',
            width: 300,
            minWidth: 300,
            maxWidth: 500,
            cellRendererSelector: (params) => {
                return {
                    component: FilePDFUploadCell
                };
            }
        },
        {
            headerName: 'File Model',
            field: 'model',
            filter: 'agSetColumnFilter',
            width: 300,
            minWidth: 300,
            maxWidth: 500,
            cellRendererSelector: (params) => {
                return {
                    component: FileModelUploadCell
                };
            }
        },
        {
            headerName: "",
            field: "action",
            minWidth: 50,
            maxWidth: 50,
            lockPosition: "right",
            pinned: 'right',
            suppressFilter: true,
            suppressMovable: true,
            suppressToolPanel: true,
            suppressSorting: true,
            suppressMenu: true,
            filter: false,
            cellRendererSelector: (params) => {
                const isFolder = params.node.data.is_folder
                if (!isFolder) {
                    return {
                        component: ActionButtonCell,
                    };
                }
                return undefined
            }
        },
    ]

    const ActionButtonCell = (params) => {
        return (
            <>
                <IconButton
                    icon={'delete'}
                    size='sssmall'
                    type={'delete'}
                    iconStyle={{ background: '#000000' }}
                    onClick={handleDelete.bind(this, params)}
                />
            </>
        )
    }
    const handleDelete = async (params) => {
        if (!window.confirm('Are you sure to delete?')) return
        await updateTableAsync(dmsRFADrawingListGridApi.api, { remove: [params.node.data] })
        dmsRFADrawingListGridApi.api.redrawRows()
    }
    const onGridReady = useCallback((params) => {
        setDMSRFADrawingListGridApi(params)
    }, []);
    return (
        <div style={{ display: 'flex', gap: 15, flexDirection: 'column', width: '100%', height: 350 }} >
            <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    rowData={drawingList}
                    columnDefs={columns}
                    defaultColDef={{
                        flex: 1,
                        resizable: true,
                        sortable: true
                    }}
                    autoGroupColumnDef={autoGroupColumnDef}
                    animateRows={true}
                    groupDisplayType='multipleColumns'
                    onGridReady={onGridReady}
                    suppressPaginationPanel={true}
                    groupDefaultExpanded={2}
                    suppressContextMenu={true}
                    rowHeight={30}
                    headerHeight={35}
                />
            </div>
        </div>
    );
}

