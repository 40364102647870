import { Route } from 'react-router-dom';

import DigicheckRoute from '../routes/digicheck/Digicheck.routes';

import { ChecklistPage, DigicheckPage } from 'src/components/pages/apps';
import ChecklistRoute from '../routes/digicheck/Checklist.routes';

export const DigiCheckAppRoutes = [
  <Route path=':projectId'>
    <Route path='digicheck' element={<DigicheckRoute />}>
      <Route index element={<DigicheckPage />} />
      <Route path='checklist' element={<ChecklistRoute />}>
        <Route index element={<ChecklistPage />} />
      </Route>
    </Route>
  </Route>,
];
