/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fiveDStore } from '../../../../store/FiveD.store';
import IconButton from '../../../gen/button/IconButton.gen';
import { viewerStore } from '../../../../store/Viewer.store';
import { AppAPI } from '../../../api';
import _ from 'lodash';
import { appStore } from '../../../../store/App.store';
import { message, Input, DatePicker as DatePickerDayjs } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import moment from 'moment';
import {
  clearColorAllModel,
  convertHexColorToVector4,
} from '../../../../functions/Viewer.function';
import { updateTableAsync } from '../../../../functions/General.function';
import { workDoneProgress } from './FiveD.utils';
const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function FiveDToolbar() {
  const {
    setIsOpen3D,
    isOpen3D,
    setIsOpenAddRow,
    setIsOpenAddColumn,
    sheetColumns,
    sheetGridApi,
    workDoneDate,
    setWorkDoneDate,
  } = fiveDStore();
  const { viewer, treeModelData } = viewerStore();
  const { setIsLoading } = appStore();

  useEffect(() => {}, []);
  const handleAddColumn = () => {
    setIsOpenAddColumn(true);
  };
  const handleAddRow = () => {
    setIsOpenAddRow(true);
  };
  const handleOpen3D = () => {
    setIsOpen3D(!isOpen3D);
  };
  const handleAssginData = async () => {
    setIsLoading(true);
    debugger;
    const data = [];
    const nodes = [];
    sheetGridApi.api.forEachLeafNode(async (node) => {
      if (node.data.object_3d && viewer) {
        const index = _.findIndex(sheetColumns, (v) => {
          return v.id === 'design_qty';
        });
        if (index < 0) {
          return;
        }
        if (!sheetColumns[index].parameter) {
          return;
        }

        const selector = node.data.object_3d;
        const parameterName = sheetColumns[index].parameter;
        let total = 0;
        for (const index in selector) {
          const element = selector[index];
          _.forEach(element.ids, (exId) => {
            const node = treeModelData.findNode(exId);
            if (node) {
              if (node.model.myData.loadOptions.fileId === element.fileId) {
                let value = node.getParameterValueByName(parameterName);
                if (value) {
                  const num = _.toNumber(value);
                  if (_.isNumber(num) && !_.isNaN(num)) {
                    total += num;
                  }
                }
              }
            }
          });
        }
        node.data.design_qty = total;
        nodes.push(node);
        data.push({
          id: node.data.id,
          design_qty: node.data.design_qty,
        });
      }
    });

    await AppAPI.FiveDSheetAPI.UpdateManySheetData({
      data,
    });
    await updateTableAsync(sheetGridApi.api, {
      update: [nodes],
    });
    setIsLoading(false);
  };
  const handleDate = (e) => {
    setWorkDoneDate(e);
    debugger;
    if (!viewer) return;
    setIsLoading(true);
    workDoneProgress(workDoneDate, sheetGridApi, treeModelData, viewer);
    setIsLoading(false);
  };
  return (
    <div style={{ padding: '0px 5px' }}>
      <div style={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: 1 }}>
          <IconButton
            icon={'add_row'}
            size='ssmall'
            onClick={handleAddRow}
            title={'Add Row'}
          />
          <IconButton
            icon={'add_column'}
            size='ssmall'
            onClick={handleAddColumn}
            title={'Add Column'}
          />
          <IconButton
            icon={'reload'}
            size='ssmall'
            onClick={handleAssginData}
            title={'Assign Data'}
          />
          <DatePicker
            size='small'
            // style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleDate.bind(this)}
            value={workDoneDate}
            picker='month'
          />
        </div>
        <div>
          <IconButton
            icon={'three_d'}
            size='ssmall'
            onClick={handleOpen3D}
            type={isOpen3D ? 'save' : 'regular'}
          />
        </div>
      </div>
    </div>
  );
}
