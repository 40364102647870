import React, { } from "react";
import { FileOutlined, FolderOutlined } from "@ant-design/icons";
import { message } from "antd";

export default function FileBrowserCell(props) {

  return (
    <>
      {props.data?.is_folder ? (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <FolderOutlined />
          <span> {props.data?.name}</span>
        </div>
      ) : (
        <div
          style={{ display: "flex", alignItems: "center", gap: 5 }}
        >
          <FileOutlined />
          <span> {props.data?.name}</span>
        </div>
      )}
    </>
  );
}