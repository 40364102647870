import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as PlusCircleIcon } from 'src/assets/icons/general/plus-circle.svg';
import PageHeader from 'src/components/atoms/PageHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { requestBimGrid } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import GroupPermissionTable from './GroupPermission.table';
import ModalAddGroupPermission from './modal/ModalAddGroupPermission';
import ModalEditGroupPermission from './modal/ModalEditGroupPermission';
export default function LayoutGroupPermission() {
  const { setIsLoading } = appStore();
  const [data, setData] = useState([]);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [rowNode, setRowNode] = useState();
  const [gridApi, setGridApi] = useState();
  const { organizationId, projectId } = useParams();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid(
      'get',
      '/api/admin/groupPermission/getAllByOrganizationId',
      null,
      { organizationId, projectId }
    );
    if (data) {
      setData(data);
    }
    setIsLoading(false);
  };
  const handleOpenAdd = () => {
    setIsModalAdd(true);
  };

  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader> Organization Group Permission</PageHeader>
          </>
        }
        right={
          <PrimaryButton onClick={handleOpenAdd}>
            <PlusCircleIcon className='size-4' />
            Add
          </PrimaryButton>
        }
      />

      <GroupPermissionTable
        data={data}
        setGridApi={setGridApi}
        setRowNode={setRowNode}
      />

      {isModalAdd && (
        <ModalAddGroupPermission gridApi={gridApi} setClose={setIsModalAdd} />
      )}
      {rowNode && (
        <ModalEditGroupPermission
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
