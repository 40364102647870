/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input, Select, DatePicker as DatePickerDayjs } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { workerStore } from '../../../../../../store/Worker.store';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { NATIONALITY, OCCUPATION } from '../../Worker.constant';
import {
  checkInput,
  updateTableAsync,
} from '../../../../../../functions/General.function';
import { AppAPI } from '../../../../../api';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalEditWorkerList() {
  const { setIsOpenEditWokerList, workerListGridApi, nodeEditWorkerList } =
    workerStore();
  const { setIsLoading } = appStore();
  const { projectId } = useParams();
  const [selectItems, setSelectItems] = useState({});
  const [data, setData] = useState([
    {
      name: 'Employee code',
      field: 'employee_code',
      value: null,
      type: 'input',
    },
    {
      name: 'Name',
      field: 'name',
      value: null,
      type: 'input',
    },
    {
      name: 'Company',
      field: 'company',
      value: null,
      type: 'input',
    },
    {
      name: 'Occupation',
      field: 'occupation',
      value: null,
      type: 'select',
      data: OCCUPATION,
    },
    {
      name: 'Designation',
      field: 'designation',
      value: null,
      type: 'input',
    },
    {
      name: 'Nationality',
      field: 'nationality',
      value: null,
      type: 'select',
      data: NATIONALITY,
    },
    {
      name: 'Transfer In Date',
      field: 'transer_in_date',
      value: moment(new Date()),
      type: 'date',
    },
    {
      name: 'Transfer out Date',
      field: 'transer_out_date',
      value: null,
      type: 'date',
    },
    {
      name: 'Tracking device',
      field: 'tracking_device_id',
      value: null,
      type: 'select',
      data: 'tracking_devices',
    },
  ]);
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);

    try {
      const trackingDevices =
        await AppAPI.WorkerSettingAPI.GetAllTrackingDevicesByProject({
          projectId,
          select: ['name', 'id'],
        });
      trackingDevices.splice(0, 0, { name: 'N/A', id: null });
      selectItems['tracking_devices'] = trackingDevices;
      setSelectItems({ ...selectItems });

      _.forEach(nodeEditWorkerList.data, (v, k) => {
        const index = _.findIndex(data, (i) => {
          return i.field === k;
        });
        if (index >= 0) {
          if (v) {
            if (data[index].type === 'date')
              data[index].value = moment(new Date(v));
            else data[index].value = v;
          }
        }
      });
      setData([...data]);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenEditWokerList(false);
  };

  const handleOk = async (e) => {
    if (!checkInput(data, ['employee_code', 'name'])) {
      return;
    }
    setIsLoading(true);
    try {
      debugger;
      const tmp = {};
      data.forEach((v) => {
        if (nodeEditWorkerList.data[v.field] !== undefined) {
          tmp[v.field] = v.value;
        }
      });
      const resWorker = await AppAPI.WorkerSettingAPI.UpdateOneWorkerList({
        id: nodeEditWorkerList.data.id,
        data: tmp,
      });
      _.forEach(resWorker, (v, k) => {
        if (nodeEditWorkerList.data[k] !== undefined) {
          nodeEditWorkerList.data[k] = v;
        }
      });
      await updateTableAsync(workerListGridApi.api, {
        update: [nodeEditWorkerList.data],
      });
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };
  const handleChange = (type, e) => {
    type.value = e.target.value;
    setData([...data]);
  };
  const handleChangeDate = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const handleChangeSelect = (type, e) => {
    type.value = e;
    setData([...data]);
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Edit worker'}
        style={{ width: 500 }}
      >
        {data.map((v) => (
          <>
            {v.type === 'input' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Input
                  placeholder='please, input'
                  onChange={handleChange.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'date' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <DatePicker
                  placeholder='please, input'
                  onChange={handleChangeDate.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'select' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Select
                  placeholder='please, input'
                  onChange={handleChangeSelect.bind(this, v)}
                  value={v.value}
                >
                  {_.isArray(v.data)
                    ? v.data.map((v) => (
                        <Select.Option value={v}> {v} </Select.Option>
                      ))
                    : selectItems['tracking_devices']?.map((v) => (
                        <Select.Option name={v.name} value={v.id}>
                          {' '}
                          {v.name}{' '}
                        </Select.Option>
                      ))}
                </Select>
              </div>
            )}
          </>
        ))}
      </ModalTemplate>
    </>
  );
}
