import { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef } from 'react';
import { AdminDeleteCompanyAPI } from 'src/api/admin';
import { updateTableAsync } from '../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../settings/General.settings';
import { appStore } from '../../../../../store/App.store';
import IconButton from '../../../../gen/button/IconButton.gen';

export default function CompanyTable({ data, setGridApi, setRowNode }) {
  const { setIsLoading } = appStore();
  const gridRef = useRef();

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    let repData = await AdminDeleteCompanyAPI({ companyId: e.node.data.id });
    if (repData) {
      await updateTableAsync(gridRef.current.api, { remove: [e.node.data] });
      gridRef.current.api.redrawRows();
    }
    setIsLoading(false);
  };
  const handleEditItem = (e) => {
    setRowNode(e.node);
  };
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      minWidth: 200,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: () => {
              return (
                <span style={{ fontWeight: 'bold' }}> {params.value}</span>
              );
            },
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Short Name',
      field: 'short_name',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Type',
      field: 'type',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Address',
      field: 'address',
      filter: 'agSetColumnFilter',
      minWidth: 300,
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          onClick={handleEditItem.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };
  const onGridReady = useCallback(
    (params) => {
      setGridApi(params);
    },
    [setGridApi]
  );
  return (
    <>
      <div className='ag-theme-alpine size-full'>
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          groupDefaultExpanded={2}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
