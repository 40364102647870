import React from 'react';

/**
 * @param {string} id
 * @param {React.Element} children
 * @param {string} placeholder
 * @param {string} name
 * @param {boolean} isRequired
 * @param {string} defaultValue
 * @returns {React.Element} The rendered input element.
 */
const SelectForm = ({
  children,
  placeholder,
  name,
  isRequired,
  defaultValue,
}) => {
  return (
    <select
      placeholder={placeholder}
      name={name}
      required={isRequired}
      defaultValue={defaultValue}
      className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
    >
      {children}
      {/* <option selected></option>
      {items.map((v) => (
        <option value={v.value}>{v.label}</option>
      ))} */}
    </select>
  );
};

export default SelectForm;
