import React from "react";
import DMSSettingLayout from "../../../../layout/apps/two_d/setting/DMSSetting.layout";


export default function DMSSettingPage() {


    return (
        <>
            <DMSSettingLayout />

        </>

    );
}


