import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Badge } from 'antd';


export default function ClashStatusCell(props) {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    
  

    return (
        <div style={{ display: 'flex', alignItems: 'center' , gap:5}}>
            {cellValue}
        </div>
    );
};