import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckCircleIcon } from 'src/assets/icons/general/check-circle.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/general/close.svg';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import { USER_PLAN } from 'src/config';
import { appStore } from 'src/store/App.store';
const PLAN_STYLE = {
  active: 'border-primary-300',
  deactive: 'border-gray-300',
};
const NoUserPlanPage = () => {
  const navigate = useNavigate();
  const { user } = appStore();
  const handleGoToMainPage = () => {
    navigate('/');
  };

  return (
    <>
      <section className='body-font overflow-hidden border-t border-gray-200 text-gray-700'>
        <div className='container mx-auto flex flex-wrap px-5 py-24'>
          <div className='mt-48 hidden lg:block lg:w-1/4'>
            <div className='mt-px overflow-hidden rounded-bl-lg rounded-tl-lg border-b border-l border-t border-gray-300'>
              <p className='-mt-px flex h-12 items-center justify-start bg-gray-100 px-4 text-center text-gray-900'>
                Own Organization
              </p>
              <p className='flex h-12 items-center justify-start px-4 text-center text-gray-900'>
                Shared Organization
              </p>
              <p className='flex h-12 items-center justify-start bg-gray-100 px-4 text-center text-gray-900'>
                Shared Project
              </p>
              <p className='flex h-12 items-center justify-start px-4 text-center text-gray-900'>
                Add-in/Plugins
              </p>
              <p className='flex h-12 items-center justify-start bg-gray-100 px-4 text-center text-gray-900'>
                All features
              </p>
              <p className='flex h-12 items-center justify-start px-4 text-center text-gray-900'>
                Technical support
              </p>
              <p className='flex h-12 items-center justify-start bg-gray-100 px-4 text-center text-gray-900'>
                Request features
              </p>
            </div>
          </div>
          <div className='flex w-full flex-wrap rounded-lg border-gray-300 lg:w-3/4 lg:border'>
            <div
              className={`${user.plan === USER_PLAN.NoPlan ? PLAN_STYLE.active : PLAN_STYLE.deactive} relative mb-10 w-full rounded-lg border-2 lg:-mt-px lg:mb-0 lg:w-1/3`}
            >
              {user.plan === USER_PLAN.NoPlan && (
                <span className='absolute right-0 top-0 rounded-bl bg-primary-500 px-3 py-1 text-xs tracking-widest text-white'>
                  YOUR PLAN
                </span>
              )}
              <div className='flex h-48 flex-col items-center justify-center px-2 text-center'>
                <h3 className='tracking-widest'>START</h3>
                <h2 className='mb-4 mt-2 text-5xl font-medium leading-none text-gray-900'>
                  Free
                </h2>
                <span className='text-sm text-gray-600'>Forever</span>
              </div>
              <p className='-mt-px flex h-12 items-center justify-center border-t border-gray-300 bg-gray-100 px-2 text-center text-gray-600'>
                <CloseIcon />
              </p>
              <p className='flex h-12 items-center justify-center text-center text-gray-600'>
                Unlimited
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                Unlimited
              </p>
              <p className='flex h-12 items-center justify-center px-6 text-center leading-relaxed text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center text-center text-gray-600'>
                <CloseIcon />
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                <CloseIcon />
              </p>

              <div className='rounded-bl-lg border-t border-gray-300 p-6 text-center'>
                <PrimaryButton
                  type='normal'
                  isFull
                  onClick={handleGoToMainPage}
                >
                  Get Started
                </PrimaryButton>
              </div>
            </div>
            <div
              className={`${user.plan === USER_PLAN.Basic ? PLAN_STYLE.active : PLAN_STYLE.deactive} relative mb-10 w-full rounded-lg border-2 lg:-mt-px lg:mb-0 lg:w-1/3`}
            >
              {user.plan === USER_PLAN.Basic && (
                <span className='absolute right-0 top-0 rounded-bl bg-primary-500 px-3 py-1 text-xs tracking-widest text-white'>
                  YOUR PLAN
                </span>
              )}
              <div className='flex h-48 flex-col items-center justify-center px-2 text-center'>
                <h3 className='tracking-widest'>BASIC</h3>
                <h2 className='mb-4 mt-2 flex items-center justify-center text-5xl font-medium leading-none text-gray-900'>
                  ---
                </h2>
                <span className='text-sm text-gray-600'>Contact us</span>
              </div>
              <p className='-mt-px flex h-12 items-center justify-center border-t border-gray-300 bg-gray-100 px-2 text-center text-gray-600'>
                1
              </p>
              <p className='flex h-12 items-center justify-center text-center text-gray-600'>
                Unlimited
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                Unlimited
              </p>
              <p className='flex h-12 items-center justify-center text-center leading-relaxed text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center text-center text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                <CloseIcon />
              </p>

              <div className='border-t border-gray-300 p-6 text-center'>
                <PrimaryButton isFull>Contact Sales</PrimaryButton>
              </div>
            </div>
            <div
              className={`${user.plan === USER_PLAN.Pro ? PLAN_STYLE.active : PLAN_STYLE.deactive} relative mb-10 w-full rounded-lg border-2 lg:-mt-px lg:mb-0 lg:w-1/3`}
            >
              {user.plan === USER_PLAN.Pro && (
                <span className='absolute right-0 top-0 rounded-bl bg-primary-500 px-3 py-1 text-xs tracking-widest text-white'>
                  YOUR PLAN
                </span>
              )}
              <div className='flex h-48 flex-col items-center justify-center px-2 text-center'>
                <h3 className='tracking-widest'>PRO</h3>
                <h2 className='mb-4 mt-2 flex items-center justify-center text-5xl font-medium leading-none text-gray-900'>
                  ---
                </h2>
                <span className='text-sm text-gray-600'>Contact us</span>
              </div>
              <p className='-mt-px flex h-12 items-center justify-center border-t border-gray-300 bg-gray-100 px-2 text-center text-gray-600'>
                Unlimited
              </p>
              <p className='flex h-12 items-center justify-center text-center text-gray-600'>
                Unlimited
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                Unlimited
              </p>
              <p className='flex h-12 items-center justify-center text-center leading-relaxed text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center text-center text-gray-600'>
                <CheckCircleIcon />
              </p>
              <p className='flex h-12 items-center justify-center bg-gray-100 text-center text-gray-600'>
                <CheckCircleIcon />
              </p>

              <div className='border-t border-gray-300 p-6 text-center'>
                <PrimaryButton isFull>Contact Sales</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoUserPlanPage;
