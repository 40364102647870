import React, { } from "react";
import IconButton from "../../../../gen/button/IconButton.gen";
import { dmsStore } from "../../../../../store/DMS.store";
import { useParams } from "react-router-dom";



export default function DMSMainToolbar() {
  const { setIsModalDMSMainAddFolder, setAddDMSMainParentNode } = dmsStore()
  const { projectId } = useParams()
  const handleAddFolder = () => {
    setAddDMSMainParentNode(projectId)
    setIsModalDMSMainAddFolder(true)
  }


  return (
    <div style={{ padding: '0px 5px' }}>
      <IconButton
        icon={'add'}
        size="ssmall"
        onClick={handleAddFolder}
      />
    </div>

  );
}