import React from 'react';

import _ from 'lodash';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';
import ButtonNav from '../../Button.nav';
// import { handleChangeFeature } from '../../../../functions/Viewer.function';

export default function Tool3D({ handleChangeFeature }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, resetAppStore } = appStore();
  const { setIsOpenTopNav, setFeature, feature, docker, viewerLoading } =
    viewerStore();

  return (
    <div style={{ display: 'flex', gap: 5 }}>
      <ButtonNav
        url={
          'https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/40/000000/external-alert-warehouse-xnimrodx-lineal-xnimrodx.png'
        }
        name='Issue'
        active={feature.includes('3d_issue')}
        onClick={handleChangeFeature.bind(
          this,
          '3d_issue',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />
      <ButtonNav
        url={'https://img.icons8.com/ios/50/null/file-preview.png'}
        name='Views'
        active={feature.includes('3d_view')}
        onClick={handleChangeFeature.bind(
          this,
          '3d_view',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />
      <ButtonNav
        url={'https://img.icons8.com/ios/50/null/parallel-tasks.png'}
        name='Tree View'
        active={feature.includes('3d_treeview')}
        onClick={handleChangeFeature.bind(
          this,
          '3d_treeview',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />
      <ButtonNav
        url={'https://img.icons8.com/windows/32/null/features-list.png'}
        name='Level Filter'
        active={feature.includes('3d_levelfilter')}
        onClick={handleChangeFeature.bind(
          this,
          '3d_levelfilter',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />

      <ButtonNav
        url={'https://img.icons8.com/fluency-systems-regular/48/null/clash.png'}
        name='Clash Detection'
        active={feature.includes('3d_clashdetection')}
        onClick={handleChangeFeature.bind(
          this,
          '3d_clashdetection',
          docker,
          feature,
          setFeature
        )}
        disabled={viewerLoading}
      />

  

      {/* <ButtonNav
                url={'https://img.icons8.com/fluency-systems-regular/48/null/clash.png'}
                name='Clash Detection'
                active={feature.includes('3d_clashdetection')}
                onClick={handleChangeFeature.bind(this, '3d_clashcheck', docker, feature, setFeature)}
                disabled={viewerLoading}
            /> */}
    </div>
  );
}
