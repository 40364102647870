import React, { useState } from 'react';
import { Button, Form } from 'antd';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import { requestBimGrid } from '../../../../../functions/General.function';
import { getElementProperty } from '../../../../../functions/Viewer.function';
import AnimationGen from './Animation.gen';




export default function AnimationAdd({ setType, setLoading }) {
  const [search] = useSearchParams();
  const { viewer, setAnimationsData, animationsData, setAnimationStepDetail, animationStepsData, setAnimationStepsData } = viewerStore()
  const fileId = search.get('fileId')
  const versionId = search.get('versionId')
  const { organizationId, projectId } = useParams()
  const [isChanged, setIsChanged] = useState()
  const handleBack = () => {
    setType('list')
  }


  const handleCreateAnimation = async () => {
    let name = window.prompt('Please fill animation name', '')
    if (!name) return
    setLoading(true)
    let animationData = {
      name, file_id: fileId
    }
    let clone = [...animationStepsData]
    let temp = []
    for (var j in clone) {
      let elements = []
      let selector = clone[j]?.elements
      for (var i in selector) {
        let model = selector[i].model
        let properties = await getElementProperty(selector[i].model, selector[i].selection)
        let ids = _.map(properties, v => { return v.externalId })
        elements.push({ versionId: model.myData.loadOptions.versionId, fileId: model.myData.loadOptions.fileId, ids })
      }

      let position = { x: clone[j].position.x, y: clone[j].position.y, z: clone[j].position.z }
      temp.push({ elements, position, time: clone[j].time, name: clone[j].name , index: j})
    }

    console.log(clone)
    let data = await requestBimGrid('post', '/api/animation', { animationData, animationStepsData: temp }, { organizationId, projectId, fileId })
    if (data) {
      let clone = [...animationsData]
      clone.push(data)
      setAnimationsData(animationsData)
      setType('list')
    }
    setLoading(false)
  }
  return (
    <>
      <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
        <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={handleBack} />
        <span style={{ fontWeight: 'bold' }} >   New Animation</span>

      </div>

      <AnimationGen
        setLoading={setLoading}
        setIsChanged={setIsChanged}
      />
      <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
        <Button className={`idd-custom-button block save`} onClick={handleCreateAnimation}
        >Create Animation</Button>

      </div>


    </>

  );
}


