import React, { useEffect, useState } from 'react';
import { viewerStore } from '../../../../../store/Viewer.store';
import _ from 'lodash';
import Loader from '../../../../loader/LoaderApp';
import TimelineGantt from './components/tasks/TimelineTasks.gantt';
import TimelineToolbar from './components/data_source/TimelineDataSource.toolbar';
import { timelineStore } from './Timeline.store';
import FieldSelector from './modal/FieldSelector';
import { Segmented } from 'antd';
import TimelineSourceData from './Timeline.sourcedata';
import TimelineTasks from './Timeline.tasks';
import TimelineConfiguration from './Timeline.configuration';
import AppearanceDefinitions from './modal/AppearanceDefinitions';
import TimelineSimulation from './Timeline.simulation';
import SimulationSetting from './modal/SimulationSetting';
import DefineRule from './modal/DefineRule';

export default function TimelineFeature() {
  const {} = viewerStore();
  const [loading, setLoading] = useState();
  const {
    isTimelineSimulationPlaying,
    isOpenFieldSelector,
    segmentKey,
    setSegmentKey,
    isOpenAppearanceDefinitions,
    isOpenSimulationSetting,
    isOpenDefineRule,
    resetTimelineStore,
  } = timelineStore();
  useEffect(()=>{
    return ()=>{
      resetTimelineStore()
    }
  },[])
  const handleChange = (e) => {
    setSegmentKey(e);
  };
  return (
    <>
      {loading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 11111111,
          }}
        >
          <Loader />
        </div>
      )}
      <Segmented
        value={segmentKey}
        onChange={handleChange}
        options={['Tasks', 'Data Source', 'Configuration', 'Simulation']}
        disabled={isTimelineSimulationPlaying}
      />
      <div style={{ width: '100%', height: 'calc(100% - 32px)' }}>
        <TimelineSourceData />
        <TimelineTasks />
        <TimelineConfiguration />
        <TimelineSimulation />
      </div>

      {isOpenFieldSelector && <FieldSelector />}
      {isOpenAppearanceDefinitions && <AppearanceDefinitions />}
      {isOpenSimulationSetting && <SimulationSetting />}
      {isOpenDefineRule && <DefineRule/>}
    </>
  );
}
