import { useState } from 'react';

import { CaretUpFilled } from '@ant-design/icons';
import { Tabs } from 'antd';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { viewerStore } from '../../../../store/Viewer.store';
import AnimationFeature from '../featrue/animation/Animation.feature';
import ClashCheckFeature from '../featrue/clash_check/ClashCheck.feature';
import ClashDetectionFeature from '../featrue/clash_detection/ClashDetection.feature';
import COBieFeature from '../featrue/cobie/COBie.feature';
import ExtractQuantityFeature from '../featrue/ExtractQuantity/ExtractQuantity.feature';
import ExtractQuantityGanttFeature from '../featrue/extract_quantity_gantt/ExtractQuantityGantt.feature';
import IssueFeature from '../featrue/Issue/Issue.feature';
import LevelFilterFeature from '../featrue/level_filter/LevelFilter.feature';
import DFMAFeature from '../featrue/precast/dfma/DFMA.feature';
import QRDrawingFeature from '../featrue/share/qr_drawing/QRDrawing.feature';
import QRElementFeature from '../featrue/share/qr_element/QRElement.feature';
import TimelineFeature from '../featrue/time_line/Timeline.feature';
import TreeViewFeature from '../featrue/tree_view/TreeView.feature';
import VRConnectorFeature from '../featrue/vdc/vr_connector/VRConnector.feature';
import ViewFeature from '../featrue/view/View.feature';
import Tool3D from './toolbar/3d/Tool3D';
import Tool4D from './toolbar/4d/Tool4D';
import Tool5D from './toolbar/5d/Tool5D';
import Tool6D from './toolbar/6d/Tool6D';
import ToolQR from './toolbar/qr/ToolQR';
import ToolVDC from './toolbar/vdc/ToolVDC';

const panel = {
  '6d_cobie': { name: 'COBie', tabName: '6d', component: <COBieFeature /> },
  '5d_takeoff': {
    name: 'Extract Quantities',
    tabName: '5d',
    component: <ExtractQuantityFeature />,
  },
  '5d_gantt': {
    name: '5D',
    tabName: '5d',
    component: <ExtractQuantityGanttFeature />,
  },
  '4d_timeline': {
    name: 'Timeline',
    tabName: '4d',
    component: <TimelineFeature />,
  },
  '4d_animation': {
    name: 'Animation',
    tabName: '4d',
    component: <AnimationFeature />,
  },
  '4d_dfma': {
    name: 'DFMA',
    tabName: '4d',
    component: <DFMAFeature />,
  },
  '3d_issue': { name: 'Issue', tabName: '3d', component: <IssueFeature /> },
  '3d_view': { name: 'View', tabName: '3d', component: <ViewFeature /> },
  '3d_treeview': {
    name: 'Tree View',
    tabName: '3d',
    component: <TreeViewFeature />,
  },
  '3d_levelfilter': {
    name: 'Level Filter',
    tabName: '3d',
    component: <LevelFilterFeature />,
  },
  '3d_clashdetection': {
    name: 'Clash Detection',
    tabName: '3d',
    component: <ClashDetectionFeature />,
  },
  '3d_clashcheck': {
    name: 'Clash Check',
    tabName: '3d',
    component: <ClashCheckFeature />,
  },
  qr_element: {
    name: 'QR Element',
    tabName: '3d',
    component: <QRElementFeature />,
  },
  qr_drawing: {
    name: 'QR Drawing',
    tabName: '3d',
    component: <QRDrawingFeature />,
  },
  vdc_vr_connector: {
    name: 'VR Connector',
    tabName: '3d',
    component: <VRConnectorFeature />,
  },
};

export default function TopNavViewer() {
  const [search, setSearchParams] = useSearchParams();
  const { setIsOpenTopNav, isOpenTopNav } = viewerStore();
  const [key, setKey] = useState(
    search.get('viewType') ? search.get('viewType') : '3d'
  );

  const handleChangeTab = (e) => {
    const newQueryParameters = new URLSearchParams();

    search.forEach((v, k) => {
      newQueryParameters.set(k, v);
    });
    newQueryParameters.set('viewType', e);
    setSearchParams(newQueryParameters);
    setKey(e);
  };

  const handleChangeFeature = (id, docker, feature, setFeature) => {
    let { name, component, tabName } = panel[id];
    if (!component) return;
    let clone = [...feature];
    let index = _.findIndex(feature, (v) => {
      return v === id;
    });
    if (index >= 0) {
      clone.splice(index, 1);
      setFeature(clone);
      let tab = docker.find(id);
      if (tab) docker.dockMove(tab, '', 'remove');
    } else {
      let tab = getTabViewer(id, name, component);
      tab = { ...tab };
      clone.push(id);
      setFeature(clone);
      // let panelData = docker.find('4d');
      // if (!panelData) {
      //   docker.dockMove({
      //     id: '4d', tabs: [tab],cached: true,
      //   },
      //     {
      //       children: [], mode: 'vertical',
      //       size: [200, 200],
      //     }, 'float')
      //   return
      // }
      // if(id ==='5d_gantt'){
      //   docker.dockMove(tab, tabName, 'bottom')
      // }else{
      //   docker.dockMove(tab, tabName, 'float')
      // }
      docker.dockMove(tab, tabName, 'float');
    }
  };
  function getTabViewer(id, name, content) {
    return {
      id,
      content,
      title: name,
      closable: true,
      cached: true,
    };
  }
  return (
    <div
      style={{
        borderBottom: '1px solid black',
        height: isOpenTopNav ? '130px' : '10px',
        position: 'relative',
        zIndex: 99,
      }}
    >
      <div style={{ width: '100%' }}>
        <div>
          {isOpenTopNav ? (
            <div style={{ width: '100%', padding: '0px 0px', height: 115 }}>
              <Tabs
                className={`idd-custom-tabs ${isOpenTopNav ? 'closed' : ''}`}
                type='card'
                activeKey={key}
                onChange={handleChangeTab}
              >
                <Tabs.TabPane tab='2D DMS' key='2d'></Tabs.TabPane>
                <Tabs.TabPane tab='3D Coordination' key='3d'>
                  <Tool3D handleChangeFeature={handleChangeFeature} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='QR' key='qr'>
                  <ToolQR handleChangeFeature={handleChangeFeature} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='PMS' key='4d'>
                  <Tool4D handleChangeFeature={handleChangeFeature} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='CMS' key='5d'>
                  <Tool5D handleChangeFeature={handleChangeFeature} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='AMS' key='6d'>
                  <Tool6D handleChangeFeature={handleChangeFeature} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='KPI' key='kpi'></Tabs.TabPane>
                <Tabs.TabPane tab='VDC' key='vdc'>
                  <ToolVDC handleChangeFeature={handleChangeFeature} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div style={{ backgroundColor: 'black' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
            onClick={setIsOpenTopNav.bind(this, !isOpenTopNav)}
          >
            <CaretUpFilled style={{ color: 'white' }} />
          </div>
        </div>
      </div>
    </div>
  );
}
