import { DragOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams, useSearchParams } from 'react-router-dom';
import * as NTHREE from 'three';
import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from 'three-mesh-bvh';
import { v4 } from 'uuid';
import {
  reorder,
  requestBimGrid,
} from '../../../../../functions/General.function';
import {
  getLeafFragIds,
  resetTransform,
} from '../../../../../functions/Viewer.function';
import { viewerStore } from '../../../../../store/Viewer.store';
import IconButton from '../../../../gen/button/IconButton.gen';
import TransformAnimationTool from '../../extensions/transform/TransformAnimation';
import AnimationPlay from '../../util/Animation.util';

let transform;
let animationPlay;
let isFirstTIme = true;
const Autodesk = window.Autodesk;
const THREE = window.THREE;
let tempPosition = null;

NTHREE.Mesh.prototype.raycast = acceleratedRaycast;
NTHREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
NTHREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

export default function AnimationGen({
  isEdit = false,
  setLoading,
  setIsChanged,
}) {
  const [search] = useSearchParams();
  const {
    viewer,
    setAnimationsData,
    animationDetail,
    setAnimationStepDetail,
    animationStepsData,
    setAnimationStepsData,
  } = viewerStore();
  const fileId = search.get('fileId');
  const versionId = search.get('versionId');
  const { organizationId, projectId } = useParams();
  const [currentSelect, setCurrentSelect] = useState();
  const [isPlay, setIsPlay] = useState();
  useEffect(() => {
    isFirstTIme = true;
    animationPlay = new AnimationPlay(viewer);
    transform = new TransformAnimationTool('Transform Animation');
    const controller = viewer.toolController;
    controller.registerTool(transform);
    controller.activateTool('Transform Animation');
    return () => {
      setIsChanged(false);
      transform?.clearSelection();
      controller.activateTool('Transform Animation');
      controller.deregisterTool(transform);
      setAnimationStepsData([]);
      setAnimationStepDetail();
    };
  }, []);
  useEffect(() => {
    setPositionItem();
    transform.addEventListener('end', setEventPosition, false);
    return () => {
      transform.removeEventListener('end', setEventPosition, false);
    };
  }, [currentSelect]);

  const setEventPosition = (e) => {
    tempPosition = e.position;
  };
  const setPositionItem = async (e) => {
    if (!currentSelect) {
      transform?.clearSelection();
      return;
    }
    var selector = currentSelect.elements;
    var selection = {
      fragIdsArray: [],
      dbIdArray: [],
      model: null,
    };
    var select = [];
    for (var i in selector) {
      let model = selector[i].model;
      const instanceTree = model.getData().instanceTree;
      const els = selector[i].selection;
      for (var v in els) {
        let fragIds = await getLeafFragIds(instanceTree, els[v]);
        selection.fragIdsArray = selection.fragIdsArray.concat(fragIds);
      }
      selection.dbIdArray = selector[i].selection;
      selection.model = model;
      select.push({ model: model, ids: selector[i].selection });
    }
    viewer.impl.selector.setAggregateSelection(select);
    var bb = viewer.utilities.getBoundingBox();
    transform.setSelection(selection);
    transform.initializeSelection(bb.getCenter());
  };
  const handleAdd = async () => {
    let name = window.prompt('Please fill step name', 'New Step');
    if (!name) return;
    let clone = [...animationStepsData];
    let item = {
      name,
      elements: [],
      position: {},
      time: 1,
      index: clone.length + 1,
    };
    if (isEdit) {
      setLoading(true);
      item.animation_id = animationDetail.id;
      let data = await requestBimGrid(
        'post',
        '/api/animation/addStep',
        { data: item },
        { organizationId, projectId, fileId }
      );
      if (data) {
        clone.push(data);
        setAnimationStepsData(clone);
      }
      setLoading(false);
    } else {
      item.id = v4();
      clone.push(item);
      setAnimationStepsData(clone);
    }
  };
  const handleRemove = async (k) => {
    if (isEdit) {
      setLoading(true);
      if (
        !window.confirm('Are you sure to delete? It will remove item in system')
      )
        return;
      let clone = [...animationStepsData];
      let data = await requestBimGrid(
        'delete',
        '/api/animation/deleteStep',
        { animationStepIds: [clone[k].id] },
        { organizationId, projectId, fileId }
      );
      if (data) {
        clone.splice(k, 1);
        setAnimationStepsData(clone);
      }
      setLoading(false);
    } else {
      if (!window.confirm('Are you sure to delete?')) return;
      let clone = [...animationStepsData];
      clone.splice(k, 1);
      setAnimationStepsData(clone);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.index === destination.index) return;

      let result = reorder(
        [...animationStepsData],
        source.index,
        destination.index
      );
      setAnimationStepsData([...result]);
    }
  };
  const handleEdit = (item) => {
    setAnimationStepDetail(item);
  };
  const handleClear = async () => {
    if (!window.confirm('Are you sure to delete all item?')) return;
    if (isEdit) {
      setLoading(true);
      let check = window.prompt(
        'Are you want to continue? please input "Confirm" to process'
      );
      if (check.toLowerCase() !== 'confirm') return;
      let ids = animationStepsData.map((v) => {
        return v.id;
      });
      let data = await requestBimGrid(
        'delete',
        '/api/animation/deleteStep',
        { animationStepIds: ids },
        { organizationId, projectId, fileId }
      );
      if (data) {
        setAnimationStepsData([]);
        setIsChanged(false);
      }
      setLoading(false);
    } else {
      setAnimationStepsData([]);
    }
  };
  const handleAddElement = async (k) => {
    if (!window.confirm('Are you sure to set elements?')) return;
    var selector = viewer.getAggregateSelection();
    let clone = [...animationStepsData];
    clone[k].elements = selector;
    setAnimationStepsData(clone);
  };
  useEffect(() => {
    animationPlay.set(animationStepsData);
    if (animationStepsData.length !== 0) {
      if (!isFirstTIme && setIsChanged) {
        setIsChanged(true);
      }
      isFirstTIme = false;
    }
  }, [animationStepsData]);
  useEffect(() => {
    if (isPlay) {
      play();
    } else {
      animationPlay?.stop();
      animationPlay.resetAllTransform();
    }
  }, [isPlay]);
  const play = async () => {
    let clone = [...animationStepsData];
    let combine = [];
    for (let index in clone) {
      const item = clone[index];
      if (!item.elements || !item.position?.x) {
        continue;
      }
      const selector = item.elements;
      const temp = [];
      let p = new THREE.Vector3(
        item.position.x,
        item.position.y,
        item.position.z
      );
      for (var i in selector) {
        let model = selector[i].model;
        const instanceTree = model.getData().instanceTree;
        const els = selector[i].selection;
        for (var v in els) {
          let fragIds = await getLeafFragIds(instanceTree, els[v]);
          fragIds.forEach((v) => {
            let fragProxy = viewer.impl.getFragmentProxy(model, v);
            fragProxy.getAnimTransform();
            fragProxy.position = p;
            fragProxy.updateAnimTransform();
            temp.push(fragProxy);
          });
        }
      }
      combine.push({ item, position: p, fragProxies: temp, time: item.time });
      viewer.impl.sceneUpdated(true);
    }
    for (let index in combine) {
      const item = combine[index];
      await animationPlay.runAnimation(item.item, item.fragProxies);
    }
    setIsPlay(false);
  };
  const handlePlay = async (k) => {
    setIsPlay(!isPlay);
  };

  const handleElementPlay = async (k) => {
    let clone = [...animationStepsData];
    const item = clone[k];
    if (!item.elements || !item?.position?.x) {
      return;
    }
    const selector = item.elements;
    const temp = [];
    // let p = new THREE.Vector3(item.position.x, item.position.y, item.position.z)
    for (var i in selector) {
      let model = selector[i].model;
      const instanceTree = model.getData().instanceTree;
      const els = selector[i].selection;
      for (var v in els) {
        let fragIds = await getLeafFragIds(instanceTree, els[v]);
        fragIds.forEach((v) => {
          let fragProxy = viewer.impl.getFragmentProxy(model, v);

          let renderProxy = viewer.impl.getRenderProxy(model, v);
          fragProxy.getAnimTransform();
          let matrix = renderProxy.matrixWorld;
          // geometry data of the fragment
          let geometry = renderProxy.geometry;

          // information of the fragment
          let attributes = geometry.attributes;

          // if (attributes.index !== undefined) {
          //     let positions = geometry.vb ? geometry.vb : attributes.position.array;
          //     let stride = geometry.vb ? geometry.vbstride : 3;
          //     let numVertices = positions.length / stride;
          //     let vec = positions.slice(0, numVertices);

          //     const geometry1 = new NTHREE.BufferGeometry();
          //     const vertices = new Float32Array(vec);

          //     geometry1.setAttribute('position', new NTHREE.BufferAttribute(vertices, 3));
          //     geometry1.applyMatrix4(matrix);
          //     const material = new NTHREE.MeshBasicMaterial({ color: 0xff0000 });
          //     const mesh = new NTHREE.Mesh(geometry1, material);
          //     mesh.geometry.computeBoundsTree()
          //     const mesh1 = new NTHREE.Mesh(geometry1.clone(), material);
          //     mesh1.geometry.computeBoundsTree()
          //     const transformMatrix =
          //     new NTHREE.Matrix4()
          //         .copy( mesh.matrixWorld ).invert()
          //         .multiply( mesh1.matrixWorld );

          //     const hit = mesh.geometry.boundsTree.intersectsGeometry( mesh1.geometry, transformMatrix );
          //     console.log(hit)
          // }

          fragProxy.getAnimTransform();
          fragProxy.position = new THREE.Vector3(
            item.position.x,
            item.position.y,
            item.position.z
          );
          fragProxy.updateAnimTransform();
          temp.push(fragProxy);
        });
      }
    }
    viewer.impl.sceneUpdated(true);
    animationPlay.runAnimation(item, temp);
  };

  const handleTransform = async (k) => {
    let clone = [...animationStepsData];
    let item = clone[k];
    if (currentSelect) {
      let index = _.findIndex(clone, (i) => {
        return i.id === currentSelect.id;
      });
      clone[index].position = tempPosition;
      setAnimationStepsData(clone);
      if (currentSelect.id === item.id) {
        const selector = currentSelect.elements;
        for (var i in selector) {
          let model = selector[i].model;
          const instanceTree = model.getData().instanceTree;
          const els = selector[i].selection;
          for (var v in els) {
            await resetTransform(viewer, instanceTree, els[v]);
          }
        }
        setCurrentSelect(null);
        return;
      }
    }
    tempPosition = null;
    setCurrentSelect(item);
  };

  return (
    <>
      <div
        style={{
          height: 'calc(100% - 75px)',
          overflow: 'auto',
          width: '100%',
          position: 'relative',
          padding: 5,
        }}
      >
        <div
          style={{ display: 'flex', width: '100%', top: 0, position: 'sticky' }}
        >
          {/* <Input placeholder='Animation name' className='block' /> */}
          <Button
            className={`idd-custom-button regular block`}
            onClick={handleAdd}
          >
            Add step
          </Button>
          <Button
            className={`idd-custom-button ${isPlay ? 'delete' : 'save'} block`}
            onClick={handlePlay}
            disabled={animationStepsData.length === 0}
          >
            {isPlay ? 'Stop' : 'Play'}
          </Button>
          <Button
            className={`idd-custom-button delete block`}
            onClick={handleClear}
          >
            Clear
          </Button>
        </div>
        <p />
        <DragDropContext onDragEnd={onDragEnd} dragHandleUsageInstructions=''>
          <Droppable droppableId='animationList' isDragDisabled={true}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  gap: 5,
                  flexDirection: 'column',
                  backgroundColor: 'white',
                }}
              >
                {animationStepsData.map((item, index) => (
                  <Draggable
                    id={item.id}
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className='idd-animation-item'
                        id={`animation-${item.id}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{ ...provided?.draggableProps?.style }}
                      >
                        <div {...provided.dragHandleProps}>
                          <DragOutlined />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div>
                            <span>{item.name}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <IconButton
                            url={
                              'https://img.icons8.com/ios-glyphs/30/null/play--v1.png'
                            }
                            size='ssmall'
                            // type='not-set'
                            onClick={handleElementPlay.bind(this, index)}
                            disabled={item?.elements?.length === 0}
                          />
                          <IconButton
                            url={
                              'https://img.icons8.com/fluency-systems-regular/48/null/element-level.png'
                            }
                            size='ssmall'
                            type={
                              item?.elements?.length === 0 ? 'regular' : 'save'
                            }
                            onClick={handleAddElement.bind(this, index)}
                          />
                          <IconButton
                            url={
                              'https://img.icons8.com/ios-glyphs/30/null/resize-four-directions--v1.png'
                            }
                            size='ssmall'
                            type={
                              currentSelect?.id !== item.id ? 'regular' : 'save'
                            }
                            onClick={handleTransform.bind(this, index)}
                            disabled={item?.elements?.length === 0}
                          />
                          <IconButton
                            url={
                              'https://img.icons8.com/ios-filled/50/null/pencil--v1.png'
                            }
                            size='ssmall'
                            type='edit'
                            onClick={handleEdit.bind(this, item)}
                          />
                          <IconButton
                            url={
                              'https://img.icons8.com/glyph-neue/64/null/delete-trash.png'
                            }
                            size='ssmall'
                            type='delete'
                            onClick={handleRemove.bind(this, index)}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {animationStepsData.length === 0 && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 'calc(100% - 75px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>No animations</span>
          </div>
        )}
      </div>
    </>
  );
}
