/* eslint-disable no-unused-vars */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Input, message, Tabs } from 'antd';
import { eachLimit } from 'async';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { S3_BASED_PATH } from '../../../../../../../config';
import {
  generateS3Path,
  updateTableAsync,
} from '../../../../../../../functions/General.function';
import { ViewerAPI } from '../../../../../../api';
import { S3API } from '../../../../../../api/app';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import AddDocument from '../components/AddDocument';
import AddProperties from '../components/AddProperties';
import { qrDrawingStore } from '../QRDrawing.store';
export default function AddNewQRDrawingModal() {
  const [search] = useSearchParams();
  const { organizationId, projectId } = useParams();
  const fileId = search.get('fileId');
  const {
    setIsAddNewDrawing,
    documents,
    properties,
    drawings,
    setDrawings,
    setQRDrawingLoading,
    gridApiMain,
  } = qrDrawingStore();
  const [name, setName] = useState('');
  const [itemUploaded, setItemUploaded] = useState([]);
  useEffect(() => {}, []);
  const close = () => {
    setIsAddNewDrawing();
  };

  const handleOk = async (e) => {
    if (!window.confirm('Are you sure to submit?')) return;
    setQRDrawingLoading(true);
    try {
      debugger;

      let array = await uploadDocument(documents);
      let result = [];
      _.forEach(array, (v) => {
        if (v.isSuccess)
          result.push({
            name: v.name,
            typeFile: v.type,
            typeUpload: 'manual',
            key: v.key,
          });
      });
      let resQRDrawing = await ViewerAPI.QRDrawingAPI.Create({
        fileId,
        name,
        documents: result,
        properties,
      });
      // let clone = [...drawings];
      // clone.push(resQRDrawing);
      // setDrawings(clone);
      await updateTableAsync(gridApiMain.api, { add: [resQRDrawing] });
      close();
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRDrawingLoading(false);
  };
  const uploadDocument = () => {
    return new Promise((resolve) => {
      const array = [];
      if (documents.length !== 0) {
        eachLimit(
          documents,
          15,
          async (node, next) => {
            let key = generateS3Path(
              organizationId,
              projectId,
              S3_BASED_PATH.QR_DRAWING_3D,
              `${fileId}/${v4()}/${node.name}`
            );
            // `bimgrid/${organizationId}/${projectId}/qrDrawing/${fileId}/${v4()}/${node.name}`;
            try {
              await S3API.UploadPutLink(key, node.type, node);
              array.push({
                name: node.name,
                key,
                type: node.type,
                isSuccess: true,
              });
              setItemUploaded([...array]);
            } catch (ex) {
              message.warning(ex.message);
              array.push({
                name: node.name,
                key,
                type: node.type,
                isSuccess: false,
              });
              setItemUploaded([...array]);
            }
            return true;
          },
          () => {
            resolve(array);
          }
        );
      } else {
        resolve(array);
      }
    });
  };
  const handleChange = (e) => {
    setName(e.target.value);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add'}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={true}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            gap: 10,
            height: 'calc(100vh - 450px)',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 10,
            }}
          >
            <div style={{}}>
              <div style={{ width: '100%', display: 'flex', gap: 5 }}>
                <span style={{ fontWeight: 'bold', width: 100 }}>Name:</span>
                <Input style={{ width: '100%' }} onChange={handleChange} />
              </div>
              {
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    gap: 5,
                    flexDirection: 'column',
                  }}
                >
                  <span style={{ fontWeight: 'bold', width: 100 }}>
                    Uploaded:
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 5,
                      height: 150,
                      overflow: 'auto',
                    }}
                  >
                    {itemUploaded.map((v) => (
                      <div
                        style={{
                          display: 'flex',
                          gap: 5,
                          alignItems: 'center',
                        }}
                      >
                        {v.isSuccess ? (
                          <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                          <CloseOutlined style={{ color: 'red' }} />
                        )}
                        <span style={{ fontWeight: '200' }}> {v.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div>
            <div>
              <Tabs type='card' className='idd-custom-tabs'>
                <Tabs.TabPane tab='Properties' key='properties' forceRender>
                  <AddProperties />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Document' key='document' forceRender>
                  <AddDocument />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
