import React from "react";
import DMSDocLayout from "../../../../layout/apps/two_d/doc/DMSDoc.layout";
export default function DMSDocPage() {


    return (
        <>
            <DMSDocLayout />

        </>

    );
}
