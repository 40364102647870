/* eslint-disable no-unused-vars */

import { message } from 'antd';
import _ from 'lodash';
import { ViewerAPI } from '../../../../../../api';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import { qrElementStore } from '../QRElement.store';

export default function Templateitem({ item }) {
  const {
    allTemplate,
    setAllTemplate,
    setCurrentTemplate,
    setQRElementLoading,
    currentTemplate,
    setFileQRElementId,
  } = qrElementStore();

  const handleDelete = async () => {
    if (!window.confirm('Are you want to delete?')) return;
    let index = _.findIndex(allTemplate, (i) => {
      return i.id == item.id;
    });
    if (index >= 0) {
      setQRElementLoading(true);
      try {
        await ViewerAPI.QRElementAPI.DeleteAll({
          ids: [item.id],
        });
        let clone = [...allTemplate];
        clone.splice(index, 1);
        setAllTemplate(clone);
        if (item.id === currentTemplate?.id) {
          setCurrentTemplate(null);
          setFileQRElementId(null);
        }
      } catch (ex) {
        message.warning(ex.message);
      }
      setQRElementLoading(false);
    }
  };
  const handleEdit = async () => {
    setQRElementLoading(true);
    try {
      let resQRElement = await ViewerAPI.QRElementAPI.GetOne({
        id: item.id,
      });
      setCurrentTemplate(resQRElement);
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRElementLoading(false);
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid black',
          borderRadius: 2,
          padding: '2px 5px',
        }}
      >
        <span>{item.name}</span>
        <div style={{ display: 'flex', gap: 2 }}>
          <IconButton
            type='edit'
            icon={'edit'}
            size='sssmall'
            onClick={handleEdit}
          />
          <IconButton
            type='delete'
            icon={'delete'}
            size='sssmall'
            onClick={handleDelete}
          />
        </div>
      </div>
    </>
  );
}
