export const OCCUPATION = [
  'Asst Doc Controller (Trainee)',
  'Assistant Engineer (Trainee)',
  'Architectural Worker',
  'Cubemen',
  'Chainman',
  'ARCH - Chargehand',
  'Safety Worker - Chargehand',
  'M&E - Chargehand',
  'Maintenance - Chargehand',
  'QC Inspector - Chargehand',
  'QM Worker - Chargehand',
  'STR - Chargehand',
  'Concretor',
  'Carpenter',
  'Safety Worker',
  'Electrician',
  'Forklift Operator',
  'General Worker',
  'Lifting Man',
  'Maintenance Worker',
  'Material Hoisting',
  'Metal Scaffold Erector',
  'Office Boy',
  'Passenger Hoist Operator',
  'Plasterer',
  'QC Inspector',
  'QM Worker',
  'Quantity Surveyor Asst (Trainee)',
  'Rigger',
  'RWDP Worker',
  'Surveyor Assistant',
  'Steelbender',
  'Storeman',
  'Structural Worker',
  'Welder',
];

export const NATIONALITY = [
  'Indian',
  'Myanmar',
  'Bangladesh',
  'Thailand',
  'China',
  'Paskistan',
  'Malaysia',
  'Singapore',
  'Other',
];

export const DATETYPE = ['Normal', 'Sunday', 'Public holiday'];
