import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as PlusCircleIcon } from 'src/assets/icons/general/plus-circle.svg';
import InputForm from 'src/components/atoms/InputForm';
import PageHeader from 'src/components/atoms/PageHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../functions/General.function';
import { appStore } from '../../../../store/App.store';
import ModalEditUser from './modal/ModalEditUser';
import UserTable from './table/User.table';
export default function LayoutUser() {
  const [name, setName] = useState('');
  const { setIsLoading } = appStore();
  const { organizationId, projectId } = useParams();
  const [gridApi, setGridApi] = useState();
  const [rowNode, setRowNode] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid('get', '/api/project/getAllUser', null, {
      organizationId,
      projectId,
    });
    if (data) {
      setData(data);
    }
    setIsLoading(false);
  };
  const handleAddName = (e) => {
    setName(e.target.value);
  };
  const handleAddNewUser = async () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(name)) {
      message.warning('You should input email');
      return;
    }
    if (!window.confirm('Are you want to add new user?')) return;
    setIsLoading(true);
    let data = await requestBimGrid(
      'post',
      '/api/project/addUserToProject',
      {
        organizationId: organizationId,
        newEmail: name,
        projectId,
      },
      {
        organizationId,
        projectId,
      }
    );
    if (data) {
      await updateTableAsync(gridApi.api, { add: [data] });
      gridApi.api.redrawRows();
    }
    setIsLoading(false);
  };

  return (
    <>
      <TopPageToolbar
        left={<PageHeader>Project User</PageHeader>}
        right={
          <>
            <InputForm
              className='w-80'
              placeholder='Enter email to add'
              onChange={handleAddName}
            />
            <PrimaryButton disabled={!name} onClick={handleAddNewUser}>
              <PlusCircleIcon className='size-4' />
              Add
            </PrimaryButton>
          </>
        }
      />

      <UserTable data={data} setGridApi={setGridApi} setRowNode={setRowNode} />
      {rowNode && (
        <ModalEditUser
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
