import _ from "lodash";
import { convertHexColorToVector4, getHitPoint, getLeafFragIds, pointerToRaycaster } from "../../../../functions/Viewer.function";
import { EventDispatcher } from "../extensions/utils/EventDispatcher";
import MarkupPoint from "./MarkupPoint.util";
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import * as NTHREE from 'three';

NTHREE.Mesh.prototype.raycast = acceleratedRaycast;
NTHREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
NTHREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

const Autodesk = window.Autodesk
const avp = Autodesk.Viewing.Private;
const THREE = window.THREE
const material = new NTHREE.MeshBasicMaterial({ color: 0xff0000 })
export default class ClashDetectionPoint extends MarkupPoint {

    colorA = convertHexColorToVector4('#00FF00')
    colorB = convertHexColorToVector4('#FF0000')
    colors = {
        'new': new THREE.Color('#ff0000'),
        'active': new THREE.Color('#ff9900'),
        'reviewed': new THREE.Color('#00ccff'),
        'approved': new THREE.Color('#00ff00'),
        'resolved': new THREE.Color('#ffff00'),
    }
    // color = new THREE.Color('#FF9900')
    colorNew = new THREE.Color('red')
    viewer = null
    size = 15
    fragmentShader = `
        uniform sampler2D tex;
        varying vec2 vSizeAp;
        varying vec3 vColor;
        void main() {
            float distance = length(gl_PointCoord - vec2(0.5, 0.5));
            if (distance > 0.5) discard;
            float borderWidth = 0.1;
            float borderDistance = length(gl_PointCoord - vec2(0.5, 0.5));
            if (borderDistance > 0.5 - borderWidth) {
                gl_FragColor =vec4( 0.0, 0.0, 0.0, 1 )  ;
            } else {
                gl_FragColor = vec4( vColor, 1)  ;
            }    
        }
    `
    material = new THREE.ShaderMaterial({
        // vertexColors: THREE.VertexColors,
        fragmentShader: this.fragmentShader,
        vertexShader: this.vertexShader,
        depthWrite: true,
        depthTest: false,
        uniforms: {
            size: { type: "f", value: this.size },
        }
    });
    isGhosting

    constructor(viewer, name = 'coordination') {
        super(viewer, name)
        this.viewer = viewer
    }

    active = async () => {
        this.isGhosting= this.viewer.prefs.get('ghosting')
        this.viewer.setGhosting(false)
        this.viewer.canvas.addEventListener('mousemove', this.eventMoveClashDetection, false);
        this.viewer.canvas.addEventListener('mousedown', this.eventMouseDownClashDetection, false);
        this.viewer.canvas.addEventListener('mouseup', this.eventMouseUpClashDetection, false);
        this.viewer.canvas.addEventListener('click', this.eventClickClashDetection, false);
        this.load()
    }
    dispose = () => {
        // this.viewer.canvas.removeEventListener('pointerdown', this.eventAddNewClashDetection,false);
        this.viewer?.setGhosting(this.isGhosting)
        this.viewer?.canvas.removeEventListener('mousemove', this.eventMoveClashDetection, false);
        this.viewer?.canvas.removeEventListener('mousedown', this.eventMouseDownClashDetection, false);
        this.viewer?.canvas.removeEventListener('mouseup', this.eventMouseUpClashDetection, false);
        this.viewer?.canvas.removeEventListener('click', this.eventClickClashDetection, false);
        this.unload()
    }

    eventMouseUpClashDetection = (event) => {
        if (!this.isHolding)
            this.pickPoint(event)
        this.isDown = false
        this.isHolding = false
    }
    eventMouseDownClashDetection = (event) => {
        this.isDown = true
    }

    eventMoveClashDetection = (event) => {
        if (this.isDown) {
            this.isHolding = true
        }
        this.hoverPoint(event);
    }


    eventClickClashDetection = (event) => {
        // if (!this.isHolding)
        //     this.pickPoint(event)
    }

    highLightClash = () => {
        this.markupItems.forEach(v => {
            if(v.objA &&v.objB){
                this.viewer.setThemingColor(v.objA.id, this.colorA, v.objA.model, true)
                this.viewer.setThemingColor(v.objB.id, this.colorB, v.objB.model, true)
            }
        })
    }
    selectClash =(params)=>{
        const models = this.viewer.impl.modelQueue().getModels();
        if (params.isGroup) {
            let nodes = params.node.allLeafChildren
            let temp = {}
            _.forEach(nodes, v => {
                const modelAId = v.objA.model.id
                if (!temp[modelAId])
                    temp[modelAId] = []
                temp[modelAId].push(v.objA.id)

                const modelBId = v.objA.model.id
                if (!temp[modelBId])
                    temp[modelBId] = []
                temp[modelBId].push(v.objA.id)
            })
            let select = []
            _.forEach(temp, (v, k) => {
                let index = _.findIndex(models, v => { return v.id == k })
                select.push({ model: models[index], ids: v })
            })
            // viewer.select(temp)
            this.viewer.isolate(select[0].ids, select[0].model)
            this.viewer.impl.selector.setAggregateSelection(select)
            this.viewer.fitToView(select[0].ids, select[0].model)
        } else {
            const modelAId = params.data.objA.model.id
            const modelBId = params.data.objB.model.id
            let indexA = _.findIndex(models, v => { return v.id === modelAId })
            let indexB = _.findIndex(models, v => { return v.id === modelBId })
            let select = []
            if (indexA === indexB) {
                select.push({ model: models[indexA], ids: [params.data.objA.id, params.data.objB.id] })
            } else {
                select.push(
                    { model: models[indexA], ids: [params.data.objA.id] },
                    { model: models[indexB], ids: [params.data.objB.id] }
                )
            }
            this.viewer.isolate(select[0].ids, select[0].model)
            this.viewer.impl.selector.setAggregateSelection(select)
            this.viewer.fitToView(select[0].ids, select[0].model)
        }
    }
}

export const getAttributePosition = async (id, instanceTree, viewer, model) => {
    return new Promise(async resolve => {
        getLeafFragIds(instanceTree, id)
            .then(fragIds => {
                let vertices = []
                let vec = []
                fragIds.forEach(v => {
                    let fragProxy = viewer.impl.getFragmentProxy(model, v)
                    let renderProxy = viewer.impl.getRenderProxy(model, v)
                    fragProxy.getAnimTransform();
                    let matrix = renderProxy.matrixWorld;
                    // geometry data of the fragment
                    let geometry = renderProxy.geometry;

                    // information of the fragment
                    let attributes = geometry.attributes;

                    var vA = new THREE.Vector3();
                    var vB = new THREE.Vector3();
                    var vC = new THREE.Vector3();

                    if (attributes.index !== undefined) {
                        var indices = attributes.index.array || geometry.ib;
                        var positions = geometry.vb ? geometry.vb : attributes.position.array;
                        positions = Array.from(positions)
                        var stride = geometry.vb ? geometry.vbstride : 3;
                        var offsets = geometry.offsets;

                        if (!offsets || offsets.length === 0) {

                            offsets = [{ start: 0, count: indices.length, index: 0 }];
                        }
                        // var vA = new THREE.Vector3();
                        // var vB = new THREE.Vector3();
                        // var vC = new THREE.Vector3();
                        for (var oi = 0, ol = offsets.length; oi < ol; ++oi) {

                            var start = offsets[oi].start;
                            var count = offsets[oi].count;
                            var index = offsets[oi].index;

                            for (var i = start, il = start + count; i < il; i += 3) {

                                var a = index + indices[i];
                                var b = index + indices[i + 1];
                                var c = index + indices[i + 2];

                                // vec.push(positions[a * stride], positions[b * stride], positions[c * stride])
                                vA.fromArray(positions, a * stride);
                                vB.fromArray(positions, b * stride);
                                vC.fromArray(positions, c * stride);

                                vA.applyMatrix4(matrix);
                                vB.applyMatrix4(matrix);
                                vC.applyMatrix4(matrix);
                                vec = vec.concat(vA.toArray())
                                vec = vec.concat(vB.toArray())
                                vec = vec.concat(vC.toArray())
                            }
                        }
                    }
                    else {

                        var positions = geometry.vb ? geometry.vb : attributes.position.array;
                        positions = Array.from(positions)
                        var stride = geometry.vb ? geometry.vbstride : 3;

                        for (var i = 0, j = 0, il = positions.length; i < il; i += 3, j += 9) {

                            var a = i;
                            var b = i + 1;
                            var c = i + 2;

                            //vec.push(positions[a * stride], positions[b * stride], positions[c * stride])
                            vA.fromArray(positions, a * stride);
                            vB.fromArray(positions, b * stride);
                            vC.fromArray(positions, c * stride);

                            vA.applyMatrix4(matrix);
                            vB.applyMatrix4(matrix);
                            vC.applyMatrix4(matrix);
                            vec = vec.concat(vA.toArray())
                            vec = vec.concat(vB.toArray())
                            vec = vec.concat(vC.toArray())
                        }
                    }
                    vertices.push(vec)
                })
                resolve(vertices)
            })
    })
}
export const createMesh = async (id, instanceTree, viewer, model) => {
    return new Promise(async resolve => {
        let meshes = []
        let attributes = await getAttributePosition(id, instanceTree, viewer, model)
        attributes.forEach(v => {
            const geometry1 = new NTHREE.BufferGeometry();
            const vertices = new Float32Array(v);
            geometry1.setAttribute('position', new NTHREE.BufferAttribute(vertices, 3));
            // geometry1.applyMatrix4(matrix);;
            const mesh = new NTHREE.Mesh(geometry1, material);
            mesh.geometry.computeBoundsTree()
            meshes.push(mesh)
        })
        resolve(meshes)
    })
}

export const checkClash = async (idAs, idBs, viewer) => {
    return new Promise(async resolve => {
        let temp = []
        let count = 1
        for (var a in idAs) {
            let keyA = idAs[a].key
            let modelA = idAs[a].model
            let meshAs = await createMesh(idAs[a].id, modelA.getData().instanceTree, viewer, modelA)
            for (var b in idBs) {
                let keyB = idBs[b].key
                if (keyA === keyB) continue
                let modelB = idBs[b].model
                let meshBs = await createMesh(idBs[b].id, modelB.getData().instanceTree, viewer, modelB)
                let hit = false
                // eslint-disable-next-line no-loop-func
                let point
                const edge = new THREE.Line3();
                _.forEach(meshAs, meshA => {
                    _.forEach(meshBs, meshB => {
                        const transformMatrix =
                            new NTHREE.Matrix4()
                                .copy(meshA.matrixWorld).invert()
                                .multiply(meshB.matrixWorld);

                        meshA.geometry.boundsTree.bvhcast(meshB.geometry.boundsTree, transformMatrix, {
                            intersectsTriangles(triangle1, triangle2) {
                                hit = true
                                if (!point) {
                                    if (triangle1.intersectsTriangle(triangle2, edge)) {
                                        const { start, end } = edge;
                                        point = start
                                    }
                                }
                            }
                        });
                        // hit = meshA.geometry.boundsTree.intersectsGeometry(meshB.geometry, transformMatrix);
                        if (hit) {
                            return false
                        }
                    })
                    if (hit) {
                        return false
                    }
                })
                if (hit) {
                    temp.push({ name: `Clash ${count}`, status: 'New', elementA: idAs[a], elementB: idBs[b], position: point.clone() })
                    count++
                }
                // _.forEach(meshBs, meshB => {
                //     meshB.dispose()
                // })
            }
            // _.forEach(meshAs, meshA => {
            //     meshA.dispose()
            // })

        }
        resolve(temp)
    })
}