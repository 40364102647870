/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input, DatePicker as DatePickerDayjs } from 'antd';
import ModalTemplate from '../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { appStore } from '../../../../../store/App.store';
import { fiveDStore } from '../../../../../store/FiveD.store';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import moment from 'moment';
import {
  toSnakeCase,
  updateTableAsync,
} from '../../../../../functions/General.function';
import { AppAPI } from '../../../../api';
const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalFiveDAddSheetRow() {
  const { setIsLoading } = appStore();
  const { setIsOpenAddRow, sheetColumns, sheetGridApi, selectedSheet } =
    fiveDStore();
  const [data, setData] = useState({});
  const [dataExtra, setDataExtra] = useState({});
  useEffect(() => {
    debugger;
    const today = moment(new Date());
    const data = {
      description: '',
      plane_start: today,
      plane_end: today.add(7, 'days'),
      actual_start: null,
      actual_end: null,
      desgin_qty: '',
      final_qty: '',
      work_done: '',
    };
    setData(data);
  }, []);
  const close = () => {
    setIsOpenAddRow(false);
  };
  const handleChange = (type, e) => {
    data[type] = e.target.value;
    setData({ ...data });
  };

  const handleChangeExtra = (type, e) => {
    dataExtra[type] = e.target.value;
    setDataExtra({ ...dataExtra });
  };
  const handleOk = async (e) => {
    try {
      setIsLoading(true);
      debugger;
      const repSheetData = await AppAPI.FiveDSheetAPI.CreateSheetData({
        fiveDSheetId: selectedSheet.id,
        data,
        extra: dataExtra,
      });
      await updateTableAsync(sheetGridApi.api, { add: [repSheetData] });
      setIsLoading(false);
      close();
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };
  const handleDate = (type, e) => {
    data[type] = e;
    setData({ ...data });
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add Row'}
        style={{ maxWidth: 500, width: 500 }}
      >
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Description:</span>
          <Input.TextArea
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleChange.bind(this, 'description')}
            value={data.description}
          />
        </div>
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Plane Start:</span>
          <DatePicker
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleDate.bind(this, 'plane_start')}
            value={data.plane_start}
          />
        </div>
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Plane End:</span>
          <DatePicker
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleDate.bind(this, 'plane_end')}
            value={data.plane_end}
          />
        </div>
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Actual Start:</span>
          <DatePicker
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleDate.bind(this, 'actual_start')}
            value={data.actual_start}
          />
        </div>
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Actual End:</span>
          <DatePicker
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleDate.bind(this, 'actual_end')}
            value={data.actual_end}
          />
        </div>
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Design QTY:</span>
          <Input
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleChange.bind(this, 'desgin_qty')}
            value={data.desgin_qty}
          />
        </div>
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Final QTY:</span>
          <Input
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleChange.bind(this, 'final_qty')}
            value={data.final_qty}
          />
        </div>
        <div className='idd-itme'>
          <span style={{ fontWeight: 'bold', width: 125 }}>Work Done:</span>
          <Input
            style={{ width: '100%' }}
            placeholder='Please input'
            onChange={handleChange.bind(this, 'work_done')}
            value={data.work_done}
          />
        </div>
        {sheetColumns.map((v) => (
          <div className='idd-itme'>
            <span style={{ fontWeight: 'bold', width: 125 }}>{v.name}</span>
            <Input
              style={{ width: '100%' }}
              placeholder='Please input'
              onChange={handleChangeExtra.bind(this, toSnakeCase(v.name))}
              value={dataExtra[toSnakeCase(v.name)]}
            />
          </div>
        ))}
      </ModalTemplate>
    </>
  );
}
