import React, { useEffect, useState } from 'react';
import { DMS_COLOR } from '../../dms/constant';
import _ from 'lodash';

// { status: 'notset', amount: 4 },
//     { status: 'approve', amount: 1 },
//     { status: 'reject', amount: 5 }
export default function ConsultantCellGroup(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [status, setStatus] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (cellValue) {
      let total = 0;
      let status = [];
      _.forEach(cellValue, (v, k) => {
        total = total + v;
        status.push({ status: k, amount: v });
      });
      setTotal(total);
      setStatus(status);
    }
  }, [cellValue]);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {status.map((v) => (
        <div
          style={{
            background: DMS_COLOR[v.status] ? DMS_COLOR[v.status].bg : '',
            color: DMS_COLOR[v.status] ? DMS_COLOR[v.status].color : '',
            width: `${v.amount * 100 - total}%`,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontWeight: 'bold' }}> {v.amount}</span>
        </div>
      ))}
    </div>
  );
}
