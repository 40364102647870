import React from 'react';
import _ from 'lodash';
import TimelineDataSourceToolbar from './components/data_source/TimelineDataSource.toolbar';
import TimelineDataSourceList from './components/data_source/TimelineDataSource.list';
import { timelineStore } from './Timeline.store';
import { TimelineComponentType } from './constants/TimelineComponentType';

export default function TimelineSourceData({}) {
  const { segmentKey } = timelineStore();
  
  return (
    <>
      <div
        style={{
          display: segmentKey ===  TimelineComponentType.DataSource ? 'flex' : 'none',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <TimelineDataSourceToolbar />
        <TimelineDataSourceList />
      </div>
    </>
  );
}
