/* eslint-disable no-unused-vars */
import { Divider, message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import trilateration from 'node-trilateration';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetForgeItemStatus, TranslateForgeItemDirectly } from 'src/api';
import { AppAPI } from 'src/components/api';
import ModalTemplate from 'src/components/gen/modal/Modal.gen';
import HubDeviceList from 'src/components/layout/apps/worker/components/HubDeviceList';
import HubDeviceTag from 'src/components/layout/apps/worker/components/HubDeviceTag';
import SafetyAreaList from 'src/components/layout/apps/worker/components/SafetyAreaList';
import WorkerTrackingDeviceList from 'src/components/layout/apps/worker/components/WorkerTrackingDeviceList';
import { calculateDistance } from 'src/components/layout/apps/worker/Worker.utits';
import ViewerForge from 'src/components/layout/viewer/Viewer.forge';

import { appStore } from 'src/store/App.store';
import { viewerStore } from 'src/store/Viewer.store';
import { workerStore } from 'src/store/Worker.store';

let intervalId;
export default function ModalTrackingModel() {
  const {
    hubDeviceSprite,
    setIsOpenTrackingModel,
    nodeEditWorkerDaily,
    dailyDate,
    isRealtimeTracking,
    trackingDevices,
  } = workerStore();
  const { setIsLoading } = appStore();
  const { setViewer, viewer } = viewerStore();
  const { projectId } = useParams();

  const [objectId, setObjectId] = useState();

  const [hubDevices, setHubDevices] = useState([]);
  const [workerDevice, setWorkerDevice] = useState([]);

  const [currentDate, setCurrentDate] = useState();

  useEffect(() => {
    setCurrentDate(nodeEditWorkerDaily.data.date ?? dailyDate);
  }, [nodeEditWorkerDaily.data.date, dailyDate]);
  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }

    if (isRealtimeTracking) {
      tracking();
      debugger;
      intervalId = setInterval(() => {
        tracking();
      }, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };
  }, [hubDevices, workerDevice, trackingDevices, isRealtimeTracking]);

  const tracking = async () => {
    try {
      const data = await AppAPI.WorkerTrackingAPI.getWorkerTrackingRealtime({
        projectId,
        currentDate: nodeEditWorkerDaily.data.date ?? dailyDate,
      });
      handleTrackingWorker(data);
    } catch (ex) {}
  };
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    if (!nodeEditWorkerDaily.data.block_id) {
      close();
    }
    setIsLoading(true);
    const key = nodeEditWorkerDaily.data.path;
    try {
      if (!key) {
        throw new Error('Cannot find path');
      }
      const res = await GetForgeItemStatus({ objectId: key });
      if (res.data.status === 'success') {
        debugger;
        setObjectId(key);
      } else {
        let reg = res.data.progress.split('%');
        let progress = _.isNumber(_.toNumber(reg[0]))
          ? _.toNumber(reg[0])
          : 100;
        message.warning(`Translate ${progress === 0 ? 1 : progress}`);
        close();
      }
    } catch (ex) {
      if (ex.response.status === 404) {
        translateFile(key);
      } else {
        message.warning(ex.message);
        console.log(ex);
      }
      close();
    }
    setIsLoading(false);
  };
  const translateFile = async (objectId) => {
    try {
      await TranslateForgeItemDirectly({ objectId });
      message.warning('Start translate file');
    } catch (ex) {
      message.warning(ex.message);
    }
  };
  const handleTrackingWorker = (data) => {
    if (
      workerDevice.length !== 0 &&
      hubDevices.length !== 0 &&
      trackingDevices
    ) {
      if (data) {
        const devices = [];
        _.forEach(data, (v, workerTrackingUUID) => {
          let index = _.findIndex(
            workerDevice,
            (i) => i.workerId === workerTrackingUUID
          );
          if (index >= 0) {
            console.log(workerDevice[index]);
            const workerName = workerDevice[index].name;
            const isEditing = workerDevice[index].isEditing;
            if (isEditing) {
              workerDevice[index].status = 'offline';
            }

            const workerId = workerDevice[index].workerId;
            index = -1;
            const trackingData = [];
            _.forEach(v, (i, hubDeviceUUID) => {
              if (!i.rssi) {
                return;
              }
              index = _.findIndex(hubDevices, (o) => o.uuid === hubDeviceUUID);
              if (index >= 0) {
                if (isEditing) {
                  const device = _.find(
                    trackingDevices.devices,
                    (d) => d.id === workerId
                  );
                  // console.log(trackingDevices.devices);
                  if (device) {
                    devices.push(device);
                  }
                  return;
                }
                if (!hubDevices[index].location) {
                  return;
                }
                if (hubDevices[index].location?.length !== 0) {
                  const deviceTime = moment(new Date(i.time));
                  const differenceInMinutes = moment().diff(
                    deviceTime,
                    'minutes'
                  );
                  if (differenceInMinutes >= 0 && differenceInMinutes <= 5) {
                    i.distance = calculateDistance(i.rssi);
                    i.x = hubDevices[index].location[0];
                    i.y = hubDevices[index].location[1];
                    i.z = hubDevices[index].location[2];
                    i.hubName = hubDevices[index].name;

                    trackingData.push(i);
                  }
                }
              }
            });

            if (trackingData.length >= 3) {
              trackingData.sort((a, b) => b.time - a.time);

              const position = trilateration.calculate(trackingData);
              devices.push({
                location: [position.x, position.y, trackingData[0].z],
                name: workerName,
                id: workerId,
              });
              workerDevice[index].status = 'online';
              // console.log(trackingData);
              // console.log(position);
            }
          }
        });
        debugger;
        trackingDevices.hubDevices = hubDevices;
        trackingDevices.devices = devices;
        trackingDevices.reGenerateDevice();
      }
    }
  };

  const close = () => {
    setIsOpenTrackingModel(false);
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        title={'Worker tracking'}
        style={{ width: '90%', height: '90%' }}
        footer={null}
      >
        {objectId && (
          <div style={{ display: 'flex', gap: 5, height: '100%' }}>
            <div style={{ width: 'calc(100% - 200px)', height: '100%' }}>
              <ViewerForge name={'Tracking'} objectId={objectId} />
            </div>
            <div className='absolute z-10'>
              <span className='font-bold'>
                {currentDate && moment(currentDate).format('DD/MM/YYYY')}
              </span>
            </div>
            {viewer && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ width: 200, height: '100%' }}>
                  <div className='h-[100px]'>
                    <SafetyAreaList
                      isViewer={true}
                      blockId={nodeEditWorkerDaily.data?.block_id}
                    />
                  </div>

                  <Divider style={{ width: '100%' }} />
                  <HubDeviceList
                    hubDevices={hubDevices}
                    setHubDevices={setHubDevices}
                    isViewer={true}
                    blockId={nodeEditWorkerDaily.data.block_id}
                  />
                  <Divider style={{ width: '100%' }} />
                  {hubDevices.length !== 0 && (
                    <WorkerTrackingDeviceList
                      workerDevice={workerDevice}
                      setWorkerDevice={setWorkerDevice}
                      node={nodeEditWorkerDaily}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </ModalTemplate>
      {hubDeviceSprite && <HubDeviceTag />}
    </>
  );
}
