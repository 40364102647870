import _ from 'lodash';

export const generateRFARevRow = (nodes) => {
  const parentConsultant = {};
  _.forEach(nodes, (node) => {
    _.forEach(node.data, (v, k) => {
      if (k.includes('consultant_')) {
        if (!parentConsultant[k]) {
          parentConsultant[k] = {};
        }
        if (!parentConsultant[k][node.data[k].status]) {
          parentConsultant[k][node.data[k].status] = 0;
        }
        parentConsultant[k][node.data[k].status]++;
      }
    });
  });
  _.forEach(parentConsultant, (obj, k) => {
    const sort = _.pick(obj, Object.keys(obj).sort());
    parentConsultant[k] = sort;
  });
  return parentConsultant;
};

export const generateRFAREVFromApi = (rows, consultantGroups) => {
  const parentConsultant = {};
  _.forEach(rows, (v) => {
    _.forEach(consultantGroups, (group, k) => {
      const index = _.findIndex(v.consultants, (i) => {
        return i.companyId === group.companyId;
      });
      let consultantData = {};
      if (index < 0) {
        consultantData = {
          groupIds: group.groupIds,
          companyId: group.companyId,
          companyName: group.companyName,
          companyShortName: group.companyShortName,
          status: 'notstart',
          date: null,
          comment: '',
        };
      } else {
        consultantData = {
          ...v.consultants[index],
          groupIds: group.groupIds,
          companyId: group.companyId,
          companyName: group.companyName,
          companyShortName: group.companyShortName,
        };
      }
      const consultantname = `consultant_${k + 1}`;
      v[consultantname] = consultantData;

      if (!parentConsultant[consultantname]) {
        parentConsultant[consultantname] = {};
      }
      if (!parentConsultant[consultantname][consultantData.status]) {
        parentConsultant[consultantname][consultantData.status] = 0;
      }
      parentConsultant[consultantname][consultantData.status]++;
    });
  });
  _.forEach(parentConsultant, (obj, k) => {
    const sort = _.pick(obj, Object.keys(obj).sort());
    parentConsultant[k] = sort;
  });
  return { rows, parent: parentConsultant };
};

export const generateRFAREVConsultantGroup = (data) => {
  const tmp = {};
  _.forEach(data.consultant_groups, (group) => {
    if (!tmp[group.companyId]) {
      tmp[group.companyId] = {
        groupIds: [],
        companyId: group.companyId,
        companyName: group.companyName,
        companyShortName: group.companyShortName,
      };
    }
    tmp[group.companyId].groupIds.push(group.id);
  });
  const unique = _.values(tmp);
  const parentConsultant = {};
  const row = data.drawings;
  _.forEach(row, (v) => {
    if (v.consultants) {
      _.forEach(unique, (group, k) => {
        const index = _.findIndex(v.consultants, (i) => {
          return i.companyId === group.companyId;
        });
        let status = 'notstart';
        if (index >= 0) {
          status = v.consultants[index].status;
        }
        const consultantname = `consultant_${k + 1}`;

        if (!parentConsultant[consultantname]) {
          parentConsultant[consultantname] = {};
        }
        if (!parentConsultant[consultantname][status]) {
          parentConsultant[consultantname][status] = 0;
        }
        parentConsultant[consultantname][status]++;
      });
      //   _.forEach(drawing.consultants, (v) => {

      //   });
    } else {
      _.forEach(unique, (group, k) => {
        const consultantname = `consultant_${k + 1}`;

        if (!parentConsultant[consultantname]) {
          parentConsultant[consultantname] = {};
        }
        if (!parentConsultant[consultantname]['notstart']) {
          parentConsultant[consultantname]['notstart'] = 0;
        }
        parentConsultant[consultantname]['notstart']++;
      });
    }
    delete v.consultants;
    delete v.consultant_groups;
  });
  _.forEach(parentConsultant, (obj, k) => {
    const sort = _.pick(obj, Object.keys(obj).sort());
    parentConsultant[k] = sort;
  });

  Object.assign(data, parentConsultant);
  return unique;
};
