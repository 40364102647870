import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Table, Row, Col, Input, Button, message } from 'antd';
import _ from 'lodash'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    DeleteFilled,
    DeleteOutlined,
    EditFilled,
    PlusCircleOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import moment from 'moment'

import { Scrollbars } from 'react-custom-scrollbars-2';
import Icon from '../../../../gen/button/Icon.gen';
import { requestBimGrid } from '../../../../../functions/General.function';


export default function AnimationCard({ data, setType, setLoading }) {
    const [search] = useSearchParams();
    const { setAnimationDetail, setAnimationsData, animationsData } = viewerStore()
    const fileId = search.get('fileId')
    const versionId = search.get('versionId')
    const { organizationId, projectId } = useParams()
    useEffect(() => {

    }, [])


    const handleViewAnimation = () => {
        setAnimationDetail(data)
        setType('detail')
    }
    const handleDeleteAnimation = async () => {
        setLoading(true)
        let check = window.prompt('Are you want to delete? please input "Confirm" to process')
        if (check.toLowerCase() !== 'confirm') return
        let index = _.findIndex(animationsData, v => { return v.id === data.id })
        let res = await requestBimGrid('delete', '/api/animation', { animationIds: [animationsData[index].id] }, { organizationId, projectId, fileId })
        if (res) {
            let clone = [...animationsData]
            clone.splice(index, 1)
            setAnimationsData(clone)
        }
        setLoading(false)
    }
    const handleEditAnimation = async () => {
        let index = _.findIndex(animationsData, v => { return v.id === data.id })
        let aniData = { ...animationsData[index] }
        let name = window.prompt('Please input new name', aniData.name)
        if (!name) return
        setLoading(true)
        aniData.name = name
        let resData = await requestBimGrid('put', '/api/animation', { animationData: aniData }, { organizationId, projectId, fileId })
        if (resData) {
            let clone = [...animationsData]
            clone.splice(index, 1, resData)
            setAnimationsData(clone)
        }
        setLoading(false)
    }
    return (
        <>
            <div className='animation-card'
                onDoubleClick={handleViewAnimation}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
                    <div className='content' style={{ width: 'calc(100% - 50px)' }}>
                        <div style={{ display: 'flex', gap: 5, overflow: 'hidden', textOverflow: 'ellipsis' }} >
                            <span style={{ fontWeight: 'bold' }} >Title:</span>
                            <span>{data?.name}</span>
                        </div>



                        <div style={{ display: 'flex', gap: 5 }} >
                            <span style={{ fontWeight: 'bold' }} >Updated at:</span>
                            <span>{moment(data.updated_at).format('DD/MM/YYYY')}</span>
                        </div>
                        <div style={{ display: 'flex', gap: 5 }} >
                            <span style={{ fontWeight: 'bold' }} >Updated by:</span>
                            <span>{data.updated_by}</span>
                        </div>
                    </div>
                    <div className='control' style={{ width: 50 }} >
                        <div className='control-edit' onClick={handleEditAnimation}  >
                            <EditFilled />
                        </div>
                        <div className='control-delete' onClick={handleDeleteAnimation}  >
                            <DeleteFilled />
                        </div>

                    </div>

                </div>



            </div>

        </>

    );
}


