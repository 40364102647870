import { ReactComponent as CheckCircleIcon } from 'src/assets/icons/general/check-circle.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/general/close-circle.svg';

export default function BooleanCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return cellValue ? (
    <CheckCircleIcon className='text-green-600' />
  ) : (
    <CloseIcon className='text-red-600' />
  );
}
