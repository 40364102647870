import { useEffect, useState } from 'react';
import PageHeader from 'src/components/atoms/PageHeader';
import AppUserTable from 'src/components/layout/root_admin/app_user/AppUser.table';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { requestBimGrid } from '../../../../functions/General.function';
import { appStore } from '../../../../store/App.store';
import ModalEditAppUser from './modal/ModalEditAppUser';

export default function LayoutAppUser() {
  const { setIsLoading } = appStore();
  const [data, setData] = useState([]);

  const [rowNode, setRowNode] = useState();
  const [gridApi, setGridApi] = useState();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let reqPermission = await requestBimGrid('get', '/api/user/getAll', null);
    if (reqPermission) {
      setData(reqPermission);
    }
    setIsLoading(false);
  };

  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader>App User</PageHeader>
          </>
        }
      />

      <AppUserTable
        data={data}
        setGridApi={setGridApi}
        setRowNode={setRowNode}
      />

      {rowNode && (
        <ModalEditAppUser
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
