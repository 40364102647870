/* eslint-disable no-loop-func */
import React, { useEffect } from 'react';
import IconButton from '../../../../gen/button/IconButton.gen';
import IconTextButton from '../../../../gen/button/IconTextButton.gen';
import { viewerStore } from '../../../../../store/Viewer.store';
import _ from 'lodash';
import {
  getElementProperty,
  getProperties,
} from '../../../../../functions/Viewer.function';
import { appStore } from '../../../../../store/App.store';
import {
  sleep,
  updateTableAsync,
} from '../../../../../functions/General.function';
import { AppAPI } from '../../../../api';
import { message } from 'antd';
import { fiveDStore } from '../../../../../store/FiveD.store';
import { openView, workDoneProgress } from '../FiveD.utils';
export default function LinkElementCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const { viewer, treeModelData } = viewerStore();
  const { setIsLoading } = appStore();
  const {
    threeDVieweData,
    setThreeDVieweData,
    setIsOpen3D,
    isOpen3D,
    sheetColumns,
    workDoneDate,
  } = fiveDStore();
  useEffect(() => {
    // setIsLoading(false);
  }, []);
  const handleAdd = async () => {
    if (viewer) {
      setIsLoading(true);
      try {
        const selector = viewer.getAggregateSelection();
        for (const index in selector) {
          const element = selector[index];
          const fileId = element.model.myData.loadOptions.fileId;
          const versionId = element.model.myData.loadOptions.versionId;
          const selection = element.selection;
          const p = await getElementProperty(element.model, selection);
          const index1 = _.findIndex(props.node.data.object_3d, (v) => {
            return v.fileId === fileId;
          });
          if (index1 >= 0) {
            props.node.data.object_3d[index1].ids.push(
              ...p.map((v) => {
                return v.externalId;
              })
            );
          } else {
            props.node.data.object_3d = [];
            props.node.data.object_3d.push({
              fileId,
              versionId,
              viewId: element.model.myData?.loadOptions?.bubbleNode?.data?.guid,
              ids: p.map((v) => {
                return v.externalId;
              }),
            });
          }
        }
        await AppAPI.FiveDSheetAPI.UpdateOneSheetData({
          id: props.node.data.id,
          data: { object_3d: props.node.data.object_3d },
        });
        await updateTableAsync(props.api, {
          update: [props.node.data],
        });
      } catch (ex) {
        if (ex.message) message.warning(ex.message);
      }
      setIsLoading(false);
    }
  };
  const handleRemoveAll = async () => {
    try {
      if (!window.confirm('Are you sure to remove?')) return;
      setIsLoading(true);
      props.node.data.object_3d = null;
      await AppAPI.FiveDSheetAPI.UpdateOneSheetData({
        id: props.node.data.id,
        data: { object_3d: props.node.data.object_3d },
      });
      await updateTableAsync(props.api, {
        update: [props.node.data],
      });
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
    setIsLoading(false);
  };
  const handleSelect = () => {
    if (props.node.data.object_3d && viewer) {
      const selector = props.node.data.object_3d;
      const select = [];
      for (const index in selector) {
        const ids = [];
        let model = null;
        const element = selector[index];
        _.forEach(element.ids, (exId) => {
          const node = treeModelData.findNodeByFileId(exId, element.fileId);
          if (node) {
            ids.push(node.dbId);
            model = node.model;
          }
        });
        select.push({ model, ids });
      }
      viewer.impl.selector.setAggregateSelection(select);
    }
  };
  const handleOpenModel = async () => {
    try {
      if (props.node.data.object_3d?.length !== 0) {
        if (!isOpen3D) {
          setIsOpen3D(true);
          await sleep(1000);
        }
        const selector = props.node.data.object_3d;
        const files = [];
        const mapping = {};
        if (viewer) {
          const models = viewer.impl.modelQueue().getModels();
          _.forEach(models, (model) => {
            if (!mapping[model.myData.loadOptions.fileId]) {
              mapping[model.myData.loadOptions.fileId] =
                model.myData.loadOptions.fileId;
            }
          });
        }

        for (const index in selector) {
          const element = selector[index];
          if (mapping[element.fileId]) {
            continue;
          }
          mapping[element.fileId] = element.fileId;
          const fileRes = await AppAPI.VersionAPI.GetLastVersion({
            fileId: element.fileId,
            select: [
              'version',
              'file.name',
              'file_id as fileId',
              'object_id as objectId',
              'version.id as versionId',
            ],
          });
          fileRes.viewId = element.viewId;
          files.push(fileRes);
        }
        if (threeDVieweData) {
          for (const index in files) {
            try {
              await openView(viewer, files[index]);
            } catch (ex) {
              if (ex.message) message.warning(ex.message);
            }
          }
          setIsLoading(false);
          workDoneProgress(workDoneDate, props, treeModelData, viewer);
        } else {
          if (files.length !== 0) {
            setIsLoading(true);
            window.fiveDViewData = {};
            window.fiveDViewData.isInitView = true;
            window.fiveDViewData.files = files;
            setThreeDVieweData({
              fileId: files[0].fileId,
              versionId: files[0].versionId,
              name: files[0].name,
              objectId: files[0].objectId,
              viewId: files[0].viewId,
            });
          }
        }
      }
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
  };
  const handleAssignData = async () => {
    if (props.node.data.object_3d && viewer) {
      const index = _.findIndex(sheetColumns, (v) => {
        return v.id === 'design_qty';
      });
      if (index < 0) {
        return;
      }
      if (!sheetColumns[index].parameter) {
        return;
      }
      const selector = props.node.data.object_3d;
      const parameterName = sheetColumns[index].parameter;
      let total = 0;
      for (const index in selector) {
        const element = selector[index];
        _.forEach(element.ids, (exId) => {
          const node = treeModelData.findNode(exId);
          if (node) {
            if (node.model.myData.loadOptions.fileId === element.fileId) {
              let value = node.getParameterValueByName(parameterName);
              if (value) {
                const num = _.toNumber(value);
                if (_.isNumber(num) && !_.isNaN(num)) {
                  total += num;
                }
              }
            }
          }
        });
      }
      props.node.data.design_qty = total;
      await AppAPI.FiveDSheetAPI.UpdateOneSheetData({
        id: props.node.data.id,
        data: { design_qty: props.node.data.design_qty },
      });
    }
  };
  return (
    <div>
      {!cellValue ? (
        <IconTextButton size='sssmall' icon={'add'} onClick={handleAdd}>
          Add Element
        </IconTextButton>
      ) : (
        <div style={{ display: 'flex', gap: 2 }}>
          <IconButton
            icon={'add'}
            size='sssmall'
            onClick={handleAdd}
            disabled={!viewer}
          />
          <IconButton
            icon={'select'}
            size='sssmall'
            onClick={handleSelect}
            disabled={!viewer}
          />
          <IconButton
            icon={'three_d'}
            size='sssmall'
            onClick={handleOpenModel}
          />
          <IconButton
            icon={'reload'}
            size='sssmall'
            onClick={handleAssignData}
            disabled={!viewer}
          />
          <IconButton
            type='delete'
            icon={'delete'}
            size='sssmall'
            onClick={handleRemoveAll}
          />
        </div>
      )}
    </div>
  );
}
