import React, { useEffect, useState } from 'react';
import {
    Upload, message
} from 'antd';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import { updateTableAsync } from '../../../../../../functions/General.function';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { dmsStore } from '../../../../../../store/DMS.store';


export default function FileModelploadCell(props) {
    const { setIsOpenDocTable, setSelectedDrawingNode, setSelectedDrawingCol } = dmsStore()
    const [file, setFile] = useState()
    useEffect(() => {
        if (props.node.data[props.column.colId]) {
            setFile(props.node.data[props.column.colId])
        } else {
            setFile()
        }
    }, [props.node.data])

    const customRequest = async (info) => {
        if (info.file !== null) {
            if (info.file.percent < 101) {
                return;
            }
            if (info.file.percent === undefined) {
                debugger
                props.node.data[props.column.colId] = info.file
                await updateTableAsync(props.api, { update: [props.node.data] })
                props.api.redrawRows()
            }
        }
    };
    const handleRemove = async () => {
        if (!window.confirm('Are you sure to delete?')) return
        props.node.data[props.column.colId] = null
        await updateTableAsync(props.api, { update: [props.node.data] })
        props.api.redrawRows()
    }

    const handleDoc = () => {
        setSelectedDrawingCol(props.column.colId)
        setSelectedDrawingNode(props.node)
        setIsOpenDocTable(true)
    }
    return (
        <>
            {!file ?
                <> <div style={{ display: 'flex', gap: 5 }} >
                    <Upload className='idd-upload-input' customRequest={customRequest}
                        showUploadList={false} b>
                        <IconTextButton block={true} size='sssmall'
                            type='save'
                            icon={'add_file'} style={{ width: '100%' }} >
                            Select Local File
                        </IconTextButton>
                    </Upload>
                    <IconTextButton block={true} size='sssmall'
                        type='save'
                        icon={'add_file'} style={{ width: '100%' }}
                        onClick={handleDoc}
                    >
                        Select From DOC
                    </IconTextButton>
                </div></>
                :
                <div style={{ display: 'flex', gap: 5, justifyContent: 'space-between' }} >
                    <span style={{ fontWeight: '600', overflow: 'hidden', textOverflow: 'ellipsis' }} >{file.name}</span>
                    <IconButton size='sssmall' type='delete' icon={'close'} onClick={handleRemove} />
                </div>

            }
        </>
    );
};