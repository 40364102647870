import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { appStore } from 'src/store/App.store';

export default function ThreeDRoute() {
  const { setAppName } = appStore();

  useEffect(() => {
    setAppName('3d');
    return () => {
      setAppName(null);
    };
  }, []);

  return <Outlet />;
}
