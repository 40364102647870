/* eslint-disable no-unused-vars */
import { Form, message } from 'antd';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import Modal from 'src/components/molecules/Modal';
import { requestBimGrid } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';

export default function ModalAddOrganization({ setClose, gridApi }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setIsLoading } = appStore();
  const formRef = useRef();
  const close = () => {
    setClose(false);
  };

  const handleOk = async (e) => {
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};

      formData.forEach((value, key) => {
        data[key] = value;
      });

      const resData = await requestBimGrid(
        'post',
        '/api/admin/organization',
        data
      );
      if (resData) {
        await gridApi.api.applyTransactionAsync({ add: [resData] }, () => {
          gridApi.api.redrawRows();
          setClose(false);
        });
      }

      setIsLoading(false);
    } catch (ex) {
      message.error(ex);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Create Organization'}>
        <div className='w-[500px] max-w-screen-sm'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndInputForm
              label='Name'
              type='name'
              name='name'
              placeholder='Enter organization name'
              isRequired={true}
            />
            <LabelAndInputForm
              label='Email'
              type='email'
              name='email'
              placeholder='Enter organization email'
              isRequired={true}
            />
            <LabelAndInputForm
              label='Phone'
              type='phone'
              name='phone'
              placeholder='Enter phone number'
              isRequired={true}
            />
            <LabelAndInputForm
              label='Country'
              type='country'
              name='country'
              placeholder='Enter organization country'
              isRequired={true}
            />
            <LabelAndInputForm
              label='Address'
              type='address'
              name='address'
              placeholder='Enter organization address'
              isRequired={true}
            />

            <LabelAndInputForm
              label='City'
              type='city'
              name='city'
              placeholder='Enter organization city'
              isRequired={true}
            />

            <LabelAndInputForm
              label='Postal Code'
              type='postal_code'
              name='postal_code'
              placeholder='Enter organization postal code'
              isRequired={true}
            />
          </form>
        </div>
      </Modal>
    </>
  );
}
