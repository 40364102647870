import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ChecklistSidebar from '../../components/layout/routes/digicheck/ChecklistSidebar';
import { appStore } from '../../store/App.store';

export default function ChecklistRoute() {
  const location = useLocation();
  const { setAppName } = appStore();
  useEffect(() => {
    setAppName('digicheck/checklist');
    return () => {
      setAppName(null);
    };
  }, [location.pathname]);

  return (
    <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }}>
      <ChecklistSidebar />
      <div style={{ height: '100%', width: '100%' }}>
        <Outlet />
      </div>
    </div>
  );
}
