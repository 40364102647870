import React, { } from 'react';


import _ from 'lodash';




export default function ButtonNav({url , name, active,onClick , disabled }) {



    return (
        <div style={{ display: 'flex', gap: 5 }} >
            <button className={`idd-button-viewer ${active?'active':''}`} onClick={onClick} disabled={disabled}  >
                <div className='icon' style={{ WebkitMaskImage: `url(${url})` }} >
                </div>
                <span> {name}</span>
            </button>
        </div>

    );
}
