import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import AlertLogin from 'src/components/atoms/AlertLogin';
import AppLogo from 'src/components/atoms/AppLogo';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import RememberCheckbox from 'src/components/molecules/RemeberCheckbox';
/**
 * SignInForm component renders a login form with email and password inputs,
 * a checkbox for remembering the user, and a button to submit the form.
 *
 * @param {function(): void} onClickLogin - Function to handle login submission.
 * @param {boolean} isLoading - Indicates if the form is submitting.
 * @param {any} error - Apollo error object if there was an error during login.
 * @param {string} email - Optional pre-filled email address.
 * @returns {JSX.Element} The rendered SignInForm component.
 */
const SignInForm = ({ onClickLogin, error, isLoading, email }) => {
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const email = formData.get('email');
      const password = formData.get('password');
      onClickLogin({ email, password });
    },
    [onClickLogin]
  );

  return (
    <section className='bg-gray-50 dark:bg-gray-900'>
      <div className='mx-auto flex flex-col items-center justify-center px-6 py-8 sm:h-screen md:h-screen lg:py-0'>
        <div className='mb-6'>
          <AppLogo />
        </div>
        <div className='w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0'>
          <div className='space-y-4 p-6 sm:p-8 md:space-y-6'>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl'>
              Welcome Back
            </h1>
            {/* <div>
              <PrimaryButton isFull={true}>
                <img alt='microsoft_logo' src={MicrosoftLogo} />
                Sign in with Microsoft
              </PrimaryButton>
            </div> */}
            {/* <HorizontalDivider label={'OR'} /> */}
            {error && <AlertLogin message={error} />}
            <form className='space-y-4 md:space-y-6' onSubmit={handleSubmit}>
              <LabelAndInputForm
                label='Email'
                type='email'
                name='email'
                placeholder='name@company.com'
                isRequired
                defaultValue={email}
              />
              <LabelAndInputForm
                label='Password'
                type='password'
                name='password'
                placeholder='••••••••'
                isRequired
              />
              <div className='flex items-center justify-between'>
                <RememberCheckbox />
                <Link
                  to={'/forgot-password'}
                  className='text-sm font-medium text-primary-600 hover:underline dark:text-primary-500'
                >
                  Forgot password?
                </Link>
              </div>
              <PrimaryButton isFull isLoading={isLoading}>
                Sign In
              </PrimaryButton>
              <p className='text-sm font-light text-gray-500 dark:text-gray-400'>
                Don’t have an account yet?{' '}
                <Link
                  to={'/sign-up'}
                  className='font-medium text-primary-600 hover:underline dark:text-primary-500'
                >
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignInForm;
