import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { updateTableAsync } from '../../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import { AppAPI } from '../../../../../api';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { updateWorkerListPayment } from '../../Worker.utits';

export default function WorkerPaymentMonthTable() {
  const { setIsLoading } = appStore();
  const {
    setPaymentMonthGridApi,
    paymentMonthGridApi,
    paymentMonthData,
    setPaymentMonthData,
    setIsOpenEditWorkerPaymentMonth,
    setNodeEditWorkerPaymentMonth,
    paymentMonthDate,
  } = workerStore();
  const gridRef = useRef();
  useEffect(() => {
    if (paymentMonthDate) run();
  }, [paymentMonthDate]);
  const run = async () => {
    setIsLoading(true);

    try {
      const result =
        await AppAPI.WorkerPaymentMonthAPI.GetAllWorkPaymentMonthByProject({
          date: paymentMonthDate,
        });
      setPaymentMonthData(result);
    } catch (ex) {
      message.warning(ex.message);
    }
    setIsLoading(false);
  };

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      await AppAPI.WorkerDailyAPI.DeleteWorkerDaily({
        id: e.node.data.id,
      });
      await updateTableAsync(paymentMonthGridApi.api, {
        remove: [e.node.data],
      });
      await updateWorkerListPayment({
        data: { id: e.node.data.worker_id },
      });
      debugger;
    } catch (ex) {}
    setIsLoading(false);
  };
  const handleEditItem = async (e) => {
    setNodeEditWorkerPaymentMonth(e.node);
    setIsOpenEditWorkerPaymentMonth(true);
  };
  const columns = [
    {
      headerName: 'Employee Code',
      field: 'employee_code',
      filter: 'agSetColumnFilter',
      minWidth: 200,
    },
    {
      headerName: 'Worker Name',
      field: 'worker_name',
      filter: 'agSetColumnFilter',
      minWidth: 200,
    },

    {
      headerName: 'Company',
      field: 'company',
      filter: 'agSetColumnFilter',
      minWidth: 200,
    },
    {
      headerName: 'Gross Additions',
      children: [
        {
          headerName: 'Tot Salary',
          field: 'salary',
          filter: 'agSetColumnFilter',
          width: 100,
          minWidth: 100,
        },
        {
          headerName: 'Shift Allowance',
          field: 'gross_shift_allowance',
          filter: 'agSetColumnFilter',
          width: 100,
          minWidth: 100,
        },
      ],
    },
    {
      headerName: 'Allowance With CPF ( Ordinary )',
      children: [
        {
          headerName: 'Addition',
          children: [
            {
              headerName: 'Additional Pay (RD)',
              field: 'a_cdf_o_a_additional_pay',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Adjustment',
              field: 'a_cdf_o_a_adjustment',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Allowance (Employment)',
              field: 'a_cdf_o_a_allowance_e',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Salary In Advance',
              field: 'a_cdf_o_a_salary_in_advance',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Supervisor Allowance',
              field: 'a_cdf_o_a_supervisor_allowance',
              width: 150,
              minWidth: 180,
            },
          ],
        },
        {
          headerName: 'Deduction',
          children: [
            {
              headerName: 'Adjustment',
              field: 'a_cdf_o_d_adjustment',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Salary In Advance',
              field: 'a_cdf_o_d_salary_in_advance',
              width: 150,
              minWidth: 180,
            },
          ],
        },
      ],
    },
    {
      headerName: 'Allowance With CPF ( Additional )',
      children: [
        {
          headerName: 'Addition',
          children: [
            {
              headerName: 'Incentive',
              field: 'a_cdf_a_a_incentive',
              width: 150,
              minWidth: 180,
            },
          ],
        },
        {
          headerName: 'Deduction',
          children: [
            {
              headerName: 'Incentive',
              field: 'a_cdf_a_d_incentive',
              width: 150,
              minWidth: 180,
            },
          ],
        },
      ],
    },
    {
      headerName: 'Allowance With Out CPF',
      children: [
        {
          headerName: 'Addition',
          children: [
            {
              headerName: 'Adjustment On Last/Next Month',
              field: 'a_wo_cdf_a_adjustment_next_month',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'MC',
              field: 'a_wo_cdf_a_mc',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Meal Allowance',
              field: 'a_wo_cdf_a_meal_allowance',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Productivity Payment',
              field: 'a_wo_cdf_a_productivity_payment',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Skill Allowance',
              field: 'a_wo_cdf_a_skill_allowance',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Work Allowance (CV)',
              field: 'a_wo_cdf_a_work_allowance',
              width: 150,
              minWidth: 180,
            },
          ],
        },
        {
          headerName: 'Deduction',
          children: [
            {
              headerName: 'Annual Leave Deduction',
              field: 'a_wo_cdf_d_annual_leave_deduction',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Dormitory',
              field: 'a_wo_cdf_d_dormitory',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Loan',
              field: 'a_wo_cdf_d_loan',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Meal Allowance',
              field: 'a_wo_cdf_d_meal_allowance',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'Meal Allowance 1',
              field: 'a_wo_cdf_d_meal_allowance_1',
              width: 150,
              minWidth: 180,
            },
          ],
        },
      ],
    },
    {
      headerName: 'Gross Deductions With Out CPF',
      children: [
        {
          headerName: 'Deduction',
          children: [
            {
              headerName: 'No Pay Day',
              field: 'gross_d_wo_cdf_d_no_pay_day',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'CC',
              field: 'gross_d_wo_cdf_d_cc',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'CDAC',
              field: 'gross_d_wo_cdf_d_cdac',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'ECF',
              field: 'gross_d_wo_cdf_d_ecf',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'MBMF',
              field: 'gross_d_wo_cdf_d_mbmf',
              width: 150,
              minWidth: 180,
            },
            {
              headerName: 'SINDA',
              field: 'gross_d_wo_cdf_d_sinda',
              width: 150,
              minWidth: 180,
            },
          ],
        },
      ],
    },
    {
      headerName: 'Bonus',
      field: 'bonus',
      width: 150,
      minWidth: 180,
    },
    {
      headerName: 'Gross Wages',
      field: 'gross_wages',
      width: 150,
      minWidth: 180,
    },
    {
      headerName: 'CPF Wages',
      field: 'cpf_wages',
      width: 150,
      minWidth: 180,
    },
    {
      headerName: 'SDF',
      field: 'sdf',
      width: 150,
      minWidth: 180,
    },
    {
      headerName: 'FWL',
      field: 'fwl',
      width: 150,
      minWidth: 180,
    },
    {
      headerName: 'CPF Contribution',
      children: [
        {
          headerName: 'Employer CPF',
          field: 'employer_cpf',
          width: 150,
          minWidth: 180,
        },
        {
          headerName: 'Employee CPF',
          field: 'employee_cpf',
          width: 150,
          minWidth: 180,
        },
        {
          headerName: 'Total',
          field: 'total_cpf',
          width: 150,
          minWidth: 180,
        },
      ],
    },
    { headerName: 'Net', field: 'net_salary', width: 150, minWidth: 180 },

    {
      headerName: '',
      field: 'action_ignore',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleEditItem.bind(this, params)}
        />
        {/* <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        /> */}
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setPaymentMonthGridApi(params);
  }, []);
  const defaultExcelExportParams = useMemo(() => {
    return {
      pageSetup: {
        orientation: 'Landscape',
        pageSize: 'A3',
      },
      margins: {
        top: 1,
        right: 1,
        bottom: 1,
        left: 1,
        header: 0.5,
        footer: 0.5,
      },
    };
  }, []);
  const excelStyles = useMemo(() => {
    return [
      {
        id: 'groupBackground',
        interior: {
          color: '#dee3ea',
          pattern: 'Solid',
        },
      },
      {
        id: 'cell',
        alignment: {
          vertical: 'Center',
          horizontal: 'Right',
        },
        borders: {
          borderBottom: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
          },
          borderRight: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
      {
        id: 'header',
        alignment: {
          vertical: 'Center',
        },
        font: {
          color: '#ffffff',
        },
        interior: {
          color: '#34506e',
          pattern: 'Solid',
          patternColor: undefined,
        },
        borders: {
          borderBottom: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
    ];
  }, []);
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={paymentMonthData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
          groupDefaultExpanded={4}
          defaultExcelExportParams={defaultExcelExportParams}
          excelStyles={excelStyles}
        ></AgGridReact>
      </div>
    </>
  );
}
