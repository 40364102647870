import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import PaymentCell from 'src/components/layout/apps/worker/cell/Payment.cell';
import PaymentCellGroup from 'src/components/layout/apps/worker/cell/Payment.cell.group';
import TrackingCellGroup from 'src/components/layout/apps/worker/cell/Tracking.cell.group';
import TrackingTimeCell from 'src/components/layout/apps/worker/cell/TrackingTime.cell';
import { updateTableAsync } from '../../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import DateCell from '../../../../../ag_grid_components/cell/Date.cell';
import TimeCell from '../../../../../ag_grid_components/cell/Time.cell';
import { AppAPI } from '../../../../../api';
import IconButton from '../../../../../gen/button/IconButton.gen';
import BlockZoneCell from '../../cell/BlockZone.cell';
import { updateWorkerListPayment } from '../../Worker.utits';

export default function WorkerTrackingTable() {
  const { setIsLoading } = appStore();
  const {
    setTrackingGridApi,
    trackingGridApi,
    setDataTracking,
    dataTracking,
    trackingDate,
    trackingWorkerId,
    setIsOpenEditWorkerDaily,
    setNodeEditWorkerDaily,
    setIsRealtimeTracking,
  } = workerStore();
  const gridRef = useRef();
  useEffect(() => {
    setIsRealtimeTracking(false);
    if (trackingWorkerId && trackingDate) run();
  }, [trackingDate, trackingWorkerId]);
  const run = async () => {
    setIsLoading(true);
    try {
      const workerListRes =
        await AppAPI.WorkerTrackingAPI.GetAllWorkerTrackingByProjectDate({
          date: trackingDate,
          workerId: trackingWorkerId,
        });
      if (workerListRes) setDataTracking(workerListRes);
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      await AppAPI.WorkerDailyAPI.DeleteWorkerDaily({
        id: e.node.data.id,
      });
      await updateTableAsync(trackingGridApi.api, { remove: [e.node.data] });
      await updateWorkerListPayment({
        data: { id: e.node.data.worker_id },
      });
      debugger;
    } catch (ex) {}
    setIsLoading(false);
  };
  const handleEditItem = async (e) => {
    setNodeEditWorkerDaily(e.node);
    setIsOpenEditWorkerDaily(true);
  };
  const columns = [
    {
      headerName: 'Worker name',
      field: 'worker_name',
      filter: 'agSetColumnFilter',
      minWidth: 200,
      rowGroup: true,
      hide: true,
      type: 'date',
      cellClassRules: {
        groupBackground: (params) => {
          return params.node.group;
        },
      },
    },
    {
      headerName: 'Supervior name',
      field: 'supervior_name',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Trade',
      field: 'trade',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },

    {
      headerName: 'Date',
      field: 'date',
      filter: 'agSetColumnFilter',
      minWidth: 200,
      rowGroup: true,
      hide: true,
      cellRendererSelector: (params) => {
        return {
          component: DateCell,
        };
      },
    },
    {
      headerName: 'Start at',
      field: 'start_at',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: TimeCell,
        };
      },
    },
    {
      headerName: 'End at',
      field: 'end_at',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: TimeCell,
        };
      },
    },
    {
      headerName: 'Time_type',
      field: 'time_type',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Tracking time',
      field: 'tracking_time',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup)
          return {
            component: TrackingTimeCell,
          };
        else {
          return {
            component: TrackingCellGroup,
          };
        }
      },
    },
    {
      headerName: 'Payment',
      field: 'payment',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup)
          return {
            component: PaymentCell,
          };
        else {
          return {
            component: PaymentCellGroup,
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Location',
      field: 'block',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: BlockZoneCell,
        };
      },
    },
    {
      headerName: '',
      field: 'action_ignore',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleEditItem.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setTrackingGridApi(params);
  }, []);
  const defaultExcelExportParams = useMemo(() => {
    return {
      pageSetup: {
        orientation: 'Landscape',
        pageSize: 'A3',
      },
      margins: {
        top: 1,
        right: 1,
        bottom: 1,
        left: 1,
        header: 0.5,
        footer: 0.5,
      },
    };
  }, []);
  const excelStyles = useMemo(() => {
    return [
      {
        id: 'groupBackground',
        interior: {
          color: '#dee3ea',
          pattern: 'Solid',
        },
      },
      {
        id: 'cell',
        alignment: {
          vertical: 'Center',
          horizontal: 'Right',
        },
        borders: {
          borderBottom: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
          },
          borderRight: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
      {
        id: 'header',
        alignment: {
          vertical: 'Center',
        },
        font: {
          color: '#ffffff',
        },
        interior: {
          color: '#34506e',
          pattern: 'Solid',
          patternColor: undefined,
        },
        borders: {
          borderBottom: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
    ];
  }, []);
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={dataTracking}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
          groupDefaultExpanded={4}
          defaultExcelExportParams={defaultExcelExportParams}
          excelStyles={excelStyles}
        ></AgGridReact>
      </div>
    </>
  );
}
