import React, { useEffect, useRef, useState } from "react";
import { Select } from 'antd';
import { viewerStore } from "../../../../../store/Viewer.store";
import _ from "lodash";
import Loader from "../../../../loader/LoaderApp";
import ClashDetectionList from "./ClashDetection.list";
import ClashDetectionPoint from "../../util/ClashDetection.util";
import ClashDetectionDetail from "./ClashDetection.detail";
import ClashDetectionLinkIssue from "./ClashDetection.linkIssue";
import { clashDetectionStore } from "./ClashDetection.store";
import { ClashComponentType } from "./constants/ClashComponentType";

const { Option } = Select;
export default function ClassDetectionFeature() {
    const {viewer} = viewerStore()
    const {
        setType,
        type,
        clashDetectionClashTestSelected,
        setClashDetectionPoint,
        clashDetectionPoint,
        clashDetectLoading,
        resetClashDetectionStore
    } = clashDetectionStore()
    const ref = useRef()

    useEffect(() => {
        let clashDetectionPoint = new ClashDetectionPoint(viewer, 'clashDetection')
        clashDetectionPoint.active()
        setClashDetectionPoint(clashDetectionPoint)
        return () => {
            clashDetectionPoint.dispose()
            resetClashDetectionStore()
        }
    }, [])


    useEffect(() => {
        if (ref.current) {
            setClashDetectionPoint(ref.current)
        }
    }, [ref.current])

    useEffect(() => {
        if (clashDetectionClashTestSelected) {
            setType(ClashComponentType.Detail)
        }
    }, [clashDetectionClashTestSelected])
    return (
        <>
            {clashDetectLoading && <div style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 11111111 }} >
                <Loader />
            </div>}
            {clashDetectionPoint &&
                <>
                    <div style={{ display: !clashDetectionClashTestSelected ? 'block' : 'none', height: '100%' }}>
                        <ClashDetectionList
                        />

                    </div>

                    {clashDetectionClashTestSelected &&
                        <>
                            <div style={{ display: type ===ClashComponentType.Detail ? 'block' : 'none', height: '100%' }}>
                                <ClashDetectionDetail
                                />
                            </div>



                            {type ===  ClashComponentType.LinkIssue  &&
                                <ClashDetectionLinkIssue
                                />}

                        </>

                    }
                </>
            }
            {/* <ClashDetectionPoint ref={ref} args={[viewer, 'clashDetection']} /> */}
        </>
    );
}

