import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { appStore } from 'src/store/App.store';

export default function VDCRoute() {
  const { setAppName } = appStore();
  useEffect(() => {
    setAppName('vdc');
    return () => {
      setAppName(null);
    };
  }, []);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Outlet />
      </div>
    </>
  );
}
