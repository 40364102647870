import { Button, Select } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllModelsElementdbIdsWithCondition } from '../../../../../functions/Viewer.function';
import { viewerStore } from '../../../../../store/Viewer.store';
import {
  extractQuantity,
  getElementAndParameter,
} from '../../util/ExtractQuantity.util';
import NumberCellGroup from './cell/Number.cell.group';
const { Option } = Select;
export default function ParameterSelector({
  setIsOpen,
  setDataTable,
  setColumnData,
}) {
  const navigate = useNavigate();
  const { viewer } = viewerStore();
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [parameter, setParameter] = useState([]);
  const [parameterSelected, setParameterSelected] = useState([]);
  useEffect(() => {
    if (viewer) {
      const models = viewer.impl.modelQueue().getModels();
      let data = [];
      _.forEach(models, (model) => {
        let instanceTree = model.getData().instanceTree;
        let temp = getAllModelsElementdbIdsWithCondition(
          instanceTree,
          viewer,
          model,
          'all'
        );
        data.push({
          dbIds: temp,
          model: model,
          modelName: model.myData.loadOptions.modelNameOverride,
        });
      });
      setData(data);
      handleGetAllCategoryAndParameter(data);
    }
  }, []);
  const handleChange = (value) => {
    let temp = [];
    let duplicate = [];
    value.forEach((name) => {
      let index = _.findIndex(categories, (o) => {
        return o.name === name;
      });
      if (index >= 0) {
        _.forEach(categories[index].data, (item) => {
          if (!duplicate.includes(item.name)) {
            duplicate.push(item.name);
            temp.push({ name: item.name, units: item.units, type: item.type });
          }
        });
      }
    });
    setCategorySelected(value);
    setParameterSelected();
    setParameter(temp);
  };
  const handleGetAllCategoryAndParameter = async (result) => {
    // let count = 0;
    // result.forEach((item) => {
    //     count = count + item.dbIds.length
    // })
    // let listTempCategory = await getElementAndParameterRvt(result, count)
    const listTempCategory = await getElementAndParameter(result);
    console.log(listTempCategory);
    setCategories(listTempCategory);
  };
  const handleChangeParameter = (e) => {
    console.log(e);
    setParameterSelected(e);
  };

  const handleApply = async () => {
    let columns = [];
    const types = [];
    _.forEach(parameterSelected, (p) => {
      let index = _.findIndex(parameter, (v) => {
        return v.name === p;
      });
      types.push(parameter[index].units);
      columns.push({
        headerName: `${p} ${parameter[index].units ? '(' + parameter[index].units + ')' : ''}`,
        field: p,
        // minWidth: 120,
        // maxWidth: 120,
        filter: 'agSetColumnFilter',
        cellRendererSelector: (params) => {
          const isGroup = params.node.group;
          if (isGroup && parameter[index].type === 3)
            return {
              component: NumberCellGroup,
            };
        },
      });
    });
    let da = await extractQuantity(
      data,
      categorySelected,
      parameterSelected,
      types
    );
    setColumnData(columns);
    setDataTable(da);
    setIsOpen(false);
  };
  return (
    <div style={{ padding: 10 }}>
      <div>
        <span>Category: </span>
        <Select
          mode='multiple'
          className='idd-select'
          placeholder='Please select category'
          onChange={handleChange}
          optionFilterProp='children'
          filterOption={(input, option) =>
            (option?.name ?? '').toLowerCase().includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.name ?? '')
              .toLowerCase()
              .localeCompare((optionB?.name ?? '').toLowerCase())
          }
          style={{
            width: '100%',
          }}
          value={categorySelected}
        >
          {categories.map((i) => (
            <Option key={i.name} value={i.name}>
              {i.name}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <span>Parameter: </span>
        <Select
          className='idd-select'
          mode='multiple'
          placeholder='Please select parameter'
          onChange={handleChangeParameter}
          optionFilterProp='children'
          filterOption={(input, option) =>
            (option?.value ?? '').toLowerCase().includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.value ?? '')
              .toLowerCase()
              .localeCompare((optionB?.value ?? '').toLowerCase())
          }
          style={{
            width: '100%',
          }}
          value={parameterSelected}
        >
          {parameter.map((i) => (
            <Option key={i.name} value={i.name}>
              {i.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className='idd-divider' />
      <div className='footer'>
        <Button
          className={`idd-custom-button save block`}
          onClick={handleApply}
          disabled={parameter.length === 0}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}
