// import { Tabs } from 'flowbite-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabItem from 'src/components/atoms/TabItem';
import LayoutAppPermission from 'src/components/layout/root_admin/app_permission/AppPermission.layout';
import LayoutAppUser from 'src/components/layout/root_admin/app_user/AppUser.layout';
import Tabs from 'src/components/molecules/Tabs';

export default function LayoutSuperAdminManahement() {
  const [search, setSearchParams] = useSearchParams();
  const [type, setType] = useState(
    search.get('type') ? search.get('type') : 'user'
  );

  const handleChangeTab = (e) => {
    const newQueryParameters = new URLSearchParams();
    newQueryParameters.set('type', e);
    setSearchParams(newQueryParameters);
    setType(e);
  };

  return (
    <div className='h-[calc(100%-45px)] w-full'>
      <Tabs onTabChange={handleChangeTab} activeKey={type}>
        <TabItem title='User' key='user' />
        <TabItem title='Permission' key='permission' />
      </Tabs>
      <div className='h-[calc(100%-55px)]'>
        {' '}
        {type === 'user' && <LayoutAppUser />}
        {type === 'permission' && <LayoutAppPermission />}
      </div>
    </div>
  );
}
