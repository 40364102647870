import React, { useEffect, useState } from "react";
import { viewerStore } from "../../../../../store/Viewer.store";
import _ from "lodash";
import IssueAdd from "../Issue/Issue.add";
import CoordinationPoint from "../../util/Coordination.util";
import { clashDetectionStore } from "./ClashDetection.store";
import {ClashComponentType} from './constants/ClashComponentType'

let coordinationPoint = null
export default function ClashDetectionLinkIssue() {
    const { viewer } = viewerStore()
    const { setType ,clashDetectLoading , setClashDetectLoading,clashPositions ,clashDetectionPoint} = clashDetectionStore()

    useEffect(() => {
        coordinationPoint = new CoordinationPoint(viewer, 'coordination-link')
        coordinationPoint.active()
        coordinationPoint.setExistingPoints(clashPositions,'Category 1')
        return () => {
            coordinationPoint?.dispose()
            coordinationPoint = null
            clashDetectionPoint.unhidePointCloud()
        }
    }, [])
    const handleBack =()=>{
        setType(ClashComponentType.Detail)
    }

    return (
        <>
            <IssueAdd
                setType={setType}
                setLoading={setClashDetectLoading}
                loading={clashDetectLoading}
                coordinationPoint={coordinationPoint}
                isLinkClash={true}
                handleBackExt={handleBack}
                clashPositions={clashPositions}
            />
        </>
    );
}


