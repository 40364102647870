import React from 'react';
import WorkerDailyLayout from '../../../layout/apps/worker/daily/WorkerDaily.layout';

export default function WorkerDailyPage() {
  return (
    <>
      {' '}
      <WorkerDailyLayout />
    </>
  );
}
