/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    Upload
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import IconButton from '../../../../../gen/button/IconButton.gen';
import IconStatic from '../../../../../gen/icon/IconStatic.gen';



export default function UploadFile({ name, setFiles }) {


    const handleDelete = () => {
        if (!window.confirm('Are you sure to delete?')) return
        setFiles(prev => {
            let index = _.findIndex(prev, v => v.name === name)
            if (index >= 0) {
                prev.splice(index, 1)
            }
            return [...prev]
        })
    }


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 5, alignItems: 'center' }}>
                <div style={{ width: 'calc(100% - 50px)', display: 'flex', gap: 5, alignItems: 'center' }} >
                    <IconStatic size='ssmall' icon='upload_file' ghost border={false} />
                    <span>{name}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', width: 50 }} >
                    <div > <IconButton size='ssmall' type='delete' icon='delete' onClick={handleDelete} /> </div>
                </div>

            </div>
        </>
    );
}

