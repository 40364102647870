import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RegisterUserInvitaionAPI } from 'src/api';
import { CheckUserInvitationAPI } from 'src/api/auth';
import FullSceenLoader from 'src/components/molecules/FullSceenLoader';
import UserInvitationForm from 'src/components/organisms/UserInvitationForm';

const UserInvitationPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setIsLoading] = useState();
  const [search] = useSearchParams();
  const [user, setUser] = useState();
  const id = search.get('id');
  useEffect(() => {
    (async () => {
      try {
        const user = await CheckUserInvitationAPI({ id });
        setUser(user);
      } catch (ex) {
        if (ex.message) message.warning(ex.message);
      }
    })();
  }, []);
  const onClickRegister = async (registerInput) => {
    setIsLoading(true);
    try {
      debugger;
      const data = await RegisterUserInvitaionAPI({
        id: user.id,
        name: registerInput.name,
        password: registerInput.password,
      });
      if (data) {
        localStorage.bimGridToken = data.token;
        navigate('/organization');
      }
    } catch (ex) {
      setError(ex.message ?? ex ?? 'Error');
    }
    setIsLoading(false);
  };
  return (
    <>
      {loading && <FullSceenLoader />}
      {user && (
        <UserInvitationForm
          onClickRegister={onClickRegister}
          isLoading={loading}
          error={error}
          email={user.email}
        />
      )}
    </>
  );
};

export default UserInvitationPage;
