import _ from 'lodash';
import { pointerToRaycaster } from '../../../../functions/Viewer.function';
import { EventDispatcher } from '../extensions/utils/EventDispatcher';

const Autodesk = window.Autodesk;
const avp = Autodesk.Viewing.Private;
const THREE = window.THREE;
export default class MarkupPoint extends EventDispatcher {
  name;
  raycaster = new THREE.Raycaster();

  viewer = null;
  size = 25.0; // markup size.  Change this if markup size is too big or small
  markupItems = []; // array containing markup data
  pointCloud; // three js point-cloud mesh object
  pointCloudVis;
  newPointCloud;
  camera;
  offset; // global offset
  isEnableAdd = false;

  hovered = []; // index of selected pointCloud id, based on markupItems array
  markupSelected = null;

  vertexShader = `
    uniform float size;
    varying float vCameraDistance;
    varying float vDistance;
    varying vec3 vColor;
    varying vec2 vSizeAp;
    attribute vec3 color;
    attribute vec2 sizeAp;
    void main() {
        vColor = color;
        vSizeAp =sizeAp;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
        vDistance = length(mvPosition.xyz);
        gl_PointSize = size *vSizeAp.y;
    
    }
`;

  fragmentShader = `
    uniform sampler2D tex;
    varying vec2 vSizeAp;
    varying vec3 vColor;
    void main() {
        float distance = length(gl_PointCoord - vec2(0.5, 0.5));
        if (distance > 0.5) discard;
        float borderWidth = 0.1;
        float borderDistance = length(gl_PointCoord - vec2(0.5, 0.5));
        if (borderDistance > 0.5 - borderWidth) {
          gl_FragColor =vec4( vColor, 1 )  ;
        } else {
          gl_FragColor = vec4( vColor, vSizeAp.x)  ;
        }    
    }
`;
  material = new THREE.ShaderMaterial({
    // vertexColors: THREE.VertexColors,
    fragmentShader: this.fragmentShader,
    vertexShader: this.vertexShader,
    depthWrite: true,
    depthTest: false,
    uniforms: {
      size: { type: 'f', value: this.size },
    },
  });

  event = {
    select: 'select',
    hover: 'hover',
    popup: 'popup',
  };
  //
  constructor(viewer, name) {
    super();
    this.viewer = viewer;
    this.raycaster = new THREE.Raycaster();
    this.raycaster.params.PointCloud.threshold = 0.1;
    this.camera = viewer.impl.camera;
    this.offset = viewer.model.getData().globalOffset;
    this.name = name;
  }

  load = async () => {
    this.viewer.impl.createOverlayScene(this.name);
    this.viewer.impl.createOverlayScene(`add-${this.name}`);
    this.viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.handleChangeCamera
    );
  };
  unload = () => {
    this.viewer.impl.removeOverlayScene(this.name);
    this.viewer.impl.removeOverlayScene(`add-${this.name}`);
    this.viewer.removeEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.handleChangeCamera
    );
  };

  setMarkupData = (data) => {
    this.newPointCloud = null;
    this.markupItems = data;
    this.initMesh_PointCloud(data);
  };
  initMesh_PointCloud = () => {
    this.viewer.impl.removeOverlayScene(this.name);
    this.viewer.impl.createOverlayScene(this.name);
    let position = [];
    let color = [];
    let sizeAp = [];
    this.geometry = new THREE.Geometry();
    this.bufferGeometry = new THREE.BufferGeometry();
    this.markupItems.forEach((item, k) => {
      const point = new THREE.Vector3(
        item.position.x,
        item.position.y,
        item.position.z
      );
      this.geometry.vertices.push(point);
      this.geometry.colors.push(this.colors[item.colorName]);
      position.push(item.position.x, item.position.y, item.position.z);
      color.push(
        this.colors[item.colorName].r,
        this.colors[item.colorName].g,
        this.colors[item.colorName].b
      );
      sizeAp.push(0.1, 1);
    });
    this.pointCloud = new THREE.PointCloud(this.geometry, this.material);
    // this.pointCloud.position.copy(new THREE.Vector3(-this.offset.x, -this.offset.y, -this.offset.z))
    let positionAttribute = new THREE.BufferAttribute(
      new Float32Array(position),
      3
    );
    let colorAttribute = new THREE.BufferAttribute(new Float32Array(color), 3);
    let sizeApAttribute = new THREE.BufferAttribute(
      new Float32Array(sizeAp),
      2
    );
    this.bufferGeometry.setAttribute('position', positionAttribute);
    this.bufferGeometry.setAttribute('color', colorAttribute);
    this.bufferGeometry.setAttribute('sizeAp', sizeApAttribute);
    this.bufferGeometry.isPoints = true;
    this.pointCloudVis = new THREE.Mesh(this.bufferGeometry, this.material);
    // this.pointCloudVis.position.copy(new THREE.Vector3(-this.offset.x, -this.offset.y, -this.offset.z))
    this.viewer.impl.addOverlay(this.name, this.pointCloudVis);
  };

  hidePointCloud = () => {
    this.viewer.impl.removeOverlayScene(this.name);
  };
  unhidePointCloud = () => {
    // this.pointCloudVis.visible = true
    this.viewer.impl.createOverlayScene(this.name);
    this.viewer.impl.addOverlay(this.name, this.pointCloudVis);
    this.viewer.impl.removeOverlayScene(`add-${this.name}`);
  };

  setExistingPoints = (positions, colorName) => {
    this.viewer.impl.removeOverlayScene(`add-${this.name}`);
    this.viewer.impl.createOverlayScene(`add-${this.name}`);
    const geometry = new THREE.BufferGeometry();
    let position = [];
    let color = [];
    let size = [];
    _.forEach(positions, (v) => {
      position.push(v.x, v.y, v.z);
      color.push(
        this.colors[colorName].r,
        this.colors[colorName].g,
        this.colors[colorName].b
      );
      size.push(0.1, 1);
    });
    geometry.setAttribute(
      'position',
      new THREE.BufferAttribute(new Float32Array(position), 3)
    );
    geometry.setAttribute(
      'color',
      new THREE.BufferAttribute(new Float32Array(color), 3)
    );
    geometry.setAttribute(
      'sizeAp',
      new THREE.BufferAttribute(new Float32Array(size), 2)
    );
    geometry.isPoints = true;
    this.newPointCloud = new THREE.Mesh(geometry, this.material);
    this.viewer.impl.addOverlay(`add-${this.name}`, this.newPointCloud);
  };

  enableAddNewPoint = (point) => {
    this.viewer.canvas.addEventListener(
      'pointerdown',
      this.eventAddNewCoordination,
      false
    );
    this.newPointCloud = point;
  };
  disableAddNewPoint = () => {
    this.viewer?.canvas.removeEventListener(
      'pointerdown',
      this.eventAddNewCoordination,
      false
    );
    return this.newPointCloud;
  };

  addNewPoint = (event) => {
    // const point = getHitPoint(event, this.viewer)
    this.container = this.viewer.canvas.parentElement;
    this.rect = this.container.getBoundingClientRect();
    this.x = event.clientX - this.rect.x;
    this.y = event.clientY - this.rect.y;
    var point = this.viewer.hitTest(this.x, this.y, true);
    if (point) {
      this.viewer.impl.removeOverlayScene(`add-${this.name}`);
      this.viewer.impl.createOverlayScene(`add-${this.name}`);
      let position = point.point;
      let geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(
          new Float32Array([position.x, position.y, position.z]),
          3
        )
      );
      geometry.setAttribute(
        'color',
        new THREE.BufferAttribute(
          new Float32Array([this.colorNew.r, this.colorNew.g, this.colorNew.b]),
          3
        )
      );
      geometry.setAttribute(
        'sizeAp',
        new THREE.BufferAttribute(new Float32Array([0.1, 1]), 2)
      );
      geometry.isPoints = true;

      this.newPointCloud = new THREE.Mesh(geometry, this.material);
      this.viewer.impl.addOverlay(`add-${this.name}`, this.newPointCloud);
    }
  };
  removeMarkupSelected = () => {
    this.markupSelected = null;
  };

  updateHitTest = (event) => {
    if (this.newPointCloud) return;
    if (!this.pointCloud) return;
    this.container = this.viewer.canvas;
    this.rect = this.container.getBoundingClientRect();
    let raycaster = pointerToRaycaster(
      this.viewer.impl.camera,
      this.raycaster,
      event,
      this.rect
    );
    let nodes = raycaster.intersectObject(this.pointCloud);
    return nodes;
  };
  updateDiv = (point) => {
    let clone = point.clone();
    let temp = clone.project(this.camera);
    let rect = this.container.getBoundingClientRect();
    let position = {
      x: ((temp.x + 1) / 2) * rect.width + rect.left,
      y: (-(temp.y - 1) / 2) * rect.height + rect.top,
    };
    return position;
  };
  handleChangeCamera = () => {
    if (this.markupSelected) {
      let position = this.updateDiv(this.markupSelected.point);
      this.dispatchEvent({
        type: this.event.popup,
        position,
      });
    }
  };

  editExistingPoint = (index, item) => {
    let regularArrayColor = Array.from(
      this.pointCloudVis.geometry.attributes.color.array
    );
    regularArrayColor[index * 3] = this.colors[item.colorName].r;
    regularArrayColor[index * 3 + 1] = this.colors[item.colorName].g;
    regularArrayColor[index * 3 + 2] = this.colors[item.colorName].b;
    this.pointCloudVis.geometry.attributes.color.array = new Float32Array(
      regularArrayColor
    );
    this.pointCloudVis.geometry.attributes.color.needsUpdate = true;
    this.pointCloudVis.needsUpdate = true;
    this.pointCloudVis.updateMatrixWorld();
    this.viewer.impl.invalidate(true);
    this.markupItems.splice(index, 1, item);
  };

  hoverPoint = (event) => {
    let nodes = this.updateHitTest(event);
    if (!nodes) return;
    let regularArrayColor = Array.from(
      this.pointCloudVis.geometry.attributes.color.array
    );
    let regularArraySizeAp = Array.from(
      this.pointCloudVis.geometry.attributes.sizeAp.array
    );
    if (nodes.length > 0) {
      if (this.hovered.length !== 0) {
        this.hovered.forEach((item) => {
          // regularArrayColor[item[0] * 3] = item[1];
          regularArraySizeAp[item[0] * 2 + 1] = 1;
        });
      }
      _.forEach(nodes, (node) => {
        let index = node.index;
        this.hovered.push([index, regularArrayColor[index * 3]]);
        // regularArrayColor[index * 3] = 2
        regularArraySizeAp[index * 2 + 1] = 1.5;
        // return false
      });
    } else {
      if (this.hovered.length !== 0) {
        this.hovered.forEach((item) => {
          // regularArrayColor[item[0] * 3] = item[1];
          regularArraySizeAp[item[0] * 2 + 1] = 1;
        });
      }
      this.hovered = [];
    }
    this.pointCloudVis.geometry.attributes.color.array = new Float32Array(
      regularArrayColor
    );
    this.pointCloudVis.geometry.attributes.color.needsUpdate = true;
    this.pointCloudVis.geometry.attributes.sizeAp.array = new Float32Array(
      regularArraySizeAp
    );
    this.pointCloudVis.geometry.attributes.sizeAp.needsUpdate = true;
    this.pointCloudVis.needsUpdate = true;
    this.pointCloudVis.updateMatrixWorld();
    this.viewer.impl.invalidate(true);
    this.dispatchEvent({
      type: this.event.hover,
      nodes,
    });
  };
  pickPoint = (event) => {
    let nodes = this.updateHitTest(event);
    if (!nodes) return;
    let position = null;
    if (nodes.length > 0) {
      let point = nodes[0].point;
      position = this.updateDiv(point);
      this.markupSelected = {
        point: new THREE.Vector3(point.x, point.y, point.z),
      };
    } else {
      this.markupSelected = null;
    }
    this.dispatchEvent({
      type: this.event.select,
      nodes,
      position,
    });
  };
}

// if (!this.viewer.overlays.hasScene('coordination-scene')) {
//     this.viewer.overlays.addScene('coordination-scene');
// }
// if (!this.viewer.overlays.hasScene('add-coordination-scene')) {
//     this.viewer.overlays.addScene('add-coordination-scene');
// }
