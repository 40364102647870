import { toSnakeCase } from "../../../../../../functions/General.function"

export const defaultParameters = [
    'Name', 'Category', 'Length', 'Height', 'Width',"Volume", 'Rebar Weight'
]

export const defaultParameters2 = defaultParameters.map(v=>toSnakeCase(v))

export const DEFAULT_COLOR ={
    NOTSTART: "#676767",
    CASTED: "#1890ff",
    DELIVERY:"#f39c12",
    INSTALL:"#722ed1"
}

export const CATEGORY_PPVC = [
    "Wall" ,'Slab', 'Foundattion' ,'Column'
]

export const CATEGORY_PPVC_COLOR ={
    Wall: "#E06666",
    Slab: "#F6B26B",
    Foundattion:"#93C47D",
    Column:"#6FA8DC"
}