import moment from 'moment';
import create from 'zustand';

const initialState = {
  //#region  worker list
  isOpenAddWokerList: false,
  isOpenEditWokerList: false,
  isOpenMoneyWokerList: false,
  nodeEditWorkerList: null,
  workerListGridApi: null,
  dataWorkerList: [],
  //#endregion

  //#region  supervior list
  isOpenAddSuperviorList: false,
  isOpenEditSuperviorList: false,
  nodeEditSuperviorList: null,
  superviorListGridApi: null,
  dataSuperviorList: [],
  //#endregion

  //#region  cost code
  isOpenAddCostCode: false,
  isOpenEditCostCode: false,
  nodeEditCostCode: null,
  costCodeGridApi: null,
  dataCostCode: [],
  //#endregion

  //#region block zone
  isOpenAddBlockZone: false,
  isOpenBlockZoneModel: false,
  isOpenEditBlockZone: false,
  nodeEditBlockZone: null,
  blockZoneGridApi: null,
  dataBlockZone: [],
  //#endregion

  //#region block zone
  isOpenAddWorkTime: false,
  isOpenEditWorkTime: false,
  nodeEditWorkTime: null,
  workTimeGridApi: null,
  dataWorkTime: [],
  //#endregion

  //#region worker daily
  isOpenAddWorkerDaily: false,
  isOpenEditWorkerDaily: false,
  workerDailyGridApi: null,
  nodeEditWorkerDaily: null,
  dailyDate: moment(new Date()).startOf('date'),
  dataWorkerDaily: [],
  isOpenTrackingModel: false,
  //#endregion

  //#region pay rate
  payRateGridApi: null,
  dataPayRate: [],
  payRateEditData: { pay_rate: 0, start_date: null, end_date: null },
  //#endregion

  //#region tracking
  trackingGridApi: null,
  trackingWorkerId: null,
  trackingDate: [
    moment(new Date()).startOf('month'),
    moment(new Date()).endOf('month'),
  ],
  dataTracking: [],
  //#endregion

  //#region hub devices
  hubDevicesGridApi: null,
  isOpenAddHubDevices: false,
  isOpenEditHubDevices: false,
  nodeEditHubDevices: null,
  dataHubDevices: [],
  //#endregion

  //#region tracking devices
  trackingDevicesGridApi: null,
  isOpenAddTrackingDevices: false,
  isOpenEditTrackingDevices: false,
  nodeEditTrackingDevices: null,
  dataTrackingDevices: [],
  trackingDevices: [],
  //#endregion

  //#region hub device tag
  hubDeviceSprite: null,
  //#endregion
  isRealtimeTracking: false,
  realtimeDate: null,

  //@region //! payment month
  paymentMonthGridApi: null,
  paymentMonthDate: [
    moment(new Date()).startOf('month'),
    moment(new Date()).endOf('month'),
  ],
  paymentMonthData: null,
  nodeEditWorkerPaymentMonth: null,
  isOpenEditWorkerPaymentMonth: false,
  //#endregion
};
export const workerStore = create((set) => ({
  ...initialState,
  //#region  worker list
  setIsOpenAddWokerList: (value) =>
    set((state) => ({ isOpenAddWokerList: value })),
  setIsOpenEditWokerList: (value) =>
    set((state) => ({ isOpenEditWokerList: value })),
  setWorkerListGridApi: (value) =>
    set((state) => ({ workerListGridApi: value })),
  setNodeEditWorkerList: (value) =>
    set((state) => ({ nodeEditWorkerList: value })),
  setDataWorkerList: (value) => set((state) => ({ dataWorkerList: value })),
  //#endregion
  //#region  supervior list
  setIsOpenAddSuperviorList: (value) =>
    set((state) => ({ isOpenAddSuperviorList: value })),
  setIsOpenEditSuperviorList: (value) =>
    set((state) => ({ isOpenEditSuperviorList: value })),
  setNodeEditSuperviorList: (value) =>
    set((state) => ({ nodeEditSuperviorList: value })),
  setSuperviorListGridApi: (value) =>
    set((state) => ({ superviorListGridApi: value })),
  setDataSuperviorList: (value) =>
    set((state) => ({ dataSuperviorList: value })),
  //#endregion
  //#region  cost code
  setIsOpenAddCostCode: (value) =>
    set((state) => ({ isOpenAddCostCode: value })),
  setIsOpenEditCostCode: (value) =>
    set((state) => ({ isOpenEditCostCode: value })),
  setNodeEditCostCode: (value) => set((state) => ({ nodeEditCostCode: value })),
  setCostCodeGridApi: (value) => set((state) => ({ costCodeGridApi: value })),
  setDataCostCode: (value) => set((state) => ({ dataCostCode: value })),
  //#endregion
  //#region block zone
  setIsOpenAddBlockZone: (value) =>
    set((state) => ({ isOpenAddBlockZone: value })),
  setIsOpenBlockZoneModel: (value) =>
    set((state) => ({ isOpenBlockZoneModel: value })),
  setIsOpenEditBlockZone: (value) =>
    set((state) => ({ isOpenEditBlockZone: value })),
  setNodeEditBlockZone: (value) =>
    set((state) => ({ nodeEditBlockZone: value })),
  setBlockZoneGridApi: (value) => set((state) => ({ blockZoneGridApi: value })),
  setDataBlockZone: (value) => set((state) => ({ dataBlockZone: value })),
  //#endregion
  //#region work time
  setIsOpenAddWorkTime: (value) =>
    set((state) => ({ isOpenAddWorkTime: value })),
  setIsOpenEditWorkTime: (value) =>
    set((state) => ({ isOpenEditWorkTime: value })),
  setNodeEditWorkTime: (value) => set((state) => ({ nodeEditWorkTime: value })),
  setWorkTimeGridApi: (value) => set((state) => ({ workTimeGridApi: value })),
  setDataWorkTime: (value) => set((state) => ({ dataWorkTime: value })),
  //#endregion
  //#region work daily
  setIsOpenAddWorkerDaily: (value) =>
    set((state) => ({ isOpenAddWorkerDaily: value })),
  setIsOpenEditWorkerDaily: (value) =>
    set((state) => ({ isOpenEditWorkerDaily: value })),
  setDailyDate: (value) => set((state) => ({ dailyDate: value })),
  setWorkerDailyGridApi: (value) =>
    set((state) => ({ workerDailyGridApi: value })),
  setDataWorkerDaily: (value) => set((state) => ({ dataWorkerDaily: value })),
  setNodeEditWorkerDaily: (value) =>
    set((state) => ({ nodeEditWorkerDaily: value })),
  setIsOpenTrackingModel: (value) =>
    set((state) => ({ isOpenTrackingModel: value })),
  //#endregion
  //#region payrate
  setIsOpenMoneyWokerList: (value) =>
    set((state) => ({ isOpenMoneyWokerList: value })),
  setPayRateEditData: (value) => set((state) => ({ payRateEditData: value })),
  setPayRateGridApi: (value) => set((state) => ({ payRateGridApi: value })),
  setDataPayRate: (value) => set((state) => ({ dataPayRate: value })),
  //#endregion
  //#region tracking
  setTrackingWorkerId: (value) => set((state) => ({ trackingWorkerId: value })),
  setTrackingGridApi: (value) => set((state) => ({ trackingGridApi: value })),
  setDataTracking: (value) => set((state) => ({ dataTracking: value })),
  setTrackingDate: (value) => set((state) => ({ trackingDate: value })),
  setTrackingDevices: (value) => set((state) => ({ trackingDevices: value })),
  //#endregion
  //#region hub
  setHubDevicesGridApi: (value) =>
    set((state) => ({ hubDevicesGridApi: value })),
  setIsOpenAddHubDevices: (value) =>
    set((state) => ({ isOpenAddHubDevices: value })),
  setIsOpenEditHubDevices: (value) =>
    set((state) => ({ isOpenEditHubDevices: value })),
  setNodeEditHubDevices: (value) =>
    set((state) => ({ nodeEditHubDevices: value })),
  setDataHubDevices: (value) => set((state) => ({ dataHubDevices: value })),
  //#endregion
  //#region tracking
  setTrackingDevicesGridApi: (value) =>
    set((state) => ({ trackingDevicesGridApi: value })),
  setIsOpenAddTrackingDevices: (value) =>
    set((state) => ({ isOpenAddTrackingDevices: value })),
  setIsOpenEditTrackingDevices: (value) =>
    set((state) => ({ isOpenEditTrackingDevices: value })),
  setNodeEditTrackingDevices: (value) =>
    set((state) => ({ nodeEditTrackingDevices: value })),
  setDataTrackingDevices: (value) =>
    set((state) => ({ dataTrackingDevices: value })),
  //#endregion

  //#region hub device tag
  setHubDeviceSprite: (value) => set((state) => ({ hubDeviceSprite: value })),
  //#endregion
  setIsRealtimeTracking: (value) =>
    set((state) => ({ isRealtimeTracking: value })),
  setRealtimeDate: (value) => set((state) => ({ realtimeDate: value })),
  //#region //! payment month
  setPaymentMonthGridApi: (value) =>
    set((state) => ({ paymentMonthGridApi: value })),
  setPaymentMonthDate: (value) => set((state) => ({ paymentMonthDate: value })),
  setPaymentMonthData: (value) => set((state) => ({ paymentMonthData: value })),
  setNodeEditWorkerPaymentMonth: (value) =>
    set((state) => ({ nodeEditWorkerPaymentMonth: value })),
  setIsOpenEditWorkerPaymentMonth: (value) =>
    set((state) => ({ isOpenEditWorkerPaymentMonth: value })),
  //#endregion

  resetWorkerStore: () => set(initialState),
}));
