import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as PlusCircleIcon } from 'src/assets/icons/general/plus-circle.svg';
import PageHeader from 'src/components/atoms/PageHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { requestBimGrid } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import CompanyTable from './Company.table';
import ModalAddCompany from './modal/ModalAddCompany';
import ModalEditCompany from './modal/ModalEditCompany';

export default function LayoutCompany() {
  const navigate = useNavigate();
  const { organizationId, projectId } = useParams();
  const [data, setData] = useState([]);
  const { setIsLoading } = appStore();
  const [rowNode, setRowNode] = useState();
  const [gridApi, setGridApi] = useState();
  const [isModalAdd, setIsModalAdd] = useState(false);
  useEffect(() => {
    if (!organizationId) {
      navigate(-1);
      return;
    }
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid(
      'get',
      '/api/admin/company/getAllByOrganizationId',
      null,
      { organizationId, projectId }
    );
    if (data) {
      setData(data);
    }
    setIsLoading(false);
  };

  const handleAddNewCompany = async () => {
    setIsModalAdd(true);
  };

  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader>Organization Company</PageHeader>
          </>
        }
        right={
          <PrimaryButton onClick={handleAddNewCompany}>
            <PlusCircleIcon className='size-4' />
            Add
          </PrimaryButton>
        }
      />

      <CompanyTable
        data={data}
        setGridApi={setGridApi}
        setRowNode={setRowNode}
      />
      {isModalAdd && (
        <ModalAddCompany gridApi={gridApi} setClose={setIsModalAdd} />
      )}
      {rowNode && (
        <ModalEditCompany
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
