import React, { useEffect, useState } from 'react';
import { Result, Button, message, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { viewerStore } from '../../../../../store/Viewer.store';
import _ from 'lodash';
import { EyeOutlined, PushpinOutlined } from '@ant-design/icons';

const Autodesk = window.Autodesk;
export default function TreeViewList({ setLoading, loading }) {
  const navigate = useNavigate();
  const { viewer, setListModel, listModel } = viewerStore();

  const handleRemoveModel = (item) => {
    const models = viewer.impl.modelQueue().getModels();
    let index = _.findIndex(models, (v) => {
      return v.myData.loadOptions.fileId === item.fileId;
    });
    if (index >= 0) {
      if (!window.confirm('Are you want to remove?')) return;
      viewer.unloadModel(models[index]);
      let clone = [...listModel];
      let index1 = _.findIndex(clone, (v) => {
        return v.fileId === item.fileId;
      });
      clone.splice(index1, 1);
      setListModel(clone);
      viewer.unloadModel(clone);
    }
  };

  return (
    <div
      style={{ padding: 10, display: 'flex', gap: 2, flexDirection: 'column' }}
    >
      {listModel.map((i, k) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span
              style={{ fontWeight: 'bold', color: k === 0 ? 'blue' : 'black' }}
            >
              {i?.modelNameOverride}
            </span>
          </div>
          <div style={{ display: 'flex', gap: 2 }}>
            {k === 0 ? (
              <PushpinOutlined />
            ) : (
              <EyeOutlined onClick={handleRemoveModel.bind(this, i)} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
