import { useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../settings/General.settings';
import { appStore } from '../../../../../store/App.store';
import IconButton from '../../../../gen/button/IconButton.gen';
import DescriptionCellGroup from './cell/Description.cell.group';

export default function GroupPermissionTable({ data, setGridApi, setRowNode }) {
  const { setIsLoading } = appStore();
  const gridRef = useRef();
  const { organizationId, projectId } = useParams();

  const handleDeleteItem = async (e) => {
    console.log(e);
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    let repData = await requestBimGrid(
      'delete',
      '/api/admin/groupPermission',
      { groupPermissionId: e.node.allLeafChildren[0].data.id },
      { organizationId, projectId }
    );
    if (repData) {
      let temp = [];
      _.forEach(e.node.allLeafChildren, (v) => {
        temp.push(v.data);
      });
      await updateTableAsync(gridRef.current.api, { remove: temp });
      gridRef.current.api.redrawRows();
    }
    setIsLoading(false);
  };

  const handleEditItem = (e) => {
    setRowNode(e.node);
  };

  const handleRemovePermission = async (e) => {};
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      rowGroup: true,
      hide: true,
      rowHeight: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DescriptionCellGroup,
          };
        }
      },
    },
    // {
    //   headerName: 'Description',
    //   field: 'description',
    //   filter: 'agSetColumnFilter',
    //   rowGroup: true,
    //   hide: true,
    // },
    {
      headerName: 'Resource',
      field: 'resource',
      filter: 'agSetColumnFilter',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Permission Name',
      field: 'permissionName',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Permission Description',
      field: 'permissionDescription',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMenu: true,
      suppressSorting: true,
      suppressMovable: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        const level = params.node.level;
        if (isGroup) {
          if (level === 0) {
            return {
              component: handleActionGroupPermission,
            };
          }
        }
      },
    },
  ];
  const handleActionGroupPermission = (params) => {
    console.log(params.node);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          // type={'edit'}
          onClick={handleEditItem.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);
  // const getRowHeight = useCallback((params) => {
  //     // return 100px height for full width rows
  //     // if (params.node.group && params.node.level === 0) {
  //     //     return 70;
  //     // }
  // }, []);
  // const isFullWidthRow = useCallback((params) => {
  //     return isFullWidth(params.rowNode);
  // }, []);
  // const fullWidthCellRenderer = useMemo(() => {
  //     return DescriptionFullCellGroup;
  // }, []);
  // const isFullWidth = (node) => {
  //     if (node.group && node.level === 1)
  //         return true;
  // };
  const groupRowRendererParams = useMemo(() => {
    return {
      // innerRenderer: DescriptionCellGroup,
      suppressCount: true,
    };
  }, []);
  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          suppressPaginationPanel={true}
          groupDefaultExpanded={2}
          groupRowRendererParams={groupRowRendererParams}
          // getRowHeight={getRowHeight}
          rowHeight={30}
          headerHeight={35}
          // isFullWidthRow={isFullWidthRow}
          // fullWidthCellRenderer={fullWidthCellRenderer}
        ></AgGridReact>
      </div>
    </>
  );
}
