import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { requestBimGrid } from '../../../functions/General.function';
import { appStore } from '../../../store/App.store';
import { projectStore } from '../../../store/Docs.store';
import { viewerStore } from '../../../store/Viewer.store';
import IconButton from '../../gen/button/IconButton.gen';

export default function BreadcrumbNav() {
  const { project, fileName } = projectStore();
  const { organization, setOrganizations, organizations, locationPage } =
    appStore();
  const { viewer } = viewerStore();
  const navigate = useNavigate();
  const [isLocalLoading, setIsLocalLoading] = useState();
  const { organizationId, projectId } = useParams();
  const location = useLocation();
  const [projectName, setProjectName] = useState();
  useEffect(() => {
    if (project.name) {
      if (project.name.length > 10) {
        setProjectName(
          `${project.name.slice(0, 4)}...${project.name.slice(
            project.name.length - 4
          )}`
        );
      } else {
        setProjectName(project.name);
      }
    }
  }, [project.name]);
  useEffect(() => {
    run();
  }, []);
  const handleProjectSetting = (e) => {
    navigate(
      `organization/${organization.id}/project/${project.id}/management`
    );
  };
  const run = async () => {
    setIsLocalLoading(true);
    let data = await requestBimGrid(
      'get',
      '/api/organization/getAllByUserId',
      null,
      { organizationId, projectId }
    );
    if (data) {
      setOrganizations(data);
    }
    setIsLocalLoading(false);
  };

  const handleChangeOrganization = (e) => {
    if (location.pathname.includes('admin')) {
      navigate(`admin/organization/${e}/management`);
    } else {
      navigate(`organization/${e}`);
    }
  };
  const handleManagementOrganization = (e) => {
    navigate(`admin/organization/${organizationId}/management`);
  };
  return (
    !location.pathname.includes('/organizations') &&
    !location.pathname.includes('/super-admin') &&
    organization &&
    organizationId && (
      <Breadcrumb separator='>' className='idd-breadcrumb'>
        <Breadcrumb.Item>
          <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <IconButton
              icon={'setting'}
              size='ssmall'
              ghost={true}
              border={false}
              circle={true}
              onClick={handleManagementOrganization}
              active={locationPage === 'admin/organization'}
            />
            <Select
              showSearch
              bordered={false}
              size='small'
              style={{ width: 100 }}
              value={organization?.id}
              loading={isLocalLoading}
              onChange={handleChangeOrganization}
            >
              {organizations?.map((i) => (
                <Select.Option key={i.id} value={i.id}>
                  {i?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Breadcrumb.Item>

        <Breadcrumb.Item
          onClick={() => {
            navigate(`organization/${organization.id}`);
          }}
        >
          {' '}
          <HomeOutlined style={{ cursor: 'pointer' }} />
        </Breadcrumb.Item>
        {project.name && (
          <Breadcrumb.Item>
            <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <IconButton
                url='https://img.icons8.com/ios/20/null/settings--v1.png'
                size='ssmall'
                ghost={true}
                border={false}
                onClick={handleProjectSetting}
                circle={true}
                active={locationPage === 'project/management'}
              />
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(
                    `organization/${organization.id}/project/${project.id}/apps`
                  );
                }}
              >
                {projectName}
              </span>
            </div>
          </Breadcrumb.Item>
        )}
        {viewer && (
          <Breadcrumb.Item style={{ cursor: 'pointer' }}>
            {fileName}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    )
  );
}
