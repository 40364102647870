import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ModalSelectOrganization from 'src/components/layout/admin/organization/modal/ModalSelectOrganization';
import { requestBimGrid } from 'src/functions/General.function';
import { appStore } from 'src/store/App.store';

export default function OrganizationRoute() {
  const {
    setIsLoading,
    organization,
    setOrganization,
    isProcess,
    setIsProcess,
  } = appStore();

  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [isSelectModal, setIsSelectModal] = useState(
    organizationId ? false : true
  );

  useEffect(() => {
    if (organizationId) {
      localStorage.setItem('bimgrid-organization-id', organizationId);
      run();
    }
    return () => {
      localStorage.removeItem('bimgrid-organization-id');
    };
  }, [organizationId]);
  useEffect(() => {
    setIsSelectModal(organizationId ? false : true);
  }, [organizationId]);
  const run = async () => {
    try {
      setIsProcess(true);
      setIsLoading(true);
      const repData = await requestBimGrid(
        'get',
        '/api/organization/getByUserId',
        null
      );
      if (repData) {
        setOrganization(repData);
      }
      setIsLoading(false);
      setIsProcess(false);
    } catch {
      navigate('/');
      setIsLoading(false);
    }
  };
  return (
    <>
      {!isProcess && organization && (
        <>
          <Outlet />
        </>
      )}
      {isSelectModal && <ModalSelectOrganization setClose={setIsSelectModal} />}
    </>
  );
}
