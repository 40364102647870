import { message } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { useSearchParams } from 'react-router-dom';
import { dfmaStore } from '../DFMA.store';
import { viewerStore } from '../../../../../../../store/Viewer.store';
import { getAllModelsElementdbIdsWithCondition } from '../../../../../../../functions/Viewer.function';
import { toSnakeCase } from '../../../../../../../functions/General.function';
import { ViewerAPI } from '../../../../../../api';

export default function DataTabToolbar() {
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const {
    setDFMALoading,
    setIsOpenSetupParameter,
    parameters,
    setRowDataTab,
    setIsOpenAddRow
  } = dfmaStore();
  const { treeModelData } = viewerStore()

  useEffect(() => {
    console.log(treeModelData)
    run();
  }, []);
  const run = async () => {
    try {
      debugger;
    } catch (ex) {
      message.warning(ex.message);
    }
  };

  const handleImportFromModel = async () => {
    if (parameters.length === 0 || !parameters) {
      alert('You need setup parameter before import')
    }
    if (!window.confirm('Are you sure to import? This action will override all data')) return;
    setDFMALoading(true);
    try {
      let keys = {}
      let mapping = []
      let parameter = ''
      _.forEach(parameters, v => {
        if (v.value) {
          if (v.name === 'Name') {
            parameter = v.value
            if (v.value !== 'Name') {
              mapping.push(v.value)
            }
          } else {
            mapping.push(v.value)
          }
          keys[v.value] = toSnakeCase(v.name)
        }
      })
      if (parameter) {
        let data = []
        let nodes = treeModelData.getLeafNodesByParameterName(parameter)
        _.forEach(nodes, v => {
          let temp = {
            file_id: fileId,
            'name': '',
            'category': '',
            'length': 0,
            'height': 0,
            'width': 0,
            "volume": 0,
            'rebar_weight': 0,
            'casting_date': null,
            'delivery_date': null,
            'install_date': null,
          }
          _.forEach(v.properties, p => {
            if (mapping.includes(p.displayName)) {
              let tempValue = temp[keys[p.displayName]]
              if (_.isNumber(tempValue)) {
                let parse = _.toNumber(p.displayValue)
                if (!_.isNaN(parse))
                  temp[keys[p.displayName]] = parse
                else
                  temp[keys[p.displayName]] = 0
              } else {
                temp[keys[p.displayName]] = p.displayValue
              }

            }
          })
          if (parameter === 'Name') {
            temp['name'] = v.name
          }
          data.push(temp)
        })
        // console.log(data)
        let repImport = await ViewerAPI.DFMAAPI.Import({ data: data.slice(0, 5), fileId })
        setRowDataTab(repImport)
      }
    } catch (ex) {

      message.warning(ex.message);
    }
    setDFMALoading(false);
  };


  const handleAdd = () => {
    setIsOpenAddRow(true)
  };

  const handleSetup = () => {
    setIsOpenSetupParameter(true)
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 5,
          padding: 5,
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          <IconTextButton
            icon={'add'}
            type='regular'
            size='ssmall'
            onClick={handleAdd.bind(this)}
          >
            Add
          </IconTextButton>
          <IconTextButton
            icon={'add'}
            type='regular'
            size='ssmall'
            onClick={handleImportFromModel.bind(this)}
          >
            Import From Model
          </IconTextButton>

          {/* <IconTextButton
            icon={'reload'}
            type='regular'
            size='ssmall'
            onClick={handleReload.bind(this)}
          >
            Reload
          </IconTextButton> */}
        </div>
        <div style={{ display: 'flex', gap: 5 }}>
          <IconTextButton
            icon={'setting'}
            type='save'
            size='ssmall'
            onClick={handleSetup.bind(this)}
          >
            Setup
          </IconTextButton>
        </div>

      </div>
    </>
  );
}
