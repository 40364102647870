/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  message,
  Input,
  Upload,
  DatePicker as DatePickerDayjs,
  Divider,
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { AppAPI } from '../../../../../api';
import moment from 'moment';
import { camelToSnakeCase } from '../../../../../../functions/General.function';
import { useParams } from 'react-router-dom';

import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { generateRFAREVFromApi } from '../utils/RevUtils';
import { DMS_COLOR } from '../../dms/constant';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);

export default function ModalDMSRFAView() {
  const {
    setIsOpenRFAViewRev,
    selectedRFARevNode,
    selectedRFAViewConsultant,
    setSelectedRFAViewConsultant,
    dmsRFAGridApi,
  } = dmsStore();
  const { setIsLoading } = appStore();
  const [data, setData] = useState({
    requestedBy: null,
    submissionDate: null,
    dueDate: null,
  });
  const [allowReUpload, setAllowReUpload] = useState();
  const [comments, setComments] = useState([]);
  useEffect(() => {
    run();
  }, []);
  const [file, setFile] = useState();
  const [fileModel, setFileModel] = useState();
  const run = async () => {
    debugger;
    if (selectedRFARevNode.data[selectedRFAViewConsultant]) {
      setData({
        requestedBy: selectedRFARevNode.data?.requested_by,
        submissionDate: moment(selectedRFARevNode.data?.submission_date),
        dueDate: moment(selectedRFARevNode.data?.due_date).add(7, 'days'),
      });
    }
    let comments = [];
    _.forEach(selectedRFARevNode.data, (v, k) => {
      if (k.includes('consultant_')) {
        if (v.status === 'reject') {
          setAllowReUpload(true);
        }
        comments.push({
          name: v.companyName,
          comment: v.comment,
          status: v.status,
        });
      }
    });
    setComments(comments);
  };
  const close = () => {
    setSelectedRFAViewConsultant();
    setIsOpenRFAViewRev();
  };

  const handleOk = async () => {
    if (!allowReUpload) {
      return;
    }
    if (!file) {
      message.warning('Please input pdf file');
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      const tmp = {};
      _.forEach(data, (v, k) => {
        const name = camelToSnakeCase(k);
        tmp[name] = v;
      });
      formData.append('file', file);
      formData.append('fileModel', fileModel);
      formData.append(
        'data',
        JSON.stringify({
          ...tmp,
          dmsRevId: selectedRFARevNode.data.dms_rfa_rev_id,
        })
      );
      const revData = await AppAPI.DMSRFAAPI.CreateDMSRFARev(formData);
      debugger;
      if (selectedRFARevNode.parent.data.rev.toLowerCase() === 'all') {
        const { rows, parent } = generateRFAREVFromApi(
          [revData],
          selectedRFARevNode.parent.node.data.consultant_groups
        );
        await dmsRFAGridApi.api.applyServerSideTransactionAsync({
          route: selectedRFARevNode.parent.node.getRoute(),
          add: rows,
        });
        selectedRFARevNode.parent.node.updateData({
          ...selectedRFARevNode.parent.node.data,
          ...parent,
        });
      }
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  const beforeUpload = (file) => {
    const split = file.name.split('.');
    let ext = split[split.length - 1];
    if (!ext) {
      return false;
    }
    ext = ext.toLowerCase();
    return ext === 'pdf';
  };

  const customRequest = async (type, info) => {
    console.log(info.file.type);
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        if (type === 'file') {
          setFile(info.file);
        } else {
          setFileModel(info.file);
        }
      }
    }
  };
  const handleChange = (type, e) => {
    const clone = { ...data };
    clone[type] = e.target.value;
    setData(clone);
  };
  const handleDate = (type, e) => {
    const clone = { ...data };
    clone[type] = e;
    setData(clone);
  };
  const handleRemoveFile = (type) => {
    if (!window.confirm('Are you sure to delete?')) return;
    if (type === 'file') {
      setFile();
    } else {
      setFileModel();
    }
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'View Drawing Rev'}
        style={{ width: '800px' }}
        footer={allowReUpload ?? null}
      >
        <div
          style={{
            display: 'flex',
            gap: 15,
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 10,
              flexDirection: 'column',
              width: '100%',
              maxHeight: 500,
              overflow: 'auto',
            }}
          >
            {comments.map((v) => (
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    width: 135,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>{v.name}:</span>
                  <span
                    style={{
                      fontSize: 10,
                      color: DMS_COLOR[v.status].color,
                      backgroundColor: DMS_COLOR[v.status].bg,
                      padding: '1px 5px',
                    }}
                  >
                    {v.status?.toUpperCase()}
                  </span>
                </div>

                <Input.TextArea
                  placeholder='Please input'
                  style={{ width: '100%' }}
                  readOnly
                  value={v.comment}
                />
              </div>
            ))}
          </div>

          {allowReUpload && (
            <>
              <Divider style={{ borderColor: 'black' }}>Re-submit</Divider>
              <div
                style={{
                  display: 'flex',
                  gap: 10,
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <span style={{ fontWeight: 'bold', minWidth: 100 }}>
                    Date Submission:
                  </span>
                  <DatePicker
                    placeholder='Please input'
                    onChange={handleDate.bind(this, 'submissionDate')}
                    value={data.submissionDate}
                  />
                </div>

                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <span style={{ fontWeight: 'bold', minWidth: 100 }}>
                    Date Reply:
                  </span>
                  <DatePicker
                    placeholder='Please input'
                    onChange={handleDate.bind(this, 'dueDate')}
                    value={data.dueDate}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span style={{ fontWeight: 'bold', minWidth: 100 }}>
                  Requested by:
                </span>
                <Input
                  placeholder='Please input'
                  onChange={handleChange.bind(this, 'requestedBy')}
                  style={{ width: 400 }}
                  value={data.requestedBy}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span style={{ fontWeight: 'bold', minWidth: 100 }}>Rev:</span>
                <Input
                  placeholder='Please input'
                  style={{ width: '100%' }}
                  readOnly
                  disabled
                  value={selectedRFARevNode?.data?.rev * 1 + 1}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span style={{ fontWeight: 'bold', minWidth: 100 }}>
                  PDF file:
                </span>
                {!file ? (
                  <Upload
                    className='idd-upload-input'
                    beforeUpload={beforeUpload}
                    customRequest={customRequest.bind(this, 'file')}
                    accept='application/pdf'
                    showUploadList={false}
                  >
                    <IconTextButton
                      block={true}
                      size='ssmall'
                      type='save'
                      icon={'add_file'}
                      style={{ width: '100%' }}
                    >
                      Re-upload File
                    </IconTextButton>
                  </Upload>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap',
                      alignItems: 'center',
                      width: 'calc(100% - 100px)',
                    }}
                  >
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {file.name}
                    </span>
                    <IconButton
                      icon={'delete'}
                      type='delete'
                      size='ssmall'
                      onClick={handleRemoveFile.bind(this, 'file')}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span style={{ fontWeight: 'bold', minWidth: 100 }}>
                  Model file:
                </span>
                {!fileModel ? (
                  <Upload
                    className='idd-upload-input'
                    customRequest={customRequest.bind(this, 'model')}
                    showUploadList={false}
                  >
                    <IconTextButton
                      block={true}
                      size='ssmall'
                      type='save'
                      icon={'add_file'}
                      style={{ width: '100%' }}
                    >
                      Re-upload File
                    </IconTextButton>
                  </Upload>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap',
                      alignItems: 'center',
                      width: 'calc(100% - 100px)',
                    }}
                  >
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {fileModel.name}
                    </span>
                    <IconButton
                      icon={'delete'}
                      type='delete'
                      size='ssmall'
                      onClick={handleRemoveFile.bind(this, 'fileModel')}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </ModalTemplate>
    </>
  );
}
