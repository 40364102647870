import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { workerStore } from '../../../../../../store/Worker.store';
import { message, DatePicker as DatePickerDayjs, InputNumber } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);

export default function DateCellEdit(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [date, setDate] = useState('');
  const { payRateEditData, setPayRateEditData } = workerStore();
  useEffect(() => {
    if (cellValue) {
      setDate(moment(new Date(cellValue)).format('DD/MM/YYYY'));
    }
  }, [cellValue]);
  const handleChangeDate = (e) => {
    debugger;
    payRateEditData[props.column.colId] = moment(e._d);
    setPayRateEditData({ ...payRateEditData });
  };
  return (
    <div>
      {payRateEditData?.id !== props.node.data.id ? (
        date
      ) : (
        <DatePicker
          allowClear={false}
          value={payRateEditData[props.column.colId]}
          size='small'
          onChange={handleChangeDate}
          format='DD-MM-YYYY'
        />
      )}
    </div>
  );
}
