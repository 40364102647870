/* eslint-disable no-unused-vars */
import { message } from 'antd';
import { useRef } from 'react';
import { SuperAdminAddPermissionAPI } from 'src/api';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import LabelAndSelectForm from 'src/components/molecules/LabelAndSelectForm';
import LabelAndTextAreaForm from 'src/components/molecules/LabelAndTextAreaForm';
import Modal from 'src/components/molecules/Modal';
import { updateTableAsync } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';

export default function ModalAddPermission({ setClose, gridApi }) {
  const formRef = useRef();
  const { setIsLoading } = appStore();
  const close = () => {
    setClose(false);
  };

  const handleOk = async (e) => {
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};
      debugger;
      formData.forEach((value, key) => {
        data[key] = value;
      });

      const resData = await SuperAdminAddPermissionAPI({
        ...data,
      });
      if (resData) {
        await updateTableAsync(gridApi.api, { add: [resData] });
        gridApi.api.redrawRows();
        close();
      }
    } catch (ex) {
      if (ex.message) message.error(ex.message);
    }
    setIsLoading(false);
  };
  // 'Organization'
  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Create Permission'}>
        <div className='w-[500px] max-w-screen-md'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndSelectForm
              label='Permission Action'
              name='permission_action'
              placeholder='Select permission action'
              isRequired
              items={['Create', 'Update', 'View', 'Delete'].map((v) => (
                <option value={v}>{v}</option>
              ))}
            />
            <LabelAndSelectForm
              label='Permission Source'
              name='permission_source'
              placeholder='Select permission source'
              isRequired
              items={['Project'].map((v) => (
                <option value={v}>{v}</option>
              ))}
            />
            <LabelAndInputForm
              label='Group Name'
              name='group'
              isRequired
              placeholder='Enter group name'
            />

            <LabelAndInputForm
              label='Display Name'
              name='display_name'
              isRequired
              placeholder='Enter permission display name'
            />

            <LabelAndTextAreaForm
              label='Description'
              name='description'
              isRequired
              placeholder='Enter description'
            />
          </form>
        </div>
      </Modal>
    </>
  );
}
