/* eslint-disable no-unused-vars */
import { Button, DatePicker, Form, Input, message, Upload } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { logoWithName } from '../../../../constant/LinkImage';
import { toBase64 } from '../../../../functions/General.function';
import { adminStore } from '../../../../store/Admin.store';
import { appStore } from '../../../../store/App.store';
import { AppAPI } from '../../../api';
import ModalTemplate from '../../../gen/modal/Modal.gen';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default function ModalEditProject() {
  const [form] = Form.useForm();
  const {
    setIsEditProject,
    editProjectId,
    setEditProjectId,
    projects,
    setProjects,
  } = adminStore();
  const { setIsLoading } = appStore();
  const { organizationId } = useParams();
  const [file, setFile] = useState();
  const [file64, setFile64] = useState();
  const [address, setAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    postCode: '',
  });

  useEffect(() => {
    if (!editProjectId) {
      setIsEditProject(false);
    }
    run();
    return () => {
      setEditProjectId();
    };
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const projectData = await AppAPI.ProjectAPI.GetOne({
        projectId: editProjectId,
        organizationId,
      });
      if (projectData) {
        if (projectData.img_path) {
          const linkData = await AppAPI.S3API.GetPublicLink({
            key: projectData.img_path,
          });
          setFile64(linkData);
        }
        form.setFieldsValue({
          name: projectData.name,
          type: projectData.type,
          duration: [
            moment(projectData.duration[0]),
            moment(projectData.duration[1]),
          ],
        });
        setAddress({
          address1: projectData.address1,
          address2: projectData.address2,
          city: projectData.city,
          postCode: projectData.postCode,
        });
      }
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsEditProject(false);
  };

  const handleOk = async (e) => {
    form
      .validateFields()
      .then(async (values) => {
        let formData = new FormData();
        let clone = { ...values };
        clone.address = address;
        clone.organization_id = organizationId;
        clone.id = editProjectId;
        formData.append('data', JSON.stringify(clone));
        formData.append('file', file);
        setIsLoading(true);
        const projectData = await AppAPI.ProjectAPI.UpdateOne(formData, {
          organizationId,
        });
        debugger;
        if (projectData) {
          const index = _.findIndex(projects, (v) => v.id === projectData.id);

          if (index >= 0) {
            projects.splice(index, 1, projectData);
            setProjects([...projects]);
          }
          close();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.warning(err);
        setIsLoading(false);
      });
  };
  const handleChangeAddress = (i, ev) => {
    console.log('dd');
    let clone = { ...address };
    clone[i] = ev.target.value;
    setAddress(clone);
  };
  const handlePicture = async (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        setFile64(await toBase64(info.file));
        setFile(info.file);
      }
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error('Image must smaller than 4MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  return (
    <>
      <ModalTemplate onClose={close} onOk={handleOk} title={'Edit Project'}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout='vertical'
          className='idd-form'
          autoComplete='off'
        >
          <div className='column'>
            <div style={{ flexGrow: 1 }}>
              <Form.Item
                label='Project Name'
                rules={[{ required: true }]}
                name='name'
                className='block'
              >
                <Input placeholder='Project Name' name='name' />
              </Form.Item>
              <Form.Item
                label='Project Type'
                name='type'
                rules={[{ required: true }]}
              >
                <Input className='idd-input' placeholder='Project Type' />
              </Form.Item>

              <Form.Item
                label='Project Duration'
                name='duration'
                rules={[{ required: true }]}
              >
                <RangePicker className='idd-input' placeholder='Duration' />
              </Form.Item>
            </div>

            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 25,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <span> Project Image</span>
                <div style={{ display: 'flex', gap: 5 }}>
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      backgroundColor: 'lightgray',
                      border: '1px solid gray',
                      borderRadius: 5,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img src={file64 ?? logoWithName} width={100} alt='logo' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Select image to upload
                    </span>
                    <span style={{ color: 'gray' }}>JPG, GIF, PNG</span>
                    <span style={{ color: 'gray' }}>(4MB Max)</span>
                    <Upload
                      showUploadList={false}
                      customRequest={handlePicture}
                      beforeUpload={beforeUpload}
                      accept='image/*'
                    >
                      <Button type='primary'>Browser</Button>
                    </Upload>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <span> Project Address</span>
                <Input
                  className='idd-input'
                  placeholder='Address Line 1'
                  value={address.address1}
                  onChange={handleChangeAddress.bind(this, 'address1')}
                />
                <Input
                  className='idd-input'
                  placeholder='Address Line 1'
                  value={address.address2}
                  onChange={handleChangeAddress.bind(this, 'address2')}
                />
                <div style={{ display: 'flex', gap: 5 }}>
                  <Input
                    className='idd-input'
                    placeholder='City'
                    value={address.city}
                    onChange={handleChangeAddress.bind(this, 'city')}
                  />
                  <Input
                    className='idd-input'
                    placeholder='Postal Code'
                    value={address.postCode}
                    onChange={handleChangeAddress.bind(this, 'postCode')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </ModalTemplate>
    </>
  );
}
