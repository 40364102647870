const icons = {
  view: 'https://img.icons8.com/material-sharp/48/null/visible.png',
  edit: 'https://img.icons8.com/material-sharp/48/null/edit--v1.png',
  delete: 'https://img.icons8.com/sf-regular-filled/48/null/trash.png',
  translate: 'https://img.icons8.com/ios/42/null/back-sorting.png',
  upload: 'https://img.icons8.com/material-sharp/48/null/upload--v1.png',
  select:
    'https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/20/000000/external-selection-graph-design-kiranshastry-lineal-kiranshastry-1.png',
  save: 'https://img.icons8.com/ios/50/save--v1.png',
  issue:
    'https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/40/000000/external-alert-warehouse-xnimrodx-lineal-xnimrodx.png',
  excel: 'https://img.icons8.com/ios-filled/32/ms-excel.png',
  msproject: 'https://img.icons8.com/ios-filled/50/ms-project.png',
  p6: 'https://img.icons8.com/ios-filled/50/strategy-board.png',
  reload: 'https://img.icons8.com/ios-glyphs/30/refresh--v1.png',
  setting: 'https://img.icons8.com/ios/50/settings--v1.png',
  add: 'https://img.icons8.com/ios/50/plus-key.png',
  download: 'https://img.icons8.com/material-rounded/24/download--v1.png',
  copy: 'https://img.icons8.com/ios/50/copy.png',
  import: 'https://img.icons8.com/sf-regular/50/import.png',
  qr: 'https://img.icons8.com/wired/32/qr-code.png',
  detail: 'https://img.icons8.com/ios/50/view-file.png',
  print: 'https://img.icons8.com/ios/50/print--v1.png" alt="print--v1',
  back: 'https://img.icons8.com/ios-filled/50/left.png',
  folder: 'https://img.icons8.com/ios/50/folder-invoices--v1.png',
  zoom_meeting: 'https://img.icons8.com/ios-filled/50/zoom.png',
  close: 'https://img.icons8.com/ios-filled/50/delete-sign--v1.png',
  add_folder: 'https://img.icons8.com/ios-filled/50/add-folder--v1.png',
  add_file: 'https://img.icons8.com/ios-filled/50/add-file.png',
  upload_file: 'https://img.icons8.com/ios-filled/50/upload-document.png',
  warning: 'https://img.icons8.com/ios-filled/50/error--v1.png',
  share: 'https://img.icons8.com/ios-filled/50/share--v1.png',
  validate: 'https://img.icons8.com/ios-filled/50/submit-for-approval.png',
  submit: 'https://img.icons8.com/ios-filled/50/submit-document.png',
  file: 'https://img.icons8.com/ios-filled/50/file.png',
  three_d:
    'https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/external-3d-model-design-thinking-and-3d-model-printing-flatart-icons-solid-flatarticons.png',
  add_row: 'https://img.icons8.com/windows/32/add-row.png',
  add_column: 'https://img.icons8.com/windows/32/add-column.png',
  money: 'https://img.icons8.com/ios/32/coins--v1.png',
  check: 'https://img.icons8.com/ios-filled/32/checkmark--v1.png',
  vdc: ' https://img.icons8.com/ios/50/simulation.png',
  tracking: 'https://img.icons8.com/windows/32/order-on-the-way.png',
  hub_device: 'https://img.icons8.com/ios-filled/50/joystick.png',
  pointer: 'https://img.icons8.com/metro/50/hand-cursor.png',
  worker: 'https://img.icons8.com/sf-regular-filled/48/worker-male.png',
  camera: 'https://img.icons8.com/ios-filled/50/camera--v2.png',
  export: 'https://img.icons8.com/ios-filled/50/export.png',
  table: 'https://img.icons8.com/ios-filled/50/data-sheet.png',
  card: 'https://img.icons8.com/ios-filled/50/bank-card-back-side.png',
  checklist: 'https://img.icons8.com/ios/50/test-passed--v1.png',
  digicheck: 'https://img.icons8.com/parakeet-line/48/survey.png',
  vr: 'https://img.icons8.com/fluency-systems-filled/48/virtual-reality.png',
  connector: 'https://img.icons8.com/wired/64/connected--v1.png',
  send_mail: 'https://img.icons8.com/windows/32/send-mass-email.png',
  worker_tracking: 'https://img.icons8.com/matisse/100/worker-male.png',
  area: 'https://img.icons8.com/ios/50/map--v1.png',
  hole: 'https://img.icons8.com/ios-filled/50/hand-dug-well.png',
};

export default icons;
