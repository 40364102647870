/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Tabs, message } from 'antd';
import _ from 'lodash';
import { vrConnectorStore } from '../VRConnector.store';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import { useSearchParams, useParams } from 'react-router-dom';
import { viewerStore } from '../../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../../store/App.store';
import { AppAPI, ViewerAPI } from '../../../../../../api';
import { VDCVRAPI } from '../../../../../../api/app';
import { updateTableAsync } from '../../../../../../../functions/General.function';
export default function AddNewVRModelModal() {
  const [search] = useSearchParams();
  const { setIsAddNewVRModel, setVRConnectorLoading, gridApiVRModel } =
    vrConnectorStore();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { setIsLoading } = appStore();
  const { viewer } = viewerStore();
  const fileId = search.get('fileId');
  const versionId = search.get('versionId');
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      await ViewerAPI.WebhookAPI.CraeteWebhookModelDerivative();
    } catch (ex) {
      if (ex.message) {
        message.warning(ex.message);
      }
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsAddNewVRModel();
  };

  const handleOk = async (e) => {
    if (!window.confirm('Are you sure to submit?')) return;

    try {
      const dbIds = viewer.getSelection();
      if (dbIds.lenght === 0) {
        message.warning('Select at least one object');
        return;
      }
      let modelGuid;
      viewer.impl.model.myData.loadOptions.bubbleNode.data.children.forEach(
        (v) => {
          if (v.mime === 'application/autodesk-svf' && v.role === 'graphics') {
            modelGuid = v.guid;
          }
        }
      );
      if (!modelGuid) {
        return;
      }

      setVRConnectorLoading(true);
      const urn = viewer.impl.model.myData.urn;
      const resVRMode = await AppAPI.VDCVRAPI.CreateVRModel({
        modelGuid,
        dbIds,
        urn,
        fileId,
        versionId,
        name,
        description,
      });
      await updateTableAsync(gridApiVRModel.api, { add: [resVRMode] });
      close();
    } catch (ex) {
      message.warning(ex.message);
    }
    setVRConnectorLoading(false);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add'}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={true}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            gap: 10,
            height: '100%',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 10,
            }}
          >
            <div style={{ width: '100%', display: 'flex', gap: 5 }}>
              <span style={{ fontWeight: 'bold', width: 100 }}>Name:</span>
              <Input
                style={{ width: '100%' }}
                onChange={handleChange}
                value={name}
              />
            </div>

            <div style={{ width: '100%', display: 'flex', gap: 5 }}>
              <span style={{ fontWeight: 'bold', width: 100 }}>
                Description:
              </span>
              <Input.TextArea
                style={{ width: '100%' }}
                onChange={handleChangeDescription}
                value={description}
              />
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
