import { Upload, Button, DatePicker } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import Loader from '../../../../loader/LoaderApp';
import LevelFilterTree from './LevelFilter.tree';
import LevelFilterElement from './LevelFilter.element';

export default function LevelFilterFeature() {
  const [loading, setLoading] = useState();
  const ref = useRef();

  useEffect(() => {}, []);

  return (
    <>
      {loading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 11111111,
          }}
        >
          <Loader />
        </div>
      )}
      <div
        style={{
          width: '100%',
          height: 'calc(100% - 0px)',
          overflow: 'auto',
          padding: 10,
        }}
      >
        {/* <LevelFilterTree /> */}
        <LevelFilterElement />
      </div>
    </>
  );
}
