import React from 'react';
import { Tag } from 'antd';


export default function TagCell(props) {

  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;


  return (
    <Tag style={props.tagStyle} onClick={props?.onClick?.bind(this, props.node)} >
      {cellValue}
    </Tag>
  );
};