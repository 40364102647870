/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message,
    Input,
    Select
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AdminAPI } from '../../../../../api/app';
import { AppAPI } from '../../../../../api';



export default function ModalDMSSettingEditGroup() {
    const { setIsModalDMSSettingEditGroup, setConsultantGroup, consultantGroup,
        editConsultantGroupId } = dmsStore()
    const { setIsLoading, } = appStore()
    const [groupName, setGroupName] = useState()
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            const group = await AppAPI.DMSettingAPI.GetDMSSettingGroupOne({ id: editConsultantGroupId })
            setGroupName(group.name)

        } catch (ex) {
            message.warning(ex)
        }
        setIsLoading(false)
    }
    const close = () => {
        setIsModalDMSSettingEditGroup(false)
    }

    const handleOk = async (e) => {
        if (!groupName) {
            message.warning('Please input group name')
            return
        }
        setIsLoading(true)
        try {
            const dsmGroupData = await AppAPI.DMSettingAPI.UpdateDMSSettingGroup({ id: editConsultantGroupId, data: { name: groupName } })
            const index = consultantGroup.findIndex(v => { return v.id === editConsultantGroupId })
            if (index >= 0) {
                consultantGroup.splice(index, 1, dsmGroupData)
                setConsultantGroup([...consultantGroup])
            }

            setIsLoading(false)
            close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
        }
    }
    const handleChange = (e) => {
        setGroupName(e.target.value)
    }


    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Edit Group'}
                style={{ width: 300 }}
            >

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Group name:</span>
                    <Input placeholder='Please input' onChange={handleChange} value={groupName} />
                </div>


            </ModalTemplate>
        </>
    );
}

