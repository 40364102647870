import React from 'react';
import WorkTimeTable from '../table/WorkTime.table';
import WorkTimeToolbar from './WorkTime.toolbar';

export default function WorkTimeLayout() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 2,
        height: '100%',
        widthL: '100%',
        flexDirection: 'column',
      }}
    >
      <WorkTimeToolbar />
      <WorkTimeTable />
    </div>
  );
}
