import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { adminStore } from 'src/store/Admin.store';
import { appStore } from 'src/store/App.store';

export default function AdminRoutes() {
  const { setLocationPage } = appStore();
  const { resetAdminStore } = adminStore();

  useEffect(() => {
    resetAdminStore();
    setLocationPage('admin/organization');
    return () => {
      setLocationPage();
    };
  }, []);

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <Outlet />
      </div>
    </>
  );
}
