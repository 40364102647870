import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import AlertLogin from 'src/components/atoms/AlertLogin';
import AppLogo from 'src/components/atoms/AppLogo';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';

/**
 * @param {function(): void} onCLickReset
 * @param {boolean} isLoading
 * @param {any} error
 * @returns {React.Element} The rendered SignUpForm component.
 */
const SignUpForm = ({ onClickReset, error, isLoading }) => {
  const [localError, setLocalError] = useState(null);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const password = formData.get('password');
      const confirmPassword = formData.get('confirm-password');

      if (confirmPassword !== password) {
        setLocalError('Passwords do not match');
        return;
      }

      setLocalError(null);
      onClickReset({ password });
    },
    [onClickReset]
  );

  const combinedError = useMemo(() => {
    if (localError) return localError;
    if (error) return error;
    return null;
  }, [localError, error]);

  return (
    <section className='bg-gray-50 dark:bg-gray-900'>
      <div className='mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0'>
        <div className='mb-6'>
          <AppLogo />
        </div>
        <div className='w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0'>
          <div className='space-y-4 p-6 sm:p-8 md:space-y-6'>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl'>
              Reset password
            </h1>
            <p className='!mt-2 text-sm font-light text-gray-500 dark:text-gray-400'>
              {`Please enter your new password`}
            </p>
            {combinedError && <AlertLogin message={combinedError} />}
            <form className='space-y-4 md:space-y-6' onSubmit={handleSubmit}>
              <LabelAndInputForm
                label='Password'
                type='password'
                name='password'
                placeholder='••••••••'
                isRequired
              />
              <LabelAndInputForm
                label='Confirm password'
                type='password'
                name='confirm-password'
                placeholder='••••••••'
                isRequired
              />

              <PrimaryButton isFull disabled={isLoading} isLoading={isLoading}>
                Reset password
              </PrimaryButton>
              <p className='text-sm font-light text-gray-500 dark:text-gray-400'>
                Already have an account?{' '}
                <Link
                  to={'/'}
                  className='font-medium text-primary-600 hover:underline dark:text-primary-500'
                >
                  Login here
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpForm;
