import React from 'react';
import HubDevicesTable from '../table/HubDevices.table';
import HubDevicesToolbar from './HubDevices.toolbar';

export default function HubDevicesLayout() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 2,
        height: '100%',
        widthL: '100%',
        flexDirection: 'column',
      }}
    >
      <HubDevicesToolbar />
      <HubDevicesTable />
    </div>
  );
}
