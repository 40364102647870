/* eslint-disable no-unused-vars */
import { message } from 'antd';
import _ from 'lodash';
import { useRef } from 'react';
import { SuperAdminUpdatePermissionAPI } from 'src/api';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import LabelAndTextAreaForm from 'src/components/molecules/LabelAndTextAreaForm';
import Modal from 'src/components/molecules/Modal';
import { updateTableAsync } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';

export default function ModalEditPermission({ setRowNode, rowNode, gridApi }) {
  const formRef = useRef();
  const { setIsLoading } = appStore();
  const close = () => {
    setRowNode();
  };

  const handleOk = async (e) => {
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};
      formData.forEach((value, key) => {
        data[key] = value;
      });
      data.id = rowNode.data.id;
      const resData = await SuperAdminUpdatePermissionAPI({
        ...data,
      });
      if (resData) {
        _.forEach(rowNode.data, (v, k) => {
          if (data[k] !== undefined) {
            rowNode.data[k] = data[k];
          }
        });
        await updateTableAsync(gridApi.api, { update: [rowNode.data] });
        gridApi.api.redrawRows();
        close();
      }
    } catch (ex) {
      if (ex.message) message.error(ex.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Edit Permission'}>
        <div className='max-w-screen-sm'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndInputForm
              label='Group Name'
              name='group'
              isRequired
              placeholder='Enter group name'
              defaultValue={rowNode.data.group}
            />

            <LabelAndInputForm
              label='Display Name'
              name='display_name'
              isRequired
              placeholder='Enter permission display name'
              defaultValue={rowNode.data.display_name}
            />

            <LabelAndTextAreaForm
              label='Description'
              name='description'
              isRequired
              placeholder='Enter description'
              defaultValue={rowNode.data.description}
            />
          </form>
        </div>
      </Modal>
    </>
  );
}
