import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { appStore } from 'src/store/App.store';

export default function FiveDRoute() {
  const { setAppName } = appStore();
  useEffect(() => {
    setAppName('5d');
    return () => {
      setAppName(null);
    };
  }, []);

  return <Outlet />;
}
