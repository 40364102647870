import _ from 'lodash';
import moment from 'moment';
import create from 'zustand';

const initialState = {
  dfmaLoading: false,

  isOpenSetupParameter:false,
  isOpenAddRow:false,

  rowDataTab:[],
  currentRow:null,
  gridApiDataTabTable:null,

  parameters:[],
  progressTimeline:null,
  dataTimeline:{
    items: null,
    groups: null,
  },

  progressTimelineData:[],
  timelineCategory: 'Wall',
  timelineCurrentTime:moment()

};
export const dfmaStore = create((set) => ({
  ...initialState,

  setDFMALoading: (value) => set((state) => ({ dfmaLoading: value })),

  setIsOpenSetupParameter: (value) => set((state) => ({ isOpenSetupParameter: value })),
  setIsOpenAddRow: (value) => set((state) => ({ isOpenAddRow: value })),

  setCurrentRow: (value) => set((state) => ({ currentRow: value })),
  setRowDataTab: (value) => set((state) => ({ rowDataTab: value })),
  setGridApiDataTabTable: (value) => set((state) => ({ gridApiDataTabTable: value })),
  setParameters: (value) => set((state) => ({ parameters: value })),
  setProgressTimeline: (value) => set((state) => ({ progressTimeline: value })),
  setDataTimeline: (value) => set((state) => ({ dataTimeline: value })),
  setProgressTimelineData: (value) => set((state) => ({ progressTimelineData: value })),
  setTimelineCategory: (value) => set((state) => ({ timelineCategory: value })),
  setTimelineCurrentTime: (value) => set((state) => ({ timelineCurrentTime: value })),

  resetDFMAStore: () => set(initialState),
}));
