/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input, Select, DatePicker as DatePickerDayjs } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { workerStore } from '../../../../../../store/Worker.store';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { NATIONALITY, OCCUPATION } from '../../Worker.constant';
import {
  checkInput,
  updateTableAsync,
} from '../../../../../../functions/General.function';
import { AppAPI } from '../../../../../api';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalEditSuperviorList() {
  const {
    setIsOpenEditSuperviorList,
    superviorListGridApi,
    nodeEditSuperviorList,
  } = workerStore();
  const { setIsLoading } = appStore();
  const [users, setUsers] = useState([]);
  const { projectId } = useParams();
  const [data, setData] = useState([
    {
      name: 'Email',
      field: 'user_id',
      value: null,
      type: 'user',
    },
    {
      name: 'Report to',
      field: 'report_to',
      value: null,
      type: 'user',
      data: [],
    },
    {
      name: 'Designation',
      field: 'designation',
      value: null,
      type: 'input',
    },
    {
      name: 'Nationality',
      field: 'nationality',
      value: null,
      type: 'select',
      data: NATIONALITY,
    },
  ]);
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const users = await AppAPI.AdminAPI.GetAllUserInOrganization({
        isIncludes: true,
      });
      setUsers(users);

      _.forEach(nodeEditSuperviorList.data, (v, k) => {
        const index = _.findIndex(data, (i) => {
          return i.field === k;
        });
        if (index >= 0) {
          data[index].value = v;
        }
      });
      setData([...data]);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenEditSuperviorList(false);
  };

  const handleOk = async (e) => {
    if (!checkInput(data, ['user_id'])) {
      return;
    }
    setIsLoading(true);
    try {
      const clone = { ...nodeEditSuperviorList.data };
      data.forEach((v) => {
        clone[v.field] = v.value;
      });
      delete clone.email;
      delete clone.report_to_email;
      const resSupervior = await AppAPI.WorkerSettingAPI.UpdateOneSuperviorList(
        {
          id: clone.id,
          data: clone,
        }
      );
      await updateTableAsync(superviorListGridApi.api, {
        update: [resSupervior[0]],
      });
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };
  const handleChange = (type, e) => {
    type.value = e.target.value;
    setData([...data]);
  };
  const handleChangeDate = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const handleChangeSelect = (type, e) => {
    type.value = e;
    setData([...data]);
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Edit supervior'}
        style={{ width: 500 }}
      >
        {data.map((v) => (
          <>
            {v.type === 'input' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Input
                  placeholder='please, input'
                  onChange={handleChange.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'date' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <DatePicker
                  placeholder='please, input'
                  onChange={handleChangeDate.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'select' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Select
                  placeholder='please, input'
                  onChange={handleChangeSelect.bind(this, v)}
                  value={v.value}
                >
                  {v.data.map((v) => (
                    <Select.Option value={v}> {v} </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            {v.type === 'user' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Select
                  placeholder='please, input'
                  onChange={handleChangeSelect.bind(this, v)}
                  value={v.value}
                >
                  {users.map((v) => (
                    <Select.Option value={v.id}> {v.email} </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </>
        ))}
      </ModalTemplate>
    </>
  );
}
