import React, { useCallback, useRef } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  autoGroupColumnDef,
  defaultColDef,
  dateFilterParams,
} from '../../../../../../../settings/General.settings';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import { qrDrawingStore } from '../../qr_drawing/QRDrawing.store';
import QRCell from '../cell/QR.cell';
import DetailCell from '../cell/Detail.cell';
import DateCell from '../../../../../../ag_grid_components/cell/Date.cell';
import moment from 'moment';
import { updateTableAsync } from '../../../../../../../functions/General.function';
import { message } from 'antd';
import { ViewerAPI } from '../../../../../../api';

export default function QRDrawingTable({ }) {
  const { drawings, setGridApiMain, gridApiMain, setQRDrawingLoading } =
    qrDrawingStore();
  const gridRef = useRef();
  const columns = [
    {
      headerName: 'Description',
      field: 'name',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Creator',
      field: 'updated_by',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Date',
      field: 'updated_at',
      filter: 'agDateColumnFilter',
      maxWidth: 150,
      minWidth: 150,
      filterParams: dateFilterParams,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
            params: {},
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'QR',
      field: 'qr',
      suppressMenu: true,
      maxWidth: 55,
      minWidth: 55,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: QRCell,
            params: {},
          };
        }
        return undefined;
      },
      cellStyle: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      headerName: 'Detail',
      field: 'detail',
      suppressMenu: true,
      maxWidth: 75,
      minWidth: 75,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DetailCell,
            params: {},
          };
        }
        return undefined;
      },
      cellStyle: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 100,
      maxWidth: 100,
      suppressMenu: true,
      suppressSorting: true,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];

  const handleView = async (e) => { };

  const handleEdit = async (e) => { };
  const handleDelete = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setQRDrawingLoading(true);
    try {
      await ViewerAPI.QRDrawingAPI.DeleteAll({ ids: [e.data.id] });
      await updateTableAsync(gridApiMain.api, { remove: [e.data] });
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRDrawingLoading(false);
  };
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        {/* <IconButton
          icon='view'
          size='sssmall'
          type='save'
          onClick={handleView.bind(this, params)}
        />
        <IconButton
          icon='upload'
          size='sssmall'
          type='save'
          onClick={handleView.bind(this, params)}
        /> */}
        {/* <IconButton
          icon='edit'
          size='sssmall'
          type='edit'
          onClick={handleEdit.bind(this, params)}
        /> */}
        <IconButton
          icon='delete'
          size='sssmall'
          type='delete'
          onClick={handleDelete.bind(this, params)}
        />
        {/* <Button size='small' className={`prefab-custom-button ${(roleForAddEditModule[role] || user.isAdmin) ? 'delete' : 'disabled'}`} onClick={handleDeleteModule.bind(this, params.data)} type='danger'
          disabled={(roleForAddEditModule[role] || user.isAdmin)? false : false} ghost >Delete</Button> */}
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    setGridApiMain(params);
  }, []);
  return (
    <>
      <div style={{ width: '100%', height: '100%', padding: 5 }}>
        <div
          style={{ width: '100%', height: '100%' }}
          className='ag-theme-alpine'
        >
          <AgGridReact
            ref={gridRef}
            rowData={drawings}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows={true}
            onGridReady={onGridReady}
            groupDisplayType='multipleColumns'
            // rowGroupPanelShow='always'
            rowHeight={35}
            // pagination={true}
            // paginationPageSize={pageSizeModuleList}
            suppressPaginationPanel={true}
            context={false}
            suppressContextMenu={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
}
