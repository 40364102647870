import moment from 'moment';
import create from 'zustand';

const initialState = {
  isOpen3D: false,
  isOpenAddSheet: false,
  isOpenAddRow: false,
  isOpenAddColumn: false,
  isOpenEditRow: false,
  listSheet: [],
  currentEditSheet: null,
  selectedSheet: null,
  sheetGridApi: null,
  sheetColumns: [],
  sheetRows: [],
  selectedRow: null,
  threeDVieweData: null,
  workDoneDate: moment(new Date()),
};
export const fiveDStore = create((set) => ({
  ...initialState,
  setIsOpen3D: (value) => set((state) => ({ isOpen3D: value })),
  setIsOpenAddSheet: (value) => set((state) => ({ isOpenAddSheet: value })),
  setIsOpenAddRow: (value) => set((state) => ({ isOpenAddRow: value })),
  setIsOpenAddColumn: (value) => set((state) => ({ isOpenAddColumn: value })),
  setIsOpenEditRow: (value) => set((state) => ({ isOpenEditRow: value })),
  setListSheet: (value) => set((state) => ({ listSheet: value })),
  setCurrentEditSheet: (value) => set((state) => ({ currentEditSheet: value })),
  setSelectedSheet: (value) => set((state) => ({ selectedSheet: value })),
  setSheetGridApi: (value) => set((state) => ({ sheetGridApi: value })),
  setSheetColumns: (value) => set((state) => ({ sheetColumns: value })),
  setSheetRows: (value) => set((state) => ({ sheetRows: value })),
  setSelectedRow: (value) => set((state) => ({ selectedRow: value })),
  setThreeDVieweData: (value) => set((state) => ({ threeDVieweData: value })),
  setWorkDoneDate: (value) => set((state) => ({ workDoneDate: value })),
  resetFiveDStore: () => set(initialState),
}));
