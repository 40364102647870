import { Tooltip } from 'antd';
import { useRef } from 'react';

export default function ButtonGen({
  size = '',
  ghost,
  active,
  onClick = () => {},
  style = {},
  border = true,
  title = '',
  tooltipColor = '#2a425e',
  tooltipPlacement = 'top',
  loading,
  disabled = false,
  circle = false,
  type = 'default',
  isIconButton,
  children,
}) {
  const isGhost = ghost ? 'ghost' : '';
  const isBorder = border ? 'border' : '';
  const isLoading = loading ? 'loading' : '';
  const IsCircle = circle ? 'circle' : '';
  const ref = useRef(null);

  const handleClick = (e) => {
    if (loading) return;
    onClick(e);
  };
  return (
    <>
      {title !== '' ? (
        <Tooltip
          title={title}
          zIndex={10000000000}
          color={tooltipColor}
          placement={tooltipPlacement}
        >
          <div style={{ display: 'flex', gap: 5 }}>
            <button
              ref={ref}
              className={`${isIconButton ? 'idd-icon-button' : 'idd-icon-text-button'} ${type} ${isGhost} ${
                active ? 'active' : ''
              } ${isBorder} ${size} ${IsCircle}`}
              onClick={handleClick}
              style={style}
              disabled={disabled}
            >
              {children}
            </button>
          </div>
        </Tooltip>
      ) : (
        <div style={{ display: 'flex', gap: 5 }}>
          <button
            ref={ref}
            className={`${isIconButton ? 'idd-icon-button' : 'idd-icon-text-button'} ${type} ${isGhost} ${
              active ? 'active' : ''
            } ${isBorder} ${size} ${IsCircle}`}
            onClick={handleClick}
            style={style}
            disabled={disabled}
          >
            {children}
          </button>
        </div>
      )}
    </>
  );
}
