import { useNavigate, useParams } from 'react-router-dom';
import { appStore } from 'src/store/App.store';
import CardApp from '../../gen/card/CardApp.gen';
import icons from '../../gen/constant/Icon.constant';

const data = [
  {
    name: 'EDMS',
    logo: 'https://img.icons8.com/external-glyph-silhouettes-icons-papa-vector/90/external-Document-Management-digital-skills-glyph-silhouettes-icons-papa-vector.png',
    app: 'dms',
  },
  {
    name: 'BIM',
    logo: 'https://img.icons8.com/dotty/80/building-with-rooftop-terrace.png',
    app: '3d',
  },
  //   {
  //     name: '4D PMS',
  //     logo: logo_baned,
  //   },
  {
    name: '5D CMS',
    logo: 'https://img.icons8.com/ios/100/constructing.png',
    app: '5d',
  },
  {
    name: 'Worker',
    logo: 'https://img.icons8.com/ios/100/worker-male.png',
    app: 'worker',
  },
  {
    name: 'VDC',
    logo: icons.vdc,
    app: 'vdc',
  },
  {
    name: 'Digicheck',
    logo: icons.digicheck,
    app: 'digicheck',
  },
  //   {
  //     name: '6D AMS',
  //     logo: logo_baned,
  //   },
  //   {
  //     name: 'KPI',
  //     logo: logo_baned,
  //   },
  //   {
  //     name: 'VPC',
  //     logo: logo_baned,
  //   },
];
const data1 = [
  {
    name: 'BIM',
    logo: 'https://img.icons8.com/dotty/80/building-with-rooftop-terrace.png',
    app: '3d',
  },
];
export default function ProjectAppPage() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { user } = appStore();
  const handleViewItem = (app) => {
    navigate(`../${projectId}/${app}`);
  };

  return (
    <>
      <div className='idd-list'>
        {user.is_admin
          ? data.map((v, idx) => (
              <CardApp key={idx} {...v} handleViewItem={handleViewItem} />
            ))
          : data1.map((v, idx) => (
              <CardApp key={idx} {...v} handleViewItem={handleViewItem} />
            ))}
      </div>
    </>
  );
}
