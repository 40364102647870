import { Input, DatePicker, message } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import { useSearchParams } from 'react-router-dom';
import { qrDrawingStore } from './QRDrawing.store';
import { SearchOutlined } from '@ant-design/icons';
import { ViewerAPI } from '../../../../../api';
const { RangePicker } = DatePicker;
export default function QRDrawingToolbar() {
  const [search] = useSearchParams();
  const fileId = search.get('fileId');
  const {
    setQRDrawingLoading,
    setIsAddNewDrawing,
    setDrawings,
  } = qrDrawingStore();

  const handleOpenSetting = () => {};
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    try {
      debugger;
    } catch (ex) {
      message.warning(ex.message);
    }
  };
  const handleChange = async (e) => {
    setQRDrawingLoading(true);
    try {
    } catch (ex) {
      message.warning(ex.message);
    }
    setQRDrawingLoading(false);
  };
  const handleAdd = () => {
    setIsAddNewDrawing(true);
  };
  const handleReload = async () => {
    setQRDrawingLoading(true);
    try {
      let data = await ViewerAPI.QRDrawingAPI.GetAll({ fileId });
      setDrawings(data);
    } catch (ex) {
      message.warning();
    }
    setQRDrawingLoading(false);
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 5,
          padding: 5,
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          {/* <IconTextButton
            icon={'import'}
            type='regular'
            size='ssmall'
            onClick={handleOpenSetting.bind(this)}
          >
            Import
          </IconTextButton> */}
          <IconTextButton
            icon={'add'}
            type='regular'
            size='ssmall'
            onClick={handleAdd.bind(this)}
          >
            Add
          </IconTextButton>

          <IconTextButton
            icon={'reload'}
            type='regular'
            size='ssmall'
            onClick={handleReload.bind(this)}
          >
            Refresh
          </IconTextButton>
        </div>

        <div style={{ display: 'flex', gap: 5 }}>
          {/* <Select
            style={{ width: 150 }}
            // value={fileQRElementId}
            placeholder='Setting Template'
            onChange={handleChange}
            loading={qrDrawingLoading}
          >
             <Select.Option key={i.id} value={i.id}>
              {i.name}
            </Select.Option>
          </Select> */}
          {/* <RangePicker />
          <Input
            placeholder='Enter keywords'
            addonBefore={<SearchOutlined />}
            style={{ maxWidth: 200 }}
          /> */}
        </div>
      </div>
    </>
  );
}
