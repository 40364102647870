import { requestBimGrid } from '../../../functions/General.function';

export const GetAllByUserIdAndOrganizationId = (params = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/project/getAllByUserIdAndOrganizationId', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex.message);
        }
    });
};

export const GetOne = (params = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('get', '/api/project', null, params)
            resolve(data);
        } catch (ex) {
            reject(ex.message);
        }
    });
};

export const UpdateOne = (body, params = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('put', '/api/project', body, params)
            resolve(data);
        } catch (ex) {
            reject(ex.message);
        }
    });
};

export const CreateOne = (body, params = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await requestBimGrid('post', '/api/project', body, params)
            resolve(data);
        } catch (ex) {
            reject(ex.message);
        }
    });
};
