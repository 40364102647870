import React, { } from "react";
import IconButton from "../../../../gen/button/IconButton.gen";
import { dmsStore } from "../../../../../store/DMS.store";
import IconTextButton from "../../../../gen/button/IconTextButton.gen";



export default function DMSSettingMemberToolbar() {
  const { setIsModalDMSSettingAddMemberGroup, selectedConsultantGroupId } = dmsStore()
  const handleAddMember = () => {
    setIsModalDMSSettingAddMemberGroup(true)
  }


  return (
    <div style={{ padding: '5px 2px', width: 'fit-content' }}>
      <IconTextButton
        icon={'add'}
        size="ssmall"
        onClick={handleAddMember}
        type='save'
        disabled={selectedConsultantGroupId === null}
      >Add Member</IconTextButton>
    </div>

  );
}