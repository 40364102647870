import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { updateTableAsync } from '../../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import DateCell from '../../../../../ag_grid_components/cell/Date.cell';
import { AppAPI } from '../../../../../api';
import IconButton from '../../../../../gen/button/IconButton.gen';
import StatusCell from '../cell/Status.cell';

export default function WorkerListTable() {
  const { setIsLoading } = appStore();
  const {
    setWorkerListGridApi,
    workerListGridApi,
    setIsOpenEditWokerList,
    setNodeEditWorkerList,
    setDataWorkerList,
    dataWorkerList,
    setIsOpenMoneyWokerList,
  } = workerStore();
  const gridRef = useRef();
  const { projectId } = useParams();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const workerListRes =
        await AppAPI.WorkerSettingAPI.GetAllWorkerListByProject({
          project_id: projectId,
        });
      setDataWorkerList(workerListRes);
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      await AppAPI.WorkerSettingAPI.DeleteWorkerList({
        id: e.node.data.id,
      });
      await updateTableAsync(workerListGridApi.api, { remove: [e.node.data] });
      debugger;
    } catch (ex) {}
    setIsLoading(false);
  };
  const handleEditItem = async (e) => {
    setNodeEditWorkerList(e.node);
    setIsOpenEditWokerList(true);
  };
  const handleMoneyItem = async (e) => {
    setNodeEditWorkerList(e.node);
    setIsOpenMoneyWokerList(true);
  };
  const columns = [
    {
      headerName: 'Employee code',
      field: 'employee_code',
      filter: 'agSetColumnFilter',
      minWidth: 200,
    },
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Company',
      field: 'company',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Occupation',
      field: 'occupation',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Designation',
      field: 'designation',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Nationality',
      field: 'nationality',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Transfer In Date',
      field: 'transer_in_date',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: DateCell,
        };
      },
    },
    {
      headerName: 'Transfer out Date',
      field: 'transer_out_date',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: DateCell,
        };
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: StatusCell,
        };
      },
    },
    {
      headerName: 'Tracking Device',
      field: 'tracking_device_name',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      // cellRendererSelector: (params) => {
      //   return {
      //     component: StatusCell,
      //   };
      // },
    },
    // {
    //   headerName: 'Payment',
    //   field: 'Curee',
    //   filter: 'agSetColumnFilter',
    //   width: 100,
    //   minWidth: 100,
    //   children: [
    //     {
    //       headerName: 'Last Month',
    //       field: 'payment_last_month',
    //       filter: 'agSetColumnFilter',
    //       width: 100,
    //       minWidth: 100,
    //       // cellRendererSelector: (params) => {
    //       //   return {
    //       //     component: StatusCell,
    //       //   };
    //       // },
    //     },
    //     {
    //       headerName: 'Current Month',
    //       field: 'payment_current_month',
    //       filter: 'agSetColumnFilter',
    //       width: 100,
    //       minWidth: 100,
    //       // cellRendererSelector: (params) => {
    //       //   return {
    //       //     component: StatusCell,
    //       //   };
    //       // },
    //     },
    //     {
    //       headerName: 'Update at',
    //       field: 'payment_update_at',
    //       filter: 'agSetColumnFilter',
    //       width: 100,
    //       minWidth: 100,
    //       cellRendererSelector: (params) => {
    //         return {
    //           component: PaymentUpdateDateCellEdit,
    //         };
    //       },
    //     },
    //   ],
    // },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'edit'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleEditItem.bind(this, params)}
        />
        <IconButton
          icon={'money'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleMoneyItem.bind(this, params)}
        />
        <IconButton
          icon={'delete'}
          size='sssmall'
          type={'delete'}
          onClick={handleDeleteItem.bind(this, params)}
        />
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setWorkerListGridApi(params);
  }, []);

  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={dataWorkerList}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
