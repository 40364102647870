import React, { useEffect } from "react";
import _ from "lodash";
import Loader from "../../../../loader/LoaderApp";
import COBie from "./COBie";
import { cobieStore } from "./COBie.store";



export default function COBieFeature(props) {
    const { cobieLoading } = cobieStore()
    useEffect(() => {

    }, [])


    return (
        <>
            {cobieLoading && <div style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 11111111 }} >
                <Loader />
            </div>}
            <>
                <COBie
                />
            </>
        </>
    );
}


