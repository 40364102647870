import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import { AppAPI } from '../../../../../api';
import ActionBlockZoneCell from '../cell/ActionBlockZone.cell';

export default function BlockZoneTable() {
  const { setIsLoading } = appStore();
  const { setBlockZoneGridApi, setDataBlockZone, dataBlockZone } =
    workerStore();
  const gridRef = useRef();
  const { projectId } = useParams();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const blockZoneRes =
        await AppAPI.WorkerSettingAPI.GetAllBlockZoneByProject({
          project_id: projectId,
        });
      setDataBlockZone(blockZoneRes);
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      headerName: 'Block',
      field: 'block',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Level',
      field: 'level',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Zone',
      field: 'zone',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: ActionBlockZoneCell,
          };
        }
        return undefined;
      },
    },
  ];

  const onGridReady = useCallback((params) => {
    setBlockZoneGridApi(params);
  }, []);

  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={dataBlockZone}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
          groupDefaultExpanded={2}
        ></AgGridReact>
      </div>
    </>
  );
}
