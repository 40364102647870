import { Route } from 'react-router-dom';
import { DMSRoute } from 'src/routes/apps';
import DMSPage from '../components/pages/apps/two_d/dms/DMS.Page';
import DMSDocPage from '../components/pages/apps/two_d/dms/DMSDoc.Page';
import DMSRFAPage from '../components/pages/apps/two_d/dms/DMSRFA.Page';
import DMSSettingPage from '../components/pages/apps/two_d/dms/DMSSetting.Page';
import DMSDocSharePage from '../components/pages/apps/two_d/dms/share/DMSDocShare.Page';
import DMSViewerPage from '../components/pages/apps/two_d/dms/share/DMSViewer.Page';

export const DMSAppRoutes = [
  <Route path=':projectId' key='projectId'>
    <Route path='dms' element={<DMSRoute />} key='dmsRoute'>
      <Route path='main' element={<DMSPage />} key='dmsMain' />
      <Route path='doc' element={<DMSDocPage />} key='dmsDoc' />
      <Route path='rfa' element={<DMSRFAPage />} key='dmsRfa' />
      <Route path='viewer' element={<DMSViewerPage />} key='dmsViewer' />
      <Route path='setting' element={<DMSSettingPage />} key='dmsSetting' />
    </Route>
  </Route>,
];

export const DMSShareAppRoutes = [
  <Route path='dms' key='dmsShare'>
    <Route path='doc' element={<DMSDocSharePage />} key='dmsDocShare' />
  </Route>,
];
