export const TimelineComponentType = {
  Tasks: 'Tasks',
  DataSource: 'Data Source',
  Configuration: 'Configuration',
  Simulation: 'Simulation',
};

export const CascadeAction = {
  indent: true,
  outdent: true,
  del: true,
};

export const SingularAction = {
  undo: true,
  redo: true,
};
export const DisplayTable = [
  ['text', 'start_date', 'end_date', 'attached', 'task_type', 'add'],
  ['actual_start', 'actual_end', 'end_date', 'attached'],
  ['material_cost', 'labor_cost', 'equipment_cost', 'total_cost'],
];
