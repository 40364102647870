import 'flowbite';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import 'rc-dock/dist/rc-dock.css';
import 'react-contexify/ReactContexify.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'vis-timeline/styles/vis-timeline-graph2d.css';
import App from './App';
import { initMicrosoft } from './functions/Auth.function';
import './scss/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

initMicrosoft();

root.render(
  <ConfigProvider
    button={{ className: 'idd-custom-antd idd-custom-button' }}
    tree={{ className: 'idd-custom-antd' }}
    theme={{
      token: {
        colorPrimary: '#2b435f',
      },
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConfigProvider>
);
