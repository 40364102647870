import React from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/icons/general/close.svg';
import BackDrop from 'src/components/atoms/BackDrop';
import IconButton from 'src/components/atoms/IconButton';

/**
 * @param {React.Element} children
 * @param {boolean} closeable
 * @param {function} onClickIcon
 * @returns {React.Element} The rendered input element.
 */
const NotificationModal = ({ children, onClickIcon, closeable = false }) => {
  return (
    <div className='fixed left-0 right-0 top-0 z-50 flex h-full max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0'>
      <BackDrop>
        <div className='relative max-h-full w-full max-w-md p-4'>
          <div className='relative rounded-lg bg-white p-5 shadow dark:bg-gray-700'>
            {closeable && (
              <IconButton
                onClick={onClickIcon}
                className='absolute right-4 rounded-full bg-transparent hover:!bg-transparent'
              >
                {' '}
                <CloseIcon className='w-3 text-gray-600 dark:text-white' />
                <span className='sr-only'>Close modal</span>
              </IconButton>
            )}

            <div className='p-4 text-center md:p-5'>{children}</div>
          </div>
        </div>
      </BackDrop>
    </div>
  );
};

export default NotificationModal;
