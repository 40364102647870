import React from 'react';
import _ from 'lodash';
import { timelineStore } from './Timeline.store';
import { TimelineComponentType } from './constants/TimelineComponentType';
import TimelineConfigurationToolbar from './components/configuration/TimelineConfiguration.toolbar';
import TimelineConfigurationTable from './components/configuration/TimelineConfiguration.table';

export default function TimelineConfiguration({}) {
  const { segmentKey } = timelineStore();
  return (
    <>
      <div
        style={{
          display: segmentKey === TimelineComponentType.Configuration ? 'flex' : 'none',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <TimelineConfigurationToolbar/>
        <TimelineConfigurationTable />
      </div>
    </>
  );
}
