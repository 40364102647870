import React, { useEffect } from 'react';
import _ from 'lodash';
import { dfmaStore } from '../DFMA.store';
import DataTabTable from '../table/DataTab.table';
import DataTabToolbar from './DataTabToolbar';
import SetupParameterModal from '../modal/SetupParameterModal';
import AddRowModal from '../modal/AddRowModal';
import EditRowModal from '../modal/EditRowModal';
export default function DataTab() {
    const {
        isOpenSetupParameter,
        isOpenAddRow,
        currentRow
    } = dfmaStore();


    return (
        <>
            <div style={{ height: 'calc(100vh - 260px)', display: 'flex', flexDirection: 'column' }} >
                <DataTabToolbar />
                <DataTabTable />
            </div>
            {isOpenSetupParameter && <SetupParameterModal />}
            {isOpenAddRow && <AddRowModal />}
            {currentRow && <EditRowModal />}
        </>

    );
}
