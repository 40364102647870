import React, { useEffect, useState } from "react";
import { Result, Button, message, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import DrawerViewer from "../../../../gen/drawer/Drawer.gen";
import { viewerStore } from "../../../../../store/Viewer.store";
import _ from "lodash";
import ParameterSelector from "./ParameterSelector";
import TableQuantity from "./table/ExtractQuantity.table";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
const { Option } = Select;
export default function ExtractQuantityGanttFeature(props) {
    const navigate = useNavigate()
    const { viewer } = viewerStore()
    const [data, setData] = useState([])
    const [isOpen, setIsOpen] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    return (
        <>
            <div style={{display:isOpen?'block':'none'}} >
                <ParameterSelector  setIsOpen={setIsOpen} setDataTable={setDataTable} setColumnData ={setColumnData}/>
            </div>
            <div>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' , backgroundColor:'black'  }} onClick={setIsOpen.bind(this, !isOpen)} >
                {isOpen ? <CaretUpFilled style={{ color: 'white' }} /> : <CaretDownFilled style={{ color: 'white' }} />}
                </div>
            </div>
            <div style={{ height: '100%' }}>
                <TableQuantity dataTable={dataTable} columnData={columnData} />

            </div>
        </>
    );
}


