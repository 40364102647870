import React, { } from 'react';
import _ from 'lodash';
import { Menu } from 'antd';
import { } from 'antd/lib/tree-select';
import { FolderAddOutlined, DeleteOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';


export default function TreeFolder({ item }) {
    const navigate = useNavigate()
    const { organizationId } = useParams()
    const [search] = useSearchParams()
    const folderId = search.get('folderId')
    const isMain = folderId === item?.key
    const handleAddFolder = (e) => {

    }
    console.log(item)
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }} >
            <span style={{ fontWeight: isMain ? 'bold' : 'normal' , color: isMain ? 'blue' : 'black'}}>{item.name}</span>
            <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                {/* <FolderAddOutlined onClick={handleAddFolder} />
                <EditOutlined onClick={editFolder} />
                <DeleteOutlined style={{ color: 'red' }} onClick={handleAddFolder} />
                <MoreOutlined /> */}
            </div>
        </div>
    )
}
