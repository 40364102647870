import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { viewerStore } from '../../../../../store/Viewer.store';
import _ from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import IssueList from './Issue.list';
import IssueAdd from './Issue.add';
import IssueDetail from './Issue.detail';
import Loader from '../../../../loader/LoaderApp';
import CoordinationPoint from '../../util/Coordination.util';
import { issueStore } from './Issue.store';
import { IssueComponentType } from './constants/IssueComponentType';
import IssueToolbar from './Issue,toolbar';
import { requestBimGrid } from '../../../../../functions/General.function';
import IssueTable from './table/Issue.table';

let coordinationPoint = null;
export default function IssueFeature() {
  const { viewer } = viewerStore();
  const {
    type,
    issueDetail,
    resetIssueStore,
    issueLoading,
    setIssueLoading,
    setType,
    setIssueData,
    layoutType,
  } = issueStore();
  const [search] = useSearchParams();
  const [isCompleted, setIsCompleted] = useState();
  const fileId = search.get('fileId');
  useEffect(() => {
    coordinationPoint = new CoordinationPoint(viewer, 'coordination');
    coordinationPoint.active();
    setIsCompleted(true);
    run();
    return () => {
      coordinationPoint?.dispose();
      coordinationPoint = null;
      resetIssueStore();
    };
  }, []);

  const run = async () => {
    setIssueLoading(true);
    let data = await requestBimGrid('get', '/api/issue/getByFileId', null, {
      fileId,
    });
    if (data) {
      setIssueData(data);
      let temp = [];
      data.forEach((v) => {
        if (v.status === 'Open') {
          v.colorName = v.category;
          temp.push(v);
        }
      });
      coordinationPoint.setMarkupData(temp);
    }
    setIssueLoading(false);
  };

  return (
    <>
      {issueLoading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 11111111,
          }}
        >
          <Loader />
        </div>
      )}
      {isCompleted && (
        <>
          <div
            style={{
              display: type === 'list' ? 'block' : 'none',
              height: '100%',
            }}
          >
            <IssueToolbar />
            <div
              style={{
                height: 'calc(100% - 50px)',
                overflow: 'auto',
                width: '100%',
                position: 'relative',
              }}
            >
              {layoutType === 'table' ? <IssueTable /> : <IssueList />}
            </div>
          </div>

          {type === IssueComponentType.Add && (
            <IssueAdd
              setType={setType}
              setLoading={setIssueLoading}
              loading={issueLoading}
              coordinationPoint={coordinationPoint}
            />
          )}
          {type === IssueComponentType.Detail && issueDetail && (
            <IssueDetail coordinationPoint={coordinationPoint} />
          )}
        </>
      )}
    </>
  );
}
