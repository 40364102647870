/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import _ from 'lodash';
import { vrConnectorStore } from '../VRConnector.store';
import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import { useSearchParams } from 'react-router-dom';
import { appStore } from '../../../../../../../store/App.store';
import { AppAPI } from '../../../../../../api';
import { updateTableAsync } from '../../../../../../../functions/General.function';
export default function EditVRModelModal() {
  const [search] = useSearchParams();
  const {
    setIsOpenEditVRModel,
    setVRConnectorLoading,
    gridApiVRModel,
    nodeEditVRModel,
  } = vrConnectorStore();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { setIsLoading } = appStore();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);

    try {
      setDescription(nodeEditVRModel.data.description);
      setName(nodeEditVRModel.data.name);
    } catch (ex) {
      if (ex.message) {
        message.warning(ex.message);
      }
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenEditVRModel();
  };

  const handleOk = async (e) => {
    if (!window.confirm('Are you sure to edit?')) return;
    try {
      debugger;
      const resVRMode = await AppAPI.VDCVRAPI.UpdateOneVRModel({
        id: nodeEditVRModel.data.id,
        name,
        description,
      });
      _.forEach(nodeEditVRModel.data, (v, k) => {
        if (resVRMode[k]) {
          nodeEditVRModel.data[k] = resVRMode[k];
        }
      });
      await updateTableAsync(gridApiVRModel.api, {
        update: [nodeEditVRModel.data],
      });
      close();
    } catch (ex) {
      message.warning(ex.message);
    }
    setVRConnectorLoading(false);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add'}
        styleBackDrop={{ position: 'absolute' }}
        style={{ top: 'auto', width: '90%' }}
        isFooter={true}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            gap: 10,
            height: '100%',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 10,
            }}
          >
            <div style={{ width: '100%', display: 'flex', gap: 5 }}>
              <span style={{ fontWeight: 'bold', width: 100 }}>Name:</span>
              <Input
                style={{ width: '100%' }}
                onChange={handleChange}
                value={name}
              />
            </div>

            <div style={{ width: '100%', display: 'flex', gap: 5 }}>
              <span style={{ fontWeight: 'bold', width: 100 }}>
                Description:
              </span>
              <Input.TextArea
                style={{ width: '100%' }}
                onChange={handleChangeDescription}
                value={description}
              />
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
