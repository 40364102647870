import { useState } from 'react';
import { ForgotPasswordAPI } from 'src/api/auth';
import FullSceenLoader from 'src/components/molecules/FullSceenLoader';
import ForgotPasswordForm from 'src/components/organisms/ForgotPasswordForm';
import ForgotPasswordModal from 'src/components/organisms/ForgotPasswordModal';
const ForgotPasswordPage = () => {
  const [error, setError] = useState();
  const [loading, setIsLoading] = useState();
  const [isSuccess, setIsSucess] = useState();
  const onClickLogin = async (resetPasswordInput) => {
    setIsLoading(true);
    try {
      const data = await ForgotPasswordAPI({
        email: resetPasswordInput.email,
        host: window.location.origin,
      });
      if (data) {
        setIsSucess(true);
      }
    } catch (ex) {
      setError(ex.message ?? ex ?? 'Error');
    }
    setIsLoading(false);
  };
  return (
    <>
      <ForgotPasswordForm
        onClickReset={onClickLogin}
        isLoading={loading}
        error={error}
      />
      {loading && <FullSceenLoader />}
      {isSuccess && <ForgotPasswordModal />}
    </>
  );
};

export default ForgotPasswordPage;
