import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoCircleIcon } from 'src/assets/icons/general/info-circle.svg';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import NotificationModal from 'src/components/molecules/NotificationModal';

/**
 * @param {string} message
 * @returns {React.Element} The rendered input element.
 */
const ResetPasswordModal = ({ message }) => {
  const navigate = useNavigate();
  const handleCLick = useCallback(() => {
    navigate('/');
  }, []);
  return (
    <NotificationModal>
      <div className='pad flex items-center justify-center text-primary-400 dark:text-primary-800'>
        {' '}
        <InfoCircleIcon className='size-10 text-red-600 dark:text-red-800' />
      </div>

      <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
        {message}
      </h3>
      <div className='flex items-center justify-center'>
        <PrimaryButton className='px-9' onClick={handleCLick}>
          {' '}
          OK
        </PrimaryButton>
      </div>
    </NotificationModal>
  );
};

export default ResetPasswordModal;
