import React, { } from 'react';


import _ from 'lodash';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
// import ButtonNav from '../../components/Button.nav';

import ButtonNav from '../../Button.nav';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';


const { Text } = Typography
const { ethereum } = window
export default function Tool5D({ handleChangeFeature }) {
    const navigate = useNavigate()
    const location = useLocation();
    const { user, resetAppStore } = appStore()
    const { setIsOpenTopNav, setFeature, feature, docker, viewerLoading } = viewerStore()





    return (
        <div style={{ display: 'flex', gap: 5 }} >
            <ButtonNav
                url={'https://img.icons8.com/external-photo3ideastudio-lineal-photo3ideastudio/40/000000/external-planning-travel-checklist-photo3ideastudio-lineal-photo3ideastudio.png'}
                name='Takeoff'
                active={feature.includes('5d_takeoff')}
                onClick={handleChangeFeature.bind(this, '5d_takeoff', docker, feature, setFeature)}
                disabled={viewerLoading}
            />
            <ButtonNav
                url={'https://img.icons8.com/dotty/80/approximately-equal.png'}
                name='5D'
                active={feature.includes('5d_gantt')}
                onClick={handleChangeFeature.bind(this, '5d_gantt', docker, feature, setFeature)}
                disabled={viewerLoading}
            />



        </div>

    );
}
