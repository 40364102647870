import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
import { message } from 'antd';
import useCss from '../../hooks/useCss';
import useScript from '../../hooks/useScript';

let Autodesk = window.Autodesk;
// var viewer;

export function ViewerForgePDF({ filePath, fileExt, setViewer }) {
  useCss(
    'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css'
  );
  const status = useScript(
    'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js'
  );

  useEffect(() => {
    if (status === 'ready') {
      Autodesk = window.Autodesk;
      if (!fileExt) {
        message.warning('You need to provide file extension');
        return;
      }
      if (
        fileExt.toLowerCase() === 'pdf' ||
        fileExt.toLowerCase() === 'dwf' ||
        fileExt.toLowerCase() === 'dwfx'
      ) {
        window.Autodesk.Viewing.Initializer({ env: 'Local' }, async () => {
          const viewer = new window.Autodesk.Viewing.GuiViewer3D(
            document.getElementById('forgeViewerPDF')
          );
          viewer.start();
          viewer.loadExtension('Autodesk.DocumentBrowser');
          viewer.loadExtension('Autodesk.Viewing.MarkupsCore');
          viewer.loadExtension('Autodesk.Viewing.MarkupsGui');
          viewer.addEventListener(
            window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
            checkLoaded
          );
          const isDWF =
            fileExt.toLowerCase() === 'pdf' ? 'Autodesk.PDF' : 'Autodesk.DWF';
          viewer.loadExtension(isDWF).then(async (e) => {
            if (isDWF === 'Autodesk.DWF') {
              var xhr = new XMLHttpRequest();
              xhr.open('GET', filePath, true);
              xhr.responseType = 'blob';
              xhr.onload = async function (e) {
                if (this.status == 200) {
                  var myBlob = this.response;
                  const url1 = window.URL.createObjectURL(myBlob);
                  viewer.loadModel(url1 + '#.dwf', {}, (e) => {
                    try {
                      viewer.setReverseZoomDirection(true);
                      let view3d = e
                        .getDocumentNode()
                        .getRootNode()
                        .search(
                          {
                            type: 'geometry',
                            role: '3d',
                            progress: 'complete',
                          },
                          true
                        );
                      let view2d = e
                        .getDocumentNode()
                        .getRootNode()
                        .search(
                          {
                            type: 'geometry',
                            role: '2d',
                            progress: 'complete',
                          },
                          true
                        );
                      let viewAll = view3d.concat(view2d);
                      if (viewAll.length > 1) {
                        let documentBrowser = viewer.getExtension(
                          'Autodesk.DocumentBrowser'
                        );
                        documentBrowser.ui.togglePanel(true);
                        documentBrowser.ui.panel.container.style.top = 0;
                        documentBrowser.ui.panel.container.style.left = 'unset';
                        documentBrowser.ui.panel.container.style.right = '0px';
                        documentBrowser.ui.panel.container.style.width =
                          '200px';
                      }
                      setViewer(viewer);
                      // showMarkup()
                    } catch {}
                  });
                }
              };
              xhr.send();
            } else {
              viewer.loadModel(filePath, {}, (e) => {
                try {
                  viewer.setReverseZoomDirection(true);
                  let view3d = e
                    .getDocumentNode()
                    .getRootNode()
                    .search(
                      { type: 'geometry', role: '3d', progress: 'complete' },
                      true
                    );
                  let view2d = e
                    .getDocumentNode()
                    .getRootNode()
                    .search(
                      { type: 'geometry', role: '2d', progress: 'complete' },
                      true
                    );
                  let viewAll = view3d.concat(view2d);
                  if (viewAll.length > 1) {
                    let documentBrowser = viewer.getExtension(
                      'Autodesk.DocumentBrowser'
                    );
                    documentBrowser.ui.togglePanel(true);
                    documentBrowser.ui.panel.container.style.top = 0;
                    documentBrowser.ui.panel.container.style.left = 'unset';
                    documentBrowser.ui.panel.container.style.right = '0px';
                    documentBrowser.ui.panel.container.style.width = '200px';
                  }
                  setViewer(viewer);
                  // showMarkup()
                } catch {}
              });
            }
          });
        });
      } else {
        message.warning('Only support pdf, dwf, dwfx format');
      }
    }
  }, [status]);

  const checkLoaded = (e) => {
    e.target.loadExtension('Autodesk.Viewing.MarkupsCore');
    e.target.loadExtension('Autodesk.Viewing.MarkupsGui');
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div id='forgeViewerPDF'></div>
    </div>
  );
}
