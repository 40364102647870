import React, { useEffect } from "react";
import _ from "lodash";

import Loader from "../../../../loader/LoaderApp";
import ExtractQuantityGantt from "./ExtractQuantityGantt.gantt";
import { extractQuantityGanttStore } from "./ExtractQuantityGantt.store";



export default function ExtractQuantityGanttFeature(props) {
    const { extractQuantityGanttLoading } = extractQuantityGanttStore()
    useEffect(() => {

    }, [])


    return (
        <>
            {extractQuantityGanttLoading && <div style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 11111111 }} >
                <Loader />
            </div>}
            <>
                <ExtractQuantityGantt
                />
            </>
        </>
    );
}


