import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as PlusCircleIcon } from 'src/assets/icons/general/plus-circle.svg';
import PageHeader from 'src/components/atoms/PageHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { requestBimGrid } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import ModalAddRole from './modal/ModalAddRole';
import ModalEditRole from './modal/ModalEditRole';
import RoleTable from './Role.table';

export default function LayoutRole() {
  const { setIsLoading } = appStore();
  const [data, setData] = useState([]);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [rowNode, setRowNode] = useState();
  const [gridApi, setGridApi] = useState();
  const { organizationId, projectId } = useParams();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid(
      'get',
      '/api/admin/role/getAllByOrganizationId',
      null,
      { organizationId, projectId }
    );
    if (data) {
      setData(data);
    }
    setIsLoading(false);
  };
  const handleOpenAdd = () => {
    setIsModalAdd(true);
  };

  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader>Organization Role</PageHeader>
          </>
        }
        right={
          <>
            <PrimaryButton onClick={handleOpenAdd}>
              <PlusCircleIcon className='size-4' />
              Add
            </PrimaryButton>
          </>
        }
      />
      <RoleTable data={data} setGridApi={setGridApi} setRowNode={setRowNode} />

      {isModalAdd && (
        <ModalAddRole gridApi={gridApi} setClose={setIsModalAdd} />
      )}
      {rowNode && (
        <ModalEditRole
          gridApi={gridApi}
          setRowNode={setRowNode}
          rowNode={rowNode}
        />
      )}
    </>
  );
}
