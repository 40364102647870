import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Table, Row, Col, Input, Button, message } from 'antd';
import _ from 'lodash'
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { AgGridReact } from 'ag-grid-react';
import { autoGroupColumnDef, defaultColDef } from '../../../../../../settings/General.settings';
import IconButton from '../../../../../gen/button/IconButton.gen';



export default function ClashCheckTestTable({ }) {
    const [search] = useSearchParams();
    const { viewer, clashTestDetail } = viewerStore()
    const columns = [
        {
            headerName: 'Name',
            field: 'name',
            filter: 'agSetColumnFilter',
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: () => {
                            return (
                                <span style={{ fontWeight: 'bold' }} > {params.value}</span>
                            )

                        }
                    }
                }
                return undefined
            }
        },
        {
            headerName: 'Status',
            field: 'status',
            filter: 'agSetColumnFilter',

        },
        {
            headerName: 'Clashes',
            field: 'clashes',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'New',
            field: 'new',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Active',
            field: 'active',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Reviewed',
            field: 'reviewed',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Approved',
            field: 'approved',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Resolved',
            field: 'resolved',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Action',
            lockPosition: 'right',
            suppressMovable: true,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: handleAction,
                    }
                }
                return undefined;
            }
        },
    ]


    useEffect(() => {


    }, [])

    const handleSelect = (params) => {

    }
    const handleAction = (params) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
                <IconButton
                    icon='select'
                    size='small'
                    ghost={true}
                    border={false}
                    iconStyle={{ background: '#000000' }}
                    onClick={handleSelect.bind(this, params)}
                />
                {/* <Button size='small' className={`prefab-custom-button ${(roleForAddEditModule[role] || user.isAdmin) ? 'delete' : 'disabled'}`} onClick={handleDeleteModule.bind(this, params.data)} type='danger'
          disabled={(roleForAddEditModule[role] || user.isAdmin)? false : false} ghost >Delete</Button> */}
            </div>
        )
    }

    const onGridReady = useCallback(() => {

    }, []);
    return (
        <>
            <div style={{ width: '100%', height: '100%', padding: 5 }} >

                <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                    <AgGridReact
                        rowData={[]}
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onGridReady={onGridReady}
                        groupDisplayType='multipleColumns'
                        rowGroupPanelShow='always'
                        rowHeight={30}
                        headerHeight={30}
                        // pagination={true}
                        // paginationPageSize={pageSizeModuleList}
                        suppressPaginationPanel={true}
                        context={false}
                    // statusBar={statusBar}
                    // groupDefaultExpanded={2}
                    ></AgGridReact>


                </div>
            </div>

        </>

    );
}


