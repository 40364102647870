import create from 'zustand'


const initialState = {
    isAddProject: false,
    isEditProject: false,
    editProjectId: null,
    isGridLayout: true,
    tabType: '',
    projects: []
}
export const adminStore = create((set) => ({
    ...initialState,
    setIsAddProject: (value) => set((state) => ({ isAddProject: value })),
    setIsEditProject: (value) => set((state) => ({ isEditProject: value })),
    setEditProjectId: (value) => set((state) => ({ editProjectId: value })),
    setIsGridLayout: (value) => set((state) => ({ isGridLayout: value })),
    setProjects: (value) => set((state) => ({ projects: value })),
    setTabType: (value) => set((state) => ({ tabType: value })),
    resetAdminStore: () => set(initialState)
}))