import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import { qrDrawingStore } from '../QRDrawing.store';

export default function QRCell(props) {
  const { setIsPrintQRDrawing, setCurrentDrawing } = qrDrawingStore();
  const handleOpen = () => {
    setCurrentDrawing(props.node)
    setIsPrintQRDrawing(true);
  };
  return (
    <IconButton
      icon={'qr'}
      size='ssmall'
      border={false}
      type='regular'
      onClick={handleOpen}
    ></IconButton>
  );
}
