import React, { } from "react";
import ProjectNav from "./Project.nav";

export default function ProjectDesktop() {
    return (
        <>
            <ProjectNav />
        </>

    );
}


