/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    message,
    Input
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AppAPI } from '../../../../../api';
import { getRouteToNode } from '../../../../../../functions/General.function';



export default function ModalDMSDocAddFolder() {
    const { setIsModalDMSDocAddFolder, addDocParentNode, setAddDocParentNode, dmsDocGridApi } = dmsStore()
    const { setIsLoading, } = appStore()
    const { projectId } = useParams()
    const [folderName, setFolderName] = useState()
    useEffect(() => {
        return () => {
            setAddDocParentNode()
        }
    }, [])
    const close = () => {
        setIsModalDMSDocAddFolder(false)
    }

    const handleOk = async (e) => {
        if (!folderName) {
            message.warning('Please input folder name')
            return
        }
        setIsLoading(true)
        try {
            let parentId = null
            debugger
            if (typeof addDocParentNode === 'string' || addDocParentNode instanceof String) {
                parentId = addDocParentNode
            } else {
                parentId = addDocParentNode.data.id
            }
            const dsmData = await AppAPI.DMSDocAPI.CreateDMSDocFolder({ name: folderName, parentId, projectId })
            const route = addDocParentNode ? getRouteToNode(addDocParentNode) : [];
            debugger;
            dmsDocGridApi.api.applyServerSideTransaction({
                route: addDocParentNode ? route : [],
                add: [dsmData],
            });

            setIsLoading(false)
            close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
        }
    }
    const handleChange = (e) => {
        setFolderName(e.target.value)
    }


    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Add Folder'}
                style={{ width: 300 }}
            >

                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold' }}>Folder name:</span>
                    <Input placeholder='Folder name' onChange={handleChange} value={folderName} />
                </div>

            </ModalTemplate>
        </>
    );
}

