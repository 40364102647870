import React from 'react';
/**
 * @param {string} label
 * @param {string} name
 * @param {boolean} isRequired
 * @returns {React.Element} The rendered label element.
 */
const LabelForm = ({ label, name, isRequired }) => {
  return (
    <label
      htmlFor={name}
      className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
    >
      {label}:{isRequired ? <span className='text-red-600'> *</span> : ''}
    </label>
  );
};

export default LabelForm;
