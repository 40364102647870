import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { viewerStore } from '../../../../../store/Viewer.store';
import _ from 'lodash';
import { getForgeTokenViewer } from '../../../../../functions/Auth.function';
import { viewerOptions } from '../../../viewer/Viewer.constant';
import { TreeModelData } from '../../../viewer/util/ExtractQuantity.util';

// import TransformExtension from "./extensions/transform/TransformExtension";

const Autodesk = window.Autodesk;

export default function ViewerShareForge({
  urn,
  name,
  fileId = '',
  versionId = '',
  callbackDone = () => { },
}) {
  const {
    viewer,
    setViewer,
    setViewerLoading,
    setAecModelData,
    setIs3D,
    setTreeModalData,
    treeModelData,
  } = viewerStore();

  const [search] = useSearchParams();

  useEffect(() => {
    const treeModelDataLocal = new TreeModelData();
    setTreeModalData(treeModelDataLocal);
    return () => {
      treeModelDataLocal.destroy();
      setTreeModalData();
    };
  }, []);

  useEffect(() => {
    if (treeModelData) {
      window.devicePixelRatio = 1;
      launchViewer();
      return () => {
        if (viewer) {
          viewer.tearDown();
          viewer.finish();
          setViewer(null);
        }
      };
    }
  }, [treeModelData]);

  const launchViewer = () => {
    var options = {
      env: 'AutodeskProduction',
      useConsolidation: true,
      useADP: false,
      getAccessToken: getForgeTokenViewer,
    };

    Autodesk.Viewing.Initializer(options, () => {
      let viewer = new Autodesk.Viewing.GuiViewer3D(
        document.getElementById('forgeViewer'),
        {
          extensions: [
            'Autodesk.DocumentBrowser',
            // "Autodesk.AEC.LevelsExtension",
            'Autodesk.AEC.Minimap3DExtension',
            'Autodesk.AEC.Hypermodeling',
            'Autodesk.Viewing.MarkupsCore',
            'Autodesk.Viewing.MarkupsGui',
          ],
        }
      );
      setViewer(viewer);
    });
  };
  useEffect(() => {
    if (viewer) {
      setViewerLoading(true);
      viewer.start();
      viewer.waitForLoadDone().then((res) => {
        setViewerLoading(false);
      });
      const documentId = 'urn:' + urn;
      viewer.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        eventGeometryLoaded
      );
      viewer.addEventListener(
        Autodesk.Viewing.OBJECT_TREE_LOAD_PROGRESS_EVENT,
        eventTreeProgress
      );
      viewer.addEventListener(
        Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
        eventAddModel
      );
      viewer.addEventListener(
        Autodesk.Viewing.MODEL_REMOVED_EVENT,
        eventRemoveModel
      );
      Autodesk.Viewing.Document.load(
        documentId,
        onDocumentLoadSuccess.bind(this),
        onDocumentLoadFailure.bind(this)
      );
    }
  }, [viewer]);

  const onDocumentLoadSuccess = async (doc) => {
    const aecModelData = await doc.downloadAecModelData();
    // console.log(aecModelData)
    setAecModelData(aecModelData);
    const viewables = doc.getRoot().getDefaultGeometry();
    setIs3D(viewables.data.role === '3d');
    viewer
      .loadDocumentNode(doc, viewables, {
        ...viewerOptions,
        ...{
          fileId,
          versionId,
          modelNameOverride: name,
          aecModelData: aecModelData,
        },
      })
      .then((i) => { });
  };

  const eventTreeProgress = () => {
    setViewerLoading(true);
  };
  const eventGeometryLoaded = (e) => {
    setIs3D(e.model.is3d());
    viewer.waitForLoadDone().then(async () => {
      // viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, eventExtensionLoaded);
      // viewer.loadExtension(SelectFaceExtension)
      viewer.loadExtension('Autodesk.ViewCubeUi').then(function (item) {
        item.showTriad(true);
      });
      const markupCore = await viewer.getExtension(
        'Autodesk.Viewing.MarkupsCore'
      );
      markupCore.addEventListener(
        Autodesk.Viewing.Extensions.Markups.Core.EVENT_EDITMODE_ENTER,
        (e) => console.log('enter')
      );
      markupCore.addEventListener(
        Autodesk.Viewing.Extensions.Markups.Core.EVENT_EDITMODE_LEAVE,
        (e) => console.log('exit')
      );
      setViewerLoading(false);
      debugger
      callbackDone(viewer)
    });
  };
  const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
      'onDocumentLoadFailure() - errorCode:' +
      viewerErrorCode +
      '\n- errorMessage:' +
      viewerErrorMsg
    );
  };
  const eventAddModel = (e) => {
    viewer.waitForLoadDone().then(async () => {
      const models = viewer.impl.modelQueue().getModels();
      treeModelData.add(models);
    });
  };
  const eventRemoveModel = (e) => {
    console.log('remove', e);
    const models = viewer.impl.modelQueue().getModels();
    treeModelData.remove(models);
  };

  return (
    <>
      <div
        style={{ position: 'relative', width: '100%', height: '100%' }}
        id='forgeViewer'
      ></div>
      <div
        style={{ position: 'absolute', zIndex: 99, bottom: 10, opacity: '30%' }}
      >
        <img
          src='http://bimgrid.com.sg/wp-content/uploads/2020/08/cropped-BIMGrid-Pte-Ltd-01-1.png'
          width={150}
        />
      </div>
    </>
  );
}
