import create from 'zustand';

const initialState = {
  //#region //!main
  isModalDMSMainAddFolder: false,
  isModalDMSMainAddDrawing: false,
  isModalDMSMainEditFolder: false,
  isModalDMSMainEditDrawing: false,
  addDMSMainParentNode: null,
  dmsMainGridApi: null,
  updateDMSMainNode: null,
  //#endregion

  //#region //!rfa
  isModalDMSRFAAddRFA: false,
  isModalDMSRFAViewRFA: false,
  isModalDMSRFAEditRFA: false,
  viewDMSRFANode: null,
  editDMSRFANode: null,
  addDMSRFAParentNode: null,
  dmsRFAGridApi: null,
  dmsRFADrawingListGridApi: null,
  dmsRFAMainGridApi: null,
  dmsRFADocGridApi: null,
  drawingList: [],
  selectedDrawingNode: null,
  selectedDrawingCol: null,
  isOpenMainTable: false,
  isOpenDocTable: false,
  groupsOfUser: [],
  selectedDraingNodeValidate: null,
  isOpenRFAValidate: false,
  selectedRFAValidateConsultant: null,
  selectedRFAViewConsultant: null,
  selectedRFARevNode: null,
  isOpenRFAViewRev: false,
  categories: [],
  disciplines: [],
  //#endregion

  //#region //!doc
  isModalDMSDocAddFile: false,
  isModalDMSDocAddFolder: false,
  isModalDMSDocEditFolder: false,
  addDocParentNode: null,
  updateDMSDocNode: null,
  dmsDocGridApi: null,
  fileDMSDocNode: null,
  //#endregion

  //#region //!setting
  isModalDMSSettingAddGroup: false,
  isModalDMSSettingEditGroup: false,
  isModalDMSSettingAddMemberGroup: false,
  dmsSettingGridApi: null,
  consultantGroup: [],
  selectedConsultantGroupId: null,
  editConsultantGroupId: null,
  //#endregion
};
export const dmsStore = create((set) => ({
  ...initialState,
  //#region //!main
  setIsModalDMSMainAddFolder: (value) =>
    set((state) => ({ isModalDMSMainAddFolder: value })),
  setIsModalDMSMainAddDrawing: (value) =>
    set((state) => ({ isModalDMSMainAddDrawing: value })),
  setIsModalDMSMainEditFolder: (value) =>
    set((state) => ({ isModalDMSMainEditFolder: value })),
  setIsModalDMSMainEditDrawing: (value) =>
    set((state) => ({ isModalDMSMainEditDrawing: value })),
  setAddDMSMainParentNode: (value) =>
    set((state) => ({ addDMSMainParentNode: value })),
  setDMSMainGridApi: (value) => set((state) => ({ dmsMainGridApi: value })),
  setUpdateDMSMainNode: (value) =>
    set((state) => ({ updateDMSMainNode: value })),
  //#endregion

  //#region //!rfa
  setIsModalDMSRFAAddRFA: (value) =>
    set((state) => ({ isModalDMSRFAAddRFA: value })),
  setIsModalDMSRFViewRFA: (value) =>
    set((state) => ({ isModalDMSRFAViewRFA: value })),
  setIsModalDMSRFEditRFA: (value) =>
    set((state) => ({ isModalDMSRFAEditRFA: value })),
  setViewDMSRFANode: (value) => set((state) => ({ viewDMSRFANode: value })),
  setEditDMSRFANode: (value) => set((state) => ({ editDMSRFANode: value })),
  setAddDMSRFAParentNode: (value) =>
    set((state) => ({ addDMSRFAParentNode: value })),
  setDMSRFAGridApi: (value) => set((state) => ({ dmsRFAGridApi: value })),
  setDMSRFADrawingListGridApi: (value) =>
    set((state) => ({ dmsRFADrawingListGridApi: value })),
  setDMSRFAMainGridApi: (value) =>
    set((state) => ({ dmsRFAMainGridApi: value })),
  setDMSRFADocGridApi: (value) => set((state) => ({ dmsRFADocGridApi: value })),
  setDrawingList: (value) => set((state) => ({ drawingList: value })),
  setSelectedDrawingNode: (value) =>
    set((state) => ({ selectedDrawingNode: value })),
  setSelectedDrawingCol: (value) =>
    set((state) => ({ selectedDrawingCol: value })),
  setIsOpenMainTable: (value) => set((state) => ({ isOpenMainTable: value })),
  setIsOpenDocTable: (value) => set((state) => ({ isOpenDocTable: value })),
  setGroupsOfUser: (value) => set((state) => ({ groupsOfUser: value })),
  setSelectedDraingNodeValidate: (value) =>
    set((state) => ({ selectedDraingNodeValidate: value })),
  setIsOpenRFAValidate: (value) =>
    set((state) => ({ isOpenRFAValidate: value })),
  setSelectedRFAValidateConsultant: (value) =>
    set((state) => ({ selectedRFAValidateConsultant: value })),

  setSelectedRFAViewConsultant: (value) =>
    set((state) => ({ selectedRFAViewConsultant: value })),
  setSelectedRFARevNode: (value) =>
    set((state) => ({ selectedRFARevNode: value })),
  setIsOpenRFAViewRev: (value) => set((state) => ({ isOpenRFAViewRev: value })),
  setCategories: (value) => set((state) => ({ categories: value })),
  setDisciplines: (value) => set((state) => ({ disciplines: value })),
  //#endregion

  //#region //!doc
  setIsModalDMSDocAddFile: (value) =>
    set((state) => ({ isModalDMSDocAddFile: value })),
  setIsModalDMSDocAddFolder: (value) =>
    set((state) => ({ isModalDMSDocAddFolder: value })),
  setIsModalDMSDocEditFolder: (value) =>
    set((state) => ({ isModalDMSDocEditFolder: value })),
  setAddDocParentNode: (value) => set((state) => ({ addDocParentNode: value })),
  setUpdateDMSDocNode: (value) => set((state) => ({ updateDMSDocNode: value })),
  setDMSDocGridApi: (value) => set((state) => ({ dmsDocGridApi: value })),
  setFileDMSDocNode: (value) => set((state) => ({ fileDMSDocNode: value })),
  //#endregion

  //#region //!setting
  setIsModalDMSSettingAddGroup: (value) =>
    set((state) => ({ isModalDMSSettingAddGroup: value })),
  setIsModalDMSSettingEditGroup: (value) =>
    set((state) => ({ isModalDMSSettingEditGroup: value })),
  setIsModalDMSSettingAddMemberGroup: (value) =>
    set((state) => ({ isModalDMSSettingAddMemberGroup: value })),
  setDMSSettingGridApi: (value) =>
    set((state) => ({ dmsSettingGridApi: value })),
  setConsultantGroup: (value) => set((state) => ({ consultantGroup: value })),
  setSelectedConsultantGroupId: (value) =>
    set((state) => ({ selectedConsultantGroupId: value })),
  setEditConsultantGroupId: (value) =>
    set((state) => ({ editConsultantGroupId: value })),
  //#endregion

  resetDMSStore: () => set(initialState),
}));
