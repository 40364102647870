import React from 'react';
import WorkerSettingLayout from '../../../layout/apps/worker/settings/WorkerSetting.layout';

export default function WorkerSettingPage() {
  return (
    <>
      {' '}
      <WorkerSettingLayout />
    </>
  );
}
