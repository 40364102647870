/* eslint-disable no-unused-vars */
import { InboxOutlined } from '@ant-design/icons';
import {
  DatePicker as DatePickerDayjs,
  Divider,
  Input,
  message,
  Select,
  Upload,
} from 'antd';
import _ from 'lodash';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { projectStore } from 'src/store/Docs.store';
import { v4 } from 'uuid';
import {
  checkInput,
  getBaseName,
  updateTableAsync,
  uploadFileToForge,
} from '../../../../../../functions/General.function';
import { appStore } from '../../../../../../store/App.store';
import { workerStore } from '../../../../../../store/Worker.store';
import { AppAPI } from '../../../../../api';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalEditBlockZone() {
  const { setIsOpenEditBlockZone, blockZoneGridApi, nodeEditBlockZone } =
    workerStore();
  const { setIsLoading } = appStore();
  const { projectId } = useParams();
  const [newItems, setNewItems] = useState({});
  const [selectItems, setSelectItems] = useState({});
  const { project } = projectStore();
  const [data, setData] = useState([
    {
      name: 'Block',
      field: 'block',
      value: null,
      type: 'select',
      isAdd: true,
      data: [],
    },
    {
      name: 'Level',
      field: 'level',
      value: null,
      type: 'input',
    },
    {
      name: 'Zone',
      field: 'zone',
      value: null,
      type: 'input',
    },
    {
      name: 'Description',
      field: 'description',
      value: null,
      type: 'input',
    },
  ]);
  const [file, setFile] = useState();
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const blockRes = await AppAPI.WorkerSettingAPI.GetAllBlock({ projectId });
      selectItems['block'] = blockRes.map((v) => v.block);
      setSelectItems({ ...selectItems });
      _.forEach(nodeEditBlockZone.data, (v, k) => {
        const index = _.findIndex(data, (i) => {
          return i.field === k;
        });
        if (index >= 0) {
          data[index].value = v;
        }
      });
      setData([...data]);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenEditBlockZone(false);
  };

  const handleOk = async (e) => {
    if (!checkInput(data, ['block'])) {
      return;
    }
    setIsLoading(true);
    try {
      const tmp = {};
      data.forEach((v) => {
        nodeEditBlockZone.data[v.field] = v.value;
        tmp[v.field] = v.value;
      });
      tmp.id = nodeEditBlockZone.data.id;
      if (file) {
        const fileName =
          v4() +
          '-' +
          'worker-tracking-app' +
          '-' +
          Date.now() +
          '-' +
          file.name;

        const result = await uploadFileToForge(
          project.forge_bucket_id,
          file,
          fileName,
          null
        );
        tmp.path = result.data.objectId;
      }

      // const formData = new FormData();
      // formData.append('data', JSON.stringify(tmp));
      // formData.append('file', file);

      const resBlockZone = await AppAPI.WorkerSettingAPI.UpdateOneBlockZone({
        data: JSON.stringify(tmp),
      });

      debugger;
      _.forEach(resBlockZone, (v, k) => {
        if (nodeEditBlockZone.data[k] !== undefined)
          nodeEditBlockZone.data[k] = v;
      });
      await updateTableAsync(blockZoneGridApi.api, {
        update: [nodeEditBlockZone.data],
      });
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };
  const handleChange = (type, e) => {
    type.value = e.target.value;
    setData([...data]);
  };
  const handleChangeDate = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const handleChangeSelect = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const onChangeNewItem = (type, event) => {
    newItems[type] = event.target.value;
    setNewItems({ ...newItems });
  };
  const handleAddNewItem = (type) => {
    if (!selectItems[type]) {
      selectItems[type] = [];
    }
    const unique = _.uniq([...selectItems[type], newItems[type]]);
    selectItems[type] = unique;
    setSelectItems({ ...selectItems });
    newItems[type] = null;
    setNewItems({ ...newItems });
  };
  const customRequest = async (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        setFile(info.file);
      }
    }
  };
  const beforeUpload = (file) => {
    if (file.size > 1500 * 1024 * 1024) {
      message.warning('Cannot upload file larger 1.5GB');
      return false;
    }
    return true;
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Edit block/zone'}
        style={{ width: 500 }}
      >
        <>
          {data.map((v) => (
            <>
              {v.type === 'input' && (
                <div
                  style={{ display: 'flex', gap: 5, flexDirection: 'column' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                  <Input
                    placeholder='please, input'
                    onChange={handleChange.bind(this, v)}
                    value={v.value}
                  />
                </div>
              )}
              {v.type === 'date' && (
                <div
                  style={{ display: 'flex', gap: 5, flexDirection: 'column' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                  <DatePicker
                    placeholder='please, input'
                    onChange={handleChangeDate.bind(this, v)}
                    value={v.value}
                  />
                </div>
              )}
              {v.type === 'select' && (
                <div
                  style={{ display: 'flex', gap: 5, flexDirection: 'column' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                  <Select
                    placeholder='please, input'
                    onChange={handleChangeSelect.bind(this, v)}
                    value={v.value}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        {v.isAdd && (
                          <>
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                gap: 5,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Input
                                placeholder='Please enter discipline'
                                style={{ width: 'calc(100% - 90px)' }}
                                value={newItems[v.field]}
                                onChange={onChangeNewItem.bind(this, v.field)}
                                onKeyDown={(e) => e.stopPropagation()}
                              />
                              <IconTextButton
                                onClick={handleAddNewItem.bind(this, v.field)}
                                size='ssmall'
                                icon={'add'}
                                type={'save'}
                              >
                                Add
                              </IconTextButton>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  >
                    {selectItems[v.field]?.map((v) => (
                      <Select.Option value={v}> {v} </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            </>
          ))}
          <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
            <span style={{ fontWeight: 'bold' }}>{`Model:`}</span>
            <Upload.Dragger
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
              accept='.rvt,.nwc,.nwd,.nwf,.ifc,.dwf,.dwfx,.fbx,.dxf,.dwg,.skp,.ifczip,.pdf,.dgn'
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              {file || nodeEditBlockZone.data.path ? (
                <>
                  <p className='ant-upload-text'>
                    {file?.name ?? getBaseName(nodeEditBlockZone.data.path)}
                  </p>
                  <p className='ant-upload-text'>
                    Click or drag file to re-upload
                  </p>
                </>
              ) : (
                <p className='ant-upload-hint'>
                  Support for
                  .rvt,.nwc,.nwd,.nwf,.ifc,.dwf,.dwfx,.fbx,.dxf,.dwg,.skp,.ifczip,.pdf,.dgn
                  format
                </p>
              )}
            </Upload.Dragger>
          </div>
        </>
      </ModalTemplate>
    </>
  );
}
