import { DatePicker as DatePickerDayjs, message, Select } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from 'src/components/gen/button/IconButton.gen';
import { appStore } from '../../../../../store/App.store';
import { workerStore } from '../../../../../store/Worker.store';
import { AppAPI } from '../../../../api';
const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function WorkerTrackingToolbar() {
  const {
    trackingDate,
    setTrackingDate,
    trackingWorkerId,
    setTrackingWorkerId,
    trackingGridApi,
  } = workerStore();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { setIsLoading } = appStore();
  const [workerList, setWorkerList] = useState([]);
  const handleChangeDaily = (e) => {
    const tmp = [e.clone().startOf('month'), e.clone().endOf('month')];
    setTrackingDate(tmp);
  };
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const workerRes = await AppAPI.WorkerSettingAPI.GetAllWorkerListByProject(
        {
          projectId,
          select: ['worker_app_worker_list.name', 'worker_app_worker_list.id'],
        }
      );

      setWorkerList(workerRes);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const handleChangeSelect = (e) => {
    setTrackingWorkerId(e);
  };
  const handleUpdatePayment = async () => {
    setIsLoading(true);
    try {
      await AppAPI.WorkerSettingAPI.UpdateRangePayment({
        workerId: trackingWorkerId,
        start: trackingDate[0],
        end: trackingDate[1],
      });
      navigate(0);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const handleExportExcel = () => {
    trackingGridApi.api.exportDataAsExcel({
      // columnKeys: trackingGridApi.api
      //   .getColumnDefs()
      //   .filter((col) => !['action_ignore'].includes(col.field))
      //   .map((col) => col.field),
      processCellCallback: function (params) {
        debugger;
        if (!params.node.group) {
          if (params.node.data[`${params.column.colId}_processed`]) {
            return params.node.data[`${params.column.colId}_processed`];
          }
          return params.node.data[`${params.column.colId}`];
        } else {
          const value = params.value;
          return value;
        }
      },
      processRowGroupCallback: (params) => {
        // This callback allows you to customize how group rows are exported
        const node = params.node;
        const field = node.field;
        if (['tracking_time', 'payement'].includes(field)) {
          return node.data[field];
        }
        return node.key;
      },
    });
  };
  return (
    <>
      <div
        style={{
          padding: '5px 2px',
          display: 'flex',
          gap: 5,
          alignItems: 'center',
        }}
      >
        <Select
          showSearch
          placeholder='Please input'
          onChange={handleChangeSelect.bind(this)}
          value={trackingWorkerId}
          filterOption={(input, option) =>
            (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.name ?? '')
              .toLowerCase()
              .localeCompare((optionB?.name ?? '').toLowerCase())
          }
        >
          {workerList?.map((v) => (
            <Select.Option name={v.name} value={v.id}>
              {' '}
              {v.name}{' '}
            </Select.Option>
          ))}
        </Select>

        <DatePicker.MonthPicker
          allowClear={false}
          value={trackingDate}
          onChange={handleChangeDaily}
        />

        <IconButton size='ssmall' icon='money' onClick={handleUpdatePayment} />
        <IconButton size='ssmall' icon='excel' onClick={handleExportExcel} />
      </div>
    </>
  );
}
