import { ReactComponent as CloseIcon } from 'src/assets/icons/general/close.svg';
import BackDrop from 'src/components/atoms/BackDrop';
import HorizontalDivider from 'src/components/atoms/HorizontalDivider';
import IconButton from 'src/components/atoms/IconButton';
import ModalHeader from 'src/components/atoms/ModalHeader';
import PrimaryButton from 'src/components/atoms/PrimaryButton';

export default function Modal({
  onOk,
  onClose,
  title,
  children,
  footer = true,
  style = {},
  className,
  isFooter = true,
  closeable = true,
}) {
  const defaultFooter = () => {
    return (
      <div className='flex gap-2 px-2 pb-2'>
        <PrimaryButton type='normal' onClick={onClose} isFull>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={onOk} isFull>
          Save
        </PrimaryButton>
      </div>
    );
  };

  return (
    <BackDrop>
      <div className='fixed inset-0 z-100 flex items-center justify-center p-4'>
        <div
          className='flex max-h-[90vh] w-full min-w-[350px] max-w-fit flex-col overflow-hidden rounded bg-white shadow-lg dark:bg-black'
          style={style}
        >
          <div className='flex items-center justify-between bg-primary-600 px-4 py-3 dark:bg-primary-800'>
            <ModalHeader>{title}</ModalHeader>
            {closeable && (
              <IconButton
                className='rounded-full !bg-primary-600 hover:!bg-primary-600'
                onClick={onClose}
              >
                <CloseIcon className='h-3.5 w-3.5 text-white dark:text-white' />
                <span className='sr-only'>Close modal</span>
              </IconButton>
            )}
          </div>

          <div className='flex-grow overflow-y-auto p-4'>{children}</div>

          {isFooter && (
            <>
              {footer === true ? (
                <>
                  <HorizontalDivider />
                  {defaultFooter()}
                </>
              ) : footer === false ? (
                <></>
              ) : (
                <>
                  <HorizontalDivider />
                  {footer}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </BackDrop>
  );
}
