/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Tabs } from 'antd';
import _ from 'lodash';
import { qrDrawingStore } from '../QRDrawing.store';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import PropertyItem from './PropertyItem';

export default function AddProperties({ isView  }) {
  const { setProperties, properties } = qrDrawingStore();
  useEffect(() => {
    return()=>{
      setProperties([])
    }
  }, []);
  const handleAdd = () => {
    let clone = [...properties];
    clone.push({ name: '', value: '' });
    setProperties(clone);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          overflow: 'auto',
          height: 200,
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 5,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', gap: 5, width: '100%' }}>
            <div style={{ width: '100%',fontWeight:'bold' }}>Description</div>
            <div style={{ width: '100%' ,fontWeight:'bold'}}>Value</div>
          </div>

          {!isView &&  <div style={{width:25}} ></div>}
        </div>
        {properties.map((v, k) => (
          <PropertyItem item={v} key={k} index={k} isView={isView} />
        ))}
      </div>
      {!isView && (
        <IconTextButton
          icon={'add'}
          size='ssmall'
          type='save'
          onClick={handleAdd}
        >
          Add Property
        </IconTextButton>
      )}
    </>
  );
}
