import React from 'react';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { appStore } from '../../../../../../store/App.store';
import { dmsStore } from '../../../../../../store/DMS.store';

import { message } from 'antd';
import { useParams } from 'react-router-dom';
const ActionButtonCell = (params) => {
  const { setIsLoading } = appStore();
  const {
    setViewDMSRFANode,
    setIsModalDMSRFViewRFA,
    setIsModalDMSRFEditRFA,
    setEditDMSRFANode,
  } = dmsStore();
  const { organizationId, projectId } = useParams();

  const handleEditItem = () => {
    if (params.node.level === 2) {
      setEditDMSRFANode(params.node);
      setIsModalDMSRFEditRFA(true);
    }
  };

  const handleViewItem = async () => {
    if (params.node.level === 2) {
      setViewDMSRFANode(params.node);
      setIsModalDMSRFViewRFA(true);
    } else {
      const split = params.node.data.name?.toLowerCase().split('.');
      const fileExt = split[split.length - 1];
      if (fileExt === 'pdf' || fileExt === 'dwf' || fileExt === 'dwfx') {
        const url = `${window.location.origin}/organization/${organizationId}/project/${projectId}/dms/viewer?fileId=${params.node.data.id}`;
        window.open(url);
      } else {
        message.warning('Only pdf, dwf and dwfx are able to view');
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        alignItems: 'center',
        height: '100%',
      }}
    >
      {params.node.level === 2 && (
        <>
          <IconButton
            icon={'view'}
            size='sssmall'
            iconStyle={{ background: '#000000' }}
            onClick={handleViewItem.bind(this, params)}
          />

          <IconButton
            icon={'edit'}
            size='sssmall'
            // type={'edit'}
            iconStyle={{ background: '#000000' }}
            onClick={handleEditItem.bind(this, params)}
          />
        </>
      )}
    </div>
  );
};

export default ActionButtonCell;
