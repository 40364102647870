import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'antd';
import _, { last } from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { clashDetectionStore } from '../ClashDetection.store';




export default function ClashDetectionDetailPopup({   }) {
    const { clashDetectionPoint,  clashDetectionSelected } = clashDetectionStore()
    const { viewer } = viewerStore()
    const [position, setPosition] = useState(clashDetectionSelected.label)
    const ref = useRef()
    useEffect(() => {
        if (ref.current) {

        }
        clashDetectionPoint.addEventListener('popup', handleSelection, false)
        return () => {
            clashDetectionPoint.removeEventListener('popup', handleSelection, false)
            clashDetectionPoint.removeMarkupSelected()
            // setClashDetectionSelected()
        }
    }, [])
    useEffect(() => {
        if (clashDetectionSelected.label) {
            let e = { position: clashDetectionSelected.label }
            // let container = viewer.canvas;
            // let rect = container.getBoundingClientRect();
            // let el = document.getElementById('idd-popup-clash')
            // let width = el.clientWidth
            // let height = el.clientHeight
            // el.style.display = 'block'
            // if (rect.top > e.position.y) {
            //     el.style.display = 'none'
            // }
            // if (rect.left > e.position.x) {
            //     el.style.display = 'none'
            // }
            // e.position.x = e.position.x - width/2
            // e.position.y = e.position.y -height+ 1
            setPosition(e.position)
        }
    }, [clashDetectionSelected.label])
    const handleSelection = (e) => {
        let container = viewer.canvas;
        let rect = container.getBoundingClientRect();
        let el = document.getElementById('idd-popup-clash')
        let width = el.clientWidth
        let height = el.clientHeight
        el.style.display = 'block'
        if (rect.top > e.position.y) {
            el.style.display = 'none'
        }
        if (rect.left > e.position.x) {
            el.style.display = 'none'
        }
        // e.position.x = e.position.x - width/2
        // e.position.y = e.position.y -height+ 1
        // let el = document.getElementById('idd-popup-clash')
        // const popupWidth = ref.current.offsetWidth;
        // const popupHeight = ref.current.offsetHeight;
        // const maxX = rect.width - popupWidth;
        // const maxY = rect.height - popupHeight
        // let popupX = e.position.x
        // let popupY = e.position.y;
        // if (popupX > maxX) {
        //     e.position.x = maxX;
        // }
        // if (popupY > maxY) {
        //     e.position.y = maxY;
        // }
        setPosition(e.position)
        viewer.clearSelection()
    }

    return (
        <>
            <div id='idd-popup-clash' style={{ top: position.y, left: position.x, position: 'fixed', fontSize: 12 }} >
                <div className={`content ${clashDetectionSelected.colorName}-clash`} style={{ display: 'flex', flexDirection: 'column', background: 'white', padding: 10, borderRadius: 5 }} >
                    <div style={{ display: 'flex', gap: 5 }} >
                        <span style={{ fontWeight: 'bold' }} > Name: </span>
                        <span>{clashDetectionSelected.name}</span>
                    </div>
                    <div style={{ display: 'flex', gap: 5 }} >
                        <span style={{ fontWeight: 'bold' }} >Status: </span>
                        <span className={`${clashDetectionSelected.colorName}-clash`} >{clashDetectionSelected.status}</span>
                    </div>
                </div>
            </div>
        </>

    );
}


