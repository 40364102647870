import React from "react";
import { DMSDocShareLayout } from "../../../../../layout/share/apps/two_d/doc/DMSDocShare.layout";

export default function DMSDocSharePage() {


    return (
        <>
            <DMSDocShareLayout />

        </>

    );
}
