import { Client } from '@microsoft/microsoft-graph-client';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LoginMicrosoftAPI } from 'src/api';
import { appStore } from 'src/store/App.store';

export default function RootRoute() {
  const navigate = useNavigate();
  const { setIsLoading, isLoading } = appStore();

  useEffect(() => {
    setIsLoading(true);
    if (!localStorage.bimGridToken && window.hello('aad').getAuthResponse()) {
      window.client = Client.init({
        authProvider: (done) => {
          done(null, window.hello('aad').getAuthResponse().access_token);
        },
      });
      window.client.api('/me').get(async (err, res) => {
        if (!err) {
          try {
            const data = await LoginMicrosoftAPI({
              email: res.mail
                ? res.mail.toLowerCase()
                : res.userPrincipalName.toLowerCase(),
            });
            if (data) {
              localStorage.bimGridToken = data.token;
              navigate('organization');
            }
            setIsLoading(false);
          } catch (ex) {
            localStorage.removeItem('hello');
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
    } else if (
      localStorage.bimGridToken &&
      window.hello('aad').getAuthResponse()
    ) {
      navigate('/organization');
    } else if (localStorage.bimGridToken) {
      navigate('/organization');
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);
  return <>{!isLoading && <Outlet />}</>;
}
