import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Badge } from 'antd';


export default function ClashNameCell(props) {
    // const [status, setStatus] = useState()
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    // useEffect(() => {
    //     setStatus(props.data.status)
    //     console.log('dss')
    // }, [props.data])

    return (
        <div style={{ display: 'flex', alignItems: 'center' , gap:5}}>
            <div className={`idd-badge ${props.data.status?.toLowerCase()}-clash`} ></div>
            <span>
                {cellValue}
            </span>

        </div>
    );
};