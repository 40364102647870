/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    message,
    Input
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AppAPI } from '../../../../../api';
import { generateS3Path, getRouteToNode } from '../../../../../../functions/General.function';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { AgGridReact } from 'ag-grid-react';
import { autoGroupColumnDef, defaultColDef } from '../../../../../../settings/General.settings';
import DateCell from '../../../../../ag_grid_components/cell/Date.cell';
import SizeCell from '../../../../../ag_grid_components/cell/Size.cell';
import { S3_BASED_PATH } from '../../../../../../config';



export default function ModalDMSDocFileVersion() {
    const { setFileDMSDocNode, fileDMSDocNode } = dmsStore()
    const { setIsLoading, } = appStore()
    const { organizationId, projectId } = useParams()
    const [, setGridApi] = useState()
    const gridRef = useRef();
    const [data, setData] = useState([])
    const close = () => {
        setFileDMSDocNode()
    }

    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            let dmsData = await AppAPI.DMSDocAPI.GetDMSDocVersions({ id: fileDMSDocNode.data.id })
            debugger
            setData(dmsData)
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
            close()
        }

    }

    const columns = [
        {
            headerName: 'Version',
            field: 'version',
            filter: 'agSetColumnFilter',
            sort: 'desc'
        },
        {
            headerName: 'Size',
            field: 'size',
            filter: 'agSetColumnFilter',
            cellRendererSelector: (params) => {
                return {
                    component: SizeCell,
                };
            },
        },
        {
            headerName: 'Date',
            field: 'updated_at',
            filter: 'agSetColumnFilter',
            width: 95,
            maxWidth: 95,
            cellRendererSelector: (params) => {
                return {
                    component: DateCell,
                };
            },
        },
        {
            headerName: 'By',
            field: 'updated_by',
            filter: 'agSetColumnFilter',
            width: 100,
            maxWidth: 180,
        },
        {
            headerName: '',
            lockPosition: 'right',
            suppressMovable: true,
            suppressMenu: true,
            suppressSorting: true,
            minWidth: 70, maxWidth: 70,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: handleAction,
                    }
                }
                return undefined;
            }
        },
    ]
    const handleView = async (node) => {
        setIsLoading(true)
        try {
            const key = generateS3Path(organizationId, projectId,
                S3_BASED_PATH.DMS_DOC, `${node.data.path}/${fileDMSDocNode.data.id}/${node.data.version}/${fileDMSDocNode.data.name}`)
            const link = await AppAPI.S3API.GetPublicLink({ key })
            window.open(link)
        } catch (ex) {
            message.warning(ex.message)
        }
        setIsLoading(false)

    }
    const handleDownload = async (node) => {
        setIsLoading(true)
        try {
            debugger
            const key = generateS3Path(organizationId, projectId,
                S3_BASED_PATH.DMS_DOC, `${node.data.path}/${fileDMSDocNode.data.id}/${node.data.version}/${fileDMSDocNode.data.name}`)
            const link = await AppAPI.S3API.GetPublicLink({ key })
            window.open(link, "_blank")
        } catch (ex) {
            message.warning(ex.message)
        }
        setIsLoading(false)

    }
    const handleAction = (params) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>

                <IconButton
                    icon={'view'}
                    size='sssmall'
                    onClick={handleView.bind(this, params.node)}
                />
                <IconButton
                    icon={'download'}
                    size='sssmall'
                    onClick={handleDownload.bind(this, params.node)}
                />
            </div>
        )
    }
    const onGridReady = useCallback((params) => {
        setGridApi(params)
    }, [setGridApi]);
    return (
        <>
            <ModalTemplate
                onClose={close}
                title={'File Version'}
                style={{ width: 700, height: 500 }}
                footer={false}
            >

                <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        groupDisplayType='multipleColumns'
                        onGridReady={onGridReady}
                        suppressPaginationPanel={true}
                        groupDefaultExpanded={2}
                        suppressContextMenu={true}
                        rowHeight={30}
                        headerHeight={35}
                    />
                </div>
            </ModalTemplate>
        </>
    );
}

