/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Upload, message
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useNavigate, useParams } from 'react-router-dom';
import _, { forEach } from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';
import IconButton from '../../../../../gen/button/IconButton.gen';
import UploadFile from '../components/UploadFile';
import { eachLimit } from 'async';
import { AppAPI } from '../../../../../api';
import UplaodProgress from '../components/UploadProgress';
import { getRouteToNode } from '../../../../../../functions/General.function';



export default function ModalDMSDocAddFile() {
  const { setIsModalDMSDocAddFile, dmsDocGridApi, addDocParentNode } = dmsStore()
  const { setIsLoading } = appStore()
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [done, setDone] = useState(0)
  const close = () => {
    setIsModalDMSDocAddFile(false)
  }

  const handleOk = async (e) => {
    if (!window.confirm('Are you sure to upload?')) return
    try {
      const array = []
      forEach(files, file => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('parentId', addDocParentNode.data.id)
        array.push(AppAPI.DMSDocAPI.CreateDMSDocFile(formData))
      })
      setIsUploading(true)
      setIsLoading(true)
      await upload(array)
      setIsUploading(false)
      setIsLoading(false)
      close()
    } catch (ex) {
      message.warning(ex.message)
      setIsUploading(false)
      setIsLoading(false)
    }

  }
  const upload = (array) => {
    return new Promise((resolve, reject) => {
      eachLimit(array, 2,
        (task, callback) => {
          task.then((res) => {
            setDone(prev => {
              prev++
              return prev
            })
            debugger
            const route = getRouteToNode(addDocParentNode);
            if (res.current_version == '1') {
              dmsDocGridApi.api.applyServerSideTransaction({
                route: addDocParentNode ? route : [],
                add: [res],
              });
            } else {
              dmsDocGridApi.api.refreshServerSide({ route, purge: false })
            }
            callback()
          }, (err) => {
            console.log(err)
            callback(err)
          })
        },
        async (err) => {
          if (err) {
            reject(err)
          } else {
            resolve()
          }
        })

    })
  }

  const customRequest = (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        setFiles(prev => {
          debugger
          prev.push(info.file)
          return [...prev]
        })
      }
    }
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={isUploading ? 'Uploading...' : 'Add Files'}
        style={{ maxWidth: 500, width: 500 }}
        footer={!isUploading}
        closeable={!isUploading}
      >
        {!isUploading ? <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 10, alignItems: 'center' }} >
          <div style={{ width: '100%' }} >
            <Upload className='idd-upload-input' customRequest={customRequest} showUploadList={false} multiple >
              <IconTextButton block={true} size='ssmall' type='save' icon={'add_file'} style={{ width: '100%' }} >
                Select Files
              </IconTextButton>
            </Upload>
          </div>
          {files.length !== 0 && <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 500, height: '100%', width: '100%', gap: 5 }} >
            {files.map(v =>
              <UploadFile name={v.name} setFiles={setFiles} />
            )}
          </div>}
        </div>
          :
          <UplaodProgress done={done} total={files.length} />
        }
      </ModalTemplate>
    </>
  );
}

