import { getLeafFragIds, resetTransform } from "../../../../functions/Viewer.function";


const Autodesk = window.Autodesk
const avp = Autodesk.Viewing.Private;
const THREE = window.THREE
export default class AnimationPlay {
    anim = null
    viewer = null
    animationStepsData = []
    previousStep = null
    constructor(viewer) {
        this.viewer = viewer
    }

    stop =async () => {
        this.anim?.stop()
        this.resetAllTransform()
        if (this.previousStep) {
            await this.unActiveItem(this.previousStep)
        }
    }

    runAnimation = (item, array) => {
        return new Promise(async resolve => {
            const updateCutPlanes = (unitTime) => {
                const t = avp.smootherStep(unitTime);
                const x = avp.lerp(item.position.x, 0, t);
                const y = avp.lerp(item.position.y, 0, t);
                const z = avp.lerp(item.position.z, 0, t);
                array.forEach(fragProxy => {
                    fragProxy.position = new THREE.Vector3(x, y, z)
                    fragProxy.updateAnimTransform()
                })

                // fade-out mouse over while animating
                const blendPass = this.viewer.impl.renderer().getBlendPass();
                const uniform = blendPass.uniforms['highlightIntensity'];
                uniform.value = Math.min(uniform.value, 1.0 - t);
                this.viewer.impl.sceneUpdated(true)
            };
            // if (this.previousStep) {
            //     await this.unActiveItem(this.previousStep)
            // }
            this.previousStep = item
            await this.activeItem(item)
            const onAnimEnd =async () => {
                if (item) {
                    await this.unActiveItem(item)
                }
                resolve()
            };
            this.anim = avp.fadeValue(0.0, 1.0, item.time, updateCutPlanes, onAnimEnd);
        })
    }
    resetAllTransform = async () => {
        if (this.animationStepsData.length === 0) return
        for (var j in this.animationStepsData) {
            const selector = this.animationStepsData[j]?.elements
            for (var i in selector) {
                let model = selector[i].model
                const instanceTree = model.getData().instanceTree;
                const els = selector[i].selection
                for (var v in els) {
                    await resetTransform(this.viewer, instanceTree, els[v])
                }
            }
        }
    }
    set = (animationStepsData) => {
        this.animationStepsData = animationStepsData
    }
    activeItem = (item) => {
        return new Promise(resolve => {
            const el = document.getElementById(`animation-${item.id}`)
            if (!el.classList.contains('active'))
                el.classList.add('active')
            resolve()
        })
    }
    unActiveItem = (item) => {
        return new Promise(resolve => {
            const el = document.getElementById(`animation-${item.id}`)
            if (el.classList.contains('active'))
                el.classList.remove('active')
            resolve()
        })
    }
}