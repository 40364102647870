import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Menu, Tag, message } from 'antd';
import {} from 'antd/lib/tree-select';
import {
  FolderAddOutlined,
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FileOutlined,
  PushpinOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { requestBimGrid } from '../../../../../../functions/General.function';
import SelectViewModal from '../modal/SelectView.modal';
import { AppAPI } from '../../../../../api';

const Autodesk = window.Autodesk;
export default function TreeFile({ item, setOtherViews, setItemSelected }) {
  const navigate = useNavigate();
  const { viewer, listModel, setListModel } = viewerStore();
  const [isView, setIsView] = useState();
  const [nodeLoading, setNodeLoading] = useState();
  const isMain =
    viewer?.impl?.model?.myData.loadOptions?.fileId === item.fileId;
  useEffect(() => {
    let index = _.findIndex(listModel, (v) => {
      return v.fileId === item.fileId;
    });
    setIsView(index >= 0);
  }, [listModel]);
  const handleOpenView = async (e) => {
    console.log(item);
    setNodeLoading(true);
    let resVersion = await AppAPI.VersionAPI.GetLastVersion({
      fileId: item.fileId,
      select: [
        'version',
        'file.name',
        'file_id as fileId',
        'object_id as objectId',
        'version.id as versionId',
      ],
    });
    //  await requestBimGrid('get', '/api/version/get-last-version', null, )
    if (resVersion) {
      Autodesk.Viewing.Document.load(
        'urn:' + btoa(resVersion.objectId),
        onDocumentLoadSuccess.bind(this, resVersion),
        onDocumentLoadFailure
      );
    } else {
      setNodeLoading(false);
    }

    e.preventDefault();
    e.stopPropagation();
  };
  const onDocumentLoadSuccess = (version, doc) => {
    let view3d = doc
      .getRoot()
      .search({ type: 'geometry', role: '3d', progress: 'complete' }, true);
    let clone = { ...item, versionId: version.versionId, doc };
    setNodeLoading(false);
    setItemSelected(clone);
    setOtherViews(view3d);
  };
  const onDocumentLoadFailure = (doc) => {
    message.warning('Cannot load this file');
    setNodeLoading(false);
  };
  const handleRemoveView = () => {
    const models = viewer.impl.modelQueue().getModels();
    let index = _.findIndex(models, (v) => {
      return v.myData.loadOptions.fileId === item.fileId;
    });
    if (index >= 0) {
      if (!window.confirm('Are you want to remove?')) return;
      viewer.unloadModel(models[index]);
      let clone = [...listModel];
      let index1 = _.findIndex(clone, (v) => {
        return v.fileId === item.fileId;
      });
      clone.splice(index1, 1);
      setListModel(clone);
      viewer.unloadModel(clone);
    }
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            color: isMain ? 'blue' : 'black',
          }}
        >
          <FileOutlined style={{}} />
          <span style={{ fontWeight: isView ? 'bold' : 'normal' }}>
            {item.fileName}
          </span>
        </div>

        <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {isMain ? (
            <PushpinOutlined />
          ) : nodeLoading ? (
            <LoadingOutlined />
          ) : isView ? (
            <EyeOutlined onClick={handleRemoveView} />
          ) : (
            <EyeInvisibleOutlined onClick={handleOpenView} />
          )}

          {/* <MoreOutlined /> */}
        </div>
      </div>
    </>
  );
}
