import React, { useCallback } from 'react';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { AppAPI } from '../../../../../api';
export default function REFNumberCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const { organizationId, projectId } = useParams();

  const handleViewFile = useCallback(
    (type) => {
      debugger;
      let fileExt = '';
      if (type === 'pdf') {
        fileExt = props.node.data.file_ext || 'pdf';
      } else {
        fileExt = props.node.data.model_ext;
      }
      if (fileExt === 'pdf' || fileExt === 'dwf' || fileExt === 'dwfx') {
        let key = AppAPI.DMSRFAAPI.rfaPath(
          organizationId,
          projectId,
          props.node.parent.data.ref_number,
          props.node.data.rev,
          type,
          props.node.data.dms_main_id + '.' + fileExt
        );
        key = btoa(key);
        const url = `${window.location.origin}/organization/${organizationId}/project/${projectId}/dms/viewer?key=${key}&type=${fileExt}`;
        window.open(url);
      } else {
        message.warning('Only pdf, dwf and dwfx are able to view');
      }
    },
    [organizationId, projectId]
  );
  return (
    <>
      {props.node.level <= 2 ? (
        <div>
          <span style={{ fontWeight: 'bold' }}>{cellValue}</span>
        </div>
      ) : (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            {`${props.node.parent.data.category}/${props.node.parent.data.discipline}/${cellValue} : ${props.node.data.rev}`}
          </span>
          <div style={{ position: 'absolute', right: 10 }}>
            <div style={{ display: 'flex', gap: 2 }}>
              <IconButton
                icon={'file'}
                size='sssmall'
                ghost={true}
                border={false}
                onClick={handleViewFile.bind(this, 'pdf')}
              />
              {props.node.data.model_ext && (
                <IconButton
                  icon={'three_d'}
                  size='sssmall'
                  ghost={true}
                  border={false}
                  onClick={handleViewFile.bind(this, 'model')}
                  iconStyle={{ webkitMaskSize: 20 }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
