/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
    message,
    Input, DatePicker, Select, Radio
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { DMS_STATUS } from '../../dms/constant';
import { AppAPI } from '../../../../../api';
import moment from 'moment';
import { generateRFARevRow } from '../utils/RevUtils';




export default function ModalDMSRFAValidate() {
    const { setIsOpenRFAValidate, selectedDraingNodeValidate, selectedRFAValidateConsultant } = dmsStore()
    const { setIsLoading, } = appStore()
    const [data, setData] = useState({
        status: '',
        comment: '',
        date: null
    })



    const close = () => {
        setIsOpenRFAValidate()
    }

    const handleOk = async () => {
        if (!data.status) {
            message.warning('Please select status')
            return
        }
        setIsLoading(true)
        try {
            setIsLoading(true)
            data.date = moment(new Date())
            data.companyId = selectedRFAValidateConsultant.companyId
            await AppAPI.DMSRFAAPI.UpdateDMSRFARev({
                id: selectedDraingNodeValidate.data.dms_rfa_rev_id,
                data
            })
            const parentConsultant = {}
            _.forEach(selectedDraingNodeValidate.data, (v, k) => {
                if (k.includes('consultant_')) {
                    if (v.companyId === data.companyId) {
                        selectedDraingNodeValidate.data[k] =
                        {
                            ...selectedDraingNodeValidate.data[k],
                            ...{ status: data.status, date: data.date }
                        }
                    }
                    // if (!parentConsultant[k]) {
                    //     parentConsultant[k] = {}
                    // }
                    // if (!parentConsultant[k][selectedDraingNodeValidate.data[k].status]) {
                    //     parentConsultant[k][selectedDraingNodeValidate.data[k].status] = 0
                    // }
                    // parentConsultant[k][selectedDraingNodeValidate.data[k].status]++
                }
            })
            selectedDraingNodeValidate.setData({ ...selectedDraingNodeValidate.data })
            if (selectedDraingNodeValidate.parent.childStore.allRowNodes
                && selectedDraingNodeValidate.parent.childStore.allRowNodes.length !== 0) {
                const parentConsultant = generateRFARevRow(selectedDraingNodeValidate.parent.childStore.allRowNodes)
                selectedDraingNodeValidate.parent.setData({ ...selectedDraingNodeValidate.parent.data, ...parentConsultant })
            }



            setIsLoading(false)
            close()
        } catch (ex) {
            setIsLoading(false)
            message.warning(ex.message)
            setIsLoading(false)
        }
    }


    const handleChange = (type, e) => {
        const clone = { ...data }
        clone[type] = e.target.value
        setData(clone)
    }
    const handleChangeStatus = (type, e) => {
        const clone = { ...data }
        clone[type] = e
        setData(clone)
    }
    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Validate RFA'}
                style={{ width: '400px' }}
            >

                <div style={{ display: 'flex', gap: 15, flexDirection: 'column', width: '100%' }} >

                    <div style={{ display: 'flex', gap: 5, alignItems: 'center', width: '100%' }} >
                        <span style={{ fontWeight: 'bold', width: 135 }}>Status:</span>
                        <Select placeholder='Consultant' onChange={handleChangeStatus.bind(this, 'status')} style={{ width: '100%' }}
                            value={data.status}
                        >
                            {DMS_STATUS.map(v =>
                                <Select.Option value={v.id} > {v.name} </Select.Option>

                            )}
                        </Select>
                    </div>

                    <div style={{ display: 'flex', gap: 5, alignItems: 'center', width: '100%' }} >
                        <span style={{ fontWeight: 'bold', width: 135 }}>Email content:</span>
                        <Input.TextArea placeholder='Please input' onChange={handleChange.bind(this, 'comment')}
                            style={{ width: '100%' }}
                            value={data.comment} />
                    </div>


                </div>

            </ModalTemplate >
        </>
    );
}

