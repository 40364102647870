import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import { dmsStore } from "../../../../../../../store/DMS.store";
import FileBrowserCell from "../../../../../../ag_grid_components/cell/FileBrowser.cell";
import SizeCell from "../../../../../../ag_grid_components/cell/Size.cell";
import TagCell from "../../../../../../ag_grid_components/cell/Tag.cell";
import DateCell from "../../../../../../ag_grid_components/cell/Date.cell";
import { AppAPI } from "../../../../../../api";
import ActionButtonCell from "../cell/ActionButton.cell";

export default function DMSDocShareTable() {
  const [search] = useSearchParams();
  const { setDMSDocGridApi, setFileDMSDocNode } = dmsStore()
  const folderId = search.get("folderId");



  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Files",
      field: "name",
      // minWidth: 330,
      cellRendererParams: {
        // checkbox: true,
        suppressCount: true,
        innerRenderer: FileBrowserCell,
      },
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: "agSetColumnFilter",
    };
  }, []);

  const columnDefs = [
    {
      field: "size",
      headerName: "Size",
      width: 100,
      maxWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        const isFolder = params.data.isFolder;
        if (!isGroup && !isFolder) {
          return {
            component: SizeCell,
          };
        }
        return undefined;
      },
    },
    {
      field: "current_version",
      headerName: "Version",
      minWidth: 100,
      maxWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        const isFolder = params.data.is_folder;
        if (!isGroup && !isFolder) {
          return {
            component: TagCell,
            params: {
              onClick: handleVersion,
              tagStyle: { backgroundColor: '#2f465e', color: 'white' }
            },
          };
        }
        return undefined;
      },
      cellStyle: { textAlign: "center" },
    },

    {
      field: "updated_at",
      headerName: "Date",
      width: 95,
      maxWidth: 95,
      cellRendererSelector: (params) => {
        return {
          component: DateCell,
        };
      },
    },

    {
      field: "updated_by",
      headerName: "By",
      width: 100,
      maxWidth: 180,
    },
    {
      headerName: "",
      field: "action",
      minWidth: 50,
      maxWidth: 50,
      lockPosition: "right",
      suppressMovable: true,
      suppressToolPanel: true,
      filter: false,
      cellRendererSelector: (params) => {
        return {
          component: ActionButtonCell,
        };
      }
    },
  ];



  const handleVersion = (e) => {
    setFileDMSDocNode(e)
  };

  const runRequest = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await AppAPI.DMSDocAPI.ShareGetChidById({
          parentId: id,
        });
        if (data) resolve(data);
        else {
          reject();
        }
      } catch {
        reject();
      }
    });
  };
  const onGridReady = useCallback((params) => {
    setDMSDocGridApi(params)
    var datasource = {
      getRows: async (params) => {
        // console.log('ServerSideDatasource.getRows: params = ', params);
        const request = params.request;
        const groupKeys =
          params.parentNode.level === -1
            ? [folderId]
            : params.request.groupKeys;
        try {
          if (groupKeys.length !== 0) {
            let _id = groupKeys[groupKeys.length - 1];
            const allRows = await runRequest(_id);
            const doingInfinite =
              request.startRow != null && request.endRow != null;
            const result = doingInfinite
              ? {
                rowData: allRows.slice(request.startRow, request.endRow),
                rowCount: allRows.length,
              }
              : { rowData: allRows };
            params.success(result);
          }
        } catch {
          params.fail();
        }
      },
    };
    params.api.setServerSideDatasource(datasource);
  }, []);

  const getDataPath = useMemo(() => {
    return (data) => data.path;
  }, []);
  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);
  const isServerSideGroup = useCallback((dataItem) => {
    return dataItem.is_folder;
  }, []);
  const getServerSideGroupKey = useCallback((dataItem) => {
    return dataItem.id;
  }, []);
  const isServerSideGroupOpenByDefault = useCallback((params) => {
    return params.rowNode.level < 2;
  }, []);
  return (
    <>
      {folderId && <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          treeData={true}
          rowHeight={35}
          onGridReady={onGridReady}
          suppressContextMenu={true}
          getDataPath={getDataPath}
          getRowId={getRowId}
          masterDetail={true}
          isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
          isServerSideGroup={isServerSideGroup}
          getServerSideGroupKey={getServerSideGroupKey}
          rowModelType={"serverSide"}
        />

      </div>}


    </>

  );
}


