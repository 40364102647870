import React, { useEffect, useState } from 'react';
import Sketch from '@uiw/react-color-sketch';
import { Popover, Select } from 'antd';
import { timelineStore } from '../Timeline.store';

export default function CellSelectColor(props) {

  //   const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  //   const [color, setColor] = useState(cellValue);
  //   const { appearanceData } = timelineStore();
  return (
    // <Select style={{width:'100%'}} value={cellValue}  >
    //   {appearanceData.map((v) => (
    //     <Select.Option value={v.id} >{v.name}</Select.Option>
    //   ))}
    // </Select>
    <div style={{ display: 'flex', alignItems:'center', gap: 5 }}>
      <div
        style={{ width: 10, height: 10, backgroundColor: props.value.color }}
      ></div>
      <span>{props.value.name}</span>
    </div>
  );
}
