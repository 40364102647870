import React from 'react';
import { fiveDStore } from '../../../../../store/FiveD.store';
import IconButton from '../../../../gen/button/IconButton.gen';
import IconStatic from '../../../../gen/icon/IconStatic.gen';
import _ from 'lodash';
import { appStore } from '../../../../../store/App.store';
import { AppAPI } from '../../../../api';
import { message } from 'antd';
export default function ListSheet() {
  const {
    listSheet,
    setCurrentEditSheet,
    setListSheet,
    setSelectedSheet,
    selectedSheet,
  } = fiveDStore();
  const { setIsLoading } = appStore();
  const handleEditSheet = (item) => {
    setCurrentEditSheet(item);
  };
  const handleDeleteSheet = async (item) => {
    if (!window.confirm('Are you sure to delete?')) return;
    try {
      debugger;
      setIsLoading(true);
      await AppAPI.FiveDSheetAPI.DeleteSheet({
        id: item.id,
      });
      if (selectedSheet?.id === item.id) {
        setSelectedSheet(null);
      }
      const index = _.findIndex(listSheet, (v) => v.id === item.id);
      if (index >= 0) {
        listSheet.splice(index, 1);
        setListSheet([...listSheet]);
      }
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };
  const handleViewSheet = (item) => {
    setSelectedSheet(item);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {listSheet.map((v) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <IconStatic icon={'file'} size='small' />
            <span>{v.name}</span>
          </div>
          <div style={{ display: 'flex', gap: 1 }}>
            <IconButton
              icon='view'
              size='sssmall'
              onClick={handleViewSheet.bind(this, v)}
            />
            <IconButton
              icon='edit'
              size='sssmall'
              onClick={handleEditSheet.bind(this, v)}
            />
            <IconButton
              type='delete'
              icon='delete'
              size='sssmall'
              onClick={handleDeleteSheet.bind(this, v)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
