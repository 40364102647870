import CheckboxForm from 'src/components/atoms/CheckboxForm';

/**
 * RememberCheckbox component renders a checkbox with a label for "Remember me".
 *
 * @returns {JSX.Element} The rendered checkbox and label elements.
 */
const RememberCheckbox = () => {
  return (
    <div className='flex items-start'>
      <div className='flex h-5 items-center'>
        <CheckboxForm name='remember' />
      </div>
      <div className='ml-3 text-sm'>
        <label htmlFor='remember' className='text-gray-500 dark:text-gray-300'>
          Remember me
        </label>
      </div>
    </div>
  );
};

export default RememberCheckbox;
