import axios from 'axios';
import hello from 'hellojs';
import decode from 'jwt-decode';
import _ from 'lodash';
import { appStore } from 'src/store/App.store';
import { projectStore } from 'src/store/Docs.store';
import { requestBimGrid } from './General.function';

export const validateToken = async (token) => {
  return new Promise(async (resolve) => {
    try {
      let res = await axios({
        method: 'post',
        url: '/api/validate',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (ex) {
      console.log(ex);
      resolve(false);
    }
  });
};

export const getForgeTokenInternal = () => {
  return new Promise(async (resolve, reject) => {
    let data = await requestBimGrid('get', '/api/forge/oauth/token-internal');
    if (data) {
      resolve(data);
    } else {
      reject();
    }
  });
};
export const getForgeTokenViewer = async (callback) => {
  let data = await requestBimGrid('get', '/api/forge/oauth/token');
  if (data) {
    callback(data.access_token, data.expires_in);
  }
};
export const getForgeToken = async (callback) => {
  return new Promise(async (resolve, reject) => {
    let data = await requestBimGrid('get', '/api/forge/oauth/token');
    if (data) {
      resolve(data);
    } else {
      reject();
    }
  });
};
export const initMicrosoft = () => {
  window.hello = hello.init({
    aad: {
      name: 'Azure Active Directory',
      oauth: {
        version: 2,
        auth: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      },
      form: false,
    },
  });
};

export function handleErrAuthMicrosoft(err, token) {
  if (err.statusCode === 401 && err.message === 'Access token has expired.') {
    localStorage.removeItem('hello');
    if (token) {
      const payload = decode(token);
      localStorage.removeItem('bimGridToken');
      // socket.emit('leave', payload.email);
    }
  }
}

export function handleLoginMs() {
  window.hello.init(
    {
      aad: '98a413f3-afbe-45f3-b1ba-b039699af8ae',
    },
    {
      redirect_uri: process.env.PUBLIC_URL,
      scope: 'user.read',
    }
  );
  window.hello.login(
    'aad',
    {
      display: 'popup',
      scope: 'user.read',
    },
    () => {
      window.location.href = window.location.origin;
    }
  );
}

export const validatePermission = (
  permission,
  requireActions = [],
  containActions = [],
  _organizationId = null,
  _projectId = null
) => {
  const store = appStore.getState();
  const projectStr = projectStore.getState();
  const organizationId = _organizationId ?? store.organization?.id;
  const projectId = _projectId ?? projectStr?.id;
  if (store.organization) {
    if (store.organization.owner_id === store.user.id) {
      return { allowed: true, permissionsMissing: [] };
    }
  }
  if (requireActions?.length === 0 && containActions?.length === 0) {
    return { allowed: true, permissionsMissing: [] };
  }
  const permissionsMissing = [];
  _.forEach(requireActions, (action) => {
    const index = _.findIndex(permission, (v) => {
      if (
        organizationId === v.organization_id &&
        projectId === v.project_id &&
        v.permission_name === action
      ) {
        return true;
      }
      return false;
    });
    if (index < 0) {
      permissionsMissing.push(action.toUpperCase());
    }
  });
  if (permissionsMissing.length !== 0) {
    return {
      allowed: false,
      permissionsMissing: permissionsMissing.concat(containActions),
    };
  }

  if (containActions.length !== 0) {
    let allowed = false;
    _.forEach(containActions, (action) => {
      const index = _.findIndex(permission, (v) => {
        if (
          organizationId === v.organization_id &&
          projectId === v.project_id &&
          v.permission_name === action
        ) {
          return true;
        }
        return false;
      });
      if (index >= 0) {
        allowed = true;
        return false; //This for loop
      }
    });
    if (!allowed) {
      return {
        allowed,
        permissionsMissing: permissionsMissing.concat(containActions),
      };
    }
  }
  return {
    allowed: true,
    permissionsMissing: permissionsMissing.concat(containActions),
  };
};
