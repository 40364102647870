// import { Tabs } from 'flowbite-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabItem from 'src/components/atoms/TabItem';
import LayoutCompany from 'src/components/layout/admin/management/company/Company.layout';
import LayoutGroupPermission from 'src/components/layout/admin/management/group_permissison/GroupPermission.layout';
import LayoutRole from 'src/components/layout/admin/management/role/Role.layout';
import LayoutUser from 'src/components/layout/admin/management/user/User.layout';
import Tabs from 'src/components/molecules/Tabs';

export default function AdminManagementNav(props) {
  const [search, setSearchParams] = useSearchParams();
  const [type, setType] = useState(
    search.get('type') ? search.get('type') : 'user'
  );

  const handleChangeTab = (e) => {
    const newQueryParameters = new URLSearchParams();
    newQueryParameters.set('type', e);
    setSearchParams(newQueryParameters);
    setType(e);
  };

  return (
    <div
      style={{ width: '100%', padding: '0px 0px', height: 'calc(100% - 0px)' }}
    >
      <Tabs onTabChange={handleChangeTab} activeKey={type}>
        <TabItem title='User' key='user' />
        <TabItem title='Role' key='role' />
        <TabItem title='Group' key='group-permission' />
        <TabItem title='Company' key='company' />
      </Tabs>
      <div className='h-[calc(100%-55px)]'>
        {' '}
        {type === 'user' && <LayoutUser />}
        {type === 'role' && <LayoutRole />}
        {type === 'group-permission' && <LayoutGroupPermission />}
        {type === 'company' && <LayoutCompany />}
      </div>
    </div>
  );
}
