/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { AppAPI } from '../../../../../api';
import {
  camelToNormal,
  camelToSnakeCase,
  generateS3Path,
  getRouteToNode,
  updateTableAsync,
} from '../../../../../../functions/General.function';
import SubmitForm from '../components/SubmitForm';
import DMSMainTable from '../table/DMSMain.table';
import DMSDocTable from '../table/DMSDoc.table';
import { eachLimit } from 'async';
import { S3_BASED_PATH } from '../../../../../../config';
import moment from 'moment';

export default function ModalDMSRFAAddRFA() {
  const {
    setIsModalDMSRFAAddRFA,
    addDMSRFAParentNode,
    dmsRFAGridApi,
    setDrawingList,
    selectedDrawingNode,
    selectedDrawingCol,
    drawingList,
    dmsRFADrawingListGridApi,
    setIsOpenMainTable,
    isOpenMainTable,
    dmsRFAMainGridApi,
    isOpenDocTable,
    setIsOpenDocTable,
    dmsRFADocGridApi,
    setCategories,
    setDisciplines,
  } = dmsStore();
  const { setIsLoading } = appStore();
  const { projectId, organizationId } = useParams();
  const [data, setData] = useState({
    category: '',
    discipline: '',
    refNumber: '',
    submissionDate: moment(new Date()),
    dueDate: moment(new Date()).add(7, 'days'),
    requestedBy: '',
    subject: '',
    content: '',
    dmsGroupConsultantIds: [],
  });
  const [done, setDone] = useState(0);

  useEffect(() => {
    run();
    return () => {};
  }, []);
  const run = async () => {
    try {
      const rfaCategories =
        await AppAPI.DMSRFAAPI.GetDMSRFACategoryWDiscipline();
      const categories = [];
      const disciplines = [];
      _.forEach(rfaCategories, (v) => {
        categories.push(v.category);
        disciplines.push(v.discipline);
      });
      setCategories(_.uniq(categories));
      setDisciplines(_.uniq(disciplines));
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
  };
  const close = () => {
    if (isOpenMainTable) {
      setIsOpenMainTable(false);
    } else if (isOpenDocTable) {
      setIsOpenDocTable(false);
    } else {
      setIsModalDMSRFAAddRFA(false);
      setDrawingList([]);
    }
  };

  const handleOk = async (e) => {
    if (isOpenMainTable) {
      const nodes = dmsRFAMainGridApi.api.getSelectedNodes();
      const tmp = [];
      _.forEach(nodes, (node) => {
        const index = _.findIndex(drawingList, (v) => {
          return v.id === node.data.id;
        });
        if (index < 0) {
          tmp.push(node.data);
        }
      });
      setDrawingList([...drawingList, ...tmp]);
      setIsOpenMainTable(false);
    } else if (isOpenDocTable) {
      debugger;
      let nodes = dmsRFADocGridApi.api.getSelectedNodes();
      if (nodes.length !== 0) {
        selectedDrawingNode.data[selectedDrawingCol] = nodes[0].data;
        await updateTableAsync(dmsRFADrawingListGridApi.api, {
          update: [selectedDrawingNode.data],
        });
        dmsRFADrawingListGridApi.api.redrawRows();
        setIsOpenDocTable(false);
      } else {
        message.warning('Please select a file');
      }
    } else {
      handleSumbit();
    }
  };
  const checkInput = (array) => {
    let result = true;
    _.forEach(array, (v) => {
      if (!data[v]) {
        message.warning(`Please input ${camelToNormal(v).toLowerCase()}`);
        result = false;
        return false;
      }
    });
    return result;
  };
  const handleSumbit = async () => {
    if (
      !checkInput([
        'refNumber',
        'category',
        'discipline',
        'submissionDate',
        'dueDate',
        'requestedBy',
      ])
    ) {
      return;
    }
    setIsLoading(true);
    try {
      data.refNumber = _.toNumber(data.refNumber);
      await AppAPI.DMSRFAAPI.CheckDMSRFAByRefNumber({
        refNumber: data.refNumber,
        category: data.category,
        discipline: data.discipline,
      });

      const files = [];
      const drawings = [];
      let isError = false;
      dmsRFADrawingListGridApi.api.forEachLeafNode(async (node) => {
        let fileExt = null;
        let modelExt = null;
        if (node.data.pdf) {
          const split = node.data.pdf.name?.toLowerCase().split('.');
          fileExt = split[split.length - 1];
          const name = `${node.data.id}.${fileExt}`;
          if (node.data.pdf.current_version) {
            const to = AppAPI.DMSRFAAPI.rfaPath(
              organizationId,
              projectId,
              data.refNumber,
              0,
              'pdf',
              name
            );
            const from = AppAPI.DMSDocAPI.docPath(
              organizationId,
              projectId,
              node.data.pdf.path,
              node.data.pdf.id,
              node.data.pdf.current_version,
              node.data.pdf.name
            );
            files.push(AppAPI.S3API.CopyLinkToDes({ to, from }));
          } else {
            const key = AppAPI.DMSRFAAPI.rfaPath(
              organizationId,
              projectId,
              data.refNumber,
              0,
              'pdf',
              name
            );
            files.push(
              AppAPI.S3API.UploadPutLink(key, node.data.pdf.type, node.data.pdf)
            );
          }
        } else {
          isError = true;
        }
        if (node.data.model) {
          const split = node.data.model.name?.toLowerCase().split('.');
          const modelExt = split[split.length - 1];
          const name = `${node.data.id}.${modelExt}`;
          if (node.data.model.current_version) {
            const to = AppAPI.DMSRFAAPI.rfaPath(
              organizationId,
              projectId,
              data.refNumber,
              0,
              'model',
              name
            );
            const from = AppAPI.DMSDocAPI.docPath(
              organizationId,
              projectId,
              node.data.model.path,
              node.data.pdf.id,
              node.data.model.current_version,
              node.data.model.name
            );
            files.push(AppAPI.S3API.CopyLinkToDes({ to, from }));
          } else {
            const key = AppAPI.DMSRFAAPI.rfaPath(
              organizationId,
              projectId,
              data.refNumber,
              0,
              'model',
              name
            );
            files.push(
              AppAPI.S3API.UploadPutLink(
                key,
                node.data.model.type,
                node.data.model.model
              )
            );
          }
        }
        drawings.push({ id: node.data.id, fileExt, modelExt });
      });
      if (isError) {
        throw new Error('You have to fill all drawing with a file');
      }
      await upload(files);

      const tmp = {};
      _.forEach(data, (v, k) => {
        const name = camelToSnakeCase(k);
        tmp[name] = v;
      });

      const rfaData = await AppAPI.DMSRFAAPI.CreateDMSRFA({
        data: { ...tmp, rev: 0 },
        projectId,
        drawings,
      });

      let rowNode = null;
      dmsRFAGridApi.api.forEachNode((node) => {
        if (
          node.data.category === data.category &&
          node.data.discipline === data.discipline &&
          node.level === 2
        ) {
          rowNode = node;
        }
      });
      if (rowNode) {
        dmsRFAGridApi.api.applyServerSideTransaction({
          route: rowNode.parent.getRoute(),
          add: [rfaData],
        });
      } else {
        dmsRFAGridApi.api.applyServerSideTransaction({
          add: [rfaData],
        });
      }

      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };

  const upload = (array) => {
    return new Promise((resolve, reject) => {
      eachLimit(
        array,
        5,
        (task, callback) => {
          task.then(
            (res) => {
              setDone((prev) => {
                prev++;
                return prev;
              });
              callback();
            },
            (err) => {
              console.log(err);
              callback(err);
            }
          );
        },
        async (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Add RFA'}
        style={{ width: '80%' }}
      >
        <div
          style={{
            display: !isOpenMainTable && !isOpenDocTable ? 'block' : 'none',
          }}
        >
          <SubmitForm data={data} setData={setData} isUpload={true} />
        </div>
        {isOpenDocTable && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              Please select a file from Document
            </span>
            <DMSDocTable />
          </div>
        )}
        {isOpenMainTable && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              Please select drawings from DMS
            </span>
            <DMSMainTable />
          </div>
        )}
      </ModalTemplate>
    </>
  );
}
