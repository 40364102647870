import React from 'react';
import VRLayout from '../../../layout/apps/vdc/vr/VR.layout';

export default function VRPage() {
  return (
    <>
      <VRLayout />
    </>
  );
}
