import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import ExtractQuantityGanttToolbar from './ExtractQuantityGantt.toolbar';
import { extractQuantityGanttStore } from './ExtractQuantityGantt.store';
import {
  calculateSummaryProgress,
  createActualDatePickerType,
  createInputType,
  hourRangeFormat,
  numberWithCommas,
  refreshSummaryProgress,
} from '../../util/Gantt.util';
// var  {gantt} =require('../../../../third-party/gantt/dhtmlxgantt.js')

const gantt = window.gantt;
const Gantt = window.Gantt;



export default function ExtractQuantityGantt() {
  const ref = useRef();
  const { setGantt5D } = extractQuantityGanttStore();


  useEffect(() => {
    if (ref.current) {
      const ganttInstance = Gantt.getGanttInstance();
      // gantt.config.row_height = 24;
      ganttInstance.config.grid_resize = true;
      ganttInstance.config.wide_form = false;
      // gantt.config.auto_types = true;
      // gantt.config.date_format = "%Y-%m-%d %H:%i";
      ganttInstance.plugins({
        // fullscreen: true,
        drag_timeline: true,
        auto_scheduling: true,
        undo: true,
        tooltip: true,
        marker: true,
        critical_path: true,
        grouping: true,
      });
      // ganttInstance.config.highlight_critical_path = true;
      createInputType(ganttInstance);
      createActualDatePickerType(ganttInstance);
      var zoomConfig = {
        levels: [
          [
            { unit: 'month', format: '%M %Y', step: 1 },
            {
              unit: 'week',
              step: 1,
              format: function (date) {
                var dateToStr = ganttInstance.date.date_to_str('%d %M');
                var endDate = ganttInstance.date.add(date, -6, 'day');
                var weekNum = ganttInstance.date.date_to_str('%W')(date);
                return (
                  'Week #' +
                  weekNum +
                  ', ' +
                  dateToStr(date) +
                  ' - ' +
                  dateToStr(endDate)
                );
              },
            },
          ],
          [
            { unit: 'month', format: '%M %Y', step: 1 },
            { unit: 'day', format: '%d %M', step: 1 },
          ],
          [
            { unit: 'day', format: '%d %M', step: 1 },
            { unit: 'hour', format: hourRangeFormat(12), step: 12 },
          ],
          [
            { unit: 'day', format: '%d %M', step: 1 },
            { unit: 'hour', format: hourRangeFormat(6), step: 6 },
          ],
          [
            { unit: 'day', format: '%d %M', step: 1 },
            { unit: 'hour', format: '%H:%i', step: 1 },
          ],
        ],
        // startDate: new Date(2018, 02, 27),
        // endDate: new Date(2018, 03, 20),
        useKey: 'ctrlKey',
        trigger: 'wheel',
        element: function () {
          return ganttInstance.$root.querySelector('.gantt_task');
        },
      };

      ganttInstance.config.columns = [
        {
          name: 'text',
          label: 'Task name',
          tree: true,
          width: 200,
          min_width: 200,
          resize: true,
        },
        {
          name: 'start_date',
          label: 'Plan Start',
          width: 100,
          align: 'center',
          resize: true,
        },
        {
          name: 'end_date',
          label: 'Plan End',
          width: 100,
          align: 'center',
          resize: true,
        },
        {
          name: 'actual_start',
          label: 'Actual Start',
          width: 100,
          align: 'center',
          resize: true,
          template: function (item) {
            return item?.actual_start;
          },
        },
        {
          name: 'actual_end',
          label: 'Actual End',
          width: 100,
          align: 'center',
          resize: true,
        },
        // {
        //   name: "duration", label: "Duration", width: 70, align: "center", resize: true,
        // },
        {
          name: 'material_cost',
          label: 'Material Cost',
          width: 100,
          align: 'center',
          resize: true,
          template: function (item) {
            if (item.$group_id) {
              let material_cost = 0;
              let labor_cost = 0;
              let equipment_cost = 0;
              ganttInstance.eachTask(
                (task) => {
                  material_cost =
                    material_cost + task.volume * task.material_price;
                  labor_cost = labor_cost + task.labor_cost;
                  equipment_cost = equipment_cost + task.equipment_cost;
                },
                [item.id]
              );
              item.material_cost = material_cost;
              item.labor_cost = labor_cost;
              item.equipment_cost = equipment_cost;
              return material_cost?.toFixed(0);
            }
            item.material_cost = item.volume * item.material_price;
            return numberWithCommas(item.material_cost.toFixed(0));
          },
        },
        {
          name: 'labor_cost',
          label: 'Labor Cost',
          width: 100,
          align: 'center',
          resize: true,
          template: function (item) {
            return numberWithCommas(item?.labor_cost?.toFixed(0));
          },
        },
        {
          name: 'equipment_cost',
          label: 'Equipment Cost',
          width: 100,
          align: 'center',
          resize: true,
          template: function (item) {
            return numberWithCommas(item?.equipment_cost?.toFixed(0));
          },
        },
        {
          name: 'total_cost',
          label: 'Total Cost',
          width: 100,
          align: 'center',
          resize: true,
          template: function (item) {
            item.total_cost =
              item.labor_cost + item.equipment_cost + item.material_cost;
            return numberWithCommas(item.total_cost.toFixed(0));
          },
        },
        { name: 'add', width: 40 },
      ];
      ganttInstance.locale.labels.section_actual_start = 'Actual Start';
      ganttInstance.locale.labels.section_material_price = 'Material Price';
      ganttInstance.locale.labels.section_labor_cost = 'Labor Cost';
      ganttInstance.locale.labels.section_equipment_cost = 'Equipment Cost';
      ganttInstance.locale.labels.section_volume = 'Volume';
      ganttInstance.config.lightbox.sections = [
        {
          name: 'description',
          height: 38,
          map_to: 'text',
          type: 'textarea',
          focus: true,
        },
        {
          name: 'material_price',
          height: 38,
          map_to: 'material_price',
          type: 'input',
          default_value: 0,
        },
        {
          name: 'volume',
          height: 38,
          map_to: 'volume',
          type: 'input',
          readonly: true,
        },
        {
          name: 'labor_cost',
          height: 38,
          map_to: 'labor_cost',
          type: 'input',
          default_value: 0,
        },
        {
          name: 'equipment_cost',
          height: 38,
          map_to: 'equipment_cost',
          type: 'input',
          default_value: 0,
        },
        {
          name: 'actual_start',
          type: 'actual-datepicker',
          height: 38,
          time_format: ['%d', '%m', '%Y'],
          default_value: undefined,
        },
        {
          name: 'time',
          type: 'time',
          map_to: 'auto',
          time_format: ['%d', '%m', '%Y'],
        },
      ];
      ganttInstance.templates.progress_text = function (start, end, task) {
        return (
          "<span style='text-align:left;'>" +
          Math.round(task.progress * 100) +
          '% </span>'
        );
      };

      ganttInstance.ext.zoom.init(zoomConfig);
      ganttInstance.init(ref.current);

      ganttInstance.attachEvent('onAfterTaskUpdate', function (id) {
        refreshSummaryProgress(ganttInstance.getParent(id), true, ganttInstance);
      });

      ganttInstance.attachEvent('onTaskDrag', function (id) {
        refreshSummaryProgress(ganttInstance.getParent(id), false, ganttInstance);
      });
      ganttInstance.attachEvent('onAfterTaskAdd', function (id) {
        refreshSummaryProgress(ganttInstance.getParent(id), true, ganttInstance);
      });

      ganttInstance.attachEvent('onParse', function () {
        ganttInstance.eachTask(function (task) {
          task.progress = calculateSummaryProgress(ganttInstance, task);
        });
      });

      var idParentBeforeDeleteTask = 0;
      ganttInstance.attachEvent('onBeforeTaskDelete', function (id) {
        idParentBeforeDeleteTask = ganttInstance.getParent(id);
      });
      ganttInstance.attachEvent('onAfterTaskDelete', function () {
        refreshSummaryProgress(idParentBeforeDeleteTask, true);
      });

      setGantt5D(ganttInstance);
      return () => {
        // let gantt = Gantt.getGanttInstance();

        ganttInstance.destructor();
      };
    }
  }, [ref.current]);

  return (
    <>
      <ExtractQuantityGanttToolbar />
      <div style={{ height: 'calc(100% - 50px)' }}>
        <div style={{ height: '100%', width: '100%' }} ref={ref}></div>
      </div>
    </>
  );
}
