import create from 'zustand';

const initialState = {
  tabName: 'Accounts',
};
export const userSettingStore = create((set) => ({
  ...initialState,
  setTabName: (value) => set((state) => ({ tabName: value })),
  resetUserSettingStore: () => set(initialState),
}));
