import React, { useCallback, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { appStore } from '../../../../../../store/App.store';
import { message } from 'antd';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { workerStore } from '../../../../../../store/Worker.store';
import { AppAPI } from '../../../../../api';
import { useParams } from 'react-router-dom';
import DateCellEdit from '../cell/DateEdit.cell';
import PayRateActionCell from '../cell/PayRateAction.cell';
import PayRateEditCell from '../cell/PayRateEdit.cell';

export default function PayRateTable() {
  const { setIsLoading } = appStore();
  const {
    setPayRateGridApi,
    setPayRateEditData,
    setDataPayRate,
    dataPayRate,
    nodeEditWorkerList,
  } = workerStore();
  const gridRef = useRef();
  const { projectId } = useParams();
  useEffect(() => {
    run();
    return () => {
      setPayRateEditData();
    };
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      const payRateRes =
        await AppAPI.WorkerPayRateAPI.GetAllWorkerPayRateByWorkerId({
          id: nodeEditWorkerList.data.id,
        });
      setDataPayRate(payRateRes);
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      headerName: 'Pay rate',
      field: 'pay_rate',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: PayRateEditCell,
        };
      },
    },
    {
      headerName: 'Start',
      field: 'start_date',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: DateCellEdit,
        };
      },
    },
    {
      headerName: 'End',
      field: 'end_date',
      filter: 'agSetColumnFilter',
      width: 100,
      minWidth: 100,
      cellRendererSelector: (params) => {
        return {
          component: DateCellEdit,
        };
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      suppressMenu: true,
      suppressSorting: true,
      minWidth: 80,
      maxWidth: 80,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: PayRateActionCell,
          };
        }
        return undefined;
      },
    },
  ];

  const onGridReady = useCallback((params) => {
    setPayRateGridApi(params);
  }, []);

  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={dataPayRate}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDisplayType='multipleColumns'
          onGridReady={onGridReady}
          suppressPaginationPanel={true}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </>
  );
}
