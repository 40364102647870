/* eslint-disable no-unreachable */
import _ from 'lodash';
import {
  getElementProperty,
  getProperties,
} from '../../../../functions/Viewer.function';
import { EventDispatcher } from '../extensions/utils/EventDispatcher';
import eachLimit from 'async/eachLimit';

const listExcludes = [
  'viewable_in',
  'parent',
  'ElementId',
  'CategoryId',
  'Coarse Scale Fill Color',
  'Coarse Scale Fill Pattern',
];

export class TreeModelData extends EventDispatcher {
  data = [];
  modelMapping = {};
  progress = {
    pending: [],
    loading: [],
  };
  concurrencyLimit = 100;
  events = {
    done: 'done',
    add: 'add',
    remove: 'remove',
  };
  categories = [];
  parameters = [];
  levels = new Map();
  constructor() {
    super();
  }
  selector = {};
  loadModel = (model) => {
    const instanceTree = model.getData().instanceTree;
    let queue = [];
    let nodeElementData = new NodeElementData(
      instanceTree.getRootId(),
      model.id,
      model,
      null
    );
    queue.push(nodeElementData);
    while (queue.length > 0) {
      const node = queue.shift();
      if (instanceTree.getChildCount(node.dbId) !== 0) {
        instanceTree.enumNodeChildren(node.dbId, function (childrenIds) {
          const childNodeElementData = new NodeElementData(
            childrenIds,
            model.id,
            model,
            node
          );
          node.children.push(childNodeElementData);
          childNodeElementData.depth = node.depth + 1;
          childNodeElementData.parentIds = `${
            node.parentIds ? node.parentIds + '_' : ''
          }${node.dbId}`;
          queue.push(childNodeElementData);
        });
      } else {
        node.isLeaf = true;
        if (!this.selector[model.id]) {
          this.selector[model.id] = [];
        }
        this.selector[model.id].push(node.dbId);
      }
    }
    return nodeElementData;
  };
  load = (event, nodeData) => {
    this.progress.loading = this.progress.pending;
    this.progress.pending = [];
    eachLimit(
      this.progress.loading,
      this.concurrencyLimit,
      async (node, next) => {
        const items = await getElementProperty(
          this.modelMapping[node.modelId],
          [node.dbId]
        );
        // if (node.depth === 4) {
        //     console.log(items)
        // }
        const item = items[0];
        node.isLoaded = true;
        if (node.level) {
          if (!this.levels[node.level]) {
            this.levels[node.level] = [];
          }
          this.levels[node.level].push(node.parentIds);
        }

        // node.properties = item.properties
        let parameters = node.setProperty(item);
        this.parameters = this.parameters.concat(parameters);
        this.categories.push(node.category);
        for (let child of node.children) {
          this.progress.pending.push(child);
        }
        return true;
      },
      () => {
        if (this.progress.pending.length === 0) {
          this.progress.loading = [];
          this.parameters = [...new Set(this.parameters)];
          this.categories = [...new Set(this.categories)];
          debugger;
          if (!event) {
            this.dispatchEvent({ type: this.events.done });
          } else {
            this.dispatchEvent({ type: event, nodeData });
          }
        } else {
          this.load();
        }
      }
    );
  };
  add = (models) => {
    let temp = [];
    let indices = [];
    _.forEach(models, (v) => {
      if (!this.modelMapping[v.id]) {
        temp.push(v);
        indices.push(v.id);
      }
    });
    let nodeElementData;
    _.forEach(temp, (model) => {
      nodeElementData = this.loadModel(model);
      this.modelMapping[model.id] = model;
      this.data.push(nodeElementData);
    });
    _.forEach(this.data, (node) => {
      if (!node.isLoaded) this.progress.pending.push(node);
    });
    this.dispatchEvent({ type: this.events.add, indices: indices });
    this.load(this.events.add, nodeElementData);
  };
  remove = (models) => {
    let temp = [];
    let indices = [];
    _.forEach(this.modelMapping, (item) => {
      let index = _.findIndex(models, (i) => {
        return item.id === i.id;
      });
      if (index < 0) {
        temp.push(index);
        indices.push(item.id);
      }
    });

    _.forEach(temp, (id) => {
      let index = _.findIndex(this.data, (v) => {
        return v.modelId === id;
      });
      if (index >= 0) {
        let node = this.data[index];
        this.destroyNode(node);
        delete this.modelMapping[id];
        delete this.selector[id];
      }
    });
    this.dispatchEvent({ type: this.events.remove, indices: indices });
  };
  destroySubTree = (node) => {
    const root = node;
    const stack = [];
    stack.push(root);
    while (stack.length > 0) {
      node = stack.pop();
      for (const child of node.children) {
        stack.push(child);
      }
      if (node !== root) {
        this.destroyNode(node);
      }
    }
    root.children = [];
  };
  destroyNode(node) {
    node.destroy();
  }

  isLoaded = () => {
    return (
      this.progress.pending.length === 0 && this.progress.loading.length === 0
    );
  };

  destroy = () => {
    this.data.forEach((node) => {
      this.destroySubTree(node);
    });
    this.data = [];
    this.modelMapping = {};
    this.categories = [];
    this.parameters = [];
    this.selector = [];
  };
  findNode = (externalId, root = this.data) => {
    let nodeNeed;
    _.forEach(root, (v) => {
      let node;
      const stack = [];
      stack.push(v);
      while (stack.length > 0) {
        node = stack.pop();
        for (const child of node.children) {
          stack.push(child);
        }
        if (externalId === node.externalId) {
          nodeNeed = node;
          break;
        }
      }
      if (nodeNeed) {
        return false;
      }
    });
    return nodeNeed;
  };
  findNodeByFileId = (externalId, fileId, root = this.data) => {
    let nodeNeed;
    _.forEach(root, (v) => {
      let node;
      const stack = [];
      stack.push(v);
      while (stack.length > 0) {
        node = stack.pop();
        for (const child of node.children) {
          stack.push(child);
        }
        if (
          externalId === node.externalId &&
          node.model.myData.loadOptions.fileId
        ) {
          nodeNeed = node;
          break;
        }
      }
      if (nodeNeed) {
        return false;
      }
    });
    return nodeNeed;
  };
  findNodeByModelId = (dbId, modelId, root = this.data[0]) => {
    let nodeNeed;
    _.forEach(root, (v) => {
      let node;
      const stack = [];
      stack.push(root);
      while (stack.length > 0) {
        node = stack.pop();
        for (const child of node.children) {
          stack.push(child);
        }
        if (dbId === node.dbId && modelId === node.modelId) {
          nodeNeed = node;
          break;
        }
      }
      if (nodeNeed) {
        return false;
      }
    });
    return nodeNeed;
  };

  findNodesByParameter = (parameterName, parameterValue, root = this.data) => {
    let nodeNeeds = [];
    _.forEach(root, (v) => {
      let node;
      let nodeNeed;
      const stack = [];
      stack.push(v);
      while (stack.length > 0) {
        node = stack.pop();
        for (const child of node.children) {
          stack.push(child);
        }
        _.forEach(node.properties, (v) => {
          if (
            v.displayName === parameterName &&
            v.displayValue === parameterValue
          ) {
            nodeNeed = node;
            return false;
          }
        });
        if (nodeNeed) {
          nodeNeeds.push(nodeNeed);
        }
      }
    });
    return nodeNeeds;
  };
  getLeafNodes = (root = this.data) => {
    let nodes = [];
    _.forEach(root, (v) => {
      let node;
      const stack = [];
      stack.push(v);
      while (stack.length > 0) {
        node = stack.pop();
        if (node.isLeaf) {
          nodes.push(node);
        }
        for (const child of node.children) {
          stack.push(child);
        }
      }
    });
    return nodes;
  };
  getLeafNodesByParameterName = (parameterName, root = this.data) => {
    let nodes = [];
    debugger;
    _.forEach(root, (v) => {
      let node;
      const stack = [];
      stack.push(v);
      while (stack.length > 0) {
        node = stack.pop();
        if (node.isLeaf) {
          _.forEach(node.properties, (v) => {
            if (v.displayName === parameterName && v.displayValue) {
              nodes.push(node);
              return false;
            }
          });
        } else {
          for (const child of node.children) {
            stack.push(child);
          }
        }
      }
    });
    return nodes;
  };

  hideAllModel = () => {};
}

export class NodeElementData {
  dbId;
  modelId;
  category;
  name;
  volume = 0;

  depth = 0;
  children = [];
  parent;
  isLoaded = false;
  isLeaf;
  parentIds = '';
  level = null;
  model = null;
  constructor(dbId, modelId, model, parent) {
    this.dbId = dbId;
    this.modelId = modelId;
    this.parent = parent;
    this.model = model;
  }

  setProperty = (item) => {
    this.properties = item.properties;
    this.externalId = item.externalId;
    // let split = item?.name?.split(' [')
    this.name = item.name;
    let parameters = [];
    this.id = `${this.modelId}-${this.dbId}`;
    _.forEach(this.properties, (v) => {
      if (v.displayName === 'Category') {
        this.category = v.displayValue;
      } else if (v.displayName === 'Volume') {
        this.volume = v.displayValue;
      } else if (v.displayName === 'Level') {
        this.level = v.displayValue;
      }
      parameters.push(v.displayName);
    });
    return [...new Set(parameters)];
  };
  setName = (input) => {
    // let regex = /[0-9]+/i
    // let result = input.match(regex);
    // if (result && result.length!==0)
    // this.elementId = input
  };
  getParameterValueByName = (name) => {
    const index = _.findIndex(this.properties, (v) => {
      return v.displayName.toLowerCase() === name.toLowerCase();
    });
    if (index >= 0) {
      return this.properties[index].displayValue;
    }
  };
  destroy = () => {
    this.properties = null;
  };
}

export async function getElementAndParameter(allDbIds) {
  return new Promise(async (resolve) => {
    const listCategory = {};
    const data = [];
    for (const i in allDbIds) {
      const dbIds = allDbIds[i].dbIds;
      const model = allDbIds[i].model;
      for (const j in dbIds) {
        const id = dbIds[j];
        const item = await getProperties(model, id);
        const properties = item.properties;
        _.forEach(properties, (property) => {
          if (property.displayName === 'Category') {
            let categoryName = property.displayValue.replace('Revit ', '');
            if (!listCategory[categoryName]) {
              listCategory[categoryName] = [];
            }
            // const parameters = listCategory[categoryName]
            _.forEach(properties, (p) => {
              const parameterName = p.displayName;
              let index = _.findIndex(listCategory[categoryName], (v) => {
                return v.name === parameterName;
              });
              if (!listExcludes.includes(parameterName) && index < 0) {
                listCategory[categoryName].push({
                  name: parameterName,
                  units: p.units,
                  type: p.type,
                });
              }
            });
            return false;
          }
        });
      }
    }
    _.forEach(listCategory, (v, k) => {
      data.push({ name: k, data: v });
    });
    resolve(data);
  });
}

export async function extractQuantity(allDbIds, categories, parameters, types) {
  return new Promise(async (resolve) => {
    const data = [];
    for (const i in allDbIds) {
      const dbIds = allDbIds[i].dbIds;
      const model = allDbIds[i].model;
      for (const j in dbIds) {
        const id = dbIds[j];
        const item = await getProperties(model, id);
        const properties = item.properties;
        const params = {};
        const paramsInSide = [];
        let categoryName = '';
        _.forEach(properties, (property) => {
          if (property.displayName === 'Category') {
            categoryName = property.displayValue.replace('Revit ', '');
            params[property.displayName] = categoryName;
          } else if (parameters.includes(property.displayName)) {
            params[property.displayName] = property.displayValue;
            paramsInSide.push(property.displayName);
          }
        });
        params.dbId = id;
        params.modelId = model.id;
        if (categories.includes(categoryName)) {
          _.forEach(parameters, (p, k) => {
            if (!paramsInSide.includes(p)) {
              params[p] = types ? (types[k] === 20 ? '' : 0) : '';
            }
          });
          data.push(params);
        }
      }
    }
    resolve(data);
  });
}
