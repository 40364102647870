/* eslint-disable no-unused-vars */
import { Button, DatePicker, Form, Input, message, Upload } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'src/components/molecules/Modal';
import { logoWithName } from '../../../../constant/LinkImage';
import { toBase64 } from '../../../../functions/General.function';
import { adminStore } from '../../../../store/Admin.store';
import { appStore } from '../../../../store/App.store';
import { AppAPI } from '../../../api';
const { RangePicker } = DatePicker;

export default function ModalAddProject() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { setIsAddProject, projects, setProjects } = adminStore();
  const { setIsLoading } = appStore();
  const { organizationId } = useParams();
  const [file, setFile] = useState();
  const [file64, setFile64] = useState();
  const [address, setAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    postCode: '',
  });

  const close = () => {
    setIsAddProject(false);
  };

  const handleOk = async (e) => {
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        const clone = { ...values };
        clone.address = address;
        clone.organization_id = organizationId;
        debugger;
        formData.append('data', JSON.stringify(clone));
        let imageData = file;
        formData.append('file', imageData);
        setIsLoading(true);
        const projectData = await AppAPI.ProjectAPI.CreateOne(formData, {
          organizationId,
        });
        if (projectData) {
          projects.push(projectData);
          setProjects([...projects]);
        }
        setIsLoading(false);
        close();
      })
      .catch((err) => {
        debugger;
        if (err instanceof String) message.warning(err);
        console.log(err);

        setIsLoading(false);
      });
  };
  const handleChangeAddress = (i, ev) => {
    let clone = { ...address };
    clone[i] = ev.target.value;
    setAddress(clone);
  };
  const handlePicture = async (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        setFile64(await toBase64(info.file));
        setFile(info.file);
      }
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error('Image must smaller than 4MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Create Project'}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout='vertical'
          className='idd-form'
          autoComplete='off'
        >
          <div className='column'>
            <div style={{ flexGrow: 1 }}>
              <Form.Item
                label='Project Name'
                rules={[{ required: true }]}
                name='name'
                className='block'
              >
                <Input placeholder='Project Name' name='name' />
              </Form.Item>
              <Form.Item
                label='Project Type'
                name='type'
                rules={[{ required: true }]}
              >
                <Input className='idd-input' placeholder='Project Type' />
              </Form.Item>

              <Form.Item
                label='Project Duration'
                name='duration'
                rules={[{ required: true }]}
              >
                <RangePicker className='idd-input' placeholder='Duration' />
              </Form.Item>
            </div>

            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 25,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <span> Project Image</span>
                <div style={{ display: 'flex', gap: 5 }}>
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      backgroundColor: 'lightgray',
                      border: '1px solid gray',
                      borderRadius: 5,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img src={file64 ?? logoWithName} width={100} alt='logo' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Select image to upload
                    </span>
                    <span style={{ color: 'gray' }}>JPG, GIF, PNG</span>
                    <span style={{ color: 'gray' }}>(4MB Max)</span>
                    <Upload
                      showUploadList={false}
                      customRequest={handlePicture}
                      beforeUpload={beforeUpload}
                    >
                      <Button type='primary'>Browser</Button>
                    </Upload>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <span> Project Address</span>
                <Input
                  className='idd-input'
                  placeholder='Address Line 1'
                  value={address.address1}
                  onChange={handleChangeAddress.bind(this, 'address1')}
                />
                <Input
                  className='idd-input'
                  placeholder='Address Line 1'
                  value={address.address2}
                  onChange={handleChangeAddress.bind(this, 'address2')}
                />
                <div style={{ display: 'flex', gap: 5 }}>
                  <Input
                    className='idd-input'
                    placeholder='City'
                    value={address.city}
                    onChange={handleChangeAddress.bind(this, 'city')}
                  />
                  <Input
                    className='idd-input'
                    placeholder='Postal Code'
                    value={address.postCode}
                    onChange={handleChangeAddress.bind(this, 'postCode')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}
