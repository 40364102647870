import React, { useEffect, useState } from 'react';
import { Button, Upload } from 'antd';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import { getAgGridRowNode, requestBimGrid, updateTableAsync } from '../../../../../functions/General.function';
import ClashDetectionTestTable from './table/ClashDetectionTest.table';
import { clashDetectionStore } from './ClashDetection.store';

// import ClashDetectionDetail from './ClashDetection.detail';




export default function ClassDetectionList() {
  const [search] = useSearchParams();
  const {  setClashDetectionClashTests, clashDetectionClashTestSelected ,setClashDetectLoading,clashDetectLoading  } = clashDetectionStore()
  const { organizationId, projectId } = useParams()
  const fileId = search.get('fileId')
  const [gridApi, setGridApi] = useState()
  useEffect(() => {
    run()
  }, [])

  useEffect(() => {
    if (clashDetectionClashTestSelected) {
      runUpdateClashTest(clashDetectionClashTestSelected)
    }
  }, [clashDetectionClashTestSelected])

  const runUpdateClashTest = async (resClash) => {
    let node = await getAgGridRowNode(gridApi.api, 'clashDetectionId', resClash)
    if (!node) {
      await updateTableAsync(gridApi.api, { add: [resClash] })
      gridApi.api.redrawRows()
    } else {
      // let clone ={...node.data, resClash}
      node.data.name = resClash.name
      node.data.version = resClash.version
      node.data.new = resClash.new
      node.data.active = resClash.active
      node.data.reviewed = resClash.reviewed
      node.data.approved = resClash.approved
      node.data.resolved = resClash.resolved
      node.data.id = resClash.id
      await updateTableAsync(gridApi.api, { update: [node.data] })
      gridApi.api.redrawRows()
    }
  }
  const run = async () => {
    setClashDetectLoading(true)
    let res = await requestBimGrid('get', '/api/clashDetection', null, {
      organizationId, projectId, fileId,
      select: ['clash_detection_version.id', 'clashes', 'new', 'active', 'reviewed', 'version',
        ' approved', 'resolved', 'selection_a', 'selection_b', 'clash_detection.name', 'clash_detection.id as clashDetectionId']
    })
    if (res) {
      setClashDetectionClashTests(res)
    }
    setClashDetectLoading(false)
  }
  const handleImportFile = async (info) => {
    setClashDetectLoading(true)
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        const reader = new FileReader();

        reader.onload = async () => {
          const fileContent = reader.result;
          const json = JSON.parse(fileContent)


          // let models = viewer.impl.modelQueue().getModels();
          // let sources = json.SelectionA.concat(json.SelectionB)
          // sources = _.uniq(sources)
          // let index = _.findIndex(sources, i => { return i === viewer.impl.model.myData.loadOptions.modelNameOverride })
          // if (index >= 0) {
          //     message.warning('Current model cannot match with this file, please open another model')
          //     return
          // }
          let name = window.prompt('Please fill name', json.Name)
          if (!name) {
            setClashDetectLoading(false)
            return
          }
          let clashTestData = {
            file_id: fileId,
            name
          }
          let clashTestVersionData = {
            clashes: json.Clashes,
            new: json.New,
            active: json.Active,
            reviewed: json.Reviewed,
            approved: json.Approved,
            resolved: json.Resolved,
            selection_a: json.SelectionA,
            selection_b: json.SelectionB,
          }
          const blob = new Blob([JSON.stringify(json.CustomClashResults)], { type: 'application/json' });
          const fileJson = new File([blob], 'data.json', { type: 'application/json' });
          const formData = new FormData()
          console.log(URL.createObjectURL(fileJson))
          formData.append('file', fileJson)
          formData.append('clashTestData', JSON.stringify(clashTestData))
          formData.append('clashTestVersionData', JSON.stringify(clashTestVersionData))
          let resClash = await requestBimGrid('post', '/api/clashDetection', formData, {
            organizationId, projectId,
          }, true)
          if (resClash) {
            runUpdateClashTest(resClash)
          }
          setClashDetectLoading(false)
        };
        reader.readAsText(info.file);
        //
      }

    }
  }
  return (
    <>

      <div style={{ padding: 10, display: 'flex' }}>
        <Upload
          style={{ width: '100%' }}
          customRequest={handleImportFile}
          showUploadList={false}
          className="idd-upload-input"

        >
          <Button className={`idd-custom-button block save`} loading={clashDetectLoading}
          >Import</Button>
        </Upload>
      </div>
      <div style={{ height: 'calc(100% - 50px)', overflow: 'auto', width: '100%', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%' }} >
          <ClashDetectionTestTable
            setClashDetectLoading={setClashDetectLoading}
            setGridApi={setGridApi}
          />
        </div>
      </div>



    </>

  );
}


