import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import WorkerSidebar from 'src/components/layout/routes/worker/WorkerSidebar';
import { appStore } from 'src/store/App.store';

export default function WorkerRoute() {
  const { setAppName } = appStore();

  useEffect(() => {
    setAppName('worker');
    return () => {
      setAppName(null);
    };
  }, []);

  return (
    <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }}>
      <WorkerSidebar />
      <div style={{ height: '100%', width: '100%' }}>
        <Outlet />
      </div>
    </div>
  );
}
