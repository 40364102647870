import { requestBimGrid } from 'src/functions/General.function';

export const SuperAdminAddPermissionAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('post', '/api/permission', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const SuperAdminUpdatePermissionAPI = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid('put', '/api/permission', body);
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
