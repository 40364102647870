const THREE = window.THREE;
const Autodesk = window.Autodesk;

export const viewerOptions = {
  applyRefPoint: true,
  isAEC: true,
  applyScaling: 'm',
  keepCurrentModels: true,
};

export const setupExtension = (viewer) => {
  viewer.setReverseZoomDirection(true);
};

export function unloadForgeExtension(viewer, check = false) {
  viewer.addEventListener(
    Autodesk.Viewing.EXTENSION_LOADED_EVENT,
    function (e) {
      if (e.extensionId === 'Autodesk.Measure') {
        var measureExtension = viewer.getExtension('Autodesk.Measure');
        measureExtension.setUnits('m');
      } else if (e.extensionId === 'Autodesk.Explode') {
        viewer.unloadExtension('Autodesk.Explode');
      } else if (e.extensionId === 'Autodesk.DefaultTools.NavTools') {
        const settingsTools1 = viewer.toolbar.getControl('navTools');
        settingsTools1.removeControl('toolbar-cameraSubmenuTool');
        const settingsTools2 = viewer.toolbar.getControl('modelTools');
        settingsTools2.removeControl('toolbar-explodeTool');
        settingsTools1.removeControl('toolbar-orbitTools');
        settingsTools1.removeControl('toolbar-panTool');
        settingsTools1.removeControl('toolbar-zoomTool');
      }
      // else if (e.extensionId === 'Autodesk.PropertiesManager') {
      //     if (check) {
      //         viewer.unloadExtension('Autodesk.PropertiesManager')
      //     }
      // }
    }
  );
}
