/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input } from 'antd';
import ModalTemplate from '../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { appStore } from '../../../../../store/App.store';
import { fiveDStore } from '../../../../../store/FiveD.store';
import { AppAPI } from '../../../../api';

export default function ModalFiveDEditSheet() {
  const { setIsLoading } = appStore();
  const { setCurrentEditSheet, setListSheet, listSheet, currentEditSheet } =
    fiveDStore();
  const [name, setName] = useState();
  useEffect(() => {
    setName(currentEditSheet.name);
  }, [currentEditSheet]);
  const close = () => {
    setCurrentEditSheet();
  };
  const handleChange = (type, e) => {
    setName(e.target.value);
  };

  const handleOk = async (e) => {
    if (!name) {
      return;
    }
    try {
      setIsLoading(true);
      await AppAPI.FiveDSheetAPI.UpdateOneSheet({
        id: currentEditSheet.id,
        data: { name },
      });
      const index = _.findIndex(listSheet, (v) => {
        return v.id === currentEditSheet.id;
      });
      if (index >= 0) {
        currentEditSheet.name = name;
        setListSheet([...listSheet]);
      }
      setIsLoading(false);
      close();
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Edit Sheet'}
        style={{ maxWidth: 500, width: 500 }}
      >
        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <span style={{ fontWeight: 'bold', width: 125 }}>Sheet Name:</span>
          <Input
            style={{ width: 400 }}
            placeholder='Please input'
            onChange={handleChange.bind(this, 'name')}
            value={name}
          />
        </div>
      </ModalTemplate>
    </>
  );
}
