import React, { useCallback, useRef } from 'react';
import _ from 'lodash'
import { useParams } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import { autoGroupColumnDef, defaultColDef } from '../../../../../../settings/General.settings';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { requestBimGrid, updateTableAsync } from '../../../../../../functions/General.function';
import TagCell from '../../../../../ag_grid_components/cell/Tag.cell';
import { clashDetectionStore } from '../ClashDetection.store';



export default function ClashDetectionTestTable({ setGridApi }) {
    const { clashDetectionClashTests, setClashDetectionClashTestSelected, setClashDetectLoading } = clashDetectionStore()
    const { organizationId, projectId } = useParams()
    const gridRef = useRef();
    const columns = [
        {
            headerName: 'Name',
            field: 'name',
            filter: 'agSetColumnFilter',
            cellStyle: {
                fontWeight: 'bold'
            }
        },
        {
            headerName: 'Version',
            field: 'version',
            filter: 'agSetColumnFilter', suppressMenu: true,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: TagCell,
                        params: { tagStyle: { backgroundColor: '#156289', color: 'white' } }
                    }
                }
                return undefined
            },
            cellStyle: {
                fontWeight: 'bold',
                textAlign: 'center'
            }
        },
        {
            headerName: 'Clashes',
            field: 'clashes',
            filter: 'agSetColumnFilter', suppressMenu: true,
            cellStyle: {
                fontWeight: 'bold'
            }
        },
        {
            headerName: 'New',
            field: 'new',
            filter: 'agSetColumnFilter',
            headerComponent: params => {
                return (
                    <div className="ag-header-group-cell-label">
                        <span className='idd-text-left-line new-clash' style={{ fontWeight: 'bold' }} >{params.displayName} </span>
                    </div>
                )
            }
        },
        {
            headerName: 'Active',
            field: 'active',
            filter: 'agSetColumnFilter',
            headerComponent: params => {
                return (
                    <div className="ag-header-group-cell-label">
                        <span className='idd-text-left-line active-clash' style={{ fontWeight: 'bold' }} >{params.displayName} </span>
                    </div>
                )
            }
        },
        {
            headerName: 'Reviewed',
            field: 'reviewed',
            filter: 'agSetColumnFilter',
            headerComponent: params => {
                return (
                    <div className="ag-header-group-cell-label">
                        <span className='idd-text-left-line reviewed-clash' style={{ fontWeight: 'bold' }} >{params.displayName} </span>
                    </div>
                )
            }
        },
        {
            headerName: 'Approved',
            field: 'approved',
            filter: 'agSetColumnFilter',
            headerComponent: params => {
                return (
                    <div className="ag-header-group-cell-label">
                        <span className='idd-text-left-line approved-clash' style={{ fontWeight: 'bold' }} >{params.displayName} </span>
                    </div>
                )
            }
        },
        {
            headerName: 'Resolved',
            field: 'resolved',
            filter: 'agSetColumnFilter',
            headerComponent: params => {
                return (
                    <div className="ag-header-group-cell-label">
                        <span className='idd-text-left-line resolved-clash' style={{ fontWeight: 'bold' }} >{params.displayName} </span>
                    </div>
                )
            }
        },
        {
            headerName: 'Action',
            lockPosition: 'right',
            suppressMovable: true,
            minWidth: 130, maxWidth: 150,
            suppressMenu: true,
            suppressSorting: true,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: handleAction,
                    }
                }
                return undefined;
            }
        },
    ]

    const handleView = async (e) => {


        setClashDetectionClashTestSelected(e.data)
        // setClashDetectionDetail(e.data.customClashResults)
    }

    const handleEdit = async (e) => {
        let name = window.prompt('Please fill name', e.data.name)
        if (!name) {
            return
        }
        setClashDetectLoading(true)
        let res = await requestBimGrid('put', '/api/clashDetection', { clashDetectionClashTest: e.data, name }, {
            organizationId, projectId
        })
        if (res) {
            e.data.name = name
            await updateTableAsync(e.api, { update: [e.data] })
            e.api.redrawRows()
        }
        setClashDetectLoading(false)
    }
    const handleDelete = async (e) => {

        if (!window.confirm('Are you sure to delete?')) return
        setClashDetectLoading(true)
        let res = await requestBimGrid('delete', '/api/clashDetection', { clashDetectionIds: [e.data.clashDetectionId] }, {
            organizationId, projectId
        })
        if (res) {
            await updateTableAsync(e.api, { remove: [e.data] })
            e.api.redrawRows()
        }
        setClashDetectLoading(false)
    }
    const handleAction = (params) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
                <IconButton
                    icon='view'
                    size='sssmall'
                    type='save'
                    onClick={handleView.bind(this, params)}
                />
                <IconButton
                    icon='upload'
                    size='sssmall'
                    type='save'
                    onClick={handleView.bind(this, params)}
                />
                <IconButton
                    icon='edit'
                    size='sssmall'
                    type='edit'
                    onClick={handleEdit.bind(this, params)}
                />
                <IconButton
                    icon='delete'
                    size='sssmall'
                    type='delete'
                    onClick={handleDelete.bind(this, params)}
                />
                {/* <Button size='small' className={`prefab-custom-button ${(roleForAddEditModule[role] || user.isAdmin) ? 'delete' : 'disabled'}`} onClick={handleDeleteModule.bind(this, params.data)} type='danger'
          disabled={(roleForAddEditModule[role] || user.isAdmin)? false : false} ghost >Delete</Button> */}
            </div>
        )
    }

    const onGridReady = useCallback((params) => {
        setGridApi(params)
    }, []);
    return (
        <>
            <div style={{ width: '100%', height: '100%', padding: 5 }} >

                <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={clashDetectionClashTests}
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onGridReady={onGridReady}
                        groupDisplayType='multipleColumns'
                        // rowGroupPanelShow='always'
                        rowHeight={30}
                        // pagination={true}
                        // paginationPageSize={pageSizeModuleList}
                        suppressPaginationPanel={true}
                        context={false}
                        suppressContextMenu={true}

                    ></AgGridReact>


                </div>
            </div>

        </>

    );
}


