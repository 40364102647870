import React, { useEffect } from 'react';
import _ from 'lodash';
import { dfmaStore } from './DFMA.store';
import { useSearchParams } from 'react-router-dom';
import { Tabs, message } from 'antd';
import DataTab from './datatab/DataTab';
import { ViewerAPI } from '../../../../../api';
import TimelineTab from './progress/TimelineTab';
export default function DFMALayout() {
  const [search] = useSearchParams();
  const {
    setDFMALoading,
    setParameters,
    setRowDataTab,
    rowDataTab
  } = dfmaStore();
  const fileId = search.get('fileId');
  useEffect(() => {
    run()
  }, [])
  const run = async () => {
    setDFMALoading(true);
    try {
      let array = [
        await ViewerAPI.DFMAAPI.GetOneSetup({ fileId }),
        await ViewerAPI.DFMAAPI.GetAll({ fileId }),
      ]

      let [repSetup, repData] = await Promise.all(array)
      if (repSetup)
        setParameters(repSetup.parameters)
      if (repData) {
        setRowDataTab(repData)
      }
    } catch (ex) {
      message.warning(ex.message);
    }
    setDFMALoading(false);
  }
  return (
    <>

      <Tabs type='card' className='idd-custom-tabs'>
        <Tabs.TabPane tab='Data' key='data' forceRender>
          <DataTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Timeline' key='timeline'>
          {rowDataTab.length !== 0 && <TimelineTab />}
        </Tabs.TabPane>
      </Tabs>
      {/* <QRDrawingToolbar />
      <QRDrawingTable /> */}
    </>
  );
}
