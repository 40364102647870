import React from 'react';
import _ from 'lodash';
import { timelineStore } from './Timeline.store';
import { TimelineComponentType } from './constants/TimelineComponentType';
import TimelineSimulationToolbar from './components/simulation/TimelineSimulation.toolbar';
import TimelineSimulationGantt from './components/simulation/TimelineSimulation.gantt';

export default function TimelineSimulation({}) {
  const { segmentKey } = timelineStore();

  return (
    <>
      <div
        style={{
          display: segmentKey ===  TimelineComponentType.Simulation ? 'flex' : 'none',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
              <TimelineSimulationToolbar />
        <TimelineSimulationGantt />
  
      </div>
    </>
  );
}
