import { Button, Tag } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FileAddFilled } from '@ant-design/icons';
import { AgGridReact } from 'ag-grid-react';
import { TranslateForgeItem } from 'src/api';
import { fileStatus } from '../../../../../../constant/Status.enum';
import { requestBimGrid } from '../../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';
import { projectStore } from '../../../../../../store/Docs.store';
import DateCell from '../../../../../ag_grid_components/cell/Date.cell';
import ProgressCell from '../../../../../ag_grid_components/cell/Progess.cell';
import IconButton from '../../../../../gen/button/IconButton.gen';

export default function ProjectTable({}) {
  const navigate = useNavigate();
  const { setIsLoading, widthApp } = appStore();
  const { fileData, setFileData, setFileName, folder, setIsAddFile } =
    projectStore();
  const [search] = useSearchParams();
  const [gridApi, setGridApi] = useState();
  const type = search.get('type') ? search.get('type') : '3d';
  const { organizationId, projectId } = useParams();
  useEffect(() => {
    if (folder) {
      run();
      return () => {
        setFileData([]);
      };
    }
  }, [folder]);
  useEffect(() => {
    if (!gridApi) return;
    if (widthApp < 700) {
      gridApi.columnApi.setColumnsVisible(
        ['created_by', 'created_at', 'updated_at', 'updated_by'],
        false
      );
    } else {
      gridApi.columnApi.setColumnsVisible(
        ['created_by', 'created_at', 'updated_at', 'updated_by'],
        true
      );
    }
  }, [widthApp, gridApi]);
  const run = async () => {
    setIsLoading(true);
    let data = await requestBimGrid(
      'get',
      '/api/file/get-all-by-folder-id',
      null,
      { organizationId, projectId, folderId: folder.key, type }
    );
    if (data) {
      setFileData(data);
    }
    setIsLoading(false);
  };
  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    let dataFile = await requestBimGrid(
      'delete',
      '/api/file',
      { id: e.id },
      {
        organizationId,
        projectId,
      }
    );
    if (dataFile) {
      navigate(0);
    }
    setIsLoading(false);
  };
  const handleViewItem = (e) => {
    console.log(e);
    setFileName(e.name);
    navigate(
      `./viewer?folderId=${folder.key}&type=${type}&fileId=${e.id}&versionId=${e.versionId}`
    );
  };
  const handleTranslateItem = async (e, params) => {
    setIsLoading(true);
    console.log(e);
    // let data = await requestBimGrid(
    //   'post',
    //   '/api/forge/modelderivative/jobs',
    //   { id: e.versionId, objectId: e.objectId },
    //   { organizationId, projectId }
    // );
    const data = await TranslateForgeItem({
      versionId: e.versionId,
      objectId: e.objectId,
    });
    if (data) {
      var rowNode = params.node;
      rowNode.setDataValue('status', 1);
    }
    setIsLoading(false);
  };
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: ProgressCell,
            params: {
              status: params.node.data.status,
              objectName: btoa(params.node.data.object_id),
            },
          };
        }
        return undefined;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      minWidth: 100,
      maxWidth: 100,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: () => {
              return (
                <Tag
                  style={{ fontWeight: 'bold' }}
                  id={`${
                    params.node.data.status === 0
                      ? 'noTranslate'
                      : params.node.data.status === 99
                        ? 'completed'
                        : 'inProcess'
                  }`}
                >
                  {' '}
                  {fileStatus[params.node.data.status]}
                </Tag>
              );
            },
          };
        }
        return undefined;
      },
      cellStyle: {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    {
      headerName: 'Version',
      field: 'version',
      filter: 'agSetColumnFilter',
      minWidth: 90,
      maxWidth: 90,
      suppressMenu: true,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      headerName: 'Updated By',
      field: 'updated_by',
      filter: 'agSetColumnFilter',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      filter: 'agSetColumnFilter',
      minWidth: 100,
      maxWidth: 150,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
          };
        }
        return undefined;
      },
    },
    {
      headerName: '',
      lockPosition: 'right',
      suppressMovable: true,
      minWidth: 100,
      maxWidth: 100,
      suppressMenu: true,
      suppressSorting: true,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          height: '100%',
        }}
      >
        {params.data.status === 0 && (
          // <Button className='idd-custom-button save' size="small"
          //     onClick={handleTranslateItem.bind(this, params.data, params)}  >Translate</Button>

          <IconButton
            // url={'https://img.icons8.com/ios/42/null/back-sorting.png'}
            icon='translate'
            size='ssmall'
            onClick={handleTranslateItem.bind(this, params.data, params)}
          />
        )}
        {params.data.status === 99 && (
          // <Button className='idd-custom-button save' size="small" onClick={handleViewItem.bind(this, params.data)}  >View</Button>
          <IconButton
            // url={'https://img.icons8.com/material-sharp/48/null/visible.png'}
            icon='view'
            size='sssmall'
            // type="save"
            onClick={handleViewItem.bind(this, params.data)}
          />
        )}
        <IconButton
          // url={'https://img.icons8.com/sf-regular-filled/48/null/trash.png'}
          icon='delete'
          size='sssmall'
          type='delete'
          onClick={handleDeleteItem.bind(this, params.data)}
        />
        {/* <Button className='idd-custom-button delete' size='small' onClick={handleDeleteItem.bind(this, params.data)} >Delete</Button> */}
      </div>
    );
  };
  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);
  return (
    <>
      <div
        style={{
          backgroundColor: '#f6f6f6',
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 5,
          }}
        >
          {folder && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
              <span style={{ fontSize: 18, fontWeight: '700' }}>
                Folder Name:
              </span>
              <span style={{ fontSize: 18, fontWeight: '700', color: 'blue' }}>
                {folder ? ` ${folder?.name}` : ''}
              </span>
            </div>
          )}

          <Button
            className='idd-custom-button save'
            disabled={!folder}
            icon={<FileAddFilled />}
            onClick={setIsAddFile.bind(this, true)}
          >
            Add
          </Button>
        </div>
      </div>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          rowData={fileData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          rowHeight={35}
          headerHeight={35}
          groupDefaultExpanded={2}
          suppressContextMenu={true}
        ></AgGridReact>
      </div>
    </>
  );
}
