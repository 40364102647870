/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, Input, DatePicker,Select } from 'antd'
import _ from 'lodash';

import ModalTemplate from '../../../../../../gen/modal/Modal.gen';
import AddParameter from '../components/AddParameter';
import { dfmaStore } from '../DFMA.store';
import { ViewerAPI } from '../../../../../../api';
import { useSearchParams } from 'react-router-dom';
import { defaultParameters } from '../DFMA.constant';
import { updateTableAsync } from '../../../../../../../functions/General.function';
import { CATEGORY_PPVC, defaultParameters2 } from '../DFMA.constant';

export default function AddRowModal() {
    const [search] = useSearchParams();
    const fileId = search.get('fileId');
    const {
        setIsOpenAddRow,
        setDFMALoading,
        gridApiDataTabTable,
    } = dfmaStore();
    const [result, setResult] = useState({})
    useEffect(() => {
        let temp = []
        let result = {
            'casting_date': null,
            'delivery_date': null,
            'install_date': null
        }
        defaultParameters.forEach(v => {
            if (v === 'Rebar Weight') {
                result['rebar_weight'] = 0
            } else if (v === 'Name') {
                result[v] = ''
            } else {
                result[v] = 0
            }
        })
        // setRow(temp)
        setResult(result)
    }, [])




    const close = () => {
        setIsOpenAddRow();
    };

    const handleOk = async (e) => {
        if (!window.confirm('Are you sure to submit?')) return;
        setDFMALoading(true);
        try {
            debugger
            let temp = {}
            _.forEach(result, (v, k) => {
                if (k === 'Name') {
                    if (!v) {
                        throw new Error('Please input name')
                    }
                }
                if (k === 'Rebar Weight') {
                    temp['rebar_weight'] = v
                } else {
                    temp[k.toLowerCase()] = v
                }
            })
            console.log(temp)
            let repRow = await ViewerAPI.DFMAAPI.Create({ data: temp, fileId })
            await updateTableAsync(gridApiDataTabTable.api, { add: [repRow] })
            close();
        } catch (ex) {
            message.warning(ex.message);
        }
        setDFMALoading(false);
    };
    const handleChangeInput = (type, v) => {
        result[type] = v.target.value
        setResult({ ...result })
    }
    const handleChangeNumber = (type, v) => {
        result[type] = _.toNumber(v.target.value)
        setResult({ ...result })
    }
    const handleChangeDate = (type, v) => {
        result[type] = v
        setResult({ ...result })
    }
    const handleChangeSelect =(type, v) => {
        result[type] = v
        setResult({ ...result })
      }
    return (
        <>
            <ModalTemplate
                onClose={close}
                onOk={handleOk}
                title={'Add'}
                styleBackDrop={{ position: 'absolute' }}
                style={{ top: 'auto', width: '90%' }}
                isFooter={true}
            >
                {result && <div
                    style={{
                        display: 'flex',
                        gap: 10,
                        height: 'calc(100% - 450px)',
                        overflow: 'auto',
                        width: '100%',
                        flexDirection: 'column'
                    }}
                >
                    {defaultParameters.map(v =>
                        <div style={{ display: 'flex' }} >
                            <div style={{ width: 170 }}>
                                <span style={{ fontWeight: 'bold' }} >{v.toUpperCase()}</span>
                            </div>
                            {v.toLowerCase() === 'name' ?
                                <Input value={result[v]} onChange={handleChangeInput.bind(this, v)} /> :
                                v.toLowerCase() === 'category' ?
                                    <Select style={{ width: '100%' }} value={result[v.toLowerCase()]} onChange={handleChangeSelect.bind(this, v)}  >
                                        {CATEGORY_PPVC.map(v =>
                                            <Select.Option key={v} value={v}  > {v} </Select.Option>
                                        )}
                                    </Select>
                                    :
                                    v.toLowerCase() === 'rebar weight' ?
                                        <Input min={0} type='number' value={result['rebar_weight']} onChange={handleChangeInput.bind(this, 'rebar_weight')} />
                                        :
                                        <Input min={0} type='number' value={result[v]} onChange={handleChangeNumber.bind(this, v)} />
                            }
                        </div>
                    )}


                    <div style={{ display: 'flex' }} >
                        <div style={{ width: 170 }}>
                            <span style={{ fontWeight: 'bold' }} >{'Casting Date'.toUpperCase()}</span>
                        </div>
                        <DatePicker style={{ width: '100%' }} value={result['casting_date']}
                            onChange={handleChangeDate.bind(this, 'casting_date')}
                        />
                    </div>
                    <div style={{ display: 'flex' }} >
                        <div style={{ width: 170 }}>
                            <span style={{ fontWeight: 'bold' }} >{'Delivery Date'.toUpperCase()}</span>
                        </div>
                        <DatePicker style={{ width: '100%' }} value={result['delivery_date']}
                            onChange={handleChangeDate.bind(this, 'delivery_date')}
                        />
                    </div>
                    <div style={{ display: 'flex' }} >
                        <div style={{ width: 170 }}>
                            <span style={{ fontWeight: 'bold' }} >{'Install Date'.toUpperCase()}</span>
                        </div>
                        <DatePicker style={{ width: '100%' }} value={result['install_date']}
                            onChange={handleChangeDate.bind(this, 'install_date')}
                        />
                    </div>

                </div>}
            </ModalTemplate>
        </>
    );
}
