import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'src/components/atoms/PageHeader';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { validatePermission } from 'src/functions/Auth.function';
import { AlertPermissionService } from 'src/services';
import { appStore } from '../../../store/App.store';
import { projectStore } from '../../../store/Docs.store';
import LayoutProjectManagement from '../../layout/project_management/ProjectManagement.layout';

export default function ProjectManagementPage({
  requireActions = [],
  containActions = [],
}) {
  const { setLocationPage, permission, organization } = appStore();
  const { project } = projectStore();
  const navigate = useNavigate();
  useEffect(() => {
    const { allowed, permissionsMissing } = validatePermission(
      permission,
      requireActions,
      containActions
    );
    if (!allowed) {
      AlertPermissionService.showAlert(permissionsMissing, 0);
      navigate(`/organization/${organization.id}`);
    }
  }, []);

  useEffect(() => {
    setLocationPage('project/management');
    return () => {
      setLocationPage();
    };
  }, []);
  return (
    <>
      <TopPageToolbar
        left={
          project ? (
            <PageHeader>
              Project Name :
              <span style={{ color: 'blue' }}>{` ${project?.name}`}</span>
            </PageHeader>
          ) : (
            ''
          )
        }
      />

      <LayoutProjectManagement />
    </>
  );
}
