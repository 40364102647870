import React from "react";
import IconButton from "../../../../../gen/button/IconButton.gen"
import { appStore } from "../../../../../../store/App.store";
import { dmsStore } from "../../../../../../store/DMS.store";

import { message } from 'antd'
import { generateS3Path, getRouteToNode } from "../../../../../../functions/General.function";
import { useParams } from "react-router-dom";
import { S3_BASED_PATH } from "../../../../../../config";
import { AppAPI } from "../../../../../api/index";
const ActionButtonCell = (params) => {
    const { setIsLoading } = appStore()
    const { setAddDocParentNode, setIsModalDMSDocAddFolder,
        setIsModalDMSDocAddFile, dmsDocGridApi,
        setUpdateDMSDocNode,
        setIsModalDMSDocEditFolder } = dmsStore()
    const { organizationId, projectId } = useParams()
    const handleAddFolder = () => {
        setIsModalDMSDocAddFolder(true)
        setAddDocParentNode(params.node)
    }
    const handleAddFile = () => {
        setIsModalDMSDocAddFile(true)
        setAddDocParentNode(params.node)
    }
    const handleDeleteItem = async () => {
        if (!window.confirm('Are you sure to delete?')) return
        setIsLoading(true)
        try {
            debugger
            await AppAPI.DMSDocAPI.DeleteDMSDocOne({ id: params.node.data.id,path: params.node.data.path })
            const route = getRouteToNode(params.node);
            dmsDocGridApi.api.applyServerSideTransaction({
                route: route.slice(0, route.length - 1),
                remove: [params.node.data],
            });
        } catch (ex) {
            message.warning(ex.message)
        }
        setIsLoading(false)

    }
    const handleEditItem = () => {
        setUpdateDMSDocNode(params.node)
        setIsModalDMSDocEditFolder(true)
    }
    const handleDownloadItem = async () => {
        if (!window.confirm('Are you sure to download?')) return
        if (params.node.data.is_folder) {
            setIsLoading(true)
            try {
                const link = await AppAPI.DMSDocAPI.DownloadFolder({ path: params.node.data.path })
                debugger
                window.open(link, "_blank")
            } catch (ex) {
                message.warning(ex.message)
            }
            setIsLoading(false)
        } else {
            setIsLoading(true)
            try {
                const key =
                    AppAPI.DMSDocAPI.docPath(organizationId, projectId, params.node.data.path,
                        params.node.data.id, params.node.data.current_version, params.node.data.name)
                // generateS3Path(organizationId, projectId,
                //     S3_BASED_PATH.DMS_DOC, `${params.node.data.path}/${params.node.data.id}/${params.node.data.current_version}/${params.node.data.name}`)
                const link = await AppAPI.S3API.GetPublicLink({ key })
                window.open(link, "_blank")
            } catch (ex) {
                message.warning(ex.message)
            }
            setIsLoading(false)
        }
    }
    const handleViewItem = async () => {

        const split = params.node.data.name?.toLowerCase().split('.')
        const fileExt = split[split.length - 1]
        if (fileExt === 'pdf' || fileExt === 'dwf' || fileExt === 'dwfx') {
            let key = AppAPI.DMSDocAPI.docPath(organizationId, projectId, params.node.data.path,
                params.node.data.id, params.node.data.current_version, params.node.data.name)
            key = btoa(key)

            const url = `${window.location.origin}/organization/${organizationId}/project/${projectId}/dms/viewer?key=${key}&type=${fileExt}`;
            window.open(url)
        } else {
            message.warning('Only pdf, dwf and dwfx are able to view')
        }

    }
    const handleShareFolder = async () => {
        if (params.node.data.is_shared) {
            const url = `${window.location.origin}/share/dms/doc?organizationId=${organizationId}&projectId=${projectId}&folderId=${params.node.data.id}`;
            navigator.clipboard.writeText(url);
            message.info('Share link copied')
        } else {
            setUpdateDMSDocNode(params.node)
            setIsModalDMSDocEditFolder(true)
        }
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center', height: '100%' }}>
            {params.node.data.is_folder &&
                <>
                    <IconButton
                        icon={'add_folder'}
                        size='sssmall'
                        // type={'save'}
                        iconStyle={{ background: '#000000' }}
                        onClick={handleAddFolder.bind(this, params)}
                    />
                    <IconButton
                        icon={'add_file'}
                        size='sssmall'
                        // type={'save'}
                        iconStyle={{ background: '#000000' }}
                        onClick={handleAddFile.bind(this, params)}
                    />
                    <IconButton
                        icon={'share'}
                        size='sssmall'
                        type={params.node.data?.is_shared ? 'save' : 'regular'}
                        // iconStyle={{ background: '#000000' }}
                        onClick={handleShareFolder.bind(this, params)}
                    />
                    <IconButton
                        icon={'edit'}
                        size='sssmall'
                        // type={'edit'}
                        iconStyle={{ background: '#000000' }}
                        onClick={handleEditItem.bind(this, params)}
                    />
                </>

            }
            {!params.node.data.is_folder &&
                <IconButton
                    icon={'view'}
                    size='sssmall'
                    iconStyle={{ background: '#000000' }}
                    onClick={handleViewItem.bind(this, params)}
                />
            }
            <IconButton
                icon={'download'}
                size='sssmall'
                // type={'save'}
                iconStyle={{ background: '#000000' }}
                onClick={handleDownloadItem.bind(this, params)}
            />

            <IconButton
                icon={'delete'}
                size='sssmall'
                type={'delete'}
                iconStyle={{ background: '#000000' }}
                onClick={handleDeleteItem.bind(this, params)}
            />
        </div>
    )
}

export default ActionButtonCell