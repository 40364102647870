import { requestBimGrid } from '../../../functions/General.function';

export const GetOneWorkerDaily = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/daily/get-one-worker-daily',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllWorkerDailyByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/daily/get-all-worker-daily-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const GetAllWorkerDailyByProjectDate = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/daily/get-all-worker-daily-by-project-date',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateWorkerDaily = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/daily/add-worker-daily',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const UpdateOneWorkerDaily = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/worker/daily/update-one-worker-daily',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteWorkerDaily = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/worker/daily/delete-one-worker-daily',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const GetWorkerTrackingDevicesByBlock = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/worker/daily/get-worker-tracking-devices-by-block',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
