import { useEffect, useState } from 'react';
import AlertPermission from 'src/components/atoms/AlertPermission';
import { AlertPermissionService } from 'src/services';

/**
 * @param {string} message
 * @returns
 */
const AlertPermissionPopup = () => {
  const [alert, setAlert] = useState(AlertPermissionService.getAlert());

  useEffect(() => {
    const unsubscribe = AlertPermissionService.subscribe(setAlert);
    return unsubscribe;
  }, []);

  if (!alert) return null;
  const handleDismiss = () => {
    AlertPermissionService.dismissAlert();
  };
  return (
    <div className='absolute bottom-0 right-0 m-5'>
      <AlertPermission
        permissionsMissing={alert.permissionsMissing}
        handleDismiss={handleDismiss}
      />
    </div>
  );
};

export default AlertPermissionPopup;
