import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import _ from 'lodash'
import { useParams } from "react-router-dom";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import { requestBimGrid } from '../../../../../functions/General.function';
import { issueImagePath } from './constants/Issue.constant'
import IssueGen from './gen/issue.gen';
import { issueStore } from './Issue.store';
import { IssueComponentType } from './constants/IssueComponentType';


export default function IssueDetail({   coordinationPoint }) {
    const [form] = Form.useForm();
    const { viewer } = viewerStore()
    const {  issueDetail, issueData, setIssueData ,setType, setIssueLoading} = issueStore()
    const [image, setImage] = useState()
    const { organizationId, projectId } = useParams()
    useEffect(() => {
        coordinationPoint?.hidePointCloud()
        coordinationPoint?.setExistingPoints(issueDetail.positions, issueDetail.category)
        run()
        return () => {
            coordinationPoint?.disableAddNewPoint()
            coordinationPoint?.unhidePointCloud()
        }
    }, [])
    const run = async () => {
        setIssueLoading(true)
        let data = await requestBimGrid('get', '/api/s3/get-link', null, {
            organizationId, projectId, key: issueImagePath(organizationId, projectId, `${issueDetail.file_id}/${issueDetail.id}/main.jpg`)
        })
        if (data) {
            setImage(data)

        }
        setIssueLoading(false)
    }

    const handleUpdate = async () => {
        form.validateFields()
            .then(async values => {
                setIssueLoading(true)
                let clone = { ...values }
                clone.id = issueDetail.id
                let data = await requestBimGrid('put', '/api/issue', clone, { organizationId, projectId })
                if (data) {
                    clone.colorName = clone.category //! add color name
                    let cloneIssueData = [...issueData]
                    let index = _.findIndex(cloneIssueData, v => { return v.id === issueDetail.id })
                    if (index >= 0) {
                        cloneIssueData.splice(index, 1, { ...issueDetail, ...clone })
                    }        
                    setIssueData(cloneIssueData)
                    coordinationPoint.setMarkupData(cloneIssueData)
                    setType('list')
                }
                setIssueLoading(false)
            })
            .catch(ex => {
                setIssueLoading(false)
            })
    }
    const handleBack = () => {
        setType(IssueComponentType.List)
    }
    const handleView = () => {
        viewer.restoreState(issueDetail.view_state)
    }
    return (
        <>
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={handleBack} />
                <span style={{ fontWeight: 'bold' }} >  {`Issue #${issueDetail.issue_no}`}</span>

            </div>
            <IssueGen
                image={image}
                form={form}
                isEdit={true}
                handleView={handleView}
            />
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <Button className={`idd-custom-button block edit`} onClick={handleUpdate}
                >Update Issue</Button>

            </div>


        </>

    );
}


