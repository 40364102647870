import create from 'zustand'



const initialState = {
    qrElementLoading:false,
    isShowSetting: false,
    allTemplate:[],
    currentTemplate:null,
    fileQRElementId: null,
    currentElement:null,
   
}
export const qrElementStore = create((set) => ({
    ...initialState,

    setQRElementLoading: (value) => set((state) => ({ qrElementLoading: value })),
    setIsShowSetting: (value) => set((state) => ({ isShowSetting: value })),
    setAllTemplate: (value) => set((state) => ({ allTemplate: value })),
    setCurrentTemplate: (value) => set((state) => ({ currentTemplate: value })),
    setFileQRElementId: (value) => set((state) => ({ fileQRElementId: value })),
    setCurrentElement: (value) => set((state) => ({ currentElement: value })),
  

    resetQRElementStore: () => set(initialState)
}))