import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'src/assets/logo.png';
/**
 *
 * @returns {React.Element}
 */
const AppLogo = () => {
  return (
    <>
      <Link
        to={'/'}
        className='flex content-center items-center text-2xl font-semibold text-gray-900 dark:text-white'
      >
        <img
          className='mr-2 h-8 w-8'
          src={logo}
          alt='logo'
          crossOrigin='anonymous'
        />
        <span className='text-2xl font-bold'>
          BIM<span className='text-primary-500 dark:text-white'>Grid</span>
        </span>
      </Link>
    </>
  );
};

export default AppLogo;
