import { Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { timelineStore } from '../../Timeline.store';
import IconButton from '../../../../../../gen/button/IconButton.gen';
import { TimelineComponentType } from '../../constants/TimelineComponentType';
import Icon from '../../../../../../gen/button/Icon.gen';

export default function TimelineDataSourceList() {
  const {
    setDataSourceList,
    dataSourceList,
    setDataSelected,
    dataSelected,
    setSegmentKey,
    timelineGantt,
  } = timelineStore();
  const [itemSelected, setItemSelected] = useState();
  const handleSelect = (e) => {
    setItemSelected(e);
  };
  const handleViewItem = (e) => {
    setDataSelected(e);
  };
  useEffect(() => {
    if (dataSelected) {
      timelineGantt?.clearAll();
      timelineGantt?.parse(dataSelected.data);
      setSegmentKey(TimelineComponentType.Tasks);
    }
  }, [dataSelected]);
  const handleDeleteItem = (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    let clone = [...dataSourceList];
    let index = _.findIndex(clone, (v) => {
      return v.id === e.id;
    });
    if (index >= 0) {
      clone.splice(index, 1);
    }
    setDataSourceList(clone);
    if (dataSelected.id === e.id) {
      timelineGantt?.clearAll();
      timelineGantt?.parse({ data: [], link: [] });
    }
  };
  return (
    <div style={{ overflow: 'auto' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          border: '1px solid black',
          height: '500px',
          background: 'white',
          padding: 5,
          borderRadius: 5,
          // overflow:'auto'
        }}
      >
        {
          // [
          //   { id: 1, name: 's' },
          //   { id: 2, name: 'sdsd' },
          // ]
          dataSourceList.map((i) => (
            <div
              onClick={handleSelect.bind(this, i)}
              style={{
                background: itemSelected?.id === i.id ? '#88c8ff' : '',
                padding: 3,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', gap: 5 }}>
                  {i.type === 'excel' && (
                    <Icon
                      icon={'excel'}
                      size='sssmall'
                      ghost={true}
                      border={false}
                    />
                  )}
                  {i.type === 'msproject' && (
                    <Icon
                      icon={'msproject'}
                      size='sssmall'
                      ghost={true}
                      border={false}
                    />
                  )}
                  {i.type === 'p6' && (
                    <Icon
                      icon={'p6'}
                      size='sssmall'
                      ghost={true}
                      border={false}
                    />
                  )}
                  <span> {i?.name}</span>
                  {dataSelected?.id === i.id && (
                    <span style={{ fontWeight: 'bold' }}>(In-use)</span>
                  )}
                </div>

                <div style={{ display: 'flex', gap: 2, borderRadius: 5 }}>
                  <IconButton
                    icon='view'
                    size='sssmall'
                    type='save'
                    onClick={handleViewItem.bind(this, i)}
                  />
                  <IconButton
                    icon='edit'
                    size='sssmall'
                    type='edit'
                    // onClick={handleViewItem.bind(this, params.data)}
                  />
                  <IconButton
                    icon='delete'
                    size='sssmall'
                    type='delete'
                    onClick={handleDeleteItem.bind(this, i)}
                  />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
