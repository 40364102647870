import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';

/**
 * @param {React.Element} children
 * @returns {React.Element} The rendered input element.
 */
const UserAccountPassword = () => {
  return (
    <>
      <p className='py-2 text-xl font-semibold'>Password</p>
      <div className='flex items-center'>
        <div className='flex flex-col space-y-2 sm:flex-row sm:space-x-3 sm:space-y-0'>
          <LabelAndInputForm
            type='password'
            name='currentPassword'
            label={'Current Password'}
            placeholder='••••••••'
            isRequired
          />
          <LabelAndInputForm
            type='password'
            name='newPassword'
            label={'New Password'}
            placeholder='••••••••'
            isRequired
          />
        </div>
      </div>
      <p className='mt-2'>
        Can't remember your current password.{' '}
        <Link
          className='text-sm font-semibold text-blue-600 underline decoration-2'
          to='/forgot-password'
        >
          Recover Account
        </Link>
      </p>
      <div className='pt-2'>
        <PrimaryButton>Save Password</PrimaryButton>
      </div>
    </>
  );
};

export default UserAccountPassword;
