import ModalTrackingModel from 'src/components/layout/apps/worker/modal/ModalTrackingModel';
import { workerStore } from '../../../../../store/Worker.store';
import ModalEditWorkerDaily from '../daily/modal/ModalEditWorkerDaily';
import WorkerTrackingTable from './table/WorkerTracking.table';
import WorkerTrackingToolbar from './WorkerTrackingtoolbar';

export default function WorkerTrackingLayout() {
  const { isOpenEditWorkerDaily, isOpenTrackingModel } = workerStore();
  return (
    <>
      <div style={{ padding: 5, height: '100%', widthL: '100%' }}>
        <WorkerTrackingToolbar />
        <div className='flex h-[calc(100%-50px)] w-full gap-1'>
          <WorkerTrackingTable />
        </div>
      </div>
      {isOpenEditWorkerDaily && <ModalEditWorkerDaily isTracking={true} />}
      {isOpenTrackingModel && <ModalTrackingModel />}
    </>
  );
}
