import _ from 'lodash';
import { useEffect, useState } from 'react';

export default function NumberCellGroup(props) {
  const [value, setValue] = useState();
  useEffect(() => {
    let temp = 0;
    debugger;
    _.forEach(props.node.allLeafChildren, (v) => {
      let data = v.data[props.column.colId];
      if (data) {
        temp = temp + data * 1;
      }
    });
    setValue(temp);
  }, []);

  return <div style={{ fontWeight: 'bold' }}>{value}</div>;
}
