import create from 'zustand'
import { ExtractQuantityGanttComponentType } from './constants/ExtractQuantityGanttComponentType'


const initialState = {
    type: ExtractQuantityGanttComponentType.List,
    extractQuantityGanttLoading:false,

    gantt5D:null


}
export const extractQuantityGanttStore = create((set) => ({
    ...initialState,
    setType: (value) => set((state) => ({ type: value })),
    setExtractQuantityGanttLoading: (value) => set((state) => ({ extractQuantityGanttLoading: value })),
    setGantt5D: (value) => set((state) => ({ gantt5D: value })),

    resetExtractQuantityGanttStore: () => set(initialState)
}))