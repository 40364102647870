import React from 'react';

/**
 * @returns {React.Element} The rendered input element.
 */
const TopPageToolbar = ({ left, right }) => {
  return (
    <div className='flex flex-col gap-5 bg-gray-200 p-1'>
      <div className='flex items-center justify-between gap-5'>
        <div className='flex h-[35px] justify-between gap-2'>{left}</div>

        <div className='flex h-[35px] justify-between gap-2'>
          <div className='flex justify-center gap-1'>{right}</div>
        </div>
      </div>
    </div>
  );
};

export default TopPageToolbar;
