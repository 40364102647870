import { Popover, Upload } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';
import IconTextButton from '../../../../../../gen/button/IconTextButton.gen';
import { timelineStore } from '../../Timeline.store';
import ColorConfigurationTable from '../../table/ColorConfiguration.table';
import { v4 } from 'uuid';
import { DefaultColorTimeline } from '../../../../util/Timeline.util';

export default function TimelineConfigurationTable() {
  const { setAppearanceData, setConfigurationData } = timelineStore();
  useEffect(() => {
    const data = [
      DefaultColorTimeline.None,
      DefaultColorTimeline.ModelAppearance,
      DefaultColorTimeline.Hide,
      DefaultColorTimeline.White,
      DefaultColorTimeline.Gray,
      DefaultColorTimeline.Red,
      DefaultColorTimeline.Green,
      DefaultColorTimeline.Yellow,
      DefaultColorTimeline.Purple,
    ];
    let config = [
      {
        id: v4(),
        name: 'Construct',
        startAppearance: DefaultColorTimeline.Green,
        endAppearance: DefaultColorTimeline.ModelAppearance,
        earlyAppearance: DefaultColorTimeline.None,
        lateAppearance: DefaultColorTimeline.None,
      },
      {
        id: v4(),
        name: 'Demolish',
        startAppearance: DefaultColorTimeline.Red,
        endAppearance: DefaultColorTimeline.None,
        earlyAppearance: DefaultColorTimeline.None,
        lateAppearance: DefaultColorTimeline.None,
      },
      {
        id: v4(),
        name: 'Temporary',
        startAppearance: DefaultColorTimeline.Yellow,
        endAppearance: DefaultColorTimeline.None,
        earlyAppearance: DefaultColorTimeline.None,
        lateAppearance: DefaultColorTimeline.None,
      },
    ];
    setConfigurationData(config);
    setAppearanceData(data);
  }, []);

  return (
    <>
      <ColorConfigurationTable />
    </>
  );
}
