import { useRef } from 'react';

import { Tooltip } from 'antd';
import icons from '../constant/Icon.constant';

export default function Icon({
  url,
  size = '',
  ghost,
  active,
  onClick = () => {},
  style = {},
  iconStyle = {},
  border = true,
  title = '',
  tooltipColor = '#2a425e',
  tooltipPlacement = 'top',
  loading,
  disabled = false,
  circle = false,
  type = 'save',
  icon,
}) {
  const isGhost = ghost ? 'ghost' : '';
  const isBorder = border ? 'border' : '';
  const isLoading = loading ? 'loading' : '';
  const isCircle = circle ? 'circle' : '';
  const ref = useRef(null);

  const handleClick = () => {
    if (loading) return;
    onClick();
  };
  return (
    <>
      {title !== '' ? (
        <Tooltip
          title={title}
          zIndex={10000000000}
          color={tooltipColor}
          placement={tooltipPlacement}
        >
          <div style={{ display: 'flex', gap: 5 }}>
            <div
              ref={ref}
              className={`idd-icon-button ${type} ${isGhost} ${
                active ? 'active' : ''
              } ${isBorder} ${size} ${isCircle}`}
              onClick={handleClick}
              style={style}
              disabled={disabled}
            >
              <div
                className={`icon  ${isLoading}`}
                style={{
                  ...iconStyle,
                  WebkitMaskImage: `url(${icons[icon] ? icons[icon] : url})`,
                }}
              ></div>
            </div>
          </div>
        </Tooltip>
      ) : (
        <div style={{ display: 'flex', gap: 5 }}>
          <div
            ref={ref}
            className={`idd-icon-button ${type}  ${isGhost} ${
              active ? 'active' : ''
            } ${isBorder} ${size} ${isCircle}`}
            onClick={handleClick}
            style={style}
            disabled={disabled}
          >
            <div
              className={`icon  ${isLoading}`}
              style={{
                ...iconStyle,
                WebkitMaskImage: `url(${icons[icon] ? icons[icon] : url})`,
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}
