import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { GetAllUserPermissionAPI } from 'src/api';
import { appStore } from 'src/store/App.store';

export default function OrganizationRoute() {
  const { setIsLoading, permission, setPermission } = appStore();
  const [isProcess, setIsProcess] = useState(true);
  const navigate = useNavigate();
  const { organizationId, projectId } = useParams();

  useEffect(() => {
    if (organizationId || projectId) {
      run();
    } else {
      setIsProcess(false);
      setIsLoading(false);
    }
  }, [organizationId, projectId]);

  const run = async () => {
    try {
      setIsProcess(true);
      setIsLoading(true);
      const repData = await GetAllUserPermissionAPI();
      if (repData) {
        setPermission(repData);
      }
      setIsLoading(false);
      setIsProcess(false);
    } catch {
      setIsProcess(false);
      setIsLoading(false);
    }
  };
  return (
    <>
      {!isProcess && (
        <>
          <Outlet />
        </>
      )}
    </>
  );
}
