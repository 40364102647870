import { useEffect } from 'react';

export default function PaymentCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  useEffect(() => {
    props.node.data[`${props.column.colId}_processed`] = cellValue;
  }, [cellValue]);
  return <span> {`$ ${cellValue ?? 0}`}</span>;
}
