import { Upload, Button, DatePicker } from 'antd';
import React, { useEffect, useRef, useState, } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import IconButton from '../../../../gen/button/IconButton.gen';
import { getElementProperty } from '../../../../../functions/Viewer.function';
import { viewerStore } from '../../../../../store/Viewer.store';
import { TreeModelData } from '../../util/ExtractQuantity.util';
import { extractQuantityGanttStore } from './ExtractQuantityGantt.store';


const gantt = window.gantt

const listCategory = ['Revit Walls', 'Revit Floors', 'Revit Structural Columns', 'Revit Structural Framing', 'Revit Structural Foundations', 'Revit Stairs']
export default function ExtractQuantityGanttToolbar() {
  const [file, setFile] = useState()
  const { viewer } = viewerStore()
  const { setTreeModelData, treeModelData } = viewerStore()
  const {gantt5D } =extractQuantityGanttStore()
  // useEffect(() => {
  //   treeModelData.addEventListener('done', handleDone)
  //   return () => {
  //     treeModelData.removeEventListener('done', handleDone)
  //   }
  // }, [])

  // const handleDone = () => {

  // }
  const handleExtractModel = () => {
    if(!gantt5D) return
    console.log(gantt5D)
    let queue = [];
    let temp = [];

    queue = queue.concat(treeModelData.data);
    while (queue.length > 0) {
      const node = queue.shift();
      _.forEach(node.children, child => {
        if (child.isLeaf) {
          temp.push(child)
        }
        queue.push(child)
      })

    }

    var ganttDataset = {
      data: [],
      links: []
    };

    _.forEach(temp, (item) => {
      if (listCategory.includes(item.category)) {
        var copy = {};
        copy.text = item.name
        copy.category = item.category
        copy.start_date = moment().format('DD-MM-YYYY')
        copy.duration = 5
        copy.volume = item.volume
        copy.material_price = 0
        copy.material_cost = item.volume * 0
        copy.labor_cost = 0
        copy.equipment_cost = 0
        copy.total_cost = 0
        copy.actual_start = null
        copy.actual_end = null
        // copy.open = true;
        ganttDataset.data.push(copy);
      }

    });
    let groups = []
    _.forEach(listCategory, (v, k) => {
      groups.push({ key: v, label: v })
    })
    gantt5D.groupBy({
      relation_property: "category",
      groups,
      group_id: "key",
      group_text: "label",
      volume: 12
    });

    gantt5D.parse(ganttDataset);

  }
  function to_snake_case(name) {
    return (name + "").toLowerCase().replace(/ /, "_");
  }


  return (
    <>
      <div style={{ display: 'flex', gap: 5, padding: 5 }}>

        <IconButton
          url='https://img.icons8.com/ios-filled/50/course-assign.png'
          size='ssmall'
          type="save"
          onClick={handleExtractModel.bind(this)}
        />

        {/* <IconButton
          icon='save'
          size='ssmall'
          type="save"
        // onClick={handleTranslateItem.bind(this, params.data, params)}
        /> */}
        {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', padding: 5 }}  >
          <Upload
            customRequest={handleChangeModel}
            showUploadList={false}
          >
            <Button className='idd-custom-button' icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          <Button
            className='idd-custom-button'
            onClick={handleLoadGant}
            disabled={!file}

          >
            Start
          </Button>
        </div> */}
        {/* <div style={{ width: '100%' }}>
          <DatePicker.RangePicker style={{ width: '100%' }} onChange={handleRangePicker} value={area} />
        </div> */}
      </div>


    </>

  );
}

