/* eslint-disable no-unused-vars */
import { message } from 'antd';
import _ from 'lodash';
import { useRef } from 'react';
import { UpdateUserAPI } from 'src/api';
import LabelAndICheckboxForm from 'src/components/molecules/LabelAndICheckboxForm';
import LabelAndSelectForm from 'src/components/molecules/LabelAndSelectForm';
import Modal from 'src/components/molecules/Modal';
import { updateTableAsync } from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';

export default function ModalEditAppUser({ setRowNode, rowNode, gridApi }) {
  const formRef = useRef();
  const { setIsLoading } = appStore();

  const close = () => {
    setIsLoading(false);
    setRowNode();
  };

  const handleOk = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};
      debugger;
      formData.forEach((value, key) => {
        data[key] = value;
      });
      data.is_confirm = data.is_confirm === 'on';
      const resData = await UpdateUserAPI({
        email: rowNode.data.email,
        ...data,
      });
      if (resData) {
        _.forEach(resData, (v, k) => {
          if (rowNode.data[k]) {
            rowNode.data[k] = v;
          }
        });
        await updateTableAsync(gridApi.api, {
          update: [rowNode.data],
        });
        gridApi.api.redrawRows();
        close();
      }
    } catch (ex) {
      if (ex.message) message.error(ex.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Edit App User'}>
        <div className='max-w-screen-sm'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndSelectForm
              label='Plan'
              name='plan'
              placeholder='Select the plan'
              isRequired={true}
              items={['No Plan', 'Basic', 'Pro'].map((v) => (
                <option value={v}>{v}</option>
              ))}
            />

            <LabelAndICheckboxForm
              label='Comfirm'
              name='is_confirm'
              defaultChecked={rowNode?.data?.is_confirm}
            />
          </form>
        </div>
      </Modal>
    </>
  );
}
