import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dmsStore } from "../../../../../../store/DMS.store";
import IconButton from "../../../../../gen/button/IconButton.gen";
import { appStore } from "../../../../../../store/App.store";
import { AppAPI } from "../../../../../api";
import { message } from 'antd'
import { APP_COLOR } from "../../../../../../config";


export default function ListGroup() {
    const { consultantGroup, setConsultantGroup, setSelectedConsultantGroupId
        , selectedConsultantGroupId, setIsModalDMSSettingEditGroup, setEditConsultantGroupId } = dmsStore()
    const { setIsLoading, } = appStore()
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setIsLoading(true)
        try {
            const groups = await AppAPI.DMSettingAPI.GetDMSSettingGroups()
            setConsultantGroup(groups)
        } catch (ex) {
            message.warning(ex.message)
        }
        setIsLoading(false)
    }

    const handleSelect = (id, e) => {
        e.stopPropagation()
        setSelectedConsultantGroupId(id)
    }
    const handleDelete = async (id, e) => {
        e.stopPropagation()
        if (!window.confirm('Are you want to delete?')) return
        setIsLoading(true)
        try {
            await AppAPI.DMSettingAPI.DeleteDMSSettingGroupOne({ id })
            const index = consultantGroup.findIndex(v => { return v.id === id })
            debugger
            if (index >= 0) {
                consultantGroup.splice(index, 1)
                setConsultantGroup([...consultantGroup])
            }
            if (selectedConsultantGroupId === id) {
                setSelectedConsultantGroupId(null)
            }
        } catch (ex) {
            message.warning(ex.message)
        }
        setIsLoading(false)
    }
    const handleEdit = async (id, e) => {
        e.stopPropagation()
        setEditConsultantGroupId(id)
        setIsModalDMSSettingEditGroup(true)

    }
    return (
        <div style={{ height: '100%', overflow: 'auto', padding: '5px 2px', display: 'flex', gap: 5, flexDirection: 'column' }}>
            {consultantGroup.map(v =>
                <div style={{
                    display: 'flex', justifyContent: 'space-between', border: '1px solid black',
                    padding: '5px 5px', borderRadius: 5,
                    background: v.id === selectedConsultantGroupId ? APP_COLOR.MAIN : '',
                    cursor: 'pointer',
                    color: v.id === selectedConsultantGroupId ? 'white' : 'black'
                }}
                    onClick={handleSelect.bind(this, v.id)}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <span style={{ fontWeight: 'bolder', }}>{v.name}</span>
                        <span style={{
                            color: v.id === selectedConsultantGroupId ? 'white' : 'gray',
                            fontSize: 12
                        }}>
                            Company: {v?.companyName}</span>
                        <span style={{
                            color: v.id === selectedConsultantGroupId ? 'white' : 'gray',
                            fontSize: 12
                        }}>
                            Members: {v?.member_length ?? 0}</span>
                    </div>
                    <div style={{ display: 'flex', gap: 2 }}>
                        <IconButton icon='edit' size="sssmall"
                            onClick={handleEdit.bind(this, v.id)} />
                        <IconButton icon='delete' type='delete' size="sssmall"
                            onClick={handleDelete.bind(this, v.id)} />
                    </div>



                </div>

            )}
        </div>

    );
}