import create from 'zustand'


const initialState = {
    project: '',
    folderId: '',
    fileName: '',
    type: '3d',
    fileData: [],
    isAddFile: false,
    isHideTabNav: false
}
export const projectStore = create((set) => ({
    ...initialState,
    setProject: (value) => set((state) => ({ project: value })),
    setFolder: (value) => set((state) => ({ folder: value })),
    setFileName: (value) => set((state) => ({ fileName: value })),
    setType: (value) => set((state) => ({ type: value })),
    setFileData: (value) => set((state) => ({ fileData: value })),
    setIsAddFile: (value) => set((state) => ({ isAddFile: value })),
    setIsHideTabNav: (value) => set((state) => ({ isHideTabNav: value })),
    resetProjectStore: () => set(initialState)
}))