import InputTextAreaForm from 'src/components/atoms/InputTextAreaForm';
import LabelForm from 'src/components/atoms/LabelForm';

/**
 * @param {string} label - The text content of the label.
 * @param {string} type - The type of the input element (e.g., text, email, password).
 * @param {string} placeholder - The placeholder text for the input element.
 * @param {string} name - The name attribute for the input element.
 * @param {boolean} isRequired - Whether the input element is required.
 * @param {string} [defaultValue] - The default value of the input element (optional).
 * @returns {JSX.Element} The rendered label and input elements.
 */
const LabelAndTextAreaForm = ({
  label,
  type,
  placeholder,
  name,
  isRequired,
  defaultValue,
}) => {
  return (
    <div className='w-full'>
      <LabelForm name={name} label={label} isRequired={isRequired} />
      <InputTextAreaForm
        name={name}
        type={type}
        placeholder={placeholder}
        isRequired={isRequired}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default LabelAndTextAreaForm;
