import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Form, Checkbox, Radio, DatePicker, Select } from 'antd';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import { requestBimGrid } from '../../../../../functions/General.function';
import IconButton from '../../../../gen/button/IconButton.gen';
import { exportMarkup, exportMarkupImage } from '../../util/Coordination.util';





export default function ViewAdd({ setType, setLoading }) {
    const [search] = useSearchParams();
    const [form] = Form.useForm();
    const { viewer, setViewData, viewData } = viewerStore()
    const [image, setImage] = useState()
    const [viewState, setViewState] = useState()
    const fileId = search.get('fileId')
    const versionId = search.get('versionId')
    const { organizationId, projectId } = useParams()
    useEffect(() => {
        if (!viewer)
            setType('list')
        handleRefresh()
    }, [])


    const handleAdd = () => {
        form.validateFields()
            .then(async values => {
                setLoading(true)
                const markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                const tempModels = []
                const models = viewer.impl.modelQueue().getModels()
                _.forEach(models, v => {
                    if (v.id === viewer.impl.model.id) {
                        tempModels.push({
                            fileId, guid: viewer.impl.model.getDocumentNode().data.guid, versionId,
                            name: v.myData.loadOptions.modelNameOverride
                        })
                    } else {
                        tempModels.push({
                            fileId: v.myData.loadOptions.fileId, guid: v.myData.loadOptions.guid,
                            versionId: v.myData.loadOptions.versionId, name: v.myData.loadOptions.modelNameOverride
                        })
                    }
                })
                let clone = { ...values }
                clone.view_state = viewState
                clone.file_id = fileId
                clone.version_id = versionId
                clone.models = tempModels
                clone.status = 'Open'
                clone.markup = await exportMarkup(viewer)
                let data = await requestBimGrid('post', '/api/view', { data: clone, image, organizationId, projectId }, { organizationId, projectId })
                if (data) {
                    let cloneIssueData = [...viewData]
                    cloneIssueData.splice(0, 0, data)
                    setViewData(cloneIssueData)
                    setType('list')
                }
                setLoading(false)
            })
            .catch(ex => {
                setLoading(false)
            })
    }
    const handleRefresh = async () => {
        let img =  await exportMarkupImage(viewer)
        setViewState(viewer.getState())
        setImage(img)
    }
    const handleBack = () => {
        setType('list')
    }
    return (
        <>
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={handleBack} />
                <span style={{ fontWeight: 'bold' }} >   New View</span>

            </div>

            <div style={{ height: 'calc(100% - 75px)', overflow: 'auto', width: '100%', position: 'relative', padding: 5 }}>
                <div id={'issue-image'} style={{ backgroundImage: `url(${image})`, gap: 5, height: 200 }} >
                    {/* <IconButton
                        url={'https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/40/000000/external-zoom-in-interface-kiranshastry-lineal-kiranshastry.png'}
                        size='small'
                        onClick={handleRefresh}
                    /> */}
                    <IconButton
                        url={'https://img.icons8.com/ios-glyphs/40/000000/refresh--v1.png'}
                        size='small'
                        onClick={handleRefresh}
                    />
                </div>

                <Form
                    form={form}
                    autoComplete={false}
                    layout="vertical"
                >
                    <Form.Item
                        name='title'
                        rules={[{ required: true, message: 'Please input your title!', },]}
                        label='Title'
                    >
                        <Input className='idd-input'
                            placeholder="Title"
                        />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        rules={[{ required: true, message: 'Please input your description!', },]}
                        label='Description'
                    >
                        <Input.TextArea className='idd-input'
                            placeholder="Description"
                        />
                    </Form.Item>
                </Form>
            </div>
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <Button className={`idd-custom-button block save`} onClick={handleAdd}
                >Add</Button>

            </div>


        </>

    );
}


