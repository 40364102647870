import React from 'react';
import IconButton from '../../../../gen/button/IconButton.gen';
import { workerStore } from '../../../../../store/Worker.store';
import { DatePicker as DatePickerDayjs } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function WorkerDailyToolbar() {
  const { setIsOpenAddWorkerDaily, dailyDate, setDailyDate } = workerStore();
  const handleAddFolder = () => {
    setIsOpenAddWorkerDaily(true);
  };
  const handleChangeDaily = (e) => {
    setDailyDate(e.startOf('date'));
  };
  return (
    <>
      <div
        style={{
          padding: '5px 2px',
          display: 'flex',
          gap: 5,
          alignItems: 'center',
        }}
      >
        <IconButton
          icon={'add'}
          size='ssmall'
          // type='save'
          onClick={handleAddFolder}
        ></IconButton>
        <DatePicker
          allowClear={false}
          value={dailyDate}
          onChange={handleChangeDaily}
        />
      </div>
    </>
  );
}
