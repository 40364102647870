/* eslint-disable no-unused-vars */
import { message, Slider } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppAPI } from 'src/components/api';
import IconButton from 'src/components/gen/button/IconButton.gen';
import { workerStore } from 'src/store/Worker.store';
import IconStatic from '../../../../gen/icon/IconStatic.gen';

const today = moment();

const workerTimes = [
  {
    x: 12.819637784750817,
    y: -1.1041057528624036,
    z: -1.82499682877733,
  },
  {
    x: 12.819637784750817,
    y: -0.1041057528624036,
    z: 2.82499682877733,
  },
  {
    x: 12.819637784750817,
    y: 0.1041057528624036,
    z: 1.82499682877733,
  },
  {
    x: 9.858941128058952,
    y: 5.6768445474390195,
    z: -1.82499682877733,
  },
  {
    x: 9.858941128058952,
    y: 4.6768445474390195,
    z: -1.82499682877733,
  },
  {
    x: 8.858941128058952,
    y: 4.6768445474390195,
    z: -1.82499682877733,
  },
  {
    x: 8.139826940302378,
    y: -5.6827769938221047,
    z: -1.8249968287684624,
  },
];
const formatter = (value) =>
  `${today.clone().subtract(value, 'minute').format('HH:mm:ss')}`;
export default function WorkerTimeline({ index, item, node }) {
  const { projectId } = useParams();
  const [isEditing, setIsEditting] = useState();
  const { dailyDate, trackingDevices } = workerStore();
  const [timelineData, setTimelineData] = useState(workerTimes);
  const handleEditMode = () => {
    (async () => {
      try {
        const edit = !isEditing;
        if (edit) {
          const result =
            await AppAPI.WorkerTrackingAPI.GetWorkerTrackingPosition({
              projectId,
              workerId: item.workerId,
              currentDate: node.data.date ?? dailyDate,
            });

          if (result) {
            const tmp = [];
            _.forEach(result, (v) => {
              const el = JSON.parse(v);
              tmp.push({ ...el.position, time: moment(el.time) });
            });
            // setTimelineData(tmp);
            setIsEditting(true);
          } else {
            setIsEditting(false);
          }
        } else {
          setIsEditting(false);
        }
      } catch (ex) {
        message.warning(ex.message);
      }
    })();
  };
  const handleChangePosition = (e) => {
    item.isEditing = true;
    trackingDevices.devices[index].location = [
      timelineData[e].x,
      timelineData[e].y,
      timelineData[e].z,
    ];
    trackingDevices.reGenerateDevice();
  };
  useEffect(() => {
    item.isEditing = false;
  }, [isEditing]);
  return (
    <div className='flex w-full flex-col'>
      <div className='flex justify-between gap-1'>
        <div className='flex gap-1'>
          <IconStatic size='sssmall' icon='worker' />
          <span
            className={`font-bold ${item.status === 'offline' ? 'text-red-600' : 'text-black'} `}
          >
            {item.name}
          </span>
        </div>
        <div>
          <IconButton size='sssmall' icon='view' onClick={handleEditMode} />
        </div>
      </div>
      {isEditing && (
        <div className='px-2'>
          <Slider
            min={0}
            max={timelineData.length - 1}
            onChange={handleChangePosition}
            tooltip={{ formatter }}
          />
        </div>
      )}
    </div>
  );
}
