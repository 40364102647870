import { PlusCircleOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { isMobile } from 'is-mobile';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from 'src/components/atoms/PrimaryButton';
import { requestBimGrid } from '../../../functions/General.function';
import { adminStore } from '../../../store/Admin.store';
import { appStore } from '../../../store/App.store';
// import IconButton from '../../gen/button/IconButton.gen';
import { ReactComponent as GridIcon } from 'src/assets/icons/general/grid.svg';
import { ReactComponent as TableIcon } from 'src/assets/icons/general/table.svg';
import IconButton from 'src/components/atoms/IconButton';
import PageHeader from 'src/components/atoms/PageHeader';
import TopPageToolbar from 'src/components/organisms/TopPageToolbar';
import { validatePermission } from 'src/functions/Auth.function';
import { AlertPermissionService } from 'src/services';
import ModalAddProject from './modal/ModalAddProject';
import ModalEditProject from './modal/ModalEditProject';
import ProjectListCard from './ProjectList.card';
import ProjectListTable from './ProjectList.table';
export default function LayOutProjectList({ gotoProject }) {
  const {
    isAddProject,
    isGridLayout,
    setIsGridLayout,
    setIsAddProject,
    projects,
    setIsEditProject,
    isEditProject,
    editProjectId,
    setProjects,
    setEditProjectId,
  } = adminStore();
  const { setIsLoading, permission } = appStore();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const handleViewItem = (id, { requireActions }, e) => {
    const { allowed, permissionsMissing } = validatePermission(
      permission,
      requireActions,
      [],
      organizationId,
      id
    );
    if (allowed) {
      gotoProject(id);
    } else {
      AlertPermissionService.showAlert(permissionsMissing, 0);
    }
  };
  const handleSettingItem = (id, e) => {
    e.stopPropagation();
    navigate(`./project/${id}/management`);
  };
  const handleDeleteItem = async (id, e) => {
    e.stopPropagation();
    if (!window.confirm('Are you want to delete?')) return;
    setIsLoading(true);
    let data = await requestBimGrid('delete', '/api/project', { id: id });
    if (data) {
      let clone = [...projects];
      let index = _.findIndex(projects, (v) => {
        return v.id === id;
      });
      if (index >= 0) {
        clone.splice(index, 1);
        setProjects(clone);
      }
    }
    setIsLoading(false);
  };
  const handleEditItem = (id, e) => {
    e.stopPropagation();
    setEditProjectId(id);
    setIsEditProject(true);
  };

  return (
    <>
      <TopPageToolbar
        left={
          <>
            <PageHeader>Project List</PageHeader>
            <PrimaryButton
              requireActions={['create.project']}
              onClick={setIsAddProject.bind(this, true)}
            >
              Add
            </PrimaryButton>
          </>
        }
        right={
          <div className='flex justify-center gap-1'>
            <IconButton
              className={`${isGridLayout ? 'bg-primary-600 dark:text-white' : ''} bg-primary-100`}
              onClick={setIsGridLayout.bind(this, true)}
            >
              <GridIcon />
            </IconButton>
            <IconButton
              className={`${!isGridLayout ? 'bg-primary-600 dark:text-white' : ''} bg-primary-100`}
              onClick={setIsGridLayout.bind(this, false)}
            >
              <TableIcon />
            </IconButton>
          </div>
        }
      />

      {isGridLayout ? (
        <ProjectListCard
          handleViewItem={handleViewItem}
          handleDeleteItem={handleDeleteItem}
          handleEditItem={handleEditItem}
          handleSettingItem={handleSettingItem}
        />
      ) : (
        <ProjectListTable
          handleViewItem={handleViewItem}
          handleDeleteItem={handleDeleteItem}
          handleEditItem={handleEditItem}
          handleSettingItem={handleSettingItem}
        />
      )}
      {isMobile() && (
        <FloatButton
          type='primary'
          style={{ fontSize: 35 }}
          icon={<PlusCircleOutlined />}
        />
      )}
      {isAddProject && <ModalAddProject />}
      {isEditProject && editProjectId && <ModalEditProject />}
    </>
  );
}
