/* eslint-disable no-unused-vars */
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { appStore } from '../../../../../store/App.store';
import { viewerStore } from '../../../../../store/Viewer.store';
import { AppAPI } from '../../../../api';
import IconButton from '../../../../gen/button/IconButton.gen';
import IconStatic from '../../../../gen/icon/IconStatic.gen';
import HubDevicePicker from '../../../viewer/util/HubDevicePicker.util';

const temp = [
  {
    x: 12.819637784750817,
    y: -1.1041057528624036,
    z: -1.82499682877733,
  },
  {
    x: 9.858941128058952,
    y: 5.6768445474390195,
    z: -1.82499682877733,
  },
  {
    x: 8.139826940302378,
    y: -3.6827769938221047,
    z: -1.8249968287684624,
  },
  {
    x: 12.628625097222304,
    y: -8.076068737679364,
    z: -1.82499682877733,
  },
  {
    x: 13.010650472279316,
    y: -16.28961417184729,
    z: -1.82499682877733,
  },
  {
    x: 5.3223897992568965,
    y: -10.511480465252404,
    z: -1.82499682877733,
  },
  {
    x: -0.36023765471620095,
    y: -16.38512051410505,
    z: -1.8249968287861975,
  },
  {
    x: 1.4066297049225014,
    y: -9.795182898319158,
    z: -1.82499682877733,
  },
  {
    x: 6.516219096310074,
    y: -0.19679550141362334,
    z: -1.82499682877733,
  },
  {
    x: 1.5498892205688861,
    y: 2.2386162261594222,
    z: -1.82499682877733,
  },
  {
    x: -8.192568040561326,
    y: 0.23298269828520723,
    z: -1.8249968287861975,
  },
  {
    x: -9.863929056435776,
    y: -6.929992971047284,
    z: -1.82499682877733,
  },
  {
    x: -14.113961353945065,
    y: -2.5844543983189054,
    z: -1.82499682877733,
  },
  {
    x: -20.9429552340513,
    y: -8.362588104913781,
    z: -1.82499682877733,
  },
  {
    x: -17.2182078272454,
    y: -0.14904267074586297,
    z: -1.82499682877733,
  },
  {
    x: -11.822099404565066,
    y: 4.5307680998847015,
    z: -1.8249968287684624,
  },
  {
    x: -4.993395825420926,
    y: 6.870673485199978,
    z: -1.82499682877733,
  },
  {
    x: -3.895072872132012,
    y: 10.452161319866226,
    z: -1.82499682877733,
  },
  {
    x: 2.981383878894256,
    y: 10.547667662123986,
    z: -1.82499682877733,
  },
  {
    x: 0.45046576914153036,
    y: 16.46906088210551,
    z: -1.82499682877733,
  },
  {
    x: -10.819282795040401,
    y: 14.7976998925946,
    z: -1.82499682877733,
  },
  {
    x: -16.597416592777748,
    y: 16.039282341945572,
    z: -1.8249968287684624,
  },
  {
    x: -18.841815671237708,
    y: 10.49991449099511,
    z: -1.82499682877733,
  },
];
export default function HubDeviceList({
  blockId,
  hubDevices,
  setHubDevices,
  isViewer,
}) {
  const { setIsLoading } = appStore();
  const { viewer } = viewerStore();
  const [hubDevicePicker, setHubDevicePicker] = useState();
  const { projectId } = useParams();

  useEffect(() => {
    if (hubDevicePicker && viewer) {
      hubDevicePicker.viewer = viewer;
    }
  }, [hubDevicePicker, viewer]);
  useEffect(() => {
    if (hubDevicePicker) {
      hubDevicePicker.addEventListener(
        hubDevicePicker.event.add,
        hanleAddDevice
      );
      hubDevicePicker.addEventListener(
        hubDevicePicker.event.update,
        hanleUpdateDevice
      );
      return () => {
        hubDevicePicker.removeEventListener(
          hubDevicePicker.event.add,
          hanleAddDevice
        );
        hubDevicePicker.removeEventListener(
          hubDevicePicker.event.update,
          hanleUpdateDevice
        );
      };
    }
  }, [hubDevicePicker, hubDevices]);
  const hanleAddDevice = async (e) => {
    if (hubDevicePicker.currentItem.hubDeviceInBlockId) {
      return;
    }
    setIsLoading(true);
    try {
      debugger;
      const resHubDeviceBlock =
        await AppAPI.WorkerSettingAPI.CreateHubDeviceWBlockZone({
          data: {
            block_id: blockId,
            hub_device_id: hubDevicePicker.currentItem.id,
            location: e.point,
          },
        });
      hubDevicePicker.currentItem.location = e.point;
      hubDevicePicker.currentItem.active = false;
      hubDevicePicker.currentItem.hubDeviceInBlockId = resHubDeviceBlock.id;
      hubDevicePicker.currentItem = null;
      hubDevicePicker.reGenerateDevice();
      setHubDevices([...hubDevices]);
    } catch (e) {
      if (e.message) message.warning(e.message);
    }
    setIsLoading(false);
  };
  const hanleUpdateDevice = async (e) => {
    debugger;
    if (!hubDevicePicker.currentItem.hubDeviceInBlockId) {
      return;
    }

    setIsLoading(true);
    try {
      await AppAPI.WorkerSettingAPI.UpdatesHubDevicesWBlockZone({
        data: {
          id: hubDevicePicker.currentItem.hubDeviceInBlockId,
          location: e.point,
        },
      });
      hubDevicePicker.currentItem.location = e.point;
      hubDevicePicker.currentItem.active = false;
      hubDevicePicker.currentItem = null;
      hubDevicePicker.reGenerateDevice();
      setHubDevices([...hubDevices]);
    } catch (e) {
      if (e.message) message.warning(e.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (viewer) {
      const runLocal = async () => {
        const resHubDevices =
          await AppAPI.WorkerSettingAPI.GetAllHubDeviceWBlockZoneByBlock({
            projectId,
            blockId: blockId,
          });
        resHubDevices.forEach((v) => {
          if (v.hubDeviceBlockBlockId !== blockId) {
            v.location = null;
            v.hubDeviceInBlockId = null;
            v.hubDeviceBlockBlockId = null;
          }
        });
        setHubDevices(resHubDevices);
        temp.forEach((v, k) => {
          resHubDevices.push({
            hubDeviceInBlockId: v4(),
            hubDeviceBlockBlockId: v4(),
            location: [v.x, v.y, v.z],
            name: `Hub ${parseInt(k) + 4}`,
          });
        });

        if (!isViewer) {
          const hubPicker = new HubDevicePicker(viewer);
          await hubPicker.init(resHubDevices);
          hubPicker.load();
          setHubDevicePicker(hubPicker);
          return () => {
            if (hubPicker) {
              hubPicker.unload();
            }
          };
        }
      };
      if (blockId)
        viewer.waitForLoadDone().then((res) => {
          runLocal();
        });
    }
  }, [viewer]);
  const handleAdd = async (item) => {
    const isDone = await viewer?.isLoadDone();
    if (!isDone) {
      message.warning('You have to wait the model finish loading');
      return;
    }
    const previousState = item.active;
    hubDevices.forEach((v) => {
      v.active = false;
    });
    item.active = !previousState;
    hubDevicePicker.setEnabled(item.active, item);
    setHubDevices([...hubDevices]);
  };
  const handleDelete = async (item) => {
    if (!item.hubDeviceInBlockId) {
      return;
    }
    setIsLoading(true);
    try {
      await AppAPI.WorkerSettingAPI.DeleteHubDeviceWBlockZone({
        id: item.hubDeviceInBlockId,
      });

      item.hubDeviceInBlockId = null;
      item.location = null;
      hubDevicePicker.reGenerateDevice();
      setHubDevices([...hubDevices]);
    } catch (e) {
      if (e.message) message.warning(e.message);
    }
    setIsLoading(false);
  };
  return (
    <>
      <div
        style={{ display: 'flex', gap: 5, flexDirection: 'column', width: 200 }}
      >
        <span style={{ fontWeight: 'bold' }}>Hub Device</span>
        <div className='flex size-full h-[300px] flex-col overflow-auto'>
          {hubDevices?.map((v) => (
            <div
              style={{
                display: 'flex',

                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <div style={{ display: 'flex', gap: 5 }}>
                <IconStatic size='sssmall' icon='hub_device' />
                <span>{`${v.name} ${
                  v.hubDeviceInBlockId ? '' : '(Not place yet)'
                }`}</span>
              </div>

              <div style={{ display: 'flex', gap: 5 }}>
                {!isViewer && (
                  <>
                    <IconButton
                      size='sssmall'
                      icon='pointer'
                      type={v.active ? 'save' : 'default'}
                      onClick={handleAdd.bind(this, v)}
                    />
                    {v.hubDeviceInBlockId && (
                      <IconButton
                        size='sssmall'
                        icon='delete'
                        type={'delete'}
                        onClick={handleDelete.bind(this, v)}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
