import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { updateTableAsync } from '../../../../functions/General.function';
import { AppAPI } from '../../../api';

export const updateWorkerListPayment = async (node, gridApi) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resWorker = await AppAPI.WorkerSettingAPI.UpdateOnePayment({
        id: node.data.id,
        date: moment(new Date()),
      });
      if (gridApi) {
        _.forEach(resWorker, (v, k) => {
          node.data[k] = v;
        });
        await updateTableAsync(gridApi.api, {
          update: [node.data],
        });
      }

      resolve();
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
      reject(ex);
    }
  });
};

export const calculateDistance = (rssi) => {
  // Path loss exponent (n) and RSSI at 1 meter (txPower) must be determined
  const txPower = -63; // Example value

  if (rssi === 0) {
    return -1.0;
  }

  const ratio = rssi / txPower;
  if (ratio < 1.0) {
    return Math.pow(ratio, 10);
  } else {
    return 0.89976 * Math.pow(ratio, 7.7095) + 0.111;
  }
};
