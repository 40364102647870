import { requestBimGrid } from '../../../../functions/General.function';

export const CraeteWebhookModelDerivative = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/forge/webhook/create-webhook-model-derivative',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
