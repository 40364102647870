import { Route } from 'react-router-dom';
import { FiveDPage } from 'src/components/pages/apps';

import { FiveDRoute } from 'src/routes/apps';

export const FiveDAppRoutes = [
  <Route path=':projectId'>
    <Route path='5d' element={<FiveDRoute />}>
      <Route index element={<FiveDPage />} />
    </Route>
  </Route>,
];
