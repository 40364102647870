import React from 'react';
import { workerStore } from '../../../../../../store/Worker.store';
import { message } from 'antd';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { appStore } from '../../../../../../store/App.store';
import { AppAPI } from '../../../../../api';
import { updateTableAsync } from '../../../../../../functions/General.function';
import moment from 'moment';
import _ from 'lodash';
import { updateWorkerListPayment } from '../../Worker.utits';
export default function PayRateActionCell(props) {
  const { setIsLoading } = appStore();
  const {
    payRateGridApi,
    setPayRateEditData,
    payRateEditData,
    nodeEditWorkerList,
    workerListGridApi,
  } = workerStore();
  const handleEditItem = async (e) => {
    if (e) {
      debugger;
      const start_date = moment(new Date(props.node.data.start_date));
      const end_date = moment(new Date(props.node.data.end_date));
      setPayRateEditData({
        worker_id: nodeEditWorkerList.data.id,
        id: props.node.data.id,
        pay_rate: props.node.data.pay_rate,
        start_date,
        end_date,
      });
    } else if (payRateEditData) {
      try {
        if (window.confirm('Are you want to update?')) {
          setIsLoading(true);
          const payRateRes =
            await AppAPI.WorkerPayRateAPI.UpdateOneWorkerPayRate({
              data: payRateEditData,
              id: payRateEditData.id,
            });

          _.forEach(payRateRes, (v, k) => {
            props.node.data[k] = v;
          });
          await updateTableAsync(payRateGridApi.api, {
            update: [props.node.data],
          });
        }
        setPayRateEditData();
        debugger;
        await updateWorkerListPayment(nodeEditWorkerList, workerListGridApi);
        // const resWorker = await AppAPI.WorkerSettingAPI.UpdateOnePayment({
        //   id: nodeEditWorkerList.data.id,
        //   date: moment(new Date()),
        // });
        // _.forEach(resWorker, (v, k) => {
        //   nodeEditWorkerList.data[k] = v;
        // });
        // await updateTableAsync(workerListGridApi.api, {
        //   update: [nodeEditWorkerList.data],
        // });

        setIsLoading(false);
      } catch (ex) {
        if (ex.message) message.warning(ex.message);
        setIsLoading(false);
      }
    }
  };

  const handleDeleteItem = async (e) => {
    if (!window.confirm('Are you sure to delete?')) return;
    setIsLoading(true);
    try {
      await AppAPI.WorkerPayRateAPI.DeleteWorkerPayRate({
        id: e.node.data.id,
      });
      await updateTableAsync(payRateGridApi.api, {
        remove: [e.node.data],
      });
    } catch (ex) {
      if (ex.message) message.warning(ex.message);
    }
    setIsLoading(false);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        alignItems: 'center',
        height: '100%',
      }}
    >
      {payRateEditData?.id === props.node.data.id ? (
        <IconButton
          icon={'check'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleEditItem.bind(this, false)}
        />
      ) : (
        <IconButton
          icon={'edit'}
          size='sssmall'
          //   type={'delete'}
          onClick={handleEditItem.bind(this, true)}
        />
      )}
      <IconButton
        icon={'delete'}
        size='sssmall'
        type={'delete'}
        onClick={handleDeleteItem.bind(this, props)}
      />
    </div>
  );
}
