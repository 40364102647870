import React, { useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { autoGroupColumnDef } from '../../../../../settings/General.settings';
import { fiveDStore } from '../../../../../store/FiveD.store';
import ActionButtonCell from '../cell/ActionButton.cell';
import { toSnakeCase } from '../../../../../functions/General.function';
import DateCell from '../../../../ag_grid_components/cell/Date.cell';
import { appStore } from '../../../../../store/App.store';
import { AppAPI } from '../../../../api';
import { message } from 'antd';
import LinkElementCell from '../cell/LinkElement.cell';
import { viewerStore } from '../../../../../store/Viewer.store';
import _ from 'lodash';

export default function FiveDTable() {
  const {
    setSheetGridApi,
    sheetColumns,
    selectedSheet,
    setSheetColumns,
    setSheetRows,
    sheetRows,
    workDoneDate,
  } = fiveDStore();
  const { setIsLoading } = appStore();
  const { viewer, treeModelData } = viewerStore();
  useEffect(() => {
    if (selectedSheet) run();
  }, [selectedSheet]);
  const run = async () => {
    try {
      debugger;
      setIsLoading(true);
      const repSheet = await AppAPI.FiveDSheetAPI.GetOneSheet({
        id: selectedSheet.id,
      });
      const repSheetData = await AppAPI.FiveDSheetAPI.GetSheetDataById({
        id: selectedSheet.id,
      });
      setSheetRows(repSheetData);
      setSheetColumns(repSheet.columns);
      setIsLoading(false);
    } catch (ex) {
      message.warning(ex.message);
      setIsLoading(false);
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: 'agSetColumnFilter',
    };
  }, []);
  const columnDefs = useMemo(() => {
    const defaultColumn = [
      {
        headerName: '',
        valueGetter: 'node.rowIndex + 1',
        pinned: 'left',
        lockPosition: 'left',
        minWidth: 50,
        maxWidth: 50,
        suppressFilter: true,
        suppressMovable: true,
        suppressToolPanel: true,
        suppressSorting: true,
        suppressMenu: true,
      },
      // {
      //   headerName: 'Id',
      //   field: 'id',
      //   filter: 'agSetColumnFilter',
      // },
      {
        headerName: 'Description',
        field: 'description',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Plane Start Date',
        field: 'plane_start',
        filter: 'agSetColumnFilter',
        cellRendererSelector: (params) => {
          return {
            component: DateCell,
          };
        },
      },
      {
        headerName: 'Plane End Date',
        field: 'plane_end',
        filter: 'agSetColumnFilter',
        cellRendererSelector: (params) => {
          return {
            component: DateCell,
          };
        },
      },
      {
        headerName: 'Actual Start Date',
        field: 'actual_start',
        filter: 'agSetColumnFilter',
        cellRendererSelector: (params) => {
          return {
            component: DateCell,
          };
        },
      },
      {
        headerName: 'Actual End Date',
        field: 'actual_end',
        filter: 'agSetColumnFilter',
        cellRendererSelector: (params) => {
          return {
            component: DateCell,
          };
        },
      },
      {
        headerName: 'Design QTY',
        field: 'design_qty',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Final QTY',
        field: 'final_qty',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Work Done',
        field: 'work_done',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Link Element',
        field: 'object_3d',
        filter: 'agSetColumnFilter',
        lockPosition: 'right',
        pinned: 'right',
        cellRendererSelector: (params) => {
          return {
            component: LinkElementCell,
          };
        },
      },
      {
        headerName: '',
        field: 'action',
        minWidth: 150,
        maxWidth: 150,
        lockPosition: 'right',
        pinned: 'right',
        suppressFilter: true,
        suppressMovable: true,
        suppressToolPanel: true,
        suppressSorting: true,
        suppressMenu: true,
        filter: false,
        cellRendererSelector: (params) => {
          return {
            component: ActionButtonCell,
          };
        },
      },
    ];
    const constantCols = ['design_qty'];
    sheetColumns.forEach((v) => {
      if (!constantCols.includes(v.id)) {
        defaultColumn.push({
          headerName: v.name,
          field: `extra.${toSnakeCase(v.name)}`,
          filter: 'agSetColumnFilter',
        });
      }
    });
    return defaultColumn;
  }, [sheetColumns, viewer, treeModelData, workDoneDate]);

  const onGridReady = useCallback((params) => {
    setSheetGridApi(params);
  }, []);

  return (
    <>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          rowData={sheetRows}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          rowHeight={30}
          headerHeight={35}
          onGridReady={onGridReady}
          suppressContextMenu={true}
          rowSelection='multiple'
        />
      </div>
    </>
  );
}
