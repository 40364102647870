import { ReactComponent as SpinnerIcon } from 'src/assets/icons/loader/spinner.svg';
import BackDrop from 'src/components/atoms/BackDrop';
/**
 * @returns {JSX.Element} The rendered label and input elements.
 */
const FullSceenLoader = () => {
  return (
    <div className='z-1000 fixed top-0 size-full'>
      <BackDrop>
        <SpinnerIcon className='me-3 inline size-10 animate-spin text-primary-600' />
      </BackDrop>
    </div>
  );
};

export default FullSceenLoader;
