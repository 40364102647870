import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { requestBimGrid } from 'src/functions/General.function';
import { appStore } from 'src/store/App.store';

export default function SuperAdminRoutes(props) {
  const { isProcess, setIsProcess, setIsLoading } = appStore();
  const { organizationId, projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    setIsProcess(true);
    let data = await requestBimGrid('get', '/api/admin', null, {
      organizationId,
      projectId,
    });
    if (data) {
      if (!data.isAdmin) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
    setIsLoading(false);
    setIsProcess(false);
  };

  return (
    !isProcess && (
      <>
        <Outlet />
      </>
    )
  );
}
