import { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';
import { autoGroupColumnDef } from '../../../../../../settings/General.settings';
import { viewerStore } from '../../../../../../store/Viewer.store';
import DateCell from '../../../../../ag_grid_components/cell/Date.cell';
import IconButton from '../../../../../gen/button/IconButton.gen';
import { IssueComponentType } from '../constants/IssueComponentType';
import { issueStore } from '../Issue.store';

export default function IssueTable({}) {
  const { viewer } = viewerStore();
  const { issueData, setIssueDetail, setType } = issueStore();
  const gridRef = useRef();
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: 'agSetColumnFilter',
    };
  }, []);
  const handleViewIssue = (node) => {
    setIssueDetail(node.data);
    setType(IssueComponentType.Detail);
  };
  const handleViewCamera = (node) => {
    viewer.restoreState(node.data.view_state);
  };
  const columns = [
    {
      headerName: 'Title',
      field: 'title',
      minWidth: 60,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Information',
      suppressMovable: true,
      suppressStickyLabel: true,
      openByDefault: true,
      children: [
        {
          headerName: 'Discipline',
          field: 'discipline',
          filter: 'agSetColumnFilter',
          suppressMovable: true,
          maxWidth: 150,
          minWidth: 150,
          columnGroupShow: 'open',
        },
        {
          headerName: 'Type',
          field: 'type',
          filter: 'agSetColumnFilter',
          maxWidth: 120,
          minWidth: 120,
          suppressMovable: true,
          columnGroupShow: 'open',
        },
        {
          headerName: 'Category',
          field: 'category',
          filter: 'agSetColumnFilter',
          maxWidth: 120,
          minWidth: 120,
          suppressMovable: true,
          columnGroupShow: 'open',
        },
        {
          headerName: 'Description',
          field: 'description',
          filter: 'agSetColumnFilter',
          suppressMovable: true,
          columnGroupShow: 'closed',
        },
      ],
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      width: 120,
      maxWidth: 120,
      // editable: true,
      // cellEditorPopup: true,
      // cellEditor: 'agRichSelectCellEditor',
      // cellEditorParams: {
      //   values: ['New', 'Active', 'Reviewed', 'Approved', 'Resolved'],
      //   cellRenderer: ClashStatusCell,
      //   searchDebounceDelay: 500,
      // },
      // valueSetter: (params) => {
      //   if (params.newValue !== params.oldValue) {
      //     params.data.status = params.newValue;
      //     params.data.colorName = params.newValue.toLowerCase();
      //     params.api.refreshCells({
      //       rowNodes: [params.node],
      //       columns: ['name'],
      //       force: true,
      //     });
      //     clashDetectionPoint.editExistingPoint(params.node.id, params.data);
      //     return true;
      //   }
      //   return false;
      // },
    },
    {
      headerName: 'Due Date',
      field: 'due_date',
      filter: 'agSetColumnFilter',
      width: 120,
      maxWidth: 120,
      cellRendererSelector: (params) => {
        return {
          component: DateCell,
        };
      },
    },
    {
      headerName: '',
      field: 'action',
      minWidth: 100,
      maxWidth: 100,
      lockPosition: 'right',
      pinned: 'right',
      suppressFilter: true,
      suppressMovable: true,
      suppressToolPanel: true,
      suppressSorting: true,
      suppressMenu: true,
      filter: false,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
        return undefined;
      },
    },
  ];

  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton
          icon={'camera'}
          size='ssmall'
          ghost={true}
          border={false}
          onClick={handleViewCamera.bind(this, params.node)}
        />
        <IconButton
          icon={'view'}
          size='ssmall'
          ghost={true}
          border={false}
          onClick={handleViewIssue.bind(this, params.node)}
        />
        {/* <Button size='small' className={`prefab-custom-button ${(roleForAddEditModule[role] || user.isAdmin) ? 'delete' : 'disabled'}`} onClick={handleDeleteModule.bind(this, params.data)} type='danger'
          disabled={(roleForAddEditModule[role] || user.isAdmin)? false : false} ghost >Delete</Button> */}
      </div>
    );
  };

  const onGridReady = useCallback(() => {
    // setGridApi(params)
  }, []);
  return (
    <>
      <div style={{ width: '100%', height: '100%', padding: 5 }}>
        <div
          style={{ width: '100%', height: '100%' }}
          className='ag-theme-alpine'
        >
          <AgGridReact
            ref={gridRef}
            rowData={issueData}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows={true}
            onGridReady={onGridReady}
            groupDisplayType='multipleColumns'
            rowGroupPanelShow='always'
            rowHeight={30}
            // pagination={true}
            // paginationPageSize={pageSizeModuleList}
            suppressPaginationPanel={true}
            context={false}
            suppressContextMenu={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
}
