import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { appStore } from '../../store/App.store';
import IconButton from '../../components/gen/button/IconButton.gen';
const MAPPING_APPS = {
  digicheck: 'Digicheck Apps',
  'digicheck/checklist': 'Checklist',
};
export default function DigicheckRoute() {
  const location = useLocation();
  const { setAppName, appName } = appStore();
  const navigate = useNavigate();
  useEffect(() => {
    setAppName('digicheck');
    return () => {
      setAppName(null);
    };
  }, [location.pathname]);
  const handleBack = () => {
    navigate(-1);
  };
  console.log(appName);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Outlet />
      </div>
    </>
  );
}
