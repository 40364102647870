/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  message,
  Input,
  Select,
  DatePicker as DatePickerDayjs,
  Divider,
} from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { workerStore } from '../../../../../../store/Worker.store';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import {
  checkInput,
  updateTableAsync,
} from '../../../../../../functions/General.function';
import { AppAPI } from '../../../../../api';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalEditHubDevices() {
  const { setIsOpenEditHubDevices, hubDevicesGridApi, nodeEditHubDevices } =
    workerStore();
  const { setIsLoading } = appStore();
  const { projectId } = useParams();
  const [newItems, setNewItems] = useState({});
  const [selectItems, setSelectItems] = useState({});
  const [data, setData] = useState([
    {
      name: 'Device name',
      field: 'name',
      value: null,
      type: 'input',
    },
    {
      name: 'Device UUID',
      field: 'uuid',
      value: null,
      type: 'input',
    },
    {
      name: 'Description',
      field: 'description',
      value: null,
      type: 'textarea',
    },
  ]);
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
      _.forEach(nodeEditHubDevices.data, (v, k) => {
        const index = _.findIndex(data, (i) => {
          return i.field === k;
        });
        if (index >= 0) {
          data[index].value = v;
        }
      });
      setData([...data]);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const close = () => {
    setIsOpenEditHubDevices(false);
  };

  const handleOk = async (e) => {
    if (!checkInput(data, ['trade'])) {
      return;
    }
    setIsLoading(true);
    try {
      data.forEach((v) => {
        nodeEditHubDevices.data[v.field] = v.value;
      });
      const resHubDevices = await AppAPI.WorkerSettingAPI.UpdateOneHubDevices({
        id: nodeEditHubDevices.data.id,
        data: nodeEditHubDevices.data,
      });
      await updateTableAsync(hubDevicesGridApi.api, {
        update: [resHubDevices],
      });
      setIsLoading(false);
      close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };
  const handleChange = (type, e) => {
    type.value = e.target.value;
    setData([...data]);
  };
  const handleChangeDate = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const handleChangeSelect = (type, e) => {
    type.value = e;
    setData([...data]);
  };
  const onChangeNewItem = (type, event) => {
    newItems[type] = event.target.value;
    setNewItems({ ...newItems });
  };
  const handleAddNewItem = (type) => {
    if (!selectItems[type]) {
      selectItems[type] = [];
    }
    const unique = _.uniq([...selectItems[type], newItems[type]]);
    selectItems[type] = unique;
    setSelectItems({ ...selectItems });
    newItems[type] = null;
    setNewItems({ ...newItems });
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Edit hub device'}
        style={{ width: 500 }}
      >
        {data.map((v) => (
          <>
            {v.type === 'input' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Input
                  placeholder='please, input'
                  onChange={handleChange.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'textarea' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Input.TextArea
                  placeholder='please, input'
                  onChange={handleChange.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'date' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <DatePicker
                  placeholder='please, input'
                  onChange={handleChangeDate.bind(this, v)}
                  value={v.value}
                />
              </div>
            )}
            {v.type === 'select' && (
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{`${v.name}: `}</span>
                <Select
                  placeholder='please, input'
                  onChange={handleChangeSelect.bind(this, v)}
                  value={v.value}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      {v.isAdd && (
                        <>
                          <Divider
                            style={{
                              margin: '8px 0',
                            }}
                          />
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              gap: 5,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Input
                              placeholder='Please enter discipline'
                              style={{ width: 'calc(100% - 90px)' }}
                              value={newItems[v.field]}
                              onChange={onChangeNewItem.bind(this, v.field)}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <IconTextButton
                              onClick={handleAddNewItem.bind(this, v.field)}
                              size='ssmall'
                              icon={'add'}
                              type={'save'}
                            >
                              Add
                            </IconTextButton>
                          </div>
                        </>
                      )}
                    </>
                  )}
                >
                  {selectItems[v.field]?.map((v) => (
                    <Select.Option value={v}> {v} </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </>
        ))}
      </ModalTemplate>
    </>
  );
}
