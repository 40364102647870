import React, { } from "react";
import DMSDocTable from "./table/DMSDoc.table";
import DMSDocToolbar from "./DMSDoc.toolbar";
import ModalDMSDocAddFile from "./modal/ModalDMSDocAddFile";
import ModalDMSDocAddFolder from "./modal/ModalDMSDocAddFolder";
import { dmsStore } from "../../../../../store/DMS.store";
import ModalDMSDocEditFolder from "./modal/ModalDMSDocEditFolder";
import ModalDMSDocFileVersion from "./modal/ModalDMSDocFileVersion";


export default function DMSDocLayout() {

    const { isModalDMSDocAddFolder,
        addDocParentNode,
        isModalDMSDocAddFile,
        updateDMSDocNode,
        isModalDMSDocEditFolder,
        fileDMSDocNode } = dmsStore()


    return (
        <>
            <div style={{ display: 'flex', gap: 1, height: '100%', widthL: '100%' }} >
                <div style={{ height: '100%', width: '100%', display: "flex", flexDirection: 'column', gap: 5 }} >
                    <DMSDocToolbar />
                    <DMSDocTable />
                </div>

            </div>
            {isModalDMSDocAddFile && <ModalDMSDocAddFile />}
            {isModalDMSDocEditFolder && updateDMSDocNode && <ModalDMSDocEditFolder />}
            {isModalDMSDocAddFolder && addDocParentNode && <ModalDMSDocAddFolder />}
            {fileDMSDocNode && <ModalDMSDocFileVersion />}
        </>


    );
}


