export const adminEmail = 'baoquylan2@gmail.com';
export const cloudFront = 'https://d3abeojlqun05u.cloudfront.net/';

export const S3_BASED_PATH = {
  DMS_DOC: 'dms_doc',
  DMS_RFA: 'dms_rfa',
  QR_DRAWING_3D: 'qrDrawing',
  WORKER_APP_BLOCK: 'worker_app_block',
};

export const APP_COLOR = {
  MAIN: '#2b435f',
};

export const USER_PLAN = {
  NoPlan: 'No Plan',
  Basic: 'Basic',
  Pro: 'Pro',
};
