import React, { } from 'react';


import _ from 'lodash';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
// import ButtonNav from '../../components/Button.nav';

import ButtonNav from '../../Button.nav';
import { viewerStore } from '../../../../../../store/Viewer.store';
import { appStore } from '../../../../../../store/App.store';


export default function Tool6D({ handleChangeFeature }) {
    const { setIsOpenTopNav, setFeature, feature, docker, viewerLoading } = viewerStore()


    return (
        <div style={{ display: 'flex', gap: 5 }} >
            <ButtonNav
                url={"https://img.icons8.com/ios-filled/50/how-much-quest.png"}
                name='Cobie'
                active={feature.includes('6d_cobie')}
                onClick={handleChangeFeature.bind(this, '6d_cobie', docker, feature, setFeature)}
                disabled={viewerLoading}
            />
    


        </div>

    );
}
