import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { dfmaStore } from '../DFMA.store';
import { Pie } from 'react-chartjs-2';
import { CATEGORY_PPVC, CATEGORY_PPVC_COLOR, DEFAULT_COLOR } from '../DFMA.constant';

const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    plugins: {
        title: {
            display: true,
            text: 'Chart.js Line Chart - Multi Axis',
            position: "bottom",
        },
    },
};

export default function TimelineChartOverall() {
    const {
        rowDataTab,
        progressTimelineData,
    } = dfmaStore();
    const [overall, setOverall] = useState()
    const [casted, setCasted] = useState()
    const [delivery, setDelivery] = useState()
    const [install, setInstall] = useState()
    useEffect(() => {
        if (progressTimelineData.length !== 0)
            generatePiechart(progressTimelineData)
    }, [progressTimelineData])
    const generatePiechart = (data) => {
        debugger
        let map = {}

        _.forEach(data, v => {
            if (!v.category) return
            if (!map[v.category]) {
                map[v.category] = {
                    countCasted: 0,
                    countDelivery: 0,
                    countInstall: 0,
                    total: 0
                }
            }
            map[v.category].total++
            _.forEach(v.listTime, i => {
                if (i.status === 'Casting Completed') {
                    map[v.category].countCasted++
                }
                else if (i.status === 'Delivered to Site') {
                    map[v.category].countDelivery++
                } else if (i.status === 'Installed on Site') {
                    map[v.category].countInstall++
                }
            })
        })

        // let total = data.length
        // let countCasted = 0
        // let countDelivery = 0
        // let countInstall = 0
        // _.forEach(data, v => {
        //     _.forEach(v.listTime, i => {
        //         if (i.status === 'Casting Completed') {
        //             countCasted++
        //         }
        //         else if (i.status === 'Delivered to Site') {
        //             countDelivery++
        //         } else if (i.status === 'Installed on Site') {
        //             countInstall++
        //         }
        //     })
        // })
        debugger
        setOverall({
            labels: CATEGORY_PPVC,
            datasets: [
                {
                    label: 'Amount:',
                    data: CATEGORY_PPVC.map(v => { return map[v]?.total ?? 0 }),
                    backgroundColor: CATEGORY_PPVC.map(v => { return CATEGORY_PPVC_COLOR[v] ?? '#EEEEEE' }),
                },
            ],
        })
        setCasted({

            labels: CATEGORY_PPVC,
            datasets: [
                {
                    label: 'Amount:',
                    data: CATEGORY_PPVC.map(v => { return map[v]?.countCasted ?? 0 }),
                    backgroundColor: CATEGORY_PPVC.map(v => { return CATEGORY_PPVC_COLOR[v] ?? '#EEEEEE' }),
                },
            ],

        })
        setDelivery({
            labels: CATEGORY_PPVC,
            datasets: [
                {
                    label: 'Amount:',
                    data: CATEGORY_PPVC.map(v => { return map[v]?.countDelivery ?? 0 }),
                    backgroundColor: CATEGORY_PPVC.map(v => { return CATEGORY_PPVC_COLOR[v] ?? '#EEEEEE' }),
                },
            ],

        })
        setInstall({

            labels: CATEGORY_PPVC,
            datasets: [
                {
                    label: 'Amount:',
                    data: CATEGORY_PPVC.map(v => { return map[v]?.countInstall ?? 0 }),
                    backgroundColor: CATEGORY_PPVC.map(v => { return CATEGORY_PPVC_COLOR[v] ?? '#EEEEEE' }),
                },
            ],

        })

    }
    return (
        <>
            <div style={{
                padding: 20,
                display: 'flex',
                height: '500px',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                gap: 20
            }} >
                <div style={{
                    height: '200px',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    // flexWrap: 'wrap'
                }}>
                    <div style={{ height: 250, width: 250 }}>
                        {overall && <Pie data={overall} options={{
                            ...options, plugins: {
                                title: {
                                    display: true,
                                    text: 'All Category',
                                    position: "bottom",
                                },
                            },
                        }} />}
                    </div>

                </div>
                <p />
                <div style={{
                    height: '200px',
                    display: 'flex',
                    // flexDirection: 'column'
                    width: '100%',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap'
                }}>
                    <div style={{ height: 250, width: 250 }}>
                        {casted && <Pie data={casted} options={{
                            ...options, plugins: {
                                title: {
                                    display: true,
                                    text: 'Casting Completed',
                                    position: "bottom",
                                },
                            },
                        }} />}
                    </div>
                    <div style={{ height: 250, width: 250 }}>
                        {delivery && <Pie data={delivery} options={{
                            ...options, plugins: {
                                title: {
                                    display: true,
                                    text: 'Delivered to Site',
                                    position: "bottom",
                                },
                            },
                        }} />}
                    </div>
                    <div style={{ height: 250, width: 250 }}>
                        {install && <Pie data={install} options={{
                            ...options, plugins: {
                                title: {
                                    display: true,
                                    text: 'Installed on Site',
                                    position: "bottom",
                                },
                            },
                        }} />}
                    </div>
                </div>



            </div>
        </>

    );
}
