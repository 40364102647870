import CheckboxForm from 'src/components/atoms/CheckboxForm';
import LabelForm from 'src/components/atoms/LabelForm';

/**
 * LabelAndInputForm component renders a label and an input form element.
 *
 * @param {string} label - The text content of the label.
 * @param {string} name - The name attribute for the input element.
 * @param {boolean} isRequired - Whether the input element is required.
 * @param {string} [defaultChecked] - The default value of the input element (optional).
 * @returns {JSX.Element} The rendered label and input elements.
 */
const LabelAndICheckboxForm = ({ label, name, isRequired, defaultChecked }) => {
  return (
    <div className='flex gap-5'>
      <LabelForm name={name} label={label} />
      <CheckboxForm
        name={name}
        isRequired={isRequired}
        defaultChecked={defaultChecked}
      />
    </div>
  );
};

export default LabelAndICheckboxForm;
