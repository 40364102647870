import React, { useEffect, useState } from 'react';
import moment from 'moment';


export default function DurationCell(props) {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    useEffect(() => {
        let split = cellValue.split(',')
        console.log(split)
        setEnd(moment(new Date(split[1].trim())).format('DD/MM/YYYY'))
        setStart(moment(new Date(split[0].trim())).format('DD/MM/YYYY'))
    }, [])

    return (
        <div  >
            {`${start} - ${end}`}
        </div>
    );
};