import { DatePicker as DatePickerDayjs, message } from 'antd';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from 'src/components/gen/button/IconButton.gen';
import { appStore } from '../../../../../store/App.store';
import { workerStore } from '../../../../../store/Worker.store';
import { AppAPI } from '../../../../api';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function WorkerPaymentMonthToolbar() {
  const { paymentMonthDate, setPaymentMonthDate, paymentMonthGridApi } =
    workerStore();
  const navigate = useNavigate();
  const { setIsLoading } = appStore();
  const handleChangeDaily = (e) => {
    const tmp = [e.clone().startOf('month'), e.clone().endOf('month')];
    setPaymentMonthDate(tmp);
  };
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    try {
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };

  const handleUpdatePayment = async () => {
    setIsLoading(true);
    try {
      await AppAPI.WorkerSettingAPI.UpdateRangePayment({
        start: paymentMonthDate[0],
        end: paymentMonthDate[1],
      });
      navigate(0);
    } catch (ex) {
      message.warning(ex);
    }
    setIsLoading(false);
  };
  const handleExportExcel = () => {
    paymentMonthGridApi.api.exportDataAsExcel({
      // columnKeys: paymentMonthGridApi.api
      //   .getColumnDefs()
      //   .filter((col) => !['action_ignore'].includes(col.field))
      //   .map((col) => col.field),
      processCellCallback: function (params) {
        debugger;
        if (!params.node.group) {
          if (params.node.data[`${params.column.colId}_processed`]) {
            return params.node.data[`${params.column.colId}_processed`];
          }
          return params.node.data[`${params.column.colId}`];
        } else {
          const value = params.value;
          return value;
        }
      },
      processRowGroupCallback: (params) => {
        // This callback allows you to customize how group rows are exported
        const node = params.node;
        const field = node.field;
        if (['tracking_time', 'payement'].includes(field)) {
          return node.data[field];
        }
        return node.key;
      },
    });
  };

  const handleExportPDF = () => {
    /**
     * This function iterates over all of the columns to create a row of header cells
     */
    const getHeaderToExport = (gridApi) => {
      const columns = gridApi.columnApi.getAllDisplayedColumns();

      return columns.map((column) => {
        const { field } = column.getColDef();
        if (column.colId !== 'action_ignore') {
          const sort = column.getSort();
          // Enables export when row grouping
          const headerName = column.getColDef().headerName ?? field;
          const headerNameUppercase =
            headerName[0].toUpperCase() + headerName.slice(1);
          const headerCell = {
            text: headerNameUppercase + (sort ? ` (${sort})` : ''),

            // styles
            bold: true,
            margin: [0, 12, 0, 0],
          };
          return headerCell;
        }
      });
    };

    /**
     * This function iterates over all of the rows and columns to create
     * a matrix of cells when pivoting is enabled
     */

    const getRowsToExportPivot = (gridApi) => {
      const columns = gridApi.getAllDisplayedColumns();

      const getCellToExport = (column, node) => ({
        text: gridApi.getValue(column, node) ?? '',
        // styles
        ...column.getColDef().cellStyle,
      });

      const rowsToExport = [];
      gridApi.api.forEachNodeAfterFilterAndSort((node) => {
        if (node.group) {
          const rowToExport = columns.map((column) =>
            getCellToExport(column, node)
          );
          rowsToExport.push(rowToExport);
        }
      });

      return rowsToExport;
    };

    /**
     * This function iterates over all of the rows and columns to create
     * a matrix of cells
     */
    const getRowsToExport = (gridApi) => {
      //Enables export when pivoting
      if (gridApi.columnApi.isPivotMode()) {
        return getRowsToExportPivot(gridApi);
      }
      const columns = gridApi.columnApi.getAllDisplayedColumns();

      const getCellToExport = (column, node) => ({
        text: gridApi.api.getValue(column, node) ?? '',
        // styles
        ...column.getColDef().cellStyle,
      });

      const rowsToExport = [];
      gridApi.api.forEachNodeAfterFilterAndSort((node) => {
        const rowToExport = columns.map((column) =>
          getCellToExport(column, node)
        );
        rowsToExport.push(rowToExport);
      });

      return rowsToExport;
    };

    // Row colors
    const HEADER_ROW_COLOR = '#f8f8f8';
    const EVEN_ROW_COLOR = '#fcfcfc';
    const ODD_ROW_COLOR = '#fff';

    const PDF_INNER_BORDER_COLOR = '#dde2eb';
    const PDF_OUTER_BORDER_COLOR = '#babfc7';

    const createLayout = (numberOfHeaderRows) => ({
      fillColor: (rowIndex) => {
        if (rowIndex < numberOfHeaderRows) {
          return HEADER_ROW_COLOR;
        }
        return rowIndex % 2 === 0 ? EVEN_ROW_COLOR : ODD_ROW_COLOR;
      },
      //vLineHeight not used here.
      vLineWidth: (rowIndex, node) =>
        rowIndex === 0 || rowIndex === node.table.widths.length ? 1 : 0,
      hLineColor: (rowIndex, node) =>
        rowIndex === 0 || rowIndex === node.table.body.length
          ? PDF_OUTER_BORDER_COLOR
          : PDF_INNER_BORDER_COLOR,
      vLineColor: (rowIndex, node) =>
        rowIndex === 0 || rowIndex === node.table.widths.length
          ? PDF_OUTER_BORDER_COLOR
          : PDF_INNER_BORDER_COLOR,
    });

    /**
     * Returns a pdfMake shaped config for export, for more information
     * regarding pdfMake configuration, please see the pdfMake documentation.
     */
    const getDocument = (gridApi) => {
      const columns = gridApi.columnApi.getAllDisplayedColumns();

      const headerRow = getHeaderToExport(gridApi);
      const rows = getRowsToExport(gridApi);

      return {
        pageOrientation: 'landscape', // can also be 'portrait'
        content: [
          {
            table: {
              // the number of header rows
              headerRows: 1,

              // the width of each column, can be an array of widths
              widths: `${100 / columns.length}%`,

              // all the rows to display, including the header rows
              body: [headerRow, ...rows],

              // Header row is 40px, other rows are 15px
              heights: (rowIndex) => (rowIndex === 0 ? 40 : 15),
            },
            layout: createLayout(1),
          },
        ],
        pageMargins: [10, 10, 10, 10],
      };
    };

    const doc = getDocument(paymentMonthGridApi);
    pdfMake.createPdf(doc).download();
  };
  return (
    <>
      <div
        style={{
          padding: '5px 2px',
          display: 'flex',
          gap: 5,
          alignItems: 'center',
        }}
      >
        <DatePicker.MonthPicker
          allowClear={false}
          value={paymentMonthDate}
          onChange={handleChangeDaily}
        />

        <IconButton size='ssmall' icon='money' onClick={handleUpdatePayment} />
        <IconButton size='ssmall' icon='excel' onClick={handleExportExcel} />
        {/* <IconButton size='ssmall' icon='pdf' onClick={handleExportPDF} /> */}
      </div>
    </>
  );
}
