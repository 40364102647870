import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { logoWithName } from '../../../constant/LinkImage'
import { AppAPI } from '../../api';

export default function ImageCell(props) {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const [img, setImg] = useState()
    useEffect(() => {
        run()
    }, [cellValue])
    const run = async () => {
        if (cellValue) {
            const linkData = await AppAPI.S3API.GetPublicLink({ key: cellValue })
            setImg(linkData)
        }

    }

    return (
        <div style={{ display: 'flex', justifyContent: "center", alignContent: 'center' }} >
            <img src={img ?? logoWithName} height={30} />
        </div>
    );
};