import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function TreeFolder({ item }) {
  const [search] = useSearchParams();
  const folderId = search.get('folderId');
  const isMain = folderId === item?.key;
  console.log(item);
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span
        style={{
          fontWeight: isMain ? 'bold' : 'normal',
          color: isMain ? 'blue' : 'black',
        }}
      >
        {item.name}
      </span>
      <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}></div>
    </div>
  );
}
