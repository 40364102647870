import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Badge } from 'antd';


export default function ClashDistanceCell(props) {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;


    return (
        <span>
        {`${cellValue.toFixed(3)} m`}
    </span>
    );
};