import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Menu, Tag, message } from 'antd';
import {} from 'antd/lib/tree-select';
import {
  FolderAddOutlined,
  DeleteOutlined,
  MoreOutlined,
  QrcodeOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FileOutlined,
  PushpinOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { qrElementStore } from '../../QRElement.store';

export default function TreeQRElement({ item ,isLeaf}) {
  const navigate = useNavigate();
  const { fileQRElementId, setCurrentElement } = qrElementStore();
  const handleQR = () => {
    if (!fileQRElementId) return;
    setCurrentElement(item)
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            color: 'black',
          }}
        >
          <FileOutlined style={{}} />
          <span style={{ fontWeight: '' }}>{item.name}</span>
        </div>

        <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
         {isLeaf&& <QrcodeOutlined onClick={handleQR} />}
        </div>
      </div>
    </>
  );
}
