import React, { } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ProjectTable from "./table/Project.table";
import ProjectSidebar from "./sidebar/Project.sidebar";

export default function Project3DSectionDesktop({ }) {
    const navigate = useNavigate()
    const [search,] = useSearchParams();



    return (
        <div style={{ display: 'flex', gap: 5, height: '100%', widthL: '100%' }} >
            <div style={{ display: 'flex', width: 300 }} >
                <ProjectSidebar />
            </div>
            <div style={{ height: 'calc(100% - 50px)', width: '100%' }} >
                <ProjectTable />
            </div>

        </div>

    );
}


