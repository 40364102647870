import React, { useEffect, useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { timelineStore } from '../Timeline.store';
import CellSelectColor from '../cell/CellSelectColor.cell';
import CellSelectedColor from '../cell/CellSelectColor.cell';

export default function ColorConfigurationTable({  }) {
  const { configurationData,appearanceData ,setConfigurationData} = timelineStore();
  const [gridApi, setGridApi] = useState();
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(() => {
    const columns = [
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agSetColumnFilter',
        // editable: true,
      },
      {
        headerName: 'Start Appearance',
        field: 'startAppearance',
        filter: 'agSetColumnFilter',
        editable: true,
        cellRenderer: CellSelectedColor,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values:appearanceData,
          cellRenderer: CellSelectColor,
          cellEditorPopup: true,
        },
      },
      {
        headerName: 'End Appearance',
        field: 'endAppearance',
        filter: 'agSetColumnFilter',
        editable: true,
        cellRenderer: CellSelectedColor,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values:appearanceData,
          cellRenderer: CellSelectColor,
          cellEditorPopup: true,
        },
      },
      {
        headerName: 'Early Appearance',
        field: 'earlyAppearance',
        filter: 'agSetColumnFilter',
        editable: true,
        cellRenderer: CellSelectedColor,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values:appearanceData,
          cellRenderer: CellSelectColor,
          cellEditorPopup: true,
        },
      },
      {
        headerName: 'Late Appearance',
        field: 'lateAppearance',
        filter: 'agSetColumnFilter',
        editable: true,
        cellRenderer: CellSelectedColor,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values:appearanceData,
          cellRenderer: CellSelectColor,
          cellEditorPopup: true,
        },
      },
      // {
      //   headerName: 'External Field Name',
      //   field: 'external',
      //   filter: 'agSetColumnFilter',
      //   editable: true,
      //   cellEditorSelector: () => {
      //     return {
      //       component: 'agRichSelectCellEditor',
      //       params: {
      //         values: externalData,
      //       },
      //       popup: true,
      //     };
      //   },
      // },
    ];
    setColumnDefs(columns);
  }, [appearanceData]);

  const onGridReady = useCallback((node) => {
    setGridApi(node);
  }, []);
  const handleChangeCell =(e)=>{
   let clone =  [...configurationData]
   let index = _.findIndex(clone, v=>{return v.id ===e.data.id})
    if(index>=0){
      clone[index] = e.data
    }
    setConfigurationData(clone)
  }
  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div
          style={{ width: '100%', height: '100%' }}
          className='ag-theme-alpine'
        >
          <AgGridReact
            rowHeight={30}
            rowData={configurationData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows={true}
            onGridReady={onGridReady}
            suppressPaginationPanel={true}
            context={false}
            onCellEditingStopped={handleChangeCell}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
}
