/* eslint-disable no-unused-vars */
import { Button, Form, Input, message, Select } from 'antd';
import { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
  requestBimGrid,
  updateTableAsync,
} from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import IconButton from '../../../../gen/button/IconButton.gen';
import ModalTemplate from '../../../../gen/modal/Modal.gen';

export default function ModalEdiRole({ rowNode, setRowNode, gridApi }) {
  const [form] = Form.useForm();
  const { setIsLoading } = appStore();
  const [userInProject, setUserInProject] = useState([]);
  const { projectId, organizationId } = useParams();
  const [userId, setUserId] = useState();
  const close = () => {
    setRowNode();
  };
  useEffect(() => {
    if (!rowNode.allLeafChildren) {
      close();
    }
  }, [rowNode]);
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    const result = await Promise.all([
      requestBimGrid('get', '/api/project/getAllUser', null, {
        organizationId,
        projectId,
      }),
    ]);
    setUserInProject(result[0]);
    // setCompanies(result[1])
    // setUserRoles(result[2].map(v => { return v.id }))
    // setUserCompanies(result[3].map(v => { return v.id }))
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  const handleAddRole = async (e) => {
    let index = _.findIndex(rowNode.allLeafChildren, (v) => {
      return v.data.userId === userId;
    });
    if (index >= 0) {
      message.warning('User had this role');
      return;
    }
    setIsLoading(true);
    let userRole = await requestBimGrid(
      'post',
      '/api/user/addRole',
      {
        roleId: rowNode.allLeafChildren[0].data.roleId,
        projectId,
        userId: userId,
      },
      { organizationId, projectId }
    );
    if (userRole) {
      let index1 = _.findIndex(userInProject, (v) => {
        return v.userId === userId;
      });
      let combine = { ...rowNode.allLeafChildren[0].data };
      combine.email = userInProject[index1].email;
      await updateTableAsync(gridApi.api, { add: [combine] });
    }
    setIsLoading(false);
    console.log(e);
  };
  const handleRemoveRole = async (e) => {
    if (!window.confirm('Are you sure to remove?')) {
      return;
    }
    setIsLoading(true);
    let userRole = await requestBimGrid(
      'delete',
      '/api/user/removeRole',
      {
        roleId: rowNode.allLeafChildren[0].data.roleId,
        projectId,
        userId: e.userId,
      },
      { organizationId, projectId }
    );
    if (userRole) {
      // let clone = [...userRoles]
      // clone.splice(index, 1)
      // setUserRoles(clone)
      await updateTableAsync(gridApi.api, { remove: [e] });
    }
    setIsLoading(false);
  };
  const handleChangeEmail = (e) => {
    setUserId(e);
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        title={'Edit Role'}
        isFooter={false}
        // style={{width:300}}
      >
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout='vertical'
          className='idd-form'
          autoComplete='off'
          style={{ width: 500, maxWidth: '90vw' }}
        >
          <div>
            <Form.Item
              label='Role Name'
              name='name'
              initialValue={rowNode.allLeafChildren[0].data.name}
            >
              <Input placeholder='Enter role name' disabled />
            </Form.Item>

            <Form.Item label='List user'>
              <div style={{ display: 'flex' }}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.value?.toLowerCase() ?? '').includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.value ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.value ?? '').toLowerCase())
                  }
                  onChange={handleChangeEmail}
                  value={userId}
                >
                  {userInProject?.map((i) => (
                    <Select.Option value={i.userId} key={i.userId}>
                      {i.email}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  className={`idd-custom-button save`}
                  onClick={handleAddRole}
                  disabled={!userId}
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              </div>
            </Form.Item>

            <Form.Item>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                {rowNode.allLeafChildren?.map((i) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px gray solid',
                      paddingLeft: 5,
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>{i.data.email}</span>
                    <IconButton
                      url='https://img.icons8.com/ios-filled/50/null/trash.png'
                      size='ssmall'
                      type='delete'
                      onClick={handleRemoveRole.bind(this, i.data)}
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
          </div>
        </Form>
      </ModalTemplate>
    </>
  );
}
