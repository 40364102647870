import React, { useEffect, useState } from 'react';
import TextButton from '../../../../../gen/button/TextButton.gen';
import { appStore } from '../../../../../../store/App.store';
import { message, Select } from 'antd'
import { AppAPI } from '../../../../../api';
import _, { update } from 'lodash';
import { dmsStore } from '../../../../../../store/DMS.store';
import { generateRFAREVConsultantGroup, generateRFAREVFromApi, generateRFARevRow } from '../utils/RevUtils';


export default function RevCell(props) {
    const { setIsLoading } = appStore()
    const { dmsRFAGridApi } = dmsStore()
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const [revs, setRevs] = useState([])
    const [rev, setRev] = useState('All')
    useEffect(() => {
        if (props.node.data.revs) {
            let clone = ['All']
            _.forEach(props.node.data.revs, v => {
                clone.push(v.rev)
            })
            setRevs(clone)
        }
    }, [cellValue])

    useEffect(() => {
        if (props.node.data.rev !== undefined && props.node.data.rev !== null) {

            setRev(props.node.data.rev)
        }
    }, [props.node.data.rev])

    const handleChangeRev = async (type) => {
        // setRev(type)
        setIsLoading(true)
        try {
            const revDatas = await AppAPI.DMSRFAAPI.GetDMSRFAByRev({ dmsRFAId: props.node.data.dmsRFAId, rev: type })
            if (props.node.expanded) {
                let rm = []
                _.forEach(props.node?.childStore?.allRowNodes, v => {
                    rm.push(v.data)
                })
                await dmsRFAGridApi.api.applyServerSideTransactionAsync({
                    route: props.node.getRoute(),
                    remove: rm,
                });

                const { rows, parent } = generateRFAREVFromApi(revDatas, props.node.data.consultant_groups)
                debugger
                await dmsRFAGridApi.api.applyServerSideTransactionAsync({
                    route: props.node.getRoute(),
                    add: rows,
                });
                props.node.updateData({ ...props.node.data, ...parent, rev: type });
            } else {
                props.node.updateData({ ...props.node.data, rev: type });
                props.node.setExpanded(true)
            }

        } catch (ex) {
            if (ex.message)
                message.warning(ex.message)
        }
        setIsLoading(false)
    }
    return (
        <>
            <Select
                size='small'
                value={rev}
                variant="borderless"
                onChange={handleChangeRev}
                style={{ width: 80 }}
            >
                {revs.map(v =>
                    <Select.Option value={v}>{v}</Select.Option>
                )}
            </Select>
        </>

    );
};