/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { workerStore } from '../../../../../store/Worker.store';

export default function HubDeviceTag() {
  const { hubDeviceSprite } = workerStore();
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 100,
        top: hubDeviceSprite?.event?.clientY ?? 0,
        left: hubDeviceSprite?.event?.clientX ?? 0,
        transform: 'translate(-50%, -170%)',
      }}
      id='hub-device-tag'
    >
      <div
        style={{
          background: '#3e3f3a61',
          padding: '5px 10px',
          borderRadius: 10,
        }}
      >
        <span style={{ color: 'white' }}> {hubDeviceSprite?.item?.name}</span>
      </div>
    </div>
  );
}
