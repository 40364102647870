export default function ChecklistPage() {
  return (
    <>
      <div className='idd-list'>
        {/* {data.map((v, idx) => (
          <CardApp key={idx} {...v} handleViewItem={handleViewItem} />
        ))} */}
      </div>
    </>
  );
}
