/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { message, DatePicker as DatePickerDayjs, InputNumber } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import { appStore } from '../../../../../../store/App.store';
import _ from 'lodash';
import { workerStore } from '../../../../../../store/Worker.store';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { AppAPI } from '../../../../../api';
import PayRateTable from '../table/PayRate.table';
import IconTextButton from '../../../../../gen/button/IconTextButton.gen';

const DatePicker = DatePickerDayjs.generatePicker(momentGenerateConfig);
export default function ModalEditMoneyWorkerList() {
  const { setIsOpenMoneyWokerList, payRateGridApi, nodeEditWorkerList } =
    workerStore();
  const { setIsLoading } = appStore();
  const [data, setData] = useState({
    pay_rate: 25,
    start_date: moment(new Date()),
    end_date: moment(new Date()).add(7, 'days'),
  });
  // useEffect(() => {
  //   run();
  // }, []);
  // const run = async () => {
  //   setIsLoading(true);
  //   try {
  //   } catch (ex) {
  //     message.warning(ex);
  //   }
  //   setIsLoading(false);
  // };
  const close = () => {
    setIsOpenMoneyWokerList(false);
  };

  const handleOk = async (e) => {
    let check = true;
    debugger;
    const tmp = ['pay_rate', 'start_date', 'end_date'];
    _.forEach(tmp, (v) => {
      if (!data[v]) {
        message.warning(`Please fill all input`);
        check = false;
        return false;
      }
    });
    if (!check) {
      return;
    }
    setIsLoading(true);
    try {
      const resWorker = await AppAPI.WorkerPayRateAPI.CreateWorkerPayRate({
        data: data,
        workerId: nodeEditWorkerList.data.id,
      });
      await updateTableAsync(payRateGridApi.api, { add: [resWorker] });
      setIsLoading(false);
      // close();
    } catch (ex) {
      setIsLoading(false);
      message.warning(ex.message);
    }
  };
  const handleChange = (e) => {
    debugger;
    data.start_date = e[0];
    data.end_date = e[1];
    setData({ ...data });
  };
  // const handleChangeDate = (type, e) => {
  //   type.value = e;
  //   setData([...data]);
  // };
  // const handleChangeSelect = (type, e) => {
  //   type.value = e;
  //   setData([...data]);
  // };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Pay Rate'}
        style={{ width: 600 }}
        footer={false}
      >
        <div
          style={{
            display: 'flex',
            gap: 10,
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 5,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <InputNumber value={data.pay_rate} />
            <DatePicker.RangePicker
              value={[data.start_date, data.end_date]}
              onChange={handleChange}
            />
            <IconTextButton icon={'add'} size='ssmall' onClick={handleOk}>
              {' '}
              Add
            </IconTextButton>
          </div>
          <div style={{ height: 400 }}>
            <PayRateTable />
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
