/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Input, Radio, Select, message } from 'antd';
import ModalTemplate from '../../../../../gen/modal/Modal.gen';
import _ from 'lodash';
import { timelineStore } from '../Timeline.store';
import { AgGridReact } from 'ag-grid-react';
import TableMappingExcel from '../table/MappingExcel.table';
import { toSnakeCase } from '../../../util/Gantt.util';
import { v4 } from 'uuid';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import CellColor from '../cell/CellColor.cell';
import ButtonGen from '../../../../../gen/button/common/Button.gen';
import CellTextEditor from '../cell/CellTextEditor.cell';
import { hexToRgba } from '@uiw/color-convert'


export default function AppearanceDefinitions() {
  const {
    setIsOpenAppearanceDefinitions,
    setAppearanceData,
    appearanceData,
  } = timelineStore();
  const [gridApi, setGridApi] = useState();
  const close = () => {
    setIsOpenAppearanceDefinitions();
  };
 
  const handleOk = async (e) => {
    let data = [];
    gridApi.api.forEachLeafNode((v) => {
      data.push(v.data)
    });
    setAppearanceData(data)
    close()
  };

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditorSelector: (params) => {
        return {
          component: CellTextEditor,
        };
      },
    },
    {
      headerName: 'Color',
      field: 'color',
      filter: 'agSetColumnFilter',
      cellStyle: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: CellColor,
          };
        }
      },
    },
    // {
    //   headerName: 'Transparency',
    //   field: 'transparency',
    //   filter: 'agSetColumnFilter',
    //   cellRendererSelector: (params) => {
    //     if(!['none','hide','model_appearance'].includes(params.node.data.id)){
    //       const color = params.node.data.color;
    //       let rgba = hexToRgba(color)
    //       return {
    //         component: ()=>{
    //           return <>{rgba.a*100}%</>
    //         },
    //       };
    //     }
    //     return undefined
    //   },
    // },
  ];
  const onGridReady = useCallback((node) => {
    setGridApi(node);
  }, []);
  const handleAdd = () => {
    gridApi.api.applyTransaction({
      add: [{ id: v4(), name: 'New', color: 'black' }],
    });
  };
  const handleDelete = () => {
    let nodes = gridApi.api.getSelectedNodes();
    gridApi.api.applyTransaction({
      remove: nodes.map((v) => {
        return v.data;
      }),
    });
  };
  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Appearance Definitions'}
        isFullscreen={true}
        // styleBackDrop={{ position: 'abo' }}
        style={{ top: 'auto', width: '500px' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            height: '500px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 2,
            }}
          >
            <ButtonGen size='ssmall' onClick={handleAdd}>
              Add
            </ButtonGen>
            <ButtonGen type='delete' onClick={handleDelete} size='ssmall'>
              Delete
            </ButtonGen>
          </div>
          <div
            style={{ width: '100%', height: '100%' }}
            className='ag-theme-alpine'
          >
            <AgGridReact
              rowHeight={30}
              rowData={appearanceData}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              animateRows={true}
              onGridReady={onGridReady}
              suppressPaginationPanel={true}
              context={false}
              rowSelection='multiple'
            ></AgGridReact>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
}
