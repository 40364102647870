import React, { useState } from 'react';

/**
 * @param {string} title
 * @param {React.Element} icon
 *  @param {Function} onTabChange
 * @returns {React.Element} The rendered input element.
 */
const Tabs = ({ children, onTabChange, activeKey }) => {
  const [activeTab, setActiveTab] = useState(activeKey ?? children[0]?.key);

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
    onTabChange(tabKey);
  };
  return (
    <div className='border-b border-gray-200 dark:border-gray-700'>
      <ul className='-mb-px flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400'>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            active: child.key === activeTab,
            onClick: () => handleTabClick(child.key),
          })
        )}
      </ul>
    </div>
  );
};

export default Tabs;
