/* eslint-disable no-unused-vars */
import { Button, Form, message, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  requestBimGrid,
  uploadFileToForge,
} from '../../../../../functions/General.function';
import { appStore } from '../../../../../store/App.store';
import { projectStore } from '../../../../../store/Docs.store';
import ModalTemplate from '../../../../gen/modal/Modal.gen';

const chunkSize = 8 * 1000 * 1000;
export default function ModalAddFile({ setClose }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { folder, setIsAddFile, project } = projectStore();
  const { setIsLoading, user } = appStore();
  const [file, setFile] = useState('');
  const [search] = useSearchParams();
  const { organizationId, projectId } = useParams();
  const [load, setLoad] = useState(0);
  useEffect(() => {
    // message.success({
    //   content: <Progress percent={50} />,
    //   // className: 'custom-class',
    //   style: {
    //    display:'flex'
    //   },
    //   key: 'upload',
    //   duration:0
    // });
  }, []);

  const close = () => {
    setIsAddFile(false);
  };
  console.log(project);
  const handleOk = async (e) => {
    if (!file) return;
    form
      .validateFields()
      .then(async (values) => {
        setIsLoading(true);
        const fileName = v4() + '-' + folder.key + '-' + file.name;
        const result = await uploadFileToForge(
          project.forge_bucket_id,
          file,
          fileName,
          setLoad
        );
        debugger;
        finishFileToForge('3d', result);
      })
      .catch((err) => {
        console.log(err);
        message.warning(err.message);
        setIsLoading(false);
      });
  };
  const handleUploadFile = (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        setFile(info.file);
      }
    }
  };

  const finishFileToForge = async (type, item) => {
    try {
      setIsLoading(true);
      let dataFile = await requestBimGrid(
        'post',
        '/api/file',
        {
          projectId: projectId,
          name: file.name,
          objectId: item.data.objectId,
          folderId: folder.key,
          status: 0,
          type,
        },
        {
          organizationId,
          projectId,
        }
      );
      if (dataFile) {
        navigate(0);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalTemplate
        onClose={close}
        onOk={handleOk}
        title={'Create Item'}
        style={{ top: 'auto' }}
      >
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout='vertical'
          className='idd-form'
          autoComplete='off'
        >
          <div>
            <div style={{}}>
              {/* <Form.Item label="Item Type" name='type'
                rules={[{ required: true }]}
              >
                <Select>
                  <Select.Option key={'2d'} > 2D </Select.Option>
                  <Select.Option key={'3d'} > 3D </Select.Option>
                  <Select.Option key={'4d'} > 4D </Select.Option>
                  <Select.Option key={'5d'} > 5D </Select.Option>
                  <Select.Option key={'6d'} > 6D </Select.Option>
                </Select>
              </Form.Item> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 25 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <div style={{ display: 'flex', gap: 5 }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Select file to upload
                    </span>
                    {/* <span style={{ color: 'gray' }} >JPG, GIF, PNG</span>
                    <span style={{ color: 'gray' }} >(4MB Max)</span> */}
                    <Upload
                      customRequest={handleUploadFile}
                      showUploadList={false}
                    >
                      <Button
                        className='idd-custom-button save'
                        style={{ widthMax: 300, widthMin: 200, width: 250 }}
                      >{`${file ? 'Re-upload' : 'Upload'}`}</Button>
                    </Upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </ModalTemplate>
    </>
  );
}

// const uploadChunk = (upload) => {
//   return new Promise((resolve, reject) => {
//     let count = 0
//     let numberofChunks = Math.ceil(file.size / chunkSize);
//     message.loading({
//       content: `Uploading... ${0}%`,
//       style: {
//         zIndex: 10000000
//       },
//       key: 'upload',
//       duration: 0
//     });
//     eachLimit(upload, 6,
//       (task, callback) => {
//         task.then((res) => {
//           let progress = (count / numberofChunks * 95)
//           setLoad(progress)
//           count++
//           message.loading({
//             content: `Uploading... ${progress}%`,
//             style: {
//               zIndex: 10000000
//             },
//             key: 'upload',
//             duration: 0
//           });
//           callback()
//         }, (err) => {
//           console.log(err)
//           callback(err)
//         })
//       },
//       async (err) => {
//         message.destroy()
//         if (err) {
//           console.log(err)
//           reject()
//         } else {
//           resolve()
//         }
//       })
//   })
// }
