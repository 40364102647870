import React from "react";
import DMSRFALayout from "../../../../layout/apps/two_d/rfa/DMSRFA.layout";


export default function DMSRFAPage() {


    return (
        <>
            <DMSRFALayout />

        </>

    );
}


