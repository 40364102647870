import React from 'react';
import { Empty } from 'antd';
import { fiveDStore } from '../../../../../store/FiveD.store';
import TextButton from '../../../../gen/button/TextButton.gen';
export default function EmptySheet() {
  const { setIsOpenAddSheet } = fiveDStore();

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F3F3F3',
      }}
    >
      <Empty
        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
        imageStyle={{ height: 60 }}
        description={<span>No sheet selected</span>}
      >
        <div style={{ width: 150 }}>
          <TextButton
            size='ssmall'
            type='save'
            onClick={() => setIsOpenAddSheet(true)}
          >
            Add Sheet
          </TextButton>
        </div>
      </Empty>
    </div>
  );
}
