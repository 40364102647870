import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import _ from 'lodash'
import { useParams, useSearchParams } from "react-router-dom";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { viewerStore } from '../../../../../store/Viewer.store';
import { requestBimGrid } from '../../../../../functions/General.function';
import { getAllModelsElementdbIdsWithCondition, getElementProperty } from '../../../../../functions/Viewer.function';
import AnimationGen from './Animation.gen';


const THREE = window.THREE

export default function AnimationDetail({ setType, setLoading }) {
    const [search] = useSearchParams();
    const { viewer, setAnimationsData, animationsData, setAnimationStepsData, animationStepsData, animationDetail } = viewerStore()
    const fileId = search.get('fileId')
    const versionId = search.get('versionId')
    const { organizationId, projectId } = useParams()
    const [isChanged, setIsChanged] = useState()
    useEffect(() => {
        run()
    }, [])
    const run = async () => {
        setLoading(true)
        let data = await requestBimGrid('get', '/api/animation/getStepByAnimationId', null, {
            organizationId, projectId, animationId: animationDetail.id,
            select: ['id', 'name', 'animation_id', 'elements', 'position', 'time']
        })
        if (data) {
            let temp = []
            const models = viewer.impl.modelQueue().getModels();
            for (var i in data) {
                let v = data[i]
                let elements = []
                for (var j in v.elements) {
                    const el = v.elements[j]
                    let index = _.findIndex(models, v => { return v.myData.loadOptions.fileId === el.fileId })
                    if (index >= 0) {
                        const model = models[index]
                        const selection = []
                        let instanceTree = model.getData().instanceTree;
                        let temp = getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all');
                        let properties = await getElementProperty(model, temp)
                        _.forEach(properties, p => {
                            if (el.ids.includes(p.externalId)) {
                                selection.push(p.dbId)
                            }
                        })
                        elements.push({ model, selection })
                    }
                }
                v.elements = elements
                v.position = v?.position?.x ? new THREE.Vector3(v?.position?.x, v?.position?.y, v?.position?.z) : {}
                v.time = _.toNumber(v.time)
            }
            setAnimationStepsData(data)
        }
        setLoading(false)
    }

    const handleBack = () => {
        if(isChanged){
            if (!window.confirm('You made some changed, you still want to back?')) return
        }
        setType('list')
    }


    const handleUpdateAnimation = async () => {
        if (!window.confirm('Are you want to update?')) return
        setLoading(true)
        let clone = [...animationStepsData]
        let temp = []
        for (var j in clone) {
            let elements = []
            let selector = clone[j]?.elements
            for (var i in selector) {
                let model = selector[i].model
                let properties = await getElementProperty(selector[i].model, selector[i].selection)
                let ids = _.map(properties, v => { return v.externalId })
                elements.push({ versionId: model.myData.loadOptions.versionId, fileId: model.myData.loadOptions.fileId, ids })
            }
            let position = clone[j].position ? { x: clone[j].position.x, y: clone[j].position.y, z: clone[j].position.z } : {}
            temp.push({ id: clone[j].id, elements, position, time: clone[j].time, name: clone[j].name , index: j})
        }

        console.log(clone)
        let data = await requestBimGrid('put', '/api/animation/updateStep', { animationStepsData: temp }, { organizationId, projectId, fileId })
        if (data) {
            let clone = [...animationsData]
            clone.push(data)
            setAnimationsData(animationsData)
            setType('list')
        }
        setLoading(false)
    }
    return (
        <>
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={handleBack} />
                <span style={{ fontWeight: 'bold' }} >  {animationDetail.name}</span>

            </div>

            {animationStepsData &&
                <AnimationGen
                    isEdit={true}
                    setLoading={setLoading}
                    setIsChanged={setIsChanged}
                />}
            <div style={{ padding: 5, display: 'flex', gap: 5, alignItems: 'center' }}>
                <Button className={`idd-custom-button block edit`} onClick={handleUpdateAnimation}
                >Update Animation</Button>

            </div>


        </>

    );
}


