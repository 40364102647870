import { requestBimGrid } from '../../../functions/General.function';

export const getAllVRModelByProject = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'get',
        '/api/vdc/vr/get-all-vr-model-by-project',
        null,
        params
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const CreateVRModel = (body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await requestBimGrid(
        'post',
        '/api/vdc/vr/add-vr-model',
        body,
        {}
      );
      resolve(data);
    } catch (ex) {
      reject(ex.message);
    }
  });
};
export const UpdateOneVRModel = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'post',
        '/api/vdc/vr/update-one-vr-model',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
export const DeleteVRModel = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await requestBimGrid(
        'delete',
        '/api/vdc/vr/delete-one-vr-model',
        body
      );
      resolve(data);
    } catch (ex) {
      reject(ex);
    }
  });
};
