import { Route } from 'react-router-dom';

import {
  ThreeDFolderPage,
  ViewerPage,
} from 'src/components/pages/apps/three_d';
import { ThreeDRoute, ViewerRoute } from 'src/routes/apps';
import QRShareDrawingPage from '../components/pages/apps/three_d/share/QRShareDrawing.Page';
import QRShareElement from '../components/pages/apps/three_d/share/QRShareElement.Page';

export const ThreeDICSAppRoutes = [
  <Route path=':projectId/'>
    <Route element={<ThreeDRoute />}>
      <Route path='3d' element={<ThreeDFolderPage />} />
      <Route element={<ViewerRoute />}>
        <Route path='3d/viewer' element={<ViewerPage />} />
      </Route>
    </Route>
  </Route>,
];

export const ThreeDShareAppRoutes = [
  <Route path='qr-element' element={<QRShareElement />} />,
  <Route path='qr-drawing' element={<QRShareDrawingPage />} />,
];
