import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import IconButton from 'src/components/gen/button/IconButton.gen';
import { requestBimGrid } from '../../../../../../functions/General.function';
import {
  autoGroupColumnDef,
  defaultColDef,
} from '../../../../../../settings/General.settings';
import { appStore } from '../../../../../../store/App.store';

export default function RoleGroupPermissionTable({
  groupPermissions,
  setGridApi,
  handleAddGroupPermission,
  handleRemoveGroupPermission,
}) {
  const navigate = useNavigate();
  const { setIsLoading } = appStore();
  const gridRef = useRef();
  const { organizationId, projectId } = useParams();

  const [data, setData] = useState([]);
  useEffect(() => {
    run();
  }, []);
  const run = async () => {
    setIsLoading(true);
    console.log(groupPermissions);
    let reqGroupPermission = await requestBimGrid(
      'get',
      '/api/admin/role/getAllGroupPermissionByOrganizationId',
      null,
      { organizationId, projectId }
    );
    if (reqGroupPermission) {
      _.forEach(reqGroupPermission, (v) => {
        let index = _.findIndex(groupPermissions, (i) => {
          return v.id === i.groupPermissionId;
        });
        if (index >= 0) {
          v.isAdded = true;
        }
      });
      setData(reqGroupPermission);
    }
    setIsLoading(false);
  };

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agSetColumnFilter',
      width: 40,
      cellRendererSelector: (params) => {
        return {
          component: () => {
            return <span style={{ fontWeight: 'bold' }}> {params.value}</span>;
          },
        };
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: '',
      lockPosition: 'right',
      maxWidth: 100,
      suppressMovable: true,
      cellRendererSelector: (params) => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          };
        }
      },
    },
  ];
  const handleAction = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          alignItems: 'center',
          height: '100%',
        }}
      >
        {params.node.data.isAdded ? (
          <IconButton
            icon={'delete'}
            size='sssmall'
            type={'delete'}
            onClick={handleRemoveGroupPermission.bind(this, params.node.data)}
          />
        ) : (
          <IconButton
            icon={'add'}
            size='sssmall'
            type='save'
            onClick={handleAddGroupPermission.bind(this, params.node.data)}
          />
        )}
      </div>
    );
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);
  return (
    <div style={{ height: 400 }}>
      <div
        style={{ width: '100%', height: '100%' }}
        className='ag-theme-alpine'
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          suppressPaginationPanel={true}
          groupDefaultExpanded={1}
          suppressContextMenu={true}
          rowHeight={30}
          headerHeight={35}
        ></AgGridReact>
      </div>
    </div>
  );
}
