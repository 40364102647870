import React, { useEffect, useRef, useState } from "react";
import { Select, Button, Tabs } from 'antd';
import { viewerStore } from "../../../../../store/Viewer.store";
import _ from "lodash";
import Loader from "../../../../loader/LoaderApp";
import ClashDetectionPoint from "../../util/ClashDetection.util";
import ClashCheckSelectModel from "./ClashCheck.select";
import ClashCheckTestTable from "./table/ClashCheckTest.table";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import ClashCheckResultTable from "./table/ClashCheckResult.table";

const { Option } = Select;
export default function ClashCheckFeature(props) {
    const {  viewer, setClashDetectionPoint, clashDetectionPoint, setClashDetectionClashTest, setClashDetectionDetail } = viewerStore()
    const [loading, setLoading] = useState()
    const [type, setType] = useState('list')
    const [isOpen, setIsOpen] = useState([])
    const ref = useRef()
    useEffect(() => {
        let clashDetectionPoint = new ClashDetectionPoint(viewer, 'clashDetection2')
        clashDetectionPoint.active()
        setClashDetectionPoint(clashDetectionPoint)
        return () => {
            clashDetectionPoint.dispose()
            setClashDetectionPoint()
            setClashDetectionClashTest()
            setClashDetectionDetail()
        }
    }, [])

    useEffect(() => {
        if (ref.current) {
            setClashDetectionPoint(ref.current)
        }
    }, [ref.current])
    const handleAdd = () => {
        setType('add')
    }
    const handleRunTest = () => {

    }
    return (
        <>
            {loading && <div style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 11111111 }} >
                <Loader />
            </div>}
            <div style={{ style: 'flex', flexDirection: 'column', height: 'calc(100% - 0px)', padding: 5 }} >
                <div style={{ height: 200, display: isOpen ? 'block' : 'none' }} >
                    <ClashCheckTestTable />
                </div>
                <div style={{ padding: 5}}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', backgroundColor: 'black' }} onClick={setIsOpen.bind(this, !isOpen)} >
                        {isOpen ? <CaretUpFilled style={{ color: 'white' }} /> : <CaretDownFilled style={{ color: 'white' }} />}
                    </div>
                </div>
                <div style={{ height: '100%', borderRadius: 5,padding: 5 }} >
                   {clashDetectionPoint&& <Tabs style={{ height: '100%', background: 'white',  }} type='card'   >
                        <Tabs.TabPane tab="Rule" key="rule" style={{ height: `calc(100vh - ${isOpen ? '500px' : '300px'})` }} >
                            <ClashCheckSelectModel />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Result" key="result" style={{ height: `calc(100vh - ${isOpen ? '500px' : '300px'})` }} forceRender >
                            <ClashCheckResultTable />
                        </Tabs.TabPane>
                    </Tabs>}

                </div>

            </div>


        </>
    );
}
