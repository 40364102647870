import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";


import { AgGridReact } from 'ag-grid-react';
import { AppAPI } from "../../../../../api";
import DrawingNumberCell from "../../dms/cell/DrawingNumber.cell";
import { dmsStore } from "../../../../../../store/DMS.store";



export default function DMSMainTable() {
    const { setDMSRFAMainGridApi } = dmsStore()

    const { projectId } = useParams()

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: "Drawing Number",
            field: "drawing_number",
            // pinned: 'left',
            minWidth: 400,
            cellRendererParams: {
                // checkbox: true,
                suppressCount: true,
                innerRenderer: DrawingNumberCell,
                // innerRendererParams: { setIsLoading, pathDir, role, isAdmin, isWHUser },
            },
            colSpan: params => params.data.is_folder ? 15 : 1,
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            filter: "agSetColumnFilter",
        };
    }, []);

    const columnDefs = [
        {
            headerName: "",
            valueGetter: "node.rowIndex + 1",
            pinned: 'left',
            lockPosition: "left",
            minWidth: 50,
            maxWidth: 50,
            suppressFilter: true,
            suppressMovable: true,
            suppressToolPanel: true,
            suppressSorting: true,
            suppressMenu: true,
        },
        {
            headerName: 'Drawing Name',
            field: 'drawing_name',
            filter: 'agSetColumnFilter',

        },
        {
            headerName: 'Block',
            field: 'block_zone',
            filter: 'agSetColumnFilter',

        },
        {
            headerName: 'Level',
            field: 'level',
            filter: 'agSetColumnFilter',

        },
        {
            headerName: 'Drawing Type',
            field: 'drawingType',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Coordinator In Charge',
            field: 'coordinator_in_charge',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Modeller/Drafter',
            field: 'modeller_drafter',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Remark',
            field: 'remark',
            filter: 'agSetColumnFilter',
        }
    ]
    const runRequest = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await AppAPI.DMSRFAAPI.GetDMSMainDrawingChildrenById({
                    parentId: id,
                });
                if (data) resolve(data);
                else {
                    reject();
                }
            } catch {
                reject();
            }
        });
    };
    const onGridReady = useCallback((params) => {
        setDMSRFAMainGridApi(params)
        var datasource = {
            getRows: async (params) => {
                // console.log('ServerSideDatasource.getRows: params = ', params);
                const request = params.request;
                const groupKeys =
                    params.parentNode.level === -1
                        ? [projectId]
                        : params.request.groupKeys;
                try {
                    if (groupKeys.length !== 0) {
                        let _id = groupKeys[groupKeys.length - 1];
                        const allRows = await runRequest(_id);
                        const doingInfinite =
                            request.startRow != null && request.endRow != null;
                        const result = doingInfinite
                            ? {
                                rowData: allRows.slice(request.startRow, request.endRow),
                                rowCount: allRows.length,
                            }
                            : { rowData: allRows };
                        params.success(result);
                    }
                } catch {
                    params.fail();
                }
            },
        };
        params.api.setServerSideDatasource(datasource);
    }, []);

    const getDataPath = useMemo(() => {
        return (data) => data.path;
    }, []);
    const getRowId = useMemo(() => {
        return (params) => params.data.id;
    }, []);
    const isServerSideGroup = useCallback((dataItem) => {
        return dataItem.is_folder;
    }, []);
    const getServerSideGroupKey = useCallback((dataItem) => {
        return dataItem.id;
    }, []);
    const isServerSideGroupOpenByDefault = useCallback((params) => {
        return params.rowNode.level < 2;
    }, []);
    const onSortChanged = useCallback((params) => {
        params.api.refreshCells()
    }, []);
    const isRowSelectable = useCallback((params) => {
        return !params.data.is_folder;
    }, []);
    return (
        <div style={{ display: 'flex', gap: 15, flexDirection: 'column', width: '100%', height: 500 }} >
            <div style={{ width: '100%', height: '100%' }} className="ag-theme-alpine">
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    autoGroupColumnDef={autoGroupColumnDef}
                    animateRows={true}
                    treeData={true}
                    rowHeight={30}
                    headerHeight={35}
                    onGridReady={onGridReady}
                    suppressContextMenu={true}
                    getDataPath={getDataPath}
                    getRowId={getRowId}
                    masterDetail={true}
                    isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
                    isServerSideGroup={isServerSideGroup}
                    getServerSideGroupKey={getServerSideGroupKey}
                    rowModelType={"serverSide"}
                    onSortChanged={onSortChanged}
                    rowSelection='multiple'
                    isRowSelectable={isRowSelectable}
                />

            </div>


        </div>

    );
}


