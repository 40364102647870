/* eslint-disable no-unused-vars */
import { message, Tag } from 'antd';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AdminAddGroupPermissionAPI } from 'src/api';
import HorizontalDivider from 'src/components/atoms/HorizontalDivider';
import LabelForm from 'src/components/atoms/LabelForm';
import LabelAndInputForm from 'src/components/molecules/LabelAndInputForm';
import LabelAndTextAreaForm from 'src/components/molecules/LabelAndTextAreaForm';
import Modal from 'src/components/molecules/Modal';
import { updateTableAsync } from '../../../../../../functions/General.function';
import { appStore } from '../../../../../../store/App.store';
import GPPermissionTable from '../table/GPPermission.table';

export default function ModalAddOrganization({ setClose, gridApi }) {
  const formRef = useRef();
  const { setIsLoading } = appStore();
  const [subGridApi, setSubGridApi] = useState();
  const [permissions, setPermissions] = useState([]);
  const { organizationId } = useParams();
  const close = () => {
    setClose(false);
  };

  const handleOk = async (e) => {
    setIsLoading(true);
    try {
      const formData = new FormData(formRef.current);
      const data = {};
      debugger;
      formData.forEach((value, key) => {
        data[key] = value;
      });

      data.organization_id = organizationId;
      const resData = await AdminAddGroupPermissionAPI({ data, permissions });
      if (resData) {
        const temp = [];
        _.forEach(permissions, (v) => {
          temp.push({
            id: resData.id,
            name: data.name,
            description: data.description,
            resource: v.group,
            permissionName: v.name,
            permissionDescription: v.description,
            permissionId: v.id,
          });
        });
        await updateTableAsync(gridApi.api, { add: temp });
        gridApi.api.redrawRows();
        setClose(false);
      }
    } catch (ex) {
      if (ex.message) message.error(ex.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {}, []);
  const handleAddPermission = async (e) => {
    let clone = [...permissions];
    let index = _.findIndex(clone, (v) => {
      return v.id === e.id;
    });
    if (index < 0) {
      e.isAdded = true;
      clone.push(e);
      setPermissions(clone);
      await updateTableAsync(subGridApi.api, { update: [e] });
      subGridApi.api.redrawRows();
    } else {
      message.warning('This permission was added');
    }
  };
  const handleRemovePermission = async (e) => {
    if (!window.confirm('Are you want to remove permission?')) return;
    let clone = [...permissions];
    let index = _.findIndex(clone, (v) => {
      return v.id === e.id;
    });
    if (index >= 0) {
      permissions[index].isAdded = false;
      await updateTableAsync(subGridApi.api, { update: [permissions[index]] });
      subGridApi.api.redrawRows();
      clone.splice(index, 1);
      setPermissions(clone);
    }
  };
  return (
    <>
      <Modal onClose={close} onOk={handleOk} title={'Create Group Permission'}>
        <div className='w-[1000px] max-w-screen-lg'>
          <form ref={formRef} className='space-y-4 md:space-y-6'>
            <LabelAndInputForm
              label='Group Permission Name'
              name='name'
              isRequired
              placeholder='Enter group permission name'
            />
            <LabelAndTextAreaForm
              label='Description'
              name='description'
              placeholder='Enter description'
            />

            <LabelForm label={'Permission'} />
            {permissions.map((i) => (
              <Tag
                // closable
                onClose={handleRemovePermission.bind(this, i)}
                key={i.id}
                className='m-1 bg-primary-600 text-[14px] font-bold text-white'
                // color='#2a425e'
              >
                {i?.display_name}
              </Tag>
            ))}
          </form>

          <HorizontalDivider />
          <GPPermissionTable
            setGridApi={setSubGridApi}
            permissions={permissions}
            handleAddPermission={handleAddPermission}
            handleRemovePermission={handleRemovePermission}
          />
        </div>
      </Modal>
    </>
  );
}
